import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserSecret } from "@fortawesome/pro-solid-svg-icons";
import { faArrowUpRightFromSquare } from "@fortawesome/pro-solid-svg-icons";
import { faUserTie } from "@fortawesome/pro-solid-svg-icons";
import { faTruckMoving } from "@fortawesome/pro-solid-svg-icons";
import { faUmbrellaBeach } from "@fortawesome/pro-solid-svg-icons";
import { faFilePdf } from "@fortawesome/pro-solid-svg-icons";
import { faUsers } from "@fortawesome/pro-solid-svg-icons";
import { faCommentDollar } from "@fortawesome/pro-solid-svg-icons";
import { faThumbsDown } from "@fortawesome/pro-solid-svg-icons";
import { faHandshake } from "@fortawesome/pro-solid-svg-icons";
import { faFileInvoiceDollar } from "@fortawesome/pro-solid-svg-icons";
import { faPersonDolly } from "@fortawesome/pro-solid-svg-icons";
import { faPersonCarryBox } from "@fortawesome/pro-solid-svg-icons";
import { faTruckFast } from "@fortawesome/pro-solid-svg-icons";
import { faHandHoldingBox } from "@fortawesome/pro-solid-svg-icons";
import { faRightLeft } from "@fortawesome/pro-solid-svg-icons";
import { faPenNib } from "@fortawesome/pro-solid-svg-icons";
import { faForklift } from "@fortawesome/pro-solid-svg-icons";
import { faCashRegister } from "@fortawesome/pro-solid-svg-icons";
import { faPiggyBank } from "@fortawesome/pro-solid-svg-icons";
import { faPalletBoxes } from "@fortawesome/pro-solid-svg-icons";
import { faBoxesStacked } from "@fortawesome/pro-solid-svg-icons";
import { faTruckFlatbed } from "@fortawesome/pro-solid-svg-icons";
import { faFileChartColumn } from "@fortawesome/pro-solid-svg-icons";
import { faWineGlassCrack } from "@fortawesome/pro-solid-svg-icons";

export default function gsGroup({
  key
}) {
  return (
    <div className="custom-group">
      <div><FontAwesomeIcon className={"mr-2"}
        icon={
          (key === 'Checklist Supervisores' ? faUserSecret :
            (key === 'Checklist Auditores' ? faUserTie :
              (key === 'Checklist Vehiculos' ? faTruckMoving :
                (key === 'Solicitud Vac' ? faUmbrellaBeach :
                  (key === 'Plantilla PDF' ? faFilePdf :
                    (key === 'Lista de Usuarios' ? faUsers :
                      (key === 'Cotización' ? faCommentDollar :
                        (key === 'Venta Perdida' ? faThumbsDown :
                          (key === 'Pedido' ? faHandshake :
                            (key === 'Anticipo Saldo' ? faPiggyBank :
                              (key === 'Factura' ? faFileInvoiceDollar :
                                (key === 'Sol. Modificacion' ? faPenNib :
                                  (key === 'Corte Caja' ? faCashRegister :
                                    (key === 'Entrega Directa' ? faPersonDolly :
                                      (key === 'Devolucion Directa' ? faPersonCarryBox :
                                        (key === 'Solicitud Envio' ? faTruckFast :
                                          (key === 'Solicitud Devolucion' ? faHandHoldingBox :
                                            (key === 'Solicitud Traspaso' ? faRightLeft :
                                              (key === 'Transferencia' ? faWineGlassCrack :
                                                (key === 'Solicitud Embarque' ? faForklift :
                                                  undefined
                                                )
                                              )
                                            )
                                          )
                                        )
                                      )
                                    )
                                  )
                                )
                              )
                            )
                          )
                        )
                      )
                    )
                  )
                )
              )
            )
          )} />

        {`${key}`}</div>
    </div>
  );
}