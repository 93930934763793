import React, { useState } from 'react';

// Iconos
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlassDollar } from "@fortawesome/pro-solid-svg-icons";
import { faTruck } from "@fortawesome/pro-duotone-svg-icons";

export default function Rastreopedidos(props) {
  const [action, setAction] = useState((window.location.href.indexOf('tampico') >= 0 ? 'https://supercolchoneslaleona.dispatchtrack.com/widget/U8F3dOE3zKtq796iGdJNug' : 'https://supercolchones.dispatchtrack.com/widget/uBR7GwI7rfcps0BGGZT7QA'));

  return (
    <div>
      <div class="bg-gray-200 m-2">
        <h1 class="font-sans inline-block text-xl font-bold text-gray-700 leading-8 align-top mb-1"><FontAwesomeIcon icon={faTruck} size="xl" /> Rastreo de Pedido</h1>
      </div>
      <div className="bg-white p-4 shadow-xl m-2">
        <form id="form_widget-uBR7GwI7rfcps0BGGZT7QA" accept-charset="utf-8" target="guide_form_window" method="POST" action={action}>
          <fieldset>
            <div class="form-input">
              <label htmlFor="beetrack_widget_input" class="block text-sm text-gray-00 ">Número de guía.</label>
              <input type="text" id="beetrack_widget_input" name="search" class="placeholder-blueGray-300 bg-white text-sm border-2 w-1/2 px-5 py-1 rounded flex flex-col space-y-2" placeholder=""></input>

              <input type="submit" class="mt-5 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" value="Buscar guía" ></input>
            </div>
            <div class="form-footer">
            </div>
          </fieldset>
        </form>
      </div>
    </div>
  )
};