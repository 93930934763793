import React, { useState, useEffect, useRef } from 'react';

// LoadPanel
import { LoadPanel } from 'devextreme-react/load-panel';

// Toast
import { Toast } from 'devextreme-react/toast';

// maplibre
import { createMap } from "maplibre-gl-js-amplify";
import maplibregl from "maplibre-gl";
import "maplibre-gl/dist/maplibre-gl.css";
import "maplibre-gl-js-amplify/dist/public/amplify-map.css";
import * as turf from "@turf/turf";

// Grid
import DataGrid, {
  Column, FilterRow, HeaderFilter, FilterPanel, FilterBuilderPopup,
  GroupPanel, Grouping, SearchPanel, Sorting, Editing, Lookup, Toolbar, Item, RequiredRule,
  Scrolling, Pager, Paging, Summary, TotalItem, StateStoring, ColumnChooser, Format, Selection,
  Export, ColumnChooserSearch, ColumnChooserSelection
} from 'devextreme-react/data-grid';

//CheckBox
import CheckBox from 'devextreme-react/check-box';

//Form
import Form, { GroupItem, Label, TabbedItem, TabPanelOptions, Tab } from 'devextreme-react/form';

import { Auth, API, Storage } from 'aws-amplify';

// Ruteador
import { Route, Link, useHistory } from "react-router-dom";

// Iconos
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt } from "@fortawesome/pro-solid-svg-icons";
import { faArrowRotateLeft } from "@fortawesome/pro-solid-svg-icons";
import { faSave } from "@fortawesome/pro-solid-svg-icons";
import { faClipboardCheck } from "@fortawesome/pro-solid-svg-icons";
import { faList } from "@fortawesome/pro-duotone-svg-icons";
import { faUserTie } from "@fortawesome/pro-duotone-svg-icons";
import { faStopwatch } from "@fortawesome/pro-solid-svg-icons";
import { faFilePdf } from "@fortawesome/pro-solid-svg-icons";
import { faArrowsRotate } from "@fortawesome/pro-solid-svg-icons";
import { faFileAlt } from "@fortawesome/pro-solid-svg-icons";
import { faTrash } from "@fortawesome/pro-solid-svg-icons";

//FileUploader
import FileUploader from 'devextreme-react/file-uploader';

//FileManager
import FileManager, { Permissions } from 'devextreme-react/file-manager';

import './ChecklistAud.css';

//Text area
import 'devextreme-react/text-area';

import 'devextreme/data/odata/store';
import CustomStore from 'devextreme/data/custom_store';

import DataSource from 'devextreme/data/data_source';

import '../ListStyle.css';

import dxDateBox from "devextreme/ui/date_box";

import dxNumberBox from "devextreme/ui/number_box";

import TransactionStatus from "../TransactionStatus/TransactionStatus.js";

import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
import { exportDataGrid } from 'devextreme/excel_exporter'

dxDateBox.defaultOptions({
  options: {
    dateSerializationFormat: "yyyy-MM-dd"
  }
});

dxNumberBox.defaultOptions({
  options: {
    step: 0
  }
});

function isNotEmpty(value) {
  return value !== undefined && value !== null && value !== '';
}

export default function Checklist(props) {
  const history = useHistory();

  var mapRef = useRef(null); // Reference to the map DOM element

  const [isDetail, setIsDetail] = useState(false);
  const [id, setId] = useState();
  const [checkListPayload, setCheckListPayload] = useState({
    header: {},
    checklist: [],
    consecutivos: [],
    celulares: [],
    pedidos: [],
    pagos: [],
    billetemxn: [],
    monedamxn: [],
    billeteusd: [],
    monedausd: [],
    cheque: [],
    tarjeta: [],
    valedespensa: [],
    valesaldofavor: [],
    celulargerente: [],
    gasadmingerente: [],
    gasolinagerente: [],
    billetemxngerente: [],
    monedamxngerente: [],
    tarjetabancgerente: [],
    valegasgerente: []
  });

  const [attachments, setAttachments] = useState([]);

  const [allowNew, setAllowNew] = useState(false);
  const [allowUpdating, setAllowUpdating] = useState(false);
  const [activateButton, setActivateButton] = useState(false);
  const [activateStopwatch, setActivateStopwatch] = useState(false);
  const [activateSaveButton, setActivateSaveButton] = useState(false);
  const [activateDeleteButton, setActivateDeleteButton] = useState(false);

  const [sucursales, setSucursales] = useState([]);
  const [personales, setPersonales] = useState([]);

  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');
  const [autoExpandAll, setAutoExpandAll] = useState(true);

  //Controles para los campos tipo Checkbox
  const [conDiferenciaCajaMenor, setConDiferenciaCajaMenor] = useState(true);
  const [conReposicionCajaMenor, setConReposicionCajaMenor] = useState(true);
  const [conDiferenciaGteCajaChica, setConDiferenciaGteCajaChica] = useState(true);

  //Controles para expander los grupos del grid de checklist
  const [autoExpandAllChecklist, setAutoExpandAllChecklist] = useState(true);

  // setValue para el control tab
  const [value, setValue] = React.useState(0);

  // loadPanelPosition para el control FileManager
  const loadPanelPosition = { of: '#file-manager' };

  const [visibleLoadPanel, setVisibleLoadPanel] = useState(false);

  const [visibleToast, setVisibleToast] = useState(false);
  const [messageToast, setMessageToast] = useState('');
  const [typeToast, setTypeToast] = useState('success');

  //Ref para el grid Pedidos
  const [gridPedidosRef, setGridPedidosRef] = useState(React.createRef());

  //Ref para el grid Pagos
  const [gridPagosRef, setGridPagosRef] = useState(React.createRef());

  //Ref para el grid Billetes MXN
  const [gridBilleteMXNRef, setGridBilleteMXNRef] = useState(React.createRef());

  //Ref para el grid Monedas MXN
  const [gridMonedaMXNRef, setGridMonedaMXNRef] = useState(React.createRef());

  //Ref para el grid Billetes USD
  const [gridBilleteUSDRef, setGridBilleteUSDRef] = useState(React.createRef());

  //Ref para el grid Monedas USD
  const [gridMonedaUSDRef, setGridMonedaUSDRef] = useState(React.createRef());

  //Ref para el grid Cheque
  const [gridChequeRef, setGridChequeRef] = useState(React.createRef());

  //Ref para el grid Tarjeta
  const [gridTarjetaRef, setGridTarjetaRef] = useState(React.createRef());

  //Ref para el grid ValeDespensa
  const [gridValeDespensaRef, setGridValeDespensaRef] = useState(React.createRef());

  //Ref para el grid Vale
  const [gridValeRef, setGridValeRef] = useState(React.createRef());

  //Ref para el grid Gastos Administrativos Gerente
  const [gridGasAdminGerenteRef, setGridGasAdminGerenteRef] = useState(React.createRef());

  //Ref para el grid Gasolina Gerente
  const [gridGasolinaGerenteRef, setGridGasolinaGerenteRef] = useState(React.createRef());

  //Ref para el grid Billete MXN Gerente
  const [gridBilleteMXNGerenteRef, setGridBilleteMXNGerenteRef] = useState(React.createRef());

  //Ref para el grid Moneda MXN Gerente
  const [gridMonedaMXNGerenteRef, setGridMonedaMXNGerenteRef] = useState(React.createRef());

  //Ref para el grid Tarjetas Bancarias Gerente
  const [gridTarjetaBancGerenteRef, setGridTarjetaBancGerenteRef] = useState(React.createRef());

  //Ref para el grid Vales de Gasolina Gerente
  const [gridValesGasGerenteRef, setGridValesGasGerenteRef] = useState(React.createRef());

  //Ref para el form de Datos Arqueo
  const [datosArqueoRef, setDatosArqueoRef] = useState(React.createRef());

  //Ref para el form de Datos Arqueo Gerente
  const [datosArqueoGerenteRef, setDatosArqueoGerenteRef] = useState(React.createRef());

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const filterBuilderPopupPosition = {
    of: window,
    at: 'top',
    my: 'top',
    offset: { y: 10 },
  };

  const evaluacion = [
    { name: '', value: '' },
    { name: '1 = Deficiente', value: '1 = Deficiente' },
    { name: '2 = Malo', value: '2 = Malo' },
    { name: '3 = Regular', value: '3 = Regular' },
    { name: '4 = Bueno', value: '4 = Bueno' },
    { name: '5 = Excelente', value: '5 = Excelente' },
    { name: 'N.A. = No aplica', value: 'N.A. = No aplica' }
  ]

  const allowedPageSizes = [5, 10, 15, 20, 'all'];

  const exportFormats = ['xlsx'];

  //Ref para el grid Tablero de Control
  const [gridTableroRef, setGridTableroRef] = useState(React.createRef());

  function getOrderDay(rowData) {
    return (new Date(rowData.OrderDate)).getDay();
  }

  const filterBuilder = {
    customOperations: [{
      name: 'weekends',
      caption: 'Weekends',
      dataTypes: ['date'],
      icon: 'check',
      hasValue: false,
      calculateFilterExpression: () => [[getOrderDay, '=', 0], 'or', [getOrderDay, '=', 6]],
    }],
    allowHierarchicalFields: true,
  };

  const filterValue = [['Estatus', '=', 'Por Confirmar']];

  async function getCheckListRecords(sqlFilter) {
    try {
      var personal = props.personal;
      var checklistAccess = props.checklistAccess;

      if (!checklistAccess || checklistAccess === 'undefined')
        checklistAccess = '';

      let apiName = 'AdminSC';
      let path = '/procesachecklist';

      let myInit = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
        },
        queryStringParameters: {
          personal: personal,
          checklistAccess: checklistAccess,
          action: 'list',
          mov: 'Check List Aud',
          sqlFilter: sqlFilter,
          username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username
        }
      }

      var checkListGet = await API.get(apiName, path, myInit);
    }
    catch (e) {
      if (e === 'No current user') {
        history.push('/');

        window.location.reload();
      }
    }

    return checkListGet;
  }

  const store = new CustomStore({
    key: 'ID',
    load(loadOptions) {
      let sqlFilter = '(';
      [
        'filter'
      ].forEach((i) => {
        if (i in loadOptions && isNotEmpty(loadOptions[i])) {
          var filters = loadOptions[i];

          var filtersLength = filters.length;

          for (var i = 0; i <= filtersLength; i++) {
            var singleFilter = filters[i];
            var singleFilterType = typeof singleFilter;
            var singleFilterIsArray = Array.isArray(singleFilter);
            var singleFilterIsDate = singleFilter instanceof Date;

            if (singleFilter === 'NULL') {
              singleFilter = '0';
            }

            if (singleFilterType.toString() === 'object' && singleFilterIsArray) {
              sqlFilter += '(';

              var subFiltersLength = singleFilter.length;

              for (var i2 = 0; i2 <= subFiltersLength; i2++) {
                var singleSubFilter = singleFilter[i2];
                var singleSubFilterType = typeof singleSubFilter;
                var singleSubFilterIsArray = Array.isArray(singleSubFilter);
                var singleSubFilterIsDate = singleSubFilter instanceof Date

                if (singleSubFilter === 'NULL') {
                  singleSubFilter = '0';
                }

                if (singleSubFilterType.toString() === 'object' && singleSubFilterIsArray) {
                  sqlFilter += '(';

                  var subSubFiltersLength = singleSubFilter.length;

                  for (var i3 = 0; i3 <= subSubFiltersLength; i3++) {
                    var singleSubSubFilter = singleSubFilter[i3];
                    var singleSubSubFilterType = typeof singleSubSubFilter;
                    var singleSubSubFilterIsArray = Array.isArray(singleSubSubFilter);
                    var singleSubSubFilterIsDate = singleSubSubFilter instanceof Date

                    if (singleSubSubFilter === 'NULL') {
                      singleSubSubFilter = '0';
                    }

                    if (singleSubSubFilterType.toString() === 'object' && singleSubSubFilterIsArray) {
                      sqlFilter += '(';

                      var subSubSubFiltersLength = singleSubSubFilter.length;

                      for (var i4 = 0; i4 <= subSubSubFiltersLength; i4++) {
                        var singleSubSubSubFilter = singleSubSubFilter[i4];
                        var singleSubSubSubFilterType = typeof singleSubSubSubFilter;
                        var singleSubSubSubFilterIsArray = Array.isArray(singleSubSubSubFilter);
                        var singleSubSubSubFilterIsDate = singleSubSubSubFilter instanceof Date

                        if (singleSubSubSubFilter === 'NULL') {
                          singleSubSubSubFilter = '0';
                        }

                        if (singleSubSubSubFilterType.toString() === 'object' && singleSubSubSubFilterIsArray) {
                          sqlFilter += '(';

                          var subSubSubSubFiltersLength = singleSubSubSubFilter.length;

                          for (var i5 = 0; i5 <= subSubSubSubFiltersLength; i5++) {
                            var singleSubSubSubSubFilter = singleSubSubSubFilter[i5];
                            var singleSubSubSubSubFilterType = typeof singleSubSubSubSubFilter;
                            var singleSubSubSubSubFilterIsArray = Array.isArray(singleSubSubSubSubFilter);
                            var singleSubSubSubSubFilterIsDate = singleSubSubSubSubFilter instanceof Date

                            if (singleSubSubSubSubFilter === 'NULL') {
                              singleSubSubSubSubFilter = '0';
                            }

                            if (singleSubSubSubSubFilterType.toString() === 'string') {
                              if (singleSubSubSubSubFilter === 'contains') {
                                filters[i][i2][i3][i4][i5] = 'LIKE';
                                singleSubSubSubSubFilter = 'LIKE';

                                filters[i][i2][i3][i4][i5 + 1] = '\'%' + filters[i][i2][i3][i4][i5 + 1] + '%\'';
                              }
                              else if (singleSubSubSubSubFilter === 'notcontains') {
                                filters[i][i2][i3][i4][i5] = 'NOT LIKE';
                                singleSubSubSubSubFilter = 'NOT LIKE';

                                filters[i][i2][i3][i4][i5 + 1] = '\'%' + filters[i][i2][i3][i4][i5 + 1] + '%\'';
                              }
                              else if (singleSubSubSubSubFilter === 'startswith') {
                                filters[i][i2][i3][i4][i5] = 'LIKE';
                                singleSubSubSubSubFilter = 'LIKE';

                                filters[i][i2][i3][i4][i5 + 1] = '\'' + filters[i][i2][i3][i4][i5 + 1] + '%\'';
                              }
                              else if (singleSubSubSubSubFilter === 'endswith') {
                                filters[i][i2][i3][i4][i5] = 'LIKE';
                                singleSubSubSubSubFilter = 'LIKE';

                                filters[i][i2][i3][i4][i5 + 1] = '\'%' + filters[i][i2][i3][i4][i5 + 1] + '\'';
                              }
                              else if (singleSubSubSubSubFilter === '=' || singleSubSubSubSubFilter === '<>' || singleSubSubSubSubFilter === '>' || singleSubSubSubSubFilter === '>=' || singleSubSubSubSubFilter === '<' || singleSubSubSubSubFilter === '<=') {
                                if (!filters[i][i2][i3][i4][i5 + 1])
                                  filters[i][i2][i3][i4][i5 + 1] = 'NULL';
                                else if (typeof filters[i][i2][i3][i4][i5 + 1] === 'string') {
                                  filters[i][i2][i3][i4][i5 + 1] = '\'' + filters[i][i2][i3][i4][i5 + 1] + '\'';
                                }
                              }
                              else if (singleSubSubSubSubFilter === '!') {
                                filters[i][i2][i3][i4][i5] = 'NOT';
                                singleSubSubSubSubFilter = 'NOT';
                              }

                              sqlFilter += ' ' + singleSubSubSubSubFilter + ' ';
                            }
                            else if (singleSubSubSubSubFilterType.toString() === 'object' && singleSubSubSubSubFilterIsDate) {
                              sqlFilter += ' \'' + singleSubSubSubSubFilter.toISOString().split('T')[0] + '\' ';
                            }
                            else if (singleSubSubSubSubFilterType.toString() === 'number') {
                              sqlFilter += ' ' + singleSubSubSubSubFilter.toString() + ' ';
                            }
                            else if (singleSubSubSubSubFilterType.toString() === 'boolean') {
                              if (singleSubSubSubSubFilter.toString() === 'true')
                                sqlFilter += '1 ';
                              else if (singleSubSubSubSubFilter.toString() === 'false' || singleSubSubSubSubFilter === null || singleSubSubSubSubFilter === undefined)
                                sqlFilter += '0 ';
                            }
                          }
                        }
                        else if (singleSubSubSubFilterType.toString() === 'string') {
                          if (singleSubSubSubFilter === 'contains') {
                            filters[i][i2][i3][i4] = 'LIKE';
                            singleSubSubSubFilter = 'LIKE';

                            filters[i][i2][i3][i4 + 1] = '\'%' + filters[i][i2][i3][i4 + 1] + '%\'';
                          }
                          else if (singleSubSubSubFilter === 'notcontains') {
                            filters[i][i2][i3][i4] = 'NOT LIKE';
                            singleSubSubSubFilter = 'NOT LIKE';

                            filters[i][i2][i3][i4 + 1] = '\'%' + filters[i][i2][i3][i4 + 1] + '%\'';
                          }
                          else if (singleSubSubSubFilter === 'startswith') {
                            filters[i][i2][i3][i4] = 'LIKE';
                            singleSubSubSubFilter = 'LIKE';

                            filters[i][i2][i3][i4 + 1] = '\'' + filters[i][i2][i3][i4 + 1] + '%\'';
                          }
                          else if (singleSubSubSubFilter === 'endswith') {
                            filters[i][i2][i3][i4] = 'LIKE';
                            singleSubSubSubFilter = 'LIKE';

                            filters[i][i2][i3][i4 + 1] = '\'%' + filters[i][i2][i3][i4 + 1] + '\'';
                          }
                          else if (singleSubSubSubFilter === '=' || singleSubSubSubFilter === '<>' || singleSubSubSubFilter === '>' || singleSubSubSubFilter === '>=' || singleSubSubSubFilter === '<' || singleSubSubSubFilter === '<=') {
                            if (!filters[i][i2][i3][i4 + 1])
                              filters[i][i2][i3][i4 + 1] = 'NULL';
                            else if (typeof filters[i][i2][i3][i4 + 1] === 'string') {
                              filters[i][i2][i3][i4 + 1] = '\'' + filters[i][i2][i3][i4 + 1] + '\'';
                            }
                          }
                          else if (singleSubSubSubFilter === '!') {
                            filters[i][i2][i3][i4] = 'NOT';
                            singleSubSubSubFilter = 'NOT';
                          }

                          sqlFilter += ' ' + singleSubSubSubFilter + ' ';
                        }
                        else if (singleSubSubSubFilterType.toString() === 'object' && singleSubSubSubFilterIsDate) {
                          sqlFilter += ' \'' + singleSubSubSubFilter.toISOString().split('T')[0] + '\' ';
                        }
                        else if (singleSubSubSubFilterType.toString() === 'number') {
                          sqlFilter += ' ' + singleSubSubSubFilter.toString() + ' ';
                        }
                        else if (singleSubSubSubFilterType.toString() === 'boolean') {
                          if (singleSubSubSubFilter.toString() === 'true')
                            sqlFilter += '1 ';
                          else if (singleSubSubSubFilter.toString() === 'false' || singleSubSubSubFilter === null || singleSubSubSubFilter === undefined)
                            sqlFilter += '0 ';
                        }
                      }

                      sqlFilter += ')';
                    }
                    else if (singleSubSubFilterType.toString() === 'string') {
                      if (singleSubSubFilter === 'contains') {
                        filters[i][i2][i3] = 'LIKE';
                        singleSubSubFilter = 'LIKE';

                        filters[i][i2][i3 + 1] = '\'%' + filters[i][i2][i3 + 1] + '%\'';
                      }
                      else if (singleSubSubFilter === 'notcontains') {
                        filters[i][i2][i3] = 'NOT LIKE';
                        singleSubSubFilter = 'NOT LIKE';

                        filters[i][i2][i3 + 1] = '\'%' + filters[i][i2][i3 + 1] + '%\'';
                      }
                      else if (singleSubSubFilter === 'startswith') {
                        filters[i][i2][i3] = 'LIKE';
                        singleSubSubFilter = 'LIKE';

                        filters[i][i2][i3 + 1] = '\'' + filters[i][i2][i3 + 1] + '%\'';
                      }
                      else if (singleSubSubFilter === 'endswith') {
                        filters[i][i2][i3] = 'LIKE';
                        singleSubSubFilter = 'LIKE';

                        filters[i][i2][i3 + 1] = '\'%' + filters[i][i2][i3 + 1] + '\'';
                      }
                      else if (singleSubSubFilter === '=' || singleSubSubFilter === '<>' || singleSubSubFilter === '>' || singleSubSubFilter === '>=' || singleSubSubFilter === '<' || singleSubSubFilter === '<=') {
                        if (!filters[i][i2][i3 + 1])
                          filters[i][i2][i3 + 1] = 'NULL';
                        else if (typeof filters[i][i2][i3 + 1] === 'string') {
                          filters[i][i2][i3 + 1] = '\'' + filters[i][i2][i3 + 1] + '\'';
                        }
                      }
                      else if (singleSubSubFilter === '!') {
                        filters[i][i2][i3] = 'NOT';
                        singleSubSubFilter = 'NOT';
                      }
                      sqlFilter += ' ' + singleSubSubFilter + ' ';
                    }
                    else if (singleSubSubFilterType.toString() === 'object' && singleSubSubFilterIsDate) {
                      sqlFilter += ' \'' + singleSubSubFilter.toISOString().split('T')[0] + '\' ';
                    }
                    else if (singleSubSubFilterType.toString() === 'number') {
                      sqlFilter += ' ' + singleSubSubFilter.toString() + ' ';
                    }
                    else if (singleSubSubFilterType.toString() === 'boolean') {
                      if (singleSubSubFilter.toString() === 'true')
                        sqlFilter += '1 ';
                      else if (singleSubSubFilter.toString() === 'false' || singleSubSubFilter === null || singleSubSubFilter === undefined)
                        sqlFilter += '0 ';
                    }
                  }

                  sqlFilter += ')';
                }
                else if (singleSubFilterType.toString() === 'string') {
                  if (singleSubFilter === 'contains') {
                    filters[i][i2] = 'LIKE';
                    singleSubFilter = 'LIKE';

                    filters[i][i2 + 1] = '\'%' + filters[i][i2 + 1] + '%\'';
                  }
                  else if (singleSubFilter === 'notcontains') {
                    filters[i][i2] = 'NOT LIKE';
                    singleSubFilter = 'NOT LIKE';

                    filters[i][i2 + 1] = '\'%' + filters[i][i2 + 1] + '%\'';
                  }
                  else if (singleSubFilter === 'startswith') {
                    filters[i][i2] = 'LIKE';
                    singleSubFilter = 'LIKE';

                    filters[i][i2 + 1] = '\'' + filters[i][i2 + 1] + '%\'';
                  }
                  else if (singleSubFilter === 'endswith') {
                    filters[i][i2] = 'LIKE';
                    singleSubFilter = 'LIKE';

                    filters[i][i2 + 1] = '\'%' + filters[i][i2 + 1] + '\'';
                  }
                  else if (singleSubFilter === '=' || singleSubFilter === '<>' || singleSubFilter === '>' || singleSubFilter === '>=' || singleSubFilter === '<' || singleSubFilter === '<=') {
                    if (!filters[i][i2 + 1])
                      filters[i][i2 + 1] = 'NULL';
                    else if (typeof filters[i][i2 + 1] === 'string') {
                      filters[i][i2 + 1] = '\'' + filters[i][i2 + 1] + '\'';
                    }
                  }
                  else if (singleSubFilter === '!') {
                    filters[i][i2] = 'NOT';
                    singleSubFilter = 'NOT';
                  }
                  sqlFilter += ' ' + singleSubFilter + ' ';
                }
                else if (singleSubFilterType.toString() === 'object' && singleSubFilterIsDate) {
                  sqlFilter += ' \'' + singleSubFilter.toISOString().split('T')[0] + '\' ';
                }
                else if (singleSubFilterType.toString() === 'number') {
                  sqlFilter += ' ' + singleSubFilter.toString() + ' ';
                }
                else if (singleSubFilterType.toString() === 'boolean') {
                  if (singleSubFilter.toString() === 'true')
                    sqlFilter += '1 ';
                  else if (singleSubFilter.toString() === 'false' || singleSubFilter === null || singleSubFilter === undefined)
                    sqlFilter += '0 ';
                }
              }

              sqlFilter += ')';
            }
            else if (singleFilterType.toString() === 'string') {
              if (singleFilter === 'contains') {
                filters[i] = 'LIKE';
                singleFilter = 'LIKE';

                filters[i + 1] = '\'%' + filters[i + 1] + '%\'';
              }
              else if (singleFilter === 'notcontains') {
                filters[i] = 'NOT LIKE';
                singleFilter = 'NOT LIKE';

                filters[i + 1] = '\'%' + filters[i + 1] + '%\'';
              }
              else if (singleFilter === 'startswith') {
                filters[i] = 'LIKE';
                singleFilter = 'LIKE';

                filters[i + 1] = '\'' + filters[i + 1] + '%\'';
              }
              else if (singleFilter === 'endswith') {
                filters[i] = 'LIKE';
                singleFilter = 'LIKE';

                filters[i + 1] = '\'%' + filters[i + 1] + '\'';
              }
              else if (singleFilter === '=' || singleFilter === '<>' || singleFilter === '>' || singleFilter === '>=' || singleFilter === '<' || singleFilter === '<=') {
                if (!filters[i + 1])
                  filters[i + 1] = 'NULL';
                else if (typeof filters[i + 1] === 'string') {
                  filters[i + 1] = '\'' + filters[i + 1] + '\'';
                }
              }
              else if (singleFilter === '!') {
                filters[i] = 'NOT';
                singleFilter = 'NOT';
              }
              sqlFilter += ' ' + singleFilter + ' ';
            }
            else if (singleFilterType.toString() === 'object' && singleFilterIsDate) {
              sqlFilter += ' \'' + singleFilter.toISOString().split('T')[0] + '\' ';
            }
            else if (singleFilterType.toString() === 'number') {
              sqlFilter += ' ' + singleFilter.toString() + ' ';
            }
            else if (singleFilterType.toString() === 'boolean') {
              if (singleFilter.toString() === 'true')
                sqlFilter += '1 ';
              else if (singleFilter.toString() === 'false' || singleFilter === null || singleFilter === undefined)
                sqlFilter += '0 ';
            }
          }

          sqlFilter += ')';
        }
      });

      async function getRecords() {
        if (sqlFilter === '(')
          sqlFilter = '';

        var records = await getCheckListRecords(sqlFilter);
        return {
          data: records,
          totalCount: records.length
        };
      }

      return getRecords()
        .then(function (result) {
          return result;
        })
        .catch(() => { throw new Error('Data Loading Error'); });
    },
  });

  async function getCheckListPayload(ID) {
    try {
      var personal = props.personal;

      let apiName = 'AdminSC';
      let path = '/procesachecklist';

      let myInit = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
        },
        queryStringParameters: {
          personal: personal,
          ID: ID,
          action: 'payload',
          mov: 'Check List Aud',
          username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username
        }
      }

      var payloadGet = await API.get(apiName, path, myInit);

      setCheckListPayload(payloadGet);

      //Obtenemos los attachments
      var attachmentsGet = await Storage.list("/checklist/" + ID);
      var attachmentsAux = [];

      for (var attachmentsGetIndex in attachmentsGet) {
        var singleAttachmentsGet = attachmentsGet[attachmentsGetIndex];

        var singleAttachment = {
          name: singleAttachmentsGet.key.split('/').pop(),
          isDirectory: false,
          size: singleAttachmentsGet.size,
        };

        attachmentsAux.push(singleAttachment);
      }

      setAttachments(attachmentsAux);

      //Asignamos las variables para los controles tipo CheckBox
      setConDiferenciaCajaMenor(payloadGet.header.ArqDiferencia);
      setConReposicionCajaMenor(payloadGet.header.ArqGteReposicion);
      setConDiferenciaGteCajaChica(payloadGet.header.ArqGteDiferencia);
    }
    catch (e) {
      if (e === 'No current user') {
        history.push('/');

        window.location.reload();
      }
    }

    return payloadGet;
  }

  function onFieldDatosChecklistChanged(e) {
    if (e.dataField === 'ArqDiferencia') {
      setConDiferenciaCajaMenor(!conDiferenciaCajaMenor);
    }
  }

  function onFieldDatosArqueoGerenteChanged(e) {
    if (e.dataField === 'ArqGteReposicion') {
      setConReposicionCajaMenor(!conReposicionCajaMenor);
    }
    else if (e.dataField === 'ArqGteDiferencia') {
      setConDiferenciaGteCajaChica(!conDiferenciaGteCajaChica);
    }
  }

  async function renderMap(payload) {
    let map;
    var keys;

    //Obtenemos las ubicacion aceptada del checklist
    var locations = {};
    var circles = {};

    // Definimos el circulo
    if (payload.header.Longitud && payload.header.Latitud) {
      var center = [payload.header.Longitud, payload.header.Latitud];

      var radius = .1;
      var options = {
        steps: 360,
        units: "kilometers",
      };

      var circle = turf.circle(center, radius, options);

      var validLocation = {
        coordinates: circle.geometry.coordinates
      };

      locations[payload.header.Nombre] = validLocation;
      locations[payload.header.Nombre].Latitud = payload.header.Latitud;
      locations[payload.header.Nombre].Longitud = payload.header.Longitud;

      circles[payload.header.Nombre] = circle;
    }
    // We only want to initialize the underlying maplibre map after the div has been rendered
    // Creamos el mapa
    map = await createMap({
      container: "map",
      center: [-101.95812036696248, 23.732791552256273],
      zoom: 4,
      repaint: true
    });

    // Definimos el GeolocateControl para poder obtener la posición del usuario
    var geolocate = new maplibregl.GeolocateControl({
      positionOptions: {
        enableHighAccuracy: true
      },
      trackUserLocation: false
    });

    // Agregamos el control al mapa
    map.addControl(geolocate);

    // Evento al presionar el boton de ubicación del usuario
    geolocate.on('geolocate', function (data) {
      try {
        keys = Object.keys(locations);
      }
      catch (e) {
        keys = [];
      }

      var valid = false;

      for (var keyIndex in keys) {
        if (valid === false) {
          var singleKey = keys[keyIndex];

          var location = locations[singleKey];
          location.name = singleKey;

          var centerLatitud = location.Latitud;
          var centerLongitud = location.Longitud;

          var centerLatLong = new maplibregl.LngLat(centerLongitud, centerLatitud);
          var userLocationLatLong = new maplibregl.LngLat(data.coords.longitude, data.coords.latitude);

          var distance = centerLatLong.distanceTo(userLocationLatLong) / 1000.0;

          if (distance <= 0.1) {
            valid = true;

            setLatitude(data.coords.latitude);
            setLongitude(data.coords.longitude);

            break;
          }
        }
      }

      //Asignamos el permiso para editar los grids y los botones
      if (payload.header.Estatus === 'Concluido') {
        setActivateButton(false);
        setActivateSaveButton(false);
        setAllowUpdating(false);
        setActivateStopwatch(false);
        setActivateDeleteButton(false);
      }
      else {
        if (valid === false) {
          setActivateButton(false);
          setActivateSaveButton(false);
          setAllowUpdating(false);
          setActivateStopwatch(false);
          setActivateDeleteButton(false);
        }
        else {
          if (payload.header.InicioEvaluacion) {
            setActivateButton(true);
            setActivateSaveButton(true);
            setAllowUpdating(true);
            setActivateStopwatch(false);
            setActivateDeleteButton(false);
          }
          else {
            setActivateButton(false);
            setActivateSaveButton(false);
            setAllowUpdating(false);
            setActivateStopwatch(true);
            setActivateDeleteButton(false)
          }
        }
      }
    });

    map.on('load', function () {
      try {
        keys = Object.keys(locations);
      }
      catch (e) {
        keys = [];
      }

      for (var keyIndex in keys) {
        var singleKey = keys[keyIndex];

        var circle = circles[singleKey];

        var layer = map.getLayer(singleKey);

        if (layer)
          map.removeLayer(singleKey);

        var source = map.getSource(singleKey);

        if (source)
          map.removeSource(singleKey);

        map.addSource(singleKey, {
          type: "geojson",
          data: circle
        });

        //Agregamos el Layer al mapa para mostrarlo
        map.addLayer({
          id: singleKey,
          type: "fill",
          source: singleKey,
          paint: {
            "fill-color": "blue",
            "fill-opacity": 0.2
          }
        });
      }

      //Ejecutamos el Geolocate Control para poder centrar el mapa a la posición del usuario
      geolocate.trigger();
    });

    map.addControl(new maplibregl.NavigationControl(), "top-right");

    try {
      keys = Object.keys(locations);
    }
    catch (e) {
      keys = [];
    }

    for (var keyIndex in keys) {
      var singleKey = keys[keyIndex];

      var location = locations[singleKey];

      new maplibregl.Marker({
      })
        .setLngLat([location.Longitud, location.Latitud])
        .addTo(map);
    }
  };

  async function startStopwatch() {
    try {
      if (!window.confirm('¿Desea iniciar el checklist?'))
        return;

      setVisibleLoadPanel(true);

      var ID = checkListPayload.header.ID;

      //Despues enviamos todo al backend
      let apiName = 'AdminSC';
      let path = '/procesachecklist';

      let myInit = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
        },
        body: {
          ID: ID,
          payload: checkListPayload,
          action: 'startStopwatch',
          username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username
        }
      }

      var payloadPost = await API.post(apiName, path, myInit);

      if (payloadPost.ok === 'true') {
        setVisibleLoadPanel(false);

        setVisibleToast(true);
        setMessageToast('Se inició el checklist con éxito');
        setTypeToast('success')
      }
      else if (payloadPost.ok === 'false') {
        setVisibleLoadPanel(false);

        setVisibleToast(true);
        setMessageToast(payloadPost.message);
        setTypeToast('error')
      }

      editChecklistButton(ID);
    }
    catch (e) {
      setVisibleLoadPanel(false);

      if (e === 'No current user') {
        history.push('/');

        window.location.reload();
      }
    }
  };

  async function editChecklistButton(ID) {
    try {
      if (!window.event.metaKey && !window.event.ctrlKey) {
        setVisibleLoadPanel(true);

        setIsDetail(true);

        setId(ID);

        var payload = await getCheckListPayload(ID);

        if (payload.header.Estatus === 'Sin Afectar') {
          setAllowNew(true);
          setActivateButton(true);
          setActivateStopwatch(false);
          setActivateSaveButton(true);
          setActivateDeleteButton(true);
          setAllowUpdating(false);
        }
        else {
          setAllowNew(false);
        }

        setVisibleLoadPanel(false);

        if (ID && ID !== 'undefined' && payload.header.Estatus !== 'Sin Afectar') {
          await renderMap(payload);
        }
      }
    }
    catch (e) {
      setVisibleLoadPanel(false);

      if (!ID || ID === 'undefined') {
        newButton();
      }
    }
  };

  async function cancelEditButton() {
    setIsDetail(false);
  };

  async function saveButton() {
    try {
      if (checkListPayload.header.Estatus === 'Sin Afectar') {
        if (!checkListPayload.header.PersonalAsignado || checkListPayload.header.PersonalAsignado === '') {
          setVisibleToast(true);
          setMessageToast('Falta especificar el Supervisor')
          setTypeToast('error');
          return;
        }
        if (!checkListPayload.header.SucursalAsignada || checkListPayload.header.SucursalAsignada === '') {
          setVisibleToast(true);
          setMessageToast('Falta especificar la Sucursal')
          setTypeToast('error');
          return;
        }
      }

      setVisibleLoadPanel(true);

      var ID = checkListPayload.header.ID;

      //Primero guardamos los cambios del grid Pedidos
      if (gridPedidosRef.current) {
        var gridPedidos = gridPedidosRef.current.instance;

        gridPedidos.saveEditData();
      }

      //Despues guardamos los cambios del grid Pagos
      if (gridPagosRef.current) {
        var gridPagos = gridPagosRef.current.instance;

        gridPagos.saveEditData();
      }

      //Despues guardamos los cambios del grid Billetes MXN
      if (gridBilleteMXNRef.current) {
        var gridBilleteMXN = gridBilleteMXNRef.current.instance;

        gridBilleteMXN.saveEditData();
      }

      //Despues guardamos los cambios del grid Moneda MXN
      if (gridMonedaMXNRef.current) {
        var gridMonedaMXN = gridMonedaMXNRef.current.instance;

        gridMonedaMXN.saveEditData();
      }

      //Despues guardamos los cambios del grid Billetes MXN
      if (gridBilleteUSDRef.current) {
        var gridBilleteUSD = gridBilleteUSDRef.current.instance;

        gridBilleteUSD.saveEditData();
      }

      //Despues guardamos los cambios del grid Moneda USD
      if (gridMonedaUSDRef.current) {
        var gridMonedaUSD = gridMonedaUSDRef.current.instance;

        gridMonedaUSD.saveEditData();
      }

      //Despues guardamos los cambios del grid Cheque
      if (gridChequeRef.current) {
        var gridCheque = gridChequeRef.current.instance;

        gridCheque.saveEditData();
      }

      //Despues guardamos los cambios del grid Tarjeta
      if (gridTarjetaRef.current) {
        var gridTarjeta = gridTarjetaRef.current.instance;

        gridTarjeta.saveEditData();
      }

      //Despues guardamos los cambios del grid ValeDespensa
      if (gridValeDespensaRef.current) {
        var gridValeDespensa = gridValeDespensaRef.current.instance;

        gridValeDespensa.saveEditData();
      }

      //Despues guardamos los cambios del grid Vale
      if (gridValeRef.current) {
        var gridVale = gridValeRef.current.instance;

        gridVale.saveEditData();
      }

      //Despues guardamos los cambios del grid Gastos Administrativos Gerente
      if (gridGasAdminGerenteRef.current) {
        var gridGasAdminGerente = gridGasAdminGerenteRef.current.instance;

        gridGasAdminGerente.saveEditData();
      }

      //Despues guardamos los cambios del grid Gasolina Gerente
      if (gridGasolinaGerenteRef.current) {
        var gridGasolinaGerente = gridGasolinaGerenteRef.current.instance;

        gridGasolinaGerente.saveEditData();
      }

      //Despues guardamos los cambios del grid Billetes MXN
      if (gridBilleteMXNGerenteRef.current) {
        var gridBilleteMXNGerente = gridBilleteMXNGerenteRef.current.instance;

        gridBilleteMXNGerente.saveEditData();
      }

      //Despues guardamos los cambios del grid Moneda MXN
      if (gridMonedaMXNGerenteRef.current) {
        var gridMonedaMXNGerente = gridMonedaMXNGerenteRef.current.instance;

        gridMonedaMXNGerente.saveEditData();
      }

      //Despues guardamos los cambios del grid Tarjeta Bancaria Gerente
      if (gridTarjetaBancGerenteRef.current) {
        var gridTarjetaBancGerente = gridTarjetaBancGerenteRef.current.instance;

        gridTarjetaBancGerente.saveEditData();
      }

      //Despues guardamos los cambios del grid Vales de Gasolina Gerente
      if (gridValesGasGerenteRef.current) {
        var gridValesGasGerente = gridValesGasGerenteRef.current.instance;

        gridValesGasGerente.saveEditData();
      }

      //Despues enviamos todo al backend
      let apiName = 'AdminSC';
      let path = '/procesachecklist';

      let myInit = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
        },
        body: {
          ID: ID,
          payload: checkListPayload,
          action: 'saveChanges',
          username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username,
          s3Bucket: Storage._config.AWSS3.bucket
        }
      }

      var payloadPost = await API.post(apiName, path, myInit);

      if (payloadPost.ok === 'true') {
        if (!ID) {
          setVisibleToast(true);
          setMessageToast('Se creó el checklist con éxito');
          setTypeToast('success')

          ID = payloadPost.newID;

          editChecklistButton(ID);

          history.push('/ChecklistAud/' + ID.toString());
        }
        else {
          setVisibleToast(true);
          setMessageToast('Se actualizó el checklist con éxito');
          setTypeToast('success')
        }
      }
      else if (payloadPost.ok === 'false') {
        setVisibleToast(true);
        setMessageToast(payloadPost.message);
        setTypeToast('error')
      }

      editChecklistButton(ID);

      setVisibleLoadPanel(false);
    }
    catch (e) {
      setVisibleLoadPanel(false);

      if (e === 'No current user') {
        history.push('/');

        window.location.reload();
      }
    }
  };

  async function confirmButton() {
    try {
      if (checkListPayload.header.Estatus === 'Sin Afectar') {
        if (!checkListPayload.header.PersonalAsignado || checkListPayload.header.PersonalAsignado === '') {
          setVisibleToast(true);
          setMessageToast('Falta especificar el Supervisor')
          setTypeToast('error');
          return;
        }
        if (!checkListPayload.header.SucursalAsignada || checkListPayload.header.SucursalAsignada === '') {
          setVisibleToast(true);
          setMessageToast('Falta especificar la Sucursal')
          setTypeToast('error');
          return;
        }
      }

      if (!window.confirm('¿Desea confirmar el checklist?'))
        return;

      setVisibleLoadPanel(true);

      var ID = checkListPayload.header.ID;

      //Primero guardamos los cambios del grid Pedidos
      if (gridPedidosRef.current) {
        var gridPedidos = gridPedidosRef.current.instance;

        gridPedidos.saveEditData();
      }

      //Despues guardamos los cambios del grid Pagos
      if (gridPagosRef.current) {
        var gridPagos = gridPagosRef.current.instance;

        gridPagos.saveEditData();
      }

      //Despues guardamos los cambios del grid Billetes MXN
      if (gridBilleteMXNRef.current) {
        var gridBilleteMXN = gridBilleteMXNRef.current.instance;

        gridBilleteMXN.saveEditData();
      }

      //Despues guardamos los cambios del grid Moneda MXN
      if (gridMonedaMXNRef.current) {
        var gridMonedaMXN = gridMonedaMXNRef.current.instance;

        gridMonedaMXN.saveEditData();
      }

      //Despues guardamos los cambios del grid Billetes MXN
      if (gridBilleteUSDRef.current) {
        var gridBilleteUSD = gridBilleteUSDRef.current.instance;

        gridBilleteUSD.saveEditData();
      }

      //Despues guardamos los cambios del grid Moneda USD
      if (gridMonedaUSDRef.current) {
        var gridMonedaUSD = gridMonedaUSDRef.current.instance;

        gridMonedaUSD.saveEditData();
      }

      //Despues guardamos los cambios del grid Cheque
      if (gridChequeRef.current) {
        var gridCheque = gridChequeRef.current.instance;

        gridCheque.saveEditData();
      }

      //Despues guardamos los cambios del grid Tarjeta
      if (gridTarjetaRef.current) {
        var gridTarjeta = gridTarjetaRef.current.instance;

        gridTarjeta.saveEditData();
      }

      //Despues guardamos los cambios del grid ValeDespensa
      if (gridValeDespensaRef.current) {
        var gridValeDespensa = gridValeDespensaRef.current.instance;

        gridValeDespensa.saveEditData();
      }

      //Despues guardamos los cambios del grid Vale
      if (gridValeRef.current) {
        var gridVale = gridValeRef.current.instance;

        gridVale.saveEditData();
      }

      //Despues guardamos los cambios del grid Gastos Administrativos Gerente
      if (gridGasAdminGerenteRef.current) {
        var gridGasAdminGerente = gridGasAdminGerenteRef.current.instance;

        gridGasAdminGerente.saveEditData();
      }

      //Despues guardamos los cambios del grid Gasolina Gerente
      if (gridGasolinaGerenteRef.current) {
        var gridGasolinaGerente = gridGasolinaGerenteRef.current.instance;

        gridGasolinaGerente.saveEditData();
      }

      //Despues guardamos los cambios del grid Billetes MXN
      if (gridBilleteMXNGerenteRef.current) {
        var gridBilleteMXNGerente = gridBilleteMXNGerenteRef.current.instance;

        gridBilleteMXNGerente.saveEditData();
      }

      //Despues guardamos los cambios del grid Moneda MXN
      if (gridMonedaMXNGerenteRef.current) {
        var gridMonedaMXNGerente = gridMonedaMXNGerenteRef.current.instance;

        gridMonedaMXNGerente.saveEditData();
      }

      //Despues guardamos los cambios del grid Tarjeta Bancaria Gerente
      if (gridTarjetaBancGerenteRef.current) {
        var gridTarjetaBancGerente = gridTarjetaBancGerenteRef.current.instance;

        gridTarjetaBancGerente.saveEditData();
      }

      //Despues guardamos los cambios del grid Vales de Gasolina Gerente
      if (gridValesGasGerenteRef.current) {
        var gridValesGasGerente = gridValesGasGerenteRef.current.instance;

        gridValesGasGerente.saveEditData();
      }

      //Despues enviamos todo al backend
      let apiName = 'AdminSC';
      let path = '/procesachecklist';

      let myInit = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
        },
        body: {
          ID: ID,
          payload: checkListPayload,
          action: 'apply',
          username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username
        }
      }

      var payloadPost = await API.post(apiName, path, myInit);

      if (payloadPost.ok === 'true') {
        if (!ID) {
          setVisibleLoadPanel(false);

          setVisibleToast(true);
          setMessageToast('Checklist creado con éxito');
          setTypeToast('success')

          ID = payloadPost.newID;

          editChecklistButton(ID);

          history.push('/ChecklistAud/' + ID.toString());
        }
        else {
          setVisibleLoadPanel(false);

          setVisibleToast(true);
          setMessageToast('Checklist Concluido con éxito');
          setTypeToast('success')

          setAllowUpdating(false);
          setActivateButton(false);
          setActivateSaveButton(false);

          editChecklistButton(ID);

          history.push('/ChecklistAud/' + ID.toString());
        }
      }
      else if (payloadPost.ok === 'false') {
        setVisibleLoadPanel(false);

        setVisibleToast(true);
        setMessageToast(payloadPost.message);
        setTypeToast('error')
      }
    }
    catch (e) {
      setVisibleLoadPanel(false);

      if (e === 'No current user') {
        history.push('/');

        window.location.reload();
      }
    }
  };

  function printButton() {
    var baseUrl = window.location.href.split('/')[0];

    window.open(baseUrl + "/#/PdfRender?type=checklistaud&id=" + id, "_blank");
  };

  function editCheckList(data) {
    return (
      <Link to={`/ChecklistAud/${data.data.ID}`}>
        <button class="bg-blue-500 hover:bg-blue-700 text-white  py-1 px-3 rounded" onClick={() => editChecklistButton(data.data.ID)}><FontAwesomeIcon icon={faPencilAlt} /> Editar</button>
      </Link>
    )
  }

  function onAutoExpandAllChanged() {
    setAutoExpandAll(!autoExpandAll)
  };

  function onAutoExpandAllChecklistChanged() {
    setAutoExpandAllChecklist(!autoExpandAllChecklist)
  };

  async function getSucursales() {
    let apiName = 'AdminSC';
    let path = '/getcatalogo';

    let myInit = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
      },
      queryStringParameters: {
        type: 'Sucursal',
        username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username
      }
    }

    var sucursalesGet = await API.get(apiName, path, myInit);

    var sucursalesTmp = [];
    for (var sucursalesGetIndex in sucursalesGet) {
      var singleSucursalesGet = sucursalesGet[sucursalesGetIndex];

      var singleSucursal = {
        name: singleSucursalesGet.Nombre,
        value: singleSucursalesGet.Sucursal.toString()
      };

      sucursalesTmp.push(singleSucursal);
    }

    setSucursales(sucursalesTmp);

    return sucursalesTmp;
  };

  async function getPersonal() {
    let apiName = 'AdminSC';
    let path = '/getcatalogo';

    let myInit = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
      },
      queryStringParameters: {
        type: 'Personal',
        includeInactive: true,
        username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username
      }
    }

    var personalesGet = await API.get(apiName, path, myInit);

    var personalesTmp = [];
    for (var personalesGetIndex in personalesGet) {
      var singlePersonalesGet = personalesGet[personalesGetIndex];

      var singlePersonal = {
        name: singlePersonalesGet.ApellidoPaterno + ' ' + singlePersonalesGet.ApellidoMaterno + ' ' + singlePersonalesGet.Nombre,
        value: singlePersonalesGet.Personal
      };

      personalesTmp.push(singlePersonal);
    }

    setPersonales(personalesTmp);

    return personalesTmp;
  };

  var sucursalDataSource = new DataSource({
    paginate: true,
    store: {
      type: "array",
      key: "Sucursal",
      data: sucursales
    }
  });

  var personalDataSource = new DataSource({
    paginate: true,
    store: {
      type: "array",
      key: "Personal",
      data: personales
    }
  });

  var fechaEmisionEditorOptions = { width: '100%', disabled: !allowNew, text: 'Fecha Emisión' };
  var movimientoEditorOptions = { width: '100%', readOnly: true };
  var estatusEditorOptions = { width: '100%', readOnly: true };

  //Opciones Sucursal
  try {
    var sucursalAsignadaEditorOptions = { disabled: !allowNew, dataSource: sucursalDataSource, displayExpr: 'name', valueExpr: 'value', searchEnabled: true, value: (checkListPayload.header.SucursalAsignada.toString() ? checkListPayload.header.SucursalAsignada.toString() : ''), width: '100%' };
  }
  catch (e) {
    var sucursalAsignadaEditorOptions = { disabled: !allowNew, dataSource: sucursalDataSource, displayExpr: 'name', valueExpr: 'value', searchEnabled: true, value: '', width: '100%' };
  }

  //Opciones Personal
  try {
    var personalAsignadoEditorOptions = { disabled: !allowNew, dataSource: personalDataSource, displayExpr: 'name', valueExpr: 'value', searchEnabled: true, value: (checkListPayload.header.PersonalAsignado ? checkListPayload.header.PersonalAsignado : ''), width: '100%' };
  }
  catch (e) {
    var personalAsignadoEditorOptions = { disabled: !allowNew, dataSource: personalDataSource, displayExpr: 'name', valueExpr: 'value', searchEnabled: true, value: '', width: '100%' };
  }

  var nombreEditorOptions = { width: '100%', readOnly: true };

  var nombreSupervisorEditorOptions = { width: '100%', readOnly: true };
  var inicioEvaluacionEditorOptions = { width: '100%', readOnly: true };
  var finEvaluacionEditorOptions = { width: '100%', readOnly: true };
  var duracionEvaluacionEditorOptions = { width: '100%', readOnly: true };

  var noVendedoresOptions = { format: { type: 'fixedPoint', precision: 0 }, width: '100%' };
  var noAuxiliaresOptions = { format: { type: 'fixedPoint', precision: 0 }, width: '100%' };

  var marcaEditorOptions = { width: '100%' };
  var modeloEditorOptions = { width: '100%' };
  var numeroCelularEditorOptions = { width: '100%', mask: '000-0000-000' };

  var importeGridEditorOptions = { format: { type: 'currency', currency: 'MXN', precision: 2 }, width: '100%' };
  var importeEditorOptions = { format: { type: 'currency', currency: 'MXN', precision: 2 }, width: '100%', readOnly: true };
  var cantidadEditorOptions = { format: { type: 'fixedPoint', precision: 0 } };

  var arqDiferenciaEditorOptions = { format: { type: 'currency', currency: 'MXN', precision: 2 }, width: '100%', disabled: !conDiferenciaCajaMenor };

  var arqGteReposicionMontoEditorOptions = { format: { type: 'currency', currency: 'MXN', precision: 2 }, width: '100%', disabled: !conReposicionCajaMenor };

  var arqGteFaltanteEditorOptions = { format: { type: 'currency', currency: 'MXN', precision: 2 }, width: '100%', disabled: !conDiferenciaGteCajaChica };

  var arqDineroDevueltoEditorOptions = { width: '100%' };

  var uppercaseEditorOptions = { inputAttr: { 'style': 'text-transform: uppercase' } };

  var uppercaseCaratulaAudEditorOptions = { inputAttr: { 'style': 'text-transform: uppercase' }, width: '100%' };

  var resultadoEditorOptions = { height: 130, width: '100%' };

  const denominacionBilleteMXN = [
    { name: '$1,000.00', value: '$1,000.00' },
    { name: '$500.00', value: '$500.00' },
    { name: '$200.00', value: '$200.00' },
    { name: '$100.00', value: '$100.00' },
    { name: '$50.00', value: '$50.00' },
    { name: '$20.00', value: '$20.00' }
  ];

  const denominacionMonedaMXN = [
    { name: '$20.00', value: '$20.00' },
    { name: '$10.00', value: '$10.00' },
    { name: '$5.00', value: '$5.00' },
    { name: '$2.00', value: '$2.00' },
    { name: '$1.00', value: '$1.00' },
    { name: 'Centavos', value: 'Centavos' }
  ];

  const denominacionBilleteUSD = [
    { name: '$100.00', value: '$100.00' },
    { name: '$50.00', value: '$50.00' },
    { name: '$20.00', value: '$20.00' },
    { name: '$10.00', value: '$10.00' },
    { name: '$5.00', value: '$5.00' },
    { name: '$2.00', value: '$2.00' },
    { name: '$1.00', value: '$1.00' }
  ];

  const denominacionMonedaUSD = [
    { name: '$1.00', value: '$1.00' },
    { name: '$0.50', value: '$0.50' },
    { name: '$0.25', value: '$0.25' },
    { name: '$0.10', value: '$0.10' },
    { name: '$0.05', value: '$0.05' },
    { name: '$0.01', value: '$0.01' }
  ];

  useEffect(() => {
    window.addEventListener('popstate', (e) => {
      var hash;

      try {
        hash = e.target.location.hash;
      }
      catch (e) {
        hash = '';
      }

      if (hash === '#/ChecklistAud') {
        history.push('/ChecklistAud');

        window.location.reload();
      }
    });

    async function initialize() {
      // setVisibleLoadPanel(true);

      //Obtenemos el listado de Sucursales
      var sucursalList = await getSucursales();

      //Obtenemos el listado de Personales
      var personalList = await getPersonal();

      // setVisibleLoadPanel(false);
    }

    initialize();

    //En caso de solicitar un ID especifico, redireccionamos a ese ID
    if (!props.match.isExact) {
      var urlID = props.location.pathname.replace('/ChecklistAud/', '');

      editChecklistButton(urlID);
    }

    // eslint-disable-next-line
  }, []);

  function overrideOnValueChanged(e) {
    //Deshabilitamos la escritura en los campos tipo select
    if (e.parentType === 'dataRow' && (e.dataField === 'Evaluacion' || e.dataField === 'Uniforme' || e.dataField === 'Presentacion' || e.dataField === 'Disposicion' || e.dataField === 'Actitud' || e.dataField === 'Concepto' || e.dataField === 'Responsable' || e.dataField === 'Trabajador' || e.dataField === 'Bienvenida' || e.dataField === 'DeteccionNecesidades' || e.dataField === 'LaborVenta' || e.dataField === 'ConocimientoProducto' || e.dataField === 'ManejoObjeciones' || e.dataField === 'Cierre' || e.dataField === 'ProductosAdicionales' || e.dataField === 'Denominacion')) {
      e.editorOptions.searchEnabled = false;
    }

    //Hacemos la data persistente en cuanto el usuario escriba en el campo
    // if (e.parentType === "dataRow") {
    //   e.editorOptions.valueChangeEvent = "input";
    // }
  }

  function overrideOnValueChangedArqueo(e) {
    //Deshabilitamos la escritura en los campos tipo select
    if (e.parentType === 'dataRow' && (e.dataField === 'Evaluacion' || e.dataField === 'Uniforme' || e.dataField === 'Presentacion' || e.dataField === 'Disposicion' || e.dataField === 'Actitud' || e.dataField === 'Concepto' || e.dataField === 'Responsable' || e.dataField === 'Trabajador' || e.dataField === 'Bienvenida' || e.dataField === 'DeteccionNecesidades' || e.dataField === 'LaborVenta' || e.dataField === 'ConocimientoProducto' || e.dataField === 'ManejoObjeciones' || e.dataField === 'Cierre' || e.dataField === 'ProductosAdicionales' || e.dataField === 'Denominacion')) {
      e.editorOptions.searchEnabled = false;
    }

    //Hacemos la data persistente en cuanto el usuario escriba en el campo
    // if (e.parentType === "dataRow") {
    //   e.editorOptions.valueChangeEvent = "input";
    // }

    if (e.parentType === 'dataRow' && (e.dataField === 'Importe')) {
      e.editorOptions.disabled = true;
    }
  }

  function actualizaImporteArqueo(e) {
    var Denominacion = e.newData.Denominacion;
    var Cantidad = e.newData.Cantidad;

    if (!Denominacion)
      Denominacion = e.oldData.Denominacion;

    if (!Cantidad)
      Cantidad = e.oldData.Cantidad;

    if (Denominacion !== 'Centavos')
      Denominacion = parseFloat(Denominacion.replace('$', '').replace(/,/g, ''));
    else
      Denominacion = 0.01;

    e.newData.Importe = Cantidad * Denominacion;
  }

  function insertaImporteArqueo(e) {
    var Denominacion = e.data.Denominacion;
    var Cantidad = e.data.Cantidad;

    if (Denominacion !== 'Centavos')
      Denominacion = parseFloat(Denominacion.replace('$', '').replace(/,/g, ''));
    else
      Denominacion = 0.01;

    e.data.Importe = Cantidad * Denominacion;
  }

  function actualizaImporteCajaMenor(e) {
    var importePedidos;
    var importePagos;
    var total;

    var gridPedidos;
    var gridPagos;

    //Obtenemos el total del Grid Pedidos
    if (gridPedidosRef.current) {
      gridPedidos = gridPedidosRef.current.instance;

      importePedidos = parseFloat(gridPedidos.getTotalSummaryValue('Importe'));
    }
    else {
      importePedidos = 0;
    }

    //Obtenemos el total del Grid Pagos
    if (gridPagosRef.current) {
      gridPagos = gridPagosRef.current.instance;

      importePagos = parseFloat(gridPagos.getTotalSummaryValue('Importe'));
    }
    else {
      importePagos = 0;
    }

    // Actualizamos el total
    if (datosArqueoRef.current) {
      var formDatosArqueo = datosArqueoRef.current.instance;

      formDatosArqueo.updateData('ArqImportePedidos', importePedidos);
      formDatosArqueo.updateData('ArqImportePagos', importePagos);

      formDatosArqueo.updateData('ArqValorTotalSistema', importePedidos + importePagos);
    }
  }

  function actualizaOtroImporteCajaMenor(e) {
    var importeBilleteMXN;
    var importeMonedaMXN;
    var importeBilleteUSD;
    var importeMonedaUSD;
    var importeCheque;
    var importeTarjeta;
    var importeValeDespensa;
    var importeVale;
    var total;

    var gridBilleteMXN;
    var gridMonedaMXN;
    var gridBilleteUSD;
    var gridMonedaUSD;
    var gridCheque;
    var gridTarjeta;
    var gridValeDespensa;
    var gridVale;

    //Obtenemos el total del Grid Billete MXN
    if (gridBilleteMXNRef.current) {
      gridBilleteMXN = gridBilleteMXNRef.current.instance;

      importeBilleteMXN = parseFloat(gridBilleteMXN.getTotalSummaryValue('Importe'));
    }
    else {
      importeBilleteMXN = 0;
    }

    //Obtenemos el total del Grid Moneda MXN
    if (gridMonedaMXNRef.current) {
      gridMonedaMXN = gridMonedaMXNRef.current.instance;

      importeMonedaMXN = parseFloat(gridMonedaMXN.getTotalSummaryValue('Importe'));
    }
    else {
      importeMonedaMXN = 0;
    }

    //Obtenemos el total del Grid Billete USD
    if (gridBilleteUSDRef.current) {
      gridBilleteUSD = gridBilleteUSDRef.current.instance;

      importeBilleteUSD = parseFloat(gridBilleteUSD.getTotalSummaryValue('Importe'));
    }
    else {
      importeBilleteUSD = 0;
    }

    //Obtenemos el total del Grid Moneda USD
    if (gridMonedaUSDRef.current) {
      gridMonedaUSD = gridMonedaUSDRef.current.instance;

      importeMonedaUSD = parseFloat(gridMonedaUSD.getTotalSummaryValue('Importe'));
    }
    else {
      importeMonedaUSD = 0;
    }

    //Obtenemos el total del Grid Cheque
    if (gridChequeRef.current) {
      gridCheque = gridChequeRef.current.instance;

      importeCheque = parseFloat(gridCheque.getTotalSummaryValue('Importe'));
    }
    else {
      importeCheque = 0;
    }

    //Obtenemos el total del Grid Tarjeta
    if (gridTarjetaRef.current) {
      gridTarjeta = gridTarjetaRef.current.instance;

      importeTarjeta = parseFloat(gridTarjeta.getTotalSummaryValue('Importe'));
    }
    else {
      importeTarjeta = 0;
    }

    //Obtenemos el total del Grid Vale Despensa
    if (gridValeDespensaRef.current) {
      gridValeDespensa = gridValeDespensaRef.current.instance;

      importeValeDespensa = parseFloat(gridValeDespensa.getTotalSummaryValue('Importe'));
    }
    else {
      importeValeDespensa = 0;
    }

    //Obtenemos el total del Grid Vale
    if (gridValeRef.current) {
      gridVale = gridValeRef.current.instance;

      importeVale = parseFloat(gridVale.getTotalSummaryValue('Importe'));
    }
    else {
      importeVale = 0;
    }

    // Actualizamos el total
    if (datosArqueoRef.current) {
      var formDatosArqueo = datosArqueoRef.current.instance;

      formDatosArqueo.updateData('ArqEfectivoMXN', importeBilleteMXN + importeMonedaMXN);
      formDatosArqueo.updateData('ArqEfectivoUSD', importeBilleteUSD + importeMonedaUSD);
      formDatosArqueo.updateData('ArqCheques', importeCheque);
      formDatosArqueo.updateData('ArqTarjetaBancaria', importeTarjeta);
      formDatosArqueo.updateData('ArqVales', importeVale + importeValeDespensa);
      formDatosArqueo.updateData('ArqValorTotalCajaMenor', importeBilleteMXN + importeMonedaMXN + importeBilleteUSD + importeMonedaUSD + importeCheque + importeTarjeta + importeVale + importeValeDespensa);
    }
  }

  function actualizaGastosGteCajaChica(e) {
    var importeAdministrativo;
    var importeGasolina;
    var total;

    var gridGasAdminGerente;
    var gridGasolinaGerente;

    //Obtenemos el total del Grid Gastos Administrativos
    if (gridGasAdminGerenteRef.current) {
      gridGasAdminGerente = gridGasAdminGerenteRef.current.instance;

      importeAdministrativo = parseFloat(gridGasAdminGerente.getTotalSummaryValue('Importe'));
    }
    else {
      importeAdministrativo = 0;
    }

    //Obtenemos el total del Grid Gasolina
    if (gridGasolinaGerenteRef.current) {
      gridGasolinaGerente = gridGasolinaGerenteRef.current.instance;

      importeGasolina = parseFloat(gridGasolinaGerente.getTotalSummaryValue('Importe'));
    }
    else {
      importeGasolina = 0;
    }

    // Actualizamos el total
    if (datosArqueoGerenteRef.current) {
      var formDatosArqueoGerente = datosArqueoGerenteRef.current.instance;

      formDatosArqueoGerente.updateData('ArqGteGastosAdmin', importeAdministrativo);
      formDatosArqueoGerente.updateData('ArqGteGasolina', importeGasolina);

      formDatosArqueoGerente.updateData('ArqGteGastosTotal', importeAdministrativo + importeGasolina);
    }
  }

  function actualizaDictamenGteCajaChica(e) {
    var importeBilleteMXNGerente;
    var importeMonedaMXNGerente;
    var importeTarjetaBancGerente;
    var importeValeGasGerente;
    var total;

    var gridBilleteMXNGerente;
    var gridMonedaMXNGerente;
    var gridTarjetaBancGerente;
    var gridValesGasGerente;

    //Obtenemos el total del Grid Billete MXN Gerente
    if (gridBilleteMXNGerenteRef.current) {
      gridBilleteMXNGerente = gridBilleteMXNGerenteRef.current.instance;

      importeBilleteMXNGerente = parseFloat(gridBilleteMXNGerente.getTotalSummaryValue('Importe'));
    }
    else {
      importeBilleteMXNGerente = 0;
    }

    //Obtenemos el total del Grid Moneda MXN Gerente
    if (gridMonedaMXNGerenteRef.current) {
      gridMonedaMXNGerente = gridMonedaMXNGerenteRef.current.instance;

      importeMonedaMXNGerente = parseFloat(gridMonedaMXNGerente.getTotalSummaryValue('Importe'));
    }
    else {
      importeMonedaMXNGerente = 0;
    }

    //Obtenemos el total del Grid Tarjeta Banc Gerente
    if (gridTarjetaBancGerenteRef.current) {
      gridTarjetaBancGerente = gridTarjetaBancGerenteRef.current.instance;

      importeTarjetaBancGerente = parseFloat(gridTarjetaBancGerente.getTotalSummaryValue('Importe'));
    }
    else {
      importeTarjetaBancGerente = 0;
    }

    //Obtenemos el total del Grid Vales Gas Gerente
    if (gridValesGasGerenteRef.current) {
      gridValesGasGerente = gridValesGasGerenteRef.current.instance;

      importeValeGasGerente = parseFloat(gridValesGasGerente.getTotalSummaryValue('Importe'));
    }
    else {
      importeValeGasGerente = 0;
    }

    // Actualizamos el total
    if (datosArqueoGerenteRef.current) {
      var formDatosArqueoGerente = datosArqueoGerenteRef.current.instance;

      formDatosArqueoGerente.updateData('ArqGteEfectivoMXN', importeBilleteMXNGerente + importeMonedaMXNGerente);
      formDatosArqueoGerente.updateData('ArqGteTarjetaBancaria', importeTarjetaBancGerente);
      formDatosArqueoGerente.updateData('ArqGteValeGasolina', importeValeGasGerente);

      formDatosArqueoGerente.updateData('ArqGteValorTotalCaja', importeBilleteMXNGerente + importeMonedaMXNGerente + importeTarjetaBancGerente + importeValeGasGerente);
    }
  }

  async function uploadChunk(file, uploadInfo) {
    try {
      var responseS3 = await Storage.put("/checklist/" + id + "/" + file.name, uploadInfo.chunkBlob, {
        contentType: file.type,
        ACL: 'public-read'
      });

      //Obtenemos el nombre del bucket
      let bucketName = Storage._config.AWSS3.bucket;


      let apiName = 'AdminSC';
      let path = '/uploadattachment';

      let myInit = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
        },
        body: {
          action: 'register',
          bucketName: bucketName,
          type: 'checklist',
          id: id,
          Key: "/checklist/" + id + "/" + file.name,
          fileName: file.name,
          username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username
        }
      }

      var attachmentsResult = await API.post(apiName, path, myInit);

      //Obtenemos los attachments
      var attachmentsGet = await Storage.list("/checklist/" + id);
      var attachmentsAux = [];

      for (var attachmentsGetIndex in attachmentsGet) {
        var singleAttachmentsGet = attachmentsGet[attachmentsGetIndex];

        var singleAttachment = {
          name: singleAttachmentsGet.key.split('/').pop(),
          isDirectory: false,
          size: singleAttachmentsGet.size,
        };

        attachmentsAux.push(singleAttachment);
      }

      setAttachments(attachmentsAux);

    }
    catch (err) {
      console.log("Error uploading file:", err);
    }
  }

  async function onAttachmentDeleting(e) {
    var fileName = e.item.key;

    //Primero borramos el archivo de S3
    var attachmentsDelete = await Storage.remove("/checklist/" + id + "/" + fileName);

    //Despues borramos el attachment en Intelisis
    let bucketName = Storage._config.AWSS3.bucket;

    let apiName = 'AdminSC';
    let path = '/uploadattachment';

    let myInit = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
      },
      body: {
        action: 'delete',
        bucketName: bucketName,
        type: 'checklist',
        id: id,
        Key: "/checklist/" + id + "/" + fileName,
        fileName: fileName,
        username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username
      }
    }

    var attachmentsResult = await API.post(apiName, path, myInit);

    //Finalmente sincronizamos el state
    var attachmentsGet = await Storage.list("/checklist/" + id);
    var attachmentsAux = [];

    for (var attachmentsGetIndex in attachmentsGet) {
      var singleAttachmentsGet = attachmentsGet[attachmentsGetIndex];

      var singleAttachment = {
        name: singleAttachmentsGet.key.split('/').pop(),
        isDirectory: false,
        size: singleAttachmentsGet.size,
      };

      attachmentsAux.push(singleAttachment);
    }

    setAttachments(attachmentsAux);
  };

  function downloadBlob(blob, filename) {
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename || 'download';
    const clickHandler = () => {
      setTimeout(() => {
        URL.revokeObjectURL(url);
        a.removeEventListener('click', clickHandler);
      }, 150);
    };

    a.addEventListener('click', clickHandler, false);

    a.click();

    return a;
  };

  async function onItemDownloading(e) {
    e.cancel = true;

    const downloadFileResponse = await Storage.get("/checklist/" + id + "/" + e.item.name, { download: true });

    downloadBlob(downloadFileResponse.Body, e.item.name);
  };

  function openBlob(blob, filename) {
    const url = URL.createObjectURL(blob);

    window.open(url, "_blank");
  };

  async function onAttachmentOpen(e) {
    const downloadFileResponse = await Storage.get("/checklist/" + id + "/" + e.file.name, { download: true });

    openBlob(downloadFileResponse.Body, e.file.name);
  };


  function onHidingToast() {
    setVisibleToast(false);
  }

  function refreshButton() {
    if (gridTableroRef.current) {
      var gridTablero = gridTableroRef.current.instance;

      gridTablero.refresh();
    }
  }

  async function newButton() {
    try {
      setVisibleLoadPanel(true);

      let apiName = 'AdminSC';
      let path = '/procesachecklist';

      let myInit = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
        },
        queryStringParameters: {
          personal: props.personal,
          checklistAccess: props.checklistAccess,
          action: 'new',
          mov: 'Check List Aud',
          username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username
        }
      };

      var payloadNew = await API.get(apiName, path, myInit);

      setCheckListPayload(payloadNew);

      setAttachments([]);

      setIsDetail(true);
      setId(undefined);
      setAllowNew(true);

      setActivateButton(true);
      setActivateStopwatch(false);
      setActivateSaveButton(true);
      setActivateDeleteButton(true);
      setAllowUpdating(false);

      setVisibleLoadPanel(false);
    }
    catch (e) {
      setVisibleLoadPanel(false);
      if (e === 'No current user') {
        history.push('/');

        window.location.reload();
      }
    }
  };

  async function deleteButton() {
    try {
      if (!window.confirm('¿Desea eliminar el checklist?'))
        return;

      setVisibleLoadPanel(true);

      var ID = checkListPayload.header.ID;

      if (!ID) {
        //Vamos al tablero de control
        history.push('/ChecklistAud');
        return;
      }

      let apiName = 'AdminSC';
      let path = '/procesachecklist';

      let myInit = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
        },
        body: {
          ID: ID,
          payload: checkListPayload,
          action: 'delete',
          username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username
        }
      }

      var payloadPost = await API.post(apiName, path, myInit);

      if (payloadPost.ok === 'true') {
        setVisibleLoadPanel(false);

        setVisibleToast(true);
        setMessageToast('Checklist eliminado con éxito');
        setTypeToast('success')

        setIsDetail(false);

        //Vamos al tablero de control
        history.push('/ChecklistAud');
      }
      else if (payloadPost.ok === 'false') {
        setVisibleLoadPanel(false);

        setVisibleToast(true);
        setMessageToast(payloadPost.message);
        setTypeToast('error')
      }
    }
    catch (e) {
      setVisibleLoadPanel(false);

      if (e === 'No current user') {
        history.push('/');

        window.location.reload();
      }
    }
  }

  function onExporting(e) {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Datos');

    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Datos.xlsx');
      });
    });
    e.cancel = true;
  };

  return (
    <div>
      {isDetail === false ? (
        <div class="bg-gray-200 m-2">
          <h1 class="font-sans inline-block text-xl font-bold text-gray-700 leading-8 align-top mb-1"><FontAwesomeIcon icon={faList} size="lg" /> Checklist Auditores</h1>
        </div>
      ) :
        (
          <div class="bg-gray-200 m-2">
            <h1 class="font-sans inline-block text-xl font-bold text-gray-700 leading-8 align-top mb-1"><FontAwesomeIcon icon={faUserTie} size="xl" /> {(checkListPayload.header ? checkListPayload.header.Movimiento : 'Check List Aud')}</h1>
            <TransactionStatus {...props} Estatus={checkListPayload.header.Estatus} />
          </div>
        )}

      <LoadPanel
        shadingColor="rgba(0,0,0,0.4)"
        visible={visibleLoadPanel}
        showIndicator={true}
        message="Por favor espere..."
        shading={true}
        showPane={true}
        hideOnOutsideClick={false}
      />

      <Toast
        visible={visibleToast}
        message={messageToast}
        type={typeToast}
        onHiding={onHidingToast}
        displayTime={5000}
        width={'auto'}
        position={{
          my: 'top center',
          at: 'top center',
          of: window,
          offset: '0 130'
        }}
      />

      <div class="bg-gray-200 m-2">
        {isDetail === false ? (
          <div class="bg-white p-3 shadow-xl">

            {props.newChecklistAud.toString() === 'true' ?
              (
                <div>
                  <Link to="/ChecklistAud/undefined">
                    <button class="mb-2 bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded" onClick={() => newButton()}><FontAwesomeIcon icon={faFileAlt} /> Nuevo Checklist</button>
                  </Link>

                  <button onClick={refreshButton} type="submit" class="ml-4 mb-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                    <FontAwesomeIcon icon={faArrowsRotate} /> Actualizar
                  </button>
                </div>
              ) :
              (
                <div>
                  <button onClick={refreshButton} type="submit" class="mb-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                    <FontAwesomeIcon icon={faArrowsRotate} /> Actualizar
                  </button>
                </div>
              )}

            <div className="options mb-2">
              <div className="option">
                <CheckBox text="Expandir todos los grupos"
                  value={autoExpandAll}
                  onValueChanged={onAutoExpandAllChanged}>
                </CheckBox>
              </div>
            </div>
            <DataGrid
              height={800}
              id="gridContainer"
              columnsAutoWidth={true}
              filterBuilder={filterBuilder}
              dataSource={store}
              defaultFilterValue={filterValue}
              allowColumnReordering={true}
              allowColumnResizing={true}
              columnResizingMode={"widget"}
              showBorders={true}
              rowAlternationEnabled={true}
              showRowLines={true}
              showColumnLines={true}
              remoteOperations={{ filtering: true }}
              ref={gridTableroRef}
              onExporting={onExporting}
            >
              <Scrolling rowRenderingMode='virtual'></Scrolling>
              <Paging defaultPageSize={10} />
              <Pager
                visible={true}
                allowedPageSizes={allowedPageSizes}
                displayMode='compact'
                showPageSizeSelector={true}
                showInfo={true}
                showNavigationButtons={true} />
              <FilterRow visible={true} />
              <FilterPanel visible={true} />
              <FilterBuilderPopup position={filterBuilderPopupPosition} />

              <HeaderFilter visible={true} />

              <GroupPanel visible={true} />
              <SearchPanel visible={true} />
              <ColumnChooser
                enabled={true}
                mode='select'
              >
                <ColumnChooserSearch
                  enabled={true}
                />

                <ColumnChooserSelection
                  allowSelectAll={true}
                  selectByClick={true}
                  recursive={true}
                />
              </ColumnChooser>
              <Grouping autoExpandAll={autoExpandAll} />

              <StateStoring enabled={true} type="localStorage" storageKey="gridContainerChecklistAud" />

              <Export enabled={true} />

              <Column caption="" alignment="center" width={100} cellRender={editCheckList} />

              <Column dataField="MovID" dataType="string" width={190} caption="Folio" />
              <Column dataField="FechaEmision" caption="Fecha Requerida" dataType="date" width={150} />
              <Column dataField="Estatus" dataType="string" width={150} />
              <Column caption="Nombre Sucursal" dataField="Nombre" dataType="string" groupIndex={1} />
              <Column caption="Nombre Auditor" dataField="NombreSupervisor" dataType="string" width={350} />
              <Column caption="Plaza" dataField="Plaza" dataType="string" width={150} groupIndex={0} />
            </DataGrid>
          </div>
        ) : (<div />)}
        <Route exact path="/ChecklistAud/:ID" render={({ match }) => {
          return (
            <div class="bg-gray-200">
              {isDetail.toString() === 'true' ?
                (
                  <div>
                    <Link to="/ChecklistAud">
                      <button onClick={cancelEditButton} type="submit" class="mb-4 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
                        <FontAwesomeIcon icon={faArrowRotateLeft} /> Regresar
                      </button>
                    </Link>

                    {activateSaveButton.toString() === 'true' ?
                      (
                        <button onClick={saveButton} type="submit" class="ml-4 mb-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                          <FontAwesomeIcon icon={faSave} /> Guardar Cambios
                        </button>
                      ) : (
                        <button disabled onClick={saveButton} type="submit" class="ml-4 mb-4 cursor-not-allowed disabled:opacity-75 bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded">
                          <FontAwesomeIcon icon={faSave} /> Guardar Cambios
                        </button>
                      )}

                    {activateButton.toString() === 'true' ?
                      (
                        <button onClick={confirmButton} type="submit" class="ml-4 mb-4 bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">
                          <FontAwesomeIcon icon={faClipboardCheck} /> Confirmar Checklist
                        </button>
                      ) : (
                        <button disabled onClick={confirmButton} type="submit" class="ml-4 mb-4 cursor-not-allowed disabled:opacity-75 bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded">
                          <FontAwesomeIcon icon={faClipboardCheck} /> Confirmar Checklist
                        </button>
                      )}

                    {activateStopwatch.toString() === 'true' ?
                      (
                        <button onClick={startStopwatch} type="submit" class="ml-4 mb-4 bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-2 px-4 rounded">
                          <FontAwesomeIcon icon={faStopwatch} /> Iniciar Checklist
                        </button>
                      ) : (
                        <button disabled onClick={startStopwatch} type="submit" class="ml-4 mb-4 cursor-not-allowed disabled:opacity-75 bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded">
                          <FontAwesomeIcon icon={faStopwatch} /> Iniciar Checklist
                        </button>
                      )}

                    <button onClick={printButton} type="submit" class="ml-4 mb-4 bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded">
                      <FontAwesomeIcon icon={faFilePdf} /> PDF
                    </button>

                    {activateDeleteButton.toString() === 'true' && id ?
                      (
                        <button onClick={deleteButton} type="submit" class="ml-4 mb-4 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
                          <FontAwesomeIcon icon={faTrash} /> Eliminar
                        </button>
                      ) :
                      (<div></div>)}

                    <div class="h-72" ref={mapRef} id="map" />
                    <div class="p-3 mt-40 bg-white shadow-xl">
                      <Form>
                        <TabbedItem>
                          <TabPanelOptions deferRendering={false} showNavButtons={true} />
                          <Tab title="Datos Generales" >
                            <Form
                              id="datosGenerales"
                              labelMode={'outside'}
                              formData={checkListPayload.header}

                              showColonAfterLabel={false}
                              labelLocation={'top'}
                              colCount={2}
                            >
                              <GroupItem caption="General">
                                <Item cssClass="boldText" dataField="Movimiento" editorOptions={movimientoEditorOptions} />
                                <Item dataField="FechaEmision" editorType="dxDateBox" editorOptions={fechaEmisionEditorOptions}>
                                  <Label text={"Fecha Emisión"} />
                                </Item>
                              </GroupItem>
                              <GroupItem caption="Sucursal">
                                <Item dataField="SucursalAsignada" editorType="dxSelectBox" editorOptions={sucursalAsignadaEditorOptions} />
                              </GroupItem>
                              <GroupItem caption="Auditor">
                                <Item dataField="PersonalAsignado" editorType="dxSelectBox" editorOptions={personalAsignadoEditorOptions}>
                                  <Label text={"Auditor Asignado"} />
                                </Item>
                              </GroupItem>
                              <GroupItem caption="Tiempo">
                                <Item dataField="InicioEvaluacion" editorOptions={inicioEvaluacionEditorOptions}>
                                  <Label text={"Inicio Evaluación"} />
                                </Item>
                                <Item dataField="FinEvaluacion" editorOptions={finEvaluacionEditorOptions}>
                                  <Label text={"Fin Evaluación"} />
                                </Item>
                                <Item dataField="DuracionEvaluacion" editorOptions={duracionEvaluacionEditorOptions}>
                                  <Label text={"Duración Evaluación"} />
                                </Item>
                              </GroupItem>
                            </Form>
                          </Tab>
                          <Tab title="Checklist" >
                            <Form
                              id="datosChecklist"
                              labelMode={'outside'}
                              formData={checkListPayload.header}
                              readOnly={!allowUpdating}
                              showColonAfterLabel={false}
                              labelLocation={'top'}
                              colCount={1}
                            >
                              <GroupItem caption="# Personal">
                                <Item dataField="NoVendedores" editorType="dxNumberBox" editorOptions={noVendedoresOptions} >
                                  <Label text={"Vendedores"} />
                                </Item>
                                <Item dataField="NoAuxiliares" editorType="dxNumberBox" editorOptions={noAuxiliaresOptions}>
                                  <Label text={"Auxiliares"} />
                                </Item>
                              </GroupItem>
                              <GroupItem caption="Check List">
                                <CheckBox text="Expandir todos los grupos"
                                  value={autoExpandAllChecklist}
                                  onValueChanged={onAutoExpandAllChecklistChanged}>
                                </CheckBox>
                                <DataGrid
                                  id="gridChecklist"
                                  columnsAutoWidth={true}
                                  dataSource={checkListPayload.checklist}
                                  showBorders={true}
                                  showColumnLines={true}
                                  showRowLines={true}
                                  rowAlternationEnabled={false}
                                  allowColumnResizing={true}
                                  columnResizingMode={"widget"}
                                  allowColumnReordering={false}
                                  onEditorPreparing={overrideOnValueChanged}
                                  repaintChangesOnly={true}
                                >
                                  <Paging defaultPageSize={100} />
                                  <Editing
                                    mode="cell"
                                    allowUpdating={allowUpdating}
                                  />
                                  <Grouping autoExpandAll={autoExpandAllChecklist} />

                                  <Sorting mode="none" />
                                  <Column dataField="Seccion" caption="Sección" dataType="string" width={200} groupIndex={0} />
                                  <Column dataField="Concepto" dataType="string" allowEditing={false} width={450} />
                                  <Column dataField="Evaluacion" caption="Evaluacion" width={140} showEditorAlways={true}>
                                    <Lookup dataSource={evaluacion} valueExpr="value" displayExpr="name" />
                                  </Column>
                                  <Column dataField="Comentarios" width={600} dataType="string" showEditorAlways={true} />
                                </DataGrid>
                              </GroupItem>
                              <GroupItem caption="Consecutivos">
                                <DataGrid
                                  id="gridConsecutivos"
                                  columnsAutoWidth={true}
                                  dataSource={checkListPayload.consecutivos}
                                  showBorders={true}
                                  showColumnLines={true}
                                  showRowLines={true}
                                  rowAlternationEnabled={false}
                                  allowColumnResizing={true}
                                  columnResizingMode={"widget"}
                                  allowColumnReordering={false}
                                  onEditorPreparing={overrideOnValueChanged}
                                  repaintChangesOnly={true}
                                >
                                  <Editing
                                    mode="cell"
                                    allowUpdating={allowUpdating}
                                  />

                                  <Sorting mode="none" />
                                  <Column dataField="Concepto" dataType="string" allowEditing={false} width={450} />
                                  <Column dataField="PrimerFolio" caption="Primer Folio" width={150} dataType="string" showEditorAlways={true} editorOptions={uppercaseEditorOptions} />
                                  <Column dataField="UltimoFolio" caption="Ultimo Folio" width={150} dataType="string" showEditorAlways={true} editorOptions={uppercaseEditorOptions} />
                                </DataGrid>
                              </GroupItem>
                              <GroupItem caption="Marca / Modelo Celulares">
                                <Item dataField="MarcaCelular" editorOptions={marcaEditorOptions} >
                                  <Label text={"Marca"} />
                                </Item>
                                <Item dataField="ModeloCelular" editorOptions={modeloEditorOptions} >
                                  <Label text={"Modelo"} />
                                </Item>
                                <Item dataField="NumeroCelular" editorOptions={numeroCelularEditorOptions} >
                                  <Label text={"Número Celular"} />
                                </Item>
                              </GroupItem>
                              <GroupItem caption="Check List Celulares">
                                <DataGrid
                                  id="gridCelulares"
                                  columnsAutoWidth={true}
                                  dataSource={checkListPayload.celulares}
                                  showBorders={true}
                                  showColumnLines={true}
                                  showRowLines={true}
                                  rowAlternationEnabled={false}
                                  allowColumnResizing={true}
                                  columnResizingMode={"widget"}
                                  allowColumnReordering={false}
                                  onEditorPreparing={overrideOnValueChanged}
                                  repaintChangesOnly={true}
                                >
                                  <Paging defaultPageSize={100} />
                                  <Editing
                                    mode="cell"
                                    allowUpdating={allowUpdating}
                                  />
                                  <Grouping autoExpandAll={autoExpandAllChecklist} />

                                  <Sorting mode="none" />
                                  <Column dataField="Concepto" dataType="string" allowEditing={false} width={450} />
                                  <Column dataField="Evaluacion" caption="Evaluacion" width={140} showEditorAlways={true}>
                                    <Lookup dataSource={evaluacion} valueExpr="value" displayExpr="name" />
                                  </Column>
                                  <Column dataField="Comentarios" width={600} dataType="string" showEditorAlways={true} />
                                </DataGrid>
                              </GroupItem>
                            </Form>
                          </Tab>
                          <Tab title="Arqueo de Caja" >
                            <Form
                              id="datosArqueo"
                              labelMode={'outside'}
                              formData={checkListPayload.header}
                              readOnly={!allowUpdating}
                              showColonAfterLabel={false}
                              labelLocation={'top'}
                              ref={datosArqueoRef}
                              onFieldDataChanged={onFieldDatosChecklistChanged}
                              colCount={4}
                            >
                              <GroupItem caption="Pedidos" colSpan={4}>
                                <DataGrid
                                  id="gridPedidos"
                                  ref={gridPedidosRef}
                                  columnsAutoWidth={true}
                                  dataSource={checkListPayload.pedidos}
                                  showBorders={true}
                                  showColumnLines={true}
                                  showRowLines={true}
                                  rowAlternationEnabled={true}
                                  allowColumnResizing={true}
                                  columnResizingMode={"widget"}
                                  allowColumnReordering={false}
                                  onEditorPreparing={overrideOnValueChanged}
                                  onRowUpdated={actualizaImporteCajaMenor}
                                  onRowInserted={actualizaImporteCajaMenor}
                                  onRowRemoved={actualizaImporteCajaMenor}

                                  repaintChangesOnly={true}
                                >
                                  <Editing
                                    mode="row"
                                    allowUpdating={allowUpdating}
                                    allowAdding={allowUpdating}
                                    allowDeleting={allowUpdating}
                                    useIcons={true}
                                  />
                                  <Sorting mode="none" />

                                  <Column dataField="Fecha" caption="Fecha" width={150} dataType="date" showEditorAlways={true} />
                                  <Column dataField="Folio" caption="Folio Físico" width={150} showEditorAlways={true} editorOptions={uppercaseEditorOptions} />
                                  <Column dataField="NumeroPedido" caption="No. Pedido en Sistema" width={200} showEditorAlways={true} editorOptions={uppercaseEditorOptions} />
                                  <Column dataField="Importe" caption="Importe" width={150} showEditorAlways={true} editorType="dxNumberBox" editorOptions={importeGridEditorOptions} />
                                  <Column dataField="MetodoPago" caption="Método de Pago" width={300} showEditorAlways={true} />

                                  <Toolbar>
                                    <Item name="addRowButton" showText="inMenu" />
                                  </Toolbar>
                                  <Summary>
                                    <GroupItem
                                      column="Importe"
                                      summaryType="sum"
                                      valueFormat="$,##0.##"
                                      displayFormat="Total {0}"
                                      showInGroupFooter={false} />
                                    <TotalItem
                                      column="Importe"
                                      summaryType="sum"
                                      valueFormat="$,##0.##"
                                      displayFormat="Total {0}"
                                      showInColumn="Importe" />
                                  </Summary>
                                </DataGrid>
                              </GroupItem>

                              <GroupItem caption="Pagos o Anticipos" colSpan={4}>
                                <DataGrid
                                  id="gridPagos"
                                  ref={gridPagosRef}
                                  columnsAutoWidth={true}
                                  dataSource={checkListPayload.pagos}
                                  showBorders={true}
                                  showColumnLines={true}
                                  showRowLines={true}
                                  rowAlternationEnabled={true}
                                  allowColumnResizing={true}
                                  columnResizingMode={"widget"}
                                  allowColumnReordering={false}
                                  onEditorPreparing={overrideOnValueChanged}
                                  onRowUpdated={actualizaImporteCajaMenor}
                                  onRowInserted={actualizaImporteCajaMenor}
                                  onRowRemoved={actualizaImporteCajaMenor}
                                  repaintChangesOnly={true}
                                >
                                  <Editing
                                    mode="row"
                                    allowUpdating={allowUpdating}
                                    allowAdding={allowUpdating}
                                    allowDeleting={allowUpdating}
                                    useIcons={true}
                                  />
                                  <Sorting mode="none" />

                                  <Column dataField="Fecha" caption="Fecha" width={150} dataType="date" showEditorAlways={true} />
                                  <Column dataField="Folio" caption="Folio Físico" width={150} showEditorAlways={true} editorOptions={uppercaseEditorOptions} />
                                  <Column dataField="NumeroPedido" caption="No. Pago en Sistema" width={200} showEditorAlways={true} editorOptions={uppercaseEditorOptions} />
                                  <Column dataField="Importe" caption="Importe" width={150} showEditorAlways={true} editorType="dxNumberBox" editorOptions={importeGridEditorOptions} />
                                  <Column dataField="MetodoPago" caption="Método de Pago" width={300} showEditorAlways={true} />

                                  <Toolbar>
                                    <Item name="addRowButton" showText="inMenu" />
                                  </Toolbar>
                                  <Summary>
                                    <GroupItem
                                      column="Importe"
                                      summaryType="sum"
                                      valueFormat="$,##0.##"
                                      displayFormat="Total {0}"
                                      showInGroupFooter={false} />
                                    <TotalItem
                                      column="Importe"
                                      summaryType="sum"
                                      valueFormat="$,##0.##"
                                      displayFormat="Total {0}"
                                      showInColumn="Importe" />
                                  </Summary>
                                </DataGrid>
                              </GroupItem>

                              <GroupItem caption="Billetes MXN" colSpan={4}>
                                <DataGrid
                                  id="gridBilleteMXN"
                                  ref={gridBilleteMXNRef}
                                  columnsAutoWidth={true}
                                  dataSource={checkListPayload.billetemxn}
                                  showBorders={true}
                                  showColumnLines={true}
                                  showRowLines={true}
                                  rowAlternationEnabled={true}
                                  allowColumnResizing={true}
                                  columnResizingMode={"widget"}
                                  allowColumnReordering={false}
                                  onEditorPreparing={overrideOnValueChangedArqueo}
                                  onRowUpdating={actualizaImporteArqueo}
                                  onRowInserting={insertaImporteArqueo}
                                  onRowUpdated={actualizaOtroImporteCajaMenor}
                                  onRowInserted={actualizaOtroImporteCajaMenor}
                                  onRowRemoved={actualizaOtroImporteCajaMenor}
                                  repaintChangesOnly={true}
                                >
                                  <Editing
                                    mode="row"
                                    allowUpdating={allowUpdating}
                                    allowAdding={allowUpdating}
                                    allowDeleting={allowUpdating}
                                    useIcons={true}
                                  />
                                  <Sorting mode="none" />

                                  <Column dataField="Cantidad" caption="Cantidad" width={150} showEditorAlways={true} editorType="dxNumberBox" editorOptions={cantidadEditorOptions} />
                                  <Column dataField="Denominacion" caption="Denominación" width={300} showEditorAlways={true} >
                                    <Lookup dataSource={denominacionBilleteMXN} valueExpr="value" displayExpr="name" />
                                  </Column>
                                  <Column dataField="Importe" caption="Importe" width={150} showEditorAlways={true} editorType="dxNumberBox" editorOptions={importeGridEditorOptions} />

                                  <Toolbar>
                                    <Item name="addRowButton" showText="inMenu" />
                                  </Toolbar>
                                  <Summary>
                                    <GroupItem
                                      column="Importe"
                                      summaryType="sum"
                                      valueFormat="$,##0.##"
                                      displayFormat="Total {0}"
                                      showInGroupFooter={false} />
                                    <TotalItem
                                      column="Importe"
                                      summaryType="sum"
                                      valueFormat="$,##0.##"
                                      displayFormat="Total {0}"
                                      showInColumn="Importe" />
                                  </Summary>
                                </DataGrid>
                              </GroupItem>

                              <GroupItem caption="Monedas MXN" colSpan={4}>
                                <DataGrid
                                  id="gridMonedaMXN"
                                  ref={gridMonedaMXNRef}
                                  columnsAutoWidth={true}
                                  dataSource={checkListPayload.monedamxn}
                                  showBorders={true}
                                  showColumnLines={true}
                                  showRowLines={true}
                                  rowAlternationEnabled={true}
                                  allowColumnResizing={true}
                                  columnResizingMode={"widget"}
                                  allowColumnReordering={false}
                                  onEditorPreparing={overrideOnValueChangedArqueo}
                                  onRowUpdating={actualizaImporteArqueo}
                                  onRowInserting={insertaImporteArqueo}
                                  onRowUpdated={actualizaOtroImporteCajaMenor}
                                  onRowInserted={actualizaOtroImporteCajaMenor}
                                  onRowRemoved={actualizaOtroImporteCajaMenor}
                                  repaintChangesOnly={true}
                                >
                                  <Editing
                                    mode="row"
                                    allowUpdating={allowUpdating}
                                    allowAdding={allowUpdating}
                                    allowDeleting={allowUpdating}
                                    useIcons={true}
                                  />
                                  <Sorting mode="none" />

                                  <Column dataField="Cantidad" caption="Cantidad" width={150} showEditorAlways={true} editorType="dxNumberBox" editorOptions={cantidadEditorOptions} />
                                  <Column dataField="Denominacion" caption="Denominación" width={300} showEditorAlways={true} >
                                    <Lookup dataSource={denominacionMonedaMXN} valueExpr="value" displayExpr="name" />
                                  </Column>
                                  <Column dataField="Importe" caption="Importe" width={150} showEditorAlways={true} editorType="dxNumberBox" editorOptions={importeGridEditorOptions} />

                                  <Toolbar>
                                    <Item name="addRowButton" showText="inMenu" />
                                  </Toolbar>

                                  <Summary>
                                    <GroupItem
                                      column="Importe"
                                      summaryType="sum"
                                      valueFormat="$,##0.##"
                                      displayFormat="Total {0}"
                                      showInGroupFooter={false} />
                                    <TotalItem
                                      column="Importe"
                                      summaryType="sum"
                                      valueFormat="$,##0.##"
                                      displayFormat="Total {0}"
                                      showInColumn="Importe" />
                                  </Summary>
                                </DataGrid>
                              </GroupItem>

                              <GroupItem caption="Billetes USD" colSpan={4}>
                                <DataGrid
                                  id="gridBilleteUSD"
                                  ref={gridBilleteUSDRef}
                                  columnsAutoWidth={true}
                                  dataSource={checkListPayload.billeteusd}
                                  showBorders={true}
                                  showColumnLines={true}
                                  showRowLines={true}
                                  rowAlternationEnabled={true}
                                  allowColumnResizing={true}
                                  columnResizingMode={"widget"}
                                  allowColumnReordering={false}
                                  onEditorPreparing={overrideOnValueChangedArqueo}
                                  onRowUpdating={actualizaImporteArqueo}
                                  onRowInserting={insertaImporteArqueo}
                                  onRowUpdated={actualizaOtroImporteCajaMenor}
                                  onRowInserted={actualizaOtroImporteCajaMenor}
                                  onRowRemoved={actualizaOtroImporteCajaMenor}
                                  repaintChangesOnly={true}
                                >
                                  <Editing
                                    mode="row"
                                    allowUpdating={allowUpdating}
                                    allowAdding={allowUpdating}
                                    allowDeleting={allowUpdating}
                                    useIcons={true}
                                  />
                                  <Sorting mode="none" />

                                  <Column dataField="Cantidad" caption="Cantidad" width={150} showEditorAlways={true} editorType="dxNumberBox" editorOptions={cantidadEditorOptions} />
                                  <Column dataField="Denominacion" caption="Denominación" width={300} showEditorAlways={true} >
                                    <Lookup dataSource={denominacionBilleteUSD} valueExpr="value" displayExpr="name" />
                                  </Column>
                                  <Column dataField="Importe" caption="Importe" width={150} showEditorAlways={true} editorType="dxNumberBox" editorOptions={importeGridEditorOptions} />

                                  <Toolbar>
                                    <Item name="addRowButton" showText="inMenu" />
                                  </Toolbar>

                                  <Summary>
                                    <GroupItem
                                      column="Importe"
                                      summaryType="sum"
                                      valueFormat="$,##0.##"
                                      displayFormat="Total {0}"
                                      showInGroupFooter={false} />
                                    <TotalItem
                                      column="Importe"
                                      summaryType="sum"
                                      valueFormat="$,##0.##"
                                      displayFormat="Total {0}"
                                      showInColumn="Importe" />
                                  </Summary>
                                </DataGrid>
                              </GroupItem>

                              <GroupItem caption="Monedas USD" colSpan={4}>
                                <DataGrid
                                  id="gridMonedaUSD"
                                  ref={gridMonedaUSDRef}
                                  columnsAutoWidth={true}
                                  dataSource={checkListPayload.monedausd}
                                  showBorders={true}
                                  showColumnLines={true}
                                  showRowLines={true}
                                  rowAlternationEnabled={true}
                                  allowColumnResizing={true}
                                  columnResizingMode={"widget"}
                                  allowColumnReordering={false}
                                  onEditorPreparing={overrideOnValueChangedArqueo}
                                  onRowUpdating={actualizaImporteArqueo}
                                  onRowInserting={insertaImporteArqueo}
                                  onRowUpdated={actualizaOtroImporteCajaMenor}
                                  onRowInserted={actualizaOtroImporteCajaMenor}
                                  onRowRemoved={actualizaOtroImporteCajaMenor}
                                  repaintChangesOnly={true}
                                >
                                  <Editing
                                    mode="row"
                                    allowUpdating={allowUpdating}
                                    allowAdding={allowUpdating}
                                    allowDeleting={allowUpdating}
                                    useIcons={true}
                                  />
                                  <Sorting mode="none" />

                                  <Column dataField="Cantidad" caption="Cantidad" width={150} showEditorAlways={true} editorType="dxNumberBox" editorOptions={cantidadEditorOptions} />
                                  <Column dataField="Denominacion" caption="Denominación" width={300} showEditorAlways={true} >
                                    <Lookup dataSource={denominacionMonedaUSD} valueExpr="value" displayExpr="name" />
                                  </Column>
                                  <Column dataField="Importe" caption="Importe" width={150} showEditorAlways={true} editorType="dxNumberBox" editorOptions={importeGridEditorOptions} />

                                  <Toolbar>
                                    <Item name="addRowButton" showText="inMenu" />
                                  </Toolbar>

                                  <Summary>
                                    <GroupItem
                                      column="Importe"
                                      summaryType="sum"
                                      valueFormat="$,##0.##"
                                      displayFormat="Total {0}"
                                      showInGroupFooter={false} />
                                    <TotalItem
                                      column="Importe"
                                      summaryType="sum"
                                      valueFormat="$,##0.##"
                                      displayFormat="Total {0}"
                                      showInColumn="Importe" />
                                  </Summary>
                                </DataGrid>
                              </GroupItem>

                              <GroupItem caption="Cheques" colSpan={4}>
                                <DataGrid
                                  id="gridCheque"
                                  ref={gridChequeRef}
                                  columnsAutoWidth={true}
                                  dataSource={checkListPayload.cheque}
                                  showBorders={true}
                                  showColumnLines={true}
                                  showRowLines={true}
                                  rowAlternationEnabled={true}
                                  allowColumnResizing={true}
                                  columnResizingMode={"widget"}
                                  allowColumnReordering={false}
                                  onEditorPreparing={overrideOnValueChanged}
                                  onRowUpdated={actualizaOtroImporteCajaMenor}
                                  onRowInserted={actualizaOtroImporteCajaMenor}
                                  onRowRemoved={actualizaOtroImporteCajaMenor}
                                  repaintChangesOnly={true}
                                >
                                  <Editing
                                    mode="row"
                                    allowUpdating={allowUpdating}
                                    allowAdding={allowUpdating}
                                    allowDeleting={allowUpdating}
                                    useIcons={true}
                                  />
                                  <Sorting mode="none" />

                                  <Column dataField="NumeroCheque" caption="Número de Cheque" width={200} showEditorAlways={true} editorOptions={uppercaseEditorOptions} />
                                  <Column dataField="Banco" caption="Banco" width={200} showEditorAlways={true} />
                                  <Column dataField="Importe" caption="Importe" width={150} showEditorAlways={true} editorType="dxNumberBox" editorOptions={importeGridEditorOptions} />

                                  <Toolbar>
                                    <Item name="addRowButton" showText="inMenu" />
                                  </Toolbar>

                                  <Summary>
                                    <GroupItem
                                      column="Importe"
                                      summaryType="sum"
                                      valueFormat="$,##0.##"
                                      displayFormat="Total {0}"
                                      showInGroupFooter={false} />
                                    <TotalItem
                                      column="Importe"
                                      summaryType="sum"
                                      valueFormat="$,##0.##"
                                      displayFormat="Total {0}"
                                      showInColumn="Importe" />
                                  </Summary>
                                </DataGrid>
                              </GroupItem>

                              <GroupItem caption="Tarjetas Bancarias" colSpan={4}>
                                <DataGrid
                                  id="gridTarjeta"
                                  ref={gridTarjetaRef}
                                  columnsAutoWidth={true}
                                  dataSource={checkListPayload.tarjeta}
                                  showBorders={true}
                                  showColumnLines={true}
                                  showRowLines={true}
                                  rowAlternationEnabled={true}
                                  allowColumnResizing={true}
                                  columnResizingMode={"widget"}
                                  allowColumnReordering={false}
                                  onEditorPreparing={overrideOnValueChanged}
                                  onRowUpdated={actualizaOtroImporteCajaMenor}
                                  onRowInserted={actualizaOtroImporteCajaMenor}
                                  onRowRemoved={actualizaOtroImporteCajaMenor}
                                  repaintChangesOnly={true}
                                >
                                  <Editing
                                    mode="row"
                                    allowUpdating={allowUpdating}
                                    allowAdding={allowUpdating}
                                    allowDeleting={allowUpdating}
                                    useIcons={true}
                                  />
                                  <Sorting mode="none" />

                                  <Column dataField="Terminacion" caption="Terminación" width={200} showEditorAlways={true} />
                                  <Column dataField="Terminal" caption="Terminal" width={200} showEditorAlways={true} />
                                  <Column dataField="Importe" caption="Importe" width={150} showEditorAlways={true} editorType="dxNumberBox" editorOptions={importeGridEditorOptions} />

                                  <Toolbar>
                                    <Item name="addRowButton" showText="inMenu" />
                                  </Toolbar>

                                  <Summary>
                                    <GroupItem
                                      column="Importe"
                                      summaryType="sum"
                                      valueFormat="$,##0.##"
                                      displayFormat="Total {0}"
                                      showInGroupFooter={false} />
                                    <TotalItem
                                      column="Importe"
                                      summaryType="sum"
                                      valueFormat="$,##0.##"
                                      displayFormat="Total {0}"
                                      showInColumn="Importe" />
                                  </Summary>
                                </DataGrid>
                              </GroupItem>

                              <GroupItem caption="Vales de Despensa" colSpan={4}>
                                <DataGrid
                                  id="gridValeDespensa"
                                  ref={gridValeDespensaRef}
                                  columnsAutoWidth={true}
                                  dataSource={checkListPayload.valedespensa}
                                  showBorders={true}
                                  showColumnLines={true}
                                  showRowLines={true}
                                  rowAlternationEnabled={true}
                                  allowColumnResizing={true}
                                  columnResizingMode={"widget"}
                                  allowColumnReordering={false}
                                  onEditorPreparing={overrideOnValueChanged}
                                  onRowUpdated={actualizaOtroImporteCajaMenor}
                                  onRowInserted={actualizaOtroImporteCajaMenor}
                                  onRowRemoved={actualizaOtroImporteCajaMenor}
                                  repaintChangesOnly={true}
                                >
                                  <Editing
                                    mode="row"
                                    allowUpdating={allowUpdating}
                                    allowAdding={allowUpdating}
                                    allowDeleting={allowUpdating}
                                    useIcons={true}
                                  />
                                  <Sorting mode="none" />

                                  <Column dataField="NumeroVale" caption="Número de Vale" width={200} showEditorAlways={true} editorOptions={uppercaseEditorOptions} />
                                  <Column dataField="Empresa" caption="Empresa" width={200} showEditorAlways={true} />
                                  <Column dataField="Importe" caption="Importe" width={150} showEditorAlways={true} editorType="dxNumberBox" editorOptions={importeGridEditorOptions} />

                                  <Toolbar>
                                    <Item name="addRowButton" showText="inMenu" />
                                  </Toolbar>

                                  <Summary>
                                    <GroupItem
                                      column="Importe"
                                      summaryType="sum"
                                      valueFormat="$,##0.##"
                                      displayFormat="Total {0}"
                                      showInGroupFooter={false} />
                                    <TotalItem
                                      column="Importe"
                                      summaryType="sum"
                                      valueFormat="$,##0.##"
                                      displayFormat="Total {0}"
                                      showInColumn="Importe" />
                                  </Summary>
                                </DataGrid>
                              </GroupItem>

                              <GroupItem caption="Vales Saldo a Favor" colSpan={4}>
                                <DataGrid
                                  id="gridVale"
                                  ref={gridValeRef}
                                  columnsAutoWidth={true}
                                  dataSource={checkListPayload.valesaldofavor}
                                  showBorders={true}
                                  showColumnLines={true}
                                  showRowLines={true}
                                  rowAlternationEnabled={true}
                                  allowColumnResizing={true}
                                  columnResizingMode={"widget"}
                                  allowColumnReordering={false}
                                  onEditorPreparing={overrideOnValueChanged}
                                  onRowUpdated={actualizaOtroImporteCajaMenor}
                                  onRowInserted={actualizaOtroImporteCajaMenor}
                                  onRowRemoved={actualizaOtroImporteCajaMenor}
                                  repaintChangesOnly={true}
                                >
                                  <Editing
                                    mode="row"
                                    allowUpdating={allowUpdating}
                                    allowAdding={allowUpdating}
                                    allowDeleting={allowUpdating}
                                    useIcons={true}
                                  />
                                  <Sorting mode="none" />

                                  <Column dataField="Folio" caption="Folio de Vale" width={200} showEditorAlways={true} editorOptions={uppercaseEditorOptions} />
                                  <Column dataField="Importe" caption="Importe" width={150} showEditorAlways={true} editorType="dxNumberBox" editorOptions={importeGridEditorOptions} />

                                  <Toolbar>
                                    <Item name="addRowButton" showText="inMenu" />
                                  </Toolbar>

                                  <Summary>
                                    <GroupItem
                                      column="Importe"
                                      summaryType="sum"
                                      valueFormat="$,##0.##"
                                      displayFormat="Total {0}"
                                      showInGroupFooter={false} />
                                    <TotalItem
                                      column="Importe"
                                      summaryType="sum"
                                      valueFormat="$,##0.##"
                                      displayFormat="Total {0}"
                                      showInColumn="Importe" />
                                  </Summary>
                                </DataGrid>
                              </GroupItem>

                              <GroupItem caption="Caratula resumen de auditoria." colSpan={4}>
                                <Item dataField="ArqUltimoCorte" editorOptions={uppercaseCaratulaAudEditorOptions} >
                                  <Label text={"Ultimo corte"} />
                                </Item>
                                <Item dataField="ArqPedidosPendientes" editorOptions={uppercaseCaratulaAudEditorOptions} >
                                  <Label text={"Pedidos pend. corte"} />
                                </Item>
                                <Item dataField="ArqRecibosPendientes" editorOptions={uppercaseCaratulaAudEditorOptions} >
                                  <Label text={"Recibos pend. corte"} />
                                </Item>
                              </GroupItem>

                              <GroupItem caption="Cuadre de Caja Menor" colSpan={4}>
                              </GroupItem>

                              <GroupItem caption="Sistema" colSpan={2}>
                                <Item dataField="ArqImportePedidos" editorType="dxNumberBox" editorOptions={importeEditorOptions} >
                                  <Label text={"Importe Pedidos"} />
                                </Item>
                                <Item dataField="ArqImportePagos" editorType="dxNumberBox" editorOptions={importeEditorOptions} >
                                  <Label text={"Importe Pagos"} />
                                </Item>
                                <Item cssClass="boldTextBgGray" dataField="ArqValorTotalSistema" editorType="dxNumberBox" editorOptions={importeEditorOptions} >
                                  <Label text={"Total"} />
                                </Item>
                              </GroupItem>

                              <GroupItem caption="Caja Menor" colSpan={2}>
                                <Item dataField="ArqEfectivoMXN" editorType="dxNumberBox" editorOptions={importeEditorOptions}>
                                  <Label text={"Efectivo MXN"} />
                                </Item>
                                <Item dataField="ArqEfectivoUSD" editorType="dxNumberBox" editorOptions={importeEditorOptions}>
                                  <Label text={"Efectivo USD"} />
                                </Item>

                                <Item dataField="ArqCheques" editorType="dxNumberBox" editorOptions={importeEditorOptions}>
                                  <Label text={"Cheques"} />
                                </Item>
                                <Item dataField="ArqTarjetaBancaria" editorType="dxNumberBox" editorOptions={importeEditorOptions}>
                                  <Label text={"Tarjetas Banc."} />
                                </Item>
                                <Item dataField="ArqVales" editorType="dxNumberBox" editorOptions={importeEditorOptions}>
                                  <Label text={"Vales"} />
                                </Item>
                                <Item cssClass="boldTextBgGray" dataField="ArqValorTotalCajaMenor" editorType="dxNumberBox" editorOptions={importeEditorOptions}>
                                  <Label text={"Total"} />
                                </Item>
                              </GroupItem>

                              <GroupItem caption="Diferencia Caja Menor" colSpan={2}>
                                <GroupItem caption="">
                                  <Item dataField="ArqDiferencia" editorType="dxCheckBox">
                                    <Label text={"Diferencia"} />
                                  </Item>
                                </GroupItem>
                                <GroupItem caption="">
                                  <Item dataField="ArqFaltante" editorType="dxNumberBox" editorOptions={arqDiferenciaEditorOptions}>
                                    <Label text={"Faltante"} />
                                  </Item>
                                </GroupItem>
                                <GroupItem caption="">
                                  <Item dataField="ArqSobrante" editorType="dxNumberBox" editorOptions={arqDiferenciaEditorOptions}>
                                    <Label text={"Sobrante"} />
                                  </Item>
                                </GroupItem>
                              </GroupItem>

                              <GroupItem caption="Dinero en Efectivo" colSpan={2}>
                                <Item dataField="ArqDineroPresencia" >
                                  <Label text={"Contado con"} />
                                </Item>
                                <Item dataField="ArqDineroDevuelto" editorType="dxDateBox" editorOptions={arqDineroDevueltoEditorOptions} >
                                  <Label text={"Devuelto"} />
                                </Item>
                              </GroupItem>

                              <GroupItem caption="Resolución y Comentarios" colSpan={4}>
                                <Item dataField="ArqAudResolucion" editorType="dxTextArea" editorOptions={resultadoEditorOptions}>
                                  <Label text={"Resolución"} />
                                </Item>
                              </GroupItem>
                            </Form>
                          </Tab>
                          <Tab title="Arqueo de Caja Gerente" >
                            <Form
                              id="datosArqueoGerente"
                              ref={datosArqueoGerenteRef}
                              labelMode={'outside'}
                              formData={checkListPayload.header}
                              readOnly={!allowUpdating}
                              showColonAfterLabel={false}
                              labelLocation={'top'}
                              onFieldDataChanged={onFieldDatosArqueoGerenteChanged}
                              colCount={4}
                            >
                              <GroupItem caption="Marca / Modelo Celular" colSpan={4}>
                                <Item dataField="GteMarcaCelular" editorOptions={marcaEditorOptions} >
                                  <Label text={"Marca"} />
                                </Item>
                                <Item dataField="GteModeloCelular" editorOptions={modeloEditorOptions} >
                                  <Label text={"Modelo"} />
                                </Item>
                                <Item dataField="GteNumeroCelular" editorOptions={numeroCelularEditorOptions} >
                                  <Label text={"Número Celular"} />
                                </Item>
                              </GroupItem>
                              <GroupItem caption="Celular / Radio empresarial" colSpan={4}>
                                <DataGrid
                                  id="gridCelularGerente"
                                  columnsAutoWidth={true}
                                  dataSource={checkListPayload.celulargerente}
                                  showBorders={true}
                                  showColumnLines={true}
                                  showRowLines={true}
                                  rowAlternationEnabled={false}
                                  allowColumnResizing={true}
                                  columnResizingMode={"widget"}
                                  allowColumnReordering={false}
                                  onEditorPreparing={overrideOnValueChanged}
                                  repaintChangesOnly={true}
                                >
                                  <Paging defaultPageSize={100} />
                                  <Editing
                                    mode="cell"
                                    allowUpdating={allowUpdating}
                                  />
                                  <Grouping autoExpandAll={autoExpandAllChecklist} />

                                  <Sorting mode="none" />
                                  <Column dataField="Concepto" dataType="string" allowEditing={false} width={450} />
                                  <Column dataField="Evaluacion" caption="Evaluacion" width={140} showEditorAlways={true}>
                                    <Lookup dataSource={evaluacion} valueExpr="value" displayExpr="name" />
                                  </Column>
                                  <Column dataField="Comentarios" width={600} dataType="string" showEditorAlways={true} />
                                </DataGrid>
                              </GroupItem>
                              <GroupItem caption="Gastos Administrativos" colSpan={4}>
                                <DataGrid
                                  id="gridGasAdminGerente"
                                  ref={gridGasAdminGerenteRef}
                                  columnsAutoWidth={true}
                                  dataSource={checkListPayload.gasadmingerente}
                                  showBorders={true}
                                  showColumnLines={true}
                                  showRowLines={true}
                                  rowAlternationEnabled={true}
                                  allowColumnResizing={true}
                                  columnResizingMode={"widget"}
                                  allowColumnReordering={false}
                                  onEditorPreparing={overrideOnValueChanged}
                                  onRowUpdated={actualizaGastosGteCajaChica}
                                  onRowInserted={actualizaGastosGteCajaChica}
                                  onRowRemoved={actualizaGastosGteCajaChica}
                                  repaintChangesOnly={true}
                                >
                                  <Editing
                                    mode="row"
                                    allowUpdating={allowUpdating}
                                    allowAdding={allowUpdating}
                                    allowDeleting={allowUpdating}
                                    useIcons={true}
                                  />
                                  <Sorting mode="none" />

                                  <Column dataField="Fecha" caption="Fecha" width={150} dataType="date" showEditorAlways={true} />
                                  <Column dataField="TipoDocumento" caption="Tipo de Documento" width={150} showEditorAlways={true} />
                                  <Column dataField="Numero" caption="Numero" width={150} showEditorAlways={true} editorOptions={uppercaseEditorOptions} />
                                  <Column dataField="Concepto" caption="Concepto" width={150} showEditorAlways={true} />
                                  <Column dataField="Importe" caption="Importe" width={150} showEditorAlways={true} editorType="dxNumberBox" editorOptions={importeGridEditorOptions} />
                                  <Column dataField="MetodoPago" caption="Método de Pago" width={300} showEditorAlways={true} />

                                  <Toolbar>
                                    <Item name="addRowButton" showText="inMenu" />
                                  </Toolbar>
                                  <Summary>
                                    <GroupItem
                                      column="Importe"
                                      summaryType="sum"
                                      valueFormat="$,##0.##"
                                      displayFormat="Total {0}"
                                      showInGroupFooter={false} />
                                    <TotalItem
                                      column="Importe"
                                      summaryType="sum"
                                      valueFormat="$,##0.##"
                                      displayFormat="Total {0}"
                                      showInColumn="Importe" />
                                  </Summary>
                                </DataGrid>
                              </GroupItem>
                              <GroupItem caption="Gastos de Gasolina" colSpan={4}>
                                <DataGrid
                                  id="gridGasolinaGerente"
                                  ref={gridGasolinaGerenteRef}
                                  columnsAutoWidth={true}
                                  dataSource={checkListPayload.gasolinagerente}
                                  showBorders={true}
                                  showColumnLines={true}
                                  showRowLines={true}
                                  rowAlternationEnabled={true}
                                  allowColumnResizing={true}
                                  columnResizingMode={"widget"}
                                  allowColumnReordering={false}
                                  onEditorPreparing={overrideOnValueChanged}
                                  onRowUpdated={actualizaGastosGteCajaChica}
                                  onRowInserted={actualizaGastosGteCajaChica}
                                  onRowRemoved={actualizaGastosGteCajaChica}
                                  repaintChangesOnly={true}
                                >
                                  <Editing
                                    mode="row"
                                    allowUpdating={allowUpdating}
                                    allowAdding={allowUpdating}
                                    allowDeleting={allowUpdating}
                                    useIcons={true}
                                  />
                                  <Sorting mode="none" />

                                  <Column dataField="Fecha" caption="Fecha" width={150} dataType="date" showEditorAlways={true} />
                                  <Column dataField="Numero" caption="Numero Ticket" width={150} showEditorAlways={true} editorOptions={uppercaseEditorOptions} />
                                  <Column dataField="Factura" caption="Factura" width={150} showEditorAlways={true} editorOptions={uppercaseEditorOptions} />
                                  <Column dataField="UnidadSurtida" caption="Unidad Surtida" width={150} showEditorAlways={true} />
                                  <Column dataField="Importe" caption="Importe" width={150} showEditorAlways={true} editorType="dxNumberBox" editorOptions={importeGridEditorOptions} />
                                  <Column dataField="MetodoPago" caption="Método de Pago" width={300} showEditorAlways={true} />

                                  <Toolbar>
                                    <Item name="addRowButton" showText="inMenu" />
                                  </Toolbar>
                                  <Summary>
                                    <GroupItem
                                      column="Importe"
                                      summaryType="sum"
                                      valueFormat="$,##0.##"
                                      displayFormat="Total {0}"
                                      showInGroupFooter={false} />
                                    <TotalItem
                                      column="Importe"
                                      summaryType="sum"
                                      valueFormat="$,##0.##"
                                      displayFormat="Total {0}"
                                      showInColumn="Importe" />
                                  </Summary>
                                </DataGrid>
                              </GroupItem>

                              <GroupItem caption="Cuadre de Caja Menor" colSpan={4}>
                              </GroupItem>

                              <GroupItem caption="Billetes MXN" colSpan={4}>
                                <DataGrid
                                  id="gridBilleteMXN"
                                  ref={gridBilleteMXNGerenteRef}
                                  columnsAutoWidth={true}
                                  dataSource={checkListPayload.billetemxngerente}
                                  showBorders={true}
                                  showColumnLines={true}
                                  showRowLines={true}
                                  rowAlternationEnabled={true}
                                  allowColumnResizing={true}
                                  columnResizingMode={"widget"}
                                  allowColumnReordering={false}
                                  onEditorPreparing={overrideOnValueChangedArqueo}
                                  onRowUpdating={actualizaImporteArqueo}
                                  onRowInserting={insertaImporteArqueo}
                                  onRowUpdated={actualizaDictamenGteCajaChica}
                                  onRowInserted={actualizaDictamenGteCajaChica}
                                  onRowRemoved={actualizaDictamenGteCajaChica}
                                  repaintChangesOnly={true}
                                >
                                  <Editing
                                    mode="row"
                                    allowUpdating={allowUpdating}
                                    allowAdding={allowUpdating}
                                    allowDeleting={allowUpdating}
                                    useIcons={true}
                                  />
                                  <Sorting mode="none" />

                                  <Column dataField="Cantidad" caption="Cantidad" width={150} showEditorAlways={true} editorType="dxNumberBox" editorOptions={cantidadEditorOptions} />
                                  <Column dataField="Denominacion" caption="Denominación" width={300} showEditorAlways={true} >
                                    <Lookup dataSource={denominacionBilleteMXN} valueExpr="value" displayExpr="name" />
                                  </Column>
                                  <Column dataField="Importe" caption="Importe" width={150} showEditorAlways={true} editorType="dxNumberBox" editorOptions={importeEditorOptions} />

                                  <Toolbar>
                                    <Item name="addRowButton" showText="inMenu" />
                                  </Toolbar>
                                  <Summary>
                                    <GroupItem
                                      column="Importe"
                                      summaryType="sum"
                                      valueFormat="$,##0.##"
                                      displayFormat="Total {0}"
                                      showInGroupFooter={false} />
                                    <TotalItem
                                      column="Importe"
                                      summaryType="sum"
                                      valueFormat="$,##0.##"
                                      displayFormat="Total {0}"
                                      showInColumn="Importe" />
                                  </Summary>
                                </DataGrid>
                              </GroupItem>

                              <GroupItem caption="Monedas MXN" colSpan={4}>
                                <DataGrid
                                  id="gridMonedaMXN"
                                  ref={gridMonedaMXNGerenteRef}
                                  columnsAutoWidth={true}
                                  dataSource={checkListPayload.monedamxngerente}
                                  showBorders={true}
                                  showColumnLines={true}
                                  showRowLines={true}
                                  rowAlternationEnabled={true}
                                  allowColumnResizing={true}
                                  columnResizingMode={"widget"}
                                  allowColumnReordering={false}
                                  onEditorPreparing={overrideOnValueChangedArqueo}
                                  onRowUpdating={actualizaImporteArqueo}
                                  onRowInserting={insertaImporteArqueo}
                                  onRowUpdated={actualizaDictamenGteCajaChica}
                                  onRowInserted={actualizaDictamenGteCajaChica}
                                  onRowRemoved={actualizaDictamenGteCajaChica}
                                  repaintChangesOnly={true}
                                >
                                  <Editing
                                    mode="row"
                                    allowUpdating={allowUpdating}
                                    allowAdding={allowUpdating}
                                    allowDeleting={allowUpdating}
                                    useIcons={true}
                                  />
                                  <Sorting mode="none" />

                                  <Column dataField="Cantidad" caption="Cantidad" width={150} showEditorAlways={true} editorType="dxNumberBox" editorOptions={cantidadEditorOptions} />
                                  <Column dataField="Denominacion" caption="Denominación" width={300} showEditorAlways={true} >
                                    <Lookup dataSource={denominacionMonedaMXN} valueExpr="value" displayExpr="name" />
                                  </Column>
                                  <Column dataField="Importe" caption="Importe" width={150} showEditorAlways={true} editorType="dxNumberBox" editorOptions={importeEditorOptions} />

                                  <Toolbar>
                                    <Item name="addRowButton" showText="inMenu" />
                                  </Toolbar>

                                  <Summary>
                                    <GroupItem
                                      column="Importe"
                                      summaryType="sum"
                                      valueFormat="$,##0.##"
                                      displayFormat="Total {0}"
                                      showInGroupFooter={false} />
                                    <TotalItem
                                      column="Importe"
                                      summaryType="sum"
                                      valueFormat="$,##0.##"
                                      displayFormat="Total {0}"
                                      showInColumn="Importe" />
                                  </Summary>
                                </DataGrid>
                              </GroupItem>

                              <GroupItem caption="Tarjetas Bancarias" colSpan={4}>
                                <DataGrid
                                  id="gridTarjetaBancGerente"
                                  ref={gridTarjetaBancGerenteRef}
                                  columnsAutoWidth={true}
                                  dataSource={checkListPayload.tarjetabancgerente}
                                  showBorders={true}
                                  showColumnLines={true}
                                  showRowLines={true}
                                  rowAlternationEnabled={true}
                                  allowColumnResizing={true}
                                  columnResizingMode={"widget"}
                                  allowColumnReordering={false}
                                  onEditorPreparing={overrideOnValueChanged}
                                  onRowUpdated={actualizaDictamenGteCajaChica}
                                  onRowInserted={actualizaDictamenGteCajaChica}
                                  onRowRemoved={actualizaDictamenGteCajaChica}
                                  repaintChangesOnly={true}
                                >
                                  <Editing
                                    mode="row"
                                    allowUpdating={allowUpdating}
                                    allowAdding={allowUpdating}
                                    allowDeleting={allowUpdating}
                                    useIcons={true}
                                  />
                                  <Sorting mode="none" />

                                  <Column dataField="Banco" caption="Banco" width={200} showEditorAlways={true} />
                                  <Column dataField="FechaVencimiento" caption="Vencimiento" width={200} showEditorAlways={true} />
                                  <Column dataField="NumeroTarjeta" caption="Número Tarjeta" width={200} showEditorAlways={true} />
                                  <Column dataField="Importe" caption="Importe" width={150} showEditorAlways={true} editorType="dxNumberBox" editorOptions={importeGridEditorOptions} />

                                  <Toolbar>
                                    <Item name="addRowButton" showText="inMenu" />
                                  </Toolbar>

                                  <Summary>
                                    <GroupItem
                                      column="Importe"
                                      summaryType="sum"
                                      valueFormat="$,##0.##"
                                      displayFormat="Total {0}"
                                      showInGroupFooter={false} />
                                    <TotalItem
                                      column="Importe"
                                      summaryType="sum"
                                      valueFormat="$,##0.##"
                                      displayFormat="Total {0}"
                                      showInColumn="Importe" />
                                  </Summary>
                                </DataGrid>
                              </GroupItem>

                              <GroupItem caption="Vales de Gasolina" colSpan={4}>
                                <DataGrid
                                  id="gridValesGasGerente"
                                  ref={gridValesGasGerenteRef}
                                  columnsAutoWidth={true}
                                  dataSource={checkListPayload.valegasgerente}
                                  showBorders={true}
                                  showColumnLines={true}
                                  showRowLines={true}
                                  rowAlternationEnabled={true}
                                  allowColumnResizing={true}
                                  columnResizingMode={"widget"}
                                  allowColumnReordering={false}
                                  onEditorPreparing={overrideOnValueChanged}
                                  onRowUpdated={actualizaDictamenGteCajaChica}
                                  onRowInserted={actualizaDictamenGteCajaChica}
                                  onRowRemoved={actualizaDictamenGteCajaChica}
                                  repaintChangesOnly={true}
                                >
                                  <Editing
                                    mode="row"
                                    allowUpdating={allowUpdating}
                                    allowAdding={allowUpdating}
                                    allowDeleting={allowUpdating}
                                    useIcons={true}
                                  />
                                  <Sorting mode="none" />

                                  <Column dataField="NumeroTarjeta" caption="Número de Tarjeta" width={200} showEditorAlways={true} />
                                  <Column dataField="UnidadAsignada" caption="Unidad Asignada" width={200} showEditorAlways={true} />
                                  <Column dataField="Importe" caption="Importe" width={150} showEditorAlways={true} editorType="dxNumberBox" editorOptions={importeGridEditorOptions} />

                                  <Toolbar>
                                    <Item name="addRowButton" showText="inMenu" />
                                  </Toolbar>

                                  <Summary>
                                    <GroupItem
                                      column="Importe"
                                      summaryType="sum"
                                      valueFormat="$,##0.##"
                                      displayFormat="Total {0}"
                                      showInGroupFooter={false} />
                                    <TotalItem
                                      column="Importe"
                                      summaryType="sum"
                                      valueFormat="$,##0.##"
                                      displayFormat="Total {0}"
                                      showInColumn="Importe" />
                                  </Summary>
                                </DataGrid>
                              </GroupItem>

                              <GroupItem caption="Caratula resumen de Auditoria" colSpan={4}>
                                <GroupItem caption="">
                                  <Item dataField="ArqGteReposicion" editorType="dxCheckBox" >
                                    <Label text={"Reposición"} />
                                  </Item>
                                </GroupItem>
                                <GroupItem caption="">
                                  <Item dataField="ArqGteReposicionMonto" editorType="dxNumberBox" editorOptions={arqGteReposicionMontoEditorOptions}>
                                    <Label text={"Monto"} />
                                  </Item>
                                </GroupItem>
                              </GroupItem>

                              <GroupItem caption="Cuadre de Caja Chica" colSpan={4}>
                              </GroupItem>

                              <GroupItem caption="Gastos" colSpan={2}>
                                <Item dataField="ArqGteGastosAdmin" editorType="dxNumberBox" editorOptions={importeEditorOptions} >
                                  <Label text={"Administrativo"} />
                                </Item>
                                <Item dataField="ArqGteGasolina" editorType="dxNumberBox" editorOptions={importeEditorOptions} >
                                  <Label text={"Gasolina"} />
                                </Item>
                                <Item cssClass="boldTextBgGray" dataField="ArqGteGastosTotal" editorType="dxNumberBox" editorOptions={importeEditorOptions} >
                                  <Label text={"Total Gastos"} />
                                </Item>
                              </GroupItem>

                              <GroupItem caption="Dictamen de Auditoría" colSpan={2}>
                                <Item dataField="ArqGteEfectivoMXN" editorType="dxNumberBox" editorOptions={importeEditorOptions} >
                                  <Label text={"Efectivo MXN"} />
                                </Item>
                                <Item dataField="ArqGteTarjetaBancaria" editorType="dxNumberBox" editorOptions={importeEditorOptions} >
                                  <Label text={"Tarjetas Banc."} />
                                </Item>
                                <Item dataField="ArqGteValeGasolina" editorType="dxNumberBox" editorOptions={importeEditorOptions} >
                                  <Label text={"Vales Gasolina"} />
                                </Item>
                                <Item cssClass="boldTextBgGray" dataField="ArqGteValorTotalCaja" editorType="dxNumberBox" editorOptions={importeEditorOptions} >
                                  <Label text={"Total"} />
                                </Item>
                              </GroupItem>

                              <GroupItem caption="Diferencia Caja Chica" colSpan={2}>
                                <GroupItem caption="">
                                  <Item dataField="ArqGteDiferencia" editorType="dxCheckBox">
                                    <Label text={"Diferencia"} />
                                  </Item>
                                </GroupItem>
                                <GroupItem caption="">
                                  <Item dataField="ArqGteFaltante" editorType="dxNumberBox" editorOptions={arqGteFaltanteEditorOptions}>
                                    <Label text={"Faltante"} />
                                  </Item>
                                </GroupItem>
                                <GroupItem caption="">
                                  <Item dataField="ArqGteSobrante" editorType="dxNumberBox" editorOptions={arqGteFaltanteEditorOptions}>
                                    <Label text={"Sobrante"} />
                                  </Item>
                                </GroupItem>
                              </GroupItem>
                              <GroupItem caption="Dinero en Efectivo" colSpan={2}>
                                <Item dataField="ArqGteDineroPresencia" >
                                  <Label text={"Contado con"} />
                                </Item>
                              </GroupItem>

                              <GroupItem caption="Resolución y Comentarios" colSpan={4}>
                                <Item dataField="ArqGteResolucion" editorType="dxTextArea" editorOptions={resultadoEditorOptions}>
                                  <Label text={"Resolución"} />
                                </Item>
                              </GroupItem>
                            </Form>
                          </Tab>
                          <Tab title="Adjuntar Evidencia" >
                            <div className="widget-container">
                              <FileUploader
                                multiple={true}
                                accept="*"
                                uploadMode="useButtons"
                                chunkSize={20000000}
                                maxFileSize={20000000}
                                uploadChunk={uploadChunk}
                                disabled={!allowUpdating}
                              />
                            </div>
                            <div id="wrapper" className="show-widget" >
                              <div id="widget-area">
                                <FileManager id="attachmentManager" fileSystemProvider={attachments} onItemDeleting={onAttachmentDeleting} onItemDownloading={onItemDownloading} onSelectedFileOpened={onAttachmentOpen}>
                                  <Permissions
                                    create={false}
                                    copy={false}
                                    move={false}
                                    delete={allowUpdating}
                                    rename={false}
                                    upload={false}
                                    download={true}
                                  >
                                  </Permissions>
                                </FileManager>
                              </div>
                            </div>
                          </Tab>
                        </TabbedItem>
                      </Form>
                    </div>
                  </div>
                ) :
                (<div></div>)
              }
            </div>
          );
        }}
        />
      </div>
    </div>
  );
}