import React, { useState, useEffect, useRef } from 'react';

// Toast
import { Toast } from 'devextreme-react/toast';

import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

import { Watermark } from '@hirohe/react-watermark';

import './Document.css';

//FileUploader
import FileUploader from 'devextreme-react/file-uploader';

//FileManager
import FileManager, { Permissions } from 'devextreme-react/file-manager';

import { Auth, API, Storage } from 'aws-amplify';

export default function PdfDocument(props) {
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [usuario, setUsuario] = useState(props.usuario)

    // loadPanelPosition para el control FileManager
    const loadPanelPosition = { of: '#file-manager' };

    const [attachments, setAttachments] = useState([]);
    const [pdfDocument, setPdfDocument] = useState(undefined);

    const [visibleToast, setVisibleToast] = useState(false);
    const [messageToast, setMessageToast] = useState('');
    const [typeToast, setTypeToast] = useState('success');

    const [ok, setOk] = useState(false);

    const options = {
        cMapUrl: 'cmaps/',
        cMapPacked: true,
        standardFontDataUrl: 'standard_fonts/',
    };

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
        setPageNumber(1);
    }

    function changePage(offset) {
        setPageNumber(prevPageNumber => prevPageNumber + offset);
    }

    function previousPage() {
        changePage(-1);
    }

    function nextPage() {
        changePage(1);
    }

    function onItemClick({ pageNumber: itemPageNumber }) {
        setPageNumber(itemPageNumber);
    }

    async function uploadChunk(file, uploadInfo) {
        try {
            var responseS3 = await Storage.put("/document/manual.pdf", uploadInfo.chunkBlob, {
                contentType: file.type,
                ACL: 'public-read'
            });

            //Obtenemos los attachments
            var attachmentsGet = await Storage.list("/document/");
            var attachmentsAux = [];

            for (var attachmentsGetIndex in attachmentsGet) {
                var singleAttachmentsGet = attachmentsGet[attachmentsGetIndex];

                var singleAttachment = {
                    name: singleAttachmentsGet.key.split('/').pop(),
                    isDirectory: false,
                    size: singleAttachmentsGet.size,
                };

                attachmentsAux.push(singleAttachment);
            }

            setVisibleToast(true);
            setMessageToast('Se actualizó el manual con éxito');
            setTypeToast('success')

            setAttachments(attachmentsAux);
        }
        catch (err) {
            setVisibleToast(true);
            setMessageToast("Error al actualizar el manual " + err.message);
            setTypeToast('error')
        }
    }

    async function onAttachmentDeleting(e) {
        //Primero borramos el archivo de S3
        var attachmentsDelete = await Storage.remove("/document/manual.pdf");

        //Obtenemos los attachments
        var attachmentsGet = await Storage.list("/document/");
        var attachmentsAux = [];

        for (var attachmentsGetIndex in attachmentsGet) {
            var singleAttachmentsGet = attachmentsGet[attachmentsGetIndex];

            var singleAttachment = {
                name: singleAttachmentsGet.key.split('/').pop(),
                isDirectory: false,
                size: singleAttachmentsGet.size,
            };

            attachmentsAux.push(singleAttachment);
        }

        setVisibleToast(true);
        setMessageToast('Se eliminó el manual con éxito');
        setTypeToast('success')

        setAttachments(attachmentsAux);
    };

    function downloadBlob(blob, filename) {
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = filename || 'download';
        const clickHandler = () => {
            setTimeout(() => {
                URL.revokeObjectURL(url);
                a.removeEventListener('click', clickHandler);
            }, 150);
        };

        a.addEventListener('click', clickHandler, false);

        a.click();

        return a;
    };

    async function onItemDownloading(e) {
        e.cancel = true;

        const downloadFileResponse = await Storage.get("/document/manual.pdf", { download: true });

        downloadBlob(downloadFileResponse.Body, e.item.name);
    };

    function openBlob(blob, filename) {
        const url = URL.createObjectURL(blob);

        window.open(url, "_blank");
    };

    async function onAttachmentOpen(e) {
        const downloadFileResponse = await Storage.get("/document/manual.pdf", { download: true });

        openBlob(downloadFileResponse.Body, e.file.name);
    };

    useEffect(() => {
        async function initialize() {
            var user = await Auth.currentAuthenticatedUser();

            var groups = user.signInUserSession.accessToken.payload["cognito:groups"];

            var okAdmin = false;

            for (var groupIndex in groups) {
                var singleGroup = groups[groupIndex];

                if (singleGroup === 'Admin') {
                    setOk(true);
                    okAdmin = true;
                }
            }

            if (okAdmin === true) {
                var getDocumentList = await Storage.list

                //Obtenemos los attachments
                var attachmentsGet = await Storage.list("/document/");
                var attachmentsAux = [];

                for (var attachmentsGetIndex in attachmentsGet) {
                    var singleAttachmentsGet = attachmentsGet[attachmentsGetIndex];

                    var singleAttachment = {
                        name: singleAttachmentsGet.key.split('/').pop(),
                        isDirectory: false,
                        size: singleAttachmentsGet.size,
                    };

                    attachmentsAux.push(singleAttachment);
                }

                setAttachments(attachmentsAux);
            }
        }

        initialize();
    }, [props.isadmin]);

    function onHidingToast() {
        setVisibleToast(false);
    }

    return (
        <div>
            {ok === true ? (
                <div>
                    <Toast
                        visible={visibleToast}
                        message={messageToast}
                        type={typeToast}
                        onHiding={onHidingToast}
                        displayTime={5000}
                        width={'auto'}
                        position={{
                            my: 'top center',
                            at: 'top center',
                            of: window,
                            offset: '0 130'
                        }}
                    />
                    <div class="bg-white p-3 shadow-xl m-2">
                        <div className="widget-container">
                            <FileUploader
                                multiple={true}
                                accept="application/pdf"
                                uploadMode="useButtons"
                                chunkSize={20000000}
                                maxFileSize={20000000}
                                uploadChunk={uploadChunk}
                                disabled={false}
                            />
                        </div>
                        <div id="wrapper" className="show-widget" >
                            <div id="widget-area">
                                <FileManager id="attachmentManager" fileSystemProvider={attachments} onItemDeleting={onAttachmentDeleting} onItemDownloading={onItemDownloading} onSelectedFileOpened={onAttachmentOpen}>
                                    <Permissions
                                        create={false}
                                        copy={false}
                                        move={false}
                                        delete={true}
                                        rename={false}
                                        upload={false}
                                        download={true}
                                    >
                                    </Permissions>
                                </FileManager>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (<div></div>)
            }
        </div>
    );
}
