import React, { useState, useEffect, useRef, useMemo } from 'react';

// LoadPanel
import { LoadPanel } from 'devextreme-react/load-panel';

// Toast
import { Toast } from 'devextreme-react/toast';

// Grid
import DataGrid, {
  Column, FilterRow, HeaderFilter, FilterPanel, FilterBuilderPopup,
  GroupPanel, Grouping, SearchPanel, Sorting, Editing, Lookup, Toolbar, Item, RequiredRule,
  Scrolling, Pager, Paging, Summary, TotalItem, StateStoring, ColumnChooser, Export,
  ColumnChooserSearch, ColumnChooserSelection
} from 'devextreme-react/data-grid';

//CheckBox
import CheckBox from 'devextreme-react/check-box';

//TagBox
import TagBox from "devextreme/ui/tag_box";

//Form
import Form, { GroupItem, Label, TabbedItem, TabPanelOptions, Tab } from 'devextreme-react/form';

import { Auth, API, Storage } from 'aws-amplify';

// Ruteador
import { Route, Link, useHistory } from "react-router-dom";

// Iconos
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt } from "@fortawesome/pro-solid-svg-icons";
import { faArrowRotateLeft } from "@fortawesome/pro-solid-svg-icons";
import { faSave } from "@fortawesome/pro-solid-svg-icons";
import { faList } from "@fortawesome/pro-duotone-svg-icons";
import { faUser } from "@fortawesome/pro-duotone-svg-icons";
import { faTrashAlt } from "@fortawesome/pro-solid-svg-icons";

import './Users.css';

import DataSource from 'devextreme/data/data_source';

import '../ListStyle.css';

import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
import { exportDataGrid } from 'devextreme/excel_exporter'

export default function Users(props) {
  const history = useHistory();

  const [isDetail, setIsDetail] = useState(false);
  const [userName, setUserName] = useState();
  const [userRecords, setUserRecords] = useState([]);

  //Controles para los campos tipo Checkbox
  const [conAsistencia, setConAsistencia] = useState(true);
  const [conChecklist, setConChecklist] = useState(true);
  const [conChecklistVeh, setConChecklistVeh] = useState(true);
  const [conChecklistAud, setConChecklistAud] = useState(true);
  const [conVacaciones, setConVacaciones] = useState(true);
  const [conProcesarFacturas, setConProcesarFacturas] = useState(true);
  const [conProcesarFacturasSucursal, setConProcesarFacturasSucursal] = useState(true);
  const [conProcesarFacturasBodega, setConProcesarFacturasBodega] = useState(true);

  const [conEstimate, setConEstimate] = useState(true);
  const [conLostDeal, setConLostDeal] = useState(true);
  const [conSalesOrder, setConSalesOrder] = useState(true);
  const [conInvoice, setConInvoice] = useState(true);
  const [conDirectDelivery, setConDirectDelivery] = useState(true);
  const [conDirectReturn, setConDirectReturn] = useState(true);
  const [conDeliveryRequest, setConDeliveryRequest] = useState(true);
  const [conReturnRequest, setConReturnRequest] = useState(true);
  const [conTransferOrder, setConTransferOrder] = useState(true);
  const [conSalesOrderMod, setConSalesOrderMod] = useState(true);
  const [conShipmentRequest, setConShipmentRequest] = useState(true);
  const [conCashRegister, setConCashRegister] = useState(true);
  const [conCustomerPayment, setConCustomerPayment] = useState(true);
  const [conInvTransferOrder, setConInvTransferOrder] = useState(true);
  const [conInventoryTransfer, setConInventoryTransfer] = useState(true);
  const [conInvItemFulfillment, setConInvItemFulfillment] = useState(true);
  const [conInvItemReceipt, setConInvItemReceipt] = useState(true);
  const [conSendWMS, setConSendWMS] = useState(true);
  const [conReceiveWMS, setConReceiveWMS] = useState(true);
  const [conProductsWMS, setConProductsWMS] = useState(true);
  const [conCreateLabelWMS, setConCreateLabelWMS] = useState(true);
  const [conReceiveOrderWMS, setConReceiveOrderWMS] = useState(true);
  const [conFulfillmentOrderWMS, setConFulfillmentOrderWMS] = useState(true);
  const [conAdjustItemWMS, setConAdjustItemWMS] = useState(true);
  const [conItemCountWMS, setConItemCountWMS] = useState(true);
  const [conPOSuggestionsReport, setConPOSuggestionsReport] = useState(true);

  const [sucursales, setSucursales] = useState([]);
  const [plazas, setPlazas] = useState([]);
  const [personales, setPersonales] = useState([]);
  const [usuarios, setUsuarios] = useState([]);
  const [agentes, setAgentes] = useState([]);

  const [userPayload, setUserPayload] = useState({
  });

  const [autoExpandAll, setAutoExpandAll] = useState(true);

  //Controles para expander los grupos del grid de checklist
  const [autoExpandAllChecklist, setAutoExpandAllChecklist] = useState(true);

  const [visibleLoadPanel, setVisibleLoadPanel] = useState(false);

  const [visibleToast, setVisibleToast] = useState(false);
  const [messageToast, setMessageToast] = useState('');
  const [typeToast, setTypeToast] = useState('success');

  const [ok, setOk] = useState(false);

  const filterBuilder = useMemo(() => {
    return {
      customOperations: [{
        name: 'weekends',
        caption: 'Weekends',
        dataTypes: ['date'],
        icon: 'check',
        hasValue: false,
        calculateFilterExpression: () => [[getOrderDay, '=', 0], 'or', [getOrderDay, '=', 6]],
      }],
      allowHierarchicalFields: true,
    };
  }, []);

  const filterBuilderPopupPosition = useMemo(() => {
    return {
      of: window,
      at: 'top',
      my: 'top',
      offset: { y: 10 },
    };
  }, []);

  const allowedPageSizes = useMemo(() => {
    return [5, 10, 15, 20, 'all'];
  }, []);

  const exportFormats = useMemo(() => {
    return ['xlsx'];
  }, []);

  function getOrderDay(rowData) {
    return (new Date(rowData.OrderDate)).getDay();
  };

  async function getSucursales() {
    let apiName = 'AdminSC';
    let path = '/getcatalogo';

    let myInit = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
      },
      queryStringParameters: {
        type: 'Sucursal',
        username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username
      }
    }

    var sucursalesGet = await API.get(apiName, path, myInit);

    var sucursalesTmp = [];
    for (var sucursalesGetIndex in sucursalesGet) {
      var singleSucursalesGet = sucursalesGet[sucursalesGetIndex];

      var singleSucursal = {
        name: singleSucursalesGet.Nombre,
        value: singleSucursalesGet.Sucursal.toString()
      };

      sucursalesTmp.push(singleSucursal);
    }

    setSucursales(sucursalesTmp);

    var plazasTmp = ['(TODAS)'];

    for (var sucursalIndex in sucursalesGet) {
      var singleSucursal = sucursalesGet[sucursalIndex];

      var existe = false;
      for (var plazaIndex in plazasTmp) {
        var singlePlaza = plazasTmp[plazaIndex];

        if (singlePlaza === singleSucursal.Grupo)
          existe = true;
      }

      if (existe === false && singleSucursal.Grupo)
        plazasTmp.push(singleSucursal.Grupo);
    }

    setPlazas(plazasTmp);

    return sucursalesTmp;
  };

  async function getPersonal() {
    let apiName = 'AdminSC';
    let path = '/getcatalogo';

    let myInit = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
      },
      queryStringParameters: {
        type: 'Personal',
        includeInactive: true,
        username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username
      }
    }

    var personalesGet = await API.get(apiName, path, myInit);

    var personalesTmp = [];
    for (var personalesGetIndex in personalesGet) {
      var singlePersonalesGet = personalesGet[personalesGetIndex];

      var singlePersonal = {
        name: singlePersonalesGet.ApellidoPaterno + ' ' + singlePersonalesGet.ApellidoMaterno + ' ' + singlePersonalesGet.Nombre,
        value: singlePersonalesGet.Personal
      };

      personalesTmp.push(singlePersonal);
    }

    setPersonales(personalesTmp);

    return personalesTmp;
  };

  async function getUsuario() {
    let apiName = 'AdminSC';
    let path = '/getcatalogo';

    let myInit = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
      },
      queryStringParameters: {
        type: 'Usuario',
        username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username
      }
    }

    var usuariosGet = await API.get(apiName, path, myInit);

    setUsuarios(usuariosGet);

    return usuariosGet;
  };

  async function getAgente() {
    let apiName = 'AdminSC';
    let path = '/getcatalogo';

    let myInit = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
      },
      queryStringParameters: {
        type: 'Agente',
        username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username
      }
    }

    var agentesGet = await API.get(apiName, path, myInit);

    setAgentes(agentesGet);

    return agentesGet;
  };

  async function getUsersList(sucursalList, personalList, usuarioList, agenteList) {

    // Obtenemos los usuarios de Cognito
    let apiNameCognito = 'AdminQueries';
    let pathCognito = '/listUsers';

    let myInitCognito = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
      }
    }

    const { NextToken, ...restCognito } = await API.get(apiNameCognito, pathCognito, myInitCognito);

    var users = [];

    // Obtenemos los atributos de los usuarios desde SQL
    let apiName = 'AdminSC';
    let path = '/procesausuariowebcfg';

    let myInit = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
      },
      queryStringParameters: {
        action: 'list',
        usernamereq: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username,
      }
    }

    var usersGet = await API.get(apiName, path, myInit);

    for (var singleUserIndex in restCognito.Users) {
      var singleUser = restCognito.Users[singleUserIndex];

      var addUser = {
        Username: singleUser.Username,
        Enabled: singleUser.Enabled,
        isAdmin: singleUser.isAdmin,
        lastModifiedDate: singleUser.UserLastModifiedDate,
        createdDate: singleUser.UserCreateDate,
        cognitoUserStatus: singleUser.UserStatus
      };

      if (singleUser.Enabled)
        addUser.Enabled = 'Activo'
      else
        addUser.Enabled = 'Inactivo'

      if (singleUser.isAdmin)
        addUser.isAdmin = 'Admin'
      else
        addUser.isAdmin = ''

      // Agregamos el email
      for (var attributeIndex in singleUser.Attributes) {
        var singleAttribute = singleUser.Attributes[attributeIndex];

        if (singleAttribute.Name === 'email')
          addUser.Email = singleAttribute.Value;
      }

      // Agregamos el resto de los atributos
      for (var usersGetIndex in usersGet) {
        var singleUserGet = usersGet[usersGetIndex];

        if (singleUserGet.username === singleUser.Username) {
          addUser.Sucursal = singleUserGet.Sucursal;
          addUser.Personal = singleUserGet.Personal;
          addUser.Usuario = singleUserGet.Usuario;
          addUser.Agente = singleUserGet.Agente;
          addUser.AsistenciaPlaza = singleUserGet.AsistenciaPlaza;
          addUser.Attendance = (singleUserGet.Attendance ? true : false);
          addUser.CheckList = (singleUserGet.CheckList ? true : false);
          addUser.CheckListAud = (singleUserGet.CheckListAud ? true : false);
          addUser.ChecklistPlaza = singleUserGet.ChecklistPlaza;
          addUser.CheckListVeh = (singleUserGet.CheckListVeh ? true : false);
          addUser.Commission = (singleUserGet.Commission ? true : false);
          addUser.Document = (singleUserGet.Document ? true : false);
          addUser.Incentive = (singleUserGet.Incentive ? true : false);
          addUser.Kibana = (singleUserGet.Kibana ? true : false);
          addUser.ProcesarFacturas = (singleUserGet.ProcesarFacturas ? true : false);
          addUser.ProcesarFacturasSucursal = (singleUserGet.ProcesarFacturasSucursal ? true : false);
          addUser.ProcesarFacturasBodega = (singleUserGet.ProcesarFacturasBodega ? true : false);
          addUser.PriceList = (singleUserGet.PriceList ? true : false);
          addUser.AfectarFacturas = (singleUserGet.AfectarFacturas ? true : false);
          addUser.Vacation = (singleUserGet.Vacation ? true : false);
          addUser.VacationPlaza = singleUserGet.VacationPlaza;
          addUser.NewCheckList = (singleUserGet.NewCheckList ? true : false);
          addUser.NewCheckListAud = (singleUserGet.NewCheckListAud ? true : false);
          addUser.NewCheckListVeh = (singleUserGet.NewCheckListVeh ? true : false);
          addUser.Estimate = (singleUserGet.Estimate ? true : false);
          addUser.LostDeal = (singleUserGet.LostDeal ? true : false);
          addUser.SalesOrder = (singleUserGet.SalesOrder ? true : false);
          addUser.Invoice = (singleUserGet.Invoice ? true : false);
          addUser.DirectDelivery = (singleUserGet.DirectDelivery ? true : false);
          addUser.DirectReturn = (singleUserGet.DirectReturn ? true : false);
          addUser.DeliveryRequest = (singleUserGet.DeliveryRequest ? true : false);
          addUser.ReturnRequest = (singleUserGet.ReturnRequest ? true : false);
          addUser.TransferOrder = (singleUserGet.TransferOrder ? true : false);
          addUser.SalesOrderMod = (singleUserGet.SalesOrderMod ? true : false);
          addUser.ShipmentRequest = (singleUserGet.ShipmentRequest ? true : false);
          addUser.CashRegister = (singleUserGet.CashRegister ? true : false);
          addUser.CustomerPayment = (singleUserGet.CustomerPayment ? true : false);
          addUser.InvTransferOrder = (singleUserGet.InvTransferOrder ? true : false);
          addUser.InvLevels = (singleUserGet.InvLevels ? true : false);
          addUser.InvLevelsIncCost = (singleUserGet.InvLevelsIncCost ? true : false);
          addUser.Kardex = (singleUserGet.Kardex ? true : false);
          addUser.ApproveDelivery = (singleUserGet.ApproveDelivery ? true : false);
          addUser.VoidDeliveryTransactions = (singleUserGet.VoidDeliveryTransactions ? true : false);
          addUser.SalesOrderModUpdatePrices = (singleUserGet.SalesOrderModUpdatePrices ? true : false);
          addUser.ApproveSalesOrderMod = (singleUserGet.ApproveSalesOrderMod ? true : false);
          addUser.RegenerateCFDi = (singleUserGet.RegenerateCFDi ? true : false);
          addUser.SendCFDi = (singleUserGet.SendCFDi ? true : false);
          addUser.InvItemFulfillment = (singleUserGet.InvItemFulfillment ? true : false);
          addUser.InvItemReceipt = (singleUserGet.InvItemReceipt ? true : false);

          addUser.RepComparativoVentas = (singleUserGet.RepComparativoVentas ? true : false);
          addUser.POSuggestionsReport = (singleUserGet.POSuggestionsReport ? true : false);
          addUser.POSuggestionsReportCfg = (singleUserGet.POSuggestionsReportCfg ? true : false);

          addUser.ExploreBarcodes = (singleUserGet.ExploreBarcodes ? true : false);
          addUser.ExploreBarcodeCosts = (singleUserGet.ExploreBarcodeCosts ? true : false);
          
          addUser.InventoryTransfer = (singleUserGet.InventoryTransfer ? true : false);
          addUser.SalesPlaza = singleUserGet.SalesPlaza;

          addUser.EstimateLevel = singleUserGet.EstimateLevel;
          addUser.LostDealLevel = singleUserGet.LostDealLevel;
          addUser.SalesOrderLevel = singleUserGet.SalesOrderLevel;
          addUser.InvoiceLevel = singleUserGet.InvoiceLevel;
          addUser.DirectDeliveryLevel = singleUserGet.DirectDeliveryLevel;
          addUser.DirectReturnLevel = singleUserGet.DirectReturnLevel;
          addUser.DeliveryRequestLevel = singleUserGet.DeliveryRequestLevel;
          addUser.ReturnRequestLevel = singleUserGet.ReturnRequestLevel;
          addUser.TransferOrderLevel = singleUserGet.TransferOrderLevel;
          addUser.SalesOrderModLevel = singleUserGet.SalesOrderModLevel;
          addUser.ShipmentRequestLevel = singleUserGet.ShipmentRequestLevel;
          addUser.CashRegisterLevel = singleUserGet.CashRegisterLevel;
          addUser.CustomerPaymentLevel = singleUserGet.CustomerPaymentLevel;
          addUser.InvTransferOrderLevel = singleUserGet.InvTransferOrderLevel;
          addUser.ApproveInvTransferOrder = singleUserGet.ApproveInvTransferOrder;
          addUser.ApproveInventoryTransfer = singleUserGet.ApproveInventoryTransfer;
          addUser.InventoryTransferLevel = singleUserGet.InventoryTransferLevel;
          addUser.InvItemFulfillmentLevel = singleUserGet.InvItemFulfillmentLevel;
          addUser.InvItemReceiptLevel = singleUserGet.InvItemReceiptLevel;
          addUser.SendWMS = singleUserGet.SendWMS;
          addUser.PickWMS = singleUserGet.PickWMS;
          addUser.PackWMS = singleUserGet.PackWMS;
          addUser.ShipWMS = singleUserGet.ShipWMS;
          addUser.ReceiveWMS = singleUserGet.ReceiveWMS;
          addUser.ProductsWMS = singleUserGet.ProductsWMS;
          addUser.CreateLabelWMS = singleUserGet.CreateLabelWMS;
          addUser.CreateLabelWMSLevel = singleUserGet.CreateLabelWMSLevel;
          addUser.ReceiveOrderWMS = singleUserGet.ReceiveOrderWMS;
          addUser.FulfillmentOrderWMS = singleUserGet.FulfillmentOrderWMS;
          addUser.AssignFulfillmentOrderWMS = singleUserGet.AssignFulfillmentOrderWMS;
          addUser.ReceiveOrderWMSLevel = singleUserGet.ReceiveOrderWMSLevel;
          addUser.FulfillmentOrderWMSLevel = singleUserGet.FulfillmentOrderWMSLevel;
          addUser.MoveItemWMS = singleUserGet.MoveItemWMS;
          addUser.AdjustItemWMS = singleUserGet.AdjustItemWMS;
          addUser.AdjustmentWMS = singleUserGet.AdjustmentWMS;
          addUser.ApproveAdjustItemWMS = singleUserGet.ApproveAdjustItemWMS;
          addUser.MoveWMS = singleUserGet.MoveWMS;
          addUser.CountWMS = singleUserGet.CountWMS;
          addUser.CloseSalesOrder = singleUserGet.CloseSalesOrder;
          addUser.AdjustItemWMSLevel = singleUserGet.AdjustItemWMSLevel;
          addUser.ItemCountWMS = singleUserGet.ItemCountWMS;
          addUser.ItemCountWMSLevel = singleUserGet.ItemCountWMSLevel;
          addUser.ApproveItemCountWMS = singleUserGet.ApproveItemCountWMS;
        }
      }

      // Agregamos el nombre de la sucursal
      for (var singleSucursalIndex in sucursalList) {
        var singleSucursal = sucursalList[singleSucursalIndex];

        if (singleSucursal && (addUser.Sucursal || addUser.Sucursal === 0)) {
          if (singleSucursal.value.toString() === addUser.Sucursal.toString()) {
            addUser.Sucursal = singleSucursal.name;
            break;
          }
        }
      }

      // Agregamos el nombre del Personal
      for (var singlePersonalIndex in personalList) {
        var singlePersonal = personalList[singlePersonalIndex];

        if (singlePersonal && addUser.Personal) {
          if (singlePersonal.value.toString() === addUser.Personal.toString()) {
            // addUser.Personal = singlePersonal.ApellidoPaterno + ' ' + singlePersonal.ApellidoMaterno + ' ' + singlePersonal.Nombre;
            addUser.Personal = singlePersonal.name;
            break;
          }
        }
      }

      // Agregamos el nombre del Usuario
      for (var singleUsuarioIndex in usuarioList) {
        var singleUsuario = usuarioList[singleUsuarioIndex];

        if (singleUsuario && addUser.Usuario) {
          if (singleUsuario.value.toString() === addUser.Usuario.toString()) {
            addUser.Usuario = singleUsuario.name;
            break;
          }
        }
      }

      // Agregamos el nombre del Agente
      for (var singleAgenteIndex in agenteList) {
        var singleAgente = agenteList[singleAgenteIndex];

        if (singleAgente && addUser.Agente) {
          if (singleAgente.value.toString() === addUser.Agente.toString()) {
            addUser.Agente = singleAgente.name;
            break;
          }
        }
      }

      users.push(addUser);
    }

    setUserRecords(users);
  };

  async function getUserPayload(propsUsername) {
    try {
      // Obtenemos los usuarios de Cognito
      let apiNameCognito = 'AdminQueries';
      let pathCognito = '/getUser';

      let myInitCognito = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
        },
        queryStringParameters: {
          username: propsUsername,
          usernamereq: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username
        }
      }

      var userGetCognito = await API.get(apiNameCognito, pathCognito, myInitCognito);

      // Obtenemos los atributos de los usuarios desde SQL
      let apiName = 'AdminSC';
      let path = '/procesausuariowebcfg';

      let myInit = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
        },
        queryStringParameters: {
          action: 'payload',
          username: propsUsername,
          usernamereq: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username
        }
      }

      var userAttributes = await API.get(apiName, path, myInit);

      var user = {
        Username: userGetCognito.Username,
        Enabled: userGetCognito.Enabled,
        isAdmin: ''
      };

      if (user.Enabled)
        user.Enabled = 'Activo'
      else
        user.Enabled = 'Inactivo'

      for (var attributeIndex in userGetCognito.UserAttributes) {
        var singleAttribute = userGetCognito.UserAttributes[attributeIndex];

        if (singleAttribute.Name === 'email')
          user.Email = singleAttribute.Value;
      }

      if (userAttributes.attributes.Sucursal || userAttributes.attributes.Sucursal === 0)
        user.Sucursal = userAttributes.attributes.Sucursal.toString();


      user.Personal = userAttributes.attributes.Personal;
      user.Usuario = userAttributes.attributes.Usuario;
      user.Agente = userAttributes.attributes.Agente;
      user.Attendance = (userAttributes.attributes.Attendance ? true : false);

      if (userAttributes.attributes.AsistenciaPlaza) {
        if (userAttributes.attributes.AsistenciaPlaza !== '' && userAttributes.attributes.AsistenciaPlaza !== '|') {
          var asistenciaPlaza = userAttributes.attributes.AsistenciaPlaza.split('|');
          asistenciaPlaza.pop();
          user.asistenciaPlaza = asistenciaPlaza;
        }
        else {
          user.asistenciaPlaza = [];
        }
      }
      else {
        user.asistenciaPlaza = [];
      }

      user.PriceList = (userAttributes.attributes.PriceList ? true : false);
      user.Commission = (userAttributes.attributes.Commission ? true : false);
      user.Incentive = (userAttributes.attributes.Incentive ? true : false);
      user.CheckList = (userAttributes.attributes.CheckList ? true : false);
      user.CheckListAud = (userAttributes.attributes.CheckListAud ? true : false);
      user.CheckListVeh = (userAttributes.attributes.CheckListVeh ? true : false);

      if (userAttributes.attributes.ChecklistPlaza) {
        if (userAttributes.attributes.ChecklistPlaza !== '' && userAttributes.attributes.ChecklistPlaza !== '|') {
          var checklistPlaza = userAttributes.attributes.ChecklistPlaza.split('|');
          checklistPlaza.pop();
          user.checklistPlaza = checklistPlaza;
        }
        else {
          user.checklistPlaza = [];
        }
      }
      else {
        user.checklistPlaza = [];
      }

      user.Document = (userAttributes.attributes.Document ? true : false);
      user.Vacation = (userAttributes.attributes.Vacation ? true : false);

      if (userAttributes.attributes.VacationPlaza) {
        if (userAttributes.attributes.VacationPlaza !== '' && userAttributes.attributes.VacationPlaza !== '|') {
          var vacationPlaza = userAttributes.attributes.VacationPlaza.split('|');
          vacationPlaza.pop();
          user.vacationPlaza = vacationPlaza;
        }
        else {
          user.vacationPlaza = [];
        }
      }
      else {
        user.vacationPlaza = [];
      }

      if (userAttributes.attributes.SalesPlaza) {
        if (userAttributes.attributes.SalesPlaza !== '' && userAttributes.attributes.SalesPlaza !== '|') {
          var salesPlaza = userAttributes.attributes.SalesPlaza.split('|');
          salesPlaza.pop();
          user.salesPlaza = salesPlaza;
        }
        else {
          user.salesPlaza = [];
        }
      }
      else {
        user.salesPlaza = [];
      }

      user.Kibana = (userAttributes.attributes.Kibana ? true : false);
      user.ProcesarFacturas = (userAttributes.attributes.ProcesarFacturas ? true : false);
      user.ProcesarFacturasSucursal = (userAttributes.attributes.ProcesarFacturasSucursal ? true : false);
      user.ProcesarFacturasBodega = (userAttributes.attributes.ProcesarFacturasBodega ? true : false);
      user.AfectarFacturas = (userAttributes.attributes.AfectarFacturas ? true : false);
      user.NewCheckList = (userAttributes.attributes.NewCheckList ? true : false);
      user.NewCheckListAud = (userAttributes.attributes.NewCheckListAud ? true : false);
      user.NewCheckListVeh = (userAttributes.attributes.NewCheckListVeh ? true : false);
      user.Estimate = (userAttributes.attributes.Estimate ? true : false);
      user.LostDeal = (userAttributes.attributes.LostDeal ? true : false);
      user.SalesOrder = (userAttributes.attributes.SalesOrder ? true : false);
      user.Invoice = (userAttributes.attributes.Invoice ? true : false);
      user.DirectDelivery = (userAttributes.attributes.DirectDelivery ? true : false);
      user.DirectReturn = (userAttributes.attributes.DirectReturn ? true : false);
      user.DeliveryRequest = (userAttributes.attributes.DeliveryRequest ? true : false);
      user.ReturnRequest = (userAttributes.attributes.ReturnRequest ? true : false);
      user.TransferOrder = (userAttributes.attributes.TransferOrder ? true : false);
      user.SalesOrderMod = (userAttributes.attributes.SalesOrderMod ? true : false);
      user.ShipmentRequest = (userAttributes.attributes.ShipmentRequest ? true : false);
      user.CashRegister = (userAttributes.attributes.CashRegister ? true : false);
      user.CustomerPayment = (userAttributes.attributes.CustomerPayment ? true : false);
      user.InvTransferOrder = (userAttributes.attributes.InvTransferOrder ? true : false);
      user.InvLevels = (userAttributes.attributes.InvLevels ? true : false);
      user.InvLevelsIncCost = (userAttributes.attributes.InvLevelsIncCost ? true : false);
      user.Kardex = (userAttributes.attributes.Kardex ? true : false);
      user.ApproveDelivery = (userAttributes.attributes.ApproveDelivery ? true : false);
      user.VoidDeliveryTransactions = (userAttributes.attributes.VoidDeliveryTransactions ? true : false);
      user.SalesOrderModUpdatePrices = (userAttributes.attributes.SalesOrderModUpdatePrices ? true : false);
      user.ApproveSalesOrderMod = (userAttributes.attributes.ApproveSalesOrderMod ? true : false);
      user.RegenerateCFDi = (userAttributes.attributes.RegenerateCFDi ? true : false);
      user.SendCFDi = (userAttributes.attributes.SendCFDi ? true : false);
      user.RepComparativoVentas = (userAttributes.attributes.RepComparativoVentas ? true : false);
      user.POSuggestionsReport = (userAttributes.attributes.POSuggestionsReport ? true : false);
      user.POSuggestionsReportCfg = (userAttributes.attributes.POSuggestionsReportCfg ? true : false);

      user.ExploreBarcodes = (userAttributes.attributes.ExploreBarcodes ? true : false);
      user.ExploreBarcodeCosts = (userAttributes.attributes.ExploreBarcodeCosts ? true : false);
      
      user.InventoryTransfer = (userAttributes.attributes.InventoryTransfer ? true : false);
      user.ApproveInvTransferOrder = (userAttributes.attributes.ApproveInvTransferOrder ? true : false);
      user.ApproveInventoryTransfer = (userAttributes.attributes.ApproveInventoryTransfer ? true : false);
      user.InvItemFulfillment = (userAttributes.attributes.InvItemFulfillment ? true : false);
      user.InvItemReceipt = (userAttributes.attributes.InvItemReceipt ? true : false);

      user.EstimateLevel = userAttributes.attributes.EstimateLevel;
      user.LostDealLevel = userAttributes.attributes.LostDealLevel;
      user.SalesOrderLevel = userAttributes.attributes.SalesOrderLevel;
      user.InvoiceLevel = userAttributes.attributes.InvoiceLevel;
      user.DirectDeliveryLevel = userAttributes.attributes.DirectDeliveryLevel;
      user.DirectReturnLevel = userAttributes.attributes.DirectReturnLevel;
      user.DeliveryRequestLevel = userAttributes.attributes.DeliveryRequestLevel;
      user.ReturnRequestLevel = userAttributes.attributes.ReturnRequestLevel;
      user.TransferOrderLevel = userAttributes.attributes.TransferOrderLevel;
      user.SalesOrderModLevel = userAttributes.attributes.SalesOrderModLevel;
      user.ShipmentRequestLevel = userAttributes.attributes.ShipmentRequestLevel;
      user.CashRegisterLevel = userAttributes.attributes.CashRegisterLevel;
      user.CustomerPaymentLevel = userAttributes.attributes.CustomerPaymentLevel;
      user.InvTransferOrderLevel = userAttributes.attributes.InvTransferOrderLevel;
      user.InventoryTransferLevel = userAttributes.attributes.InventoryTransferLevel;
      user.InvItemFulfillmentLevel = userAttributes.attributes.InvItemFulfillmentLevel;
      user.InvItemReceiptLevel = userAttributes.attributes.InvItemReceiptLevel;
      user.SendWMS = (userAttributes.attributes.SendWMS ? true : false);
      user.PickWMS = (userAttributes.attributes.PickWMS ? true : false);
      user.PackWMS = (userAttributes.attributes.PackWMS ? true : false);
      user.ShipWMS = (userAttributes.attributes.ShipWMS ? true : false);
      user.ReceiveWMS = (userAttributes.attributes.ReceiveWMS ? true : false);
      user.ProductsWMS = (userAttributes.attributes.ProductsWMS ? true : false);
      user.CreateLabelWMS = (userAttributes.attributes.CreateLabelWMS ? true : false);
      user.CreateLabelWMSLevel = userAttributes.attributes.CreateLabelWMSLevel;
      user.ReceiveOrderWMS = (userAttributes.attributes.ReceiveOrderWMS ? true : false);
      user.ReceiveOrderWMSLevel = userAttributes.attributes.ReceiveOrderWMSLevel;
      user.FulfillmentOrderWMS = (userAttributes.attributes.FulfillmentOrderWMS ? true : false);
      user.AssignFulfillmentOrderWMS = (userAttributes.attributes.AssignFulfillmentOrderWMS ? true : false);
      user.FulfillmentOrderWMSLevel = userAttributes.attributes.FulfillmentOrderWMSLevel;
      user.AdjustmentWMS = (userAttributes.attributes.AdjustmentWMS ? true : false);
      user.ApproveAdjustItemWMS = (userAttributes.attributes.ApproveAdjustItemWMS ? true : false);
      user.AdjustItemWMS = (userAttributes.attributes.AdjustItemWMS ? true : false);
      user.MoveWMS = (userAttributes.attributes.MoveWMS ? true : false);
      user.CountWMS = (userAttributes.attributes.CountWMS ? true : false);
      user.CloseSalesOrder = (userAttributes.attributes.CloseSalesOrder ? true : false);
      user.MoveItemWMS = (userAttributes.attributes.MoveItemWMS ? true : false);
      user.AdjustItemWMSLevel = userAttributes.attributes.AdjustItemWMSLevel;

      user.ItemCountWMS = (userAttributes.attributes.ItemCountWMS ? true : false);
      user.ApproveItemCountWMS = (userAttributes.attributes.ApproveItemCountWMS ? true : false);
      user.ItemCountWMSLevel = userAttributes.attributes.ItemCountWMSLevel;

      let apiNameGroups = 'AdminQueries';
      let pathGroups = '/listGroupsForUser';

      let myInitGroups = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
        },
        queryStringParameters: {
          username: propsUsername,
          usernamereq: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username
        }
      }

      var userGroupsGet = await API.get(apiNameGroups, pathGroups, myInitGroups);

      for (var groupIndex in userGroupsGet.Groups) {
        var singleGroup = userGroupsGet.Groups[groupIndex];

        user.isAdmin = singleGroup.GroupName;
      }

      //Asignamos las variables para los controles tipo CheckBox
      setConAsistencia(user.Attendance);
      setConChecklist(user.CheckList);
      setConChecklistAud(user.CheckListAud);
      setConChecklistVeh(user.CheckListVeh);
      setConVacaciones(user.Vacation)
      setConProcesarFacturas(user.ProcesarFacturas);
      setConProcesarFacturasBodega(user.ProcesarFacturasBodega);
      setConProcesarFacturasSucursal(user.ProcesarFacturasSucursal);
      setConEstimate(user.Estimate);
      setConLostDeal(user.LostDeal);
      setConSalesOrder(user.SalesOrder);
      setConInvoice(user.Invoice);
      setConDirectDelivery(user.DirectDelivery);
      setConDirectReturn(user.DirectReturn);
      setConDeliveryRequest(user.DeliveryRequest);
      setConReturnRequest(user.ReturnRequest);
      setConTransferOrder(user.TransferOrder);
      setConSalesOrderMod(user.SalesOrderMod);
      setConShipmentRequest(user.ShipmentRequest);
      setConCashRegister(user.CashRegister);
      setConCustomerPayment(user.CustomerPayment);
      setConInvTransferOrder(user.InvTransferOrder);
      setConInventoryTransfer(user.InventoryTransfer);
      setConInvItemFulfillment(user.InvItemFulfillment);
      setConInvItemReceipt(user.InvItemReceipt);
      setConSendWMS(user.SendWMS);
      setConReceiveWMS(user.ReceiveWMS);
      setConProductsWMS(user.ProductsWMS);
      setConCreateLabelWMS(user.CreateLabelWMS);
      setConReceiveOrderWMS(user.ReceiveOrderWMS);
      setConFulfillmentOrderWMS(user.FulfillmentOrderWMS);
      setConAdjustItemWMS(user.AdjustItemWMS);
      setConItemCountWMS(user.ItemCountWMS);
      setConPOSuggestionsReport(user.POSuggestionsReport);
    }
    catch (e) {
      if (e === 'No current user') {
        history.push('/');

        window.location.reload();
      }
    }

    return user;
  }

  async function editUserButton(Username) {
    if (!window.event.metaKey && !window.event.ctrlKey) {
      setVisibleLoadPanel(true);

      setIsDetail(true);

      setUserName(Username);

      var payload = await getUserPayload(Username);

      setUserPayload(payload);

      setVisibleLoadPanel(false);
    }
  };

  async function cancelEditButton() {
    setVisibleLoadPanel(true);

    setIsDetail(false);

    var sucursalList = await getSucursales();
    var personalList = await getPersonal();
    var usuarioList = await getUsuario();
    var agenteList = await getAgente();

    await getUsersList(sucursalList, personalList, usuarioList, agenteList);

    setVisibleLoadPanel(false);

    history.push('/Users');
  };

  async function deleteUser() {
    try {
      var username = userPayload.Username;

      if (username) {
        var r = window.confirm('Se eliminará el usuario ' + username + '. ¿Desea Continuar?');

        if (r) {
          let apiNameCognito = 'AdminQueries';
          let pathCognito = '/deleteUser';

          let myInitCognito = {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
            },
            body: {
              username: username
            }
          }

          let apiName = 'AdminSC';
          let path = '/procesausuariowebcfg';

          let myInit = {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
            },
            body: {
              username: username,
              usernamereq: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username,
              action: 'archive',
            }
          }

          try {
            setVisibleLoadPanel(true);

            await API.post(apiNameCognito, pathCognito, myInitCognito);

            await API.post(apiName, path, myInit);

            setVisibleToast(true);
            setMessageToast('Usuario eliminado con éxito');
            setTypeToast('success')

            setIsDetail(false);

            var sucursalList = await getSucursales();
            var personalList = await getPersonal();
            var usuarioList = await getUsuario();
            var agenteList = await getAgente();

            await getUsersList(sucursalList, personalList, usuarioList, agenteList);

            setVisibleLoadPanel(false);

            history.push('/Users');

          }
          catch (error) {
            setVisibleLoadPanel(false);

            setVisibleToast(true);
            setMessageToast(error.message);
            setTypeToast('error')
          }
        }
      }
    }
    catch (e) {
      if (e === 'No current user') {
        history.push('/');

        window.location.reload();
      }
    }
  }

  async function saveButton() {
    try {
      var username = userPayload.Username;
      var password = userPayload.Password;
      var confirmpassword = userPayload.ConfirmPassword;
      var group = userPayload.isAdmin;
      var sucursal = userPayload.Sucursal;
      var personal = userPayload.Personal;
      var usuario = userPayload.Usuario;
      var agente = userPayload.Agente;
      var email = userPayload.Email;
      var isenabled = userPayload.Enabled;
      var Attendance = userPayload.Attendance;
      var CheckList = userPayload.CheckList;
      var CheckListAud = userPayload.CheckListAud;
      var CheckListVeh = userPayload.CheckListVeh;
      var Commission = userPayload.Commission;
      var Document = userPayload.Document;
      var Incentive = userPayload.Incentive;
      var Kibana = userPayload.Kibana;
      var ProcesarFacturas = userPayload.ProcesarFacturas;
      var ProcesarFacturasSucursal = userPayload.ProcesarFacturasSucursal;
      var ProcesarFacturasBodega = userPayload.ProcesarFacturasBodega;
      var AfectarFacturas = userPayload.AfectarFacturas;
      var PriceList = userPayload.PriceList;
      var Vacation = userPayload.Vacation;
      var NewCheckList = userPayload.NewCheckList;
      var NewCheckListAud = userPayload.NewCheckListAud;
      var NewCheckListVeh = userPayload.NewCheckListVeh;
      var Estimate = userPayload.Estimate;
      var LostDeal = userPayload.LostDeal;
      var SalesOrder = userPayload.SalesOrder;
      var Invoice = userPayload.Invoice;
      var DirectDelivery = userPayload.DirectDelivery;
      var DirectReturn = userPayload.DirectReturn;
      var DeliveryRequest = userPayload.DeliveryRequest;
      var ReturnRequest = userPayload.ReturnRequest;
      var TransferOrder = userPayload.TransferOrder;
      var SalesOrderMod = userPayload.SalesOrderMod;
      var ShipmentRequest = userPayload.ShipmentRequest;
      var CashRegister = userPayload.CashRegister;
      var CustomerPayment = userPayload.CustomerPayment;
      var InvTransferOrder = userPayload.InvTransferOrder;
      var InvLevels = userPayload.InvLevels;
      var InvLevelsIncCost = userPayload.InvLevelsIncCost;
      var Kardex = userPayload.Kardex;
      var ApproveDelivery = userPayload.ApproveDelivery;
      var VoidDeliveryTransactions = userPayload.VoidDeliveryTransactions;
      var SalesOrderModUpdatePrices = userPayload.SalesOrderModUpdatePrices;
      var ApproveSalesOrderMod = userPayload.ApproveSalesOrderMod;
      var RegenerateCFDi = userPayload.RegenerateCFDi;
      var SendCFDi = userPayload.SendCFDi;
      var RepComparativoVentas = userPayload.RepComparativoVentas;
      var POSuggestionsReport = userPayload.POSuggestionsReport;
      var POSuggestionsReportCfg = userPayload.POSuggestionsReportCfg;
      var ExploreBarcodes = userPayload.ExploreBarcodes;
      var ExploreBarcodeCosts = userPayload.ExploreBarcodeCosts;
      
      var InventoryTransfer = userPayload.InventoryTransfer;
      var EstimateLevel = userPayload.EstimateLevel;
      var LostDealLevel = userPayload.LostDealLevel;
      var SalesOrderLevel = userPayload.SalesOrderLevel;
      var InvoiceLevel = userPayload.InvoiceLevel;
      var DirectDeliveryLevel = userPayload.DirectDeliveryLevel;
      var DirectReturnLevel = userPayload.DirectReturnLevel;
      var DeliveryRequestLevel = userPayload.DeliveryRequestLevel;
      var ReturnRequestLevel = userPayload.ReturnRequestLevel;
      var TransferOrderLevel = userPayload.TransferOrderLevel;
      var SalesOrderModLevel = userPayload.SalesOrderModLevel;
      var ShipmentRequestLevel = userPayload.ShipmentRequestLevel;
      var CashRegisterLevel = userPayload.CashRegisterLevel;
      var CustomerPaymentLevel = userPayload.CustomerPaymentLevel;
      var InvTransferOrderLevel = userPayload.InvTransferOrderLevel;
      var InventoryTransferLevel = userPayload.InventoryTransferLevel;
      var ApproveInvTransferOrder = userPayload.ApproveInvTransferOrder;
      var ApproveInventoryTransfer = userPayload.ApproveInventoryTransfer;
      var InvItemFulfillment = userPayload.InvItemFulfillment;
      var InvItemFulfillmentLevel = userPayload.InvItemFulfillmentLevel;
      var InvItemReceipt = userPayload.InvItemReceipt;
      var InvItemReceiptLevel = userPayload.InvItemReceiptLevel;

      var asistenciaPlaza = userPayload.asistenciaPlaza;
      var checklistPlaza = userPayload.checklistPlaza;
      var vacationPlaza = userPayload.vacationPlaza;
      var salesPlaza = userPayload.salesPlaza;

      var SendWMS = userPayload.SendWMS;
      var PickWMS = userPayload.PickWMS;
      var PackWMS = userPayload.PackWMS;
      var ShipWMS = userPayload.ShipWMS;

      var ReceiveWMS = userPayload.ReceiveWMS;
      var ProductsWMS = userPayload.ProductsWMS;

      var CreateLabelWMS = userPayload.CreateLabelWMS;
      var CreateLabelWMSLevel = userPayload.CreateLabelWMSLevel;
      var ReceiveOrderWMS = userPayload.ReceiveOrderWMS;
      var ReceiveOrderWMSLevel = userPayload.ReceiveOrderWMSLevel;
      var FulfillmentOrderWMS = userPayload.FulfillmentOrderWMS;
      var AssignFulfillmentOrderWMS = userPayload.AssignFulfillmentOrderWMS;
      var FulfillmentOrderWMSLevel = userPayload.FulfillmentOrderWMSLevel;
      var AdjustmentWMS = userPayload.AdjustmentWMS;
      var ApproveAdjustItemWMS = userPayload.ApproveAdjustItemWMS;
      var AdjustItemWMS = userPayload.AdjustItemWMS;
      var MoveWMS = userPayload.MoveWMS;
      var CountWMS = userPayload.CountWMS;
      var CloseSalesOrder = userPayload.CloseSalesOrder;
      var MoveItemWMS = userPayload.MoveItemWMS;
      var AdjustItemWMSLevel = userPayload.AdjustItemWMSLevel;
      var ItemCountWMS = userPayload.ItemCountWMS;
      var ItemCountWMSLevel = userPayload.ItemCountWMSLevel;
      var ApproveItemCountWMS = userPayload.ApproveItemCountWMS;

      var okAdmin = false;
      var user = await Auth.currentAuthenticatedUser();
      var groups = user.signInUserSession.accessToken.payload["cognito:groups"];

      for (var groupIndex in groups) {
        var singleGroup = groups[groupIndex];

        if (singleGroup === 'Admin') {
          okAdmin = true;
        }
      }

      if (okAdmin === false) {
        setVisibleToast(true);
        setMessageToast('Unauthorized');
        setTypeToast('error');
        return;
      }

      if (!Attendance)
        Attendance = false;
      if (!CheckList)
        CheckList = false;
      if (!CheckListAud)
        CheckListAud = false;
      if (!CheckListVeh)
        CheckListVeh = false;
      if (!Commission)
        Commission = false;
      if (!Document)
        Document = false;
      if (!Incentive)
        Incentive = false;
      if (!Kibana)
        Kibana = false;
      if (!ProcesarFacturas)
        ProcesarFacturas = false;
      if (!ProcesarFacturasSucursal)
        ProcesarFacturasSucursal = false;
      if (!ProcesarFacturasBodega)
        ProcesarFacturasBodega = false;
      if (!AfectarFacturas)
        AfectarFacturas = false;
      if (!PriceList)
        PriceList = false;
      if (!Vacation)
        Vacation = false;
      if (!NewCheckList)
        NewCheckList = false;
      if (!NewCheckListAud)
        NewCheckListAud = false;
      if (!NewCheckListVeh)
        NewCheckListVeh = false;
      if (!Estimate)
        Estimate = false;
      if (!LostDeal)
        LostDeal = false;
      if (!SalesOrder)
        SalesOrder = false;
      if (!Invoice)
        Invoice = false;
      if (!DirectDelivery)
        DirectDelivery = false;
      if (!DirectReturn)
        DirectReturn = false;
      if (!DeliveryRequest)
        DeliveryRequest = false;
      if (!ReturnRequest)
        ReturnRequest = false;
      if (!TransferOrder)
        TransferOrder = false;
      if (!SalesOrderMod)
        SalesOrderMod = false;
      if (!ShipmentRequest)
        ShipmentRequest = false;
      if (!CashRegister)
        CashRegister = false;
      if (!CustomerPayment)
        CustomerPayment = false;
      if (!InvTransferOrder)
        InvTransferOrder = false;
      if (!InvLevels)
        InvLevels = false;
      if (!InvLevelsIncCost)
        InvLevelsIncCost = false;
      if (!Kardex)
        Kardex = false;
      if (!ApproveDelivery)
        ApproveDelivery = false;
      if (!VoidDeliveryTransactions)
        VoidDeliveryTransactions = false;
      if (!SalesOrderModUpdatePrices)
        SalesOrderModUpdatePrices = false;
      if (!ApproveSalesOrderMod)
        ApproveSalesOrderMod = false;
      if (!RegenerateCFDi)
        RegenerateCFDi = false;
      if (!SendCFDi)
        SendCFDi = false;
      if (!RepComparativoVentas)
        RepComparativoVentas = false;
      if (!POSuggestionsReport)
        POSuggestionsReport = false;
      if (!POSuggestionsReportCfg)
        POSuggestionsReportCfg = false;
      if (!ExploreBarcodes)
        ExploreBarcodes = false;
      if (!ExploreBarcodeCosts)
        ExploreBarcodeCosts = false;
      if (!InventoryTransfer)
        InventoryTransfer = false;
      if (!InvItemFulfillment)
        InvItemFulfillment = false;
      if (!InvItemReceipt)
        InvItemReceipt = false;
      if (!SendWMS)
        SendWMS = false;
      if (!PickWMS)
        PickWMS = false;
      if (!PackWMS)
        PackWMS = false;
      if (!ShipWMS)
        ShipWMS = false;
      if (!ReceiveWMS)
        ReceiveWMS = false;
      if (!ProductsWMS)
        ProductsWMS = false;
      if (!CreateLabelWMS)
        CreateLabelWMS = false;
      if (!ReceiveOrderWMS)
        ReceiveOrderWMS = false;
      if (!FulfillmentOrderWMS)
        FulfillmentOrderWMS = false;
      if (!AssignFulfillmentOrderWMS)
        AssignFulfillmentOrderWMS = false;
      if (!AdjustmentWMS)
        AdjustmentWMS = false;
      if (!ApproveAdjustItemWMS)
        ApproveAdjustItemWMS = false;
      if (!AdjustItemWMS)
        AdjustItemWMS = false;
      if (!MoveWMS)
        MoveWMS = false;
      if (!CountWMS)
        CountWMS = false;
      if (!CloseSalesOrder)
        CloseSalesOrder = false;
      if (!MoveItemWMS)
        MoveItemWMS = false;
      if(!ItemCountWMS)
        ItemCountWMS = false;
      if (!ApproveItemCountWMS)
        ApproveItemCountWMS = false;

      if (username) {
        if (!password)
          password = '';
        if (!confirmpassword)
          confirmpassword = '';
        if (!group)
          group = '';

        if (!sucursal && sucursal !== 0)
          sucursal = '';

        if (!personal)
          personal = '';

        if (!usuario)
          usuario = '';

        if (!agente)
          agente = '';

        if (password) {
          if (password.length < 8) {
            setVisibleToast(true);
            setMessageToast('La contraseña debe tener mínimo 8 caracteres');
            setTypeToast('error')
            return;
          }
          if (password !== confirmpassword) {
            setVisibleToast(true);
            setMessageToast('Las contraseñas no coinciden');
            setTypeToast('error')
            return;
          }
        }

        try {
          var attendanceAccess = asistenciaPlaza.join('|') + '|';
        }
        catch (e) {
          var attendanceAccess = '';
        }

        try {
          var checklistAccess = checklistPlaza.join('|') + '|';
        }
        catch (e) {
          var checklistAccess = '';
        }

        try {
          var vacationAccess = vacationPlaza.join('|') + '|';
        }
        catch (e) {
          var vacationAccess = '';
        }

        try {
          var salesAccess = salesPlaza.join('|') + '|';
        }
        catch (e) {
          var salesAccess = '';
        }

        // Actualizamos los datos en Cognito
        let apiNameCognito = 'AdminQueries';
        let pathCognito = '/updateUser';

        let myInitCognito = {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
          },
          body: {
            email: email,
            username: username,
            password: password,
            confirmpassword: confirmpassword,
            isenabled: isenabled,
            group: group
          }
        }

        // Actualizamos los datos en SQL
        let apiName = 'AdminSC';
        let path = '/procesausuariowebcfg';

        let myInit = {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
          },
          body: {
            username: username,
            usernamereq: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username,
            action: 'saveChanges',
            attributes: {
              username: username,
              grupo: group,
              Sucursal: sucursal,
              Personal: personal,
              Usuario: usuario,
              Agente: agente,
              Attendance: (Attendance ? 1 : 0),
              AsistenciaPlaza: attendanceAccess,
              PriceList: (PriceList ? 1 : 0),
              Commission: (Commission ? 1 : 0),
              Incentive: (Incentive ? 1 : 0),
              CheckList: (CheckList ? 1 : 0),
              CheckListAud: (CheckListAud ? 1 : 0),
              CheckListVeh: (CheckListVeh ? 1 : 0),
              ChecklistPlaza: checklistAccess,
              Document: (Document ? 1 : 0),
              Vacation: (Vacation ? 1 : 0),
              VacationPlaza: vacationAccess,
              Kibana: (Kibana ? 1 : 0),
              ProcesarFacturas: (ProcesarFacturas ? 1 : 0),
              ProcesarFacturasSucursal: (ProcesarFacturasSucursal ? 1 : 0),
              ProcesarFacturasBodega: (ProcesarFacturasBodega ? 1 : 0),
              AfectarFacturas: (AfectarFacturas ? 1 : 0),
              NewCheckList: (NewCheckList ? 1 : 0),
              NewCheckListAud: (NewCheckListAud ? 1 : 0),
              NewCheckListVeh: (NewCheckListVeh ? 1 : 0),
              Estimate: (Estimate ? 1 : 0),
              LostDeal: (LostDeal ? 1 : 0),
              SalesOrder: (SalesOrder ? 1 : 0),
              Invoice: (Invoice ? 1 : 0),
              DirectDelivery: (DirectDelivery ? 1 : 0),
              DirectReturn: (DirectReturn ? 1 : 0),
              DeliveryRequest: (DeliveryRequest ? 1 : 0),
              ReturnRequest: (ReturnRequest ? 1 : 0),
              TransferOrder: (TransferOrder ? 1 : 0),
              SalesOrderMod: (SalesOrderMod ? 1 : 0),
              ShipmentRequest: (ShipmentRequest ? 1 : 0),
              CashRegister: (CashRegister ? 1 : 0),
              CustomerPayment: (CustomerPayment ? 1 : 0),
              InvTransferOrder: (InvTransferOrder ? 1 : 0),
              InvLevels: (InvLevels ? 1 : 0),
              InvLevelsIncCost: (InvLevelsIncCost ? 1 : 0),
              Kardex: (Kardex ? 1 : 0),
              ApproveDelivery: (ApproveDelivery ? 1 : 0),
              VoidDeliveryTransactions: (VoidDeliveryTransactions ? 1 : 0),
              SalesOrderModUpdatePrices: (SalesOrderModUpdatePrices ? 1 : 0),
              ApproveSalesOrderMod: (ApproveSalesOrderMod ? 1 : 0),
              RegenerateCFDi: (RegenerateCFDi ? 1 : 0),
              SendCFDi: (SendCFDi ? 1 : 0),
              RepComparativoVentas: (RepComparativoVentas ? 1 : 0),
              POSuggestionsReport: (POSuggestionsReport ? 1 : 0),
              POSuggestionsReportCfg: (POSuggestionsReportCfg ? 1 : 0),
              ExploreBarcodes: (ExploreBarcodes ? 1 : 0),
              ExploreBarcodeCosts: (ExploreBarcodeCosts ? 1 : 0),
              InventoryTransfer: (InventoryTransfer ? 1 : 0),
              EstimateLevel: EstimateLevel,
              LostDealLevel: LostDealLevel,
              SalesOrderLevel: SalesOrderLevel,
              InvoiceLevel: InvoiceLevel,
              DirectDeliveryLevel: DirectDeliveryLevel,
              DirectReturnLevel: DirectReturnLevel,
              DeliveryRequestLevel: DeliveryRequestLevel,
              ReturnRequestLevel: ReturnRequestLevel,
              TransferOrderLevel: TransferOrderLevel,
              SalesOrderModLevel: SalesOrderModLevel,
              ShipmentRequestLevel: ShipmentRequestLevel,
              CashRegisterLevel: CashRegisterLevel,
              CustomerPaymentLevel: CustomerPaymentLevel,
              InvTransferOrderLevel: InvTransferOrderLevel,
              InventoryTransferLevel: InventoryTransferLevel,
              ApproveInvTransferOrder: (ApproveInvTransferOrder ? 1 : 0),
              ApproveInventoryTransfer: (ApproveInventoryTransfer ? 1 : 0),
              SalesPlaza: salesAccess,
              InvItemFulfillment: (InvItemFulfillment ? 1 : 0),
              InvItemFulfillmentLevel: InvItemFulfillmentLevel,
              InvItemReceipt: (InvItemReceipt ? 1 : 0),
              InvItemReceiptLevel: InvItemReceiptLevel,
              SendWMS: (SendWMS ? 1 : 0),
              PickWMS: (PickWMS ? 1 : 0),
              PackWMS: (PackWMS ? 1 : 0),
              ShipWMS: (ShipWMS ? 1 : 0),
              ReceiveWMS: (ReceiveWMS ? 1 : 0),
              ProductsWMS: (ProductsWMS ? 1 : 0),
              CreateLabelWMS: (CreateLabelWMS ? 1 : 0),
              CreateLabelWMSLevel: CreateLabelWMSLevel,
              ReceiveOrderWMS: (ReceiveOrderWMS ? 1 : 0),
              ReceiveOrderWMSLevel: ReceiveOrderWMSLevel,
              FulfillmentOrderWMS: (FulfillmentOrderWMS ? 1 : 0),
              AssignFulfillmentOrderWMS: (AssignFulfillmentOrderWMS ? 1 : 0),
              FulfillmentOrderWMSLevel: FulfillmentOrderWMSLevel,
              AdjustmentWMS: (AdjustmentWMS ? 1 : 0),
              ApproveAdjustItemWMS: (ApproveAdjustItemWMS ? 1 : 0),
              AdjustItemWMS: (AdjustItemWMS ? 1 : 0),
              MoveWMS: (MoveWMS ? 1 : 0),
              CountWMS: (CountWMS ? 1 : 0),
              CloseSalesOrder: (CloseSalesOrder ? 1 : 0),
              MoveItemWMS: (MoveItemWMS ? 1 : 0),
              AdjustItemWMSLevel: AdjustItemWMSLevel,
              ItemCountWMS: (ItemCountWMS ? 1 : 0),
              ItemCountWMSLevel: ItemCountWMSLevel,
              ApproveItemCountWMS: (ApproveItemCountWMS ? 1 : 0)
            }
          }
        }

        try {
          setVisibleLoadPanel(true);

          await API.post(apiNameCognito, pathCognito, myInitCognito);

          var payloadPost = await API.post(apiName, path, myInit);

          setVisibleLoadPanel(false);

          if (payloadPost.ok === 'true') {
            setVisibleToast(true);
            setMessageToast('Usuario actualizado con éxito');
            setTypeToast('success')
          }
          else {
            setVisibleToast(true);
            setMessageToast(payloadPost.message);
            setTypeToast('error')
          }
        }
        catch (error) {
          setVisibleLoadPanel(false);

          setVisibleToast(true);
          setMessageToast(error.message);
          setTypeToast('error')
        }
      }
    }
    catch (e) {
      if (e === 'No current user') {
        history.push('/');

        window.location.reload();
      }
    }
  };


  const onFieldDataChanged = React.useCallback((e) => {
    if (e.dataField === 'Attendance') {
      setConAsistencia(!conAsistencia);
    }
    if (e.dataField === 'CheckList') {
      setConChecklist(!conChecklist);
    }
    if (e.dataField === 'CheckListAud') {
      setConChecklistAud(!conChecklistAud);
    }
    if (e.dataField === 'CheckListVeh') {
      setConChecklistVeh(!conChecklistVeh);
    }
    if (e.dataField === 'Vacation') {
      setConVacaciones(!conVacaciones);
    }
    if (e.dataField === 'ProcesarFacturas') {
      setConProcesarFacturas(!conProcesarFacturas);
    }
    if (e.dataField === 'ProcesarFacturasBodega') {
      setConProcesarFacturasBodega(!conProcesarFacturasBodega);
    }
    if (e.dataField === 'ProcesarFacturasSucursal') {
      setConProcesarFacturasSucursal(!conProcesarFacturasSucursal);
    }
    if (e.dataField === 'Estimate') {
      setConEstimate(!conEstimate)
    }
    if (e.dataField === 'LostDeal') {
      setConLostDeal(!conLostDeal)
    }
    if (e.dataField === 'SalesOrder') {
      setConSalesOrder(!conSalesOrder)
    }
    if (e.dataField === 'Invoice') {
      setConInvoice(!conInvoice)
    }
    if (e.dataField === 'DirectDelivery') {
      setConDirectDelivery(!conDirectDelivery)
    }
    if (e.dataField === 'DirectReturn') {
      setConDirectReturn(!conDirectReturn)
    }
    if (e.dataField === 'DeliveryRequest') {
      setConDeliveryRequest(!conDeliveryRequest)
    }
    if (e.dataField === 'ReturnRequest') {
      setConReturnRequest(!conReturnRequest)
    }
    if (e.dataField === 'TransferOrder') {
      setConTransferOrder(!conTransferOrder)
    }
    if (e.dataField === 'SalesOrderMod') {
      setConSalesOrderMod(!conSalesOrderMod)
    }
    if (e.dataField === 'ShipmentRequest') {
      setConShipmentRequest(!conShipmentRequest)
    }
    if (e.dataField === 'CashRegister') {
      setConCashRegister(!conCashRegister)
    }
    if (e.dataField === 'CustomerPayment') {
      setConCustomerPayment(!conCustomerPayment)
    }
    if (e.dataField === 'InvTransferOrder') {
      setConInvTransferOrder(!conInvTransferOrder)
    }
    if (e.dataField === 'InventoryTransfer') {
      setConInventoryTransfer(!conInventoryTransfer)
    }
    if (e.dataField === 'InventoryTransfer') {
      setConInventoryTransfer(!conInventoryTransfer)
    }
    if (e.dataField === 'InvItemFulfillment') {
      setConInvItemFulfillment(!conInvItemFulfillment)
    }
    if (e.dataField === 'InvItemReceipt') {
      setConInvItemReceipt(!conInvItemReceipt)
    }
    if (e.dataField === 'SendWMS') {
      setConSendWMS(!conSendWMS)
    }
    if (e.dataField === 'ReceiveWMS') {
      setConReceiveWMS(!conReceiveWMS)
    }
    if (e.dataField === 'ProductsWMS') {
      setConProductsWMS(!conProductsWMS)
    }
    if (e.dataField === 'CreateLabelWMS') {
      setConCreateLabelWMS(!conCreateLabelWMS)
    }
    if (e.dataField === 'ReceiveOrderWMS') {
      setConReceiveOrderWMS(!conReceiveOrderWMS)
    }
    if (e.dataField === 'FulfillmentOrderWMS') {
      setConFulfillmentOrderWMS(!conFulfillmentOrderWMS)
    }
    if (e.dataField === 'AdjustItemWMS') {
      setConAdjustItemWMS(!conAdjustItemWMS)
    }
    if (e.dataField === 'ItemCountWMS') {
      setConItemCountWMS(!conItemCountWMS)
    }
    if (e.dataField === 'POSuggestionsReport') {
      setConPOSuggestionsReport(!conPOSuggestionsReport)
    }
  }, [conAsistencia, conChecklist, conChecklistAud, conChecklistVeh, conVacaciones, conProcesarFacturas, conProcesarFacturasBodega, conProcesarFacturasSucursal, conEstimate, conLostDeal, conSalesOrder, conInvoice, conDirectDelivery, conDirectReturn, conDeliveryRequest, conReturnRequest, conTransferOrder, conSalesOrderMod, conShipmentRequest, conCashRegister, conCustomerPayment, conInvTransferOrder, conInventoryTransfer, conInvItemFulfillment, conInvItemReceipt, conProductsWMS, conReceiveWMS, conSendWMS, conCreateLabelWMS, conReceiveOrderWMS, conFulfillmentOrderWMS, conAdjustItemWMS, conItemCountWMS, conPOSuggestionsReport]);

  function editUser(data) {
    return (
      <Link to={`/Users/${data.data.Username}`}>
        <button class="bg-blue-500 hover:bg-blue-700 text-white  py-1 px-3 rounded" onClick={() => editUserButton(data.data.Username)}><FontAwesomeIcon icon={faPencilAlt} /> Editar</button>
      </Link>
    )
  }

  function userEnabled(data) {
    return (
      <span className={data.data.Enabled === "Activo" ? "px-2 py-1 font-semibold leading-tight text-green-700 bg-green-100 rounded-sm" : "px-2 py-1 font-semibold leading-tight text-red-700 bg-red-100 rounded-sm"}> {data.data.Enabled} </span>
    )
  }

  function userAdmin(data) {
    return (
      <span className={data.data.isAdmin === "Admin" ? "px-2 py-1 font-semibold leading-tight text-pink-700 bg-pink-100 rounded-sm}" : ""} > {data.data.isAdmin === "Admin" ? "Si" : ""}</span>
    )
  }

  const onAutoExpandAllChanged = React.useCallback(() => {
    setAutoExpandAll(!autoExpandAll)
  }, [autoExpandAll]);

  const onAutoExpandAllChecklistChanged = React.useCallback(() => {
    setAutoExpandAllChecklist(!autoExpandAllChecklist)
  }, [autoExpandAllChecklist]);

  var personalDataSource = useMemo(() => {
    return new DataSource({
      paginate: true,
      store: {
        type: "array",
        key: "Personal",
        data: personales
      }
    });
  }, [personales]);

  var usuarioDataSource = useMemo(() => {
    return new DataSource({
      paginate: true,
      store: {
        type: "array",
        key: "Usuario",
        data: usuarios
      }
    });
  }, [usuarios]);

  var agenteDataSource = useMemo(() => {
    return new DataSource({
      paginate: true,
      store: {
        type: "array",
        key: "Agente",
        data: agentes
      }
    });
  }, [agentes]);

  var sucursalDataSource = useMemo(() => {
    return new DataSource({
      paginate: true,
      store: {
        type: "array",
        key: "Sucursal",
        data: sucursales
      }
    });
  }, [sucursales]);

  var userNameEditorOptions = useMemo(() => {
    return { width: '100%', readOnly: true };
  }, []);

  var passwordEditorOptions = useMemo(() => {
    return { width: '100%', disabled: false, mode: 'password' };
  }, []);

  //Opciones Grupo del Usuario
  const isAdminOptions = useMemo(() => {
    return [
      { value: '', name: '' },
      { value: 'Admin', name: 'Admin' }
    ];
  }, []);

  try {
    var isAdminEditorOptions = useMemo(() => {
      return {
        items: isAdminOptions,
        displayExpr: 'name',
        valueExpr: 'value',
        searchEnabled: true,
        showClearButton: true,
        value: (userPayload.isAdmin ? userPayload.isAdmin : ''), width: '100%'
      };
    }, [isAdminOptions, userPayload.isAdmin]);
  }
  catch (e) {
    var isAdminEditorOptions = {
      items: isAdminOptions,
      displayExpr: 'name',
      valueExpr: 'value',
      searchEnabled: true,
      showClearButton: true,
      value: '',
      width: '100%'
    };
  }

  //Opciones Estatus del Usuario
  const enabledOptions = useMemo(() => {
    return [
      { value: 'Activo', name: 'Activo' },
      { value: 'Inactivo', name: 'Inactivo' }
    ];
  }, []);

  try {
    var enabledEditorOptions = useMemo(() => {
      return {
        items: enabledOptions,
        displayExpr: 'name',
        valueExpr: 'value',
        searchEnabled: true,
        value: (userPayload.Enabled ? userPayload.Enabled : ''), width: '100%'
      };
    }, [enabledOptions, userPayload.Enabled]);
  }
  catch (e) {
    var enabledEditorOptions = {
      items: enabledOptions,
      displayExpr: 'name',
      valueExpr: 'value',
      searchEnabled: true,
      value: '',
      width: '100%'
    };
  }

  //Opciones Sucursal Asociada del Usuario
  try {
    var sucursalEditorOptions = useMemo(() => {
      return {
        dataSource: sucursalDataSource,
        displayExpr: 'name',
        valueExpr: 'value',
        searchEnabled: true,
        showClearButton: true,
        value: (userPayload.Sucursal ? userPayload.Sucursal : ''), width: '100%'
      };
    }, [sucursalDataSource, userPayload.Sucursal]);
  }
  catch (e) {
    var sucursalEditorOptions = {
      dataSource: sucursalDataSource,
      displayExpr: 'name',
      valueExpr: 'value',
      searchEnabled: true,
      showClearButton: true,
      value: '',
      width: '100%'
    };
  }

  //Opciones Personal Asociado del Usuario
  try {
    var personalEditorOptions = useMemo(() => {
      return {
        dataSource: personalDataSource,
        displayExpr: 'name',
        valueExpr: 'value',
        searchEnabled: true,
        showClearButton: true,
        value: (userPayload.Personal ? userPayload.Personal : ''), width: '100%'
      };
    }, [personalDataSource, userPayload.Personal]);
  }
  catch (e) {
    var personalEditorOptions = {
      dataSource: personalDataSource,
      displayExpr: 'name',
      valueExpr: 'value',
      searchEnabled: true,
      showClearButton: true,
      value: '',
      width: '100%'
    };
  }

  //Opciones Usuario Asociado del Usuario
  try {
    var usuarioEditorOptions = useMemo(() => {
      return {
        dataSource: usuarioDataSource,
        displayExpr: 'name',
        valueExpr: 'value',
        searchEnabled: true,
        showClearButton: true,
        value: (userPayload.Usuario ? userPayload.Usuario : ''), width: '100%'
      };
    }, [usuarioDataSource, userPayload.Usuario]);
  }
  catch (e) {
    var usuarioEditorOptions = {
      dataSource: usuarioDataSource,
      displayExpr: 'name',
      valueExpr: 'value',
      searchEnabled: true,
      showClearButton: true,
      value: '',
      width: '100%'
    };
  }

  //Opciones Agente Asociado del Usuario
  try {
    var agenteEditorOptions = useMemo(() => {
      return {
        dataSource: agenteDataSource,
        displayExpr: 'name',
        valueExpr: 'value',
        searchEnabled: true,
        showClearButton: true,
        value: (userPayload.Agente ? userPayload.Agente : ''), width: '100%'
      };
    }, [agenteDataSource, userPayload.Agente]);
  }
  catch (e) {
    var agenteEditorOptions = {
      dataSource: agenteDataSource,
      displayExpr: 'name',
      valueExpr: 'value',
      searchEnabled: true,
      showClearButton: true,
      value: '',
      width: '100%'
    };
  }

  //Opciones Asistencia Plaza
  var asistenciaPlazaEditorOptions = useMemo(() => {
    return {
      dataSource: plazas,
      openOnFieldClick: true,
      readOnly: false,
      searchEnabled: true,
      showClearButton: true,
      width: '100%',
      disabled: !conAsistencia,
      visible: conAsistencia
    };
  }, [plazas, conAsistencia]);

  //Opciones Checklist Plaza
  var checklistPlazaEditorOptions = useMemo(() => {
    return {
      dataSource: plazas,
      openOnFieldClick: true,
      readOnly: false,
      searchEnabled: true,
      showClearButton: true,
      width: '100%',
      disabled: !(conChecklist || conChecklistAud || conChecklistVeh), visible: (conChecklist || conChecklistAud || conChecklistVeh)
    };
  }, [plazas, conChecklist, conChecklistAud, conChecklistVeh]);

  //Opciones Vacation Plaza
  var vacationPlazaEditorOptions = useMemo(() => {
    return {
      dataSource: plazas,
      openOnFieldClick: true,
      readOnly: false,
      searchEnabled: true,
      showClearButton: true,
      width: '100%',
      disabled: !conVacaciones,
      visible: conVacaciones
    };
  }, [plazas, conVacaciones]);

  //Opciones Send WMS
  var sendWMSEditorOptions = useMemo(() => {
    return {
      disabled: !conSendWMS
    };
  }, [conSendWMS]);

  //Opciones Send WMS
  var productsWMSEditorOptions = useMemo(() => {
    return {
      disabled: !conProductsWMS
    };
  }, [conProductsWMS]);

  //Opciones Sales Plaza
  var salesPlazaEditorOptions = useMemo(() => {
    return {
      dataSource: plazas,
      openOnFieldClick: true,
      readOnly: false,
      searchEnabled: true,
      showClearButton: true,
      width: '100%',
    };
  }, [plazas]);

  //Opciones Afectar Facturas
  var afectarFacturasEditorOptions = useMemo(() => {
    return {
      readOnly: false,
      width: '100%',
      disabled: !(conProcesarFacturas || conProcesarFacturasBodega || conProcesarFacturasSucursal)
    };
  }, [conProcesarFacturas, conProcesarFacturasBodega, conProcesarFacturasSucursal]);

  //Opciones NewChecklist
  var newChecklistEditorOptions = useMemo(() => {
    return {
      readOnly: false,
      width: '100%',
      disabled: !conChecklist
    };
  }, [conChecklist]);

  //Opciones NewChecklistAud
  var newChecklistAudEditorOptions = useMemo(() => {
    return {
      readOnly: false,
      width: '100%',
      disabled: !conChecklistAud
    };
  }, [conChecklistAud]);

  //Opciones NewChecklistVeh
  var newChecklistVehEditorOptions = useMemo(() => {
    return {
      readOnly: false,
      width: '100%',
      disabled: !conChecklistVeh
    };
  }, [conChecklistVeh]);

  //Opciones Nivel de Permiso
  const levelOptions = useMemo(() => {
    return [
      { value: 'Ver', name: 'Ver' },
      { value: 'Editar', name: 'Editar' }
    ];
  }, []);

  try {
    var estimateLevelEditorOptions = useMemo(() => {
      return {
        items: levelOptions,
        displayExpr: 'name',
        valueExpr: 'value',
        searchEnabled: false,
        value: (userPayload.EstimateLevel ? userPayload.EstimateLevel : ''), width: '50%', disabled: !conEstimate
      };
    }, [levelOptions, userPayload.EstimateLevel, conEstimate]);
  }
  catch (e) {
    var estimateLevelEditorOptions = {
      items: levelOptions,
      displayExpr: 'name',
      valueExpr: 'value',
      searchEnabled: false,
      value: '',
      width: '50%'
    };
  }

  try {
    var lostDealLevelEditorOptions = useMemo(() => {
      return {
        items: levelOptions,
        displayExpr: 'name',
        valueExpr: 'value',
        searchEnabled: false,
        value: (userPayload.LostDealLevel ? userPayload.LostDealLevel : ''), width: '50%', disabled: !conLostDeal
      };
    }, [levelOptions, userPayload.LostDealLevel, conLostDeal]);
  }
  catch (e) {
    var lostDealLevelEditorOptions = {
      items: levelOptions,
      displayExpr: 'name',
      valueExpr: 'value',
      searchEnabled: false,
      value: '',
      width: '50%'
    };
  }

  try {
    var salesOrderLevelEditorOptions = useMemo(() => {
      return {
        items: levelOptions,
        displayExpr: 'name',
        valueExpr: 'value',
        searchEnabled: false,
        value: (userPayload.SalesOrderLevel ? userPayload.SalesOrderLevel : ''),
        width: '50%',
        disabled: !conSalesOrder
      };
    }, [levelOptions, userPayload.SalesOrderLevel, conSalesOrder]);
  }
  catch (e) {
    var salesOrderLevelEditorOptions = {
      items: levelOptions,
      displayExpr: 'name',
      valueExpr: 'value',
      searchEnabled: false,
      value: '',
      width: '50%'
    };
  }

  try {
    var invoiceLevelEditorOptions = useMemo(() => {
      return {
        items: levelOptions,
        displayExpr: 'name',
        valueExpr: 'value',
        searchEnabled: false,
        value: (userPayload.InvoiceLevel ? userPayload.InvoiceLevel : ''),
        width: '50%',
        disabled: !conInvoice
      };
    }, [levelOptions, userPayload.InvoiceLevel, conInvoice]);
  }
  catch (e) {
    var invoiceLevelEditorOptions = {
      items: levelOptions,
      displayExpr: 'name',
      valueExpr: 'value',
      searchEnabled: false,
      value: '',
      width: '50%'
    };
  }

  try {
    var directDeliveryLevelEditorOptions = useMemo(() => {
      return {
        items: levelOptions,
        displayExpr: 'name',
        valueExpr: 'value',
        searchEnabled: false,
        value: (userPayload.DirectDeliveryLevel ? userPayload.DirectDeliveryLevel : ''),
        width: '50%',
        disabled: !conDirectDelivery
      };
    }, [levelOptions, userPayload.DirectDeliveryLevel, conDirectDelivery]);
  }
  catch (e) {
    var directDeliveryLevelEditorOptions = {
      items: levelOptions,
      displayExpr: 'name',
      valueExpr: 'value',
      searchEnabled: false,
      value: '',
      width: '50%'
    };
  }

  try {
    var directReturnLevelEditorOptions = useMemo(() => {
      return {
        items: levelOptions,
        displayExpr: 'name',
        valueExpr: 'value',
        searchEnabled: false,
        value: (userPayload.DirectReturnLevel ? userPayload.DirectReturnLevel : ''),
        width: '50%',
        disabled: !conDirectReturn
      };
    }, [levelOptions, userPayload.DirectReturnLevel, conDirectReturn]);
  }
  catch (e) {
    var directReturnLevelEditorOptions = {
      items: levelOptions,
      displayExpr: 'name',
      valueExpr: 'value',
      searchEnabled: false,
      value: '',
      width: '50%'
    };
  }

  try {
    var deliveryRequestLevelEditorOptions = useMemo(() => {
      return {
        items: levelOptions,
        displayExpr: 'name',
        valueExpr: 'value',
        searchEnabled: false,
        value: (userPayload.DeliveryRequestLevel ? userPayload.DeliveryRequestLevel : ''),
        width: '50%',
        disabled: !conDeliveryRequest
      };
    }, [levelOptions, userPayload.DeliveryRequestLevel, conDeliveryRequest]);
  }
  catch (e) {
    var deliveryRequestLevelEditorOptions = {
      items: levelOptions,
      displayExpr: 'name',
      valueExpr: 'value',
      searchEnabled: false,
      value: '',
      width: '50%'
    };
  }

  try {
    var returnRequestLevelEditorOptions = useMemo(() => {
      return {
        items: levelOptions,
        displayExpr: 'name',
        valueExpr: 'value',
        searchEnabled: false,
        value: (userPayload.ReturnRequestLevel ? userPayload.ReturnRequestLevel : ''),
        width: '50%',
        disabled: !conReturnRequest
      };
    }, [levelOptions, userPayload.ReturnRequestLevel, conReturnRequest]);
  }
  catch (e) {
    var returnRequestLevelEditorOptions = {
      items: levelOptions,
      displayExpr: 'name',
      valueExpr: 'value',
      searchEnabled: false,
      value: '',
      width: '50%'
    };
  }

  try {
    var transferOrderLevelEditorOptions = useMemo(() => {
      return {
        items: levelOptions,
        displayExpr: 'name',
        valueExpr: 'value',
        searchEnabled: false,
        value: (userPayload.TransferOrderLevel ? userPayload.TransferOrderLevel : ''),
        width: '50%',
        disabled: !conTransferOrder
      };
    }, [levelOptions, userPayload.TransferOrderLevel, conTransferOrder]);
  }
  catch (e) {
    var transferOrderLevelEditorOptions = {
      items: levelOptions,
      displayExpr: 'name',
      valueExpr: 'value',
      searchEnabled: false,
      value: '',
      width: '50%'
    };
  }

  try {
    var salesOrderModLevelEditorOptions = useMemo(() => {
      return {
        items: levelOptions,
        displayExpr: 'name',
        valueExpr: 'value',
        searchEnabled: false,
        value: (userPayload.SalesOrderModLevel ? userPayload.SalesOrderModLevel : ''),
        width: '50%',
        disabled: !conSalesOrderMod
      };
    }, [levelOptions, userPayload.SalesOrderModLevel, conSalesOrderMod]);
  }
  catch (e) {
    var salesOrderModLevelEditorOptions = {
      items: levelOptions,
      displayExpr: 'name',
      valueExpr: 'value',
      searchEnabled: false,
      value: '',
      width: '50%'
    };
  }

  try {
    var shipmentRequestLevelEditorOptions = useMemo(() => {
      return {
        items: levelOptions,
        displayExpr: 'name',
        valueExpr: 'value',
        searchEnabled: false,
        value: (userPayload.ShipmentRequestLevel ? userPayload.ShipmentRequestLevel : ''),
        width: '50%',
        disabled: !conShipmentRequest
      };
    }, [levelOptions, userPayload.ShipmentRequestLevel, conShipmentRequest]);
  }
  catch (e) {
    var shipmentRequestLevelEditorOptions = {
      items: levelOptions,
      displayExpr: 'name',
      valueExpr: 'value',
      searchEnabled: false,
      value: '',
      width: '50%'
    };
  }

  try {
    var cashRegisterLevelEditorOptions = useMemo(() => {
      return {
        items: levelOptions,
        displayExpr: 'name',
        valueExpr: 'value',
        searchEnabled: false,
        value: (userPayload.CashRegisterLevel ? userPayload.CashRegisterLevel : ''),
        width: '50%',
        disabled: !conCashRegister
      };
    }, [levelOptions, userPayload.CashRegisterLevel, conCashRegister]);
  }
  catch (e) {
    var cashRegisterLevelEditorOptions = {
      items: levelOptions,
      displayExpr: 'name',
      valueExpr: 'value',
      searchEnabled: false,
      value: '',
      width: '50%'
    };
  }

  try {
    var customerPaymentLevelEditorOptions = useMemo(() => {
      return {
        items: levelOptions,
        displayExpr: 'name',
        valueExpr: 'value',
        searchEnabled: false,
        value: (userPayload.CustomerPaymentLevel ? userPayload.CustomerPaymentLevel : ''),
        width: '50%',
        disabled: !conCustomerPayment
      };
    }, [levelOptions, userPayload.CustomerPaymentLevel, conCustomerPayment]);
  }
  catch (e) {
    var customerPaymentLevelEditorOptions = {
      items: levelOptions,
      displayExpr: 'name',
      valueExpr: 'value',
      searchEnabled: false,
      value: '',
      width: '50%'
    };
  }

  try {
    var invTransferOrderLevelEditorOptions = useMemo(() => {
      return {
        items: levelOptions,
        displayExpr: 'name',
        valueExpr: 'value',
        searchEnabled: false,
        value: (userPayload.InvTransferOrderLevel ? userPayload.InvTransferOrderLevel : ''),
        width: '50%',
        disabled: !conInvTransferOrder
      };
    }, [levelOptions, userPayload.InvTransferOrderLevel, conInvTransferOrder]);
  }
  catch (e) {
    var invTransferOrderLevelEditorOptions = {
      items: levelOptions,
      displayExpr: 'name',
      valueExpr: 'value',
      searchEnabled: false,
      value: '',
      width: '50%'
    };
  }

  try {
    var invItemFulfillmentLevelEditorOptions = useMemo(() => {
      return {
        items: levelOptions,
        displayExpr: 'name',
        valueExpr: 'value',
        searchEnabled: false,
        value: (userPayload.InvItemFulfillmentLevel ? userPayload.InvItemFulfillmentLevel : ''),
        width: '50%',
        disabled: !conInvItemFulfillment
      };
    }, [levelOptions, userPayload.InvItemFulfillmentLevel, conInvItemFulfillment]);
  }
  catch (e) {
    var invItemFulfillmentLevelEditorOptions = {
      items: levelOptions,
      displayExpr: 'name',
      valueExpr: 'value',
      searchEnabled: false,
      value: '',
      width: '50%'
    };
  }

  try {
    var invItemReceiptLevelEditorOptions = useMemo(() => {
      return {
        items: levelOptions,
        displayExpr: 'name',
        valueExpr: 'value',
        searchEnabled: false,
        value: (userPayload.InvItemReceiptLevel ? userPayload.InvItemReceiptLevel : ''),
        width: '50%',
        disabled: !conInvItemReceipt
      };
    }, [levelOptions, userPayload.InvItemReceiptLevel, conInvItemReceipt]);
  }
  catch (e) {
    var invItemReceiptLevelEditorOptions = {
      items: levelOptions,
      displayExpr: 'name',
      valueExpr: 'value',
      searchEnabled: false,
      value: '',
      width: '50%'
    };
  }


  try {
    var inventoryTransferLevelEditorOptions = useMemo(() => {
      return {
        items: levelOptions,
        displayExpr: 'name',
        valueExpr: 'value',
        searchEnabled: false,
        value: (userPayload.InventoryTransferLevel ? userPayload.InventoryTransferLevel : ''),
        width: '50%',
        disabled: !conInventoryTransfer
      };
    }, [levelOptions, conInventoryTransfer, userPayload.InventoryTransferLevel]);
  }
  catch (e) {
    var inventoryTransferLevelEditorOptions = {
      items: levelOptions,
      displayExpr: 'name',
      valueExpr: 'value',
      searchEnabled: false,
      value: '',
      width: '50%'
    };
  }

  try {
    var createLabelWMSLevelEditorOptions = useMemo(() => {
      return {
        items: levelOptions,
        displayExpr: 'name',
        valueExpr: 'value',
        searchEnabled: false,
        value: (userPayload.CreateLabelWMSLevel ? userPayload.CreateLabelWMSLevel : ''),
        width: '50%',
        disabled: !conCreateLabelWMS
      };
    }, [levelOptions, conCreateLabelWMS, userPayload.CreateLabelWMSLevel]);
  }
  catch (e) {
    var createLabelWMSLevelEditorOptions = {
      items: levelOptions,
      displayExpr: 'name',
      valueExpr: 'value',
      searchEnabled: false,
      value: '',
      width: '50%'
    };
  }

  try {
    var receiveOrderWMSLevelEditorOptions = useMemo(() => {
      return {
        items: levelOptions,
        displayExpr: 'name',
        valueExpr: 'value',
        searchEnabled: false,
        value: (userPayload.ReceiveOrderWMSLevel ? userPayload.ReceiveOrderWMSLevel : ''),
        width: '50%',
        disabled: !conReceiveOrderWMS
      };
    }, [levelOptions, conReceiveOrderWMS, userPayload.ReceiveOrderWMSLevel]);
  }
  catch (e) {
    var receiveOrderWMSLevelEditorOptions = {
      items: levelOptions,
      displayExpr: 'name',
      valueExpr: 'value',
      searchEnabled: false,
      value: '',
      width: '50%'
    };
  }

  try {
    var fulfillmentOrderWMSLevelEditorOptions = useMemo(() => {
      return {
        items: levelOptions,
        displayExpr: 'name',
        valueExpr: 'value',
        searchEnabled: false,
        value: (userPayload.FulfillmentOrderWMSLevel ? userPayload.FulfillmentOrderWMSLevel : ''),
        width: '50%',
        disabled: !conFulfillmentOrderWMS
      };
    }, [levelOptions, conFulfillmentOrderWMS, userPayload.FulfillmentOrderWMSLevel]);
  }
  catch (e) {
    var fulfillmentOrderWMSLevelEditorOptions = {
      items: levelOptions,
      displayExpr: 'name',
      valueExpr: 'value',
      searchEnabled: false,
      value: '',
      width: '50%'
    };
  }

  try {
    var assignFulfillmentOrderWMSEditorOptions = useMemo(() => {
      return {
        disabled: !conFulfillmentOrderWMS
      };
    }, [conFulfillmentOrderWMS]);
  }
  catch (e) {
    var assignFulfillmentOrderWMSEditorOptions = {
      disabled: false
    };
  }

  try {
    var poSuggestionsReportCfgEditorOptions = useMemo(() => {
      return {
        disabled: !conPOSuggestionsReport
      };
    }, [conPOSuggestionsReport]);
  }
  catch (e) {
    var poSuggestionsReportCfgEditorOptions = {
      disabled: false
    };
  }
  
  try {
    var adjustItemWMSLevelEditorOptions = useMemo(() => {
      return {
        items: levelOptions,
        displayExpr: 'name',
        valueExpr: 'value',
        searchEnabled: false,
        value: (userPayload.AdjustItemWMSLevel ? userPayload.AdjustItemWMSLevel : ''),
        width: '50%',
        disabled: !conAdjustItemWMS
      };
    }, [levelOptions, conAdjustItemWMS, userPayload.AdjustItemWMSLevel]);
  }
  catch (e) {
    var adjustItemWMSLevelEditorOptions = {
      items: levelOptions,
      displayExpr: 'name',
      valueExpr: 'value',
      searchEnabled: false,
      value: '',
      width: '50%'
    };
  }

  try {
    var itemCountWMSLevelEditorOptions = useMemo(() => {
      return {
        items: levelOptions,
        displayExpr: 'name',
        valueExpr: 'value',
        searchEnabled: false,
        value: (userPayload.ItemCountWMSLevel ? userPayload.ItemCountWMSLevel : ''),
        width: '50%',
        disabled: !conItemCountWMS
      };
    }, [levelOptions, conItemCountWMS, userPayload.ItemCountWMSLevel]);
  }
  catch (e) {
    var itemCountWMSLevelEditorOptions = {
      items: levelOptions,
      displayExpr: 'name',
      valueExpr: 'value',
      searchEnabled: false,
      value: '',
      width: '50%'
    };
  }

  var approveItemCountWMSLevelEditorOptions = useMemo(() => {
    return {
      readOnly: false,
      width: '100%',
      disabled: !conItemCountWMS
    };
  }, [conItemCountWMS]);

  var approveAdjustItemWMSLevelEditorOptions = useMemo(() => {
    return {
      readOnly: false,
      width: '100%',
      disabled: !conAdjustItemWMS
    };
  }, [conAdjustItemWMS]);

  var approveInventoryTransferEditorOptions = useMemo(() => {
    return {
      readOnly: false,
      width: '100%',
      disabled: !conInventoryTransfer
    };
  }, [conInventoryTransfer]);

  //Opciones Modificacion Pedidos
  var salesOrderModPermissionEditorOptions = useMemo(() => {
    return {
      readOnly: false,
      width: '100%',
      disabled: !conSalesOrderMod
    };
  }, [conSalesOrderMod]);

  var toastPosition = useMemo(() => {
    return {
      my: 'top center',
      at: 'top center',
      of: window,
      offset: '0 130'
    }
  }, []);

  useEffect(() => {
    window.addEventListener('popstate', (e) => {
      var hash;

      try {
        hash = e.target.location.hash;
      }
      catch (e) {
        hash = '';
      }

      if (hash === '#/Users') {
        history.push('/Users');

        window.location.reload();
      }
    });

    async function initialize() {
      setVisibleLoadPanel(true);

      var user = await Auth.currentAuthenticatedUser();

      var groups = user.signInUserSession.accessToken.payload["cognito:groups"];

      var okAdmin = false;

      for (var groupIndex in groups) {
        var singleGroup = groups[groupIndex];

        if (singleGroup === 'Admin') {
          setOk(true);
          okAdmin = true;
        }
      }

      if (okAdmin === true) {
        var sucursalList = await getSucursales();
        var personalList = await getPersonal();
        var usuarioList = await getUsuario();
        var agenteList = await getAgente();

        await getUsersList(sucursalList, personalList, usuarioList, agenteList);
      }

      setVisibleLoadPanel(false);
    }

    initialize();

    // En caso de solicitar un ID especifico, redireccionamos a ese ID
    if (!props.match.isExact) {
      var urlID = props.location.pathname.replace('/Users/', '');

      editUserButton(urlID);
    }

    // eslint-disable-next-line
  }, []);

  const onHidingToast = React.useCallback(() => {
    setVisibleToast(false);
  }, []);

  function onExporting(e) {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Datos');

    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Datos.xlsx');
      });
    });
    e.cancel = true;
  };

  return (
    <div>
      {isDetail === false ? (
        <div class="bg-gray-200 m-2">
          <h1 class="font-sans inline-block text-xl font-bold text-gray-700 leading-8 align-top mb-1"><FontAwesomeIcon icon={faList} size="lg" /> Usuarios</h1>
        </div>
      ) :
        (
          <div class="bg-gray-200 m-2">
            <h1 class="font-sans inline-block text-xl font-bold text-gray-700 leading-8 align-top mb-1"><FontAwesomeIcon icon={faUser} size="xl" /> {userPayload.Username}</h1>
          </div>
        )}

      <LoadPanel
        shadingColor="rgba(0,0,0,0.4)"
        visible={visibleLoadPanel}
        showIndicator={true}
        message="Por favor espere..."
        shading={true}
        showPane={true}
        hideOnOutsideClick={false}
      />

      <Toast
        visible={visibleToast}
        message={messageToast}
        type={typeToast}
        onHiding={onHidingToast}
        displayTime={5000}
        width={'auto'}
        position={toastPosition}
      />

      <div class="bg-gray-200 m-2">
        {isDetail === false ? (
          <div class="bg-white p-3 shadow-xl">
            <div className="options mb-2">
              <div className="option">
                <CheckBox text="Expandir todos los grupos"
                  value={autoExpandAll}
                  onValueChanged={onAutoExpandAllChanged}>
                </CheckBox>
              </div>
            </div>
            <DataGrid
              height={800}
              id="gridContainer"
              columnsAutoWidth={true}
              filterBuilder={filterBuilder}
              dataSource={userRecords}
              allowColumnReordering={true}
              allowColumnResizing={true}
              columnResizingMode="widget"
              showBorders={true}
              rowAlternationEnabled={true}
              showRowLines={true}
              showColumnLines={true}
              onExporting={onExporting}
            >
              <Scrolling rowRenderingMode='virtual'></Scrolling>
              <Paging defaultPageSize={10} />
              <Pager
                visible={true}
                allowedPageSizes={allowedPageSizes}
                displayMode='compact'
                showPageSizeSelector={true}
                showInfo={true}
                showNavigationButtons={true} />
              <FilterRow visible={true} />
              <FilterPanel visible={true} />
              <FilterBuilderPopup position={filterBuilderPopupPosition} />

              <HeaderFilter visible={true} />

              <GroupPanel visible={true} />
              <SearchPanel visible={true} />
              <ColumnChooser
                enabled={true}
                mode='select'
              >
                <ColumnChooserSearch
                  enabled={true}
                />

                <ColumnChooserSelection
                  allowSelectAll={true}
                  selectByClick={true}
                  recursive={true}
                />
              </ColumnChooser>
              <Grouping autoExpandAll={autoExpandAll} />
              <Export enabled={true} />
              <StateStoring enabled={true} type="localStorage" storageKey="gridContainerListUsers" />

              <Column caption="" alignment="center" width={100} cellRender={editUser} />

              <Column caption="Usuario" dataField="Username" dataType="string" width={150} />

              <Column caption="Fecha Ultima Modificacion" dataField="lastModifiedDate" dataType="string" width={200} visible={false} />
              <Column caption="Fecha Creacion" dataField="createdDate" dataType="string" width={200} visible={false} />
              <Column caption="Estatus Cognito" dataField="cognitoUserStatus" dataType="string" width={200} visible={false} />

              <Column caption="Correo Electronico" dataField="Email" dataType="string" width={200} />

              <Column caption="Estatus" alignment="center" width={120} cellRender={userEnabled} />

              <Column caption="Personal Asociado" dataField="Personal" dataType="string" width={300} />
              <Column caption="Usuario Asociado" dataField="Usuario" dataType="string" width={300} />
              <Column caption="Agente Asociado" dataField="Agente" dataType="string" width={300} />
              <Column caption="Sucursal" dataField="Sucursal" dataType="string" width={200} />

              <Column caption="Administrador" alignment="center" width={200} cellRender={userAdmin} />

              <Column caption="Plazas Asistencia" dataField="AsistenciaPlaza" visible={false} width={200} />
              <Column caption="Asistencia" dataField="Attendance" visible={false} width={200} />
              <Column caption="Checklist Supervisores" dataField="CheckList" visible={false} width={200} />
              <Column caption="Checklist Auditores" dataField="CheckListAud" visible={false} width={200} />
              <Column caption="Plazas Checklist" dataField="ChecklistPlaza" visible={false} width={200} />
              <Column caption="Checklist Vehículos" dataField="CheckListVeh" visible={false} width={200} />
              <Column caption="Comisiones" dataField="Commission" visible={false} width={200} />
              <Column caption="Manual de Capacitación" dataField="Document" visible={false} width={200} />
              <Column caption="Incentivos" dataField="Incentive" visible={false} width={200} />
              <Column caption="Kibana" dataField="Kibana" visible={false} width={200} />
              <Column caption="Procesar Facturas" dataField="ProcesarFacturas" visible={false} width={200} />
              <Column caption="Procesar Facturas Sucursal" dataField="ProcesarFacturasSucursal" visible={false} width={200} />
              <Column caption="Procesar Facturas Bodega" dataField="ProcesarFacturasBodega" visible={false} width={200} />
              <Column caption="Afectar Facturas" dataField="AfectarFacturas" visible={false} width={200} />
              <Column caption="Lista de Precios" dataField="PriceList" visible={false} width={200} />
              <Column caption="Solicitud de Vacaciones" dataField="Vacation" visible={false} width={200} />
              <Column caption="Plazas Solicitud de Vacaciones" dataField="VacationPlaza" visible={false} width={200} />
              <Column caption="Plazas Ventas" dataField="SalesPlaza" visible={false} width={200} />

              <Column caption="Crear Checklist Supervisores" dataField="NewCheckList" visible={false} width={200} />
              <Column caption="Crear Checklist Auditores" dataField="NewCheckListAud" visible={false} width={200} />
              <Column caption="Crear Checklist Vehículos" dataField="NewCheckListVeh" visible={false} width={200} />

              <Column caption="Cotizaciones" dataField="Estimate" visible={false} width={200} />
              <Column caption="Venta Perdida" dataField="LostDeal" visible={false} width={200} />
              <Column caption="Pedido" dataField="SalesOrder" visible={false} width={200} />
              <Column caption="Herramienta Cierre Pedidos" dataField="CloseSalesOrder" visible={false} width={200} />
              <Column caption="Factura" dataField="Invoice" visible={false} width={200} />
              <Column caption="Entrega Directa" dataField="DirectDelivery" visible={false} width={200} />
              <Column caption="Devolución Directa" dataField="DirectReturn" visible={false} width={200} />
              <Column caption="Solicitud Envío" dataField="DeliveryRequest" visible={false} width={200} />
              <Column caption="Solicitud Devolución" dataField="ReturnRequest" visible={false} width={200} />
              <Column caption="Solicitud Traspaso" dataField="TransferOrder" visible={false} width={200} />
              <Column caption="Sol Modificación" dataField="SalesOrderMod" visible={false} width={200} />
              <Column caption="Solicitud Embarque" dataField="ShipmentRequest" visible={false} width={200} />
              <Column caption="Corte Caja" dataField="CashRegister" visible={false} width={200} />
              <Column caption="Anticipo Saldo" dataField="CustomerPayment" visible={false} width={200} />
              <Column caption="Solicitud Traspaso" dataField="InvTransferOrder" visible={false} width={200} />
              <Column caption="Explorador Inventarios" dataField="InvLevels" visible={false} width={200} />
              <Column caption="Explorador Inventarios con costos" dataField="InvLevelsIncCost" visible={false} width={200} />
              <Column caption="Explorador Kardex" dataField="Kardex" visible={false} width={200} />
              <Column caption="Autorizar Transferencias" dataField="ApproveInventoryTransfer" visible={false} width={200} />
              <Column caption="Autorizar Entregas de Mercancía" dataField="ApproveDelivery" visible={false} width={200} />
              <Column caption="Prog - Cancelar transacciones Concluidas" dataField="VoidDeliveryTransactions" visible={false} width={200} />
              <Column caption="Mod - Permitir Modificar Precios" dataField="SalesOrderModUpdatePrices" visible={false} width={200} />
              <Column caption="Mod - Autorizar Modificaciones" dataField="ApproveSalesOrderMod" visible={false} width={200} />
              <Column caption="Regenerar CFDi" dataField="RegenerateCFDi" visible={false} width={200} />
              <Column caption="Enviar CFDi" dataField="SendCFDi" visible={false} width={200} />
              <Column caption="Reporte Comparativo Ventas" dataField="RepComparativoVentas" visible={false} width={200} />
              <Column caption="Reporte Sugerencia de Compras" dataField="POSuggestionsReport" visible={false} width={200} />
              <Column caption="Cfg. Reporte Sugerencia de Compras" dataField="POSuggestionsReportCfg" visible={false} width={200} />
              
              <Column caption="Explorador WMS Barcodes" dataField="ExploreBarcodes" visible={false} width={200} />
              <Column caption="Explorador WMS Barcodes con Costos" dataField="ExploreBarcodeCosts" visible={false} width={200} />
              
              <Column caption="Transferencia de Inventario" dataField="InventoryTransfer" visible={false} width={200} />
              <Column caption="Salida Traspaso" dataField="InvItemFulfillment" visible={false} width={200} />
              <Column caption="Recibo Traspaso" dataField="InvItemReceipt" visible={false} width={200} />

              <Column caption="Nivel Cotizaciones" dataField="EstimateLevel" visible={false} width={200} />
              <Column caption="Nivel Venta Perdida" dataField="LostDealLevel" visible={false} width={200} />
              <Column caption="Nivel Pedido" dataField="SalesOrderLevel" visible={false} width={200} />
              <Column caption="Nivel Factura" dataField="InvoiceLevel" visible={false} width={200} />
              <Column caption="Nivel Entrega Directa" dataField="DirectDeliveryLevel" visible={false} width={200} />
              <Column caption="Nivel Devolución Directa" dataField="DirectReturnLevel" visible={false} width={200} />
              <Column caption="Nivel Solicitud Envío" dataField="DeliveryRequestLevel" visible={false} width={200} />
              <Column caption="Nivel Solicitud Devolución" dataField="ReturnRequestLevel" visible={false} width={200} />
              <Column caption="Nivel Solicitud Traspaso" dataField="TransferOrderLevel" visible={false} width={200} />
              <Column caption="Nivel Sol Modificación" dataField="SalesOrderModLevel" visible={false} width={200} />
              <Column caption="Nivel Solicitud Embarque" dataField="ShipmentRequestLevel" visible={false} width={200} />
              <Column caption="Nivel Corte Caja" dataField="CashRegisterLevel" visible={false} width={200} />
              <Column caption="Nivel Anticipo Saldo" dataField="CustomerPaymentLevel" visible={false} width={200} />
              <Column caption="Nivel Inventario - Solicitud Traspaso" dataField="InvTransferOrderLevel" visible={false} width={200} />
              <Column caption="Nivel Inventario - Autorizar Solicitud Traspaso" dataField="ApproveInvTransferOrder" visible={false} width={200} />
              <Column caption="Nivel Transferencia de Inventario" dataField="InventoryTransferLevel" visible={false} width={200} />
              <Column caption="Nivel Salida Traspaso" dataField="InvItemFulfillmentLevel" visible={false} width={200} />
              <Column caption="Nivel Recibo Traspaso" dataField="InvItemReceiptLevel" visible={false} width={200} />

              <Column caption="Procesar Envío WMS HH" dataField="SendWMS" visible={false} width={200} />
              <Column caption="Procesar Pick WMS HH" dataField="PickWMS" visible={false} width={200} />
              <Column caption="Procesar Pack WMS HH" dataField="PackWMS" visible={false} width={200} />
              <Column caption="Procesar Ship WMS HH" dataField="ShipWMS" visible={false} width={200} />
              <Column caption="Procesar Recibos WMS HH" dataField="ReceiveWMS" visible={false} width={200} />
              <Column caption="Procesar Productos WMS HH" dataField="ProductsWMS" visible={false} width={200} />
              <Column caption="Procesar Ajuste WMS HH" dataField="AdjustmentWMS" visible={false} width={200} />
              <Column caption="Procesar Acomodo WMS HH" dataField="MoveWMS" visible={false} width={200} />
              <Column caption="Procesar Conteo Cíclico WMS HH" dataField="CountWMS" visible={false} width={200} />

              <Column caption="Alta Etiqueta WMS" dataField="CreateLabelWMS" visible={false} width={200} />
              <Column caption="Nivel Alta Etiqueta WMS" dataField="CreateLabelWMSLevel" visible={false} width={200} />
              <Column caption="Orden Recibo WMS" dataField="ReceiveOrderWMS" visible={false} width={200} />
              <Column caption="Nivel Orden Recibo WMS" dataField="ReceiveOrderWMSLevel" visible={false} width={200} />
              <Column caption="Orden Surtido WMS" dataField="FulfillmentOrderWMS" visible={false} width={200} />
              <Column caption="Reasignar Barcode Surtido WMS" dataField="AssignFulfillmentOrderWMS" visible={false} width={200} />
              <Column caption="Nivel Orden Surtido WMS" dataField="FulfillmentOrderWMSLevel" visible={false} width={200} />
              <Column caption="Ajuste WMS" dataField="AdjustItemWMS" visible={false} width={200} />
              <Column caption="Autorizar Ajuste WMS" dataField="ApproveAdjustItemWMS" visible={false} width={200} />
              <Column caption="Nivel Ajuste WMS" dataField="AdjustItemWMSLevel" visible={false} width={200} />

              <Column caption="Conteo Cíclico WMS" dataField="ItemCountWMS" visible={false} width={200} />
              <Column caption="Autorizar Conteo Cíclico WMS" dataField="ApproveItemCountWMS" visible={false} width={200} />
              <Column caption="Nivel Conteo Cíclico WMS" dataField="ItemCountWMSLevel" visible={false} width={200} />

              <Column caption="Acomodo WMS" dataField="MoveItemWMS" visible={false} width={200} />

            </DataGrid>
          </div>
        ) : (<div />)}
        <Route exact path="/Users/:userName" render={({ match }) => {
          return (
            <div class="bg-gray-200">
              {isDetail.toString() === 'true' ?
                (
                  <div>
                    <Link to="/Users">
                      <button onClick={cancelEditButton} type="submit" class="mb-4 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
                        <FontAwesomeIcon icon={faArrowRotateLeft} /> Regresar
                      </button>
                    </Link>

                    <button onClick={saveButton} type="submit" class="ml-4 mb-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                      <FontAwesomeIcon icon={faSave} /> Guardar Cambios
                    </button>

                    <button onClick={deleteUser} type="submit" class="ml-4 mb-4 bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded">
                      <FontAwesomeIcon icon={faTrashAlt} /> Eliminar Usuario
                    </button>

                    <div class="mt-1 bg-white pt-3 shadow-xl">
                      <div class="ml-4 mr-4 bg-white pt-3">
                        <Form
                          id="datosGenerales"
                          labelMode={'outside'}
                          formData={userPayload}

                          showColonAfterLabel={false}
                          labelLocation={'top'}
                          colCount={2}
                          onFieldDataChanged={onFieldDataChanged}
                        >
                          <GroupItem caption="Datos Generales">
                            <Item cssClass="boldText" dataField="Username" editorOptions={userNameEditorOptions} />
                            <Item cssClass="boldText" dataField="Email" editorOptions={userNameEditorOptions} />
                            <Item dataField="Enabled" editorType="dxSelectBox" editorOptions={enabledEditorOptions} >
                              <Label text="Estatus" />
                            </Item>
                            <Item dataField="isAdmin" editorType="dxSelectBox" editorOptions={isAdminEditorOptions} >
                              <Label text="Grupo" />
                            </Item>
                            <Item dataField="Sucursal" editorType="dxSelectBox" editorOptions={sucursalEditorOptions} >
                              <Label text="Sucursal Asociada" />
                            </Item>

                            <Item dataField="Personal" editorType="dxSelectBox" editorOptions={personalEditorOptions} >
                              <Label text="Personal Asociado" />
                            </Item>

                            <Item dataField="Usuario" editorType="dxSelectBox" editorOptions={usuarioEditorOptions} >
                              <Label text="Usuario Asociado" />
                            </Item>

                            <Item dataField="Agente" editorType="dxSelectBox" editorOptions={agenteEditorOptions} >
                              <Label text="Agente Asociado" />
                            </Item>
                          </GroupItem>
                          <GroupItem caption="Contraseña">
                            <Item dataField="Password" editorOptions={passwordEditorOptions} >
                              <Label text="Contraseña" />
                            </Item>
                            <Item dataField="ConfirmPassword" editorOptions={passwordEditorOptions} >
                              <Label text="Confirmar Contraseña" />
                            </Item>
                          </GroupItem>
                          <GroupItem caption="Accesos" colSpan={2}>
                            <TabbedItem>
                              <TabPanelOptions deferRendering={false} showNavButtons={true} />

                              <Tab title="Asistencia">
                                <Item dataField="Attendance" editorType="dxCheckBox">
                                  <Label text="Asistencia" />
                                </Item>
                                <Item dataField="asistenciaPlaza" editorType="dxTagBox" editorOptions={asistenciaPlazaEditorOptions} >
                                  <Label text="Plazas" />
                                </Item>
                              </Tab>
                              <Tab title="Ventas">
                                <TabbedItem>
                                  <TabPanelOptions deferRendering={false} showNavButtons={true} />
                                  <Tab title="Cfg. General">
                                    <Item dataField="salesPlaza" editorType="dxTagBox" editorOptions={salesPlazaEditorOptions} >
                                      <Label text="Acceso Plazas" />
                                    </Item>
                                    <Item dataField="RegenerateCFDi" editorType="dxCheckBox">
                                      <Label text="Regenerar CFDi" />
                                    </Item>
                                    <Item dataField="SendCFDi" editorType="dxCheckBox">
                                      <Label text="Enviar CFDi" />
                                    </Item>
                                  </Tab>
                                  <Tab title="Cotizaciones">
                                    <Item dataField="Estimate" editorType="dxCheckBox">
                                      <Label text="Cotizaciones" />
                                    </Item>
                                    <Item dataField="EstimateLevel" editorType="dxSelectBox" editorOptions={estimateLevelEditorOptions} >
                                      <Label text="Nivel Permiso" />
                                    </Item>
                                  </Tab>
                                  <Tab title="Venta Perdida">
                                    <Item dataField="LostDeal" editorType="dxCheckBox">
                                      <Label text="Venta Perdida" />
                                    </Item>
                                    <Item dataField="LostDealLevel" editorType="dxSelectBox" editorOptions={lostDealLevelEditorOptions} >
                                      <Label text="Nivel Permiso" />
                                    </Item>
                                  </Tab>
                                  <Tab title="Pedidos">
                                    <Item dataField="SalesOrder" editorType="dxCheckBox">
                                      <Label text="Pedidos" />
                                    </Item>
                                    <Item dataField="SalesOrderLevel" editorType="dxSelectBox" editorOptions={salesOrderLevelEditorOptions} >
                                      <Label text="Nivel Permiso" />
                                    </Item>
                                    <Item dataField="CloseSalesOrder" editorType="dxCheckBox" >
                                      <Label text="Herramienta Cierre Pedidos" />
                                    </Item>
                                  </Tab>
                                  <Tab title="Facturas">
                                    <Item dataField="Invoice" editorType="dxCheckBox">
                                      <Label text="Facturas" />
                                    </Item>
                                    <Item dataField="InvoiceLevel" editorType="dxSelectBox" editorOptions={invoiceLevelEditorOptions} >
                                      <Label text="Nivel Permiso" />
                                    </Item>
                                  </Tab>
                                  <Tab title="Lista de Precios">
                                    <Item dataField="PriceList" editorType="dxCheckBox">
                                      <Label text="Lista de Precios" />
                                    </Item>
                                  </Tab>
                                  <Tab title="Procesar Facturas">
                                    <Item dataField="ProcesarFacturas" editorType="dxCheckBox">
                                      <Label text="Procesar Facturas" />
                                    </Item>
                                    <Item dataField="ProcesarFacturasSucursal" editorType="dxCheckBox">
                                      <Label text="Procesar Facturas Sucursal" />
                                    </Item>
                                    <Item dataField="ProcesarFacturasBodega" editorType="dxCheckBox">
                                      <Label text="Procesar Facturas Bodega" />
                                    </Item>
                                    <Item dataField="AfectarFacturas" editorType="dxCheckBox" editorOptions={afectarFacturasEditorOptions}>
                                      <Label text="Afectar Facturas" />
                                    </Item>
                                  </Tab>
                                </TabbedItem>
                              </Tab>
                              <Tab title="Programación de Pedidos">
                                <TabbedItem>
                                  <TabPanelOptions deferRendering={false} showNavButtons={true} />
                                  <Tab title="Entrega Directa">
                                    <Item dataField="DirectDelivery" editorType="dxCheckBox">
                                      <Label text="Entrega Directa" />
                                    </Item>
                                    <Item dataField="DirectDeliveryLevel" editorType="dxSelectBox" editorOptions={directDeliveryLevelEditorOptions} >
                                      <Label text="Nivel Permiso" />
                                    </Item>
                                  </Tab>
                                  <Tab title="Devolución Directa">
                                    <Item dataField="DirectReturn" editorType="dxCheckBox">
                                      <Label text="Devolución Directa" />
                                    </Item>
                                    <Item dataField="DirectReturnLevel" editorType="dxSelectBox" editorOptions={directReturnLevelEditorOptions} >
                                      <Label text="Nivel Permiso" />
                                    </Item>
                                  </Tab>
                                  <Tab title="Solicitud Envío">
                                    <Item dataField="DeliveryRequest" editorType="dxCheckBox">
                                      <Label text="Solicitud Envío" />
                                    </Item>
                                    <Item dataField="DeliveryRequestLevel" editorType="dxSelectBox" editorOptions={deliveryRequestLevelEditorOptions} >
                                      <Label text="Nivel Permiso" />
                                    </Item>
                                  </Tab>
                                  <Tab title="Solicitud Devolución">
                                    <Item dataField="ReturnRequest" editorType="dxCheckBox">
                                      <Label text="Solicitud Devolución" />
                                    </Item>
                                    <Item dataField="ReturnRequestLevel" editorType="dxSelectBox" editorOptions={returnRequestLevelEditorOptions} >
                                      <Label text="Nivel Permiso" />
                                    </Item>
                                  </Tab>
                                  <Tab title="Solicitud de Traspaso">
                                    <Item dataField="TransferOrder" editorType="dxCheckBox">
                                      <Label text="Solicitud Traspaso" />
                                    </Item>
                                    <Item dataField="TransferOrderLevel" editorType="dxSelectBox" editorOptions={transferOrderLevelEditorOptions} >
                                      <Label text="Nivel Permiso" />
                                    </Item>
                                  </Tab>

                                  <Tab title="Permisos Generales">
                                    <Item dataField="ApproveDelivery" editorType="dxCheckBox">
                                      <Label text="Autorizar Entregas de Mercancía" />
                                    </Item>
                                    <Item dataField="VoidDeliveryTransactions" editorType="dxCheckBox">
                                      <Label text="Cancelar transacciones Concluidas" />
                                    </Item>
                                  </Tab>

                                </TabbedItem>
                              </Tab>
                              <Tab title="Modificación de Pedidos">
                                <Item dataField="SalesOrderMod" editorType="dxCheckBox">
                                  <Label text="Sol Modificación" />
                                </Item>
                                <Item dataField="SalesOrderModLevel" editorType="dxSelectBox" editorOptions={salesOrderModLevelEditorOptions} >
                                  <Label text="Nivel Permiso" />
                                </Item>
                                <Item dataField="SalesOrderModUpdatePrices" editorType="dxCheckBox" editorOptions={salesOrderModPermissionEditorOptions}>
                                  <Label text="Permitir Modificar Precios" />
                                </Item>
                                <Item dataField="ApproveSalesOrderMod" editorType="dxCheckBox" editorOptions={salesOrderModPermissionEditorOptions}>
                                  <Label text="Autorizar Modificaciones" />
                                </Item>
                              </Tab>
                              <Tab title="Embarques">
                                <Item dataField="ShipmentRequest" editorType="dxCheckBox">
                                  <Label text="Solicitud Embarque" />
                                </Item>
                                <Item dataField="ShipmentRequestLevel" editorType="dxSelectBox" editorOptions={shipmentRequestLevelEditorOptions} >
                                  <Label text="Nivel Permiso" />
                                </Item>
                              </Tab>
                              <Tab title="Corte de Caja">
                                <Item dataField="CashRegister" editorType="dxCheckBox">
                                  <Label text="Corte Caja" />
                                </Item>
                                <Item dataField="CashRegisterLevel" editorType="dxSelectBox" editorOptions={cashRegisterLevelEditorOptions} >
                                  <Label text="Nivel Permiso" />
                                </Item>
                              </Tab>
                              <Tab title="Cuentas por Cobrar">
                                <Item dataField="CustomerPayment" editorType="dxCheckBox">
                                  <Label text="Anticipo Saldo" />
                                </Item>
                                <Item dataField="CustomerPaymentLevel" editorType="dxSelectBox" editorOptions={customerPaymentLevelEditorOptions} >
                                  <Label text="Nivel Permiso" />
                                </Item>
                              </Tab>
                              <Tab title="Inventario">
                                <TabbedItem>
                                  <TabPanelOptions deferRendering={false} showNavButtons={true} />
                                  <Tab title="Solicitud Traspaso">
                                    <Item dataField="InvTransferOrder" editorType="dxCheckBox">
                                      <Label text="Solicitud Traspaso" />
                                    </Item>
                                    <Item dataField="InvTransferOrderLevel" editorType="dxSelectBox" editorOptions={invTransferOrderLevelEditorOptions} >
                                      <Label text="Nivel Permiso" />
                                    </Item>
                                    <Item dataField="ApproveInvTransferOrder" editorType="dxCheckBox" editorOptions={invTransferOrderLevelEditorOptions} >
                                      <Label text="Autorizar Solicitud Traspaso" />
                                    </Item>
                                  </Tab>
                                  <Tab title="Salida Traspaso">
                                    <Item dataField="InvItemFulfillment" editorType="dxCheckBox">
                                      <Label text="Salida Traspaso" />
                                    </Item>
                                    <Item dataField="InvItemFulfillmentLevel" editorType="dxSelectBox" editorOptions={invItemFulfillmentLevelEditorOptions} >
                                      <Label text="Nivel Permiso" />
                                    </Item>
                                  </Tab>
                                  <Tab title="Recibo Traspaso">
                                    <Item dataField="InvItemReceipt" editorType="dxCheckBox">
                                      <Label text="Recibo Traspaso" />
                                    </Item>
                                    <Item dataField="InvItemReceiptLevel" editorType="dxSelectBox" editorOptions={invItemReceiptLevelEditorOptions} >
                                      <Label text="Nivel Permiso" />
                                    </Item>
                                  </Tab>
                                  <Tab title="Transferencia de Inventario">
                                    <Item dataField="InventoryTransfer" editorType="dxCheckBox">
                                      <Label text="Transferencia de Inventario" />
                                    </Item>
                                    <Item dataField="InventoryTransferLevel" editorType="dxSelectBox" editorOptions={inventoryTransferLevelEditorOptions} >
                                      <Label text="Nivel Permiso" />
                                    </Item>
                                    <Item dataField="ApproveInventoryTransfer" editorType="dxCheckBox" editorOptions={approveInventoryTransferEditorOptions} >
                                      <Label text="Autorizar Transferencia" />
                                    </Item>
                                  </Tab>
                                </TabbedItem>
                              </Tab>
                              <Tab title="Comisiones e Incentivos">
                                <TabbedItem>
                                  <TabPanelOptions deferRendering={false} showNavButtons={true} />
                                  <Tab title="Comisiones">
                                    <Item dataField="Commission" editorType="dxCheckBox">
                                      <Label text="Comisiones" />
                                    </Item>
                                  </Tab>
                                  <Tab title="Incentivos">
                                    <Item dataField="Incentive" editorType="dxCheckBox">
                                      <Label text="Incentivos" />
                                    </Item>
                                  </Tab>
                                </TabbedItem>
                              </Tab>
                              <Tab title="Checklist">
                                <TabbedItem>
                                  <TabPanelOptions deferRendering={false} showNavButtons={true} />
                                  <Tab title="Supervisores">
                                    <Item dataField="CheckList" editorType="dxCheckBox">
                                      <Label text="Checklist Supervisores" />
                                    </Item>
                                    <Item dataField="NewCheckList" editorType="dxCheckBox" editorOptions={newChecklistEditorOptions}>
                                      <Label text="Crear Checklist" />
                                    </Item>
                                  </Tab>
                                  <Tab title="Auditores">
                                    <Item dataField="CheckListAud" editorType="dxCheckBox">
                                      <Label text="Checklist Auditores" />
                                    </Item>
                                    <Item dataField="NewCheckListAud" editorType="dxCheckBox" editorOptions={newChecklistAudEditorOptions}>
                                      <Label text="Crear Checklist" />
                                    </Item>
                                  </Tab>
                                  <Tab title="Vehículos">
                                    <Item dataField="CheckListVeh" editorType="dxCheckBox">
                                      <Label text="Checklist Vehículos" />
                                    </Item>
                                    <Item dataField="NewCheckListVeh" editorType="dxCheckBox" editorOptions={newChecklistVehEditorOptions}>
                                      <Label text="Crear Checklist" />
                                    </Item>
                                  </Tab>
                                  <Tab title="Plazas">
                                    <Item dataField="checklistPlaza" editorType="dxTagBox" editorOptions={checklistPlazaEditorOptions} >
                                      <Label text="Plazas" />
                                    </Item>
                                  </Tab>
                                </TabbedItem>
                              </Tab>
                              <Tab title="Manual de capacitación">
                                <Item dataField="Document" editorType="dxCheckBox">
                                  <Label text="Manual de Capacitación" />
                                </Item>
                              </Tab>
                              <Tab title="Solicitud de vacaciones">
                                <Item dataField="Vacation" editorType="dxCheckBox">
                                  <Label text="Solicitud de Vacaciones" />
                                </Item>
                                <Item dataField="vacationPlaza" editorType="dxTagBox" editorOptions={vacationPlazaEditorOptions} >
                                  <Label text="Plazas" />
                                </Item>
                              </Tab>
                              <Tab title="Kibana">
                                <Item dataField="Kibana" editorType="dxCheckBox">
                                  <Label text="Kibana" />
                                </Item>
                              </Tab>
                              <Tab title="Exploradores">
                                <Item dataField="InvLevels" editorType="dxCheckBox">
                                  <Label text="Inventarios" />
                                </Item>
                                <Item dataField="InvLevelsIncCost" editorType="dxCheckBox">
                                  <Label text="Inventarios con costos" />
                                </Item>
                                <Item dataField="Kardex" editorType="dxCheckBox">
                                  <Label text="Kardex" />
                                </Item>
                                <Item dataField="ExploreBarcodes" editorType="dxCheckBox">
                                  <Label text="WMS Barcodes" />
                                </Item>
                                <Item dataField="ExploreBarcodeCosts" editorType="dxCheckBox">
                                  <Label text="WMS Barcodes con Costos" />
                                </Item>                                
                              </Tab>
                              <Tab title="Reportes">
                                <Item dataField="RepComparativoVentas" editorType="dxCheckBox">
                                  <Label text="Comparativo Ventas" />
                                </Item>
                                <Item dataField="POSuggestionsReport" editorType="dxCheckBox">
                                  <Label text="Sugerencia de Compras" />
                                </Item>
                                <Item dataField="POSuggestionsReportCfg" editorType="dxCheckBox" editorOptions={poSuggestionsReportCfgEditorOptions} >
                                  <Label text="Configuración Sugerencia de Compras" />
                                </Item>
                              </Tab>
                              <Tab title="WMS">
                                <TabbedItem>
                                  <Tab title="Alta Barcode">
                                    <Item dataField="CreateLabelWMS" editorType="dxCheckBox">
                                      <Label text="Alta Barcode" />
                                    </Item>
                                    <Item dataField="CreateLabelWMSLevel" editorType="dxSelectBox" editorOptions={createLabelWMSLevelEditorOptions} >
                                      <Label text="Nivel Permiso" />
                                    </Item>
                                  </Tab>
                                  <Tab title="Orden Recibo">
                                    <Item dataField="ReceiveOrderWMS" editorType="dxCheckBox">
                                      <Label text="Orden Recibo" />
                                    </Item>
                                    <Item dataField="ReceiveOrderWMSLevel" editorType="dxSelectBox" editorOptions={receiveOrderWMSLevelEditorOptions} >
                                      <Label text="Nivel Permiso" />
                                    </Item>
                                  </Tab>
                                  <Tab title="Orden Surtido">
                                    <Item dataField="FulfillmentOrderWMS" editorType="dxCheckBox">
                                      <Label text="Orden Surtido" />
                                    </Item>
                                    <Item dataField="AssignFulfillmentOrderWMS" editorType="dxCheckBox" editorOptions={assignFulfillmentOrderWMSEditorOptions} >
                                      <Label text="Reasignar Barcode" />
                                    </Item>
                                    <Item dataField="FulfillmentOrderWMSLevel" editorType="dxSelectBox" editorOptions={fulfillmentOrderWMSLevelEditorOptions} >
                                      <Label text="Nivel Permiso" />
                                    </Item>
                                  </Tab>
                                  <Tab title="Acomodo">
                                    <Item dataField="MoveItemWMS" editorType="dxCheckBox">
                                      <Label text="Acomodo" />
                                    </Item>
                                  </Tab>
                                  <Tab title="Ajuste">
                                    <Item dataField="AdjustItemWMS" editorType="dxCheckBox">
                                      <Label text="Ajuste" />
                                    </Item>
                                    <Item dataField="AdjustItemWMSLevel" editorType="dxSelectBox" editorOptions={adjustItemWMSLevelEditorOptions} >
                                      <Label text="Nivel Permiso" />
                                    </Item>
                                    <Item dataField="ApproveAdjustItemWMS" editorType="dxCheckBox" editorOptions={approveAdjustItemWMSLevelEditorOptions} >
                                      <Label text="Autorizar Ajuste" />
                                    </Item>
                                  </Tab>
                                  <Tab title="Conteo Cíclico">
                                    <Item dataField="ItemCountWMS" editorType="dxCheckBox">
                                      <Label text="Conteo Cíclico" />
                                    </Item>
                                    <Item dataField="ItemCountWMSLevel" editorType="dxSelectBox" editorOptions={itemCountWMSLevelEditorOptions} >
                                      <Label text="Nivel Permiso" />
                                    </Item>
                                    <Item dataField="ApproveItemCountWMS" editorType="dxCheckBox" editorOptions={approveItemCountWMSLevelEditorOptions} >
                                      <Label text="Autorizar Conteo Cíclico" />
                                    </Item>
                                  </Tab>
                                  <Tab title="Procesar Envios Handheld">
                                    <Item dataField="SendWMS" editorType="dxCheckBox">
                                      <Label text="Procesar Envios" />
                                    </Item>
                                    <Item dataField="PickWMS" editorType="dxCheckBox" editorOptions={sendWMSEditorOptions} >
                                      <Label text="Procesar Pick" />
                                    </Item>
                                    <Item dataField="PackWMS" editorType="dxCheckBox" editorOptions={sendWMSEditorOptions} >
                                      <Label text="Procesar Pack" />
                                    </Item>
                                    <Item dataField="ShipWMS" editorType="dxCheckBox" editorOptions={sendWMSEditorOptions} >
                                      <Label text="Procesar Ship" />
                                    </Item>
                                  </Tab>
                                  <Tab title="Procesar Recibos Handheld">
                                    <Item dataField="ReceiveWMS" editorType="dxCheckBox">
                                      <Label text="Procesar Recibos" />
                                    </Item>
                                  </Tab>
                                  <Tab title="Procesar Productos Handheld">
                                    <Item dataField="ProductsWMS" editorType="dxCheckBox">
                                      <Label text="Procesar Productos" />
                                    </Item>
                                    <Item dataField="AdjustmentWMS" editorType="dxCheckBox" editorOptions={productsWMSEditorOptions} >
                                      <Label text="Procesar Ajuste" />
                                    </Item>
                                    <Item dataField="MoveWMS" editorType="dxCheckBox" editorOptions={productsWMSEditorOptions} >
                                      <Label text="Procesar Acomodo" />
                                    </Item>
                                    <Item dataField="CountWMS" editorType="dxCheckBox" editorOptions={productsWMSEditorOptions} >
                                      <Label text="Procesar Conteo Cíclico" />
                                    </Item>
                                  </Tab>
                                </TabbedItem>
                              </Tab>
                            </TabbedItem>
                          </GroupItem>
                        </Form>
                      </div>
                    </div>
                  </div>
                ) :
                (<div></div>)
              }
            </div>
          );
        }}
        />
      </div>
    </div>
  );
}