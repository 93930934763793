import React, { useState, useEffect, useRef } from 'react';

// LoadPanel
import { LoadPanel } from 'devextreme-react/load-panel';

// Toast
import { Toast } from 'devextreme-react/toast';

// Grid
import DataGrid, {
  Column, FilterRow, HeaderFilter, FilterPanel, FilterBuilderPopup,
  GroupPanel, Grouping, SearchPanel, Sorting, Editing, Lookup, Toolbar, Item, RequiredRule,
  Scrolling, Pager, Paging, Summary, TotalItem, StateStoring, ColumnChooser, Format, Selection,
  GroupItem, Export, ColumnChooserSearch, ColumnChooserSelection
} from 'devextreme-react/data-grid';

//CheckBox
import CheckBox from 'devextreme-react/check-box';

import SelectBox from 'devextreme-react/select-box';

import { Auth, API, Storage } from 'aws-amplify';

// Ruteador
import { Route, Link, useHistory } from "react-router-dom";

// Iconos
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/pro-duotone-svg-icons";
import { faLockKeyhole } from "@fortawesome/pro-solid-svg-icons";
import { faArrowsRotate } from "@fortawesome/pro-solid-svg-icons";
import { faCircleInfo } from "@fortawesome/pro-solid-svg-icons";

// Iconos tablero de control
import { faPlaneCircleExclamation } from "@fortawesome/pro-duotone-svg-icons";
import { faPlaneCircleCheck } from "@fortawesome/pro-duotone-svg-icons";
import { faCoins } from "@fortawesome/pro-duotone-svg-icons";
import { faMoneyBill } from "@fortawesome/pro-duotone-svg-icons";
import { faBadgeCheck } from "@fortawesome/pro-duotone-svg-icons";
import { faCircleExclamation } from "@fortawesome/pro-duotone-svg-icons";
import { faBan } from "@fortawesome/pro-duotone-svg-icons";
import { faPrint } from "@fortawesome/pro-duotone-svg-icons";
import { faRotateLeft } from "@fortawesome/pro-duotone-svg-icons";
import { faBoxTaped } from "@fortawesome/pro-duotone-svg-icons";
import { faBoxOpenFull } from "@fortawesome/pro-duotone-svg-icons";

import 'devextreme/data/odata/store';
import CustomStore from 'devextreme/data/custom_store';

import Lambda from 'aws-sdk/clients/lambda';

import { Popup, Position, ToolbarItem } from 'devextreme-react/popup';

import ScrollView from 'devextreme-react/scroll-view';

import '../ListStyle.css';

import dxDateBox from "devextreme/ui/date_box";

import dxNumberBox from "devextreme/ui/number_box";

import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
import { exportDataGrid } from 'devextreme/excel_exporter'

//confirm
import { confirm } from 'devextreme/ui/dialog';

dxDateBox.defaultOptions({
  options: {
    dateSerializationFormat: "yyyy-MM-dd"
  }
});

dxNumberBox.defaultOptions({
  options: {
    step: 0
  }
});

function isNotEmpty(value) {
  return value !== undefined && value !== null && value !== '';
}

export default function LockSalesOrder(props) {
  const history = useHistory();

  //Controles para expander los grupos del grid de checklist
  const [autoExpandAllChecklist, setAutoExpandAllChecklist] = useState(true);

  const [autoExpandAll, setAutoExpandAll] = useState(true);

  //Ref para el grid Cerrar Pedidos
  const [gridLockSalesOrderRef, setGridLockSalesOrderRef] = useState(React.createRef());

  //Ref para el grid Icono Ayuda
  const [gridIconoAyudaRef, setGridIconoAyudaRef] = useState(React.createRef());


  const [visibleLoadPanel, setVisibleLoadPanel] = useState(false);

  const filterBuilderPopupPosition = {
    of: window,
    at: 'top',
    my: 'top',
    offset: { y: 10 },
  };

  const [visibleToast, setVisibleToast] = useState(false);
  const [messageToast, setMessageToast] = useState('');
  const [typeToast, setTypeToast] = useState('success');

  const [popupVisible, setPopupVisible] = useState(false);
  const [popupTitle, setPopupTitle] = useState('Íconos Pedido');

  const allowedPageSizes = [5, 10, 15, 20, 100, 200, 'all'];

  const exportFormats = ['xlsx'];

  function getOrderDay(rowData) {
    return (new Date(rowData.OrderDate)).getDay();
  }

  const filterBuilder = {
    customOperations: [{
      name: 'weekends',
      caption: 'Weekends',
      dataTypes: ['date'],
      icon: 'check',
      hasValue: false,
      calculateFilterExpression: () => [[getOrderDay, '=', 0], 'or', [getOrderDay, '=', 6]],
    }],
    allowHierarchicalFields: true,
  };

  const filterValue = [];

  const getSalesOrderRecords = React.useCallback(async (sqlFilter) => {
    try {
      let apiName = 'AdminSC';
      let path = '/cerrarpedidos';

      let myInit = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
        },
        queryStringParameters: {
          action: 'listOpen',
          sqlFilter: sqlFilter,
          salesAccess: props.salesAccess,
          sucursal: props.sucursal,
          username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username
        }
      }

      var salesOrdersGet = await API.get(apiName, path, myInit);
    }
    catch (e) {
      if (e === 'No current user') {
        history.push('/');

        window.location.reload();
      }
    }

    return salesOrdersGet;

  }, [history, props.salesAccess, props.sucursal]);

  const [storeAyuda] = useState([
    {
      Tipo: 'Estatus general',
      Descripcion: 'Pedido cerrado',
      Icono: 'faBadgeCheck'
    },
    {
      Tipo: 'Estatus general',
      Descripcion: 'Pedido abierto',
      Icono: 'faCircleExclamation'
    },
    {
      Tipo: 'Estatus general',
      Descripcion: 'Pedido cancelado',
      Icono: 'faBan'
    },
    {
      Tipo: 'Situación pedido',
      Descripcion: 'Pedido facturable',
      Icono: 'faBoxTaped'
    },
    {
      Tipo: 'Situación pedido',
      Descripcion: 'Pedido separado',
      Icono: 'faBoxOpenFull'
    },
    {
      Tipo: 'Programación',
      Descripcion: 'Programación nula',
      Icono: 'programacionNula'
    },
    {
      Tipo: 'Programación',
      Descripcion: 'Programación parcial',
      Icono: 'programacionParcial'
    },
    {
      Tipo: 'Programación',
      Descripcion: 'Programación total',
      Icono: 'programacionTotal'
    },
    {
      Tipo: 'Entrega',
      Descripcion: 'Entrega parcial',
      Icono: 'faPlaneCircleExclamation'
    },
    {
      Tipo: 'Entrega',
      Descripcion: 'Entrega total',
      Icono: 'faPlaneCircleCheck'
    },
    {
      Tipo: 'Pago',
      Descripcion: 'Pago parcial',
      Icono: 'faCoins'
    },
    {
      Tipo: 'Pago',
      Descripcion: 'Pago total',
      Icono: 'faMoneyBill'
    },
    {
      Tipo: 'Factura',
      Descripcion: 'Factura impresa',
      Icono: 'faPrint'
    },
    {
      Tipo: 'Mercancía pendiente recolección',
      Descripcion: 'Mercancía pendiente recolección',
      Icono: 'faRotateLeft'
    },
  ]);

  const store = new CustomStore({
    key: 'ID',
    load(loadOptions) {
      let sqlFilter = '(';
      [
        'filter'
      ].forEach((i) => {
        if (i in loadOptions && isNotEmpty(loadOptions[i])) {
          var filters = loadOptions[i];

          var filtersLength = filters.length;

          for (var i = 0; i <= filtersLength; i++) {
            var singleFilter = filters[i];
            var singleFilterType = typeof singleFilter;
            var singleFilterIsArray = Array.isArray(singleFilter);
            var singleFilterIsDate = singleFilter instanceof Date;

            if (singleFilter === 'NULL') {
              singleFilter = '0';
            }

            if (singleFilterType.toString() === 'object' && singleFilterIsArray) {
              sqlFilter += '(';

              var subFiltersLength = singleFilter.length;

              for (var i2 = 0; i2 <= subFiltersLength; i2++) {
                var singleSubFilter = singleFilter[i2];
                var singleSubFilterType = typeof singleSubFilter;
                var singleSubFilterIsArray = Array.isArray(singleSubFilter);
                var singleSubFilterIsDate = singleSubFilter instanceof Date

                if (singleSubFilter === 'NULL') {
                  singleSubFilter = '0';
                }

                if (singleSubFilterType.toString() === 'object' && singleSubFilterIsArray) {
                  sqlFilter += '(';

                  var subSubFiltersLength = singleSubFilter.length;

                  for (var i3 = 0; i3 <= subSubFiltersLength; i3++) {
                    var singleSubSubFilter = singleSubFilter[i3];
                    var singleSubSubFilterType = typeof singleSubSubFilter;
                    var singleSubSubFilterIsArray = Array.isArray(singleSubSubFilter);
                    var singleSubSubFilterIsDate = singleSubSubFilter instanceof Date

                    if (singleSubSubFilter === 'NULL') {
                      singleSubSubFilter = '0';
                    }

                    if (singleSubSubFilterType.toString() === 'object' && singleSubSubFilterIsArray) {
                      sqlFilter += '(';

                      var subSubSubFiltersLength = singleSubSubFilter.length;

                      for (var i4 = 0; i4 <= subSubSubFiltersLength; i4++) {
                        var singleSubSubSubFilter = singleSubSubFilter[i4];
                        var singleSubSubSubFilterType = typeof singleSubSubSubFilter;
                        var singleSubSubSubFilterIsArray = Array.isArray(singleSubSubSubFilter);
                        var singleSubSubSubFilterIsDate = singleSubSubSubFilter instanceof Date

                        if (singleSubSubSubFilter === 'NULL') {
                          singleSubSubSubFilter = '0';
                        }

                        if (singleSubSubSubFilterType.toString() === 'object' && singleSubSubSubFilterIsArray) {
                          sqlFilter += '(';

                          var subSubSubSubFiltersLength = singleSubSubSubFilter.length;

                          for (var i5 = 0; i5 <= subSubSubSubFiltersLength; i5++) {
                            var singleSubSubSubSubFilter = singleSubSubSubFilter[i5];
                            var singleSubSubSubSubFilterType = typeof singleSubSubSubSubFilter;
                            var singleSubSubSubSubFilterIsArray = Array.isArray(singleSubSubSubSubFilter);
                            var singleSubSubSubSubFilterIsDate = singleSubSubSubSubFilter instanceof Date

                            if (singleSubSubSubSubFilter === 'NULL') {
                              singleSubSubSubSubFilter = '0';
                            }

                            if (singleSubSubSubSubFilterType.toString() === 'string') {
                              if (singleSubSubSubSubFilter === 'contains') {
                                filters[i][i2][i3][i4][i5] = 'LIKE';
                                singleSubSubSubSubFilter = 'LIKE';

                                filters[i][i2][i3][i4][i5 + 1] = '\'%' + filters[i][i2][i3][i4][i5 + 1] + '%\'';
                              }
                              else if (singleSubSubSubSubFilter === 'notcontains') {
                                filters[i][i2][i3][i4][i5] = 'NOT LIKE';
                                singleSubSubSubSubFilter = 'NOT LIKE';

                                filters[i][i2][i3][i4][i5 + 1] = '\'%' + filters[i][i2][i3][i4][i5 + 1] + '%\'';
                              }
                              else if (singleSubSubSubSubFilter === 'startswith') {
                                filters[i][i2][i3][i4][i5] = 'LIKE';
                                singleSubSubSubSubFilter = 'LIKE';

                                filters[i][i2][i3][i4][i5 + 1] = '\'' + filters[i][i2][i3][i4][i5 + 1] + '%\'';
                              }
                              else if (singleSubSubSubSubFilter === 'endswith') {
                                filters[i][i2][i3][i4][i5] = 'LIKE';
                                singleSubSubSubSubFilter = 'LIKE';

                                filters[i][i2][i3][i4][i5 + 1] = '\'%' + filters[i][i2][i3][i4][i5 + 1] + '\'';
                              }
                              else if (singleSubSubSubSubFilter === '=' || singleSubSubSubSubFilter === '<>' || singleSubSubSubSubFilter === '>' || singleSubSubSubSubFilter === '>=' || singleSubSubSubSubFilter === '<' || singleSubSubSubSubFilter === '<=') {
                                if (!filters[i][i2][i3][i4][i5 + 1])
                                  filters[i][i2][i3][i4][i5 + 1] = 'NULL';
                                else if (typeof filters[i][i2][i3][i4][i5 + 1] === 'string') {
                                  filters[i][i2][i3][i4][i5 + 1] = '\'' + filters[i][i2][i3][i4][i5 + 1] + '\'';
                                }
                              }
                              else if (singleSubSubSubSubFilter === '!') {
                                filters[i][i2][i3][i4][i5] = 'NOT';
                                singleSubSubSubSubFilter = 'NOT';
                              }

                              sqlFilter += ' ' + singleSubSubSubSubFilter + ' ';
                            }
                            else if (singleSubSubSubSubFilterType.toString() === 'object' && singleSubSubSubSubFilterIsDate) {
                              sqlFilter += ' \'' + singleSubSubSubSubFilter.toISOString().split('T')[0] + '\' ';
                            }
                            else if (singleSubSubSubSubFilterType.toString() === 'number') {
                              sqlFilter += ' ' + singleSubSubSubSubFilter.toString() + ' ';
                            }
                            else if (singleSubSubSubSubFilterType.toString() === 'boolean') {
                              if (singleSubSubSubSubFilter.toString() === 'true')
                                sqlFilter += '1 ';
                              else if (singleSubSubSubSubFilter.toString() === 'false' || singleSubSubSubSubFilter === null || singleSubSubSubSubFilter === undefined)
                                sqlFilter += '0 ';
                            }
                          }
                        }
                        else if (singleSubSubSubFilterType.toString() === 'string') {
                          if (singleSubSubSubFilter === 'contains') {
                            filters[i][i2][i3][i4] = 'LIKE';
                            singleSubSubSubFilter = 'LIKE';

                            filters[i][i2][i3][i4 + 1] = '\'%' + filters[i][i2][i3][i4 + 1] + '%\'';
                          }
                          else if (singleSubSubSubFilter === 'notcontains') {
                            filters[i][i2][i3][i4] = 'NOT LIKE';
                            singleSubSubSubFilter = 'NOT LIKE';

                            filters[i][i2][i3][i4 + 1] = '\'%' + filters[i][i2][i3][i4 + 1] + '%\'';
                          }
                          else if (singleSubSubSubFilter === 'startswith') {
                            filters[i][i2][i3][i4] = 'LIKE';
                            singleSubSubSubFilter = 'LIKE';

                            filters[i][i2][i3][i4 + 1] = '\'' + filters[i][i2][i3][i4 + 1] + '%\'';
                          }
                          else if (singleSubSubSubFilter === 'endswith') {
                            filters[i][i2][i3][i4] = 'LIKE';
                            singleSubSubSubFilter = 'LIKE';

                            filters[i][i2][i3][i4 + 1] = '\'%' + filters[i][i2][i3][i4 + 1] + '\'';
                          }
                          else if (singleSubSubSubFilter === '=' || singleSubSubSubFilter === '<>' || singleSubSubSubFilter === '>' || singleSubSubSubFilter === '>=' || singleSubSubSubFilter === '<' || singleSubSubSubFilter === '<=') {
                            if (!filters[i][i2][i3][i4 + 1])
                              filters[i][i2][i3][i4 + 1] = 'NULL';
                            else if (typeof filters[i][i2][i3][i4 + 1] === 'string') {
                              filters[i][i2][i3][i4 + 1] = '\'' + filters[i][i2][i3][i4 + 1] + '\'';
                            }
                          }
                          else if (singleSubSubSubFilter === '!') {
                            filters[i][i2][i3][i4] = 'NOT';
                            singleSubSubSubFilter = 'NOT';
                          }

                          sqlFilter += ' ' + singleSubSubSubFilter + ' ';
                        }
                        else if (singleSubSubSubFilterType.toString() === 'object' && singleSubSubSubFilterIsDate) {
                          sqlFilter += ' \'' + singleSubSubSubFilter.toISOString().split('T')[0] + '\' ';
                        }
                        else if (singleSubSubSubFilterType.toString() === 'number') {
                          sqlFilter += ' ' + singleSubSubSubFilter.toString() + ' ';
                        }
                        else if (singleSubSubSubFilterType.toString() === 'boolean') {
                          if (singleSubSubSubFilter.toString() === 'true')
                            sqlFilter += '1 ';
                          else if (singleSubSubSubFilter.toString() === 'false' || singleSubSubSubFilter === null || singleSubSubSubFilter === undefined)
                            sqlFilter += '0 ';
                        }
                      }

                      sqlFilter += ')';
                    }
                    else if (singleSubSubFilterType.toString() === 'string') {
                      if (singleSubSubFilter === 'contains') {
                        filters[i][i2][i3] = 'LIKE';
                        singleSubSubFilter = 'LIKE';

                        filters[i][i2][i3 + 1] = '\'%' + filters[i][i2][i3 + 1] + '%\'';
                      }
                      else if (singleSubSubFilter === 'notcontains') {
                        filters[i][i2][i3] = 'NOT LIKE';
                        singleSubSubFilter = 'NOT LIKE';

                        filters[i][i2][i3 + 1] = '\'%' + filters[i][i2][i3 + 1] + '%\'';
                      }
                      else if (singleSubSubFilter === 'startswith') {
                        filters[i][i2][i3] = 'LIKE';
                        singleSubSubFilter = 'LIKE';

                        filters[i][i2][i3 + 1] = '\'' + filters[i][i2][i3 + 1] + '%\'';
                      }
                      else if (singleSubSubFilter === 'endswith') {
                        filters[i][i2][i3] = 'LIKE';
                        singleSubSubFilter = 'LIKE';

                        filters[i][i2][i3 + 1] = '\'%' + filters[i][i2][i3 + 1] + '\'';
                      }
                      else if (singleSubSubFilter === '=' || singleSubSubFilter === '<>' || singleSubSubFilter === '>' || singleSubSubFilter === '>=' || singleSubSubFilter === '<' || singleSubSubFilter === '<=') {
                        if (!filters[i][i2][i3 + 1])
                          filters[i][i2][i3 + 1] = 'NULL';
                        else if (typeof filters[i][i2][i3 + 1] === 'string') {
                          filters[i][i2][i3 + 1] = '\'' + filters[i][i2][i3 + 1] + '\'';
                        }
                      }
                      else if (singleSubSubFilter === '!') {
                        filters[i][i2][i3] = 'NOT';
                        singleSubSubFilter = 'NOT';
                      }
                      sqlFilter += ' ' + singleSubSubFilter + ' ';
                    }
                    else if (singleSubSubFilterType.toString() === 'object' && singleSubSubFilterIsDate) {
                      sqlFilter += ' \'' + singleSubSubFilter.toISOString().split('T')[0] + '\' ';
                    }
                    else if (singleSubSubFilterType.toString() === 'number') {
                      sqlFilter += ' ' + singleSubSubFilter.toString() + ' ';
                    }
                    else if (singleSubSubFilterType.toString() === 'boolean') {
                      if (singleSubSubFilter.toString() === 'true')
                        sqlFilter += '1 ';
                      else if (singleSubSubFilter.toString() === 'false' || singleSubSubFilter === null || singleSubSubFilter === undefined)
                        sqlFilter += '0 ';
                    }
                  }

                  sqlFilter += ')';
                }
                else if (singleSubFilterType.toString() === 'string') {
                  if (singleSubFilter === 'contains') {
                    filters[i][i2] = 'LIKE';
                    singleSubFilter = 'LIKE';

                    filters[i][i2 + 1] = '\'%' + filters[i][i2 + 1] + '%\'';
                  }
                  else if (singleSubFilter === 'notcontains') {
                    filters[i][i2] = 'NOT LIKE';
                    singleSubFilter = 'NOT LIKE';

                    filters[i][i2 + 1] = '\'%' + filters[i][i2 + 1] + '%\'';
                  }
                  else if (singleSubFilter === 'startswith') {
                    filters[i][i2] = 'LIKE';
                    singleSubFilter = 'LIKE';

                    filters[i][i2 + 1] = '\'' + filters[i][i2 + 1] + '%\'';
                  }
                  else if (singleSubFilter === 'endswith') {
                    filters[i][i2] = 'LIKE';
                    singleSubFilter = 'LIKE';

                    filters[i][i2 + 1] = '\'%' + filters[i][i2 + 1] + '\'';
                  }
                  else if (singleSubFilter === '=' || singleSubFilter === '<>' || singleSubFilter === '>' || singleSubFilter === '>=' || singleSubFilter === '<' || singleSubFilter === '<=') {
                    if (!filters[i][i2 + 1])
                      filters[i][i2 + 1] = 'NULL';
                    else if (typeof filters[i][i2 + 1] === 'string') {
                      filters[i][i2 + 1] = '\'' + filters[i][i2 + 1] + '\'';
                    }
                  }
                  else if (singleSubFilter === '!') {
                    filters[i][i2] = 'NOT';
                    singleSubFilter = 'NOT';
                  }
                  sqlFilter += ' ' + singleSubFilter + ' ';
                }
                else if (singleSubFilterType.toString() === 'object' && singleSubFilterIsDate) {
                  sqlFilter += ' \'' + singleSubFilter.toISOString().split('T')[0] + '\' ';
                }
                else if (singleSubFilterType.toString() === 'number') {
                  sqlFilter += ' ' + singleSubFilter.toString() + ' ';
                }
                else if (singleSubFilterType.toString() === 'boolean') {
                  if (singleSubFilter.toString() === 'true')
                    sqlFilter += '1 ';
                  else if (singleSubFilter.toString() === 'false' || singleSubFilter === null || singleSubFilter === undefined)
                    sqlFilter += '0 ';
                }
              }

              sqlFilter += ')';
            }
            else if (singleFilterType.toString() === 'string') {
              if (singleFilter === 'contains') {
                filters[i] = 'LIKE';
                singleFilter = 'LIKE';

                filters[i + 1] = '\'%' + filters[i + 1] + '%\'';
              }
              else if (singleFilter === 'notcontains') {
                filters[i] = 'NOT LIKE';
                singleFilter = 'NOT LIKE';

                filters[i + 1] = '\'%' + filters[i + 1] + '%\'';
              }
              else if (singleFilter === 'startswith') {
                filters[i] = 'LIKE';
                singleFilter = 'LIKE';

                filters[i + 1] = '\'' + filters[i + 1] + '%\'';
              }
              else if (singleFilter === 'endswith') {
                filters[i] = 'LIKE';
                singleFilter = 'LIKE';

                filters[i + 1] = '\'%' + filters[i + 1] + '\'';
              }
              else if (singleFilter === '=' || singleFilter === '<>' || singleFilter === '>' || singleFilter === '>=' || singleFilter === '<' || singleFilter === '<=') {
                if (!filters[i + 1])
                  filters[i + 1] = 'NULL';
                else if (typeof filters[i + 1] === 'string') {
                  filters[i + 1] = '\'' + filters[i + 1] + '\'';
                }
              }
              else if (singleFilter === '!') {
                filters[i] = 'NOT';
                singleFilter = 'NOT';
              }
              sqlFilter += ' ' + singleFilter + ' ';
            }
            else if (singleFilterType.toString() === 'object' && singleFilterIsDate) {
              sqlFilter += ' \'' + singleFilter.toISOString().split('T')[0] + '\' ';
            }
            else if (singleFilterType.toString() === 'number') {
              sqlFilter += ' ' + singleFilter.toString() + ' ';
            }
            else if (singleFilterType.toString() === 'boolean') {
              if (singleFilter.toString() === 'true')
                sqlFilter += '1 ';
              else if (singleFilter.toString() === 'false' || singleFilter === null || singleFilter === undefined)
                sqlFilter += '0 ';
            }
          }

          sqlFilter += ')';
        }
      });

      async function getRecords() {
        if (sqlFilter === '(')
          sqlFilter = '';

        var records = await getSalesOrderRecords(sqlFilter);
        return {
          data: records,
          totalCount: records.length
        };
      }

      return getRecords()
        .then(function (result) {
          return result;
        })
        .catch(() => { throw new Error('Data Loading Error'); });
    },
  });

  function onAutoExpandAllChanged() {
    setAutoExpandAll(!autoExpandAll)
  };

  function onOptionChanged(e) {
    if (e.name !== 'focusedRowIndex' && e.name !== 'focusedColumnIndex' && e.name !== 'selectedRowKeys') {
      e.component.on("contentReady", () => {
        e.component.off("contentReady");
        e.component.clearSelection();
      });
    }
  }

  async function processButton() {
    if (!props.usuario) {
      setVisibleToast(true);
      setMessageToast('Configuración de usuario incorrecta. Comunicarse con el área de sistemas');
      setTypeToast('error')
      return;
    }

    if (gridLockSalesOrderRef.current) {
      var gridLockSalesOrder = gridLockSalesOrderRef.current.instance;

      var keys = gridLockSalesOrder.getSelectedRowKeys();

      var arraySalesOrder = [];

      for (var keysIndex in keys) {
        arraySalesOrder.push({
          ID: keys[keysIndex]
        });
      }

      if (arraySalesOrder.length === 0) {
        setVisibleToast(true);
        setMessageToast('Falta seleccionar los Pedidos a Cerrar');
        setTypeToast('error')
      }
      else {
        var result = confirm('Se cerrarán ' + arraySalesOrder.length + ' Pedidos. ¿Desea continuar?', 'Cerrar Pedidos');

        result.then(async (dialogResult) => {
          if (dialogResult) {
            setVisibleLoadPanel(true);

            var lambdaFunction;

            if (window.location.href.indexOf('sandbox.') >= 0 || window.location.href.indexOf('localhost') >= 0)
              lambdaFunction = 'cerrarPedidos-dev';
            else if (window.location.href.indexOf('sandboxtampico.') >= 0)
              lambdaFunction = 'cerrarPedidos-devleona';
            else if (window.location.href.indexOf('tampico.') >= 0)
              lambdaFunction = 'cerrarPedidos-prodleona';
            else
              lambdaFunction = 'cerrarPedidos-prod';

            Auth.currentCredentials()
              .then(credentials => {
                const lambda = new Lambda({
                  credentials: Auth.essentialCredentials(credentials),
                  region: 'us-east-1'
                });

                var params = {
                  FunctionName: lambdaFunction,
                  Payload: JSON.stringify({
                    action: 'closeProcess',
                    usuario: props.usuario,
                    salesorders: arraySalesOrder,
                    username: props.username
                  })
                };

                lambda.invoke(params, function (err, data) {
                  if (err) {
                    setVisibleLoadPanel(false);

                    console.log(err, err.stack);
                  }
                  else {
                    setVisibleLoadPanel(false);

                    setVisibleToast(true);
                    setMessageToast('Proceso concluido');
                    setTypeToast('success');

                    gridLockSalesOrder.refresh();
                  }
                });
              })
          }
        });
      }
    }
  };

  const hidePopupInfo = React.useCallback(() => {
    setPopupVisible(false);
  }, []);

  const helpButton = React.useCallback(() => {
    if (gridIconoAyudaRef.current) {
      var gridIconoAyuda = gridIconoAyudaRef.current.instance;

      gridIconoAyuda.refresh();
    }

    setPopupVisible(true);
  }, [gridIconoAyudaRef]);


  function refreshButton() {
    if (gridLockSalesOrderRef.current) {
      var gridTablero = gridLockSalesOrderRef.current.instance;

      gridTablero.refresh();
    }
  }

  const iconoProgramacion = React.useCallback((data) => {
    var EstatusProg = data.data.EstatusProg;

    if (EstatusProg === 'NULA') {
      return (<div class="inline-block w-5 h-5 bg-red-500 rounded-full"></div>)
    }
    else if (EstatusProg === 'PARCIAL') {
      return (<div class="inline-block w-5 h-5 bg-yellow-400 rounded-full"></div>)
    }
    else if (EstatusProg === 'TOTAL') {
      return (<div class="inline-block w-5 h-5 bg-green-400 rounded-full"></div>)
    }
  }, []);

  const iconoEntrega = React.useCallback((data) => {
    var PorcEntrega = data.data.PorcEntrega;

    if (!PorcEntrega)
      PorcEntrega = 0;

    if (PorcEntrega > 0 && PorcEntrega < 1) {
      return (<div class="inline-block text-xl"><FontAwesomeIcon icon={faPlaneCircleExclamation} /></div>)
    }
    else if (PorcEntrega >= 1) {
      return (<div class="inline-block text-xl"><FontAwesomeIcon icon={faPlaneCircleCheck} /></div>)
    }
  }, []);

  const iconoPago = React.useCallback((data) => {
    var ImporteTotal = parseFloat(data.data.ImporteTotal);
    var Anticipos = parseFloat(data.data.Anticipos);

    if (!ImporteTotal)
      ImporteTotal = 0;
    if (!Anticipos)
      Anticipos = 0;

    if (Anticipos > 0 && Anticipos < ImporteTotal) {
      return (<div class="inline-block text-xl"><FontAwesomeIcon icon={faCoins} /></div>)
    }
    else if (Anticipos > 0 && Anticipos >= ImporteTotal) {
      return (<div class="inline-block text-xl"><FontAwesomeIcon icon={faMoneyBill} /></div>)
    }
  }, []);

  const iconoFactura = React.useCallback((data) => {
    var SCSituacionFact = data.data.SCSituacionFact;

    if (!SCSituacionFact)
      SCSituacionFact = 'NO';

    if (SCSituacionFact === 'SI')
      return (<div class="inline-block text-xl"><FontAwesomeIcon icon={faPrint} /></div>)
  }, []);

  const iconoMPR = React.useCallback((data) => {
    var MPR = data.data.MPR;

    if (!MPR)
      MPR = 'NO';

    if (MPR === 'SI')
      return (<div class="inline-block text-xl"><FontAwesomeIcon icon={faRotateLeft} /></div>)
  }, []);

  const iconoSituacion = React.useCallback((data) => {
    var Situacion = data.data.Situacion;
    var Estatus = data.data.Estatus;

    if (!Situacion)
      Situacion = '';

    if (Situacion === 'Separado No Programable')
      return (<div class="inline-block text-xl"><FontAwesomeIcon icon={faBoxOpenFull} /></div>)
    else if (Situacion === 'Facturable' || Estatus === 'Concluido')
      return (<div class="inline-block text-xl"><FontAwesomeIcon icon={faBoxTaped} /></div>)
  }, []);

  const iconoEstatusGeneral = React.useCallback((data) => {
    var Estatus = data.data.Estatus;
    var Anticipos = parseFloat(data.data.Anticipos);
    var ImporteTotal = parseFloat(data.data.ImporteTotal);
    var PorcEntrega = parseFloat(data.data.PorcEntrega);
    var SCSituacionFact = data.data.SCSituacionFact;
    var MPR = data.data.MPR;

    if (!ImporteTotal)
      ImporteTotal = 0;
    if (!Anticipos)
      Anticipos = 0;
    if (!PorcEntrega)
      PorcEntrega = 0;
    if (!SCSituacionFact)
      SCSituacionFact = 'NO';
    if (!MPR)
      MPR = 'NO';

    if (Estatus === 'Cancelado') {
      return (<div class="inline-block text-xl"><FontAwesomeIcon icon={faBan} /></div>);
    }
    else {
      if (Anticipos >= ImporteTotal && PorcEntrega >= 1 && SCSituacionFact === 'SI' && MPR === 'NO') {
        return (<div class="inline-block text-xl"><FontAwesomeIcon icon={faBadgeCheck} /></div>);
      }
      else {
        return (<div class="inline-block text-xl"><FontAwesomeIcon icon={faCircleExclamation} /></div>);
      }
    }
  }, []);

  const iconHelp = React.useCallback((data) => {
    var Icono = data.data.Icono;

    if (Icono === 'faPlaneCircleExclamation') {
      return (<div class="inline-block text-xl"><FontAwesomeIcon icon={faPlaneCircleExclamation} /></div>);
    }
    else if (Icono === 'faPlaneCircleCheck') {
      return (<div class="inline-block text-xl"><FontAwesomeIcon icon={faPlaneCircleCheck} /></div>);
    }
    else if (Icono === 'faCoins') {
      return (<div class="inline-block text-xl"><FontAwesomeIcon icon={faCoins} /></div>);
    }
    else if (Icono === 'faMoneyBill') {
      return (<div class="inline-block text-xl"><FontAwesomeIcon icon={faMoneyBill} /></div>);
    }
    else if (Icono === 'faBadgeCheck') {
      return (<div class="inline-block text-xl"><FontAwesomeIcon icon={faBadgeCheck} /></div>);
    }
    else if (Icono === 'faCircleExclamation') {
      return (<div class="inline-block text-xl"><FontAwesomeIcon icon={faCircleExclamation} /></div>);
    }
    else if (Icono === 'faBan') {
      return (<div class="inline-block text-xl"><FontAwesomeIcon icon={faBan} /></div>);
    }
    else if (Icono === 'faPrint') {
      return (<div class="inline-block text-xl"><FontAwesomeIcon icon={faPrint} /></div>);
    }
    else if (Icono === 'faRotateLeft') {
      return (<div class="inline-block text-xl"><FontAwesomeIcon icon={faRotateLeft} /></div>);
    }
    else if (Icono === 'faBoxTaped') {
      return (<div class="inline-block text-xl"><FontAwesomeIcon icon={faBoxTaped} /></div>);
    }
    else if (Icono === 'faBoxOpenFull') {
      return (<div class="inline-block text-xl"><FontAwesomeIcon icon={faBoxOpenFull} /></div>);
    }
    else if (Icono === 'programacionNula') {
      return (<div class="inline-block w-5 h-5 bg-red-500 rounded-full"></div>)
    }
    else if (Icono === 'programacionParcial') {
      return (<div class="inline-block w-5 h-5 bg-yellow-400 rounded-full"></div>)
    }
    else if (Icono === 'programacionTotal') {
      return (<div class="inline-block w-5 h-5 bg-green-400 rounded-full"></div>)
    }
  }, []);

  useEffect(() => {
    // eslint-disable-next-line

  }, []);

  function onHidingToast() {
    setVisibleToast(false);
  }

  function onExporting(e) {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Datos');

    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Datos.xlsx');
      });
    });
    e.cancel = true;
  };

  return (
    <div>
      <div class="bg-gray-200 m-2">
        <h1 class="font-sans inline-block text-xl font-bold text-gray-700 leading-8 align-top mb-1"><FontAwesomeIcon icon={faList} size="lg" /> Cerrar Pedidos</h1>
      </div>

      <div class="bg-gray-200 m-2">

        <button onClick={processButton} type="submit" class="mb-4 bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">
          <FontAwesomeIcon icon={faLockKeyhole} /> Cerrar Pedidos
        </button>

        <button onClick={refreshButton} type="submit" class="ml-4 mb-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
          <FontAwesomeIcon icon={faArrowsRotate} /> Actualizar
        </button>

        <button onClick={helpButton} type="submit" class="ml-4 mb-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
          <FontAwesomeIcon icon={faCircleInfo} /> Información
        </button>

        <LoadPanel
          shadingColor="rgba(0,0,0,0.4)"
          visible={visibleLoadPanel}
          showIndicator={true}
          message="Por favor espere..."
          shading={true}
          showPane={true}
          hideOnOutsideClick={false}
        />

        <Toast
          visible={visibleToast}
          message={messageToast}
          type={typeToast}
          onHiding={onHidingToast}
          displayTime={5000}
          width={'auto'}
          position={{
            my: 'top center',
            at: 'top center',
            of: window,
            offset: '0 130'
          }}
        />
        <Popup
          visible={popupVisible}
          onHiding={hidePopupInfo}
          dragEnabled={true}
          hideOnOutsideClick={true}
          showCloseButton={true}
          showTitle={true}
          title={popupTitle}
          width={610}
          height={600}
        >
          <ScrollView
            scrollByContent={true}
            showScrollbar="always"
          >
            <div>
              <DataGrid
                id="gridAyuda"
                columnsAutoWidth={false}
                dataSource={storeAyuda}
                allowColumnReordering={false}
                allowColumnResizing={true}
                columnResizingMode={"widget"}
                showBorders={true}
                rowAlternationEnabled={true}
                showRowLines={true}
                showColumnLines={true}
                ref={gridIconoAyudaRef}
              >
                <Paging defaultPageSize={50} />
                <Grouping allowCollapsing={true} />
                <Column dataField="Tipo" caption="Tipo" width={100} dataType="string" groupIndex={1} />
                <Column caption="Ícono" alignment="center" width={100} cellRender={iconHelp} />
                <Column dataField="Descripcion" dataType="string" width={400} caption="Descripción" />
              </DataGrid>
            </div>
          </ScrollView>
        </Popup>
        <div class="bg-white p-3 shadow-xl">
          <div className="options mb-2">
            <div className="option">
              <CheckBox text="Expandir todos los grupos"
                value={autoExpandAll}
                onValueChanged={onAutoExpandAllChanged}>
              </CheckBox>
            </div>
          </div>
          <DataGrid
            id="gridLockSalesOrder"
            columnsAutoWidth={true}
            filterBuilder={filterBuilder}
            dataSource={store}
            defaultFilterValue={filterValue}
            allowColumnReordering={true}
            allowColumnResizing={true}
            columnResizingMode={"widget"}
            showBorders={true}
            rowAlternationEnabled={true}
            showRowLines={true}
            showColumnLines={true}
            remoteOperations={{ filtering: true }}
            onOptionChanged={onOptionChanged}
            ref={gridLockSalesOrderRef}
            onExporting={onExporting}
          >
            <Scrolling rowRenderingMode='virtual'></Scrolling>
            <Paging defaultPageSize={100} />
            <Pager
              visible={true}
              allowedPageSizes={allowedPageSizes}
              displayMode='compact'
              showPageSizeSelector={true}
              showInfo={true}
              showNavigationButtons={true} />
            <FilterRow visible={true} />
            <FilterPanel visible={true} />
            <FilterBuilderPopup position={filterBuilderPopupPosition} />

            <HeaderFilter visible={true} />

            <GroupPanel visible={true} />
            <SearchPanel visible={true} />
            <ColumnChooser
              enabled={true}
              mode='select'
            >
              <ColumnChooserSearch
                enabled={true}
              />

              <ColumnChooserSelection
                allowSelectAll={true}
                selectByClick={true}
                recursive={true}
              />
            </ColumnChooser>
            <Grouping autoExpandAll={autoExpandAll} />
            <StateStoring enabled={true} type="localStorage" storageKey="gridLockSalesOrder" />
            <Export enabled={true} />

            <Selection
              mode="multiple"
              selectAllMode={'page'}
              showCheckBoxesMode={'always'}
            />

            <Column caption="Estatus General" alignment="center" width={130} cellRender={iconoEstatusGeneral} />
            <Column caption="Situación Pedido" alignment="center" width={130} cellRender={iconoSituacion} />
            <Column caption="Programación" alignment="center" width={110} cellRender={iconoProgramacion} />
            <Column caption="Entrega" alignment="center" width={100} cellRender={iconoEntrega} />
            <Column caption="Pago" alignment="center" width={100} cellRender={iconoPago} />
            <Column caption="Factura" alignment="center" width={100} cellRender={iconoFactura} />
            <Column caption="M. P. R." alignment="center" width={100} cellRender={iconoMPR} />

            <Column dataField="MovID" dataType="string" width={110} caption="Folio" />
            <Column dataField="FechaEmision" caption="Fecha Emisión" dataType="date" width={130} />
            <Column dataField="Estatus" dataType="string" width={120} visible={false} />
            <Column dataField="NombreCliente" caption="Nombre Cliente" dataType="string" width={250} />
            <Column dataField="Plaza" caption="Plaza" dataType="string" width={150} visible={false} />

            <Column dataField="ID" dataType="number" width={150} visible={false} />
            <Column dataField="Mov" dataType="string" width={190} caption="Tipo Movimiento" visible={false} />
            <Column dataField="Movimiento" dataType="string" width={190} visible={false} />
            <Column dataField="Situacion" dataType="string" width={190} caption="Situación" visible={false} />
            <Column dataField="Importe" caption="Importe" dataType="number" width={150} visible={false}>
              <Format
                type="currency"
                precision={2}
              />
            </Column>
            <Column dataField="Impuestos" caption="Impuestos" dataType="number" width={150} visible={false}>
              <Format
                type="currency"
                precision={2}
              />
            </Column>
            <Column dataField="ImporteTotal" caption="Importe Total" dataType="number" width={150}>
              <Format
                type="currency"
                precision={2}
              />
            </Column>
            <Column dataField="SCSituacionFact" dataType="string" width={190} caption="Situación Facturación" visible={false} />
            <Column dataField="SCTipoPedido" dataType="string" width={190} caption="Tipo de Pedido" visible={false} />
            <Column dataField="SCSituacionMPR" dataType="string" width={190} caption="Situación MPR" visible={false} />
            <Column dataField="folioeCommerce" dataType="string" width={190} caption="Folio eCommerce" visible={false} />
            <Column dataField="importeTotaleCommerce" dataType="string" width={190} caption="Importe Total eCommerce" visible={false}>
              <Format
                type="currency"
                precision={2}
              />
            </Column>
            <Column dataField="eCommerceFechaCreacion" dataType="datetime" width={190} caption="eCommerce Fecha Creación" visible={false} />
            <Column dataField="PlazaEntrega" dataType="string" width={250} caption="Plaza Entrega" visible={false} />
            <Column dataField="Almacen" dataType="string" width={190} caption="Almacén" visible={false} />
            <Column dataField="NombreAlmacen" dataType="string" width={190} caption="Nombre Almacén" visible={false} />
            <Column dataField="Agente" dataType="string" width={190} visible={false} />
            <Column dataField="NombreAgente" dataType="string" width={190} visible={false} />
            <Column dataField="Cliente" dataType="string" width={190} visible={false} />
            <Column dataField="Sucursal" dataType="number" width={150} visible={false} />
            <Column dataField="NombreSucursal" dataType="string" width={190} visible={false} />
            <Column dataField="Usuario" dataType="string" width={190} visible={false} />
            <Column dataField="NombreUsuario" dataType="string" width={190} visible={false} />
            <Column dataField="Origen" dataType="string" width={190} visible={false} />
            <Column dataField="EstatusProg" width={190} dataType="string" caption="Estatus Programación" visible={false} />
            <Column dataField="TipoProgramacion" width={190} dataType="string" caption="Tipo Programación" visible={false} />
            <Column dataField="Observaciones" width={190} dataType="string" caption="Observaciones" visible={false} />
            <Column dataField="PorcEntrega" caption="% Entrega" dataType="number" width={150} visible={false} >
              <Format
                type="percent"
              />
            </Column>
            <Column dataField="Anticipos" dataType="number" width={150} visible={false}>
              <Format
                type="currency"
                precision={2}
              />
            </Column>
            <Column dataField="MPR" dataType="string" width={190} caption="MPR" visible={false} />
            <Summary>
              <GroupItem
                column="Importe"
                summaryType="sum"
                valueFormat="$,##0.##"
                displayFormat="Importe {0}"
                showInGroupFooter={false}>
              </GroupItem>
              <GroupItem
                column="Impuestos"
                summaryType="sum"
                valueFormat="$,##0.##"
                displayFormat="Impuestos {0}"
                showInGroupFooter={false}>
              </GroupItem>
              <GroupItem
                column="ImporteTotal"
                summaryType="sum"
                valueFormat="$,##0.##"
                displayFormat="Importe Total {0}"
                showInGroupFooter={false}>
              </GroupItem>
              <TotalItem
                column="Importe"
                summaryType="sum"
                valueFormat="$,##0.##"
                displayFormat="Importe {0}"
                showInColumn="Importe">
              </TotalItem>
              <TotalItem
                column="Impuestos"
                summaryType="sum"
                valueFormat="$,##0.##"
                displayFormat="Impuestos {0}"
                showInColumn="Impuestos">
              </TotalItem>
              <TotalItem
                column="ImporteTotal"
                summaryType="sum"
                valueFormat="$,##0.##"
                displayFormat="Importe Total {0}"
                showInColumn="ImporteTotal">
              </TotalItem>
            </Summary>

          </DataGrid>
        </div>
      </div>
    </div>
  );
}