import React, { useState, useEffect, useRef } from 'react';

// import { Stimulsoft  as StimulsoftDashboard } from 'stimulsoft-dashboards-js/Scripts/stimulsoft.viewer';
// import 'stimulsoft-dashboards-js/Css/stimulsoft.viewer.office2013.whiteblue.css';
// import './App.css';

export default function Home(props) {
  // const [viewer, setViewer] = useState();
  // const [report, setReport] = useState();

  // useEffect(() => {
  //   console.log('Loading Viewer view');

  // console.log('Creating the dashboard viewer with default options');
  // var tmpViewer = new window.Stimulsoft.Viewer.StiViewer(null, 'StiViewer', false);

  // console.log('Creating a new dashboard instance');
  // var tmpReport = window.Stimulsoft.Report.StiReport.createNewDashboard();

  // console.log('Load dashboard from url');
  // tmpReport.loadFile('./dashboard.mrt');

  // console.log('Assigning dashboard to the viewer, the dashboard will be built automatically after rendering the viewer');
  // tmpViewer.report = tmpReport;

  // console.log('Rendering the viewer to selected element');
  // tmpViewer.renderHtml('viewer');
  // }, []);

  return (
    <div>
      {props.status === 'signedout' || props.status === 'signedin' ?
        (
          <div class="flex items-center justify-center">
            <img class="xl:w-2/5 2xl:w-1/4" src="https://imagenes-administracionsupercolchones.s3.amazonaws.com/home.png" alt="" />
          </div>
        ) : (
          <div id="viewer"></div>
        )}
    </div>
  );
};