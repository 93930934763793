import React, { useState, useEffect, useRef, useMemo } from 'react';

// LoadPanel
import { LoadPanel } from 'devextreme-react/load-panel';

// Toast
import { Toast } from 'devextreme-react/toast';

//Form
import Form, { GroupItem, Label, TabbedItem, TabPanelOptions, Tab, EmptyItem } from 'devextreme-react/form';

import { Auth, API, Storage, label } from 'aws-amplify';

// Ruteador
import { Route, Link, useHistory } from "react-router-dom";

// Grid
import DataGrid, {
    Column, FilterRow, HeaderFilter, FilterPanel, FilterBuilderPopup,
    GroupPanel, Grouping, SearchPanel, Sorting, Editing, Lookup, Toolbar, Item, RequiredRule,
    Scrolling, Pager, Paging, Summary, TotalItem, StateStoring, ColumnChooser, Format, Selection,
    Export, ColumnChooserSearch, ColumnChooserSelection
} from 'devextreme-react/data-grid';

//CheckBox
import CheckBox from 'devextreme-react/check-box';

// Iconos
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGlasses } from "@fortawesome/pro-solid-svg-icons";
import { faCartFlatbedSuitcase } from "@fortawesome/pro-duotone-svg-icons";
import { faArrowRotateLeft } from "@fortawesome/pro-solid-svg-icons";
import { faList } from "@fortawesome/pro-duotone-svg-icons";
import { faFilePdf } from "@fortawesome/pro-solid-svg-icons";
import { faArrowsRotate } from "@fortawesome/pro-solid-svg-icons";

import 'devextreme/data/odata/store';
import CustomStore from 'devextreme/data/custom_store';

import '../ListStyle.css';

import dxDateBox from "devextreme/ui/date_box";

import dxNumberBox from "devextreme/ui/number_box";

import TransactionStatus from "../TransactionStatus/TransactionStatus.js";

import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
import { exportDataGrid } from 'devextreme/excel_exporter'

dxDateBox.defaultOptions({
    options: {
        dateSerializationFormat: "yyyy-MM-dd"
    }
});

dxNumberBox.defaultOptions({
    options: {
        step: 0
    }
});

function isNotEmpty(value) {
    return value !== undefined && value !== null && value !== '';
}

export default function MoveItemWMS(props) {
    const history = useHistory();

    const [isDetail, setIsDetail] = useState(false);
    const [id, setId] = useState();
    const [moveItemWMSPayload, setMoveItemWMSPayload] = useState({
        header: {},
        detail: [],
        movEstatusLog: []
    });

    const [autoExpandAll, setAutoExpandAll] = useState(true);

    const [visibleLoadPanel, setVisibleLoadPanel] = useState(false);

    const [visibleToast, setVisibleToast] = useState(false);
    const [messageToast, setMessageToast] = useState('');
    const [typeToast, setTypeToast] = useState('success');

    const [activateUpdateForm, setActivateUpdateForm] = useState(false);
    const [activateUpdateGrid, setActivateUpdateGrid] = useState(false);
    const [activatePDFButton, setActivatePDFButton] = useState(false);

    //Ref para el form
    const [formDatosGeneralesRef, setFormDatosGeneralesRef] = useState(React.createRef());

    //Ref para el grid Items
    const [gridItemsRef, setGridItemsRef] = useState(React.createRef());

    const filterBuilderPopupPosition = {
        of: window,
        at: 'top',
        my: 'top',
        offset: { y: 10 },
    };

    const allowedPageSizes = [5, 10, 15, 20, 'all'];


    function getOrderDay(rowData) {
        return (new Date(rowData.OrderDate)).getDay();
    }

    const filterBuilder = {
        customOperations: [{
            name: 'weekends',
            caption: 'Weekends',
            dataTypes: ['date'],
            icon: 'check',
            hasValue: false,
            calculateFilterExpression: () => [[getOrderDay, '=', 0], 'or', [getOrderDay, '=', 6]],
        }],
        allowHierarchicalFields: true,
    };

    const filterValue = [['Estatus', '=', 'Concluido']];

    //Ref para el grid Tablero de Control
    const [gridTableroRef, setGridTableroRef] = useState(React.createRef());

    function onAutoExpandAllChanged() {
        setAutoExpandAll(!autoExpandAll)
    };

    async function getMoveItemWMSRecords(sqlFilter) {
        try {
            let apiName = 'AdminSC';
            let path = '/procesaacomodowms';

            let myInit = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
                },
                queryStringParameters: {
                    action: 'list',
                    sqlFilter: sqlFilter,
                    salesAccess: props.salesAccess,
                    sucursal: props.sucursal,
                    usuario: props.usuario,
                    username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username
                }
            }

            var moveItemWMSGet = await API.get(apiName, path, myInit);
        }
        catch (e) {
            if (e === 'No current user') {
                history.push('/');

                window.location.reload();
            }
        }

        return moveItemWMSGet;
    }

    const store = new CustomStore({
        key: 'ID',
        load(loadOptions) {
            let sqlFilter = '(';
            [
                'filter'
            ].forEach((i) => {
                if (i in loadOptions && isNotEmpty(loadOptions[i])) {
                    var filters = loadOptions[i];

                    var filtersLength = filters.length;

                    for (var i = 0; i <= filtersLength; i++) {
                        var singleFilter = filters[i];
                        var singleFilterType = typeof singleFilter;
                        var singleFilterIsArray = Array.isArray(singleFilter);
                        var singleFilterIsDate = singleFilter instanceof Date;

                        if (singleFilter === 'NULL') {
                            singleFilter = '0';
                        }

                        if (singleFilterType.toString() === 'object' && singleFilterIsArray) {
                            sqlFilter += '(';

                            var subFiltersLength = singleFilter.length;

                            for (var i2 = 0; i2 <= subFiltersLength; i2++) {
                                var singleSubFilter = singleFilter[i2];
                                var singleSubFilterType = typeof singleSubFilter;
                                var singleSubFilterIsArray = Array.isArray(singleSubFilter);
                                var singleSubFilterIsDate = singleSubFilter instanceof Date

                                if (singleSubFilter === 'NULL') {
                                    singleSubFilter = '0';
                                }

                                if (singleSubFilterType.toString() === 'object' && singleSubFilterIsArray) {
                                    sqlFilter += '(';

                                    var subSubFiltersLength = singleSubFilter.length;

                                    for (var i3 = 0; i3 <= subSubFiltersLength; i3++) {
                                        var singleSubSubFilter = singleSubFilter[i3];
                                        var singleSubSubFilterType = typeof singleSubSubFilter;
                                        var singleSubSubFilterIsArray = Array.isArray(singleSubSubFilter);
                                        var singleSubSubFilterIsDate = singleSubSubFilter instanceof Date

                                        if (singleSubSubFilter === 'NULL') {
                                            singleSubSubFilter = '0';
                                        }

                                        if (singleSubSubFilterType.toString() === 'object' && singleSubSubFilterIsArray) {
                                            sqlFilter += '(';

                                            var subSubSubFiltersLength = singleSubSubFilter.length;

                                            for (var i4 = 0; i4 <= subSubSubFiltersLength; i4++) {
                                                var singleSubSubSubFilter = singleSubSubFilter[i4];
                                                var singleSubSubSubFilterType = typeof singleSubSubSubFilter;
                                                var singleSubSubSubFilterIsArray = Array.isArray(singleSubSubSubFilter);
                                                var singleSubSubSubFilterIsDate = singleSubSubSubFilter instanceof Date

                                                if (singleSubSubSubFilter === 'NULL') {
                                                    singleSubSubSubFilter = '0';
                                                }

                                                if (singleSubSubSubFilterType.toString() === 'object' && singleSubSubSubFilterIsArray) {
                                                    sqlFilter += '(';

                                                    var subSubSubSubFiltersLength = singleSubSubSubFilter.length;

                                                    for (var i5 = 0; i5 <= subSubSubSubFiltersLength; i5++) {
                                                        var singleSubSubSubSubFilter = singleSubSubSubFilter[i5];
                                                        var singleSubSubSubSubFilterType = typeof singleSubSubSubSubFilter;
                                                        var singleSubSubSubSubFilterIsArray = Array.isArray(singleSubSubSubSubFilter);
                                                        var singleSubSubSubSubFilterIsDate = singleSubSubSubSubFilter instanceof Date

                                                        if (singleSubSubSubSubFilter === 'NULL') {
                                                            singleSubSubSubSubFilter = '0';
                                                        }

                                                        if (singleSubSubSubSubFilterType.toString() === 'string') {
                                                            if (singleSubSubSubSubFilter === 'contains') {
                                                                filters[i][i2][i3][i4][i5] = 'LIKE';
                                                                singleSubSubSubSubFilter = 'LIKE';

                                                                filters[i][i2][i3][i4][i5 + 1] = '\'%' + filters[i][i2][i3][i4][i5 + 1] + '%\'';
                                                            }
                                                            else if (singleSubSubSubSubFilter === 'notcontains') {
                                                                filters[i][i2][i3][i4][i5] = 'NOT LIKE';
                                                                singleSubSubSubSubFilter = 'NOT LIKE';

                                                                filters[i][i2][i3][i4][i5 + 1] = '\'%' + filters[i][i2][i3][i4][i5 + 1] + '%\'';
                                                            }
                                                            else if (singleSubSubSubSubFilter === 'startswith') {
                                                                filters[i][i2][i3][i4][i5] = 'LIKE';
                                                                singleSubSubSubSubFilter = 'LIKE';

                                                                filters[i][i2][i3][i4][i5 + 1] = '\'' + filters[i][i2][i3][i4][i5 + 1] + '%\'';
                                                            }
                                                            else if (singleSubSubSubSubFilter === 'endswith') {
                                                                filters[i][i2][i3][i4][i5] = 'LIKE';
                                                                singleSubSubSubSubFilter = 'LIKE';

                                                                filters[i][i2][i3][i4][i5 + 1] = '\'%' + filters[i][i2][i3][i4][i5 + 1] + '\'';
                                                            }
                                                            else if (singleSubSubSubSubFilter === '=' || singleSubSubSubSubFilter === '<>' || singleSubSubSubSubFilter === '>' || singleSubSubSubSubFilter === '>=' || singleSubSubSubSubFilter === '<' || singleSubSubSubSubFilter === '<=') {
                                                                if (!filters[i][i2][i3][i4][i5 + 1])
                                                                    filters[i][i2][i3][i4][i5 + 1] = 'NULL';
                                                                else if (typeof filters[i][i2][i3][i4][i5 + 1] === 'string') {
                                                                    filters[i][i2][i3][i4][i5 + 1] = '\'' + filters[i][i2][i3][i4][i5 + 1] + '\'';
                                                                }
                                                            }
                                                            else if (singleSubSubSubSubFilter === '!') {
                                                                filters[i][i2][i3][i4][i5] = 'NOT';
                                                                singleSubSubSubSubFilter = 'NOT';
                                                            }

                                                            sqlFilter += ' ' + singleSubSubSubSubFilter + ' ';
                                                        }
                                                        else if (singleSubSubSubSubFilterType.toString() === 'object' && singleSubSubSubSubFilterIsDate) {
                                                            sqlFilter += ' \'' + singleSubSubSubSubFilter.toISOString().split('T')[0] + '\' ';
                                                        }
                                                        else if (singleSubSubSubSubFilterType.toString() === 'number') {
                                                            sqlFilter += ' ' + singleSubSubSubSubFilter.toString() + ' ';
                                                        }
                                                        else if (singleSubSubSubSubFilterType.toString() === 'boolean') {
                                                            if (singleSubSubSubSubFilter.toString() === 'true')
                                                                sqlFilter += '1 ';
                                                            else if (singleSubSubSubSubFilter.toString() === 'false' || singleSubSubSubSubFilter === null || singleSubSubSubSubFilter === undefined)
                                                                sqlFilter += '0 ';
                                                        }
                                                    }
                                                }
                                                else if (singleSubSubSubFilterType.toString() === 'string') {
                                                    if (singleSubSubSubFilter === 'contains') {
                                                        filters[i][i2][i3][i4] = 'LIKE';
                                                        singleSubSubSubFilter = 'LIKE';

                                                        filters[i][i2][i3][i4 + 1] = '\'%' + filters[i][i2][i3][i4 + 1] + '%\'';
                                                    }
                                                    else if (singleSubSubSubFilter === 'notcontains') {
                                                        filters[i][i2][i3][i4] = 'NOT LIKE';
                                                        singleSubSubSubFilter = 'NOT LIKE';

                                                        filters[i][i2][i3][i4 + 1] = '\'%' + filters[i][i2][i3][i4 + 1] + '%\'';
                                                    }
                                                    else if (singleSubSubSubFilter === 'startswith') {
                                                        filters[i][i2][i3][i4] = 'LIKE';
                                                        singleSubSubSubFilter = 'LIKE';

                                                        filters[i][i2][i3][i4 + 1] = '\'' + filters[i][i2][i3][i4 + 1] + '%\'';
                                                    }
                                                    else if (singleSubSubSubFilter === 'endswith') {
                                                        filters[i][i2][i3][i4] = 'LIKE';
                                                        singleSubSubSubFilter = 'LIKE';

                                                        filters[i][i2][i3][i4 + 1] = '\'%' + filters[i][i2][i3][i4 + 1] + '\'';
                                                    }
                                                    else if (singleSubSubSubFilter === '=' || singleSubSubSubFilter === '<>' || singleSubSubSubFilter === '>' || singleSubSubSubFilter === '>=' || singleSubSubSubFilter === '<' || singleSubSubSubFilter === '<=') {
                                                        if (!filters[i][i2][i3][i4 + 1])
                                                            filters[i][i2][i3][i4 + 1] = 'NULL';
                                                        else if (typeof filters[i][i2][i3][i4 + 1] === 'string') {
                                                            filters[i][i2][i3][i4 + 1] = '\'' + filters[i][i2][i3][i4 + 1] + '\'';
                                                        }
                                                    }
                                                    else if (singleSubSubSubFilter === '!') {
                                                        filters[i][i2][i3][i4] = 'NOT';
                                                        singleSubSubSubFilter = 'NOT';
                                                    }

                                                    sqlFilter += ' ' + singleSubSubSubFilter + ' ';
                                                }
                                                else if (singleSubSubSubFilterType.toString() === 'object' && singleSubSubSubFilterIsDate) {
                                                    sqlFilter += ' \'' + singleSubSubSubFilter.toISOString().split('T')[0] + '\' ';
                                                }
                                                else if (singleSubSubSubFilterType.toString() === 'number') {
                                                    sqlFilter += ' ' + singleSubSubSubFilter.toString() + ' ';
                                                }
                                                else if (singleSubSubSubFilterType.toString() === 'boolean') {
                                                    if (singleSubSubSubFilter.toString() === 'true')
                                                        sqlFilter += '1 ';
                                                    else if (singleSubSubSubFilter.toString() === 'false' || singleSubSubSubFilter === null || singleSubSubSubFilter === undefined)
                                                        sqlFilter += '0 ';
                                                }
                                            }

                                            sqlFilter += ')';
                                        }
                                        else if (singleSubSubFilterType.toString() === 'string') {
                                            if (singleSubSubFilter === 'contains') {
                                                filters[i][i2][i3] = 'LIKE';
                                                singleSubSubFilter = 'LIKE';

                                                filters[i][i2][i3 + 1] = '\'%' + filters[i][i2][i3 + 1] + '%\'';
                                            }
                                            else if (singleSubSubFilter === 'notcontains') {
                                                filters[i][i2][i3] = 'NOT LIKE';
                                                singleSubSubFilter = 'NOT LIKE';

                                                filters[i][i2][i3 + 1] = '\'%' + filters[i][i2][i3 + 1] + '%\'';
                                            }
                                            else if (singleSubSubFilter === 'startswith') {
                                                filters[i][i2][i3] = 'LIKE';
                                                singleSubSubFilter = 'LIKE';

                                                filters[i][i2][i3 + 1] = '\'' + filters[i][i2][i3 + 1] + '%\'';
                                            }
                                            else if (singleSubSubFilter === 'endswith') {
                                                filters[i][i2][i3] = 'LIKE';
                                                singleSubSubFilter = 'LIKE';

                                                filters[i][i2][i3 + 1] = '\'%' + filters[i][i2][i3 + 1] + '\'';
                                            }
                                            else if (singleSubSubFilter === '=' || singleSubSubFilter === '<>' || singleSubSubFilter === '>' || singleSubSubFilter === '>=' || singleSubSubFilter === '<' || singleSubSubFilter === '<=') {
                                                if (!filters[i][i2][i3 + 1])
                                                    filters[i][i2][i3 + 1] = 'NULL';
                                                else if (typeof filters[i][i2][i3 + 1] === 'string') {
                                                    filters[i][i2][i3 + 1] = '\'' + filters[i][i2][i3 + 1] + '\'';
                                                }
                                            }
                                            else if (singleSubSubFilter === '!') {
                                                filters[i][i2][i3] = 'NOT';
                                                singleSubSubFilter = 'NOT';
                                            }
                                            sqlFilter += ' ' + singleSubSubFilter + ' ';
                                        }
                                        else if (singleSubSubFilterType.toString() === 'object' && singleSubSubFilterIsDate) {
                                            sqlFilter += ' \'' + singleSubSubFilter.toISOString().split('T')[0] + '\' ';
                                        }
                                        else if (singleSubSubFilterType.toString() === 'number') {
                                            sqlFilter += ' ' + singleSubSubFilter.toString() + ' ';
                                        }
                                        else if (singleSubSubFilterType.toString() === 'boolean') {
                                            if (singleSubSubFilter.toString() === 'true')
                                                sqlFilter += '1 ';
                                            else if (singleSubSubFilter.toString() === 'false' || singleSubSubFilter === null || singleSubSubFilter === undefined)
                                                sqlFilter += '0 ';
                                        }
                                    }

                                    sqlFilter += ')';
                                }
                                else if (singleSubFilterType.toString() === 'string') {
                                    if (singleSubFilter === 'contains') {
                                        filters[i][i2] = 'LIKE';
                                        singleSubFilter = 'LIKE';

                                        filters[i][i2 + 1] = '\'%' + filters[i][i2 + 1] + '%\'';
                                    }
                                    else if (singleSubFilter === 'notcontains') {
                                        filters[i][i2] = 'NOT LIKE';
                                        singleSubFilter = 'NOT LIKE';

                                        filters[i][i2 + 1] = '\'%' + filters[i][i2 + 1] + '%\'';
                                    }
                                    else if (singleSubFilter === 'startswith') {
                                        filters[i][i2] = 'LIKE';
                                        singleSubFilter = 'LIKE';

                                        filters[i][i2 + 1] = '\'' + filters[i][i2 + 1] + '%\'';
                                    }
                                    else if (singleSubFilter === 'endswith') {
                                        filters[i][i2] = 'LIKE';
                                        singleSubFilter = 'LIKE';

                                        filters[i][i2 + 1] = '\'%' + filters[i][i2 + 1] + '\'';
                                    }
                                    else if (singleSubFilter === '=' || singleSubFilter === '<>' || singleSubFilter === '>' || singleSubFilter === '>=' || singleSubFilter === '<' || singleSubFilter === '<=') {
                                        if (!filters[i][i2 + 1])
                                            filters[i][i2 + 1] = 'NULL';
                                        else if (typeof filters[i][i2 + 1] === 'string') {
                                            filters[i][i2 + 1] = '\'' + filters[i][i2 + 1] + '\'';
                                        }
                                    }
                                    else if (singleSubFilter === '!') {
                                        filters[i][i2] = 'NOT';
                                        singleSubFilter = 'NOT';
                                    }
                                    sqlFilter += ' ' + singleSubFilter + ' ';
                                }
                                else if (singleSubFilterType.toString() === 'object' && singleSubFilterIsDate) {
                                    sqlFilter += ' \'' + singleSubFilter.toISOString().split('T')[0] + '\' ';
                                }
                                else if (singleSubFilterType.toString() === 'number') {
                                    sqlFilter += ' ' + singleSubFilter.toString() + ' ';
                                }
                                else if (singleSubFilterType.toString() === 'boolean') {
                                    if (singleSubFilter.toString() === 'true')
                                        sqlFilter += '1 ';
                                    else if (singleSubFilter.toString() === 'false' || singleSubFilter === null || singleSubFilter === undefined)
                                        sqlFilter += '0 ';
                                }
                            }

                            sqlFilter += ')';
                        }
                        else if (singleFilterType.toString() === 'string') {
                            if (singleFilter === 'contains') {
                                filters[i] = 'LIKE';
                                singleFilter = 'LIKE';

                                filters[i + 1] = '\'%' + filters[i + 1] + '%\'';
                            }
                            else if (singleFilter === 'notcontains') {
                                filters[i] = 'NOT LIKE';
                                singleFilter = 'NOT LIKE';

                                filters[i + 1] = '\'%' + filters[i + 1] + '%\'';
                            }
                            else if (singleFilter === 'startswith') {
                                filters[i] = 'LIKE';
                                singleFilter = 'LIKE';

                                filters[i + 1] = '\'' + filters[i + 1] + '%\'';
                            }
                            else if (singleFilter === 'endswith') {
                                filters[i] = 'LIKE';
                                singleFilter = 'LIKE';

                                filters[i + 1] = '\'%' + filters[i + 1] + '\'';
                            }
                            else if (singleFilter === '=' || singleFilter === '<>' || singleFilter === '>' || singleFilter === '>=' || singleFilter === '<' || singleFilter === '<=') {
                                if (!filters[i + 1])
                                    filters[i + 1] = 'NULL';
                                else if (typeof filters[i + 1] === 'string') {
                                    filters[i + 1] = '\'' + filters[i + 1] + '\'';
                                }
                            }
                            else if (singleFilter === '!') {
                                filters[i] = 'NOT';
                                singleFilter = 'NOT';
                            }
                            sqlFilter += ' ' + singleFilter + ' ';
                        }
                        else if (singleFilterType.toString() === 'object' && singleFilterIsDate) {
                            sqlFilter += ' \'' + singleFilter.toISOString().split('T')[0] + '\' ';
                        }
                        else if (singleFilterType.toString() === 'number') {
                            sqlFilter += ' ' + singleFilter.toString() + ' ';
                        }
                        else if (singleFilterType.toString() === 'boolean') {
                            if (singleFilter.toString() === 'true')
                                sqlFilter += '1 ';
                            else if (singleFilter.toString() === 'false' || singleFilter === null || singleFilter === undefined)
                                sqlFilter += '0 ';
                        }
                    }

                    sqlFilter += ')';
                }
            });

            async function getRecords() {
                if (sqlFilter === '(')
                    sqlFilter = '';

                var records = await getMoveItemWMSRecords(sqlFilter);
                return {
                    data: records,
                    totalCount: records.length
                };
            }

            return getRecords()
                .then(function (result) {
                    return result;
                })
                .catch(() => { throw new Error('Data Loading Error'); });
        },
    });

    var fechaEmisionEditorOptions = useMemo(() => {
        return {
            width: '100%',
            readOnly: true
        };
    }, []);

    var nombreAlmacenEditorOptions = useMemo(() => {
        return {
            width: '100%',
            readOnly: true
        };
    }, []);

    var referenciaEditorOptions = useMemo(() => {
        return {
            width: '100%',
            readOnly: true
        };
    }, []);

    var movimientoEditorOptions = useMemo(() => {
        return {
            width: '100%',
            readOnly: true
        };
    }, []);


    async function getMoveItemWMSPayload(ID) {
        try {
            let apiName = 'AdminSC';
            let path = '/procesaacomodowms';

            let myInit = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
                },
                queryStringParameters: {
                    ID: ID,
                    action: 'payload',
                    username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username
                }
            }

            var payloadGet = await API.get(apiName, path, myInit);

            setMoveItemWMSPayload(payloadGet);
        }
        catch (e) {
            if (e === 'No current user') {
                history.push('/');

                window.location.reload();
            }
        }

        return payloadGet;
    }

    function editReceiveOrderWMS(data) {
        return (
            <Link to={`/MoveItemWMS/${data.data.ID}`}>
                <button class="bg-blue-500 hover:bg-blue-700 text-white  py-1 px-3 rounded" onClick={() => editButton(data.data.ID)}><FontAwesomeIcon icon={faGlasses} /> Ver</button>
            </Link>
        )
    }

    async function editButton(ID) {
        try {
            if (!window.event.metaKey && !window.event.ctrlKey) {
                setVisibleLoadPanel(true);

                setIsDetail(true);
                setId(ID);

                var payload = await getMoveItemWMSPayload(ID);

                setActivateUpdateForm(false);
                setActivateUpdateGrid(false);
                setActivatePDFButton(true);

                setVisibleLoadPanel(false);
            }
        }
        catch (e) {
            setVisibleLoadPanel(false);
        }
    };

    async function cancelEditButton() {
        setIsDetail(false);
    };

    function refreshButton() {
        if (gridTableroRef.current) {
            var gridTablero = gridTableroRef.current.instance;

            gridTablero.refresh();
        }
    }

    function printButton() {
        var pdfArray = [];
        var baseUrl = window.location.href.split('/')[0];

        for (var detailIndex in moveItemWMSPayload.detail) {
            pdfArray.push(parseInt(moveItemWMSPayload.detail[detailIndex].Barcode));
        }

        pdfArray = pdfArray.join('%2C')

        window.open(baseUrl + "/#/PdfRender?type=moveitem&id=" + pdfArray, "_blank");
    };

    useEffect(() => {
        window.addEventListener('popstate', (e) => {
            var hash;

            try {
                hash = e.target.location.hash;
            }
            catch (e) {
                hash = '';
            }

            if (hash === '#/MoveItemWMS') {
                history.push('/MoveItemWMS');

                window.location.reload();
            }
        });

        async function initialize() {
            setVisibleLoadPanel(true);

            setVisibleLoadPanel(false);
        }

        initialize();

        //En caso de solicitar un ID especifico, redireccionamos a ese ID
        if (!props.match.isExact) {
            var urlID = props.location.pathname.replace('/MoveItemWMS/', '');

            if (urlID === 'undefined')
                urlID = undefined;

            if (urlID)
                editButton(urlID);
        }

        // eslint-disable-next-line
    }, []);

    function onHidingToast() {
        setVisibleToast(false);
    }

    function onExporting(e) {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Datos');

        exportDataGrid({
            component: e.component,
            worksheet,
            autoFilterEnabled: true,
        }).then(() => {
            workbook.xlsx.writeBuffer().then((buffer) => {
                saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Datos.xlsx');
            });
        });
        e.cancel = true;
    };

    function estatusRelatedRecords(data) {
        return (
            <TransactionStatus {...props} Estatus={data.data.Estatus} />
        )
    };

    return (
        <div>
            {isDetail === false ? (
                <div class="bg-gray-200 m-2">
                    <h1 class="font-sans inline-block text-xl font-bold text-gray-700 leading-8 align-top mb-1"><FontAwesomeIcon icon={faList} size="lg" /> Acomodos</h1>
                </div>
            ) :
                (
                    <div class="bg-gray-200 m-2">
                        <h1 class="font-sans inline-block text-xl font-bold text-gray-700 leading-8 align-top mb-1"><FontAwesomeIcon icon={faCartFlatbedSuitcase} size="xl" /> {(moveItemWMSPayload.header ? moveItemWMSPayload.header.Movimiento : 'Acomodo')}</h1>
                        <TransactionStatus {...props} Estatus={moveItemWMSPayload.header.Estatus} />
                    </div>
                )}

            <LoadPanel
                shadingColor="rgba(0,0,0,0.4)"
                visible={visibleLoadPanel}
                showIndicator={true}
                message="Por favor espere..."
                shading={true}
                showPane={true}
                hideOnOutsideClick={false}
            />

            <Toast
                visible={visibleToast}
                message={messageToast}
                type={typeToast}
                onHiding={onHidingToast}
                displayTime={5000}
                width={'auto'}
                position={{
                    my: 'top center',
                    at: 'top center',
                    of: window,
                    offset: '0 130'
                }}
            />

            <div class="bg-gray-200 m-2">
                {isDetail === false ? (
                    <div class="bg-white p-3 shadow-xl">

                        <div>
                            <button onClick={refreshButton} type="submit" class="mb-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                                <FontAwesomeIcon icon={faArrowsRotate} /> Actualizar
                            </button>
                        </div>

                        <div className="options mb-2">
                            <div className="option">
                                <CheckBox text="Expandir todos los grupos"
                                    value={autoExpandAll}
                                    onValueChanged={onAutoExpandAllChanged}></CheckBox>
                            </div>
                        </div>
                        <DataGrid
                            height={800}
                            id="gridContainer"
                            columnsAutoWidth={true}
                            filterBuilder={filterBuilder}
                            dataSource={store}
                            defaultFilterValue={filterValue}
                            allowColumnReordering={true}
                            allowColumnResizing={true}
                            columnResizingMode={"widget"}
                            showBorders={true}
                            rowAlternationEnabled={true}
                            showRowLines={true}
                            showColumnLines={true}
                            remoteOperations={{ filtering: true }}
                            ref={gridTableroRef}
                            onExporting={onExporting}
                        >
                            <Scrolling rowRenderingMode='virtual'></Scrolling>
                            <Paging defaultPageSize={10} />
                            <Pager
                                visible={true}
                                allowedPageSizes={allowedPageSizes}
                                displayMode='compact'
                                showPageSizeSelector={true}
                                showInfo={true}
                                showNavigationButtons={true}
                            />
                            <FilterRow visible={true} />
                            <FilterPanel visible={true} />
                            <FilterBuilderPopup position={filterBuilderPopupPosition} />

                            <HeaderFilter visible={true} />

                            <GroupPanel visible={true} />
                            <SearchPanel visible={true} />
                            <ColumnChooser
                                enabled={true}
                                mode='select'
                            >
                                <ColumnChooserSearch
                                    enabled={true}
                                />

                                <ColumnChooserSelection
                                    allowSelectAll={true}
                                    selectByClick={true}
                                    recursive={true}
                                />
                            </ColumnChooser>
                            <Grouping autoExpandAll={autoExpandAll} />
                            <StateStoring enabled={true} type="localStorage" storageKey="gridContainerMoveItemWMS" />
                            <Export enabled={true} />

                            <Column caption="" alignment="center" width={100} cellRender={editReceiveOrderWMS} />
                            <Column dataField="MovID" dataType="string" width={110} caption="Folio" />
                            <Column dataField="FechaEmision" caption="Fecha Emisión" dataType="date" width={130} />
                            <Column dataField="Estatus" dataType="string" width={120} />
                            <Column dataField="Almacen" caption="Almacén" dataType="number" width={150} />

                            <Column caption="Plaza" dataField="Plaza" dataType="string" width={150} visible={false} />

                            <Column dataField="ID" dataType="number" width={150} visible={false} />
                            <Column dataField="Mov" dataType="string" width={190} caption="Tipo Movimiento" visible={false} />
                            <Column dataField="Movimiento" dataType="string" width={190} visible={false} />

                            <Column dataField="Sucursal" dataType="number" width={150} visible={false} />
                            <Column dataField="NombreSucursal" dataType="string" width={190} visible={false} />

                            <Column dataField="NombreAlmacen" caption="Nombre Almacén" dataType="string" width={190} visible={false} />

                            <Column dataField="Usuario" dataType="string" width={190} visible={false} />
                            <Column dataField="NombreUsuario" dataType="string" width={190} visible={false} />
                        </DataGrid>
                    </div>
                ) : (<div />)}
                <Route exact path="/MoveItemWMS/:ID" render={({ match }) => {
                    return (
                        <div class="bg-gray-200">
                            {isDetail.toString() === 'true' ?
                                (
                                    <div>
                                        <Link to="/MoveItemWMS">
                                            <button onClick={cancelEditButton} type="submit" class="mb-4 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
                                                <FontAwesomeIcon icon={faArrowRotateLeft} /> Regresar
                                            </button>
                                        </Link>


                                        {activatePDFButton.toString() === 'true' && id ?
                                            (
                                                <button onClick={printButton} type="submit" class="ml-4 mb-4 bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded">
                                                    <FontAwesomeIcon icon={faFilePdf} /> PDF
                                                </button>
                                            ) :
                                            (
                                                <div class="inline-block"></div>
                                            )}

                                        <div class="bg-white p-4 shadow-xl">
                                            <Form
                                                id="formTransaccion"
                                                labelMode={'outside'}
                                                formData={moveItemWMSPayload.header}
                                                readOnly={!activateUpdateForm}
                                                showColonAfterLabel={false}
                                                labelLocation={'top'}
                                                ref={formDatosGeneralesRef}
                                            >
                                                <TabbedItem>
                                                    <TabPanelOptions deferRendering={false} showNavButtons={true} />
                                                    <Tab title="Datos Generales" colCount={6}>
                                                        <GroupItem caption="General" colSpan={6} colCount={2}>
                                                            <Item cssClass="boldText" dataField="Movimiento" editorOptions={movimientoEditorOptions} >
                                                                <RequiredRule message="Movimiento es requerido" />
                                                            </Item>
                                                            <Item dataField="FechaEmision" editorType="dxDateBox" editorOptions={fechaEmisionEditorOptions}>
                                                                <Label text={"Fecha Emisión"} />
                                                                <RequiredRule message="Fecha Emisión es requerida" />
                                                            </Item>
                                                            <Item dataField="NombreAlmacen" editorOptions={nombreAlmacenEditorOptions}>
                                                                <Label text={"Almacén"} />
                                                                <RequiredRule message="Almacén es requerido" />
                                                            </Item>
                                                        </GroupItem>
                                                        <GroupItem caption="Artículos" colSpan={6} >
                                                            <DataGrid
                                                                id="gridItems"
                                                                ref={gridItemsRef}
                                                                columnsAutoWidth={true}
                                                                dataSource={moveItemWMSPayload.detail}
                                                                showBorders={true}
                                                                showColumnLines={true}
                                                                showRowLines={true}
                                                                rowAlternationEnabled={true}
                                                                allowColumnResizing={true}
                                                                columnResizingMode="widget"
                                                                allowColumnReordering={false}
                                                                repaintChangesOnly={false}
                                                            >
                                                                <Editing
                                                                    mode="cell"
                                                                    allowUpdating={activateUpdateGrid}
                                                                    allowAdding={false}
                                                                    allowDeleting={false}
                                                                />
                                                                <Sorting mode="none" />

                                                                <Column dataField="RenglonID" width={190} visible={false} allowEditing={false} />

                                                                <Column dataField="ArtDescripcion" caption="Artículo" width={340} allowEditing={false} dataType="string" />
                                                                <Column dataField="Barcode" caption="Barcode" width={120} allowEditing={false} dataType="string" />
                                                                <Column dataField="BarcodeNuevo" caption="Barcode Nuevo" width={120} allowEditing={false} dataType="string" />
                                                                <Column dataField="CantidadOriginal" caption="Cantidad Original" width={140} allowEditing={false} dataType="number" format=',###' />
                                                                <Column dataField="Cantidad" caption="Cantidad" width={80} allowEditing={false} dataType="number" format=',###' />
                                                                <Column dataField="Posicion" caption="Posición Origen" width={130} allowEditing={false} dataType="string" />
                                                                <Column dataField="PosicionDestino" caption="Posición Destino" width={130} allowEditing={false} dataType="string" />
                                                            </DataGrid>
                                                        </GroupItem>
                                                    </Tab>
                                                    <Tab title="Observaciones" >
                                                        <Item dataField="Observaciones" editorType="dxTextArea" >
                                                            <Label text="Observaciones" visible={false} />
                                                        </Item>
                                                    </Tab>
                                                    <Tab title="Mensajes WMS">
                                                        <DataGrid
                                                            id="gridMensajesWMS"
                                                            columnsAutoWidth={true}
                                                            dataSource={moveItemWMSPayload.wmsmessage}
                                                            allowColumnReordering={true}
                                                            allowColumnResizing={true}
                                                            columnResizingMode={"widget"}
                                                            showBorders={true}
                                                            rowAlternationEnabled={true}
                                                            showRowLines={true}
                                                            showColumnLines={true}
                                                        >
                                                            <Scrolling rowRenderingMode='virtual'></Scrolling>
                                                            <Paging defaultPageSize={10} />
                                                            <Pager
                                                                visible={true}
                                                                allowedPageSizes={allowedPageSizes}
                                                                displayMode='compact'
                                                                showPageSizeSelector={true}
                                                                showInfo={true}
                                                                showNavigationButtons={true}
                                                            />

                                                            <FilterRow visible={true} />
                                                            <FilterPanel visible={true} />
                                                            <FilterBuilderPopup position={filterBuilderPopupPosition} />
                                                            
                                                            <HeaderFilter visible={true} />

                                                            <GroupPanel visible={true} />
                                                            <SearchPanel visible={true} />
                                                            <ColumnChooser
                                                                enabled={true}
                                                                mode='select'
                                                            >
                                                                <ColumnChooserSearch
                                                                    enabled={true}
                                                                />

                                                                <ColumnChooserSelection
                                                                    allowSelectAll={true}
                                                                    selectByClick={true}
                                                                    recursive={true}
                                                                />
                                                            </ColumnChooser>
                                                            <Grouping autoExpandAll={autoExpandAll} />

                                                            <Column dataField="Mensaje" dataType="string" width={120} caption="Mensaje" />
                                                            <Column dataField="Barcode" dataType="string" width={140} caption="Barcode" />
                                                            <Column dataField="BarcodeNuevo" dataType="string" width={140} caption="Nuevo Barcode" />
                                                            <Column dataField="Articulo" dataType="string" width={120} caption="Artículo" />
                                                            <Column dataField="CantidadOriginal" dataType="number" width={150} caption="Cantidad Original" format=',###' />
                                                            <Column dataField="Cantidad" dataType="number" width={120} caption="Cantidad" format=',###' />
                                                            <Column dataField="username" dataType="string" width={140} caption="Usuario WMS" />
                                                            <Column dataField="Fecha" dataType="date" width={100} caption="Fecha" />
                                                            <Column dataField="Almacen" dataType="string" width={120} caption="Almacén" />
                                                            <Column dataField="Posicion" dataType="string" width={120} caption="Posición" />
                                                            <Column dataField="PosicionDestino" dataType="string" width={140} caption="Posición Destino" />
                                                        </DataGrid>
                                                    </Tab>
                                                    <Tab title="Log Estatus">
                                                        <DataGrid
                                                            id="gridLogEstatus"
                                                            columnsAutoWidth={true}
                                                            dataSource={moveItemWMSPayload.movEstatusLog}
                                                            allowColumnReordering={true}
                                                            allowColumnResizing={true}
                                                            columnResizingMode={"widget"}
                                                            showBorders={true}
                                                            rowAlternationEnabled={true}
                                                            showRowLines={true}
                                                            showColumnLines={true}
                                                        >
                                                            <HeaderFilter visible={false} />
                                                            <Column dataField="Usuario" dataType="string" width={350} caption="Usuario" />
                                                            <Column caption="Estatus" alignment="center" width={150} cellRender={estatusRelatedRecords} />
                                                            <Column dataField="Fecha" dataType="datetime" width={300} caption="Fecha" />
                                                        </DataGrid>
                                                    </Tab>
                                                </TabbedItem>
                                            </Form>
                                        </div>
                                    </div>
                                ) :
                                (
                                    <div></div>
                                )
                            }
                        </div>
                    )
                }}
                />
            </div>
        </div>
    );
}
