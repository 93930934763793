import React, { useState, useEffect, useRef } from 'react';

import { Auth, API, Storage } from 'aws-amplify';

import { Stimulsoft } from 'stimulsoft-reports-js/Scripts/stimulsoft.blockly.editor';
import 'stimulsoft-reports-js/Css/stimulsoft.designer.office2013.whiteblue.css';
import 'stimulsoft-reports-js/Css/stimulsoft.viewer.office2013.whiteblue.css';
import './App.css';

export default function PdfEditor(props) {
  useEffect(() => {
    async function initialize() {
      //Obtenemos los parámetros
      try {
        var type = window.location.href.split('?')[1].split('&')[0].split('=')[1].toLowerCase();

        // Primero obtenemos el template
        let apiNameTemplate = 'AdminSC';
        let pathTemplate = '/procesapdftemplate';

        let myInitTemplate = {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
          },
          queryStringParameters: {
            Tipo: type,
            action: 'payload',
            username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username
          }
        }

        var templateGet = await API.get(apiNameTemplate, pathTemplate, myInitTemplate);

        var plantilla = templateGet.header.Plantilla;

        // Obtenemos la licencia
        let apiNameLicense = 'AdminSC';
        let pathLicense = '/getStimulsoftLicense';

        let myInitLicense = {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
          },
          queryStringParameters: {
          }
        }
        
        var stiLicense = await API.post(apiNameLicense, pathLicense, myInitLicense);

        Stimulsoft.Base.StiLicense.key = stiLicense[0].Value

        var options = new Stimulsoft.Designer.StiDesignerOptions();
        options.appearance.fullScreenMode = true;
        options.appearance.showSaveDialog = false;

        options.toolbar.showFileMenuNew = false;
        options.toolbar.showFileMenuNewReport = false;
        options.toolbar.showFileMenuOpen = false;
        options.toolbar.showFileMenuSaveAs = false;
        options.toolbar.showAboutButton = false;
        options.toolbar.showFileMenu = false;
        options.toolbar.showFileMenuAbout = false;
        options.toolbar.showFileMenuClose = false;
        options.toolbar.showFileMenuExit = false;
        options.toolbar.showFileMenuInfo = false;
        options.toolbar.showFileMenuNew = false;
        options.toolbar.showFileMenuNewDashboard = false;
        options.toolbar.showFileMenuNewReport = false;
        options.toolbar.showFileMenuOpen = false;
        options.toolbar.showSaveButton = true;

        var designer = new Stimulsoft.Designer.StiDesigner(options, 'StiDesigner', false);
        var report = new Stimulsoft.Report.StiReport();

        report.load(plantilla);

        designer.report = report;

        designer.renderHtml('designer');

        // Assign the onSaveReport event function
        designer.onSaveReport = async function (e) {
          var jsonStr = e.report.saveToJsonString();

          let apiName = 'AdminSC';
          let path = '/procesapdftemplate';

          let myInit = {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
            },
            body: {
              Tipo: type,
              payload: {
                header: {
                  Tipo: type,
                  Plantilla: jsonStr
                }
              },
              action: 'saveChanges',
              username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username
            }
          };

          var payloadPost = await API.post(apiName, path, myInit);

          if (payloadPost.ok === 'true') {
            alert('Se actualizó el template con éxito');
          }
          else if (payloadPost.ok === 'false')
            alert(payloadPost.message);
        }
      }
      catch (e) {
      }
    }

    initialize();

    // eslint-disable-next-line
  }, []);

  return (
    <div id="designer" className="App">

    </div>
  );
};
