import React, { useState, useEffect, useRef, useMemo } from 'react';

// Ruteador
import { Route, Link, useHistory } from "react-router-dom";

// LoadPanel
import { LoadPanel } from 'devextreme-react/load-panel';

import { Auth, API, Storage } from 'aws-amplify';

// Grid
import DataGrid, {
    Column, FilterRow, HeaderFilter, FilterPanel, FilterBuilderPopup,
    GroupPanel, Grouping, SearchPanel, Sorting, Editing, Lookup, Toolbar, Item, RequiredRule,
    Scrolling, Pager, Paging, Summary, TotalItem, StateStoring, ColumnChooser, Format, Selection,
    Export, GroupItem, ColumnChooserSearch, ColumnChooserSelection
} from 'devextreme-react/data-grid';

// Iconos
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsRotate, faBoxOpen, faBoxOpenFull } from "@fortawesome/pro-solid-svg-icons";
import { faFileChartColumn } from "@fortawesome/pro-duotone-svg-icons";

import dxDateBox from "devextreme/ui/date_box";

import Lambda from 'aws-sdk/clients/lambda';

import 'devextreme/data/odata/store';
import CustomStore from 'devextreme/data/custom_store';

import DataSource from 'devextreme/data/data_source';

import '../ListStyle.css';

//CheckBox
import CheckBox from 'devextreme-react/check-box';

import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
import { exportDataGrid } from 'devextreme/excel_exporter'

dxDateBox.defaultOptions({
    options: {
        dateSerializationFormat: "yyyy-MM-dd"
    }
});

function isNotEmpty(value) {
    return value !== undefined && value !== null && value !== '';
}

export default function ExploreBarcode(props) {
    const history = useHistory();

    const [visibleLoadPanel, setVisibleLoadPanel] = useState(false);

    //Ref para el grid Inventario
    const [gridBarcodeRef, setGridBarcodeRef] = useState(React.createRef());

    const [autoExpandAll, setAutoExpandAll] = useState(true);

    function getOrderDay(rowData) {
        return (new Date(rowData.OrderDate)).getDay();
    }

    const allowedPageSizes = [5, 10, 15, 20, 'all'];

    const exportFormats = ['xlsx'];

    const filterBuilder = {
        customOperations: [{
            name: 'weekends',
            caption: 'Weekends',
            dataTypes: ['date'],
            icon: 'check',
            hasValue: false,
            calculateFilterExpression: () => [[getOrderDay, '=', 0], 'or', [getOrderDay, '=', 6]],
        }],
        allowHierarchicalFields: true,
    };

    const filterBuilderPopupPosition = {
        of: window,
        at: 'top',
        my: 'top',
        offset: { y: 10 },
    };

    async function getAuthCredentials() {
        return await Auth.currentCredentials();
    };

    async function lambdaInvoke(lambdaFunction, sqlFilter, credentials, username) {
        var barcodesGet = [];

        var params = {
            FunctionName: lambdaFunction,
            InvocationType: 'RequestResponse',
            Payload: JSON.stringify({
                action: 'list',
                sqlFilter: sqlFilter,
                username: username
            })
        };

        const lambda = new Lambda({
            credentials: Auth.essentialCredentials(credentials),
            region: 'us-east-1'
        });

        return await lambda.invoke(params).promise();
    }

    async function getBarcodeRecords(sqlFilter) {
        try {
            var lambdaFunction;

            if (window.location.href.indexOf('sandbox.') >= 0 || window.location.href.indexOf('localhost') >= 0)
                lambdaFunction = 'exploradorLabelWMS-dev';
            else if (window.location.href.indexOf('sandboxtampico.') >= 0)
                lambdaFunction = 'exploradorLabelWMS-devleona';
            else if (window.location.href.indexOf('tampico.') >= 0)
                lambdaFunction = 'exploradorLabelWMS-prodleona';
            else
                lambdaFunction = 'exploradorLabelWMS-prod';

            var authCredentials = await getAuthCredentials();

            var username = JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username;

            var lambdaResponse = await lambdaInvoke(lambdaFunction, sqlFilter, authCredentials, username);

            return JSON.parse(JSON.parse(lambdaResponse.Payload).body);

        }
        catch (e) {
            if (e === 'No current user') {
                history.push('/');

                window.location.reload();
            }
        }
    }

    const store = new CustomStore({
        key: 'Barcode',
        load(loadOptions) {
            let sqlFilter = '(';
            [
                'filter'
            ].forEach((i) => {
                if (i in loadOptions && isNotEmpty(loadOptions[i])) {
                    var filters = loadOptions[i];

                    var filtersLength = filters.length;

                    for (var i = 0; i <= filtersLength; i++) {
                        var singleFilter = filters[i];
                        var singleFilterType = typeof singleFilter;
                        var singleFilterIsArray = Array.isArray(singleFilter);
                        var singleFilterIsDate = singleFilter instanceof Date;

                        if (singleFilter === 'NULL') {
                            singleFilter = '0';
                        }

                        if (singleFilterType.toString() === 'object' && singleFilterIsArray) {
                            sqlFilter += '(';

                            var subFiltersLength = singleFilter.length;

                            for (var i2 = 0; i2 <= subFiltersLength; i2++) {
                                var singleSubFilter = singleFilter[i2];
                                var singleSubFilterType = typeof singleSubFilter;
                                var singleSubFilterIsArray = Array.isArray(singleSubFilter);
                                var singleSubFilterIsDate = singleSubFilter instanceof Date

                                if (singleSubFilter === 'NULL') {
                                    singleSubFilter = '0';
                                }

                                if (singleSubFilterType.toString() === 'object' && singleSubFilterIsArray) {
                                    sqlFilter += '(';

                                    var subSubFiltersLength = singleSubFilter.length;

                                    for (var i3 = 0; i3 <= subSubFiltersLength; i3++) {
                                        var singleSubSubFilter = singleSubFilter[i3];
                                        var singleSubSubFilterType = typeof singleSubSubFilter;
                                        var singleSubSubFilterIsArray = Array.isArray(singleSubSubFilter);
                                        var singleSubSubFilterIsDate = singleSubSubFilter instanceof Date

                                        if (singleSubSubFilter === 'NULL') {
                                            singleSubSubFilter = '0';
                                        }

                                        if (singleSubSubFilterType.toString() === 'object' && singleSubSubFilterIsArray) {
                                            sqlFilter += '(';

                                            var subSubSubFiltersLength = singleSubSubFilter.length;

                                            for (var i4 = 0; i4 <= subSubSubFiltersLength; i4++) {
                                                var singleSubSubSubFilter = singleSubSubFilter[i4];
                                                var singleSubSubSubFilterType = typeof singleSubSubSubFilter;
                                                var singleSubSubSubFilterIsArray = Array.isArray(singleSubSubSubFilter);
                                                var singleSubSubSubFilterIsDate = singleSubSubSubFilter instanceof Date

                                                if (singleSubSubSubFilter === 'NULL') {
                                                    singleSubSubSubFilter = '0';
                                                }

                                                if (singleSubSubSubFilterType.toString() === 'object' && singleSubSubSubFilterIsArray) {
                                                    sqlFilter += '(';

                                                    var subSubSubSubFiltersLength = singleSubSubSubFilter.length;

                                                    for (var i5 = 0; i5 <= subSubSubSubFiltersLength; i5++) {
                                                        var singleSubSubSubSubFilter = singleSubSubSubFilter[i5];
                                                        var singleSubSubSubSubFilterType = typeof singleSubSubSubSubFilter;
                                                        var singleSubSubSubSubFilterIsArray = Array.isArray(singleSubSubSubSubFilter);
                                                        var singleSubSubSubSubFilterIsDate = singleSubSubSubSubFilter instanceof Date

                                                        if (singleSubSubSubSubFilter === 'NULL') {
                                                            singleSubSubSubSubFilter = '0';
                                                        }

                                                        if (singleSubSubSubSubFilterType.toString() === 'string') {
                                                            if (singleSubSubSubSubFilter === 'contains') {
                                                                filters[i][i2][i3][i4][i5] = 'LIKE';
                                                                singleSubSubSubSubFilter = 'LIKE';

                                                                filters[i][i2][i3][i4][i5 + 1] = '\'%' + filters[i][i2][i3][i4][i5 + 1] + '%\'';
                                                            }
                                                            else if (singleSubSubSubSubFilter === 'notcontains') {
                                                                filters[i][i2][i3][i4][i5] = 'NOT LIKE';
                                                                singleSubSubSubSubFilter = 'NOT LIKE';

                                                                filters[i][i2][i3][i4][i5 + 1] = '\'%' + filters[i][i2][i3][i4][i5 + 1] + '%\'';
                                                            }
                                                            else if (singleSubSubSubSubFilter === 'startswith') {
                                                                filters[i][i2][i3][i4][i5] = 'LIKE';
                                                                singleSubSubSubSubFilter = 'LIKE';

                                                                filters[i][i2][i3][i4][i5 + 1] = '\'' + filters[i][i2][i3][i4][i5 + 1] + '%\'';
                                                            }
                                                            else if (singleSubSubSubSubFilter === 'endswith') {
                                                                filters[i][i2][i3][i4][i5] = 'LIKE';
                                                                singleSubSubSubSubFilter = 'LIKE';

                                                                filters[i][i2][i3][i4][i5 + 1] = '\'%' + filters[i][i2][i3][i4][i5 + 1] + '\'';
                                                            }
                                                            else if (singleSubSubSubSubFilter === '=' || singleSubSubSubSubFilter === '<>' || singleSubSubSubSubFilter === '>' || singleSubSubSubSubFilter === '>=' || singleSubSubSubSubFilter === '<' || singleSubSubSubSubFilter === '<=') {
                                                                if (!filters[i][i2][i3][i4][i5 + 1])
                                                                    filters[i][i2][i3][i4][i5 + 1] = 'NULL';
                                                                else if (typeof filters[i][i2][i3][i4][i5 + 1] === 'string') {
                                                                    filters[i][i2][i3][i4][i5 + 1] = '\'' + filters[i][i2][i3][i4][i5 + 1] + '\'';
                                                                }
                                                            }
                                                            else if (singleSubSubSubSubFilter === '!') {
                                                                filters[i][i2][i3][i4][i5] = 'NOT';
                                                                singleSubSubSubSubFilter = 'NOT';
                                                            }

                                                            sqlFilter += ' ' + singleSubSubSubSubFilter + ' ';
                                                        }
                                                        else if (singleSubSubSubSubFilterType.toString() === 'object' && singleSubSubSubSubFilterIsDate) {
                                                            sqlFilter += ' \'' + singleSubSubSubSubFilter.toISOString().split('T')[0] + '\' ';
                                                        }
                                                        else if (singleSubSubSubSubFilterType.toString() === 'number') {
                                                            sqlFilter += ' ' + singleSubSubSubSubFilter.toString() + ' ';
                                                        }
                                                        else if (singleSubSubSubSubFilterType.toString() === 'boolean') {
                                                            if (singleSubSubSubSubFilter.toString() === 'true')
                                                                sqlFilter += '1 ';
                                                            else if (singleSubSubSubSubFilter.toString() === 'false' || singleSubSubSubSubFilter === null || singleSubSubSubSubFilter === undefined)
                                                                sqlFilter += '0 ';
                                                        }
                                                    }
                                                }
                                                else if (singleSubSubSubFilterType.toString() === 'string') {
                                                    if (singleSubSubSubFilter === 'contains') {
                                                        filters[i][i2][i3][i4] = 'LIKE';
                                                        singleSubSubSubFilter = 'LIKE';

                                                        filters[i][i2][i3][i4 + 1] = '\'%' + filters[i][i2][i3][i4 + 1] + '%\'';
                                                    }
                                                    else if (singleSubSubSubFilter === 'notcontains') {
                                                        filters[i][i2][i3][i4] = 'NOT LIKE';
                                                        singleSubSubSubFilter = 'NOT LIKE';

                                                        filters[i][i2][i3][i4 + 1] = '\'%' + filters[i][i2][i3][i4 + 1] + '%\'';
                                                    }
                                                    else if (singleSubSubSubFilter === 'startswith') {
                                                        filters[i][i2][i3][i4] = 'LIKE';
                                                        singleSubSubSubFilter = 'LIKE';

                                                        filters[i][i2][i3][i4 + 1] = '\'' + filters[i][i2][i3][i4 + 1] + '%\'';
                                                    }
                                                    else if (singleSubSubSubFilter === 'endswith') {
                                                        filters[i][i2][i3][i4] = 'LIKE';
                                                        singleSubSubSubFilter = 'LIKE';

                                                        filters[i][i2][i3][i4 + 1] = '\'%' + filters[i][i2][i3][i4 + 1] + '\'';
                                                    }
                                                    else if (singleSubSubSubFilter === '=' || singleSubSubSubFilter === '<>' || singleSubSubSubFilter === '>' || singleSubSubSubFilter === '>=' || singleSubSubSubFilter === '<' || singleSubSubSubFilter === '<=') {
                                                        if (!filters[i][i2][i3][i4 + 1])
                                                            filters[i][i2][i3][i4 + 1] = 'NULL';
                                                        else if (typeof filters[i][i2][i3][i4 + 1] === 'string') {
                                                            filters[i][i2][i3][i4 + 1] = '\'' + filters[i][i2][i3][i4 + 1] + '\'';
                                                        }
                                                    }
                                                    else if (singleSubSubSubFilter === '!') {
                                                        filters[i][i2][i3][i4] = 'NOT';
                                                        singleSubSubSubFilter = 'NOT';
                                                    }

                                                    sqlFilter += ' ' + singleSubSubSubFilter + ' ';
                                                }
                                                else if (singleSubSubSubFilterType.toString() === 'object' && singleSubSubSubFilterIsDate) {
                                                    sqlFilter += ' \'' + singleSubSubSubFilter.toISOString().split('T')[0] + '\' ';
                                                }
                                                else if (singleSubSubSubFilterType.toString() === 'number') {
                                                    sqlFilter += ' ' + singleSubSubSubFilter.toString() + ' ';
                                                }
                                                else if (singleSubSubSubFilterType.toString() === 'boolean') {
                                                    if (singleSubSubSubFilter.toString() === 'true')
                                                        sqlFilter += '1 ';
                                                    else if (singleSubSubSubFilter.toString() === 'false' || singleSubSubSubFilter === null || singleSubSubSubFilter === undefined)
                                                        sqlFilter += '0 ';
                                                }
                                            }

                                            sqlFilter += ')';
                                        }
                                        else if (singleSubSubFilterType.toString() === 'string') {
                                            if (singleSubSubFilter === 'contains') {
                                                filters[i][i2][i3] = 'LIKE';
                                                singleSubSubFilter = 'LIKE';

                                                filters[i][i2][i3 + 1] = '\'%' + filters[i][i2][i3 + 1] + '%\'';
                                            }
                                            else if (singleSubSubFilter === 'notcontains') {
                                                filters[i][i2][i3] = 'NOT LIKE';
                                                singleSubSubFilter = 'NOT LIKE';

                                                filters[i][i2][i3 + 1] = '\'%' + filters[i][i2][i3 + 1] + '%\'';
                                            }
                                            else if (singleSubSubFilter === 'startswith') {
                                                filters[i][i2][i3] = 'LIKE';
                                                singleSubSubFilter = 'LIKE';

                                                filters[i][i2][i3 + 1] = '\'' + filters[i][i2][i3 + 1] + '%\'';
                                            }
                                            else if (singleSubSubFilter === 'endswith') {
                                                filters[i][i2][i3] = 'LIKE';
                                                singleSubSubFilter = 'LIKE';

                                                filters[i][i2][i3 + 1] = '\'%' + filters[i][i2][i3 + 1] + '\'';
                                            }
                                            else if (singleSubSubFilter === '=' || singleSubSubFilter === '<>' || singleSubSubFilter === '>' || singleSubSubFilter === '>=' || singleSubSubFilter === '<' || singleSubSubFilter === '<=') {
                                                if (!filters[i][i2][i3 + 1])
                                                    filters[i][i2][i3 + 1] = 'NULL';
                                                else if (typeof filters[i][i2][i3 + 1] === 'string') {
                                                    filters[i][i2][i3 + 1] = '\'' + filters[i][i2][i3 + 1] + '\'';
                                                }
                                            }
                                            else if (singleSubSubFilter === '!') {
                                                filters[i][i2][i3] = 'NOT';
                                                singleSubSubFilter = 'NOT';
                                            }
                                            sqlFilter += ' ' + singleSubSubFilter + ' ';
                                        }
                                        else if (singleSubSubFilterType.toString() === 'object' && singleSubSubFilterIsDate) {
                                            sqlFilter += ' \'' + singleSubSubFilter.toISOString().split('T')[0] + '\' ';
                                        }
                                        else if (singleSubSubFilterType.toString() === 'number') {
                                            sqlFilter += ' ' + singleSubSubFilter.toString() + ' ';
                                        }
                                        else if (singleSubSubFilterType.toString() === 'boolean') {
                                            if (singleSubSubFilter.toString() === 'true')
                                                sqlFilter += '1 ';
                                            else if (singleSubSubFilter.toString() === 'false' || singleSubSubFilter === null || singleSubSubFilter === undefined)
                                                sqlFilter += '0 ';
                                        }
                                    }

                                    sqlFilter += ')';
                                }
                                else if (singleSubFilterType.toString() === 'string') {
                                    if (singleSubFilter === 'contains') {
                                        filters[i][i2] = 'LIKE';
                                        singleSubFilter = 'LIKE';

                                        filters[i][i2 + 1] = '\'%' + filters[i][i2 + 1] + '%\'';
                                    }
                                    else if (singleSubFilter === 'notcontains') {
                                        filters[i][i2] = 'NOT LIKE';
                                        singleSubFilter = 'NOT LIKE';

                                        filters[i][i2 + 1] = '\'%' + filters[i][i2 + 1] + '%\'';
                                    }
                                    else if (singleSubFilter === 'startswith') {
                                        filters[i][i2] = 'LIKE';
                                        singleSubFilter = 'LIKE';

                                        filters[i][i2 + 1] = '\'' + filters[i][i2 + 1] + '%\'';
                                    }
                                    else if (singleSubFilter === 'endswith') {
                                        filters[i][i2] = 'LIKE';
                                        singleSubFilter = 'LIKE';

                                        filters[i][i2 + 1] = '\'%' + filters[i][i2 + 1] + '\'';
                                    }
                                    else if (singleSubFilter === '=' || singleSubFilter === '<>' || singleSubFilter === '>' || singleSubFilter === '>=' || singleSubFilter === '<' || singleSubFilter === '<=') {
                                        if (!filters[i][i2 + 1])
                                            filters[i][i2 + 1] = 'NULL';
                                        else if (typeof filters[i][i2 + 1] === 'string') {
                                            filters[i][i2 + 1] = '\'' + filters[i][i2 + 1] + '\'';
                                        }
                                    }
                                    else if (singleSubFilter === '!') {
                                        filters[i][i2] = 'NOT';
                                        singleSubFilter = 'NOT';
                                    }
                                    sqlFilter += ' ' + singleSubFilter + ' ';
                                }
                                else if (singleSubFilterType.toString() === 'object' && singleSubFilterIsDate) {
                                    sqlFilter += ' \'' + singleSubFilter.toISOString().split('T')[0] + '\' ';
                                }
                                else if (singleSubFilterType.toString() === 'number') {
                                    sqlFilter += ' ' + singleSubFilter.toString() + ' ';
                                }
                                else if (singleSubFilterType.toString() === 'boolean') {
                                    if (singleSubFilter.toString() === 'true')
                                        sqlFilter += '1 ';
                                    else if (singleSubFilter.toString() === 'false' || singleSubFilter === null || singleSubFilter === undefined)
                                        sqlFilter += '0 ';
                                }
                            }

                            sqlFilter += ')';
                        }
                        else if (singleFilterType.toString() === 'string') {
                            if (singleFilter === 'contains') {
                                filters[i] = 'LIKE';
                                singleFilter = 'LIKE';

                                filters[i + 1] = '\'%' + filters[i + 1] + '%\'';
                            }
                            else if (singleFilter === 'notcontains') {
                                filters[i] = 'NOT LIKE';
                                singleFilter = 'NOT LIKE';

                                filters[i + 1] = '\'%' + filters[i + 1] + '%\'';
                            }
                            else if (singleFilter === 'startswith') {
                                filters[i] = 'LIKE';
                                singleFilter = 'LIKE';

                                filters[i + 1] = '\'' + filters[i + 1] + '%\'';
                            }
                            else if (singleFilter === 'endswith') {
                                filters[i] = 'LIKE';
                                singleFilter = 'LIKE';

                                filters[i + 1] = '\'%' + filters[i + 1] + '\'';
                            }
                            else if (singleFilter === '=' || singleFilter === '<>' || singleFilter === '>' || singleFilter === '>=' || singleFilter === '<' || singleFilter === '<=') {
                                if (!filters[i + 1])
                                    filters[i + 1] = 'NULL';
                                else if (typeof filters[i + 1] === 'string') {
                                    filters[i + 1] = '\'' + filters[i + 1] + '\'';
                                }
                            }
                            else if (singleFilter === '!') {
                                filters[i] = 'NOT';
                                singleFilter = 'NOT';
                            }
                            sqlFilter += ' ' + singleFilter + ' ';
                        }
                        else if (singleFilterType.toString() === 'object' && singleFilterIsDate) {
                            sqlFilter += ' \'' + singleFilter.toISOString().split('T')[0] + '\' ';
                        }
                        else if (singleFilterType.toString() === 'number') {
                            sqlFilter += ' ' + singleFilter.toString() + ' ';
                        }
                        else if (singleFilterType.toString() === 'boolean') {
                            if (singleFilter.toString() === 'true')
                                sqlFilter += '1 ';
                            else if (singleFilter.toString() === 'false' || singleFilter === null || singleFilter === undefined)
                                sqlFilter += '0 ';
                        }
                    }

                    sqlFilter += ')';
                }
            });

            async function getRecords() {
                if (sqlFilter === '(')
                    sqlFilter = '';

                var records = await getBarcodeRecords(sqlFilter);
                return {
                    data: records,
                    totalCount: records.length
                };
            }

            return getRecords()
                .then(function (result) {
                    return result;
                })
                .catch(() => { throw new Error('Data Loading Error'); });
        },
    });

    function refreshButton() {
        if (gridBarcodeRef.current) {
            var gridBarcode = gridBarcodeRef.current.instance;

            gridBarcode.refresh();
        }
    };

    function onAutoExpandAllChanged() {
        setAutoExpandAll(!autoExpandAll)
    };

    function onExporting(e) {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Datos');

        exportDataGrid({
            component: e.component,
            worksheet,
            autoFilterEnabled: true,
        }).then(() => {
            workbook.xlsx.writeBuffer().then((buffer) => {
                saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Datos.xlsx');
            });
        });
        e.cancel = true;
    };

    const iconoProceso = React.useCallback((data) => {
        var SCWMSID = data.data.SCWMSID;
        var CantidadReservada = data.data.CantidadReservada;

        if (SCWMSID || CantidadReservada) {
            return (<div class="inline-block w-5 h-5 bg-yellow-500 rounded-full"></div>)
        }
        else {
            return (<div></div>)
        }
    }, []);

    const emptyButton = React.useCallback(async () => {
        // if (gridBarcodeRef.current) {
        //     var gridProductCluster = gridBarcodeRef.current.instance;

        //     gridProductCluster.filter(['Cantidad', '=', '0']);
        // }
    }, []);

    const fullButton = React.useCallback(async () => {
        // if (gridBarcodeRef.current) {
        //     var gridProductCluster = gridBarcodeRef.current.instance;

        //     gridProductCluster.clearFilter();

        //     gridProductCluster.columnOption('Cantidad', 'notEmpty');

        // }
    }, []);

    return (
        <div>
            <div class="bg-gray-200 m-2">
                <h1 class="font-sans inline-block text-xl font-bold text-gray-700 leading-8 align-top mb-1"><FontAwesomeIcon icon={faFileChartColumn} size="xl" /> WMS Barcodes</h1>
            </div>
            <div>
                <button onClick={refreshButton} type="submit" class="ml-2 mb-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                    <FontAwesomeIcon icon={faArrowsRotate} /> Actualizar
                </button>
                {/* <button onClick={emptyButton} type="submit" class="ml-2 mb-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                    <FontAwesomeIcon icon={faBoxOpen} /> Sin asignar
                </button>
                <button onClick={fullButton} type="submit" class="ml-2 mb-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                    <FontAwesomeIcon icon={faBoxOpenFull} /> Asignados
                </button> */}
            </div>

            <LoadPanel
                shadingColor="rgba(0,0,0,0.4)"
                visible={visibleLoadPanel}
                showIndicator={true}
                message="Por favor espere..."
                shading={true}
                showPane={true}
                hideOnOutsideClick={false}
            />

            <div class="m-2 bg-white p-4 shadow-xl">
                <div className="options mb-2">
                    <div className="option">
                        <CheckBox text="Expandir todos los grupos"
                            value={autoExpandAll}
                            onValueChanged={onAutoExpandAllChanged}>
                        </CheckBox>
                    </div>
                </div>
                <DataGrid
                    id="gridBarcode"
                    columnsAutoWidth={true}
                    filterBuilder={filterBuilder}
                    dataSource={store}
                    allowColumnReordering={true}
                    allowColumnResizing={true}
                    columnResizingMode="widget"
                    showBorders={true}
                    rowAlternationEnabled={true}
                    showRowLines={true}
                    showColumnLines={true}
                    remoteOperations={{ filtering: true }}
                    ref={gridBarcodeRef}
                    onExporting={onExporting}
                >
                    <Scrolling rowRenderingMode='virtual'></Scrolling>
                    <Paging defaultPageSize={10} />
                    <Pager
                        visible={true}
                        allowedPageSizes={allowedPageSizes}
                        displayMode='compact'
                        showPageSizeSelector={true}
                        showInfo={true}
                        showNavigationButtons={true}
                    />
                    <FilterRow visible={true} />
                    <FilterPanel visible={true} />
                    <FilterBuilderPopup position={filterBuilderPopupPosition} />

                    <HeaderFilter visible={true} />

                    <Editing
                        mode="cell"
                        newRowPosition="last"
                        allowUpdating={false}
                        allowAdding={false}
                        allowDeleting={false}
                    />

                    <GroupPanel visible={true} />
                    <SearchPanel visible={true} />
                    <ColumnChooser
                        enabled={true}
                        mode='select'
                    >
                        <ColumnChooserSearch
                            enabled={true}
                        />

                        <ColumnChooserSelection
                            allowSelectAll={true}
                            selectByClick={true}
                            recursive={true}
                        />
                    </ColumnChooser>
                    <Grouping autoExpandAll={autoExpandAll} />
                    <StateStoring enabled={true} type="localStorage" storageKey="gridContainerExploreBarcode" />
                    <Export enabled={true} />

                    <Column dataField="Barcode" caption="Barcode" dataType="number" width={100} visible={true} />
                    <Column dataField="BarcodePadre" caption="Barcode Padre" dataType="number" width={100} visible={false} />
                    <Column caption="En Proceso" alignment="center" width={110} cellRender={iconoProceso} visible={true} />
                    <Column dataField="Articulo" caption="Artículo" dataType="string" width={100} visible={true} />
                    <Column dataField="DescripcionArticulo" caption="Descripción" dataType="string" width={300} visible={true} />
                    <Column dataField="Almacen" caption="Almacén" dataType="string" width={100} visible={true} />
                    <Column dataField="NombreAlmacen" caption="Nombre Almacén" dataType="string" width={300} visible={false} />
                    <Column dataField="Posicion" caption="Posición" dataType="string" width={120} visible={true} />
                    <Column dataField="Sucursal" caption="Sucursal" dataType="number" width={300} visible={false} />
                    <Column dataField="NombreSucursal" caption="Nombre Sucursal" dataType="string" width={300} visible={false} />
                    <Column dataField="Plaza" caption="Plaza" dataType="string" width={300} visible={false} />
                    <Column dataField="Cantidad" caption="Cantidad" dataType="number" width={150} visible={true} format=',###' />
                    <Column dataField="CantidadReservada" caption="Cantidad Reservada" dataType="number" width={180} visible={true} format=',###' />
                    <Column dataField="CantidadDisponible" caption="Cantidad Disponible" dataType="number" width={180} visible={true} format=',###' />
                    <Column dataField="Pasillo" caption="Pasillo" dataType="string" width={300} visible={false} />
                    <Column dataField="Nivel" caption="Nivel" dataType="string" width={300} visible={false} />
                    <Column dataField="Zona" caption="Zona" dataType="string" width={300} visible={false} />
                    <Column dataField="FechaIngreso" caption="Fecha de Ingreso" dataType="date" width={150} visible={true} />
                    <Column dataField="SCWMSID" caption="ID WMS" dataType="number" width={100} visible={false} />
                    <Column dataField="MovimientoWMS" caption="Movimiento WMS" dataType="string" width={300} visible={false} />
                    <Column dataField="SCRama" caption="Surtido en Pedido" dataType="string" width={300} visible={false} />
                    <Summary>
                        <GroupItem
                            column="Cantidad"
                            summaryType="sum"
                            valueFormat=",##0.##"
                            displayFormat="Cantidad {0}"
                            showInGroupFooter={false}>
                        </GroupItem>
                        <GroupItem
                            column="CantidadReservada"
                            summaryType="sum"
                            valueFormat=",##0.##"
                            displayFormat="Cantidad Reservada {0}"
                            showInGroupFooter={false}>
                        </GroupItem>
                        <GroupItem
                            column="CantidadDisponible"
                            summaryType="sum"
                            valueFormat=",##0.##"
                            displayFormat="Cantidad Disponible {0}"
                            showInGroupFooter={false}>
                        </GroupItem>
                        <TotalItem
                            column="Cantidad"
                            summaryType="sum"
                            valueFormat=",##0.##"
                            displayFormat="Cantidad {0}"
                            showInColumn="Cantidad">
                        </TotalItem>
                        <TotalItem
                            column="CantidadReservada"
                            summaryType="sum"
                            valueFormat=",##0.##"
                            displayFormat="Cantidad Reservada {0}"
                            showInColumn="CantidadReservada">
                        </TotalItem>
                        <TotalItem
                            column="CantidadDisponible"
                            summaryType="sum"
                            valueFormat=",##0.##"
                            displayFormat="Cantidad Disponible {0}"
                            showInColumn="CantidadDisponible">
                        </TotalItem>
                    </Summary>
                </DataGrid>
            </div>
        </div>
    );
}