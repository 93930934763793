import React, { useState, useEffect, useRef, useMemo } from 'react';

// Ruteador
import { Route, Link, useHistory } from "react-router-dom";

// LoadPanel
import { LoadPanel } from 'devextreme-react/load-panel';

//Form
import Form, { GroupItem, Label, TabbedItem, TabPanelOptions, Tab, EmptyItem } from 'devextreme-react/form';

//Validator
import { EmailRule, CustomRule } from 'devextreme-react/validator';

import { Auth, API, Storage } from 'aws-amplify';

// Grid
import DataGrid, {
    Column, FilterRow, HeaderFilter, FilterPanel, FilterBuilderPopup,
    GroupPanel, Grouping, SearchPanel, Sorting, Editing, Lookup, Toolbar, Item, RequiredRule,
    Scrolling, Pager, Paging, Summary, TotalItem, StateStoring, ColumnChooser, Format, Selection,
    Export
} from 'devextreme-react/data-grid';

import { exportDataGrid } from 'devextreme/pdf_exporter';
import { exportDataGrid as exportDataGridExcel } from 'devextreme/excel_exporter';
import { jsPDF } from 'jspdf';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';

// Iconos
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsRotate } from "@fortawesome/pro-solid-svg-icons";
import { faArrowUp } from "@fortawesome/pro-solid-svg-icons";
import { faArrowDown } from "@fortawesome/pro-solid-svg-icons";
import { faEquals } from "@fortawesome/pro-solid-svg-icons";
import { faFileExcel } from "@fortawesome/pro-solid-svg-icons";
import { faFilePdf } from "@fortawesome/pro-solid-svg-icons";
import { faMobile } from "@fortawesome/pro-solid-svg-icons";
import { faFileChartColumn } from "@fortawesome/pro-duotone-svg-icons";

import dxDateBox from "devextreme/ui/date_box";

import Lambda from 'aws-sdk/clients/lambda';

import '../ListStyle.css';

dxDateBox.defaultOptions({
    options: {
        dateSerializationFormat: "yyyy-MM-dd"
    }
});

export default function SalesCompReport(props) {
    const history = useHistory();

    const [visibleLoadPanel, setVisibleLoadPanel] = useState(false);

    const dateFormatYYYYDDMM = React.useCallback(() => {
        var date = new Date();

        var year = date.getFullYear().toString();
        var month = date.getMonth() + 1;
        var day = date.getDate().toString();

        if (month < 10)
            month = '0' + month.toString();
        else
            month = month.toString();

        return (
            year + "-" + month + "-" + day
        );
    }, []);

    const [reportPayload, setReportPayload] = useState({
        header: {
            FechaD: dateFormatYYYYDDMM(),
            FechaA: dateFormatYYYYDDMM()
        },
        salesPlaza: [],
        locations: []
    });

    //Ref para el form
    const [formReporteRef, setFormReporteRef] = useState(React.createRef());

    //Ref para el grid Sucursales
    const [gridSucursalesRef, setGridSucursalesRef] = useState(React.createRef());

    //Ref para el grid Plazas
    const [gridPlazasRef, setGridPlazasRef] = useState(React.createRef());

    const filterBuilderPopupPosition = {
        of: window,
        at: 'top',
        my: 'top',
        offset: { y: 10 },
    };

    var fechaEditorOptions = useMemo(() => {
        return {
            width: '100%',
            readOnly: false
        };
    }, []);

    var fechaAnteriorEditorOptions = useMemo(() => {
        return {
            width: '100%',
            readOnly: true
        };
    }, []);

    const icono = React.useCallback((data) => {
        var Diferencia = parseFloat(data.data.Diferencia);

        if (!Diferencia)
            Diferencia = 0;

        if (Diferencia === 0) {
            return (<div class="inline-block text-xl"><FontAwesomeIcon icon={faEquals} /></div>)
        }
        else if (Diferencia > 0) {
            return (<div class="inline-block text-xl text-green-500"><FontAwesomeIcon icon={faArrowUp} /></div>)
        }
        else if (Diferencia < 0) {
            return (<div class="inline-block text-xl text-red-500"><FontAwesomeIcon icon={faArrowDown} /></div>)
        }
    }, []);

    const refreshButton = React.useCallback(async () => {
        if (formReporteRef.current) {
            var formReporte = formReporteRef.current.instance;
            var isValid = formReporte.validate();

            if (!isValid.isValid) {
                return;
            }
        }

        try {
            setVisibleLoadPanel(true);

            var lambdaFunction;

            if (window.location.href.indexOf('sandbox.') >= 0 || window.location.href.indexOf('localhost') >= 0)
                lambdaFunction = 'reporteComparativoVentas-dev';
            else if (window.location.href.indexOf('sandboxtampico.') >= 0)
                lambdaFunction = 'reporteComparativoVentas-devleona';
            else if (window.location.href.indexOf('tampico.') >= 0)
                lambdaFunction = 'reporteComparativoVentas-prodleona';
            else
                lambdaFunction = 'reporteComparativoVentas-prod';

            Auth.currentCredentials()
                .then(credentials => {
                    const lambda = new Lambda({
                        credentials: Auth.essentialCredentials(credentials),
                        region: 'us-east-1'
                    });

                    var params = {
                        FunctionName: lambdaFunction,
                        Payload: JSON.stringify({
                            fromDate: reportPayload.header.FechaD,
                            toDate: reportPayload.header.FechaA,
                            Title: reportPayload.header.Titulo,
                            username: props.username
                        })
                    };

                    lambda.invoke(params, function (err, data) {
                        if (err) {
                            setVisibleLoadPanel(false);
                        }
                        else {
                            var payload = JSON.parse(JSON.parse(data.Payload).body);

                            setVisibleLoadPanel(false);
                            setReportPayload(payload);
                        }
                    });
                })
        }
        catch (e) {
            setVisibleLoadPanel(false);

            if (e === 'No current user') {
                history.push('/');

                window.location.reload();
            }
        }
    }, [formReporteRef, history, reportPayload, props.username]);

    const excelButton = React.useCallback(() => {
        const workbook = new Workbook();
        const locationSheet = workbook.addWorksheet('Sucursales');
        const salesPlazaSheet = workbook.addWorksheet('Plazas');

        var gridSucursales = gridSucursalesRef.current.instance;
        var gridPlazas = gridPlazasRef.current.instance;

        exportDataGridExcel({
            worksheet: locationSheet,
            component: gridSucursales,
            topLeftCell: { row: 1, column: 1 }
        }).then(() => exportDataGridExcel({
            worksheet: salesPlazaSheet,
            component: gridPlazas,
            topLeftCell: { row: 1, column: 1 }
        })).then(() => {
            workbook.xlsx.writeBuffer().then((buffer) => {
                saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Reporte.xlsx');
            });
        });

    }, [gridPlazasRef, gridSucursalesRef]);

    const pdfButton = React.useCallback(() => {
        const doc = new jsPDF();

        var gridSucursales = gridSucursalesRef.current.instance;
        var gridPlazas = gridPlazasRef.current.instance;

        doc.text('Comparación de ventas por Sucursal de ' + reportPayload.header.FechaD + ' al ' + reportPayload.header.FechaA, 7, 10);

        if (reportPayload.header.Titulo)
            doc.text(reportPayload.header.Titulo, 7, 17);

        exportDataGrid({
            jsPDFDocument: doc,
            component: gridSucursales,
            topLeft: { x: 7, y: 7 },
            columnWidths: [41, 31, 31, 0, 31, 25],
            customizeCell: ({ gridCell, pdfCell }) => {
                pdfCell.font.size = 7;
            }
        }).then(() => {
            doc.addPage();

            doc.text('Comparación de ventas por Plaza de ' + reportPayload.header.FechaD + ' al ' + reportPayload.header.FechaA, 7, 10);

            if (reportPayload.header.Titulo)
                doc.text(reportPayload.header.Titulo, 7, 17);

            exportDataGrid({
                jsPDFDocument: doc,
                component: gridPlazas,
                topLeft: { x: 7, y: 7 },
                columnWidths: [41, 31, 31, 0, 31, 25],
                customizeCell: ({ gridCell, pdfCell }) => {
                    pdfCell.font.size = 7;
                },
            }).then(() => {
                doc.save('Reporte.pdf');
            });
        });
    }, [gridPlazasRef, gridSucursalesRef, reportPayload.header.FechaA, reportPayload.header.FechaD, reportPayload.header.Titulo]);

    const pdfMobileButton = React.useCallback(() => {
        const doc = new jsPDF();

        var gridSucursales = gridSucursalesRef.current.instance;
        var gridPlazas = gridPlazasRef.current.instance;

        doc.text('Comparación de ventas por Sucursal de ' + reportPayload.header.FechaD + ' al ' + reportPayload.header.FechaA, 7, 10);

        if (reportPayload.header.Titulo)
            doc.text(reportPayload.header.Titulo, 7, 17);

        exportDataGrid({
            jsPDFDocument: doc,
            component: gridSucursales,
            topLeft: { x: 7, y: 7 },
            columnWidths: [68, 38, 38, 0, 0, 30],
            customizeCell: ({ gridCell, pdfCell }) => {
                pdfCell.font.size = 10;
            }
        }).then(() => {
            doc.addPage();

            doc.text('Comparación de ventas por Plaza de ' + reportPayload.header.FechaD + ' al ' + reportPayload.header.FechaA, 7, 10);

            if (reportPayload.header.Titulo)
                doc.text(reportPayload.header.Titulo, 7, 17);

            exportDataGrid({
                jsPDFDocument: doc,
                component: gridPlazas,
                topLeft: { x: 7, y: 7 },
                columnWidths: [68, 38, 38, 0, 0, 30],
                customizeCell: ({ gridCell, pdfCell }) => {
                    pdfCell.font.size = 10;
                },
            }).then(() => {
                doc.save('Reporte.pdf');
            });
        });
    }, [gridPlazasRef, gridSucursalesRef, reportPayload.header.FechaA, reportPayload.header.FechaD, reportPayload.header.Titulo]);

    const calculateDiffPercent = React.useCallback((options) => {
        if (options.name === 'PorcentajeDiferencia') {
            if (options.summaryProcess === 'start') {
                options.totalValue = 0;
            }
            else if (options.summaryProcess === 'calculate') {
                options.totalValue = reportPayload.header.Porcentaje;
            }
        }
    }, [reportPayload]);

    const calculateDiffPercentPlaza = React.useCallback((options) => {
        if (options.name === 'PorcentajeDiferencia') {
            if (options.summaryProcess === 'start') {
                options.totalValue = 0;
            }
            else if (options.summaryProcess === 'calculate') {
                options.totalValue = reportPayload.header.PorcentajePlazas;
            }
        }
    }, [reportPayload]);

    return (
        <div>
            <div class="bg-gray-200 m-2">
                <h1 class="font-sans inline-block text-xl font-bold text-gray-700 leading-8 align-top mb-1"><FontAwesomeIcon icon={faFileChartColumn} size="xl" /> Comparativo de Ventas</h1>
            </div>
            <div>
                <button onClick={refreshButton} type="submit" class="ml-2 mb-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                    <FontAwesomeIcon icon={faArrowsRotate} /> Actualizar
                </button>
                <button onClick={excelButton} type="submit" class="mb-4 ml-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                    <FontAwesomeIcon icon={faFileExcel} /> Exportar Excel
                </button>
                <button onClick={pdfButton} type="submit" class="mb-4 ml-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                    <FontAwesomeIcon icon={faFilePdf} /> Exportar PDF
                </button>
                <button onClick={pdfMobileButton} type="submit" class="mb-4 ml-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                    <FontAwesomeIcon icon={faMobile} /> Exportar PDF Móvil
                </button>
            </div>

            <LoadPanel
                shadingColor="rgba(0,0,0,0.4)"
                visible={visibleLoadPanel}
                showIndicator={true}
                message="Por favor espere..."
                shading={true}
                showPane={true}
                hideOnOutsideClick={false}
            />

            <div class="m-2 bg-white p-4 shadow-xl">
                <Form
                    id="formReporte"
                    labelMode={'outside'}
                    formData={reportPayload}
                    readOnly={false}
                    showColonAfterLabel={false}
                    labelLocation={'top'}
                    ref={formReporteRef}
                >
                    <GroupItem caption="Filtros" colCount={2}>
                        <Item dataField="header.FechaD" editorType="dxDateBox" editorOptions={fechaEditorOptions}>
                            <Label text={"De Fecha"} />
                            <RequiredRule message="De Fecha es requerido" />
                        </Item>
                        <Item dataField="header.FechaA" editorType="dxDateBox" editorOptions={fechaEditorOptions}>
                            <Label text={"A Fecha"} />
                            <RequiredRule message="A Fecha es requerido" />
                        </Item>
                        <Item dataField="header.FechaDAnterior" editorType="dxDateBox" editorOptions={fechaAnteriorEditorOptions}>
                            <Label text={"De Fecha Anterior"} />
                        </Item>
                        <Item dataField="header.FechaAAnterior" editorType="dxDateBox" editorOptions={fechaAnteriorEditorOptions}>
                            <Label text={"A Fecha Anterior"} />
                        </Item>
                    </GroupItem>
                    <GroupItem caption="Título Reporte" colCount={2}>
                        <Item dataField="header.Titulo" >
                            <Label text={"Título"} />
                        </Item>
                    </GroupItem>
                    <GroupItem caption="Información" colCount={1}>
                        <TabbedItem>
                            <TabPanelOptions deferRendering={false} showNavButtons={true} />
                            <Tab title="Sucursales" colCount={2}>
                                <DataGrid
                                    id="gridSucursales"
                                    ref={gridSucursalesRef}
                                    columnsAutoWidth={true}
                                    dataSource={reportPayload.locations}
                                    showBorders={true}
                                    showColumnLines={true}
                                    showRowLines={true}
                                    rowAlternationEnabled={true}
                                    allowColumnResizing={true}
                                    columnResizingMode="widget"
                                    allowColumnReordering={false}
                                    repaintChangesOnly={true}
                                >
                                    <Scrolling
                                        mode='virtual'
                                    />
                                    <Editing
                                        mode="cell"
                                        newRowPosition="last"
                                        allowUpdating={false}
                                        allowAdding={false}
                                        allowDeleting={false}
                                    />

                                    <Sorting mode="none" />
                                    <FilterRow visible={true} />
                                    <FilterPanel visible={true} />
                                    <FilterBuilderPopup position={filterBuilderPopupPosition} />

                                    <HeaderFilter visible={true} />

                                    <Column dataField="Nombre" width={400} visible={true} />
                                    <Column dataField="Importe" width={180} dataType="number" caption="Importe Actual">
                                        <Format
                                            type="currency"
                                            precision={2}

                                        />
                                    </Column>
                                    <Column dataField="ImporteAnterior" width={180} dataType="number" caption="Importe Anterior">
                                        <Format
                                            type="currency"
                                            precision={2}

                                        />
                                    </Column>
                                    <Column caption="" alignment="center" width={50} cellRender={icono} />
                                    <Column dataField="Diferencia" width={180} dataType="number" caption="Diferencia">
                                        <Format
                                            type="currency"
                                            precision={2}
                                        />
                                    </Column>
                                    <Column dataField="Porcentaje" width={180} dataType="number" caption="Porcentaje">
                                        <Format
                                            type="percent"
                                            precision={2}
                                        />
                                    </Column>
                                    <Summary calculateCustomSummary={calculateDiffPercent}>
                                        <TotalItem
                                            column="Importe"
                                            summaryType="sum"
                                            valueFormat="$,##0.##"
                                            displayFormat="Total {0}"
                                            showInColumn="Importe"
                                        />
                                        <TotalItem
                                            column="ImporteAnterior"
                                            summaryType="sum"
                                            valueFormat="$,##0.##"
                                            displayFormat="Total {0}"
                                            showInColumn="ImporteAnterior"
                                        />
                                        <TotalItem
                                            column="Diferencia"
                                            summaryType="sum"
                                            valueFormat="$,##0.##"
                                            displayFormat="Total {0}"
                                            showInColumn="Diferencia"
                                        />
                                        <TotalItem
                                            name="PorcentajeDiferencia"
                                            summaryType="custom"
                                            valueFormat=",##0.##"
                                            displayFormat="{0} %"
                                            showInColumn="Porcentaje"
                                        />
                                    </Summary>
                                </DataGrid>
                            </Tab>
                            <Tab title="Plazas" colCount={2}>
                                <DataGrid
                                    id="gridPlazas"
                                    ref={gridPlazasRef}
                                    columnsAutoWidth={true}
                                    dataSource={reportPayload.salesPlaza}
                                    showBorders={true}
                                    showColumnLines={true}
                                    showRowLines={true}
                                    rowAlternationEnabled={true}
                                    allowColumnResizing={true}
                                    columnResizingMode="widget"
                                    allowColumnReordering={false}
                                    repaintChangesOnly={true}
                                >
                                    <Scrolling
                                        mode='virtual'
                                    />
                                    <Editing
                                        mode="cell"
                                        newRowPosition="last"
                                        allowUpdating={false}
                                        allowAdding={false}
                                        allowDeleting={false}
                                    />

                                    <Sorting mode="none" />
                                    <FilterRow visible={true} />
                                    <FilterPanel visible={true} />
                                    <FilterBuilderPopup position={filterBuilderPopupPosition} />

                                    <HeaderFilter visible={true} />

                                    <Column dataField="Plaza" width={300} visible={true} />
                                    <Column dataField="Importe" width={180} dataType="number" caption="Importe Actual">
                                        <Format
                                            type="currency"
                                            precision={2}

                                        />
                                    </Column>
                                    <Column dataField="ImporteAnterior" width={180} dataType="number" caption="Importe Anterior">
                                        <Format
                                            type="currency"
                                            precision={2}

                                        />
                                    </Column>
                                    <Column caption="" alignment="center" width={50} cellRender={icono} />
                                    <Column dataField="Diferencia" width={180} dataType="number" caption="Diferencia">
                                        <Format
                                            type="currency"
                                            precision={2}
                                        />
                                    </Column>
                                    <Column dataField="Porcentaje" width={180} dataType="number" caption="Porcentaje">
                                        <Format
                                            type="percent"
                                            precision={2}
                                        />
                                    </Column>
                                    <Summary calculateCustomSummary={calculateDiffPercentPlaza}>
                                        <TotalItem
                                            column="Importe"
                                            summaryType="sum"
                                            valueFormat="$,##0.##"
                                            displayFormat="Total {0}"
                                            showInColumn="Importe"
                                        />
                                        <TotalItem
                                            column="ImporteAnterior"
                                            summaryType="sum"
                                            valueFormat="$,##0.##"
                                            displayFormat="Total {0}"
                                            showInColumn="ImporteAnterior"
                                        />
                                        <TotalItem
                                            column="Diferencia"
                                            summaryType="sum"
                                            valueFormat="$,##0.##"
                                            displayFormat="Total {0}"
                                            showInColumn="Diferencia"
                                        />
                                        <TotalItem
                                            name="PorcentajeDiferencia"
                                            summaryType="custom"
                                            valueFormat=",##0.##"
                                            displayFormat="{0} %"
                                            showInColumn="Porcentaje"
                                        />
                                    </Summary>
                                </DataGrid>
                            </Tab>
                        </TabbedItem>
                    </GroupItem>
                </Form>
            </div>
        </div>
    );
}