import React, { useState, useEffect, useRef } from 'react';

// Toast
import { Toast } from 'devextreme-react/toast';

import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

import { Watermark } from '@hirohe/react-watermark';

import './DocumentViewer.css';

import { Auth, API, Storage } from 'aws-amplify';

export default function DocumentViewer(props) {
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [usuario, setUsuario] = useState(props.usuario)

    // loadPanelPosition para el control FileManager
    const loadPanelPosition = { of: '#file-manager' };

    const [attachments, setAttachments] = useState([]);
    const [pdfDocument, setPdfDocument] = useState(undefined);

    const [visibleToast, setVisibleToast] = useState(false);
    const [messageToast, setMessageToast] = useState('');
    const [typeToast, setTypeToast] = useState('success');

    const options = {
        cMapUrl: 'cmaps/',
        cMapPacked: true,
        standardFontDataUrl: 'standard_fonts/',
    };

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
        setPageNumber(1);
    }

    function changePage(offset) {
        setPageNumber(prevPageNumber => prevPageNumber + offset);
    }

    function onItemClick({ pageNumber: itemPageNumber }) {
        setPageNumber(itemPageNumber);
    }

    useEffect(() => {
        async function initialize() {
            var pdfGet = await Storage.get("/document/manual.pdf", { download: true });

            setPdfDocument(pdfGet.Body);
        }

        initialize();
    }, [props.isadmin]);

    function onHidingToast() {
        setVisibleToast(false);
    }

    return (
        <div>
            <Toast
                visible={visibleToast}
                message={messageToast}
                type={typeToast}
                onHiding={onHidingToast}
                displayTime={5000}
                width={'auto'}
                position={{
                    my: 'top center',
                    at: 'top center',
                    of: window,
                    offset: '0 130'
                }}
            />
            <div className="pdfDiv">
                <div className="pdfContainer">
                    <div className="pdfContainerDocument">
                        <Watermark
                            fontFamily="Arial, Helvetica, sans-serif"
                            opacity={0.2}
                            rotate={-45}
                            show
                            text={usuario}
                            textColor="#392D2B"
                            textSize={24}
                        >
                            <Document
                                file={pdfDocument}
                                onLoadSuccess={onDocumentLoadSuccess}
                                options={options}
                                onItemClick={onItemClick}
                            >
                                <Page pageNumber={pageNumber || 1} />
                            </Document>
                        </Watermark>
                    </div>
                </div>
            </div>
        </div>
    );
}
