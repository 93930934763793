import React, { useState, useEffect, useRef } from 'react';

// Iconos
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTable } from "@fortawesome/pro-solid-svg-icons";
import { faList } from "@fortawesome/pro-duotone-svg-icons";
import { faFilePdf } from "@fortawesome/pro-duotone-svg-icons";
import { faPencilAlt } from "@fortawesome/pro-solid-svg-icons";
import { faArrowRotateLeft } from "@fortawesome/pro-solid-svg-icons";
import { faSave } from "@fortawesome/pro-solid-svg-icons";
import { faDownload } from "@fortawesome/pro-solid-svg-icons";

import { Auth, API, Storage } from 'aws-amplify';

// Ruteador
import { Route, Link, useHistory } from "react-router-dom";

// Grid
import DataGrid, {
  Column, FilterRow, HeaderFilter, FilterPanel, FilterBuilderPopup,
  GroupPanel, Grouping, SearchPanel, Sorting, Editing, Lookup, Toolbar, Item, RequiredRule,
  Scrolling, Pager, Paging, Summary, TotalItem, StateStoring
} from 'devextreme-react/data-grid';

import '../ListStyle.css';

export default function PdfTemplate(props) {
  const history = useHistory();

  const [templateRecords, setTemplateRecords] = useState([]);
  const [tipo, setTipo] = useState();

  const allowedPageSizes = [5, 10, 15, 20, 'all'];

  const filterBuilderPopupPosition = {
    of: window,
    at: 'top',
    my: 'top',
    offset: { y: 10 },
  };

  async function getTemplateRecords() {
    try {
      let apiName = 'AdminSC';
      let path = '/procesapdftemplate';

      let myInit = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
        },
        queryStringParameters: {
          action: 'list',
          username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username
        }
      }

      var templatesGet = await API.get(apiName, path, myInit);
    }
    catch (e) {
      if (e === 'No current user') {
        history.push('/');

        window.location.reload();
      }
    }

    return templatesGet;
  }

  async function editTemplateButton(Tipo) {
    var baseUrl = window.location.href.split('/')[0];

    window.open(baseUrl + "/#/PdfEditor?type=" + Tipo, "_blank");
  };

  function editTemplate(data) {
    return (
      <Link to={`/PdfTemplate/${data.data.Tipo}`}>
        <button class="bg-blue-500 hover:bg-blue-700 text-white  py-1 px-3 rounded" onClick={() => editTemplateButton(data.data.Tipo)}><FontAwesomeIcon icon={faPencilAlt} /> Editar</button>
      </Link>
    )
  }

  async function downloadJSONButton(Tipo) {
    let apiNamePayload = 'AdminSC';
    let pathPayload;
    let myInitPayload;

    Tipo = Tipo.toLowerCase();

    if (Tipo === 'vacation') {
      pathPayload = '/procesavacaciones';

      myInitPayload = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
        },
        queryStringParameters: {
          ID: 0,
          action: 'payload'
        }
      };
    }
    else if (Tipo === 'checklistaud') {
      pathPayload = '/procesachecklist';

      myInitPayload = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
        },
        queryStringParameters: {
          personal: '',
          ID: 0,
          action: 'payload',
          mov: 'Check List Aud'
        }
      };
    }
    else if (Tipo === 'checklist') {
      pathPayload = '/procesachecklist';

      myInitPayload = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
        },
        queryStringParameters: {
          personal: '',
          ID: 0,
          action: 'payload',
          mov: 'Check List'
        }
      };
    }
    else if (Tipo === 'checklistveh') {
      pathPayload = '/procesachecklist';

      myInitPayload = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
        },
        queryStringParameters: {
          personal: '',
          ID: 0,
          action: 'payload',
          mov: 'Check List Veh'
        }
      };
    }
    else if (Tipo === 'invoice') {
      pathPayload = '/procesafacturaslote';

      myInitPayload = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
        },
        body: {
          action: 'payload',
          invoices: [{ ID: 0 }]
        }
      };
    }
    else if (Tipo === 'directdelivery') {
      pathPayload = '/procesaentregadirecta';

      myInitPayload = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
        },
        queryStringParameters: {
          ID: 0,
          action: 'payload'
        }
      };
    }
    else if (Tipo === 'deliveryrequest') {
      pathPayload = '/procesasolenvio';

      myInitPayload = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
        },
        queryStringParameters: {
          ID: 0,
          action: 'payload'
        }
      };
    }
    else if (Tipo === 'directreturn') {
      pathPayload = '/procesadevoluciondirecta';

      myInitPayload = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
        },
        queryStringParameters: {
          ID: 0,
          action: 'payload'
        }
      };
    }
    else if (Tipo === 'returnrequest') {
      pathPayload = '/procesasoldevolucion';

      myInitPayload = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
        },
        queryStringParameters: {
          ID: 0,
          action: 'payload'
        }
      };
    }
    else if (Tipo === 'salesordermod') {
      pathPayload = '/procesamodpedido';

      myInitPayload = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
        },
        queryStringParameters: {
          ID: 0,
          action: 'payload'
        }
      };
    }
    else if (Tipo === 'customerpayment') {
      pathPayload = '/procesaanticiposaldo';

      myInitPayload = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
        },
        queryStringParameters: {
          ID: 0,
          action: 'payload'
        }
      };
    }
    else if (Tipo === 'salesorder') {
      pathPayload = '/procesapedidos';

      myInitPayload = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
        },
        queryStringParameters: {
          ID: 0,
          action: 'payload'
        }
      };
    }
    else if (Tipo === 'estimate') {
      pathPayload = '/procesacotizaciones';

      myInitPayload = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
        },
        queryStringParameters: {
          ID: 0,
          action: 'payload'
        }
      };
    }
    else if (Tipo === 'inventorytransfer') {
      pathPayload = '/procesatransferencias';

      myInitPayload = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
        },
        queryStringParameters: {
          ID: 0,
          action: 'payload'
        }
      };
    }
    else if (Tipo === 'shipmentrequest') {
      pathPayload = '/procesasolembarque';

      myInitPayload = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
        },
        queryStringParameters: {
          ID: 0,
          action: 'payload'
        }
      };
    }
    else if (Tipo === 'transferorder') {
      pathPayload = '/procesasoltraspaso';

      myInitPayload = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
        },
        queryStringParameters: {
          ID: 0,
          action: 'payload'
        }
      };
    }
    else if (Tipo === 'invtransferorder') {
      pathPayload = '/procesainvsoltraspaso';

      myInitPayload = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
        },
        queryStringParameters: {
          ID: 0,
          action: 'payload'
        }
      };
    }
    else if (Tipo === 'warehousepositionwms') {
      pathPayload = '/procesaalmacenposicion';

      myInitPayload = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
        },
        queryStringParameters: {
          ID: 0,
          action: 'payload',
          username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username
        }
      };
    }

    if (Tipo === 'labelwms') {
      var payloadGet = [1234567890, 2, 3, 4, 5, 6, 7, 8, 9, 10]
    }
    else {
      if (Tipo !== 'invoice')
        var payloadGet = await API.get(apiNamePayload, pathPayload, myInitPayload);
      else
        var payloadGet = await API.post(apiNamePayload, pathPayload, myInitPayload);
    }


    const url = window.URL.createObjectURL(
      new Blob([JSON.stringify(payloadGet)]),
    );

    const link = document.createElement('a');

    link.href = url;

    link.setAttribute('download', 'JSON.json');

    // Append to html link element page
    document.body.appendChild(link);

    // Start download
    link.click();

    // Clean up and remove the link
    link.parentNode.removeChild(link);
  }

  function downloadJSON(data) {
    return (
      <button class="bg-pink-500 hover:bg-pink-700 text-white  py-1 px-3 rounded" onClick={() => downloadJSONButton(data.data.Tipo)}><FontAwesomeIcon icon={faDownload} /> Descargar JSON</button>
    )
  }

  useEffect(() => {
    async function initialize() {
      //Obtenemos los templates
      var getTemplateList = await getTemplateRecords();

      setTemplateRecords(getTemplateList);
    }

    initialize();
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <div class="bg-gray-200 m-2">
        <h1 class="font-sans inline-block text-xl font-bold text-gray-700 leading-8 align-top mb-1"><FontAwesomeIcon icon={faList} size="lg" /> PDF Templates</h1>
      </div>
      <div class="bg-gray-200 m-2">
        <div class="bg-white p-3 shadow-xl">
          <DataGrid
            height={800}
            id="gridContainer"
            columnsAutoWidth={true}
            dataSource={templateRecords}
            allowColumnReordering={true}
            allowColumnResizing={true}
            columnResizingMode={"widget"}
            showBorders={true}
            rowAlternationEnabled={true}
            showRowLines={true}
            showColumnLines={true}
            keyExpr="Tipo"
          >
            <Scrolling rowRenderingMode='virtual'></Scrolling>
            <Paging defaultPageSize={10} />
            <Pager
              visible={true}
              allowedPageSizes={allowedPageSizes}
              displayMode='compact'
              showPageSizeSelector={true}
              showInfo={true}
              showNavigationButtons={true}
            />
            <FilterRow visible={true} />
            <FilterPanel visible={true} />
            <FilterBuilderPopup position={filterBuilderPopupPosition} />

            <HeaderFilter visible={true} />

            <GroupPanel visible={true} />
            <SearchPanel visible={true} />

            <StateStoring enabled={true} type="localStorage" storageKey="gridContainerPDFTemplate" />

            <Column caption="" alignment="center" width={100} cellRender={editTemplate} />

            <Column dataField="Tipo" dataType="string" width={300} />

            <Column caption="" alignment="center" width={200} cellRender={downloadJSON} />
          </DataGrid>
        </div>
      </div>
    </div>
  )
};