import "@aws-amplify/ui-react/styles.css";

import {
    Authenticator,
    ThemeProvider,
    Theme,
    useTheme,
    View,
    Image,
    Text,
    Heading,
    Button,
    useAuthenticator

} from '@aws-amplify/ui-react';

import { Auth } from 'aws-amplify';

import { I18n } from 'aws-amplify';

import { translations } from '@aws-amplify/ui-react';

import '@aws-amplify/ui-react/styles.css';

import './Signin.css';

I18n.putVocabularies(translations);

I18n.setLanguage('es');

I18n.putVocabularies({
    es: {
        'Sign In': 'Iniciar sesión',
        'Please fill out this field.': 'Favor de introducir este campo'
    }
});

const Signin = (props) => {
    const formFields = {
        signIn: {
            username: {
                labelHidden: false,
                label: 'Usuario:',
                placeholder: 'Introduzca su usuario',
                isRequired: true,
                order: 1
            },
            password: {
                labelHidden: false,
                label: 'Contraseña:',
                placeholder: 'Introduzca su contraseña:',
                isRequired: true,
                order: 2,
            }
        }
    };

    const components = {
        Header() {
            const { tokens } = useTheme();

            return (
                <View textAlign="center" padding={tokens.space.large}>
                </View>
            );
        },
        Footer() {
            const { tokens } = useTheme();

            return (
                <View textAlign="center" padding={tokens.space.large}>
                </View>
            );
        },

        SignIn: {
            Header() {
                const { tokens } = useTheme();

                return (
                    <Heading
                        padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
                        level={4}
                    >
                        Inicio de sesión
                    </Heading>
                );
            },
            Footer() {
                const { toResetPassword } = useAuthenticator();

                return (
                    <View textAlign="center">
                    </View>
                );
            },
        }
    };

    const services = {
        async handleSignIn(formData) {
            let { username, password } = formData;

            return Auth.signIn(username, password)
                .then((user) => {
                    props.handleAuthStateChange('signedin');
                })
        }
    };

    return (
        <div>
            {props.status === 'signedout' ?
                <Authenticator
                    formFields={formFields}
                    components={components}
                    loginMechanisms={['username']}
                    hideSignUp
                >
                </Authenticator>
                :
                <div>
                </div>
            }
        </div>
    )
}

export default Signin;