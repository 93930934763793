import React, { Component } from "react";

import { Auth, API } from 'aws-amplify';

import './Pricelist.css';


async function getPricelist(sucursal) {
  let apiName = 'AdminSC';
  let path = '/listaarticulos';

  let myInit = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
    },
    body: {
      sucursal: sucursal,
      username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username
    }
  }

  var itemsGet = await API.post(apiName, path, myInit);

  return itemsGet;
}

class Pricelist extends Component {
  constructor(props) {

    super(props);

    this.state = {
      items: [],
      allitems: [],
      headers: [],
      listNames: [],
      searchItem: '',
      selectedItem: '',
      sucursal: props.sucursal
    };

    this.handleSearch = this.handleSearch.bind(this);
    this.columnaClave = this.columnaClave.bind(this);
  }

  async componentDidMount() {

    // Para poder leer los props agregamos este renglon
    const _this = this;

    const items = await getPricelist(_this.props.sucursal);

    var headers = [];
    var listNames = [];

    var keys;

    //Armamos el array con los encabezados
    for (var itemIndex in items) {
      var singleItem = items[itemIndex];

      if (singleItem.Orden === 0) {

        //Borramos el renglon con los encabezados para que no se duplique abajo
        delete items[itemIndex];

        //Primero insertamos los encabezados fijos
        headers.push(singleItem['Articulo']);
        headers.push(singleItem['Descripcion1']);

        try {
          keys = Object.keys(singleItem);
        }
        catch (e) {
          keys = [];
        }

        //Después insertamos los nombres de las listas de precios
        for (var keyIndex in keys) {
          var key = keys[keyIndex];

          if (!key)
            key = '';

          if (key !== 'Categoria' && key !== 'ImagenURL' && key !== 'Grupo' && key !== 'Articulo' && key !== 'Descripcion1' && key !== 'NombreCorto' && key !== 'Orden' && key !== 'NoListas') {
            headers.push(key);
            listNames.push(key);
          }
        }
      }
    }

    this.setState({
      items: items,
      allitems: items,
      headers: headers,
      listNames: listNames,
      searchItem: '',
      selectedItem: ''
    });
  }

  handleSearch(event) {
    var keyword = event.target.value.toLowerCase();

    if (!keyword)
      keyword = '';

    var allitems = this.state.allitems.slice();
    var items = [];

    if (keyword) {
      for (var itemIndex in allitems) {
        var singleItem = allitems[itemIndex];

        if (!singleItem.Articulo)
          singleItem.Articulo = '';
        if (!singleItem.Descripcion1)
          singleItem.Descripcion1 = '';
        if (!singleItem.Categoria)
          singleItem.Categoria = '';
        if (!singleItem.Grupo)
          singleItem.Grupo = '';

        if (singleItem.Articulo.toLowerCase().indexOf(keyword) >= 0 || singleItem.Descripcion1.toLowerCase().indexOf(keyword) >= 0 || singleItem.Categoria.toLowerCase().indexOf(keyword) >= 0 || singleItem.Grupo.toLowerCase().indexOf(keyword) >= 0)
          items.push(singleItem);
      }
    }
    else {
      items = allitems;
    }

    this.setState({ items: items, searchItem: keyword });
  };

  columnaClave(item) {
    switch (item.Orden) {
      case 1:
        return <td className="px-4 py-3 text-xs font-semibold border"><img width="100" height="110" src={item.ImagenURL} alt="Proveedor" /></td>;
      case 2:
        return <td className="px-4 py-3 text-xs font-bold border bg-gray-200">{item.Grupo}</td>;
      case 3:
        return <td className="px-4 py-3 text-xs font-semibold border">{item.Articulo}</td>;
      default:
        return <td className="px-4 py-3 text-xs font-semibold border"></td>;
    }
  };

  render() {
    return (
      <section className="container mx-auto p-6">
        <div className="relative flex w-full flex-wrap items-stretch mb-3">
          <input type="text" value={this.state.searchItem} onChange={this.handleSearch} placeholder="Buscar producto" className="px-3 py-3 placeholder-blueGray-300 text-blueGray-600 relative bg-white bg-white rounded text-sm border-0 shadow outline-none focus:outline-none focus:ring w-full pr-10" />
          <button type="submit" className="absolute right-0 top-0 mt-3 mr-4">
            <svg className="h-4 w-4 fill-current" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 56.966 56.966" width="512px" height="512px" >
              <path d="M55.146,51.887L41.588,37.786c3.486-4.144,5.396-9.358,5.396-14.786c0-12.682-10.318-23-23-23s-23,10.318-23,23  s10.318,23,23,23c4.761,0,9.298-1.436,13.177-4.162l13.661,14.208c0.571,0.593,1.339,0.92,2.162,0.92  c0.779,0,1.518-0.297,2.079-0.837C56.255,54.982,56.293,53.08,55.146,51.887z M23.984,6c9.374,0,17,7.626,17,17s-7.626,17-17,17  s-17-7.626-17-17S14.61,6,23.984,6z" />
            </svg>
          </button>
          <span className="z-10 h-full leading-snug font-normal absolute text-center text-blueGray-300 absolute bg-transparent rounded text-base items-center justify-center w-8 right-0 pr-3 py-3">
            <i className="fas fa-user"></i>
          </span>
        </div>
        <div className="w-full mb-8 overflow-hidden rounded-lg shadow-lg">
          <div className="w-full overflow-x-auto">
            <table className="w-full">
              <thead>
                <tr className="text-xs font-semibold tracking-wide text-left text-gray-900 bg-gray-300 uppercase border-b border-gray-600">

                  {
                    this.state.headers.map((header, index) => (
                      <th className="px-4 py-3">{header}</th>
                    ))
                  }

                </tr>
              </thead>
              <tbody className="bg-white">
                {
                  this.state.items.map((item, index) => (
                    <tr key={index} class="text-gray-700">
                      {this.columnaClave(item)}

                      {item.Orden === 2 ? (<td className="bg-gray-200 px-4 py-3 text-xs font-semibold border">{item.Descripcion1}</td>) : (<td className="px-4 py-3 text-xs font-semibold border">{item.Descripcion1}</td>)}

                      {
                        this.state.listNames.map((list, index) => (
                          item.Orden === 2 ? (<td className="bg-gray-200 px-4 py-3 text-xs font-semibold border">{item[list]}</td>) : (<td className="px-4 py-3 text-xs font-semibold border">{item[list]}</td>)
                        ))
                      }


                    </tr>
                  ))
                }

              </tbody>
            </table>
          </div>
        </div>
      </section>
    )
  }
}

export default Pricelist