import React, { useState, useEffect, useRef, useMemo } from 'react';

// LoadPanel
import { LoadPanel } from 'devextreme-react/load-panel';

// Toast
import { Toast } from 'devextreme-react/toast';

//Form
import Form, { GroupItem, Label, Item } from 'devextreme-react/form';


import { Auth, API, Storage } from 'aws-amplify';

// Ruteador
import { Route, Link, useHistory } from "react-router-dom";

// Iconos
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/pro-solid-svg-icons";
import { faSort } from "@fortawesome/pro-duotone-svg-icons";

import 'devextreme/data/odata/store';
import CustomStore from 'devextreme/data/custom_store';

import '../ListStyle.css';

import dxDateBox from "devextreme/ui/date_box";

import dxNumberBox from "devextreme/ui/number_box";

import dxSlider from "devextreme/ui/slider";

dxDateBox.defaultOptions({
    options: {
        dateSerializationFormat: "yyyy-MM-dd"
    }
});

dxNumberBox.defaultOptions({
    options: {
        step: 0
    }
});

function isNotEmpty(value) {
    return value !== undefined && value !== null && value !== '';
}

export default function FulfillmentOrderWMSPriority(props) {
    const history = useHistory();

    const [fulfillmentOrderWMSPriorityPayload, setFulfillmentOrderWMSPriorityPayload] = useState({
        header: {}
    });

    const [visibleLoadPanel, setVisibleLoadPanel] = useState(false);

    const [visibleToast, setVisibleToast] = useState(false);
    const [messageToast, setMessageToast] = useState('');
    const [typeToast, setTypeToast] = useState('success');

    const [activateSaveButton, setActivateSaveButton] = useState(false);

    //Ref para el form
    const [formDatosGeneralesRef, setFormDatosGeneralesRef] = useState(React.createRef());

    async function getfulfillmentOrderWMSPriorityPayload() {
        try {
            let apiName = 'AdminSC';
            let path = '/procesaprioridadsurtido';

            let myInit = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
                },
                queryStringParameters: {
                    action: 'payload',
                    username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username
                }
            }

            var payloadGet = await API.get(apiName, path, myInit);

            setFulfillmentOrderWMSPriorityPayload(payloadGet);
        }
        catch (e) {
            if (e === 'No current user') {
                history.push('/');

                window.location.reload();
            }
        }

        return payloadGet;
    }

    async function saveButton() {
        try {
            setVisibleLoadPanel(true);

            var ID = fulfillmentOrderWMSPriorityPayload.header.ID;

            //Despues enviamos todo al backend
            let apiName = 'AdminSC';
            let path = '/procesaprioridadsurtido';

            let myInit = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
                },
                body: {
                    payload: fulfillmentOrderWMSPriorityPayload,
                    action: 'saveChanges',
                    username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username
                }
            }

            var payloadPost = await API.post(apiName, path, myInit);
            
            if (payloadPost.ok === 'true') {
                setVisibleToast(true);
                setMessageToast('Se actualizó la Prioridad de Surtido con éxito');
                setTypeToast('success')
            }
            else if (payloadPost.ok === 'false') {
                setVisibleToast(true);
                setMessageToast(payloadPost.message);
                setTypeToast('error')
            }

            setVisibleLoadPanel(false);
        }
        catch (e) {
            setVisibleLoadPanel(false);

            if (e === 'No current user') {
                history.push('/');

                window.location.reload();
            }
        }
    };

    useEffect(() => {
        async function initialize() {
            setVisibleLoadPanel(true);

            await getfulfillmentOrderWMSPriorityPayload();

            setVisibleLoadPanel(false);
        }

        initialize();

        // eslint-disable-next-line
    }, []);

    function onHidingToast() {
        setVisibleToast(false);
    };

    var sliderEditorOptions = useMemo(() => {
        return {
            width: '100%',
            readOnly: false,
            tooltip: {
                enabled: true,
                position: "bottom",
                showMode: "onHover"
            },
            min: 1,
            max: 10,
            showRange: false
        };
    }, []);

    return (
        <div>
            <div class="bg-gray-200 m-2">
                <h1 class="font-sans inline-block text-xl font-bold text-gray-700 leading-8 align-top mb-1"><FontAwesomeIcon icon={faSort} size="xl" /> Prioridad Surtido WMS</h1>
            </div>
            <LoadPanel
                shadingColor="rgba(0,0,0,0.4)"
                visible={visibleLoadPanel}
                showIndicator={true}
                message="Por favor espere..."
                shading={true}
                showPane={true}
                hideOnOutsideClick={false}
            />
            <Toast
                visible={visibleToast}
                message={messageToast}
                type={typeToast}
                onHiding={onHidingToast}
                displayTime={5000}
                width={'auto'}
                position={{
                    my: 'top center',
                    at: 'top center',
                    of: window,
                    offset: '0 130'
                }}
            />
            <div class="bg-gray-200 m-2">
                <div>
                    <button onClick={saveButton} type="submit" class="mb-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                        <FontAwesomeIcon icon={faSave} /> Guardar Cambios
                    </button>
                </div>

                <div class="bg-white p-4 shadow-xl">
                    <Form
                        id="formTransaccion"
                        labelMode={'outside'}
                        formData={fulfillmentOrderWMSPriorityPayload.header}
                        readOnly={false}
                        showColonAfterLabel={false}
                        labelLocation={'top'}
                        ref={formDatosGeneralesRef}
                    >
                        <GroupItem>
                            <Item dataField="EntregaDirecta" editorType="dxSlider" editorOptions={sliderEditorOptions}>
                                <Label text={"Entrega Directa"} />
                            </Item>
                            <Item dataField="SolicitudEmbarque" editorType="dxSlider" editorOptions={sliderEditorOptions}>
                                <Label text={"Solicitud Embarque"} />
                            </Item>
                            <Item dataField="SolicitudTraspaso" editorType="dxSlider" editorOptions={sliderEditorOptions}>
                                <Label text={"Solicitud Traspaso"} />

                            </Item>
                            <Item dataField="Transferencia" editorType="dxSlider" editorOptions={sliderEditorOptions}>
                                <Label text={"Transferencia"} />
                            </Item>
                            <Item dataField="DevolucionCompra" editorType="dxSlider" editorOptions={sliderEditorOptions}>
                                <Label text={"Devolucion Compra"} />
                            </Item>
                        </GroupItem>
                    </Form >
                </div >
            </div>
        </div>
    );
}
