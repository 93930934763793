import React, { useState, useEffect, useRef } from 'react';

// LoadPanel
import { LoadPanel } from 'devextreme-react/load-panel';

// Grid
import DataGrid, {
  Column, FilterRow, HeaderFilter, FilterPanel, FilterBuilderPopup,
  GroupPanel, Grouping, SearchPanel, Sorting, Editing, Lookup, Toolbar, Item, RequiredRule,
  Summary, TotalItem, GroupItem, Scrolling, Pager, Paging, StateStoring, ColumnChooser, Selection,
  ColumnChooserSearch, ColumnChooserSelection
} from 'devextreme-react/data-grid';

//CheckBox
import CheckBox from 'devextreme-react/check-box';

//Chart
import {
  Chart, SeriesTemplate, CommonSeriesSettings, Title, Tooltip, Format, Export
} from 'devextreme-react/chart';

import { Auth, API } from 'aws-amplify';

// Ruteador
import { Route, Link, useHistory } from "react-router-dom";

// Iconos
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGlasses } from "@fortawesome/pro-solid-svg-icons";
import { faArrowRotateLeft } from "@fortawesome/pro-solid-svg-icons";
import { faSave } from "@fortawesome/pro-solid-svg-icons";
import { faClipboardCheck } from "@fortawesome/pro-solid-svg-icons";
import { faList } from "@fortawesome/pro-duotone-svg-icons";
import { faSackDollar } from "@fortawesome/pro-duotone-svg-icons";

import './Commission.css';

//Form
import Form, { TabbedItem, TabPanelOptions, Tab } from 'devextreme-react/form';

import SelectBox from 'devextreme-react/select-box';

import '../ListStyle.css';

import dxDateBox from "devextreme/ui/date_box";

import dxNumberBox from "devextreme/ui/number_box";

import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
import { exportDataGrid } from 'devextreme/excel_exporter'

dxDateBox.defaultOptions({
  options: {
    dateSerializationFormat: "yyyy-MM-dd"
  }
});

dxNumberBox.defaultOptions({
  options: {
    step: 0
  }
});

export default function Commission(props) {
  const history = useHistory();

  const [commissionRecords, setCommissionRecords] = useState([]);
  const [commissionTotalRecords, setCommissionTotalRecords] = useState([]);
  const [chartTotalRecords, setChartTotalRecords] = useState([]);
  const [commissionTotalYear, setCommissionTotalYear] = useState([]);
  const [isDetail, setIsDetail] = useState(false);
  const [commissionPayload, setCommissionPayload] = useState({ header: {}, detail: [] });

  const [autoExpandAllLista, setAutoExpandAllLista] = useState(true);
  const [autoExpandAllTotal, setAutoExpandAllTotal] = useState(true);

  // setValue para el control tab
  const [value, setValue] = React.useState(0);

  const [visibleLoadPanel, setVisibleLoadPanel] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const allowedPageSizes = [5, 10, 15, 20, 'all'];

  const exportFormats = ['xlsx'];

  const filterBuilderPopupPosition = {
    of: window,
    at: 'top',
    my: 'top',
    offset: { y: 10 },
  };

  function getOrderDay(rowData) {
    return (new Date(rowData.OrderDate)).getDay();
  }

  const filterBuilder = {
    customOperations: [{
      name: 'weekends',
      caption: 'Weekends',
      dataTypes: ['date'],
      icon: 'check',
      hasValue: false,
      calculateFilterExpression: () => [[getOrderDay, '=', 0], 'or', [getOrderDay, '=', 6]],
    }],
    allowHierarchicalFields: true,
  };

  const filterValue = [['Estatus', '=', 'Concluido']];


  async function getCommissionRecords() {
    try {
      var personal = props.personal;

      let apiName = 'AdminSC';
      let path = '/procesacomisiones';

      let myInit = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
        },
        queryStringParameters: {
          personal: personal,
          action: 'list',
          username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username
        }
      }

      var commissionsGet = await API.get(apiName, path, myInit);
    }
    catch (e) {
      if (e === 'No current user') {
        history.push('/');

        window.location.reload();
      }
    }

    return commissionsGet;
  }

  async function getCommissionPayload(ID) {
    try {
      var personal = props.personal;

      let apiName = 'AdminSC';
      let path = '/procesacomisiones';

      let myInit = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
        },
        queryStringParameters: {
          Personal: personal,
          ID: ID,
          action: 'payload',
          username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username
        }
      }

      var payloadGet = await API.get(apiName, path, myInit);

      setCommissionPayload(payloadGet);
    }
    catch (e) {
      if (e === 'No current user') {
        history.push('/');

        window.location.reload();
      }
    }

    return payloadGet;
  }

  async function viewCommissionButton(ID) {
    if (!window.event.metaKey && !window.event.ctrlKey) {
      setVisibleLoadPanel(true);

      setIsDetail(true);

      var payload = await getCommissionPayload(ID);

      setVisibleLoadPanel(false);
    }
  };

  async function cancelViewButton() {
    setIsDetail(false);

    var getCommission = await getCommissionRecords(props.personal);

    setCommissionRecords(getCommission.list);
    setCommissionTotalRecords(getCommission.total);

    // // Obtenemos los años para el filtro de la gráfica
    var years = JSON.parse(JSON.stringify(commissionTotalYear));

    for (var commissionTotalIndex in getCommission.total) {
      var singleCommissionTotal = getCommission.total[commissionTotalIndex];

      var exists = false;
      for (var yearsIndex in years) {
        var singleYears = years[yearsIndex];

        if (singleYears === singleCommissionTotal.anio)
          exists = true;
      }

      if (exists === false) {
        years.push(singleCommissionTotal.anio)
      }
    }

    setCommissionTotalYear(years);

    var year = new Date().getFullYear();
    var chartPayloadtmp = [];


    for (var commissionTotalRecordsIndex in commissionTotalRecords) {
      var singleCommissionTotalRecord = commissionTotalRecords[commissionTotalRecordsIndex];

      if (singleCommissionTotalRecord.anio === year) {
        chartPayloadtmp.push(singleCommissionTotalRecord);
      }
    }

    setChartTotalRecords(chartPayloadtmp);
  };

  function onAutoExpandAllListaChanged() {
    setAutoExpandAllLista(!autoExpandAllLista)
  };

  function onAutoExpandAllTotalChanged() {
    setAutoExpandAllTotal(!autoExpandAllTotal)
  };

  function viewCommission(data) {
    return (
      <Link to={`/Commission/${data.data.ID}`}>
        <button class="bg-blue-500 hover:bg-blue-700 text-white  py-1 px-3 rounded" onClick={() => viewCommissionButton(data.data.ID)}><FontAwesomeIcon icon={faGlasses} /> Detalle</button>
      </Link>
    )
  }

  function onValueChangedAnio(e) {
    var year = e.value;
    var chartPayloadtmp = [];


    for (var commissionTotalRecordsIndex in commissionTotalRecords) {
      var singleCommissionTotalRecord = commissionTotalRecords[commissionTotalRecordsIndex];

      if (singleCommissionTotalRecord.anio === year) {
        chartPayloadtmp.push(singleCommissionTotalRecord);
      }
    }

    setChartTotalRecords(chartPayloadtmp);
  }

  var fechaEmisionEditorOptions = { width: '100%', readOnly: true, text: 'Fecha Emisión' };
  var movimientoEditorOptions = { width: '100%', readOnly: true };
  var estatusEditorOptions = { width: '100%', readOnly: true };
  var nombreEditorOptions = { width: '100%', readOnly: true };
  var personalAsignadoEditorOptions = { width: '100%', readOnly: true };
  var nombreSupervisorEditorOptions = { width: '100%', readOnly: true };
  var inicioEvaluacionEditorOptions = { width: '100%', readOnly: true };
  var finEvaluacionEditorOptions = { width: '100%', readOnly: true };
  var duracionEvaluacionEditorOptions = { width: '100%', readOnly: true };

  useEffect(() => {
    window.addEventListener('popstate', (e) => {
      var hash;

      try {
        hash = e.target.location.hash;
      }
      catch (e) {
        hash = '';
      }

      if (hash === '#/Commission') {
        history.push('/Commission');

        window.location.reload();
      }
    });

    async function initialize() {
      var years = JSON.parse(JSON.stringify(commissionTotalYear));

      //Obtenemos sus comisiones asignadas
      var getCommissionList = await getCommissionRecords(props.personal);

      setCommissionRecords(getCommissionList.list);
      setCommissionTotalRecords(getCommissionList.total);

      // Obtenemos el listado de años 
      for (var commissionTotalIndex in getCommissionList.total) {
        var singleCommissionTotal = getCommissionList.total[commissionTotalIndex];

        var exists = false;
        for (var yearsIndex in years) {
          var singleYears = years[yearsIndex];

          if (singleYears === singleCommissionTotal.anio)
            exists = true;
        }

        if (exists === false) {
          years.push(singleCommissionTotal.anio)
        }
      }

      setCommissionTotalYear(years);

      // Especificamos los datos para la gráfica
      var year = new Date().getFullYear();
      var chartPayloadtmp = [];

      for (var commissionTotalRecordsIndex in getCommissionList.total) {
        var singleCommissionTotalRecord = getCommissionList.total[commissionTotalRecordsIndex];

        if (singleCommissionTotalRecord.anio === year) {
          chartPayloadtmp.push(singleCommissionTotalRecord);
        }
      }

      setChartTotalRecords(chartPayloadtmp);
    }

    initialize();

    //En caso de solicitar un ID especifico, redireccionamos a ese ID
    if (!props.match.isExact) {
      var urlID = props.location.pathname.replace('/Commission/', '');

      viewCommissionButton(urlID);
    }

    // eslint-disable-next-line
  }, []);

  function onExporting(e) {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Datos');

    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Datos.xlsx');
      });
    });
    e.cancel = true;
  };

  return (
    <div>
      {isDetail === false ? (
        <div class="bg-gray-200 m-2">
          <h1 class="font-sans inline-block text-xl font-bold text-gray-700 leading-8 align-top mb-1"><FontAwesomeIcon icon={faList} size="lg" /> Comisiones</h1>
        </div>
      ) :
        (
          <div class="bg-gray-200 m-2">
            <h1 class="font-sans inline-block text-xl font-bold text-gray-700 leading-8 align-top mb-1"><FontAwesomeIcon icon={faSackDollar} size="xl" /> {commissionPayload.header.Movimiento}</h1>
          </div>
        )}

      <LoadPanel
        shadingColor="rgba(0,0,0,0.4)"
        visible={visibleLoadPanel}
        showIndicator={true}
        message="Por favor espere..."
        shading={true}
        showPane={true}
        hideOnOutsideClick={false}
      />

      <div class="bg-gray-200 m-2">
        {isDetail === false ? (
          <div class="bg-white p-3 shadow-xl">
            <Form>
              <TabbedItem>
                <TabPanelOptions deferRendering={false} showNavButtons={true} />
                <Tab title="Lista Comisiones">
                  <div className="options mb-2">
                    <div className="option">
                      <CheckBox text="Expandir todos los grupos"
                        value={autoExpandAllLista}
                        onValueChanged={onAutoExpandAllListaChanged}></CheckBox>
                    </div>
                  </div>
                  <DataGrid
                    id="gridLista"
                    columnsAutoWidth={true}
                    filterBuilder={filterBuilder}
                    dataSource={commissionRecords}
                    allowColumnReordering={true}
                    allowColumnResizing={true}
                    columnResizingMode={"widget"}
                    showBorders={true}
                    rowAlternationEnabled={true}
                    showRowLines={true}
                    showColumnLines={true}
                    keyExpr="ID"
                  >
                    <Scrolling rowRenderingMode='virtual'></Scrolling>
                    <Paging defaultPageSize={10} />
                    <Pager
                      visible={true}
                      allowedPageSizes={allowedPageSizes}
                      displayMode='compact'
                      showPageSizeSelector={true}
                      showInfo={true}
                      showNavigationButtons={true} />
                    <FilterRow visible={true} />
                    <FilterPanel visible={true} />
                    <FilterBuilderPopup position={filterBuilderPopupPosition} />

                    <HeaderFilter visible={true} />

                    <GroupPanel visible={true} />
                    <SearchPanel visible={true} />
                    <Grouping autoExpandAll={autoExpandAllLista} />
                    <StateStoring enabled={true} type="localStorage" storageKey="gridContainerComision" />

                    <Column caption="" alignment="center" width={100} cellRender={viewCommission} />

                    <Column dataField="Movimiento" dataType="string" width={190} />
                    <Column dataField="anio" caption="Año" dataType="string" width={200} groupIndex={0} />
                    <Column dataField="mes" caption="Mes" dataType="string" width={200} />
                  </DataGrid>
                </Tab>
                <Tab title="Total por mes">
                  <div className="options mb-2">
                    <div className="option">
                      <CheckBox text="Expandir todos los grupos"
                        value={autoExpandAllTotal}
                        onValueChanged={onAutoExpandAllTotalChanged}></CheckBox>
                    </div>
                  </div>
                  <DataGrid
                    id="gridTotales"
                    columnsAutoWidth={true}
                    filterBuilder={filterBuilder}
                    dataSource={commissionTotalRecords}
                    // defaultFilterValue={filterValue}
                    allowColumnReordering={true}
                    showBorders={true}

                    showColumnLines={true}
                    showRowLines={true}
                    onExporting={onExporting}
                  >
                    <FilterRow visible={true} />
                    <FilterPanel visible={true} />
                    <FilterBuilderPopup position={filterBuilderPopupPosition} />

                    <HeaderFilter visible={true} />

                    <GroupPanel visible={true} />
                    <SearchPanel visible={true} />
                    <Grouping autoExpandAll={autoExpandAllTotal} />
                    <Export enabled={true} />

                    <Column dataField="anio" caption="Año" dataType="string" width={200} groupIndex={0} />
                    <Column dataField="mes" caption="Mes" dataType="string" width={200} />
                    <Column dataField="Comision" caption="Comisión" dataType="number" width={250}>
                      <Format
                        type="currency"
                        precision={2}
                      />
                    </Column>
                    <Summary>
                      <GroupItem
                        column="Comision"
                        summaryType="sum"
                        valueFormat="$,##0.##"
                        displayFormat="Total Comisiones {0}"
                        showInGroupFooter={false}>
                      </GroupItem>
                      <TotalItem
                        column="Comision"
                        summaryType="sum"
                        valueFormat="$,##0.##"
                        displayFormat="Total Comisiones {0}"
                        showInColumn="Comision">
                      </TotalItem>
                    </Summary>
                  </DataGrid>
                </Tab>
                <Tab title="Gráfica Total por mes">
                  <div class="w-1/4">
                    <div>Año
                      <SelectBox
                        id="anio"
                        dataSource={commissionTotalYear}
                        defaultValue={new Date().getFullYear()}
                        onValueChanged={onValueChangedAnio} />
                    </div>
                  </div>
                  <div>
                    <Chart
                      id="chartComisiones"
                      palette="Harmony Light"
                      dataSource={chartTotalRecords}>
                      <CommonSeriesSettings
                        argumentField="mes"
                        valueField="Comision"
                        type="bar"
                        ignoreEmptyPoints={true}
                      />
                      <SeriesTemplate nameField="mes" />
                      <Title
                        text="Total de Comisiones por Mes"
                      />
                      <Tooltip enabled location="edge">
                        <Format
                          type="currency"
                          precision={2}
                        />
                      </Tooltip>
                      <Export enabled={true} />
                    </Chart>
                  </div>
                </Tab>
              </TabbedItem>
            </Form>
          </div>
        ) : (<div />)}

        <Route exact path="/Commission/:ID" render={({ match }) => {
          return (
            <div class="bg-gray-200">
              {isDetail.toString() === 'true' ?
                (
                  <div>
                    <Link to="/Commission">
                      <button onClick={cancelViewButton} type="submit" class="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
                        <FontAwesomeIcon icon={faArrowRotateLeft} /> Regresar
                      </button>
                    </Link>

                    <div class="mt-4 bg-white p-4 shadow-xl">
                      <DataGrid
                        id="gridDetalle"
                        columnsAutoWidth={true}
                        filterBuilder={filterBuilder}
                        dataSource={commissionPayload.detail}
                        // defaultFilterValue={filterValue}
                        allowColumnReordering={true}
                        showBorders={true}
                        rowAlternationEnabled={true}
                        allowColumnResizing={true}
                        columnResizingMode={"widget"}
                        onExporting={onExporting}
                      >
                        <FilterRow visible={true} />
                        <FilterPanel visible={true} />
                        <FilterBuilderPopup position={filterBuilderPopupPosition} />

                        <HeaderFilter visible={true} />

                        <GroupPanel visible={true} />
                        <SearchPanel visible={true} />
                        <ColumnChooser
                          enabled={true}
                          mode='select'
                        >
                          <ColumnChooserSearch
                            enabled={true}
                          />

                          <ColumnChooserSelection
                            allowSelectAll={true}
                            selectByClick={true}
                            recursive={true}
                          />
                        </ColumnChooser>
                        <Grouping autoExpandAll={true} />
                        <StateStoring enabled={true} type="localStorage" storageKey="gridDetalleComision" />
                        <Export enabled={true} />

                        <Column dataField="Pedido" caption="Número Pedido" dataType="string" width={200} />
                        <Column dataField="Fecha" caption="Fecha" dataType="date" width={200} />
                        <Column dataField="Factura" caption="Factura" dataType="string" width={200} />
                        <Column dataField="NotaCredito" caption="Nota Crédito" dataType="string" width={200} />
                        <Column dataField="Comision" caption="Comisión" dataType="number" width={250}>
                          <Format
                            type="currency"
                            precision={2}
                          />
                        </Column>
                        <Column dataField="FechaPedido" caption="Fecha Pedido" dataType="date" width={200} />
                        <Summary>
                          <GroupItem
                            column="Comision"
                            summaryType="sum"
                            valueFormat="$,##0.##"
                            displayFormat="Total Comisiones {0}"
                            showInGroupFooter={false} />
                          <TotalItem
                            column="Comision"
                            summaryType="sum"
                            valueFormat="$,##0.##"
                            displayFormat="Total Comisiones {0}"
                            showInColumn="Comision" />
                        </Summary>
                      </DataGrid>
                    </div>
                  </div>
                ) :
                (<div></div>)
              }
            </div>
          );
        }}
        />
      </div>
    </div>
  );
}