import React, { useState, useEffect, useMemo } from 'react';

// LoadPanel
import { LoadPanel } from 'devextreme-react/load-panel';

// Toast
import { Toast } from 'devextreme-react/toast';

//Form
import Form, { GroupItem, Label, TabbedItem, TabPanelOptions, Tab } from 'devextreme-react/form';

import { Auth, API, Storage } from 'aws-amplify';

// Ruteador
import { Route, Link, useHistory } from "react-router-dom";

// Grid
import DataGrid, {
    Column, FilterRow, HeaderFilter, FilterPanel, FilterBuilderPopup,
    GroupPanel, Grouping, SearchPanel, Sorting, Editing, Lookup, Item, RequiredRule,
    Scrolling, Pager, Paging, Summary, TotalItem, StateStoring, ColumnChooser, Format,
    Export, ColumnChooserSearch, ColumnChooserSelection
} from 'devextreme-react/data-grid';

//CheckBox
import CheckBox from 'devextreme-react/check-box';

// Iconos
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGlasses } from "@fortawesome/pro-solid-svg-icons";
import { faPencilAlt } from "@fortawesome/pro-solid-svg-icons";
import { faArrowRotateLeft } from "@fortawesome/pro-solid-svg-icons";
import { faList } from "@fortawesome/pro-duotone-svg-icons";
import { faFilePdf } from "@fortawesome/pro-solid-svg-icons";
import { faArrowsRotate } from "@fortawesome/pro-solid-svg-icons";
import { faFileInvoiceDollar } from "@fortawesome/pro-duotone-svg-icons";
import { faArrowUpRightFromSquare } from "@fortawesome/pro-solid-svg-icons";
import { faFiles } from "@fortawesome/pro-solid-svg-icons";
import { faEnvelope } from "@fortawesome/pro-solid-svg-icons";
import { faPlay } from "@fortawesome/pro-solid-svg-icons";
import { faTrash } from "@fortawesome/pro-solid-svg-icons";

import 'devextreme/data/odata/store';
import CustomStore from 'devextreme/data/custom_store';

import DataSource from 'devextreme/data/data_source';

import '../ListStyle.css';

//FileUploader
import FileUploader from 'devextreme-react/file-uploader';

//FileManager
import FileManager, { Permissions } from 'devextreme-react/file-manager';

import dxDateBox from "devextreme/ui/date_box";

import dxNumberBox from "devextreme/ui/number_box";

import TransactionStatus from "../TransactionStatus/TransactionStatus.js";

import Lambda from 'aws-sdk/clients/lambda';

import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
import { exportDataGrid } from 'devextreme/excel_exporter'

dxDateBox.defaultOptions({
    options: {
        dateSerializationFormat: "yyyy-MM-dd"
    }
});

dxNumberBox.defaultOptions({
    options: {
        step: 0
    }
});

function isNotEmpty(value) {
    return value !== undefined && value !== null && value !== '';
}

export default function Invoice(props) {
    const history = useHistory();

    const [isDetail, setIsDetail] = useState(false);
    const [id, setId] = useState();
    const [permissionLevel] = useState(props.nivel);
    const [invoicePayload, setInvoicePayload] = useState({
        header: {},
        detail: [],
        relatedRecords: [],
        movEstatusLog: []
    });

    const [autoExpandAll, setAutoExpandAll] = useState(true);

    const [visibleLoadPanel, setVisibleLoadPanel] = useState(false);

    const [visibleToast, setVisibleToast] = useState(false);
    const [messageToast, setMessageToast] = useState('');
    const [typeToast, setTypeToast] = useState('success');

    const [sucursal] = useState(props.sucursal);
    const [agentes, setAgentes] = useState([]);
    const [listaPrecios, setListaPrecios] = useState([]);
    const [conceptos, setConceptos] = useState([]);
    const [condiciones, setCondiciones] = useState([]);

    const [activateConfirmButton, setActivateConfirmButton] = useState(false);
    const [activateDeleteButton, setActivateDeleteButton] = useState(false);
    const [activatePDFButton, setActivatePDFButton] = useState(false);
    const [activateRegenerateCFDi, setActivateRegenerateCFDi] = useState(false);
    const [activateSendCFDi, setActivateSendCFDi] = useState(false);
    const [activateAttachments, setActivateAttachments] = useState(false);

    const [regenerateCFDi] = useState(props.regenerateCFDi);
    const [sendCFDi] = useState(props.sendCFDi);

    //Ref para el form
    const [formDatosGeneralesRef] = useState(React.createRef());

    //Ref para el grid Articulos
    const [gridArticulosRef] = useState(React.createRef());

    const [attachments, setAttachments] = useState([]);

    const filterBuilderPopupPosition = {
        of: window,
        at: 'top',
        my: 'top',
        offset: { y: 10 },
    };

    const allowedPageSizes = [5, 10, 15, 20, 'all'];

    const exportFormats = ['xlsx'];

    function getOrderDay(rowData) {
        return (new Date(rowData.OrderDate)).getDay();
    }

    const filterBuilder = {
        customOperations: [{
            name: 'weekends',
            caption: 'Weekends',
            dataTypes: ['date'],
            icon: 'check',
            hasValue: false,
            calculateFilterExpression: () => [[getOrderDay, '=', 0], 'or', [getOrderDay, '=', 6]],
        }],
        allowHierarchicalFields: true,
    };

    const filterValue = [['Estatus', '=', 'Sin Afectar']];

    //Ref para el grid Tablero de Control
    const [gridTableroRef] = useState(React.createRef());

    function onAutoExpandAllChanged() {
        setAutoExpandAll(!autoExpandAll)
    };

    const getInvoiceRecords = React.useCallback(async (sqlFilter) => {
        try {
            let apiName = 'AdminSC';
            let path = '/procesafacturas';

            let myInit = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
                },
                queryStringParameters: {
                    action: 'list',
                    sqlFilter: sqlFilter,
                    salesAccess: props.salesAccess,
                    sucursal: props.sucursal,
                    username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username
                }
            }

            var invoicesGet = await API.get(apiName, path, myInit);
        }
        catch (e) {
            if (e === 'No current user') {
                history.push('/');

                window.location.reload();
            }
        }

        return invoicesGet;
    }, [history, props.salesAccess, props.sucursal]);

    const getCliente = React.useCallback(async (sqlFilter) => {
        let apiName = 'AdminSC';
        let path = '/getcatalogo';

        let myInit = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
            },
            queryStringParameters: {
                type: 'Cliente',
                username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username,
                sqlFilter: sqlFilter
            }
        }

        var articulosGet = await API.get(apiName, path, myInit);

        return articulosGet;
    }, []);

    const getArticulo = React.useCallback(async (sqlFilter) => {
        let apiName = 'AdminSC';
        let path = '/getcatalogo';

        var ListaPreciosEsp = invoicePayload.header.ListaPreciosEsp;

        let myInit = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
            },
            queryStringParameters: {
                type: 'ObtenerPrecioArticuloPedido',
                username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username,
                sqlFilter: sqlFilter,
                ListaPreciosEsp: ListaPreciosEsp
            }
        }

        var articulosGet = await API.get(apiName, path, myInit);

        return articulosGet;
    }, [invoicePayload.header.ListaPreciosEsp]);

    const getAgente = React.useCallback(async () => {
        let apiName = 'AdminSC';
        let path = '/getcatalogo';

        let myInit = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
            },
            queryStringParameters: {
                type: 'Agente',
                username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username
            }
        }

        var agentesGet = await API.get(apiName, path, myInit);

        setAgentes(agentesGet);

        return agentesGet;
    }, []);

    const getListaPrecios = React.useCallback(async () => {
        let apiName = 'AdminSC';
        let path = '/getcatalogo';

        let myInit = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
            },
            queryStringParameters: {
                type: 'ListaPrecios',
                sucursal: sucursal,
                username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username
            }
        }

        var listaPreciosGet = await API.get(apiName, path, myInit);

        setListaPrecios(listaPreciosGet);

        return listaPreciosGet;
    }, [sucursal]);

    const getConceptos = React.useCallback(async () => {
        let apiName = 'AdminSC';
        let path = '/getcatalogo';

        let myInit = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
            },
            queryStringParameters: {
                type: 'Concepto',
                modulo: 'VTAS',
                username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username
            }
        }

        var conceptosGet = await API.get(apiName, path, myInit);

        setConceptos(conceptosGet);

        return conceptosGet;
    }, []);

    const getCondiciones = React.useCallback(async () => {
        let apiName = 'AdminSC';
        let path = '/getcatalogo';

        let myInit = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
            },
            queryStringParameters: {
                type: 'Condicion',
                username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username
            }
        }

        var condicionesGet = await API.get(apiName, path, myInit);

        setCondiciones(condicionesGet);

        return condicionesGet;
    }, []);

    const store = useMemo(() => {
        return new CustomStore({
            key: 'ID',
            load(loadOptions) {
                let sqlFilter = '(';
                [
                    'filter'
                ].forEach((i) => {
                    if (i in loadOptions && isNotEmpty(loadOptions[i])) {
                        var filters = loadOptions[i];

                        var filtersLength = filters.length;

                        for (var i = 0; i <= filtersLength; i++) {
                            var singleFilter = filters[i];
                            var singleFilterType = typeof singleFilter;
                            var singleFilterIsArray = Array.isArray(singleFilter);
                            var singleFilterIsDate = singleFilter instanceof Date;

                            if (singleFilter === 'NULL') {
                                singleFilter = '0';
                            }

                            if (singleFilterType.toString() === 'object' && singleFilterIsArray) {
                                sqlFilter += '(';

                                var subFiltersLength = singleFilter.length;

                                for (var i2 = 0; i2 <= subFiltersLength; i2++) {
                                    var singleSubFilter = singleFilter[i2];
                                    var singleSubFilterType = typeof singleSubFilter;
                                    var singleSubFilterIsArray = Array.isArray(singleSubFilter);
                                    var singleSubFilterIsDate = singleSubFilter instanceof Date

                                    if (singleSubFilter === 'NULL') {
                                        singleSubFilter = '0';
                                    }

                                    if (singleSubFilterType.toString() === 'object' && singleSubFilterIsArray) {
                                        sqlFilter += '(';

                                        var subSubFiltersLength = singleSubFilter.length;

                                        for (var i3 = 0; i3 <= subSubFiltersLength; i3++) {
                                            var singleSubSubFilter = singleSubFilter[i3];
                                            var singleSubSubFilterType = typeof singleSubSubFilter;
                                            var singleSubSubFilterIsArray = Array.isArray(singleSubSubFilter);
                                            var singleSubSubFilterIsDate = singleSubSubFilter instanceof Date

                                            if (singleSubSubFilter === 'NULL') {
                                                singleSubSubFilter = '0';
                                            }

                                            if (singleSubSubFilterType.toString() === 'object' && singleSubSubFilterIsArray) {
                                                sqlFilter += '(';

                                                var subSubSubFiltersLength = singleSubSubFilter.length;

                                                for (var i4 = 0; i4 <= subSubSubFiltersLength; i4++) {
                                                    var singleSubSubSubFilter = singleSubSubFilter[i4];
                                                    var singleSubSubSubFilterType = typeof singleSubSubSubFilter;
                                                    var singleSubSubSubFilterIsArray = Array.isArray(singleSubSubSubFilter);
                                                    var singleSubSubSubFilterIsDate = singleSubSubSubFilter instanceof Date

                                                    if (singleSubSubSubFilter === 'NULL') {
                                                        singleSubSubSubFilter = '0';
                                                    }

                                                    if (singleSubSubSubFilterType.toString() === 'object' && singleSubSubSubFilterIsArray) {
                                                        sqlFilter += '(';

                                                        var subSubSubSubFiltersLength = singleSubSubSubFilter.length;

                                                        for (var i5 = 0; i5 <= subSubSubSubFiltersLength; i5++) {
                                                            var singleSubSubSubSubFilter = singleSubSubSubFilter[i5];
                                                            var singleSubSubSubSubFilterType = typeof singleSubSubSubSubFilter;
                                                            var singleSubSubSubSubFilterIsArray = Array.isArray(singleSubSubSubSubFilter);
                                                            var singleSubSubSubSubFilterIsDate = singleSubSubSubSubFilter instanceof Date

                                                            if (singleSubSubSubSubFilter === 'NULL') {
                                                                singleSubSubSubSubFilter = '0';
                                                            }

                                                            if (singleSubSubSubSubFilterType.toString() === 'string') {
                                                                if (singleSubSubSubSubFilter === 'contains') {
                                                                    filters[i][i2][i3][i4][i5] = 'LIKE';
                                                                    singleSubSubSubSubFilter = 'LIKE';

                                                                    filters[i][i2][i3][i4][i5 + 1] = '\'%' + filters[i][i2][i3][i4][i5 + 1] + '%\'';
                                                                }
                                                                else if (singleSubSubSubSubFilter === 'notcontains') {
                                                                    filters[i][i2][i3][i4][i5] = 'NOT LIKE';
                                                                    singleSubSubSubSubFilter = 'NOT LIKE';

                                                                    filters[i][i2][i3][i4][i5 + 1] = '\'%' + filters[i][i2][i3][i4][i5 + 1] + '%\'';
                                                                }
                                                                else if (singleSubSubSubSubFilter === 'startswith') {
                                                                    filters[i][i2][i3][i4][i5] = 'LIKE';
                                                                    singleSubSubSubSubFilter = 'LIKE';

                                                                    filters[i][i2][i3][i4][i5 + 1] = '\'' + filters[i][i2][i3][i4][i5 + 1] + '%\'';
                                                                }
                                                                else if (singleSubSubSubSubFilter === 'endswith') {
                                                                    filters[i][i2][i3][i4][i5] = 'LIKE';
                                                                    singleSubSubSubSubFilter = 'LIKE';

                                                                    filters[i][i2][i3][i4][i5 + 1] = '\'%' + filters[i][i2][i3][i4][i5 + 1] + '\'';
                                                                }
                                                                else if (singleSubSubSubSubFilter === '=' || singleSubSubSubSubFilter === '<>' || singleSubSubSubSubFilter === '>' || singleSubSubSubSubFilter === '>=' || singleSubSubSubSubFilter === '<' || singleSubSubSubSubFilter === '<=') {
                                                                    if (!filters[i][i2][i3][i4][i5 + 1])
                                                                        filters[i][i2][i3][i4][i5 + 1] = 'NULL';
                                                                    else if (typeof filters[i][i2][i3][i4][i5 + 1] === 'string') {
                                                                        filters[i][i2][i3][i4][i5 + 1] = '\'' + filters[i][i2][i3][i4][i5 + 1] + '\'';
                                                                    }
                                                                }
                                                                else if (singleSubSubSubSubFilter === '!') {
                                                                    filters[i][i2][i3][i4][i5] = 'NOT';
                                                                    singleSubSubSubSubFilter = 'NOT';
                                                                }

                                                                sqlFilter += ' ' + singleSubSubSubSubFilter + ' ';
                                                            }
                                                            else if (singleSubSubSubSubFilterType.toString() === 'object' && singleSubSubSubSubFilterIsDate) {
                                                                sqlFilter += ' \'' + singleSubSubSubSubFilter.toISOString().split('T')[0] + '\' ';
                                                            }
                                                            else if (singleSubSubSubSubFilterType.toString() === 'number') {
                                                                sqlFilter += ' ' + singleSubSubSubSubFilter.toString() + ' ';
                                                            }
                                                            else if (singleSubSubSubSubFilterType.toString() === 'boolean') {
                                                                if (singleSubSubSubSubFilter.toString() === 'true')
                                                                    sqlFilter += '1 ';
                                                                else if (singleSubSubSubSubFilter.toString() === 'false' || singleSubSubSubSubFilter === null || singleSubSubSubSubFilter === undefined)
                                                                    sqlFilter += '0 ';
                                                            }
                                                        }
                                                    }
                                                    else if (singleSubSubSubFilterType.toString() === 'string') {
                                                        if (singleSubSubSubFilter === 'contains') {
                                                            filters[i][i2][i3][i4] = 'LIKE';
                                                            singleSubSubSubFilter = 'LIKE';

                                                            filters[i][i2][i3][i4 + 1] = '\'%' + filters[i][i2][i3][i4 + 1] + '%\'';
                                                        }
                                                        else if (singleSubSubSubFilter === 'notcontains') {
                                                            filters[i][i2][i3][i4] = 'NOT LIKE';
                                                            singleSubSubSubFilter = 'NOT LIKE';

                                                            filters[i][i2][i3][i4 + 1] = '\'%' + filters[i][i2][i3][i4 + 1] + '%\'';
                                                        }
                                                        else if (singleSubSubSubFilter === 'startswith') {
                                                            filters[i][i2][i3][i4] = 'LIKE';
                                                            singleSubSubSubFilter = 'LIKE';

                                                            filters[i][i2][i3][i4 + 1] = '\'' + filters[i][i2][i3][i4 + 1] + '%\'';
                                                        }
                                                        else if (singleSubSubSubFilter === 'endswith') {
                                                            filters[i][i2][i3][i4] = 'LIKE';
                                                            singleSubSubSubFilter = 'LIKE';

                                                            filters[i][i2][i3][i4 + 1] = '\'%' + filters[i][i2][i3][i4 + 1] + '\'';
                                                        }
                                                        else if (singleSubSubSubFilter === '=' || singleSubSubSubFilter === '<>' || singleSubSubSubFilter === '>' || singleSubSubSubFilter === '>=' || singleSubSubSubFilter === '<' || singleSubSubSubFilter === '<=') {
                                                            if (!filters[i][i2][i3][i4 + 1])
                                                                filters[i][i2][i3][i4 + 1] = 'NULL';
                                                            else if (typeof filters[i][i2][i3][i4 + 1] === 'string') {
                                                                filters[i][i2][i3][i4 + 1] = '\'' + filters[i][i2][i3][i4 + 1] + '\'';
                                                            }
                                                        }
                                                        else if (singleSubSubSubFilter === '!') {
                                                            filters[i][i2][i3][i4] = 'NOT';
                                                            singleSubSubSubFilter = 'NOT';
                                                        }

                                                        sqlFilter += ' ' + singleSubSubSubFilter + ' ';
                                                    }
                                                    else if (singleSubSubSubFilterType.toString() === 'object' && singleSubSubSubFilterIsDate) {
                                                        sqlFilter += ' \'' + singleSubSubSubFilter.toISOString().split('T')[0] + '\' ';
                                                    }
                                                    else if (singleSubSubSubFilterType.toString() === 'number') {
                                                        sqlFilter += ' ' + singleSubSubSubFilter.toString() + ' ';
                                                    }
                                                    else if (singleSubSubSubFilterType.toString() === 'boolean') {
                                                        if (singleSubSubSubFilter.toString() === 'true')
                                                            sqlFilter += '1 ';
                                                        else if (singleSubSubSubFilter.toString() === 'false' || singleSubSubSubFilter === null || singleSubSubSubFilter === undefined)
                                                            sqlFilter += '0 ';
                                                    }
                                                }

                                                sqlFilter += ')';
                                            }
                                            else if (singleSubSubFilterType.toString() === 'string') {
                                                if (singleSubSubFilter === 'contains') {
                                                    filters[i][i2][i3] = 'LIKE';
                                                    singleSubSubFilter = 'LIKE';

                                                    filters[i][i2][i3 + 1] = '\'%' + filters[i][i2][i3 + 1] + '%\'';
                                                }
                                                else if (singleSubSubFilter === 'notcontains') {
                                                    filters[i][i2][i3] = 'NOT LIKE';
                                                    singleSubSubFilter = 'NOT LIKE';

                                                    filters[i][i2][i3 + 1] = '\'%' + filters[i][i2][i3 + 1] + '%\'';
                                                }
                                                else if (singleSubSubFilter === 'startswith') {
                                                    filters[i][i2][i3] = 'LIKE';
                                                    singleSubSubFilter = 'LIKE';

                                                    filters[i][i2][i3 + 1] = '\'' + filters[i][i2][i3 + 1] + '%\'';
                                                }
                                                else if (singleSubSubFilter === 'endswith') {
                                                    filters[i][i2][i3] = 'LIKE';
                                                    singleSubSubFilter = 'LIKE';

                                                    filters[i][i2][i3 + 1] = '\'%' + filters[i][i2][i3 + 1] + '\'';
                                                }
                                                else if (singleSubSubFilter === '=' || singleSubSubFilter === '<>' || singleSubSubFilter === '>' || singleSubSubFilter === '>=' || singleSubSubFilter === '<' || singleSubSubFilter === '<=') {
                                                    if (!filters[i][i2][i3 + 1])
                                                        filters[i][i2][i3 + 1] = 'NULL';
                                                    else if (typeof filters[i][i2][i3 + 1] === 'string') {
                                                        filters[i][i2][i3 + 1] = '\'' + filters[i][i2][i3 + 1] + '\'';
                                                    }
                                                }
                                                else if (singleSubSubFilter === '!') {
                                                    filters[i][i2][i3] = 'NOT';
                                                    singleSubSubFilter = 'NOT';
                                                }
                                                sqlFilter += ' ' + singleSubSubFilter + ' ';
                                            }
                                            else if (singleSubSubFilterType.toString() === 'object' && singleSubSubFilterIsDate) {
                                                sqlFilter += ' \'' + singleSubSubFilter.toISOString().split('T')[0] + '\' ';
                                            }
                                            else if (singleSubSubFilterType.toString() === 'number') {
                                                sqlFilter += ' ' + singleSubSubFilter.toString() + ' ';
                                            }
                                            else if (singleSubSubFilterType.toString() === 'boolean') {
                                                if (singleSubSubFilter.toString() === 'true')
                                                    sqlFilter += '1 ';
                                                else if (singleSubSubFilter.toString() === 'false' || singleSubSubFilter === null || singleSubSubFilter === undefined)
                                                    sqlFilter += '0 ';
                                            }
                                        }

                                        sqlFilter += ')';
                                    }
                                    else if (singleSubFilterType.toString() === 'string') {
                                        if (singleSubFilter === 'contains') {
                                            filters[i][i2] = 'LIKE';
                                            singleSubFilter = 'LIKE';

                                            filters[i][i2 + 1] = '\'%' + filters[i][i2 + 1] + '%\'';
                                        }
                                        else if (singleSubFilter === 'notcontains') {
                                            filters[i][i2] = 'NOT LIKE';
                                            singleSubFilter = 'NOT LIKE';

                                            filters[i][i2 + 1] = '\'%' + filters[i][i2 + 1] + '%\'';
                                        }
                                        else if (singleSubFilter === 'startswith') {
                                            filters[i][i2] = 'LIKE';
                                            singleSubFilter = 'LIKE';

                                            filters[i][i2 + 1] = '\'' + filters[i][i2 + 1] + '%\'';
                                        }
                                        else if (singleSubFilter === 'endswith') {
                                            filters[i][i2] = 'LIKE';
                                            singleSubFilter = 'LIKE';

                                            filters[i][i2 + 1] = '\'%' + filters[i][i2 + 1] + '\'';
                                        }
                                        else if (singleSubFilter === '=' || singleSubFilter === '<>' || singleSubFilter === '>' || singleSubFilter === '>=' || singleSubFilter === '<' || singleSubFilter === '<=') {
                                            if (!filters[i][i2 + 1])
                                                filters[i][i2 + 1] = 'NULL';
                                            else if (typeof filters[i][i2 + 1] === 'string') {
                                                filters[i][i2 + 1] = '\'' + filters[i][i2 + 1] + '\'';
                                            }
                                        }
                                        else if (singleSubFilter === '!') {
                                            filters[i][i2] = 'NOT';
                                            singleSubFilter = 'NOT';
                                        }
                                        sqlFilter += ' ' + singleSubFilter + ' ';
                                    }
                                    else if (singleSubFilterType.toString() === 'object' && singleSubFilterIsDate) {
                                        sqlFilter += ' \'' + singleSubFilter.toISOString().split('T')[0] + '\' ';
                                    }
                                    else if (singleSubFilterType.toString() === 'number') {
                                        sqlFilter += ' ' + singleSubFilter.toString() + ' ';
                                    }
                                    else if (singleSubFilterType.toString() === 'boolean') {
                                        if (singleSubFilter.toString() === 'true')
                                            sqlFilter += '1 ';
                                        else if (singleSubFilter.toString() === 'false' || singleSubFilter === null || singleSubFilter === undefined)
                                            sqlFilter += '0 ';
                                    }
                                }

                                sqlFilter += ')';
                            }
                            else if (singleFilterType.toString() === 'string') {
                                if (singleFilter === 'contains') {
                                    filters[i] = 'LIKE';
                                    singleFilter = 'LIKE';

                                    filters[i + 1] = '\'%' + filters[i + 1] + '%\'';
                                }
                                else if (singleFilter === 'notcontains') {
                                    filters[i] = 'NOT LIKE';
                                    singleFilter = 'NOT LIKE';

                                    filters[i + 1] = '\'%' + filters[i + 1] + '%\'';
                                }
                                else if (singleFilter === 'startswith') {
                                    filters[i] = 'LIKE';
                                    singleFilter = 'LIKE';

                                    filters[i + 1] = '\'' + filters[i + 1] + '%\'';
                                }
                                else if (singleFilter === 'endswith') {
                                    filters[i] = 'LIKE';
                                    singleFilter = 'LIKE';

                                    filters[i + 1] = '\'%' + filters[i + 1] + '\'';
                                }
                                else if (singleFilter === '=' || singleFilter === '<>' || singleFilter === '>' || singleFilter === '>=' || singleFilter === '<' || singleFilter === '<=') {
                                    if (!filters[i + 1])
                                        filters[i + 1] = 'NULL';
                                    else if (typeof filters[i + 1] === 'string') {
                                        filters[i + 1] = '\'' + filters[i + 1] + '\'';
                                    }
                                }
                                else if (singleFilter === '!') {
                                    filters[i] = 'NOT';
                                    singleFilter = 'NOT';
                                }
                                sqlFilter += ' ' + singleFilter + ' ';
                            }
                            else if (singleFilterType.toString() === 'object' && singleFilterIsDate) {
                                sqlFilter += ' \'' + singleFilter.toISOString().split('T')[0] + '\' ';
                            }
                            else if (singleFilterType.toString() === 'number') {
                                sqlFilter += ' ' + singleFilter.toString() + ' ';
                            }
                            else if (singleFilterType.toString() === 'boolean') {
                                if (singleFilter.toString() === 'true')
                                    sqlFilter += '1 ';
                                else if (singleFilter.toString() === 'false' || singleFilter === null || singleFilter === undefined)
                                    sqlFilter += '0 ';
                            }
                        }

                        sqlFilter += ')';
                    }
                });

                async function getRecords() {
                    if (sqlFilter === '(')
                        sqlFilter = '';

                    var records = await getInvoiceRecords(sqlFilter);
                    return {
                        data: records,
                        totalCount: records.length
                    };
                }

                return getRecords()
                    .then(function (result) {
                        return result;
                    })
                    .catch(() => { throw new Error('Data Loading Error'); });
            }
        })
    }, [getInvoiceRecords]);

    const storeCliente = useMemo(() => {
        return new CustomStore({
            key: 'value',
            load(loadOptions) {
                let sqlFilter = '';
                [
                    'filter'
                ].forEach((i) => {
                    sqlFilter = loadOptions.searchValue;
                });

                async function getRecords() {
                    if (sqlFilter) {
                        var records = await getCliente(sqlFilter);

                        return {
                            data: records,
                            totalCount: records.length
                        };
                    }
                    else {
                        return {
                            data: [],
                            totalCount: 0
                        };
                    }
                }

                return getRecords()
                    .then(function (result) {
                        return result;
                    })
                    .catch(() => { throw new Error('Data Loading Error'); });
            },
            byKey: (key) => {
                /*
                async function getRecords(key) {
                var records = await getCliente(key);

                return records;
                }

                return getRecords(key);
                */
                return {
                    name: invoicePayload.header.CteNombre,
                    value: invoicePayload.header.Cliente
                };
            }
        })
    }, [getCliente, invoicePayload.header.CteNombre, invoicePayload.header.Cliente]);

    const storeArticulo = useMemo(() => {
        return new CustomStore({
            key: 'value',
            load(loadOptions) {
                let sqlFilter = '';
                [
                    'filter'
                ].forEach((i) => {
                    sqlFilter = loadOptions.searchValue;
                });

                async function getRecords() {
                    if (sqlFilter) {
                        var records = await getArticulo(sqlFilter);

                        return {
                            data: records,
                            totalCount: records.length
                        };
                    }
                    else {
                        return {
                            data: [],
                            totalCount: 0
                        };
                    }
                }

                return getRecords()
                    .then(function (result) {
                        return result;
                    })
                    .catch(() => { throw new Error('Data Loading Error'); });
            },
            byKey: (key) => {
                async function getRecords(key) {
                    var records = await getArticulo(key);

                    return records;
                }

                return getRecords(key);
            }
        })
    }, [getArticulo]);

    var fechaEmisionEditorOptions = useMemo(() => {
        return {
            width: '100%',
            readOnly: true
        };
    }, []);

    var movimientoEditorOptions = useMemo(() => {
        return {
            width: '100%',
            readOnly: true
        };
    }, []);

    var pedidoEditorOptions = useMemo(() => {
        return {
            width: '100%',
            readOnly: true
        };
    }, []);

    var rfcEditorOptions = useMemo(() => {
        return {
            width: '100%',
            readOnly: true,
            inputAttr: {
                'style': 'text-transform: uppercase'
            }
        };
    }, []);

    var cantidadEditorOptions = useMemo(() => {
        return {
            format: '#'
        };
    }, []);

    var listaPreciosDataSource = useMemo(() => {
        return new DataSource({
            paginate: true,
            store: {
                type: "array",
                key: "ListaPrecios",
                data: listaPrecios
            }
        });
    }, [listaPrecios]);

    var condicionesDataSource = useMemo(() => {
        return new DataSource({
            paginate: true,
            store: {
                type: "array",
                key: "Condicion",
                data: condiciones
            }
        });
    }, [condiciones]);

    var agenteDataSource = useMemo(() => {
        return new DataSource({
            paginate: true,
            store: {
                type: "array",
                key: "Agente",
                data: agentes
            }
        });
    }, [agentes]);

    var observacionesEditorOptions = useMemo(() => {
        return {
            readOnly: true
        };
    }, []);

    var datosCFDIEditorOptions = useMemo(() => {
        return { width: '100%', readOnly: true };
    }, []);

    //Opciones Agente 
    try {
        var agenteEditorOptions = useMemo(() => {
            return {
                dataSource: agenteDataSource,
                displayExpr: 'name',
                valueExpr: 'value',
                searchEnabled: true,
                readOnly: true,
                value: (invoicePayload.header.Agente ? invoicePayload.header.Agente : ''), width: '100%'
            };
        }, [agenteDataSource, invoicePayload.header.Agente]);
    }
    catch (e) {
        var agenteEditorOptions = {
            dataSource: agenteDataSource,
            displayExpr: 'name',
            valueExpr: 'value',
            searchEnabled: true,
            readOnly: true,
            value: '',
            width: '100%'
        };
    };

    //Opciones Lista Precios 
    try {
        var listaPreciosEditorOptions = useMemo(() => {
            return {
                dataSource: listaPreciosDataSource,
                displayExpr: 'name',
                valueExpr: 'value',
                searchEnabled: true,
                value: (invoicePayload.header.ListaPreciosEsp ? invoicePayload.header.ListaPreciosEsp : ''),
                width: '100%'
            };
        }, [listaPreciosDataSource, invoicePayload.header.ListaPreciosEsp]);
    }
    catch (e) {
        var listaPreciosEditorOptions = {
            dataSource: listaPreciosDataSource,
            displayExpr: 'name',
            valueExpr: 'value',
            searchEnabled: true,
            value: '',
            width: '100%'
        };
    };

    //Opciones Condicion
    try {
        var condicionEditorOptions = useMemo(() => {
            return {
                dataSource: condicionesDataSource,
                displayExpr: 'name',
                valueExpr: 'value',
                searchEnabled: true,
                value: (invoicePayload.header.Condicion ? invoicePayload.header.Condicion : ''),
                width: '100%'
            };
        }, [condicionesDataSource, invoicePayload.header.Condicion]);
    }
    catch (e) {
        var condicionEditorOptions = {
            dataSource: condicionesDataSource,
            displayExpr: 'name',
            valueExpr: 'value',
            searchEnabled: true,
            value: '',
            width: '100%'
        };
    };

    //Opciones del campo Cliente
    try {
        var clienteEditorOptions = useMemo(() => {
            return {
                dataSource: storeCliente,
                displayExpr: 'name',
                valueExpr: 'value',
                searchEnabled: true,
                showClearButton: true,
                acceptCustomValue: false,
                disabled: ((invoicePayload.header.Estatus !== 'Sin Afectar')),
                searchTimeout: 100,
                width: '100%',
                clearButtonText: 'Cerrar',
                noDataText: 'No se encontraron resultados'
            };
        }, [storeCliente, invoicePayload.header.Estatus]);
    }
    catch (e) {
        var clienteEditorOptions = {
            dataSource: storeCliente,
            displayExpr: 'name',
            valueExpr: 'value',
            searchEnabled: true,
            acceptCustomValue: false,
            disabled: ((invoicePayload.header.Estatus !== 'Sin Afectar')),
            width: '100%'
        };
    };

    async function uploadChunk(file, uploadInfo) {
        try {
            var responseS3 = await Storage.put("/vtas/" + id + "/" + file.name, uploadInfo.chunkBlob, {
                contentType: file.type,
                ACL: 'public-read'
            });

            //Obtenemos el nombre del bucket
            let bucketName = Storage._config.AWSS3.bucket;


            let apiName = 'AdminSC';
            let path = '/uploadattachment';

            let myInit = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
                },
                body: {
                    action: 'register',
                    bucketName: bucketName,
                    type: 'vtas',
                    id: id,
                    Key: "/vtas/" + id + "/" + file.name,
                    fileName: file.name,
                    username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username
                }
            }

            var attachmentsResult = await API.post(apiName, path, myInit);

            //Obtenemos los attachments
            var attachmentsGet = await Storage.list("/vtas/" + id);
            var attachmentsAux = [];

            for (var attachmentsGetIndex in attachmentsGet) {
                var singleAttachmentsGet = attachmentsGet[attachmentsGetIndex];

                var singleAttachment = {
                    name: singleAttachmentsGet.key.split('/').pop(),
                    isDirectory: false,
                    size: singleAttachmentsGet.size,
                };

                attachmentsAux.push(singleAttachment);
            }

            setAttachments(attachmentsAux);
        }
        catch (err) {
            console.log("Error uploading file:", err);
        }
    }

    async function onAttachmentDeleting(e) {
        var fileName = e.item.key;

        //Primero borramos el archivo de S3
        var attachmentsDelete = await Storage.remove("/vtas/" + id + "/" + fileName);

        //Despues borramos el attachment en Intelisis
        let bucketName = Storage._config.AWSS3.bucket;

        let apiName = 'AdminSC';
        let path = '/uploadattachment';

        let myInit = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
            },
            body: {
                action: 'delete',
                bucketName: bucketName,
                type: 'vtas',
                id: id,
                Key: "/vtas/" + id + "/" + fileName,
                fileName: fileName,
                username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username
            }
        }

        var attachmentsResult = await API.post(apiName, path, myInit);

        //Finalmente sincronizamos el state
        var attachmentsGet = await Storage.list("/vtas/" + id);
        var attachmentsAux = [];

        for (var attachmentsGetIndex in attachmentsGet) {
            var singleAttachmentsGet = attachmentsGet[attachmentsGetIndex];

            var singleAttachment = {
                name: singleAttachmentsGet.key.split('/').pop(),
                isDirectory: false,
                size: singleAttachmentsGet.size,
            };

            attachmentsAux.push(singleAttachment);
        }

        setAttachments(attachmentsAux);
    };

    function downloadBlob(blob, filename) {
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = filename || 'download';
        const clickHandler = () => {
            setTimeout(() => {
                URL.revokeObjectURL(url);
                a.removeEventListener('click', clickHandler);
            }, 150);
        };

        a.addEventListener('click', clickHandler, false);

        a.click();

        return a;
    };

    async function onItemDownloading(e) {
        e.cancel = true;

        const downloadFileResponse = await Storage.get("/vtas/" + id + "/" + e.item.name, { download: true });

        downloadBlob(downloadFileResponse.Body, e.item.name);
    };

    function openBlob(blob, filename) {
        const url = URL.createObjectURL(blob);

        window.open(url, "_blank");
    };

    async function onAttachmentOpen(e) {
        const downloadFileResponse = await Storage.get("/vtas/" + id + "/" + e.file.name, { download: true });

        openBlob(downloadFileResponse.Body, e.file.name);
    };

    function editInvoice(data) {
        return (
            <Link to={`/Invoice/${data.data.ID}`}>
                <button class="bg-blue-500 hover:bg-blue-700 text-white py-1 px-3 rounded" onClick={() => editButton(data.data.ID)}><FontAwesomeIcon icon={permissionLevel === 'Editar' ? faPencilAlt : faGlasses} /> {permissionLevel === 'Editar' ? 'Editar' : 'Ver'}</button>
            </Link>
        )
    }

    function openRelatedRecord(redirect) {
        if (redirect) {
            var baseUrl = window.location.href.split('#/')[0];

            redirect = baseUrl + '#' + redirect;

            window.open(redirect, "_blank");
        }
    };

    function openRelatedRecordButton(data) {
        return (
            <button onClick={() => openRelatedRecord(data.data.redirect)}><FontAwesomeIcon icon={faArrowUpRightFromSquare} /></button>
        )
    };

    function estatusRelatedRecords(data) {
        return (
            <TransactionStatus {...props} Estatus={data.data.Estatus} />
        )
    };

    const getInvoicePayload = React.useCallback(async (ID) => {
        try {
            let apiName = 'AdminSC';
            let path = '/procesafacturas';

            let myInit = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
                },
                body: {
                    action: 'payload',
                    invoices: [{ ID: ID }],
                    username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username
                }
            };

            var payloadGet = await API.post(apiName, path, myInit);

            setInvoicePayload(payloadGet);

            //Obtenemos los attachments
            var attachmentsGet = await Storage.list("/vtas/" + ID);
            var attachmentsAux = [];

            for (var attachmentsGetIndex in attachmentsGet) {
                var singleAttachmentsGet = attachmentsGet[attachmentsGetIndex];

                var singleAttachment = {
                    name: singleAttachmentsGet.key.split('/').pop(),
                    isDirectory: false,
                    size: singleAttachmentsGet.size,
                };

                attachmentsAux.push(singleAttachment);
            }

            setAttachments(attachmentsAux);
        }
        catch (e) {
            if (e === 'No current user') {
                history.push('/');

                window.location.reload();
            }
        }

        return payloadGet;

    }, [history]);

    function blobToBase64(blob) {
        return new Promise((resolve, _) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.readAsDataURL(blob);
        });
    };

    const sendCFDiFiles = React.useCallback(async (hideToast, MovimientoFactura, IDFactura) => {
        try {
            let apiName = 'AdminSC';
            let path = '/enviarcorreo';

            let pdfFile = await Storage.get("/vtas/" + IDFactura + "/" + MovimientoFactura.trim() + '.pdf', { download: true });
            let xmlFile = await Storage.get("/vtas/" + IDFactura + "/" + MovimientoFactura.trim() + '.xml', { download: true });

            if (!pdfFile || !xmlFile) {
                return;
            }

            if (!hideToast)
                setVisibleLoadPanel(true);

            let xmlFileContents = await blobToBase64(xmlFile.Body);
            let pdfFileContents = await blobToBase64(pdfFile.Body);

            xmlFileContents = xmlFileContents.toString().split('base64,')[1];
            pdfFileContents = pdfFileContents.toString().split('base64,')[1];

            if (!xmlFileContents || !pdfFileContents) {
                if (!hideToast)
                    setVisibleLoadPanel(false);

                setVisibleToast(true);
                setMessageToast('Archivos CFDi incorrectos');
                setTypeToast('error');
                return;
            }

            let myInit = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
                },
                body: {
                    ToAddresses: [invoicePayload.header.cte_CorreoElectronico],
                    // BccAddresses: [],
                    // CcAddresses: [],
                    Body: 'CFDi ' + MovimientoFactura.trim(),
                    Subject: 'CFDi ' + MovimientoFactura.trim(),
                    Source: 'admin@supercolchones.com',
                    Attachments: [{
                        filename: MovimientoFactura.trim() + '.xml',
                        content: xmlFileContents,
                        encoding: 'base64'
                    },
                    {
                        filename: MovimientoFactura.trim() + '.pdf',
                        content: pdfFileContents,
                        encoding: 'base64'
                    }
                    ],
                    username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username
                }
            }

            var sendCFDiPost = await API.post(apiName, path, myInit);

            if (sendCFDiPost.message.indexOf('Error') >= 0) {
                if (!hideToast)
                    setVisibleLoadPanel(false);

                setVisibleToast(true);
                setMessageToast(sendCFDiPost.message);
                setTypeToast('error');
            }
            else {
                if (hideToast === false) {
                    setVisibleLoadPanel(false);

                    setVisibleToast(true);
                    setMessageToast(sendCFDiPost.message);
                    setTypeToast('success');
                }
            }

            return sendCFDiPost;
        }
        catch (e) {
            setVisibleToast(true);
            setMessageToast(e.message);
            setTypeToast('error');
        }
    }, [invoicePayload.header.cte_CorreoElectronico]);

    const editButton = React.useCallback(async (ID) => {
        try {
            if (!window.event.metaKey && !window.event.ctrlKey) {
                setVisibleLoadPanel(true);

                setIsDetail(true);
                setId(ID);

                var payload = await getInvoicePayload(ID);

                if (permissionLevel === 'Editar') {
                    if (payload.header.Estatus === 'Sin Afectar') {
                        setActivatePDFButton(false);
                        setActivateRegenerateCFDi(false);
                        setActivateSendCFDi(false);
                        setActivateConfirmButton(true);
                        setActivateAttachments(false);

                        if (!payload.header.MovID)
                            setActivateDeleteButton(true);
                    }
                    else if (payload.header.Estatus === 'Concluido') {
                        setActivatePDFButton(true);
                        setActivateConfirmButton(false);
                        setActivateDeleteButton(false);
                        setActivateAttachments(false);

                        if (regenerateCFDi && payload.header.cfdi_CFDIUUID)
                            setActivateRegenerateCFDi(true);
                        else
                            setActivateRegenerateCFDi(false);

                        if (sendCFDi && payload.header.cfdi_CFDIUUID)
                            setActivateSendCFDi(true);
                        else
                            setActivateSendCFDi(false);
                    }
                    else if (payload.header.Estatus === 'Cancelado') {
                        setActivatePDFButton(true);
                        setActivateConfirmButton(false);
                        setActivateDeleteButton(false);
                        setActivateAttachments(false);

                        if (regenerateCFDi && payload.header.cfdi_CFDIUUID)
                            setActivateRegenerateCFDi(true);
                        else
                            setActivateRegenerateCFDi(false);

                        if (sendCFDi && payload.header.cfdi_CFDIUUID)
                            setActivateSendCFDi(true);
                        else
                            setActivateSendCFDi(false);
                    }
                }
                else if (permissionLevel === 'Ver') {
                    setActivatePDFButton(true);
                    setActivateRegenerateCFDi(false);
                    setActivateSendCFDi(false);
                    setActivateConfirmButton(false);
                    setActivateDeleteButton(false);
                    setActivateAttachments(false);
                }

                setVisibleLoadPanel(false);
            }
        }
        catch (e) {
            setVisibleLoadPanel(false);
        }
    }, [getInvoicePayload, permissionLevel, regenerateCFDi, sendCFDi]);

    async function generateCFDiFiles(hideToast) {
        var modulo = 'vtas';
        var type = 'invoice';

        try {
            //Primero obtenemos el payload actualizado
            var payload = await getInvoicePayload(id);

            // En caso de no existir folio fiscal no es necesario crear los archivos
            if (!payload.header.cfdi_CFDIUUID)
                return;

            if (!hideToast)
                setVisibleLoadPanel(true);

            // Despues enviamos el payload a la API crearPDF
            // *********************************************
            let apiName = 'AdminSC';
            let path = '/crearpdf';

            let myInit = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
                },
                body: {
                    type: type,
                    payload: payload,
                    bucketName: Storage._config.AWSS3.bucket,
                    fileKey: `public//${modulo}/${id}/${payload.header.Mov.trim()} ${payload.header.MovID.trim()}.pdf`,
                    contentType: 'application/pdf',
                    username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username
                }
            }

            var pdfPost = await API.post(apiName, path, myInit);
            // *********************************************

            if (pdfPost.message.indexOf('Error') >= 0) {
                if (!hideToast)
                    setVisibleLoadPanel(false);

                setVisibleToast(true);
                setMessageToast(pdfPost.message);
                setTypeToast('error');
                return;
            }

            // Despues enviamos el payload a la API crearXML
            // *********************************************
            apiName = 'AdminSC';
            path = '/crearxml';

            myInit = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
                },
                body: {
                    modulo: modulo,
                    id: id,
                    bucketName: Storage._config.AWSS3.bucket,
                    fileKey: `public//${modulo}/${id}/${payload.header.Mov.trim()} ${payload.header.MovID.trim()}.xml`,
                    contentType: 'text/xml',
                    username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username
                }
            }

            var xmlPost = await API.post(apiName, path, myInit);
            // *********************************************
            if (!hideToast) {
                setVisibleLoadPanel(false);

                setVisibleToast(true);
                setMessageToast('Se generaron los archivos CFDi con éxito');
                setTypeToast('success')
            }
        }
        catch (e) {
            setVisibleLoadPanel(false);

            if (e === 'No current user') {
                history.push('/');

                window.location.reload();
            }
        }
    };

    async function generateCFDiPDF(payload) {
        var modulo = 'vtas';
        var type = 'invoice';

        try {
            // En caso de no existir folio fiscal no es necesario crear los archivos
            if (!payload.header.cfdi_CFDIUUID)
                return;

            // Despues enviamos el payload a la API crearPDF
            // *********************************************
            let apiName = 'AdminSC';
            let path = '/crearpdf';

            let myInit = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
                },
                body: {
                    type: type,
                    payload: payload,
                    bucketName: Storage._config.AWSS3.bucket,
                    fileKey: `public//${modulo}/${id}/${payload.header.Mov.trim()} ${payload.header.MovID.trim()}.pdf`,
                    contentType: 'application/pdf',
                    username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username
                }
            }

            var pdfPost = await API.post(apiName, path, myInit);
            // *********************************************

            if (pdfPost.message.indexOf('Error') >= 0) {
                setVisibleToast(true);
                setMessageToast(pdfPost.message);
                setTypeToast('error');
                return;
            }
            else {
                return pdfPost;
            }
        }
        catch (e) {
            setVisibleLoadPanel(false);

            if (e === 'No current user') {
                history.push('/');

                window.location.reload();
            }
        }
    };
    async function regenerateCFDiFilesButton() {
        await generateCFDiFiles(false);
    };

    async function sendCFDiFilesButton() {
        await sendCFDiFiles(false, invoicePayload.header.Movimiento, invoicePayload.header.ID);
    }

    async function cancelEditButton() {
        setIsDetail(false);
    };

    function printButton() {
        var pdfArray = [];
        var baseUrl = window.location.href.split('/')[0];

        pdfArray.push(invoicePayload.header.ID);

        window.open(baseUrl + "/#/PdfRender?type=invoice&id=" + pdfArray, "_blank");
    };

    async function confirmButton() {
        try {
            setVisibleLoadPanel(true);

            var ID = invoicePayload.header.ID;

            var lambdaFunction;

            if (window.location.href.indexOf('sandbox.') >= 0 || window.location.href.indexOf('localhost') >= 0)
                lambdaFunction = 'procesaFacturas-dev';
            else if (window.location.href.indexOf('sandboxtampico.') >= 0)
                lambdaFunction = 'procesaFacturas-devleona';
            else if (window.location.href.indexOf('tampico.') >= 0)
                lambdaFunction = 'procesaFacturas-prodleona';
            else
                lambdaFunction = 'procesaFacturas-prod';

            Auth.currentCredentials()
                .then(credentials => {
                    const lambda = new Lambda({
                        credentials: Auth.essentialCredentials(credentials),
                        region: 'us-east-1'
                    });

                    var params = {
                        FunctionName: lambdaFunction,
                        Payload: JSON.stringify({
                            ID: ID,
                            payload: invoicePayload,
                            action: 'apply',
                            username: props.username,
                            usuario: props.usuario,
                            sucursal: props.sucursal,
                            s3Bucket: Storage._config.AWSS3.bucket
                        })
                    };

                    lambda.invoke(params, function (err, data) {
                        if (err) {
                            setVisibleLoadPanel(false);

                            setVisibleToast(true);
                            setMessageToast(err.message);
                            setTypeToast('error');
                        }
                        else {
                            var payloadPost = JSON.parse(JSON.parse(data.Payload).body);

                            if (payloadPost.ok === 'true') {
                                async function checkCFDiStatus() {
                                    //Primero obtenemos el payload actualizado
                                    var payload = await getInvoicePayload(id);

                                    if (payload.header.cfdi_CFDIUUID) {
                                        // Despues generamos el PDF
                                        var pdfPost = await generateCFDiPDF(payload);

                                        // Finalmente enviamos el correo electrónico
                                        var sendCFDiPost = await sendCFDiFiles(true, payload.header.Movimiento, payload.header.ID);
                                    }

                                    setVisibleToast(true);
                                    setMessageToast('Factura aplicada correctamente');
                                    setTypeToast('success');

                                    editButton(ID);

                                    setVisibleLoadPanel(false);
                                };

                                checkCFDiStatus();

                                window.opener.location.reload();
                            }
                            else if (payloadPost.ok === 'false') {
                                setVisibleToast(true);

                                if (!payloadPost.message || payloadPost.message === '' || payloadPost.message === 'Error SQL')
                                    setMessageToast('No se pudo aplicar la Factura');
                                else
                                    setMessageToast(payloadPost.message);

                                setTypeToast('error')

                                editButton(ID);

                                setVisibleLoadPanel(false);
                            }
                        }
                    });
                })
        }
        catch (e) {
            setVisibleLoadPanel(false);

            if (e === 'No current user') {
                history.push('/');

                window.location.reload();
            }
        }
    };

    function refreshButton() {
        if (gridTableroRef.current) {
            var gridTablero = gridTableroRef.current.instance;

            gridTablero.refresh();
        }
    };

    useEffect(() => {
        window.addEventListener('popstate', (e) => {
            var hash;

            try {
                hash = e.target.location.hash;
            }
            catch (e) {
                hash = '';
            }

            if (hash === '#/Invoice') {
                history.push('/Invoice');

                window.location.reload();
            }
        });

        async function initialize() {
            setVisibleLoadPanel(true);

            await getAgente();

            await getListaPrecios();

            await getConceptos();

            await getCondiciones();

            setVisibleLoadPanel(false);
        }

        initialize();

        //En caso de solicitar un ID especifico, redireccionamos a ese ID
        if (!props.match.isExact) {
            var urlID = props.location.pathname.replace('/Invoice/', '');

            if (urlID === 'undefined')
                urlID = undefined;

            if (urlID)
                editButton(urlID);
            else
                history.push('/Invoice')
        }

        // eslint-disable-next-line
    }, []);

    function onHidingToast() {
        setVisibleToast(false);
    }

    async function deleteButton() {
        try {
            if (!window.confirm('¿Desea eliminar la Factura?'))
                return;

            setVisibleLoadPanel(true);

            var ID = invoicePayload.header.ID;

            if (!ID) {
                //Vamos al tablero de control
                history.push('/Invoice');
                return;
            }

            let apiName = 'AdminSC';
            let path = '/procesafacturas';

            let myInit = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
                },
                body: {
                    ID: ID,
                    payload: invoicePayload,
                    action: 'delete',
                    username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username,
                    usuario: props.usuario,
                    sucursal: props.sucursal,
                }
            }

            var payloadPost = await API.post(apiName, path, myInit);

            if (payloadPost.ok === 'true') {
                setVisibleLoadPanel(false);

                setVisibleToast(true);
                setMessageToast('Factura eliminada con éxito');
                setTypeToast('success')

                setIsDetail(false);

                //Vamos al tablero de control
                history.push('/Invoice');
            }
            else if (payloadPost.ok === 'false') {
                setVisibleLoadPanel(false);

                setVisibleToast(true);
                setMessageToast(payloadPost.message);
                setTypeToast('error')
            }
        }
        catch (e) {
            setVisibleLoadPanel(false);

            if (e === 'No current user') {
                history.push('/');

                window.location.reload();
            }
        }
    }

    function onExporting(e) {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Datos');

        exportDataGrid({
            component: e.component,
            worksheet,
            autoFilterEnabled: true,
        }).then(() => {
            workbook.xlsx.writeBuffer().then((buffer) => {
                saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Datos.xlsx');
            });
        });
        e.cancel = true;
    };

    return (
        <div>
            {isDetail === false ? (
                <div class="bg-gray-200 m-2">
                    <h1 class="font-sans inline-block text-xl font-bold text-gray-700 leading-8 align-top mb-1"><FontAwesomeIcon icon={faList} size="lg" /> Facturas</h1>
                </div>
            ) :
                (
                    <div class="bg-gray-200 m-2">
                        <h1 class="font-sans inline-block text-xl font-bold text-gray-700 leading-8 align-top mb-1"><FontAwesomeIcon icon={faFileInvoiceDollar} size="xl" /> {(invoicePayload.header ? invoicePayload.header.Movimiento : 'Factura')}</h1>
                        <TransactionStatus {...props} Estatus={invoicePayload.header.Estatus} />
                    </div>
                )}

            <LoadPanel
                shadingColor="rgba(0,0,0,0.4)"
                visible={visibleLoadPanel}
                showIndicator={true}
                message="Por favor espere..."
                shading={true}
                showPane={true}
                hideOnOutsideClick={false}
            />

            <Toast
                visible={visibleToast}
                message={messageToast}
                type={typeToast}
                onHiding={onHidingToast}
                displayTime={5000}
                width={'auto'}
                position={{
                    my: 'top center',
                    at: 'top center',
                    of: window,
                    offset: '0 130'
                }}
            />

            <div class="bg-gray-200 m-2">
                {isDetail === false ? (
                    <div class="bg-white p-3 shadow-xl">
                        <div>
                            <button onClick={refreshButton} type="submit" class="mb-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                                <FontAwesomeIcon icon={faArrowsRotate} /> Actualizar
                            </button>
                        </div>
                        <div className="options mb-2">
                            <div className="option">
                                <CheckBox text="Expandir todos los grupos"
                                    value={autoExpandAll}
                                    onValueChanged={onAutoExpandAllChanged}></CheckBox>
                            </div>
                        </div>
                        <DataGrid
                            height={800}
                            id="gridContainer"
                            columnsAutoWidth={true}
                            filterBuilder={filterBuilder}
                            dataSource={store}
                            defaultFilterValue={filterValue}
                            allowColumnReordering={true}
                            allowColumnResizing={true}
                            columnResizingMode={"widget"}
                            showBorders={true}
                            rowAlternationEnabled={true}
                            showRowLines={true}
                            showColumnLines={true}
                            remoteOperations={{ filtering: true }}
                            ref={gridTableroRef}
                            onExporting={onExporting}
                        >
                            <Scrolling rowRenderingMode='virtual'></Scrolling>
                            <Paging defaultPageSize={10} />
                            <Pager
                                visible={true}
                                allowedPageSizes={allowedPageSizes}
                                displayMode='compact'
                                showPageSizeSelector={true}
                                showInfo={true}
                                showNavigationButtons={true}
                            />
                            <FilterRow visible={true} />
                            <FilterPanel visible={true} />
                            <FilterBuilderPopup position={filterBuilderPopupPosition} />

                            <HeaderFilter visible={true} />

                            <GroupPanel visible={true} />
                            <SearchPanel visible={true} />
                            <ColumnChooser
                                enabled={true}
                                mode='select'
                            >
                                <ColumnChooserSearch
                                    enabled={true}
                                />

                                <ColumnChooserSelection
                                    allowSelectAll={true}
                                    selectByClick={true}
                                    recursive={true}
                                />
                            </ColumnChooser>
                            <Grouping autoExpandAll={autoExpandAll} />
                            <StateStoring enabled={true} type="localStorage" storageKey="gridContainerInvoice" />
                            <Export enabled={true} />

                            <Column caption="" alignment="center" width={100} cellRender={editInvoice} />

                            <Column dataField="MovID" dataType="string" width={110} caption="Folio" />
                            <Column dataField="FechaEmision" caption="Fecha Emisión" dataType="date" width={130} />
                            <Column dataField="Estatus" dataType="string" width={120} />
                            <Column dataField="NombreCliente" caption="Nombre Cliente" dataType="string" width={250} />
                            <Column dataField="Plaza" caption="Plaza" dataType="string" width={150} visible={false} />
                            <Column dataField="Pedido" dataType="string" width={150} />

                            <Column dataField="ID" dataType="number" width={150} visible={false} />
                            <Column dataField="Mov" dataType="string" width={190} caption="Tipo Movimiento" visible={false} />
                            <Column dataField="Movimiento" dataType="string" width={190} visible={false} />
                            <Column dataField="SCSituacionCom" dataType="string" width={190} caption="Situación Comisiones" visible={false} />
                            <Column dataField="SCSituacionInc" dataType="string" width={190} caption="Situación Incentivos" visible={false} />

                            <Column dataField="Importe" caption="Importe" dataType="number" width={150} visible={false}>
                                <Format
                                    type="currency"
                                    precision={2}
                                />
                            </Column>
                            <Column dataField="Impuestos" caption="Impuestos" dataType="number" width={150} visible={false}>
                                <Format
                                    type="currency"
                                    precision={2}
                                />
                            </Column>
                            <Column dataField="ImporteTotal" caption="Importe Total" dataType="number" width={150}>
                                <Format
                                    type="currency"
                                    precision={2}
                                />
                            </Column>

                            <Column dataField="Almacen" dataType="string" width={190} caption="Almacén" visible={false} />
                            <Column dataField="NombreAlmacen" dataType="string" width={190} caption="Nombre Almacén" visible={false} />
                            <Column dataField="Agente" dataType="string" width={190} visible={false} />
                            <Column dataField="NombreAgente" dataType="string" width={190} visible={false} />
                            <Column dataField="Cliente" dataType="string" width={190} visible={false} />
                            <Column dataField="Sucursal" dataType="number" width={150} visible={false} />
                            <Column dataField="NombreSucursal" dataType="string" width={190} visible={false} />
                            <Column dataField="Usuario" dataType="string" width={190} visible={false} />
                            <Column dataField="NombreUsuario" dataType="string" width={190} visible={false} />
                            <Column dataField="Origen" dataType="string" width={190} visible={false} />

                            <Column dataField="CFDIFechaTimbrado" dataType="datetime" caption="CFDI - Fecha Certificación" width={190} visible={false} />
                            <Column dataField="CFDIUUID" dataType="string" caption="CFDI - UUID" width={190} visible={false} />
                            <Column dataField="PlazaEntrega" dataType="string" width={250} caption="Plaza Entrega" visible={false} />

                            <Summary>
                                <GroupItem
                                    column="Importe"
                                    summaryType="sum"
                                    valueFormat="$,##0.##"
                                    displayFormat="Importe {0}"
                                    showInGroupFooter={false}>
                                </GroupItem>
                                <GroupItem
                                    column="Impuestos"
                                    summaryType="sum"
                                    valueFormat="$,##0.##"
                                    displayFormat="Impuestos {0}"
                                    showInGroupFooter={false}>
                                </GroupItem>
                                <GroupItem
                                    column="ImporteTotal"
                                    summaryType="sum"
                                    valueFormat="$,##0.##"
                                    displayFormat="Importe Total {0}"
                                    showInGroupFooter={false}>
                                </GroupItem>
                                <TotalItem
                                    column="Importe"
                                    summaryType="sum"
                                    valueFormat="$,##0.##"
                                    displayFormat="Importe {0}"
                                    showInColumn="Importe">
                                </TotalItem>
                                <TotalItem
                                    column="Impuestos"
                                    summaryType="sum"
                                    valueFormat="$,##0.##"
                                    displayFormat="Impuestos {0}"
                                    showInColumn="Impuestos">
                                </TotalItem>
                                <TotalItem
                                    column="ImporteTotal"
                                    summaryType="sum"
                                    valueFormat="$,##0.##"
                                    displayFormat="Importe Total {0}"
                                    showInColumn="ImporteTotal">
                                </TotalItem>
                            </Summary>
                        </DataGrid>
                    </div>
                ) : (<div />)}
                <Route exact path="/Invoice/:ID" render={({ match }) => {
                    return (
                        <div class="bg-gray-200">
                            {isDetail.toString() === 'true' ?
                                (
                                    <div>
                                        <Link to="/Invoice">
                                            <button onClick={cancelEditButton} type="submit" class="mb-4 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
                                                <FontAwesomeIcon icon={faArrowRotateLeft} /> Regresar
                                            </button>
                                        </Link>

                                        {activateConfirmButton.toString() === 'true' && id ?
                                            (
                                                <button onClick={confirmButton} type="submit" class="ml-4 mb-4 bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">
                                                    <FontAwesomeIcon icon={faPlay} /> Afectar
                                                </button>
                                            ) : (
                                                <div class="inline-block"></div>
                                            )}

                                        {activateDeleteButton.toString() === 'true' && id ?
                                            (
                                                <button onClick={deleteButton} type="submit" class="ml-4 mb-4 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
                                                    <FontAwesomeIcon icon={faTrash} /> Eliminar
                                                </button>
                                            ) :
                                            (
                                                <div class="inline-block"></div>
                                            )}


                                        {activatePDFButton.toString() === 'true' && id ?
                                            (
                                                <button onClick={printButton} type="submit" class="ml-4 mb-4 bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded">
                                                    <FontAwesomeIcon icon={faFilePdf} /> PDF
                                                </button>
                                            ) :
                                            (
                                                <div class="inline-block"></div>
                                            )}

                                        {activateRegenerateCFDi && id ?
                                            (
                                                <button onClick={regenerateCFDiFilesButton} type="submit" class="ml-4 mb-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                                                    <FontAwesomeIcon icon={faFiles} /> Regenerar CFDi
                                                </button>
                                            ) :
                                            (
                                                <div class="inline-block"></div>
                                            )}

                                        {activateSendCFDi && id ?
                                            (
                                                <button onClick={sendCFDiFilesButton} type="submit" class="ml-4 mb-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                                                    <FontAwesomeIcon icon={faEnvelope} /> Reenviar CFDi
                                                </button>
                                            ) :
                                            (
                                                <div class="inline-block"></div>
                                            )}

                                        <div class="bg-white p-4 shadow-xl">
                                            <Form
                                                id="formTransaccion"
                                                labelMode={'outside'}
                                                formData={invoicePayload}
                                                readOnly={true}
                                                showColonAfterLabel={false}
                                                labelLocation={'top'}
                                                ref={formDatosGeneralesRef}
                                            >
                                                <TabbedItem>
                                                    <TabPanelOptions deferRendering={false} showNavButtons={true} />
                                                    <Tab title="Datos Generales" colCount={2}>
                                                        <GroupItem caption="General" colCount={2}>
                                                            <Item cssClass="boldText" colSpan={2} dataField="header.Movimiento" editorOptions={movimientoEditorOptions} >
                                                                <Label text={"Movimiento"} />
                                                            </Item>
                                                            <Item dataField="header.FechaEmision" editorType="dxDateBox" editorOptions={fechaEmisionEditorOptions}>
                                                                <Label text={"Fecha Emisión"} />
                                                            </Item>
                                                            <Item dataField="header.SCRama" editorOptions={pedidoEditorOptions} >
                                                                <Label text={"Pedido"} />
                                                            </Item>
                                                            <Item dataField="header.Condicion" editorType="dxSelectBox" editorOptions={condicionEditorOptions} >
                                                                <RequiredRule message="Condiciones es requerido" />
                                                                <Label text={"Condiciones"} />
                                                            </Item>
                                                            <Item dataField="header.ListaPreciosEsp" editorType="dxSelectBox" editorOptions={listaPreciosEditorOptions} >
                                                                <RequiredRule message="Lista de Precios es requerido" />
                                                                <Label text={"Lista de Precios"} />
                                                            </Item>
                                                            <Item dataField="header.Agente" editorType="dxSelectBox" editorOptions={agenteEditorOptions} colSpan={2} >
                                                                <RequiredRule message="Vendedor es requerido" />
                                                                <Label text={"Vendedor"} />
                                                            </Item>
                                                        </GroupItem>
                                                        <GroupItem caption="Cliente" colCount={2}>
                                                            <Item dataField="header.Cliente" colSpan={2} editorType="dxLookup" editorOptions={clienteEditorOptions} >
                                                                <Label text={"Cliente"} />
                                                            </Item>
                                                            <Item dataField="header.cte_RFC" editorOptions={rfcEditorOptions}>
                                                                <Label text={"RFC"} />
                                                            </Item>
                                                        </GroupItem>
                                                        <GroupItem caption="Artículos" colSpan={2}>
                                                            <DataGrid
                                                                id="gridArticulos"
                                                                ref={gridArticulosRef}
                                                                columnsAutoWidth={true}
                                                                dataSource={invoicePayload.detail}
                                                                showBorders={true}
                                                                showColumnLines={true}
                                                                showRowLines={true}
                                                                rowAlternationEnabled={true}
                                                                allowColumnResizing={true}
                                                                columnResizingMode="widget"
                                                                allowColumnReordering={false}
                                                                repaintChangesOnly={true}
                                                            >
                                                                <Scrolling
                                                                    mode='virtual'
                                                                />
                                                                <Editing
                                                                    mode="cell"
                                                                    newRowPosition="last"
                                                                    allowUpdating={false}
                                                                    allowAdding={false}
                                                                    allowDeleting={false}
                                                                />

                                                                <Sorting mode="none" />

                                                                <Column dataField="Renglon" width={190} visible={false} />
                                                                <Column dataField="Articulo" caption="Artículo" width={600} calculateDisplayValue="ArtDescripcion" >
                                                                    <Lookup
                                                                        dataSource={storeArticulo}
                                                                        displayExpr="name"
                                                                        valueExpr="value"
                                                                        searchEnabled={true}
                                                                        showClearButton={true}
                                                                        acceptCustomValue={false}
                                                                        clearButtonText='Cerrar'
                                                                        noDataText='No se encontraron resultados'
                                                                    />
                                                                    <RequiredRule message="Artículo es requerido" />
                                                                </Column>
                                                                <Column dataField="ArtDescripcion" width={400} allowEditing={false} visible={false} />
                                                                <Column dataField="Cantidad" width={80} dataType="number" editorOptions={cantidadEditorOptions}>
                                                                    <RequiredRule message="Cantidad es requerida" />
                                                                </Column>
                                                                <Column dataField="Precio" width={110} dataType="number">
                                                                    <RequiredRule message="Precio es requerido" />
                                                                    <Format
                                                                        type="currency"
                                                                        precision={2}

                                                                    />
                                                                </Column>
                                                                <Column dataField="Importe" width={150} allowEditing={false} dataType="number">
                                                                    <Format
                                                                        type="currency"
                                                                        precision={2}
                                                                    />
                                                                </Column>
                                                                <Summary>
                                                                    <TotalItem
                                                                        column="Importe"
                                                                        summaryType="sum"
                                                                        valueFormat="$,##0.##"
                                                                        displayFormat="Total {0}"
                                                                        showInColumn="Importe"
                                                                    />
                                                                </Summary>
                                                            </DataGrid>
                                                        </GroupItem>
                                                    </Tab>
                                                    <Tab title="Observaciones" >
                                                        <Item dataField="header.Observaciones" editorType="dxTextArea" editorOptions={observacionesEditorOptions}>
                                                            <Label text="Observaciones" visible={false} />
                                                        </Item>
                                                    </Tab>
                                                    <Tab title="Registros Relacionados">
                                                        <DataGrid
                                                            id="gridRelatedRecords"
                                                            columnsAutoWidth={true}
                                                            dataSource={invoicePayload.relatedRecords}
                                                            allowColumnReordering={true}
                                                            allowColumnResizing={true}
                                                            columnResizingMode={"widget"}
                                                            showBorders={true}
                                                            rowAlternationEnabled={true}
                                                            showRowLines={true}
                                                            showColumnLines={true}
                                                        >
                                                            <HeaderFilter visible={false} />

                                                            <Column dataField="name" dataType="string" width={300} caption="Transacción" />
                                                            <Column caption="Estatus" alignment="center" width={150} cellRender={estatusRelatedRecords} />
                                                            <Column caption="" alignment="center" width={50} cellRender={openRelatedRecordButton} />
                                                            <Column dataField="FechaEmision" dataType="date" width={150} caption="Fecha Emisión" />
                                                            <Column dataField="ImporteTotal" caption="Importe Total" dataType="number" width={180} >
                                                                <Format
                                                                    type="currency"
                                                                    precision={2}
                                                                />
                                                            </Column>
                                                        </DataGrid>
                                                    </Tab>

                                                    <Tab title="CFDi" colCount={2}>
                                                        <Item dataField="header.cfdi_CFDIFechaTimbrado" editorOptions={datosCFDIEditorOptions}>
                                                            <Label text="Fecha Certificación" />
                                                        </Item>
                                                        <Item dataField="header.cfdi_CFDIUUID" editorOptions={datosCFDIEditorOptions}>
                                                            <Label text="UUID" />
                                                        </Item>
                                                        <Item dataField="header.cfdi_CFDITipoComprobante" editorOptions={datosCFDIEditorOptions}>
                                                            <Label text="Tipo de Comprobante" />
                                                        </Item>
                                                    </Tab>
                                                    <Tab title="Log Estatus">
                                                        <DataGrid
                                                            id="gridLogEstatus"
                                                            columnsAutoWidth={true}
                                                            dataSource={invoicePayload.movEstatusLog}
                                                            allowColumnReordering={true}
                                                            allowColumnResizing={true}
                                                            columnResizingMode={"widget"}
                                                            showBorders={true}
                                                            rowAlternationEnabled={true}
                                                            showRowLines={true}
                                                            showColumnLines={true}
                                                        >
                                                            <HeaderFilter visible={false} />
                                                            <Column dataField="Usuario" dataType="string" width={350} caption="Usuario" />
                                                            <Column caption="Estatus" alignment="center" width={150} cellRender={estatusRelatedRecords} />
                                                            <Column dataField="Fecha" dataType="datetime" width={300} caption="Fecha" />
                                                        </DataGrid>
                                                    </Tab>
                                                    <Tab title="Archivos Anexos" >
                                                        <div className="widget-container">
                                                            <FileUploader
                                                                multiple={true}
                                                                accept="*"
                                                                uploadMode="useButtons"
                                                                chunkSize={20000000}
                                                                maxFileSize={20000000}
                                                                uploadChunk={uploadChunk}
                                                                disabled={!activateAttachments}
                                                            />
                                                        </div>
                                                        <div id="wrapper" className="show-widget" >
                                                            <div id="widget-area">
                                                                <FileManager id="attachmentManager" fileSystemProvider={attachments} onItemDeleting={onAttachmentDeleting} onItemDownloading={onItemDownloading} onSelectedFileOpened={onAttachmentOpen}>
                                                                    <Permissions
                                                                        create={false}
                                                                        copy={false}
                                                                        move={false}
                                                                        delete={false}
                                                                        rename={false}
                                                                        upload={false}
                                                                        download={true}
                                                                    >
                                                                    </Permissions>
                                                                </FileManager>
                                                            </div>
                                                        </div>
                                                    </Tab>
                                                </TabbedItem>
                                            </Form>
                                        </div>
                                    </div>
                                ) :
                                (
                                    <div></div>
                                )
                            }
                        </div>
                    )
                }}
                />
            </div>
        </div>
    );
}
