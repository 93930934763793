import React, { useState, useEffect, useRef } from 'react';

import secureLocalStorage from "react-secure-storage";

//react-router-dom
import {
  Route,
  NavLink,
  HashRouter,
  useHistory
} from "react-router-dom";

//Importaciones para el menu
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

//Modulos de Super Colchones
import Home from "./Home/Home";
import Rastreopedidos from "./Rastreopedidos/Rastreopedidos.js";
import DownloadInvoice from "./DownloadInvoice/DownloadInvoice.js";
import Attendance from "./Attendance/Attendance.js";
import Pricelist from "./Pricelist/Pricelist.js";
import Commission from "./Commission/Commission.js";
import Incentive from "./Incentive/Incentive.js";
import Checklist from "./Checklist/Checklist.js";
import ChecklistAud from "./ChecklistAud/ChecklistAud.js";
import ChecklistVeh from "./ChecklistVeh/ChecklistVeh.js";
import Document from "./Document/Document.js";
import DocumentViewer from "./DocumentViewer/DocumentViewer.js";
import Vacation from "./Vacation/Vacation.js";
import Signin from "./Signin/Signin.js";
import Signout from "./Signout/Signout.js";
import Signup from "./Signup/Signup.js";
import Users from "./Users/Users.js";
import PdfTemplate from "./PdfTemplate/PdfTemplate.js";
import PdfRender from "./PdfRender/PdfRender.js";
import PdfEditor from "./PdfEditor/PdfEditor.js";
import PasswordChange from "./PasswordChange/PasswordChange.js";
import ProcessInvoice from "./ProcessInvoice/ProcessInvoice.js";
import ProcessInvoiceLoc from "./ProcessInvoiceLoc/ProcessInvoiceLoc.js";
import ProcessInvoiceWHS from "./ProcessInvoiceWHS/ProcessInvoiceWHS.js";
import Estimate from "./Estimate/Estimate.js";
import LostDeal from "./LostDeal/LostDeal.js";
import SalesOrder from "./SalesOrder/SalesOrder.js";
import Invoice from "./Invoice/Invoice.js";
import CustomerPayment from "./CustomerPayment/CustomerPayment.js";
import SalesOrderMod from "./SalesOrderMod/SalesOrderMod.js";
import CashRegister from "./CashRegister/CashRegister.js";
import DirectDelivery from "./DirectDelivery/DirectDelivery.js";
import DirectReturn from "./DirectReturn/DirectReturn.js";
import DeliveryRequest from "./DeliveryRequest/DeliveryRequest.js";
import ReturnRequest from "./ReturnRequest/ReturnRequest.js";
import TransferOrder from "./TransferOrder/TransferOrder.js";
import ShipmentRequest from "./ShipmentRequest/ShipmentRequest.js";
import InvTransferOrder from "./InvTransferOrder/InvTransferOrder.js";
import InvItemFulfillment from "./InvItemFulfillment/InvItemFulfillment.js";
import InvItemReceipt from "./InvItemReceipt/InvItemReceipt.js";
import Kardex from "./Kardex/Kardex.js";
import InvLevels from "./InvLevels/InvLevels.js";
import InvLevelsIncCost from "./InvLevelsIncCost/InvLevelsIncCost.js";
import SalesCompReport from "./SalesCompReport/SalesCompReport.js";
import InventoryTransfer from "./InventoryTransfer/InventoryTransfer.js";
import LoginInfo from "./LoginInfo/LoginInfo.js";
import LabelWMS from "./LabelWMS/LabelWMS.js";
import ExploreBarcode from "./ExploreBarcode/ExploreBarcode.js";
import ExploreBarcodeCosts from "./ExploreBarcodeCosts/ExploreBarcodeCosts.js";
import MoveItemWMS from './MoveItemWMS/MoveItemWMS';
import ReceiveOrderWMS from "./ReceiveOrderWMS/ReceiveOrderWMS.js";
import FulfillmentOrderWMS from "./FulfillmentOrderWMS/FulfillmentOrderWMS.js";
import WarehouseWMS from "./WarehouseWMS/WarehouseWMS.js";
import WarehousePositionWMS from "./WarehousePositionWMS/WarehousePositionWMS.js";
import ItemWMS from "./ItemWMS/ItemWMS.js";
import AdjustItemWMS from "./AdjustItemWMS/AdjustItemWMS.js";
import FulfillmentOrderWMSPriority from "./FulfillmentOrderWMSPriority/FulfillmentOrderWMSPriority.js";
import LockSalesOrder from './LockSalesOrder/LockSalesOrder.js';
import UnlockSalesOrder from './UnlockSalesOrder/UnlockSalesOrder.js';
import ItemCountWMS from './ItemCountWMS/ItemCountWMS.js';
import CreateItemCountWMS from './CreateItemCountWMS/CreateItemCountWMS.js';
import POSuggestionsReport from './POSuggestionsReport/POSuggestionsReport.js';
import ItemPOSuggestion from './ItemPOSuggestion/ItemPOSuggestion.js';
import WarehousePOSuggestion from './WarehousePOSuggestion/WarehousePOSuggestion.js';


import logo from './supercolchones.png';

import { faHome } from "@fortawesome/pro-solid-svg-icons";
import { faLockKeyhole } from "@fortawesome/pro-solid-svg-icons";
import { faLockKeyholeOpen } from "@fortawesome/pro-solid-svg-icons";
import { faBarcode } from "@fortawesome/pro-solid-svg-icons";
import { faTruck } from "@fortawesome/pro-solid-svg-icons";
import { faUsers } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDollarSign } from "@fortawesome/pro-solid-svg-icons";
import { faSignOutAlt } from "@fortawesome/pro-solid-svg-icons";
import { faUserPlus } from "@fortawesome/pro-solid-svg-icons";
import { faShoppingBag } from "@fortawesome/pro-solid-svg-icons";
import { faFileDownload } from "@fortawesome/pro-solid-svg-icons";
import { faMapMarkerAlt } from "@fortawesome/pro-solid-svg-icons";
import { faSackDollar } from "@fortawesome/pro-solid-svg-icons";
import { faUserSecret } from "@fortawesome/pro-solid-svg-icons";
import { faTrophy } from "@fortawesome/pro-solid-svg-icons";
import { faBook } from "@fortawesome/pro-solid-svg-icons";
import { faAddressCard } from "@fortawesome/pro-solid-svg-icons";
import { faUser } from "@fortawesome/pro-solid-svg-icons";
import { faSignInAlt } from "@fortawesome/pro-solid-svg-icons";
import { faWrench } from "@fortawesome/pro-solid-svg-icons";
import { faBed } from "@fortawesome/pro-solid-svg-icons";
import { faUmbrellaBeach } from "@fortawesome/pro-solid-svg-icons";
import { faTruckMoving } from "@fortawesome/pro-solid-svg-icons";
import { faUserTie } from "@fortawesome/pro-solid-svg-icons";
import { faFilePdf } from "@fortawesome/pro-solid-svg-icons";
import { faKey } from "@fortawesome/pro-solid-svg-icons";
import { faCircleUser } from "@fortawesome/pro-solid-svg-icons";
import { faChartSimple } from "@fortawesome/pro-solid-svg-icons";
import { faGear } from "@fortawesome/pro-solid-svg-icons";
import { faMapLocationDot } from "@fortawesome/pro-solid-svg-icons";
import { faWarehouse } from "@fortawesome/pro-solid-svg-icons";
import { faLeft } from "@fortawesome/pro-solid-svg-icons";
import { faRight } from "@fortawesome/pro-solid-svg-icons";
import { faInboxIn } from "@fortawesome/pro-solid-svg-icons";
import { faInboxOut } from "@fortawesome/pro-solid-svg-icons";
import { faCommentDollar } from "@fortawesome/pro-solid-svg-icons";
import { faThumbsDown } from "@fortawesome/pro-solid-svg-icons";
import { faHandshake } from "@fortawesome/pro-solid-svg-icons";
import { faFileInvoiceDollar } from "@fortawesome/pro-solid-svg-icons";
import { faPersonDolly } from "@fortawesome/pro-solid-svg-icons";
import { faPersonCarryBox } from "@fortawesome/pro-solid-svg-icons";
import { faTruckFast } from "@fortawesome/pro-solid-svg-icons";
import { faHandHoldingBox } from "@fortawesome/pro-solid-svg-icons";
import { faRightLeft } from "@fortawesome/pro-solid-svg-icons";
import { faPenNib } from "@fortawesome/pro-solid-svg-icons";
import { faForklift } from "@fortawesome/pro-solid-svg-icons";
import { faCashRegister } from "@fortawesome/pro-solid-svg-icons";
import { faPiggyBank } from "@fortawesome/pro-solid-svg-icons";
import { faPalletBoxes } from "@fortawesome/pro-solid-svg-icons";
import { faTruckFlatbed } from "@fortawesome/pro-solid-svg-icons";
import { faFileChartColumn } from "@fortawesome/pro-solid-svg-icons";
import { faWineGlassCrack } from "@fortawesome/pro-solid-svg-icons";
import { faScannerGun } from "@fortawesome/pro-solid-svg-icons";
import { faWarehouseFull } from "@fortawesome/pro-solid-svg-icons";
import { faShelves } from "@fortawesome/pro-solid-svg-icons";
import { faBedFront } from "@fortawesome/pro-solid-svg-icons";
import { faCartFlatbedSuitcase } from "@fortawesome/pro-solid-svg-icons";
import { faPenToSquare } from "@fortawesome/pro-solid-svg-icons";
import { faSort } from "@fortawesome/pro-solid-svg-icons";
import { faArrowsSpin } from "@fortawesome/pro-solid-svg-icons";
import { faToolbox } from "@fortawesome/pro-solid-svg-icons";

import Amplify from 'aws-amplify';
import { Auth, API, Hub } from 'aws-amplify';
import awsconfig from './aws-exports';

import { Lookup, DropDownOptions } from 'devextreme-react/lookup';
import 'devextreme/data/odata/store';
import CustomStore from 'devextreme/data/custom_store';

import gsItem from './GlobalSearch/gsItem.js';
import gsGroup from './GlobalSearch/gsGroup.js';

import './Main.css';

import esMessages from "devextreme/localization/messages/es.json";

import { locale, loadMessages } from "devextreme/localization";

import config from "devextreme/core/config";
import { licenseKey } from './devextreme-license.ts'; 

Amplify.configure(awsconfig);

export default function Main(props) {
  const history = useHistory();

  // MENU HOME
  const [anchorHome, setAnchorHome] = React.useState(null);
  const openHome = Boolean(anchorHome);

  function handleClickHome(event) {
    setAnchorHome(event.currentTarget);
  };
  function handleCloseHome() {
    setAnchorHome(null);
  };

  //Ref para el form de Datos Arqueo
  const [globalSearchRef, setGlobalSearchRef] = useState(React.createRef());

  // MENU CUSTOMER
  const [anchorCustomer, setAnchorCustomer] = React.useState(null);
  const openCustomer = Boolean(anchorCustomer);

  function handleClickCustomer(event) {
    setAnchorCustomer(event.currentTarget);
  };
  function handleCloseCustomer() {
    setAnchorCustomer(null);
  };

  // MENU EMPLOYEE
  const [anchorEmployee, setAnchorEmployee] = React.useState(null);
  const openEmployee = Boolean(anchorEmployee);

  function handleClickEmployee(event) {
    setAnchorEmployee(event.currentTarget);
  };
  function handleCloseEmployee() {
    setAnchorEmployee(null);
  };

  // MENU SALES
  const [anchorSales, setAnchorSales] = React.useState(null);
  const openSales = Boolean(anchorSales);

  function handleClickSales(event) {
    setAnchorSales(event.currentTarget);
  };
  function handleCloseSales() {
    setAnchorSales(null);
  };

  // MENU LOGISTICA
  const [anchorLogistics, setAnchorLogistics] = React.useState(null);
  const openLogistics = Boolean(anchorLogistics);

  function handleClickLogistics(event) {
    setAnchorLogistics(event.currentTarget);
  };
  function handleCloseLogistics() {
    setAnchorLogistics(null);
  };

  // MENU WMS
  const [anchorWMS, setAnchorWMS] = React.useState(null);
  const openWMS = Boolean(anchorWMS);

  function handleClickWMS(event) {
    setAnchorWMS(event.currentTarget);
  };
  function handleCloseWMS() {
    setAnchorWMS(null);
  };

  // MENU REPORTS
  const [anchorReports, setAnchorReports] = React.useState(null);
  const openReports = Boolean(anchorReports);

  function handleClickReports(event) {
    setAnchorReports(event.currentTarget);
  };
  function handleCloseReports() {
    setAnchorReports(null);
  };

  function handleKibana() {
    window.open("https://f05b68279a09437698c8c3889d7970c3.us-east-1.aws.found.io:9243", "_blank");
    setAnchorReports(null);
  };

  // MENU ACCOUNT
  const [anchorAccount, setAnchorAccount] = React.useState(null);
  const openAccount = Boolean(anchorAccount);

  function handleClickAccount(event) {
    setAnchorAccount(event.currentTarget);
  };
  function handleCloseAccount() {
    setAnchorAccount(null);
  };

  // MENU CONFIGURATION
  const [anchorConfiguration, setAnchorConfiguration] = React.useState(null);
  const openConfiguration = Boolean(anchorConfiguration);

  function handleClickConfiguration(event) {
    setAnchorConfiguration(event.currentTarget);
  };
  function handleCloseConfiguration() {
    setAnchorConfiguration(null);
  };

  const [status, setStatus] = useState('signedout');
  const [username, setUsername] = useState('');
  const [isadmin, setIsadmin] = useState('false');
  const [sucursal, setSucursal] = useState('');
  const [nombreSucursal, setNombreSucursal] = useState('');
  const [personal, setPersonal] = useState('');
  const [usuario, setUsuario] = useState('');
  const [agente, setAgente] = useState('');
  const [attendance, setAttendance] = useState(false);
  const [pricelist, setPricelist] = useState(false);
  const [commission, setCommission] = useState(false);
  const [incentive, setIncentive] = useState(false);
  const [checklist, setChecklist] = useState(false);
  const [checklistAud, setChecklistAud] = useState(false);
  const [checklistVeh, setChecklistVeh] = useState(false);
  const [pdfDocument, setPdfDocument] = useState(false);
  const [vacation, setVacation] = useState(false);
  const [checklistAccess, setChecklistAccess] = useState('');
  const [attendanceAccess, setAttendanceAccess] = useState('');
  const [vacationAccess, setVacationAccess] = useState('');
  const [salesAccess, setSalesAccess] = useState('');
  const [kibana, setKibana] = useState(false);
  const [procesarFacturas, setProcesarFacturas] = useState(false);
  const [procesarFacturasSucursal, setProcesarFacturasSucursal] = useState(false);
  const [procesarFacturasBodega, setProcesarFacturasBodega] = useState(false);
  const [afectarFacturas, setAfectarFacturas] = useState(false);

  const [newChecklist, setNewChecklist] = useState(false);
  const [newChecklistAud, setNewChecklistAud] = useState(false);
  const [newChecklistVeh, setNewChecklistVeh] = useState(false);
  const [estimate, setEstimate] = useState(false);
  const [lostDeal, setLostDeal] = useState(false);
  const [salesOrder, setSalesOrder] = useState(false);
  const [invoice, setInvoice] = useState(false);
  const [directDelivery, setDirectDelivery] = useState(false);
  const [directReturn, setDirectReturn] = useState(false);
  const [deliveryRequest, setDeliveryRequest] = useState(false);
  const [returnRequest, setReturnRequest] = useState(false);
  const [transferOrder, setTransferOrder] = useState(false);
  const [salesOrderMod, setSalesOrderMod] = useState(false);
  const [shipmentRequest, setShipmentRequest] = useState(false);
  const [cashRegister, setCashRegister] = useState(false);
  const [customerPayment, setCustomerPayment] = useState(false);
  const [invTransferOrder, setInvTransferOrder] = useState(false);
  const [repComparativoVentas, setRepComparativoVentas] = useState(false);
  const [poSuggestionsReport, setPOSuggestionsReport] = useState(false);
  const [poSuggestionsReportCfg, setPOSuggestionsReportCfg] = useState(false);
  const [exploreBarcodes, setExploreBarcodes] = useState(false);
  const [exploreBarcodeCosts, setExploreBarcodeCosts] = useState(false);

  const [inventoryTransfer, setInventoryTransfer] = useState(false);
  const [invLevels, setInvLevels] = useState(false);
  const [invLevelsIncCost, setInvLevelsIncCost] = useState(false);
  const [kardex, setKardex] = useState(false);
  const [estimateLevel, setEstimateLevel] = useState('');
  const [lostDealLevel, setLostDealLevel] = useState('');
  const [salesOrderLevel, setSalesOrderLevel] = useState('');
  const [invoiceLevel, setInvoiceLevel] = useState('');
  const [directDeliveryLevel, setDirectDeliveryLevel] = useState('');
  const [directReturnLevel, setDirectReturnLevel] = useState('');
  const [deliveryRequestLevel, setDeliveryRequestLevel] = useState('');
  const [returnRequestLevel, setReturnRequestLevel] = useState('');
  const [transferOrderLevel, setTransferOrderLevel] = useState('');
  const [salesOrderModLevel, setSalesOrderModLevel] = useState('');
  const [shipmentRequestLevel, setShipmentRequestLevel] = useState('');
  const [cashRegisterLevel, setCashRegisterLevel] = useState('');
  const [customerPaymentLevel, setCustomerPaymentLevel] = useState('');
  const [invTransferOrderLevel, setInvTransferOrderLevel] = useState('');
  const [inventoryTransferLevel, setInventoryTransferLevel] = useState('');
  const [approveDelivery, setApproveDelivery] = useState(false);
  const [voidDeliveryTransactions, setVoidDeliveryTransactions] = useState(false);
  const [salesOrderModUpdatePrices, setSalesOrderModUpdatePrices] = useState(false);
  const [approveSalesOrderMod, setApproveSalesOrderMod] = useState(false);
  const [regenerateCFDi, setRegenerateCFDi] = useState(false);
  const [sendCFDi, setSendCFDi] = useState(false);
  const [approveInvTransferOrder, setApproveInvTransferOrder] = useState(false);
  const [approveInventoryTransfer, setApproveInventoryTransfer] = useState(false);
  const [invItemFulfillment, setInvItemFulfillment] = useState(false);
  const [invItemFulfillmentLevel, setInvItemFulfillmentLevel] = useState('');
  const [invItemReceipt, setInvItemReceipt] = useState(false);
  const [invItemReceiptLevel, setInvItemReceiptLevel] = useState('');
  const [createLabelWMS, setCreateLabelWMS] = useState(false);
  const [createLabelWMSLevel, setCreateLabelWMSLevel] = useState('');
  const [receiveOrderWMS, setReceiveOrderWMS] = useState(false);
  const [receiveOrderWMSLevel, setReceiveOrderWMSLevel] = useState('');
  const [fulfillmentOrderWMS, setFulfillmentOrderWMS] = useState(false);
  const [assignFulfillmentOrderWMS, setAssignFulfillmentOrderWMS] = useState(false);
  const [fulfillmentOrderWMSLevel, setFulfillmentOrderWMSLevel] = useState('');
  const [adjustItemWMS, setAdjustItemWMS] = useState(false);
  const [adjustItemWMSLevel, setAdjustItemWMSLevel] = useState('');
  const [approveAdjustItemWMS, setApproveAdjustItemWMS] = useState(false);
  const [moveItemWMS, setMoveItemWMS] = useState(false);
  const [itemCountWMS, setItemCountWMS] = useState(false);
  const [approveItemCountWMS, setApproveItemCountWMS] = useState(false);
  const [itemCountWMSLevel, setItemCountWMSLevel] = useState('');

  const [closeSalesOrder, setCloseSalesOrder] = useState(false);

  const store = new CustomStore({
    load(loadOptions) {
      let sqlFilter = '';
      [
        'filter'
      ].forEach((i) => {
        sqlFilter = loadOptions.searchValue;
      });

      async function getRecords() {
        var records = await getGlobalRecords(sqlFilter);

        var groupedRecords = [];

        for (var recordIndex in records) {
          var singleRecords = records[recordIndex];

          var groupedIndex = -1;

          for (var groupedRecordsIndex in groupedRecords) {
            var singlegroupedRecords = groupedRecords[groupedRecordsIndex];

            if (singlegroupedRecords.key === singleRecords.recordtype) {
              groupedIndex = groupedRecordsIndex;
              break;
            }
          }

          if (groupedIndex === -1) {
            groupedRecords.push({
              key: singleRecords.recordtype,
              items: [],
              count: 0
            })

            groupedIndex = groupedRecords.length - 1;
          }

          if (singleRecords.recordtype === groupedRecords[groupedIndex].key) {
            groupedRecords[groupedIndex].items.push(singleRecords);
            groupedRecords[groupedIndex].count++;
          }
        }

        return {
          data: groupedRecords,
          totalCount: records.length,
          groupCount: groupedRecords.length
        };
      }

      return getRecords()
        .then(function (result) {
          return result;
        })
        .catch(() => { throw new Error('Data Loading Error'); });
    },
  });

  function isNotEmpty(value) {
    return value !== undefined && value !== null && value !== '';
  }

  async function getUserAttributes(user, newStatus) {
    // Obtenemos los atributos de los usuarios desde SQL
    let apiName = 'AdminSC';
    let path = '/procesausuariowebcfg';
    var admin = false;

    let myInit = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
      },
      queryStringParameters: {
        action: 'payload',
        username: JSON.parse(JSON.stringify(user)).username,
        usernamereq: JSON.parse(JSON.stringify(user)).username
      }
    }

    var userAttributes = await API.get(apiName, path, myInit);

    if (!userAttributes.attributes) {
      Signout();
      secureLocalStorage.clear();
      localStorage.clear();
      return;
    }

    // Normalizamos los datos para que el encriptador no genere basura en Application

    if (userAttributes.attributes.Sucursal === "0") {
      userAttributes.attributes.Sucursal = 0;
    }
    else {
      if (userAttributes.attributes.Sucursal)
        userAttributes.attributes.Sucursal = parseInt(userAttributes.attributes.Sucursal);
      else {
        userAttributes.attributes.Sucursal = 0;
      }
    }

    userAttributes.attributes.NombreSucursal = (userAttributes.attributes.NombreSucursal ? userAttributes.attributes.NombreSucursal : '');
    userAttributes.attributes.Personal = (userAttributes.attributes.Personal ? userAttributes.attributes.Personal : '');
    userAttributes.attributes.Usuario = (userAttributes.attributes.Usuario ? userAttributes.attributes.Usuario : '');
    userAttributes.attributes.Agente = (userAttributes.attributes.Agente ? userAttributes.attributes.Agente : '');

    userAttributes.attributes.Attendance = (userAttributes.attributes.Attendance ? userAttributes.attributes.Attendance : false);
    userAttributes.attributes.PriceList = (userAttributes.attributes.PriceList ? userAttributes.attributes.PriceList : false);
    userAttributes.attributes.Commission = (userAttributes.attributes.Commission ? userAttributes.attributes.Commission : false);
    userAttributes.attributes.Incentive = (userAttributes.attributes.Incentive ? userAttributes.attributes.Incentive : false);
    userAttributes.attributes.CheckList = (userAttributes.attributes.CheckList ? userAttributes.attributes.CheckList : false);
    userAttributes.attributes.CheckListAud = (userAttributes.attributes.CheckListAud ? userAttributes.attributes.CheckListAud : false);
    userAttributes.attributes.CheckListVeh = (userAttributes.attributes.CheckListVeh ? userAttributes.attributes.CheckListVeh : false);
    userAttributes.attributes.Document = (userAttributes.attributes.Document ? userAttributes.attributes.Document : false);
    userAttributes.attributes.Vacation = (userAttributes.attributes.Vacation ? userAttributes.attributes.Vacation : false);
    userAttributes.attributes.Kibana = (userAttributes.attributes.Kibana ? userAttributes.attributes.Kibana : false);
    userAttributes.attributes.ProcesarFacturas = (userAttributes.attributes.ProcesarFacturas ? userAttributes.attributes.ProcesarFacturas : false);
    userAttributes.attributes.ProcesarFacturasSucursal = (userAttributes.attributes.ProcesarFacturasSucursal ? userAttributes.attributes.ProcesarFacturasSucursal : false);
    userAttributes.attributes.ProcesarFacturasBodega = (userAttributes.attributes.ProcesarFacturasBodega ? userAttributes.attributes.ProcesarFacturasBodega : false);
    userAttributes.attributes.AfectarFacturas = (userAttributes.attributes.AfectarFacturas ? userAttributes.attributes.AfectarFacturas : false);
    userAttributes.attributes.NewCheckList = (userAttributes.attributes.NewCheckList ? userAttributes.attributes.NewCheckList : false);
    userAttributes.attributes.NewCheckListAud = (userAttributes.attributes.NewCheckListAud ? userAttributes.attributes.NewCheckListAud : false);
    userAttributes.attributes.NewCheckListVeh = (userAttributes.attributes.NewCheckListVeh ? userAttributes.attributes.NewCheckListVeh : false);
    userAttributes.attributes.Estimate = (userAttributes.attributes.Estimate ? userAttributes.attributes.Estimate : false);
    userAttributes.attributes.LostDeal = (userAttributes.attributes.LostDeal ? userAttributes.attributes.LostDeal : false);
    userAttributes.attributes.SalesOrder = (userAttributes.attributes.SalesOrder ? userAttributes.attributes.SalesOrder : false);
    userAttributes.attributes.Invoice = (userAttributes.attributes.Invoice ? userAttributes.attributes.Invoice : false);
    userAttributes.attributes.DirectDelivery = (userAttributes.attributes.DirectDelivery ? userAttributes.attributes.DirectDelivery : false);
    userAttributes.attributes.DirectReturn = (userAttributes.attributes.DirectReturn ? userAttributes.attributes.DirectReturn : false);
    userAttributes.attributes.DeliveryRequest = (userAttributes.attributes.DeliveryRequest ? userAttributes.attributes.DeliveryRequest : false);
    userAttributes.attributes.ReturnRequest = (userAttributes.attributes.ReturnRequest ? userAttributes.attributes.ReturnRequest : false);
    userAttributes.attributes.TransferOrder = (userAttributes.attributes.TransferOrder ? userAttributes.attributes.TransferOrder : false);
    userAttributes.attributes.SalesOrderMod = (userAttributes.attributes.SalesOrderMod ? userAttributes.attributes.SalesOrderMod : false);
    userAttributes.attributes.ShipmentRequest = (userAttributes.attributes.ShipmentRequest ? userAttributes.attributes.ShipmentRequest : false);
    userAttributes.attributes.CashRegister = (userAttributes.attributes.CashRegister ? userAttributes.attributes.CashRegister : false);
    userAttributes.attributes.CustomerPayment = (userAttributes.attributes.CustomerPayment ? userAttributes.attributes.CustomerPayment : false);
    userAttributes.attributes.InvTransferOrder = (userAttributes.attributes.InvTransferOrder ? userAttributes.attributes.InvTransferOrder : false);
    userAttributes.attributes.RepComparativoVentas = (userAttributes.attributes.RepComparativoVentas ? userAttributes.attributes.RepComparativoVentas : false);
    userAttributes.attributes.POSuggestionsReport = (userAttributes.attributes.POSuggestionsReport ? userAttributes.attributes.POSuggestionsReport : false);
    userAttributes.attributes.POSuggestionsReportCfg = (userAttributes.attributes.POSuggestionsReportCfg ? userAttributes.attributes.POSuggestionsReportCfg : false);
    userAttributes.attributes.ExploreBarcodes = (userAttributes.attributes.ExploreBarcodes ? userAttributes.attributes.ExploreBarcodes : false);
    userAttributes.attributes.ExploreBarcodeCosts = (userAttributes.attributes.ExploreBarcodeCosts ? userAttributes.attributes.ExploreBarcodeCosts : false);
    userAttributes.attributes.InventoryTransfer = (userAttributes.attributes.InventoryTransfer ? userAttributes.attributes.InventoryTransfer : false);
    userAttributes.attributes.InvLevels = (userAttributes.attributes.InvLevels ? userAttributes.attributes.InvLevels : false);
    userAttributes.attributes.InvLevelsIncCost = (userAttributes.attributes.InvLevelsIncCost ? userAttributes.attributes.InvLevelsIncCost : false);
    userAttributes.attributes.Kardex = (userAttributes.attributes.Kardex ? userAttributes.attributes.Kardex : false);
    userAttributes.attributes.ApproveDelivery = (userAttributes.attributes.ApproveDelivery ? userAttributes.attributes.ApproveDelivery : false);
    userAttributes.attributes.ApproveInvTransferOrder = (userAttributes.attributes.ApproveInvTransferOrder ? userAttributes.attributes.ApproveInvTransferOrder : false);
    userAttributes.attributes.ApproveInventoryTransfer = (userAttributes.attributes.ApproveInventoryTransfer ? userAttributes.attributes.ApproveInventoryTransfer : false);
    userAttributes.attributes.VoidDeliveryTransactions = (userAttributes.attributes.VoidDeliveryTransactions ? userAttributes.attributes.VoidDeliveryTransactions : false);
    userAttributes.attributes.SalesOrderModUpdatePrices = (userAttributes.attributes.SalesOrderModUpdatePrices ? userAttributes.attributes.SalesOrderModUpdatePrices : false);
    userAttributes.attributes.ApproveSalesOrderMod = (userAttributes.attributes.ApproveSalesOrderMod ? userAttributes.attributes.ApproveSalesOrderMod : false);
    userAttributes.attributes.RegenerateCFDi = (userAttributes.attributes.RegenerateCFDi ? userAttributes.attributes.RegenerateCFDi : false);
    userAttributes.attributes.SendCFDi = (userAttributes.attributes.SendCFDi ? userAttributes.attributes.SendCFDi : false);
    userAttributes.attributes.CloseSalesOrder = (userAttributes.attributes.CloseSalesOrder ? userAttributes.attributes.CloseSalesOrder : false);

    userAttributes.attributes.SalesPlaza = (userAttributes.attributes.SalesPlaza ? userAttributes.attributes.SalesPlaza : '');
    userAttributes.attributes.EstimateLevel = (userAttributes.attributes.EstimateLevel ? userAttributes.attributes.EstimateLevel : 'Ver');
    userAttributes.attributes.LostDealLevel = (userAttributes.attributes.LostDealLevel ? userAttributes.attributes.LostDealLevel : 'Ver');
    userAttributes.attributes.SalesOrderLevel = (userAttributes.attributes.SalesOrderLevel ? userAttributes.attributes.SalesOrderLevel : 'Ver');
    userAttributes.attributes.InvoiceLevel = (userAttributes.attributes.InvoiceLevel ? userAttributes.attributes.InvoiceLevel : 'Ver');
    userAttributes.attributes.DirectDeliveryLevel = (userAttributes.attributes.DirectDeliveryLevel ? userAttributes.attributes.DirectDeliveryLevel : 'Ver');
    userAttributes.attributes.DirectReturnLevel = (userAttributes.attributes.DirectReturnLevel ? userAttributes.attributes.DirectReturnLevel : 'Ver');
    userAttributes.attributes.DeliveryRequestLevel = (userAttributes.attributes.DeliveryRequestLevel ? userAttributes.attributes.DeliveryRequestLevel : 'Ver');
    userAttributes.attributes.ReturnRequestLevel = (userAttributes.attributes.ReturnRequestLevel ? userAttributes.attributes.ReturnRequestLevel : 'Ver');
    userAttributes.attributes.TransferOrderLevel = (userAttributes.attributes.TransferOrderLevel ? userAttributes.attributes.TransferOrderLevel : 'Ver');
    userAttributes.attributes.SalesOrderModLevel = (userAttributes.attributes.SalesOrderModLevel ? userAttributes.attributes.SalesOrderModLevel : 'Ver');
    userAttributes.attributes.ShipmentRequestLevel = (userAttributes.attributes.ShipmentRequestLevel ? userAttributes.attributes.ShipmentRequestLevel : 'Ver');
    userAttributes.attributes.CashRegisterLevel = (userAttributes.attributes.CashRegisterLevel ? userAttributes.attributes.CashRegisterLevel : 'Ver');
    userAttributes.attributes.CustomerPaymentLevel = (userAttributes.attributes.CustomerPaymentLevel ? userAttributes.attributes.CustomerPaymentLevel : 'Ver');
    userAttributes.attributes.InvTransferOrderLevel = (userAttributes.attributes.InvTransferOrderLevel ? userAttributes.attributes.InvTransferOrderLevel : 'Ver');
    userAttributes.attributes.InventoryTransferLevel = (userAttributes.attributes.InventoryTransferLevel ? userAttributes.attributes.InventoryTransferLevel : 'Ver');
    userAttributes.attributes.InvItemFulfillment = (userAttributes.attributes.InvItemFulfillment ? userAttributes.attributes.InvItemFulfillment : false);
    userAttributes.attributes.InvItemFulfillmentLevel = (userAttributes.attributes.InvItemFulfillmentLevel ? userAttributes.attributes.InvItemFulfillmentLevel : 'Ver');
    userAttributes.attributes.InvItemReceipt = (userAttributes.attributes.InvItemReceipt ? userAttributes.attributes.InvItemReceipt : false);
    userAttributes.attributes.InvItemReceiptLevel = (userAttributes.attributes.InvItemReceiptLevel ? userAttributes.attributes.InvItemReceiptLevel : 'Ver');
    userAttributes.attributes.CreateLabelWMS = (userAttributes.attributes.CreateLabelWMS ? userAttributes.attributes.CreateLabelWMS : false);
    userAttributes.attributes.CreateLabelWMSLevel = (userAttributes.attributes.CreateLabelWMSLevel ? userAttributes.attributes.CreateLabelWMSLevel : 'Ver');
    userAttributes.attributes.ReceiveOrderWMS = (userAttributes.attributes.ReceiveOrderWMS ? userAttributes.attributes.ReceiveOrderWMS : false);
    userAttributes.attributes.ReceiveOrderWMSLevel = (userAttributes.attributes.ReceiveOrderWMSLevel ? userAttributes.attributes.ReceiveOrderWMSLevel : 'Ver');
    userAttributes.attributes.FulfillmentOrderWMS = (userAttributes.attributes.FulfillmentOrderWMS ? userAttributes.attributes.FulfillmentOrderWMS : false);
    userAttributes.attributes.AssignFulfillmentOrderWMS = (userAttributes.attributes.AssignFulfillmentOrderWMS ? userAttributes.attributes.AssignFulfillmentOrderWMS : false);
    userAttributes.attributes.FulfillmentOrderWMSLevel = (userAttributes.attributes.FulfillmentOrderWMSLevel ? userAttributes.attributes.FulfillmentOrderWMSLevel : 'Ver');
    userAttributes.attributes.AdjustItemWMS = (userAttributes.attributes.AdjustItemWMS ? userAttributes.attributes.AdjustItemWMS : false);
    userAttributes.attributes.ApproveAdjustItemWMS = (userAttributes.attributes.ApproveAdjustItemWMS ? userAttributes.attributes.ApproveAdjustItemWMS : false);
    userAttributes.attributes.MoveItemWMS = (userAttributes.attributes.MoveItemWMS ? userAttributes.attributes.MoveItemWMS : false);
    userAttributes.attributes.AdjustItemWMSLevel = (userAttributes.attributes.AdjustItemWMSLevel ? userAttributes.attributes.AdjustItemWMSLevel : 'Ver');
    userAttributes.attributes.ItemCountWMS = (userAttributes.attributes.ItemCountWMS ? userAttributes.attributes.ItemCountWMS : false);
    userAttributes.attributes.ItemCountWMSLevel = (userAttributes.attributes.ItemCountWMSLevel ? userAttributes.attributes.ItemCountWMSLevel : 'Ver');
    userAttributes.attributes.ApproveItemCountWMS = (userAttributes.attributes.ApproveItemCountWMS ? userAttributes.attributes.ApproveItemCountWMS : false);

    var groups = user.signInUserSession.accessToken.payload["cognito:groups"];

    for (var groupIndex in groups) {
      var singleGroup = groups[groupIndex];

      if (singleGroup === 'Admin') {
        setIsadmin('true');
        secureLocalStorage.setItem('isadmin', 'true');
        admin = true;
      }
    }

    setUsername(user.username);
    secureLocalStorage.setItem('username', user.username);

    setStatus(newStatus);
    secureLocalStorage.setItem('status', newStatus);

    setSucursal(userAttributes.attributes.Sucursal.toString());
    secureLocalStorage.setItem('sucursal', userAttributes.attributes.Sucursal.toString());

    setNombreSucursal(userAttributes.attributes.NombreSucursal);
    secureLocalStorage.setItem('nombreSucursal', userAttributes.attributes.NombreSucursal);

    setPersonal(userAttributes.attributes.Personal);
    secureLocalStorage.setItem('personal', userAttributes.attributes.Personal);

    setUsuario(userAttributes.attributes.Usuario);
    secureLocalStorage.setItem('usuario', userAttributes.attributes.Usuario);

    setAgente(userAttributes.attributes.Agente);
    secureLocalStorage.setItem('agente', userAttributes.attributes.Agente);

    setChecklistAccess(userAttributes.attributes.ChecklistPlaza);
    secureLocalStorage.setItem('checklistAccess', userAttributes.attributes.ChecklistPlaza);

    setAttendanceAccess(userAttributes.attributes.AsistenciaPlaza);
    secureLocalStorage.setItem('attendanceAccess', userAttributes.attributes.AsistenciaPlaza);

    setVacationAccess(userAttributes.attributes.VacationPlaza);
    secureLocalStorage.setItem('vacationAccess', userAttributes.attributes.VacationPlaza);

    setSalesAccess(userAttributes.attributes.SalesPlaza);
    secureLocalStorage.setItem('salesAccess', userAttributes.attributes.SalesPlaza);

    setAttendance(userAttributes.attributes.Attendance);
    secureLocalStorage.setItem('attendance', userAttributes.attributes.Attendance);

    setPricelist(userAttributes.attributes.PriceList);
    secureLocalStorage.setItem('pricelist', userAttributes.attributes.PriceList);

    setCommission(userAttributes.attributes.Commission);
    secureLocalStorage.setItem('commission', userAttributes.attributes.Commission);

    setIncentive(userAttributes.attributes.Incentive);
    secureLocalStorage.setItem('incentive', userAttributes.attributes.Incentive);

    setChecklist(userAttributes.attributes.CheckList);
    secureLocalStorage.setItem('checklist', userAttributes.attributes.CheckList);

    setChecklistAud(userAttributes.attributes.CheckListAud);
    secureLocalStorage.setItem('checklistaud', userAttributes.attributes.CheckListAud);

    setChecklistVeh(userAttributes.attributes.CheckListVeh);
    secureLocalStorage.setItem('checklistveh', userAttributes.attributes.CheckListVeh);

    setPdfDocument(userAttributes.attributes.Document);
    secureLocalStorage.setItem('pdfDocument', userAttributes.attributes.Document);

    setVacation(userAttributes.attributes.Vacation);
    secureLocalStorage.setItem('vacation', userAttributes.attributes.Vacation);

    setKibana(userAttributes.attributes.Kibana);
    secureLocalStorage.setItem('kibana', userAttributes.attributes.Kibana);

    setProcesarFacturas(userAttributes.attributes.ProcesarFacturas);
    secureLocalStorage.setItem('procesarfacturas', userAttributes.attributes.ProcesarFacturas);

    setProcesarFacturasSucursal(userAttributes.attributes.ProcesarFacturasSucursal);
    secureLocalStorage.setItem('procesarfacturassucursal', userAttributes.attributes.ProcesarFacturasSucursal);

    setProcesarFacturasBodega(userAttributes.attributes.ProcesarFacturasBodega);
    secureLocalStorage.setItem('procesarfacturasbodega', userAttributes.attributes.ProcesarFacturasBodega);

    setAfectarFacturas(userAttributes.attributes.AfectarFacturas);
    secureLocalStorage.setItem('afectarfacturas', userAttributes.attributes.AfectarFacturas);

    setNewChecklist(userAttributes.attributes.NewCheckList);
    secureLocalStorage.setItem('newchecklist', userAttributes.attributes.NewCheckList);

    setNewChecklistAud(userAttributes.attributes.NewCheckListAud);
    secureLocalStorage.setItem('newchecklistaud', userAttributes.attributes.NewCheckListAud);

    setNewChecklistVeh(userAttributes.attributes.NewCheckListVeh);
    secureLocalStorage.setItem('newchecklistveh', userAttributes.attributes.NewCheckListVeh);

    setEstimate(userAttributes.attributes.Estimate);
    secureLocalStorage.setItem('estimate', userAttributes.attributes.Estimate);

    setLostDeal(userAttributes.attributes.LostDeal);
    secureLocalStorage.setItem('lostDeal', userAttributes.attributes.LostDeal);

    setSalesOrder(userAttributes.attributes.SalesOrder);
    secureLocalStorage.setItem('salesOrder', userAttributes.attributes.SalesOrder);

    setInvoice(userAttributes.attributes.Invoice);
    secureLocalStorage.setItem('invoice', userAttributes.attributes.Invoice);

    setDirectDelivery(userAttributes.attributes.DirectDelivery);
    secureLocalStorage.setItem('directDelivery', userAttributes.attributes.DirectDelivery);

    setDirectReturn(userAttributes.attributes.DirectReturn);
    secureLocalStorage.setItem('directReturn', userAttributes.attributes.DirectReturn);

    setDeliveryRequest(userAttributes.attributes.DeliveryRequest);
    secureLocalStorage.setItem('deliveryRequest', userAttributes.attributes.DeliveryRequest);

    setReturnRequest(userAttributes.attributes.ReturnRequest);
    secureLocalStorage.setItem('returnRequest', userAttributes.attributes.ReturnRequest);

    setTransferOrder(userAttributes.attributes.TransferOrder);
    secureLocalStorage.setItem('transferOrder', userAttributes.attributes.TransferOrder);

    setSalesOrderMod(userAttributes.attributes.SalesOrderMod);
    secureLocalStorage.setItem('salesOrderMod', userAttributes.attributes.SalesOrderMod);

    setShipmentRequest(userAttributes.attributes.ShipmentRequest);
    secureLocalStorage.setItem('shipmentRequest', userAttributes.attributes.ShipmentRequest);

    setCashRegister(userAttributes.attributes.CashRegister);
    secureLocalStorage.setItem('cashRegister', userAttributes.attributes.CashRegister);

    setCustomerPayment(userAttributes.attributes.CustomerPayment);
    secureLocalStorage.setItem('customerPayment', userAttributes.attributes.CustomerPayment);

    setInvTransferOrder(userAttributes.attributes.InvTransferOrder);
    secureLocalStorage.setItem('invTransferOrder', userAttributes.attributes.InvTransferOrder);

    setRepComparativoVentas(userAttributes.attributes.RepComparativoVentas);
    secureLocalStorage.setItem('repComparativoVentas', userAttributes.attributes.RepComparativoVentas);

    setPOSuggestionsReport(userAttributes.attributes.POSuggestionsReport);
    secureLocalStorage.setItem('poSuggestionsReport', userAttributes.attributes.POSuggestionsReport);

    setPOSuggestionsReportCfg(userAttributes.attributes.POSuggestionsReportCfg);
    secureLocalStorage.setItem('poSuggestionsReportCfg', userAttributes.attributes.POSuggestionsReportCfg);

    setExploreBarcodes(userAttributes.attributes.ExploreBarcodes);
    secureLocalStorage.setItem('exploreBarcodes', userAttributes.attributes.ExploreBarcodes);

    setExploreBarcodeCosts(userAttributes.attributes.ExploreBarcodeCosts);
    secureLocalStorage.setItem('exploreBarcodeCosts', userAttributes.attributes.ExploreBarcodeCosts);

    setInventoryTransfer(userAttributes.attributes.InventoryTransfer);
    secureLocalStorage.setItem('inventoryTransfer', userAttributes.attributes.InventoryTransfer);

    setInvLevels(userAttributes.attributes.InvLevels);
    secureLocalStorage.setItem('invLevels', userAttributes.attributes.InvLevels);

    setInvLevelsIncCost(userAttributes.attributes.InvLevelsIncCost);
    secureLocalStorage.setItem('invLevelsIncCost', userAttributes.attributes.InvLevelsIncCost);

    setKardex(userAttributes.attributes.Kardex);
    secureLocalStorage.setItem('kardex', userAttributes.attributes.Kardex);

    setApproveDelivery(userAttributes.attributes.ApproveDelivery);
    secureLocalStorage.setItem('approveDelivery', userAttributes.attributes.ApproveDelivery);

    setApproveInvTransferOrder(userAttributes.attributes.ApproveInvTransferOrder);
    secureLocalStorage.setItem('approveInvTransferOrder', userAttributes.attributes.ApproveInvTransferOrder);

    setApproveInventoryTransfer(userAttributes.attributes.ApproveInventoryTransfer);
    secureLocalStorage.setItem('approveInventoryTransfer', userAttributes.attributes.ApproveInventoryTransfer);

    setVoidDeliveryTransactions(userAttributes.attributes.VoidDeliveryTransactions);
    secureLocalStorage.setItem('voidDeliveryTransactions', userAttributes.attributes.VoidDeliveryTransactions);

    setSalesOrderModUpdatePrices(userAttributes.attributes.SalesOrderModUpdatePrices);
    secureLocalStorage.setItem('salesOrderModUpdatePrices', userAttributes.attributes.SalesOrderModUpdatePrices);

    setApproveSalesOrderMod(userAttributes.attributes.ApproveSalesOrderMod);
    secureLocalStorage.setItem('approveSalesOrderMod', userAttributes.attributes.ApproveSalesOrderMod);

    setRegenerateCFDi(userAttributes.attributes.RegenerateCFDi);
    secureLocalStorage.setItem('regenerateCFDi', userAttributes.attributes.RegenerateCFDi);

    setSendCFDi(userAttributes.attributes.SendCFDi);
    secureLocalStorage.setItem('sendCFDi', userAttributes.attributes.SendCFDi);

    setEstimateLevel(userAttributes.attributes.EstimateLevel);
    secureLocalStorage.setItem('estimateLevel', userAttributes.attributes.EstimateLevel)

    setLostDealLevel(userAttributes.attributes.LostDealLevel);
    secureLocalStorage.setItem('lostDealLevel', userAttributes.attributes.LostDealLevel)

    setSalesOrderLevel(userAttributes.attributes.SalesOrderLevel);
    secureLocalStorage.setItem('salesOrderLevel', userAttributes.attributes.SalesOrderLevel)

    setInvoiceLevel(userAttributes.attributes.InvoiceLevel);
    secureLocalStorage.setItem('invoiceLevel', userAttributes.attributes.InvoiceLevel)

    setDirectDeliveryLevel(userAttributes.attributes.DirectDeliveryLevel);
    secureLocalStorage.setItem('directDeliveryLevel', userAttributes.attributes.DirectDeliveryLevel)

    setDirectReturnLevel(userAttributes.attributes.DirectReturnLevel);
    secureLocalStorage.setItem('directReturnLevel', userAttributes.attributes.DirectReturnLevel)

    setDeliveryRequestLevel(userAttributes.attributes.DeliveryRequestLevel);
    secureLocalStorage.setItem('deliveryRequestLevel', userAttributes.attributes.DeliveryRequestLevel)

    setReturnRequestLevel(userAttributes.attributes.ReturnRequestLevel);
    secureLocalStorage.setItem('returnRequestLevel', userAttributes.attributes.ReturnRequestLevel)

    setTransferOrderLevel(userAttributes.attributes.TransferOrderLevel);
    secureLocalStorage.setItem('transferOrderLevel', userAttributes.attributes.TransferOrderLevel)

    setSalesOrderModLevel(userAttributes.attributes.SalesOrderModLevel);
    secureLocalStorage.setItem('salesOrderModLevel', userAttributes.attributes.SalesOrderModLevel)

    setShipmentRequestLevel(userAttributes.attributes.ShipmentRequestLevel);
    secureLocalStorage.setItem('shipmentRequestLevel', userAttributes.attributes.ShipmentRequestLevel)

    setCashRegisterLevel(userAttributes.attributes.CashRegisterLevel);
    secureLocalStorage.setItem('cashRegisterLevel', userAttributes.attributes.CashRegisterLevel)

    setCustomerPaymentLevel(userAttributes.attributes.CustomerPaymentLevel);
    secureLocalStorage.setItem('customerPaymentLevel', userAttributes.attributes.CustomerPaymentLevel)

    setInvTransferOrderLevel(userAttributes.attributes.InvTransferOrderLevel);
    secureLocalStorage.setItem('invTransferOrderLevel', userAttributes.attributes.InvTransferOrderLevel)

    setInventoryTransferLevel(userAttributes.attributes.InventoryTransferLevel);
    secureLocalStorage.setItem('inventoryTransferLevel', userAttributes.attributes.InventoryTransferLevel)

    setInvItemFulfillment(userAttributes.attributes.InvItemFulfillment);
    secureLocalStorage.setItem('invItemFulfillment', userAttributes.attributes.InvItemFulfillment);

    setInvItemFulfillmentLevel(userAttributes.attributes.InvItemFulfillmentLevel);
    secureLocalStorage.setItem('invItemFulfillmentLevel', userAttributes.attributes.InvItemFulfillmentLevel)

    setInvItemReceipt(userAttributes.attributes.InvItemReceipt);
    secureLocalStorage.setItem('invItemReceipt', userAttributes.attributes.InvItemReceipt);

    setInvItemReceiptLevel(userAttributes.attributes.InvItemReceiptLevel);
    secureLocalStorage.setItem('invItemReceiptLevel', userAttributes.attributes.InvItemReceiptLevel)

    setCreateLabelWMS(userAttributes.attributes.CreateLabelWMS);
    secureLocalStorage.setItem('createLabelWMS', userAttributes.attributes.CreateLabelWMS);

    setCreateLabelWMSLevel(userAttributes.attributes.CreateLabelWMSLevel);
    secureLocalStorage.setItem('createLabelWMSLevel', userAttributes.attributes.CreateLabelWMSLevel);

    setReceiveOrderWMS(userAttributes.attributes.ReceiveOrderWMS);
    secureLocalStorage.setItem('receiveOrderWMS', userAttributes.attributes.ReceiveOrderWMS);

    setReceiveOrderWMSLevel(userAttributes.attributes.ReceiveOrderWMSLevel);
    secureLocalStorage.setItem('receiveOrderWMSLevel', userAttributes.attributes.ReceiveOrderWMSLevel);

    setFulfillmentOrderWMS(userAttributes.attributes.FulfillmentOrderWMS);
    secureLocalStorage.setItem('fulfillmentOrderWMS', userAttributes.attributes.FulfillmentOrderWMS);

    setAssignFulfillmentOrderWMS(userAttributes.attributes.AssignFulfillmentOrderWMS);
    secureLocalStorage.setItem('assignFulfillmentOrderWMS', userAttributes.attributes.AssignFulfillmentOrderWMS);

    setFulfillmentOrderWMSLevel(userAttributes.attributes.FulfillmentOrderWMSLevel);
    secureLocalStorage.setItem('fulfillmentOrderWMSLevel', userAttributes.attributes.FulfillmentOrderWMSLevel);

    setAdjustItemWMS(userAttributes.attributes.AdjustItemWMS);
    secureLocalStorage.setItem('adjustItemWMS', userAttributes.attributes.AdjustItemWMS);

    setApproveAdjustItemWMS(userAttributes.attributes.ApproveAdjustItemWMS);
    secureLocalStorage.setItem('approveAdjustItemWMS', userAttributes.attributes.ApproveAdjustItemWMS);

    setMoveItemWMS(userAttributes.attributes.MoveItemWMS);
    secureLocalStorage.setItem('moveItemWMS', userAttributes.attributes.MoveItemWMS);

    setAdjustItemWMSLevel(userAttributes.attributes.AdjustItemWMSLevel);
    secureLocalStorage.setItem('adjustItemWMSLevel', userAttributes.attributes.AdjustItemWMSLevel);

    setCloseSalesOrder(userAttributes.attributes.CloseSalesOrder);
    secureLocalStorage.setItem('closeSalesOrder', userAttributes.attributes.CloseSalesOrder);

    setItemCountWMS(userAttributes.attributes.ItemCountWMS);
    secureLocalStorage.setItem('itemCountWMS', userAttributes.attributes.ItemCountWMS);

    setItemCountWMSLevel(userAttributes.attributes.ItemCountWMSLevel);
    secureLocalStorage.setItem('itemCountWMSLevel', userAttributes.attributes.ItemCountWMSLevel);

    setApproveItemCountWMS(userAttributes.attributes.ApproveItemCountWMS);
    secureLocalStorage.setItem('approveItemCountWMS', userAttributes.attributes.ApproveItemCountWMS);
  }

  const handleAuthStateChange = React.useCallback((data) => {
    var newStatus;

    if (data === 'signedout') {
      newStatus = 'signedout';
    }
    else {
      if (data.payload.event === 'signIn')
        newStatus = 'signedin';
      else if (data.payload.event === 'signOut')
        newStatus = 'signedout';
    }

    if (newStatus !== 'signedin' && newStatus !== 'signedout')
      return;

    if (newStatus === 'signedin' || newStatus === 'signedout') {
      if (newStatus === 'signedin') {
        Auth.currentAuthenticatedUser({
          bypassCache: false
        })
          .then(user => {
            getUserAttributes(user, newStatus)
          })
          .catch(err => {
            setStatus(newStatus);
            setUsername('');
            setIsadmin('false');
            setSucursal('');
            setNombreSucursal('');
            setPersonal('');
            setUsuario('');
            setAgente('');
            setAttendance(false);
            setPricelist(false);
            setCommission(false);
            setIncentive(false);
            setChecklist(false);
            setChecklistAud(false);
            setChecklistVeh(false);
            setPdfDocument(false);
            setVacation(false);
            setKibana(false);
            setProcesarFacturas(false);
            setProcesarFacturasBodega(false);
            setProcesarFacturasSucursal(false);
            setAfectarFacturas(false);
            setChecklistAccess('');
            setAttendanceAccess('');
            setVacationAccess('');
            setSalesAccess('');
            setNewChecklist(false);
            setNewChecklistAud(false);
            setNewChecklistVeh(false);
            setEstimate(false);
            setLostDeal(false);
            setSalesOrder(false);
            setInvoice(false);
            setDirectDelivery(false);
            setDirectReturn(false);
            setDeliveryRequest(false);
            setReturnRequest(false);
            setTransferOrder(false);
            setSalesOrderMod(false);
            setShipmentRequest(false);
            setCashRegister(false);
            setCustomerPayment(false);
            setInvTransferOrder(false);
            setRepComparativoVentas(false);
            setPOSuggestionsReport(false);
            setPOSuggestionsReportCfg(false);
            setExploreBarcodes(false);
            setExploreBarcodeCosts(false);
            setInventoryTransfer(false);
            setInvLevels(false);
            setInvLevelsIncCost(false);
            setKardex(false);
            setApproveDelivery(false);
            setApproveInvTransferOrder(false);
            setApproveInventoryTransfer(false);
            setVoidDeliveryTransactions(false);
            setSalesOrderModUpdatePrices(false);
            setApproveSalesOrderMod(false);
            setRegenerateCFDi(false);
            setSendCFDi(false);
            setInvItemFulfillment(false);
            setInvItemReceipt(false);

            setCreateLabelWMS(false);
            setReceiveOrderWMS(false);
            setFulfillmentOrderWMS(false);
            setAssignFulfillmentOrderWMS(false);

            setAdjustItemWMS(false);
            setApproveAdjustItemWMS(false);
            setMoveItemWMS(false);

            setCloseSalesOrder(false);

            setItemCountWMS(false);
            setApproveItemCountWMS(false);

            setEstimateLevel('');
            setLostDealLevel('');
            setSalesOrderLevel('');
            setInvoiceLevel('');
            setDirectDeliveryLevel('');
            setDirectReturnLevel('');
            setDeliveryRequestLevel('');
            setReturnRequestLevel('');
            setTransferOrderLevel('');
            setSalesOrderModLevel('');
            setShipmentRequestLevel('');
            setCashRegisterLevel('');
            setCustomerPaymentLevel('');
            setInvTransferOrderLevel('');
            setInventoryTransferLevel('');
            setInvItemFulfillmentLevel('');
            setInvItemReceiptLevel('');

            setCreateLabelWMSLevel('');
            setReceiveOrderWMSLevel('');
            setFulfillmentOrderWMSLevel('');
            setAdjustItemWMSLevel('');

            setItemCountWMSLevel('');

            secureLocalStorage.setItem('username', '');
            secureLocalStorage.setItem('status', newStatus);
            secureLocalStorage.setItem('sucursal', '');
            secureLocalStorage.setItem('nombreSucursal', '');
            secureLocalStorage.setItem('personal', '');
            secureLocalStorage.setItem('usuario', '');
            secureLocalStorage.setItem('agente', '');
            secureLocalStorage.setItem('checklistAccess', '');
            secureLocalStorage.setItem('attendanceAccess', '');
            secureLocalStorage.setItem('vacationAccess', '');
            secureLocalStorage.setItem('salesAccess', '');
            secureLocalStorage.setItem('attendance', false);
            secureLocalStorage.setItem('pricelist', false);
            secureLocalStorage.setItem('commission', false);
            secureLocalStorage.setItem('incentive', false);
            secureLocalStorage.setItem('checklist', false);
            secureLocalStorage.setItem('checklistaud', false);
            secureLocalStorage.setItem('checklistveh', false);
            secureLocalStorage.setItem('pdfDocument', false);
            secureLocalStorage.setItem('vacation', false);
            secureLocalStorage.setItem('kibana', false);
            secureLocalStorage.setItem('procesarfacturas', false);
            secureLocalStorage.setItem('procesarfacturassucursal', false);
            secureLocalStorage.setItem('procesarfacturasbodega', false);
            secureLocalStorage.setItem('afectarfacturas', false);
            secureLocalStorage.setItem('isadmin', 'false');
            secureLocalStorage.setItem('newchecklist', false);
            secureLocalStorage.setItem('newchecklistaud', false);
            secureLocalStorage.setItem('newchecklistveh', false);
            secureLocalStorage.setItem('estimate', false);
            secureLocalStorage.setItem('lostDeal', false);
            secureLocalStorage.setItem('salesOrder', false);
            secureLocalStorage.setItem('invoice', false);
            secureLocalStorage.setItem('directDelivery', false);
            secureLocalStorage.setItem('directReturn', false);
            secureLocalStorage.setItem('deliveryRequest', false);
            secureLocalStorage.setItem('returnRequest', false);
            secureLocalStorage.setItem('transferOrder', false);
            secureLocalStorage.setItem('salesOrderMod', false);
            secureLocalStorage.setItem('shipmentRequest', false);
            secureLocalStorage.setItem('cashRegister', false);
            secureLocalStorage.setItem('customerPayment', false);
            secureLocalStorage.setItem('invTransferOrder', false);
            secureLocalStorage.setItem('repComparativoVentas', false);
            secureLocalStorage.setItem('poSuggestionsReport', false);
            secureLocalStorage.setItem('poSuggestionsReportCfg', false);
            secureLocalStorage.setItem('exploreBarcodes', false);
            secureLocalStorage.setItem('exploreBarcodeCosts', false);
            secureLocalStorage.setItem('inventoryTransfer', false);
            secureLocalStorage.setItem('invLevels', false);
            secureLocalStorage.setItem('invLevelsIncCost', false);
            secureLocalStorage.setItem('kardex', false);
            secureLocalStorage.setItem('approveDelivery', false);
            secureLocalStorage.setItem('approveInvTransferOrder', false);
            secureLocalStorage.setItem('approveInventoryTransfer', false);
            secureLocalStorage.setItem('voidDeliveryTransactions', false);
            secureLocalStorage.setItem('salesOrderModUpdatePrices', false);
            secureLocalStorage.setItem('approveSalesOrderMod', false);
            secureLocalStorage.setItem('regenerateCFDi', false);
            secureLocalStorage.setItem('sendCFDi', false);
            secureLocalStorage.setItem('invItemFulfillment', false);
            secureLocalStorage.setItem('invItemReceipt', false);

            secureLocalStorage.setItem('createLabelWMS', false);
            secureLocalStorage.setItem('receiveOrderWMS', false);
            secureLocalStorage.setItem('fulfillmentOrderWMS', false);
            secureLocalStorage.setItem('assignFulfillmentOrderWMS', false);
            secureLocalStorage.setItem('adjustItemWMS', false);
            secureLocalStorage.setItem('approveAdjustItemWMS', false);
            secureLocalStorage.setItem('moveItemWMS', false);
            secureLocalStorage.setItem('closeSalesOrder', false);

            secureLocalStorage.setItem('itemCountWMS', false);
            secureLocalStorage.setItem('approveItemCountWMS', false);


            secureLocalStorage.setItem('estimateLevel', '');
            secureLocalStorage.setItem('lostDealLevel', '');
            secureLocalStorage.setItem('salesOrderLevel', '');
            secureLocalStorage.setItem('invoiceLevel', '');
            secureLocalStorage.setItem('directDeliveryLevel', '');
            secureLocalStorage.setItem('directReturnLevel', '');
            secureLocalStorage.setItem('deliveryRequestLevel', '');
            secureLocalStorage.setItem('returnRequestLevel', '');
            secureLocalStorage.setItem('transferOrderLevel', '');
            secureLocalStorage.setItem('salesOrderModLevel', '');
            secureLocalStorage.setItem('shipmentRequestLevel', '');
            secureLocalStorage.setItem('cashRegisterLevel', '');
            secureLocalStorage.setItem('customerPaymentLevel', '');
            secureLocalStorage.setItem('invTransferOrderLevel', '');
            secureLocalStorage.setItem('inventoryTransferLevel', '');
            secureLocalStorage.setItem('invItemFulfillmentLevel', '');
            secureLocalStorage.setItem('invItemReceiptLevel', '');
            secureLocalStorage.setItem('createLabelWMSLevel', '');
            secureLocalStorage.setItem('receiveOrderWMSLevel', '');
            secureLocalStorage.setItem('fulfillmentOrderWMSLevel', '');
            secureLocalStorage.setItem('adjustItemWMSLevel', '');
            secureLocalStorage.setItem('itemCountWMSLevel', '');

            secureLocalStorage.clear();
            localStorage.clear();
          });

        var baseUrl = window.location.href.split('/')[0];

        window.open(baseUrl + "/#/", "_self");
      }
      else if (newStatus === 'signedout') {
        setStatus(newStatus);
        setUsername('');
        setIsadmin('false');
        setSucursal('');
        setNombreSucursal('');
        setPersonal('');
        setUsuario('');
        setAgente('');
        setAttendance(false);
        setPricelist(false);
        setCommission(false);
        setIncentive(false);
        setChecklist(false);
        setChecklistAud(false);
        setChecklistVeh(false);
        setPdfDocument(false);
        setVacation(false);
        setKibana(false);
        setProcesarFacturas(false);
        setProcesarFacturasBodega(false);
        setProcesarFacturasSucursal(false);
        setAfectarFacturas(false);
        setChecklistAccess('');
        setAttendanceAccess('');
        setVacationAccess('');
        setSalesAccess('');
        setNewChecklist(false);
        setNewChecklistAud(false);
        setNewChecklistVeh(false);
        setEstimate(false);
        setLostDeal(false);
        setSalesOrder(false);
        setInvoice(false);
        setDirectDelivery(false);
        setDirectReturn(false);
        setDeliveryRequest(false);
        setReturnRequest(false);
        setTransferOrder(false);
        setSalesOrderMod(false);
        setShipmentRequest(false);
        setCashRegister(false);
        setCustomerPayment(false);
        setInvTransferOrder(false);
        setRepComparativoVentas(false);
        setPOSuggestionsReport(false);
        setPOSuggestionsReportCfg(false);
        setExploreBarcodes(false);
        setExploreBarcodeCosts(false);
        setInventoryTransfer(false);
        setInvLevels(false);
        setInvLevelsIncCost(false);
        setKardex(false);
        setApproveDelivery(false);
        setApproveInvTransferOrder(false);
        setApproveInventoryTransfer(false);
        setVoidDeliveryTransactions(false);
        setSalesOrderModUpdatePrices(false);
        setApproveSalesOrderMod(false);
        setRegenerateCFDi(false);
        setSendCFDi(false);
        setInvItemFulfillment(false);
        setInvItemReceipt(false);
        setCreateLabelWMS(false);
        setReceiveOrderWMS(false);
        setFulfillmentOrderWMS(false);
        setAssignFulfillmentOrderWMS(false);
        setAdjustItemWMS(false);
        setApproveAdjustItemWMS(false);
        setMoveItemWMS(false);
        setCloseSalesOrder(false);
        setItemCountWMS(false);
        setApproveItemCountWMS(false);

        setEstimateLevel('');
        setLostDealLevel('');
        setSalesOrderLevel('');
        setInvoiceLevel('');
        setDirectDeliveryLevel('');
        setDirectReturnLevel('');
        setDeliveryRequestLevel('');
        setReturnRequestLevel('');
        setTransferOrderLevel('');
        setSalesOrderModLevel('');
        setShipmentRequestLevel('');
        setCashRegisterLevel('');
        setCustomerPaymentLevel('');
        setInvTransferOrderLevel('');
        setInventoryTransferLevel('');
        setInvItemFulfillmentLevel('');
        setInvItemReceiptLevel('');
        setCreateLabelWMSLevel('');
        setReceiveOrderWMSLevel('');
        setFulfillmentOrderWMSLevel('');
        setAdjustItemWMSLevel('');
        setItemCountWMSLevel('');

        secureLocalStorage.setItem('username', '');
        secureLocalStorage.setItem('status', newStatus);
        secureLocalStorage.setItem('sucursal', '');
        secureLocalStorage.setItem('nombreSucursal', '');
        secureLocalStorage.setItem('personal', '');
        secureLocalStorage.setItem('usuario', '');
        secureLocalStorage.setItem('agente', '');
        secureLocalStorage.setItem('checklistAccess', '');
        secureLocalStorage.setItem('attendanceAccess', '');
        secureLocalStorage.setItem('vacationAccess', '');
        secureLocalStorage.setItem('salesAccess', '');
        secureLocalStorage.setItem('attendance', false);
        secureLocalStorage.setItem('pricelist', false);
        secureLocalStorage.setItem('commission', false);
        secureLocalStorage.setItem('incentive', false);
        secureLocalStorage.setItem('checklist', false);
        secureLocalStorage.setItem('checklistaud', false);
        secureLocalStorage.setItem('checklistveh', false);
        secureLocalStorage.setItem('pdfDocument', false);
        secureLocalStorage.setItem('vacation', false);
        secureLocalStorage.setItem('kibana', false);
        secureLocalStorage.setItem('procesarfacturas', false);
        secureLocalStorage.setItem('procesarfacturassucursal', false);
        secureLocalStorage.setItem('procesarfacturasbodega', false);
        secureLocalStorage.setItem('afectarfacturas', false);
        secureLocalStorage.setItem('isadmin', 'false');
        secureLocalStorage.setItem('newchecklist', false);
        secureLocalStorage.setItem('newchecklistaud', false);
        secureLocalStorage.setItem('newchecklistveh', false);
        secureLocalStorage.setItem('estimate', false);
        secureLocalStorage.setItem('lostDeal', false);
        secureLocalStorage.setItem('salesOrder', false);
        secureLocalStorage.setItem('invoice', false);
        secureLocalStorage.setItem('directDelivery', false);
        secureLocalStorage.setItem('directReturn', false);
        secureLocalStorage.setItem('deliveryRequest', false);
        secureLocalStorage.setItem('returnRequest', false);
        secureLocalStorage.setItem('transferOrder', false);
        secureLocalStorage.setItem('salesOrderMod', false);
        secureLocalStorage.setItem('shipmentRequest', false);
        secureLocalStorage.setItem('cashRegister', false);
        secureLocalStorage.setItem('customerPayment', false);
        secureLocalStorage.setItem('invTransferOrder', false);
        secureLocalStorage.setItem('repComparativoVentas', false);
        secureLocalStorage.setItem('poSuggestionsReport', false);
        secureLocalStorage.setItem('poSuggestionsReportCfg', false);
        secureLocalStorage.setItem('exploreBarcodes', false);
        secureLocalStorage.setItem('exploreBarcodeCosts', false);
        secureLocalStorage.setItem('inventoryTransfer', false);

        secureLocalStorage.setItem('invLevels', false);
        secureLocalStorage.setItem('invLevelsIncCost', false);
        secureLocalStorage.setItem('kardex', false);
        secureLocalStorage.setItem('approveDelivery', false);
        secureLocalStorage.setItem('approveInvTransferOrder', false);
        secureLocalStorage.setItem('approveInventoryTransfer', false);
        secureLocalStorage.setItem('voidDeliveryTransactions', false);
        secureLocalStorage.setItem('salesOrderModUpdatePrices', false);
        secureLocalStorage.setItem('approveSalesOrderMod', false);
        secureLocalStorage.setItem('regenerateCFDi', false);
        secureLocalStorage.setItem('sendCFDi', false);
        secureLocalStorage.setItem('invItemFulfillment', false);
        secureLocalStorage.setItem('invItemReceipt', false);
        secureLocalStorage.setItem('createLabelWMS', false);
        secureLocalStorage.setItem('receiveOrderWMS', false);
        secureLocalStorage.setItem('fulfillmentOrderWMS', false);
        secureLocalStorage.setItem('assignFulfillmentOrderWMS', false);
        secureLocalStorage.setItem('adjustItemWMS', false);
        secureLocalStorage.setItem('approveAdjustItemWMS', false);
        secureLocalStorage.setItem('moveItemWMS', false);
        secureLocalStorage.setItem('closeSalesOrder', false);
        secureLocalStorage.setItem('itemCountWMS', false);
        secureLocalStorage.setItem('approveItemCountWMS', false);

        secureLocalStorage.setItem('estimateLevel', '');
        secureLocalStorage.setItem('lostDealLevel', '');
        secureLocalStorage.setItem('salesOrderLevel', '');
        secureLocalStorage.setItem('invoiceLevel', '');
        secureLocalStorage.setItem('directDeliveryLevel', '');
        secureLocalStorage.setItem('directReturnLevel', '');
        secureLocalStorage.setItem('deliveryRequestLevel', '');
        secureLocalStorage.setItem('returnRequestLevel', '');
        secureLocalStorage.setItem('transferOrderLevel', '');
        secureLocalStorage.setItem('salesOrderModLevel', '');
        secureLocalStorage.setItem('shipmentRequestLevel', '');
        secureLocalStorage.setItem('cashRegisterLevel', '');
        secureLocalStorage.setItem('customerPaymentLevel', '');
        secureLocalStorage.setItem('invTransferOrderLevel', '');
        secureLocalStorage.setItem('inventoryTransferLevel', '');
        secureLocalStorage.setItem('invItemFulfillmentLevel', '');
        secureLocalStorage.setItem('invItemReceiptLevel', '');
        secureLocalStorage.setItem('createLabelWMSLevel', '');
        secureLocalStorage.setItem('receiveOrderWMSLevel', '');
        secureLocalStorage.setItem('fulfillmentOrderWMSLevel', '');
        secureLocalStorage.setItem('adjustItemWMSLevel', '');
        secureLocalStorage.setItem('itemCountWMSLevel', '');

        secureLocalStorage.clear();
        localStorage.clear();

        var baseUrl = window.location.href.split('/')[0];

        window.open(baseUrl + "/#/Signin", "_self");
      }
    }
  }, []);

  function signOut() {
    try {
      Auth.signOut();

      handleAuthStateChange('signedout');
    } catch (error) {
      console.log('Error signing out: ', error);

      handleAuthStateChange('signedout');
    } finally {
      secureLocalStorage.clear();
      localStorage.clear();
    }
  }

  function webstore() {
    window.open("https://www.supercolchones.com.mx", "_blank")
  }

  async function getGlobalRecords(sqlFilter) {
    try {
      let apiName = 'AdminSC';
      let path = '/globalsearch';

      let myInit = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
        },
        queryStringParameters: {

          username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username,
          isadmin: isadmin,
          sqlFilter: sqlFilter,
          action: 'list'
        }
      };

      var searchGet = await API.get(apiName, path, myInit);
    }
    catch (e) {
      if (e === 'No current user') {
        history.push('/');

        window.location.reload();
      }
    }
    return searchGet;
  }

  // window.addEventListener("storage", async function (e) {
  //   if (e.key.indexOf('@secure.') >= 0) {
  //     var checkLoggedIn = await Auth.currentAuthenticatedUser();

  //     await getUserAttributes(checkLoggedIn, 'signedin');
  //   }
  // }, false);

  useEffect(() => {
    Hub.listen('auth', handleAuthStateChange);

    loadMessages(esMessages);

    locale('es-MX');

    config({
      defaultCurrency: 'MXN'
    });

    config({ licenseKey });

    async function checkIsLoggedIn() {
      try {
        var checkLoggedIn = await Auth.currentAuthenticatedUser();

        // Refrescamos los permisos desde Intelisis
        await getUserAttributes(checkLoggedIn, 'signedin');
      }
      catch (e) {
        try {
          Auth.signOut();

          handleAuthStateChange('signedout');
        } catch (error) {
          console.log('Error signing out: ', error);

          handleAuthStateChange('signedout');
        }
      }
    }

    checkIsLoggedIn();

    return () => Hub.remove('auth', handleAuthStateChange);

  }, [handleAuthStateChange]);

  function globalSearchOnValueChanged(e) {
    // Limpiamos el control
    if (globalSearchRef.current) {
      var globalSearchLookup = globalSearchRef.current.instance;

      globalSearchLookup.reset();
    }
  }

  function onItemClickGlobalSearch(e) {
    var redirect;
    try {
      redirect = e.itemData.redirect;
    }
    catch (e) {
      redirect = undefined;
    }

    if (redirect) {
      var baseUrl = window.location.href.split('#/')[0];

      redirect = baseUrl + '#' + redirect;

      window.open(redirect, "_blank");
    }
  }

  return (
    <div>
      <HashRouter>
        <div>
          <table className="w-full bg-red-800" >
            <tbody>
              <tr>
                <th className="pl-3 w-1/6"><img src={logo} title="Super&#x20;Colchones" alt="Super&#x20;Colchones" width="170" height="88" /></th>
                <th className="w-1/5">
                  {status === "signedin" ?
                    (
                      <Lookup
                        dataSource={store}
                        grouped={true}
                        displayExpr="name"
                        placeholder="Buscar..."
                        onValueChanged={globalSearchOnValueChanged}
                        onItemClick={onItemClickGlobalSearch}
                        ref={globalSearchRef}
                        itemRender={gsItem}
                        groupRender={gsGroup}
                        applyValueMode="useButtons"
                        showCancelButton={false}
                        showClearButton={true}
                        clearButtonText="Cerrar"
                        searchTimeout={1500}
                        noDataText="No se encontraron resultados"
                      >
                        <DropDownOptions
                          hideOnOutsideClick={true}
                          showTitle={false}
                          minHeight="40%"
                        />
                      </Lookup>
                    ) :
                    (<div></div>)
                  }
                </th>
                <th className="w-1/6" align="right">
                  <button className="mt-2 pr-5" title="Tienda Virtual" onClick={webstore}><FontAwesomeIcon icon={faShoppingBag} size="2x" inverse /></button>
                  {status === "signedin" ?
                    (
                      <div>
                        <LoginInfo {...props} username={username} nombreSucursal={nombreSucursal} sucursal={sucursal} usuario={usuario} plaza={salesAccess} isadmin={isadmin} />
                      </div>
                    ) :
                    (<div />)
                  }
                </th>
              </tr>
            </tbody>
          </table>
          <React.Fragment>
            <Box sx={{ display: '-webkit-box', overflow: 'auto', alignItems: 'center', textAlign: 'center', backgroundColor: "#4B5563" }}>
              {/* *******MENU HOME******* */}
              <NavLink exact to="/">
                <Button
                  id="buttonHome"
                  aria-controls={openHome ? 'basic-menu' : undefined}
                  aria-haspopup="false"
                  aria-expanded={openHome ? 'true' : undefined}
                  onClick={handleClickHome}
                  style={{ fontSize: '17px', color: '#FFF', backgroundColor: "#4B5563", margin: '0', borderRadius: 0 }}
                >
                  <FontAwesomeIcon icon={faHome} size="lg" />
                </Button>
              </NavLink>

              {/* *******MENU CONFIGURATION******* */}
              {status === "signedin" && isadmin === "true" ?
                (
                  <div>
                    <Button
                      id="buttonConfiguration"
                      aria-controls={openConfiguration ? 'basic-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={openConfiguration ? 'true' : undefined}
                      onClick={handleClickConfiguration}
                      endIcon={<KeyboardArrowDownIcon />}
                      style={{ fontSize: '15px', color: '#FFF', backgroundColor: "#4B5563", margin: '0', borderRadius: 0 }}
                    >
                      <div className="mr-2"><FontAwesomeIcon icon={faWrench} size="lg" /></div>Configuración
                    </Button>
                    <Menu
                      id="menuConfiguration"
                      anchorEl={anchorConfiguration}
                      open={openConfiguration}
                      onClose={handleCloseConfiguration}
                      MenuListProps={{
                        'aria-labelledby': 'basic-button',
                      }}
                    >
                      {(status === "signedin" && isadmin === "true") ?
                        (
                          <div>
                            <NavLink to="/Document"><MenuItem onClick={handleCloseConfiguration}><div className="mr-2"><FontAwesomeIcon icon={faBook} size="lg" /></div>Manual de Capacitación</MenuItem></NavLink>
                            <Divider />
                          </div>
                        ) :
                        (<div />)
                      }

                      {(status === "signedin" && isadmin === "true") ?
                        (<NavLink to="/Signup"><MenuItem onClick={handleCloseConfiguration}><div className="mr-2"><FontAwesomeIcon icon={faUserPlus} size="lg" /></div>Agregar Usuario</MenuItem></NavLink>) :
                        (<div />)
                      }
                      {(status === "signedin" && isadmin === "true") ?
                        (<NavLink to="/Users"><MenuItem onClick={handleCloseConfiguration}><div className="mr-2"><FontAwesomeIcon icon={faUsers} size="lg" /></div>Lista de Usuarios</MenuItem></NavLink>) :
                        (<div />)
                      }

                      {(status === "signedin" && isadmin === "true") ?
                        (
                          <div>
                            <Divider />
                            <NavLink to="/PdfTemplate">
                              <MenuItem onClick={handleCloseConfiguration}><div className="mr-2"><FontAwesomeIcon icon={faFilePdf} size="lg" /></div>Plantilla PDF</MenuItem>
                            </NavLink>
                          </div>
                        ) :
                        (<div />)
                      }
                      {(status === "signedin" && isadmin === "true") ?
                        (
                          <div>
                            <Divider />
                            <NavLink to="/WarehouseWMS">
                              <MenuItem onClick={handleCloseConfiguration}><div className="mr-2"><FontAwesomeIcon icon={faWarehouseFull} size="lg" /></div>Almacén WMS</MenuItem>
                            </NavLink>
                          </div>
                        ) :
                        (<div />)
                      }
                      {(status === "signedin" && isadmin === "true") ?
                        (
                          <div>
                            <NavLink to="/WarehousePositionWMS">
                              <MenuItem onClick={handleCloseConfiguration}><div className="mr-2"><FontAwesomeIcon icon={faShelves} size="lg" /></div>Posiciones Almacén WMS</MenuItem>
                            </NavLink>
                          </div>
                        ) :
                        (<div />)
                      }
                      {(status === "signedin" && isadmin === "true") ?
                        (
                          <div>
                            <NavLink to="/ItemWMS">
                              <MenuItem onClick={handleCloseConfiguration}><div className="mr-2"><FontAwesomeIcon icon={faBedFront} size="lg" /></div>Artículos WMS</MenuItem>
                            </NavLink>
                          </div>
                        ) :
                        (<div />)
                      }
                      {(status === "signedin" && isadmin === "true") ?
                        (
                          <div>
                            <NavLink to="/FulfillmentOrderWMSPriority">
                              <MenuItem onClick={handleCloseConfiguration}><div className="mr-2"><FontAwesomeIcon icon={faSort} size="lg" /></div>Prioridad Surtido WMS</MenuItem>
                            </NavLink>
                          </div>
                        ) :
                        (<div />)
                      }
                    </Menu>
                  </div>
                ) :
                (<div></div>)
              }

              {/* *******MENU CUSTOMER******* */}
              <Button
                id="buttonCustomer"
                aria-controls={openCustomer ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={openCustomer ? 'true' : undefined}
                onClick={handleClickCustomer}
                endIcon={<KeyboardArrowDownIcon />}
                style={{ fontSize: '15px', color: '#FFF', backgroundColor: "#4B5563", margin: '0', borderRadius: 0 }}
              >
                <div className="mr-2"><FontAwesomeIcon icon={faUser} size="lg" /></div>Clientes
              </Button>
              <Menu
                id="menuCustomer"
                anchorEl={anchorCustomer}
                open={openCustomer}
                onClose={handleCloseCustomer}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                <NavLink to="/Rastreopedidos"><MenuItem onClick={handleCloseCustomer}><div className="mr-2"><FontAwesomeIcon icon={faTruck} size="lg" /></div>Rastreo de Pedidos</MenuItem></NavLink>
                <NavLink to="/DownloadInvoice"><MenuItem onClick={handleCloseCustomer}><div className="mr-4"><FontAwesomeIcon icon={faFileDownload} size="lg" /></div>Descarga tu Factura</MenuItem></NavLink>
              </Menu>

              {/* *******MENU EMPLOYEE******* */}
              {status === "signedin" && (attendance === true || commission === true || incentive === true || checklist === true || checklistAud === true || checklistVeh === true || pdfDocument === true || vacation === true) ?
                (
                  <div>
                    <Button
                      id="buttonEmployee"
                      aria-controls={openEmployee ? 'basic-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={openEmployee ? 'true' : undefined}
                      onClick={handleClickEmployee}
                      endIcon={<KeyboardArrowDownIcon />}
                      style={{ fontSize: '15px', color: '#FFF', backgroundColor: "#4B5563", margin: '0', borderRadius: 0 }}
                    >
                      <div className="mr-2"><FontAwesomeIcon icon={faAddressCard} size="lg" /></div>Empleados
                    </Button>
                    <Menu
                      id="menuEmployee"
                      anchorEl={anchorEmployee}
                      open={openEmployee}
                      onClose={handleCloseEmployee}
                      MenuListProps={{
                        'aria-labelledby': 'basic-button',
                      }}
                    >
                      {status === "signedin" && attendance === true ?
                        (
                          <div>
                            <NavLink to="/Attendance"><MenuItem onClick={handleCloseEmployee}><div className="mr-2"><FontAwesomeIcon icon={faMapMarkerAlt} size="lg" /></div>Asistencia</MenuItem></NavLink>
                            <Divider />
                          </div>
                        ) :
                        (<div />)
                      }

                      {status === "signedin" && commission === true ?
                        (<NavLink to="/Commission"><MenuItem onClick={handleCloseEmployee}><div className="mr-2"><FontAwesomeIcon icon={faSackDollar} size="lg" /></div>Comisiones</MenuItem></NavLink>) :
                        (<div />)
                      }

                      {status === "signedin" && incentive === true ?
                        (
                          <div>
                            <NavLink to="/Incentive"><MenuItem onClick={handleCloseEmployee}><div className="mr-2"><FontAwesomeIcon icon={faTrophy} size="lg" /></div>Incentivos</MenuItem></NavLink>
                            <Divider />
                          </div>
                        ) :
                        (<div />)
                      }

                      {status === "signedin" && checklist === true ?
                        (<NavLink to="/Checklist"><MenuItem onClick={handleCloseEmployee}><div className="mr-2"><FontAwesomeIcon icon={faUserSecret} size="lg" /></div>Checklist Supervisores</MenuItem></NavLink>) :
                        (<div />)
                      }

                      {status === "signedin" && checklistAud === true ?
                        (<NavLink to="/ChecklistAud"><MenuItem onClick={handleCloseEmployee}><div className="mr-2"><FontAwesomeIcon icon={faUserTie} size="lg" /></div>Checklist Auditores</MenuItem></NavLink>) :
                        (<div />)
                      }

                      {status === "signedin" && checklistVeh === true ?
                        (<NavLink to="/ChecklistVeh"><MenuItem onClick={handleCloseEmployee}><div className="mr-2"><FontAwesomeIcon icon={faTruckMoving} size="lg" /></div>Checklist Vehículos</MenuItem></NavLink>) :
                        (<div />)
                      }

                      {status === "signedin" && pdfDocument === true ?
                        (
                          <div>
                            <Divider />
                            <NavLink to="/DocumentViewer"><MenuItem onClick={handleCloseEmployee}><div className="mr-2"><FontAwesomeIcon icon={faBook} size="lg" /></div>Manual de Capacitación</MenuItem></NavLink>
                          </div>
                        ) :
                        (<div />)
                      }

                      {status === "signedin" && (vacation === true) ?
                        (
                          <div>
                            <Divider />
                            <NavLink to="/Vacation"><MenuItem onClick={handleCloseEmployee}><div className="mr-2"><FontAwesomeIcon icon={faUmbrellaBeach} size="lg" /></div>Solicitud de Vacaciones</MenuItem></NavLink>
                          </div>
                        ) :
                        (<div />)
                      }
                    </Menu>
                  </div>
                ) :
                (<div></div>)
              }

              {/* *******MENU SALES******* */}
              {status === "signedin" && (pricelist === true || procesarFacturas === true || procesarFacturasBodega === true || procesarFacturasSucursal === true || estimate === true || lostDeal === true || salesOrder === true || invoice === true || customerPayment === true || salesOrderMod === true || cashRegister === true || closeSalesOrder === true) ?
                (
                  <div>
                    <Button
                      id="buttonSales"
                      aria-controls={openSales ? 'basic-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={openSales ? 'true' : undefined}
                      onClick={handleClickSales}
                      endIcon={<KeyboardArrowDownIcon />}
                      style={{ fontSize: '15px', color: '#FFF', backgroundColor: "#4B5563", margin: '0', borderRadius: 0 }}
                    >
                      <div className="mr-2"><FontAwesomeIcon icon={faBed} size="lg" /></div>Ventas
                    </Button>
                    <Menu
                      id="menuSales"
                      anchorEl={anchorSales}
                      open={openSales}
                      onClose={handleCloseSales}
                      MenuListProps={{
                        'aria-labelledby': 'basic-button',
                      }}
                    >

                      {status === "signedin" && estimate === true ?
                        (<NavLink to="/Estimate"><MenuItem onClick={handleCloseSales}><div className="mr-2"><FontAwesomeIcon icon={faCommentDollar} size="lg" /></div>Cotización</MenuItem></NavLink>) :
                        (<div />)
                      }
                      {status === "signedin" && lostDeal === true ?
                        (<NavLink to="/LostDeal"><MenuItem onClick={handleCloseSales}><div className="mr-2"><FontAwesomeIcon icon={faThumbsDown} size="lg" /></div>Venta Perdida</MenuItem></NavLink>) :
                        (<div />)
                      }
                      {status === "signedin" && salesOrder === true ?
                        (<NavLink to="/SalesOrder"><MenuItem onClick={handleCloseSales}><div className="mr-2"><FontAwesomeIcon icon={faHandshake} size="lg" /></div>Pedido</MenuItem></NavLink>) :
                        (<div />)
                      }
                      {status === "signedin" && invoice === true ?
                        (<NavLink to="/Invoice"><MenuItem onClick={handleCloseSales}><div className="mr-2"><FontAwesomeIcon icon={faFileInvoiceDollar} size="lg" /></div>Factura</MenuItem></NavLink>) :
                        (<div />)
                      }
                      {status === "signedin" && customerPayment === true ?
                        (<div>
                          <Divider />
                          <NavLink to="/CustomerPayment"><MenuItem onClick={handleCloseSales}><div className="mr-2"><FontAwesomeIcon icon={faPiggyBank} size="lg" /></div>Anticipo Saldo</MenuItem></NavLink>
                          <Divider />
                        </div>
                        ) :
                        (<div />)
                      }
                      {status === "signedin" && salesOrderMod === true ?
                        (<div>
                          <Divider />
                          <NavLink to="/SalesOrderMod"><MenuItem onClick={handleCloseSales}><div className="mr-2"><FontAwesomeIcon icon={faPenNib} size="lg" /></div>Modificación de Pedidos</MenuItem></NavLink>
                          <Divider />
                        </div>
                        ) :
                        (<div />)
                      }
                      {status === "signedin" && pricelist === true ?
                        (<div>
                          <Divider />
                          <NavLink to="/Pricelist"><MenuItem onClick={handleCloseSales}><div className="mr-2"><FontAwesomeIcon icon={faDollarSign} size="lg" /></div>Lista de Precios</MenuItem></NavLink>
                          <Divider />
                        </div>
                        ) :
                        (<div />)
                      }
                      {status === "signedin" && procesarFacturas === true ?
                        (<NavLink to="/ProcessInvoice"><MenuItem onClick={handleCloseSales}><div className="mr-2"><FontAwesomeIcon icon={faGear} size="lg" /></div>Procesar Facturas</MenuItem></NavLink>) :
                        (<div />)
                      }
                      {status === "signedin" && procesarFacturasSucursal === true ?
                        (<NavLink to="/ProcessInvoiceLoc"><MenuItem onClick={handleCloseSales}><div className="mr-2"><FontAwesomeIcon icon={faMapLocationDot} size="lg" /></div>Procesar Facturas Sucursal</MenuItem></NavLink>) :
                        (<div />)
                      }
                      {status === "signedin" && procesarFacturasBodega === true ?
                        (<NavLink to="/ProcessInvoiceWHS"><MenuItem onClick={handleCloseSales}><div className="mr-2"><FontAwesomeIcon icon={faWarehouse} size="lg" /></div>Procesar Facturas Bodega</MenuItem></NavLink>) :
                        (<div />)
                      }
                      {status === "signedin" && cashRegister === true ?
                        (<div>
                          <Divider />
                          <NavLink to="/CashRegister"><MenuItem onClick={handleCloseSales}><div className="mr-2"><FontAwesomeIcon icon={faCashRegister} size="lg" /></div>Corte de Caja</MenuItem></NavLink>
                        </div>
                        ) :
                        (<div />)
                      }
                      {status === "signedin" && closeSalesOrder === true ?
                        (<div>
                          <Divider />
                          <NavLink to="/LockSalesOrder"><MenuItem onClick={handleCloseSales}><div className="mr-2"><FontAwesomeIcon icon={faLockKeyhole} size="lg" /></div>Cerrar Pedidos</MenuItem></NavLink>
                          <NavLink to="/UnlockSalesOrder"><MenuItem onClick={handleCloseSales}><div className="mr-2"><FontAwesomeIcon icon={faLockKeyholeOpen} size="lg" /></div>Abrir Pedidos</MenuItem></NavLink>
                        </div>
                        ) :
                        (<div />)
                      }
                    </Menu>
                  </div>
                ) :
                (<div></div>)
              }

              {/* *******MENU LOGISTICS******* */}
              {status === "signedin" && (directDelivery === true || directReturn === true || deliveryRequest === true || returnRequest === true || transferOrder === true || shipmentRequest === true || invTransferOrder === true || inventoryTransfer === true || invItemFulfillment === true || invItemReceipt === true) ?
                (
                  <div>
                    <Button
                      id="buttonLogistics"
                      aria-controls={openLogistics ? 'basic-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={openLogistics ? 'true' : undefined}
                      onClick={handleClickLogistics}
                      endIcon={<KeyboardArrowDownIcon />}
                      style={{ fontSize: '15px', color: '#FFF', backgroundColor: "#4B5563", margin: '0', borderRadius: 0 }}
                    >
                      <div className="mr-2"><FontAwesomeIcon icon={faTruckFlatbed} size="lg" /></div>Logística
                    </Button>
                    <Menu
                      id="menuLogistics"
                      anchorEl={anchorLogistics}
                      open={openLogistics}
                      onClose={handleCloseLogistics}
                      MenuListProps={{
                        'aria-labelledby': 'basic-button',
                      }}
                    >
                      {status === "signedin" && directDelivery === true ?
                        (<NavLink to="/DirectDelivery" replace ><MenuItem onClick={handleCloseLogistics}><div className="mr-2"><FontAwesomeIcon icon={faPersonDolly} size="lg" /></div>Entrega Directa</MenuItem></NavLink>) :
                        (<div />)
                      }
                      {status === "signedin" && directReturn === true ?
                        (<NavLink to="/DirectReturn" replace ><MenuItem onClick={handleCloseLogistics}><div className="mr-2"><FontAwesomeIcon icon={faPersonCarryBox} size="lg" /></div>Devolución Directa</MenuItem></NavLink>) :
                        (<div />)
                      }
                      {status === "signedin" && deliveryRequest === true ?
                        (<NavLink to="/DeliveryRequest" replace ><MenuItem onClick={handleCloseLogistics}><div className="mr-2"><FontAwesomeIcon icon={faTruckFast} size="lg" /></div>Solicitud Envío</MenuItem></NavLink>) :
                        (<div />)
                      }
                      {status === "signedin" && returnRequest === true ?
                        (<NavLink to="/ReturnRequest" replace ><MenuItem onClick={handleCloseLogistics}><div className="mr-2"><FontAwesomeIcon icon={faHandHoldingBox} size="lg" /></div>Solicitud Devolución</MenuItem></NavLink>) :
                        (<div />)
                      }
                      {status === "signedin" && transferOrder === true ?
                        (<NavLink to="/TransferOrder" replace ><MenuItem onClick={handleCloseLogistics}><div className="mr-2"><FontAwesomeIcon icon={faRightLeft} size="lg" /></div>Solicitud Traspaso</MenuItem></NavLink>) :
                        (<div />)
                      }
                      {status === "signedin" && shipmentRequest === true ?
                        (<div>
                          <Divider />
                          <NavLink to="/ShipmentRequest" replace ><MenuItem onClick={handleCloseLogistics}><div className="mr-2"><FontAwesomeIcon icon={faForklift} size="lg" /></div>Solicitud Embarque</MenuItem></NavLink>
                        </div>
                        ) :
                        (<div />)
                      }
                      {status === "signedin" && invTransferOrder === true ?
                        (<div>
                          <Divider />
                          <NavLink to="/InvTransferOrder" replace ><MenuItem onClick={handleCloseLogistics}><div className="mr-2"><FontAwesomeIcon icon={faPalletBoxes} size="lg" /></div>Inventarios - Solicitud Traspaso</MenuItem></NavLink>
                        </div>
                        ) :
                        (<div />)
                      }
                      {status === "signedin" && invItemFulfillment === true ?
                        (<div>
                          <NavLink to="/InvItemFulfillment" replace ><MenuItem onClick={handleCloseLogistics}><div className="mr-2"><FontAwesomeIcon icon={faLeft} size="lg" /></div>Inventarios - Salida Traspaso</MenuItem></NavLink>
                        </div>
                        ) :
                        (<div />)
                      }
                      {status === "signedin" && invItemReceipt === true ?
                        (<div>
                          <NavLink to="/InvItemReceipt" replace ><MenuItem onClick={handleCloseLogistics}><div className="mr-2"><FontAwesomeIcon icon={faRight} size="lg" /></div>Inventarios - Recibo Traspaso</MenuItem></NavLink>
                        </div>
                        ) :
                        (<div />)
                      }
                      {status === "signedin" && inventoryTransfer === true ?
                        (<div>
                          <Divider />
                          <NavLink to="/InventoryTransfer" replace><MenuItem onClick={handleCloseLogistics}><div className="mr-2"><FontAwesomeIcon icon={faWineGlassCrack} size="lg" /></div>Transferencia</MenuItem></NavLink>
                        </div>
                        ) :
                        (<div />)
                      }
                    </Menu>
                  </div>
                ) :
                (<div></div>)
              }

              {/* *******MENU WMS******* */}
              {status === "signedin" && (createLabelWMS === true || receiveOrderWMS === true || fulfillmentOrderWMS === true || adjustItemWMS === true || moveItemWMS === true || itemCountWMS === true) ?
                (
                  <div>
                    <Button
                      id="buttonWMS"
                      aria-controls={openWMS ? 'basic-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={openWMS ? 'true' : undefined}
                      onClick={handleClickWMS}
                      endIcon={<KeyboardArrowDownIcon />}
                      style={{ fontSize: '15px', color: '#FFF', backgroundColor: "#4B5563", margin: '0', borderRadius: 0 }}
                    >
                      <div className="mr-2"><FontAwesomeIcon icon={faScannerGun} size="lg" /></div>WMS
                    </Button>
                    <Menu
                      id="menuWMS"
                      anchorEl={anchorWMS}
                      open={openWMS}
                      onClose={handleCloseWMS}
                      MenuListProps={{
                        'aria-labelledby': 'basic-button',
                      }}
                    >
                      {status === "signedin" && createLabelWMS === true ?
                        (<NavLink to="/LabelWMS" replace ><MenuItem onClick={handleCloseWMS}><div className="mr-2"><FontAwesomeIcon icon={faBarcode} size="lg" /></div>Alta Barcode</MenuItem></NavLink>) :
                        (<div />)
                      }

                      {status === "signedin" && receiveOrderWMS === true ?
                        (<div>
                          <Divider />
                          <NavLink to="/ReceiveOrderWMS" replace ><MenuItem onClick={handleCloseWMS}><div className="mr-2"><FontAwesomeIcon icon={faInboxIn} size="lg" /></div>Orden Recibo</MenuItem></NavLink>
                        </div>
                        ) :
                        (<div />)

                      }

                      {status === "signedin" && fulfillmentOrderWMS === true ?
                        (<div>
                          <Divider />
                          <NavLink to="/FulfillmentOrderWMS" replace ><MenuItem onClick={handleCloseWMS}><div className="mr-2"><FontAwesomeIcon icon={faInboxOut} size="lg" /></div>Orden Surtido</MenuItem></NavLink>
                        </div>
                        ) :
                        (<div />)
                      }

                      {status === "signedin" && moveItemWMS === true ?
                        (<div>
                          <Divider />
                          <NavLink to="/MoveItemWMS" replace ><MenuItem onClick={handleCloseWMS}><div className="mr-2"><FontAwesomeIcon icon={faCartFlatbedSuitcase} size="lg" /></div>Acomodo</MenuItem></NavLink>
                        </div>
                        ) :
                        (<div />)
                      }

                      {status === "signedin" && adjustItemWMS === true ?
                        (<div>
                          <Divider />
                          <NavLink to="/AdjustItemWMS" replace ><MenuItem onClick={handleCloseWMS}><div className="mr-2"><FontAwesomeIcon icon={faPenToSquare} size="lg" /></div>Ajuste</MenuItem></NavLink>
                        </div>
                        ) :
                        (<div />)
                      }

                      {status === "signedin" && itemCountWMS === true ?
                        (<div>
                          <Divider />
                          <NavLink to="/CreateItemCountWMS" replace ><MenuItem onClick={handleCloseWMS}><div className="mr-2"><FontAwesomeIcon icon={faToolbox} size="lg" /></div>Crear Conteo Cíclico</MenuItem></NavLink>
                          <NavLink to="/ItemCountWMS" replace ><MenuItem onClick={handleCloseWMS}><div className="mr-2"><FontAwesomeIcon icon={faArrowsSpin} size="lg" /></div>Conteo Cíclico</MenuItem></NavLink>
                        </div>
                        ) :
                        (<div />)
                      }
                    </Menu>
                  </div>
                ) :
                (<div></div>)
              }

              {/* *******MENU REPORTES******* */}
              {(status === "signedin") && (kibana === true || kardex === true || invLevels === true || invLevelsIncCost === true || repComparativoVentas === true || exploreBarcodes === true || exploreBarcodeCosts === true || poSuggestionsReport === true) ?
                (
                  <div>
                    <Button
                      id="buttonReports"
                      aria-controls={openReports ? 'basic-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={openReports ? 'true' : undefined}
                      onClick={handleClickReports}
                      endIcon={<KeyboardArrowDownIcon />}
                      style={{ fontSize: '15px', color: '#FFF', backgroundColor: "#4B5563", margin: '0', borderRadius: 0 }}
                    >
                      <div className="mr-2"><FontAwesomeIcon icon={faBook} size="lg" /></div>Reportes
                    </Button>
                    <Menu
                      id="menuReports"
                      anchorEl={anchorReports}
                      open={openReports}
                      onClose={handleCloseReports}
                      MenuListProps={{
                        'aria-labelledby': 'basic-button',
                      }}
                    >
                      {status === "signedin" && kibana === true ?
                        (
                          <div>
                            <MenuItem onClick={handleKibana}><div className="mr-2"><FontAwesomeIcon icon={faChartSimple} size="lg" /></div>Kibana</MenuItem>
                          </div>
                        ) :
                        (<div />)
                      }
                      {status === "signedin" && kardex === true ?
                        (
                          <div>
                            <Divider />
                            <NavLink to="/Kardex" replace ><MenuItem onClick={handleCloseReports}><div className="mr-2"><FontAwesomeIcon icon={faFileChartColumn} size="lg" /></div>Kardex</MenuItem></NavLink>
                          </div>
                        ) :
                        (<div />)
                      }
                      {status === "signedin" && invLevels === true ?
                        (<NavLink to="/InvLevels" replace ><MenuItem onClick={handleCloseReports}><div className="mr-2"><FontAwesomeIcon icon={faFileChartColumn} size="lg" /></div>Inventarios</MenuItem></NavLink>) :
                        (<div />)
                      }
                      {status === "signedin" && invLevelsIncCost === true ?
                        (<NavLink to="/InvLevelsIncCost" replace ><MenuItem onClick={handleCloseReports}><div className="mr-2"><FontAwesomeIcon icon={faFileChartColumn} size="lg" /></div>Inventarios con costos</MenuItem></NavLink>) :
                        (<div />)
                      }
                      {status === "signedin" && repComparativoVentas === true ?
                        (
                          <div>
                            <Divider />
                            <NavLink to="/SalesCompReport" replace ><MenuItem onClick={handleCloseReports}><div className="mr-2"><FontAwesomeIcon icon={faFileChartColumn} size="lg" /></div>Comparativo Ventas</MenuItem></NavLink>
                          </div>
                        ) :
                        (<div />)
                      }
                      {status === "signedin" && exploreBarcodes === true ?
                        (
                          <div>
                            <Divider />
                            <NavLink to="/ExploreBarcode" replace ><MenuItem onClick={handleCloseReports}><div className="mr-2"><FontAwesomeIcon icon={faFileChartColumn} size="lg" /></div>WMS Barcodes</MenuItem></NavLink>
                          </div>
                        ) :
                        (<div />)
                      }
                      {status === "signedin" && exploreBarcodeCosts === true ?
                        (
                          <div>
                            <Divider />
                            <NavLink to="/ExploreBarcodeCosts" replace ><MenuItem onClick={handleCloseReports}><div className="mr-2"><FontAwesomeIcon icon={faFileChartColumn} size="lg" /></div>WMS Barcode Costos</MenuItem></NavLink>
                          </div>
                        ) :
                        (<div />)
                      }
                      {status === "signedin" && poSuggestionsReport === true ?
                        (
                          <div>
                            <Divider />
                            <NavLink to="/POSuggestionsReport" replace ><MenuItem onClick={handleCloseReports}><div className="mr-2"><FontAwesomeIcon icon={faFileChartColumn} size="lg" /></div>Sugerencia de Compras</MenuItem></NavLink>
                          </div>
                        ) :
                        (<div />)
                      }

                    </Menu>
                  </div>
                ) :
                (<div></div>)
              }

              {/* *******MENU ACCOUNT******* */}
              <IconButton
                onClick={handleClickAccount}
                size="small"
                sx={{ ml: 2 }}
                aria-controls={openAccount ? 'account-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={openAccount ? 'true' : undefined}
              >
                <Avatar sx={{ width: 30, height: 30 }}></Avatar>
              </IconButton>

              <Menu
                anchorEl={anchorAccount}
                id="account-menu"
                open={openAccount}
                onClose={handleCloseAccount}
                onClick={handleCloseAccount}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 1.5,
                    '& .MuiAvatar-root': {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    '&:before': {
                      content: '""',
                      display: 'block',
                      position: 'absolute',
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: 'background.paper',
                      transform: 'translateY(-50%) rotate(45deg)',
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
              >
                {status === "signedin" ?
                  (<div>
                    <MenuItem disabled={true} onClick={signOut}><div className="mr-2"><FontAwesomeIcon icon={faCircleUser} size="xl" /></div>Usuario {username}</MenuItem>
                    <Divider />
                  </div>) :
                  (<div />)
                }

                {status === "signedout" ?
                  (<NavLink to="/Signin" replace ><MenuItem onClick={handleCloseAccount}><div className="mr-2"><FontAwesomeIcon icon={faSignInAlt} size="lg" /></div>Iniciar Sesión</MenuItem></NavLink>) :
                  (<div />)
                }
                {status === "signedin" ?
                  (<div>
                    <NavLink to="/PasswordChange" replace ><MenuItem onClick={handleCloseConfiguration}><div className="mr-2"><FontAwesomeIcon icon={faKey} size="lg" /></div>Cambiar Contraseña</MenuItem></NavLink>
                    <Divider />
                  </div>
                  ) :
                  (<div />)
                }
                {status === "signedin" ?
                  (<NavLink to="/Signout" replace ><MenuItem onClick={signOut}><div className="mr-2"><FontAwesomeIcon icon={faSignOutAlt} size="lg" /></div>Cerrar Sesión</MenuItem></NavLink>) :
                  (<div />)
                }
              </Menu>
            </Box>
          </React.Fragment>
          <div className="content">
            <Route exact path="/" render={(props) => <Home {...props} usuario={username} status={status} />} />
            <Route exact path="/Rastreopedidos" render={(props) => <Rastreopedidos {...props} />} />
            <Route exact path="/DownloadInvoice" render={(props) => <DownloadInvoice {...props} />} />

            {status === "signedin" && attendance === true ?
              (<Route exact path="/Attendance" render={(props) => <Attendance {...props} personal={personal} attendanceAccess={attendanceAccess} />} />) :
              (<Route />)
            }
            {status === "signedout" ?
              (<Route exact path="/Signin" render={(props) => <Signin {...props} status={status} />} />) :
              (<Route />)
            }
            {status === "signedin" && pricelist === true ?
              (<Route exact path="/Pricelist" render={(props) => <Pricelist {...props} sucursal={sucursal} />} />) :
              (<Route />)
            }
            {status === "signedin" && commission === true ?
              (<Route path="/Commission" render={(props) => <Commission {...props} personal={personal} />} />) :
              (<Route />)
            }
            {status === "signedin" && incentive === true ?
              (<Route path="/Incentive" render={(props) => <Incentive {...props} personal={personal} />} />) :
              (<Route />)
            }
            {status === "signedin" && checklist === true ?
              (<Route path="/Checklist" render={(props) => <Checklist {...props} personal={personal} usuario={username} checklistAccess={checklistAccess} newChecklist={newChecklist} />} />) :
              (<Route />)
            }
            {status === "signedin" && checklistAud === true ?
              (<Route path="/ChecklistAud" render={(props) => <ChecklistAud {...props} personal={personal} usuario={username} checklistAccess={checklistAccess} newChecklistAud={newChecklistAud} />} />) :
              (<Route />)
            }
            {status === "signedin" && checklistVeh === true ?
              (<Route path="/ChecklistVeh" render={(props) => <ChecklistVeh {...props} personal={personal} usuario={username} checklistAccess={checklistAccess} newChecklistVeh={newChecklistVeh} />} />) :
              (<Route />)
            }
            {status === "signedin" && isadmin === "true" ?
              (<Route path="/Document" render={(props) => <Document {...props} isadmin={isadmin} usuario={username} personal={personal} />} />) :
              (<Route />)
            }
            {status === "signedin" && pdfDocument === true ?
              (<Route path="/DocumentViewer" render={(props) => <DocumentViewer {...props} isadmin={isadmin} usuario={username} personal={personal} />} />) :
              (<Route />)
            }
            {status === "signedin" && (vacation === true) ?
              (<Route path="/Vacation" render={(props) => <Vacation {...props} personal={personal} usuario={username} vacationAccess={vacationAccess} />} />) :
              (<Route />)
            }
            {(status === "signedin" && isadmin === "true") ?
              (<Route exact path="/Signup" render={(props) => <Signup {...props} status={status} handleAuthStateChange={handleAuthStateChange} />} />) :
              (<Route />)
            }
            {(status === "signedin" && isadmin === "true") ?
              (<Route path="/Users" render={(props) => <Users {...props} status={status} />} />) :
              (<Route />)
            }
            {(status === "signedin" && isadmin === "true") ?
              (<Route path="/PdfTemplate" render={(props) => <PdfTemplate {...props} />} />) :
              (<Route />)
            }
            {(status === "signedin") ?
              (<Route path="/PdfRender" render={(props) => <PdfRender {...props} />} />) :
              (<Route />)
            }
            {(status === "signedin" && isadmin === "true") ?
              (<Route path="/PdfEditor" render={(props) => <PdfEditor {...props} />} />) :
              (<Route />)
            }
            {(status === "signedin" && isadmin === "true") ?
              (<Route path="/WarehouseWMS" render={(props) => <WarehouseWMS {...props} />} />) :
              (<Route />)
            }
            {(status === "signedin" && isadmin === "true") ?
              (<Route path="/WarehousePositionWMS" render={(props) => <WarehousePositionWMS {...props} />} />) :
              (<Route />)
            }
            {(status === "signedin" && isadmin === "true") ?
              (<Route path="/ItemWMS" render={(props) => <ItemWMS {...props} />} />) :
              (<Route />)
            }
            {(status === "signedin" && isadmin === "true") ?
              (<Route path="/FulfillmentOrderWMSPriority" render={(props) => <FulfillmentOrderWMSPriority {...props} />} />) :
              (<Route />)
            }
            {status === "signedin" ?
              (<Route path="/PasswordChange" render={(props) => <PasswordChange {...props} />} />) :
              (<Route />)
            }
            {status === "signedin" ?
              (<Route path="/Signout" component={Signout} />) :
              (<Route />)
            }
            {status === "signedin" && procesarFacturas === true ?
              (<Route exact path="/ProcessInvoice" render={(props) => <ProcessInvoice {...props} usuario={usuario} afectarFacturas={afectarFacturas} salesAccess={salesAccess} />} />) :
              (<Route />)
            }
            {status === "signedin" && procesarFacturasSucursal === true ?
              (<Route exact path="/ProcessInvoiceLoc" render={(props) => <ProcessInvoiceLoc {...props} usuario={usuario} afectarFacturas={afectarFacturas} salesAccess={salesAccess} />} />) :
              (<Route />)
            }
            {status === "signedin" && procesarFacturasBodega === true ?
              (<Route exact path="/ProcessInvoiceWHS" render={(props) => <ProcessInvoiceWHS {...props} usuario={usuario} afectarFacturas={afectarFacturas} salesAccess={salesAccess} />} />) :
              (<Route />)
            }
            {status === "signedin" && estimate === true ?
              (<Route path="/Estimate" render={(props) => <Estimate {...props} username={username} sucursal={sucursal} usuario={usuario} nivel={estimateLevel} salesAccess={salesAccess} lostDeal={lostDeal} lostDealLevel={lostDealLevel} salesOrder={salesOrder} salesOrderLevel={salesOrderLevel} />} />) :
              (<Route />)
            }
            {status === "signedin" && lostDeal === true ?
              (<Route path="/LostDeal" render={(props) => <LostDeal {...props} username={username} sucursal={sucursal} usuario={usuario} nivel={lostDealLevel} salesAccess={salesAccess} />} />) :
              (<Route />)
            }
            {status === "signedin" && salesOrder === true ?
              (<Route path="/SalesOrder" render={(props) => <SalesOrder {...props} username={username} sucursal={sucursal} usuario={usuario} agente={agente} nivel={salesOrderLevel} salesAccess={salesAccess} customerPayment={customerPayment} customerPaymentLevel={customerPaymentLevel} directDelivery={directDelivery} directDeliveryLevel={directDeliveryLevel} deliveryRequest={deliveryRequest} deliveryRequestLevel={deliveryRequestLevel} directReturn={directReturn} directReturnLevel={directReturnLevel} returnRequest={returnRequest} returnRequestLevel={returnRequestLevel} transferOrder={transferOrder} transferOrderLevel={transferOrderLevel} />} />) :
              (<Route />)
            }
            {status === "signedin" && invoice === true ?
              (<Route path="/Invoice" render={(props) => <Invoice {...props} username={username} sucursal={sucursal} usuario={usuario} nivel={invoiceLevel} salesAccess={salesAccess} regenerateCFDi={regenerateCFDi} sendCFDi={sendCFDi} />} />) :
              (<Route />)
            }
            {status === "signedin" && customerPayment === true ?
              (<Route path="/CustomerPayment" render={(props) => <CustomerPayment {...props} username={username} sucursal={sucursal} usuario={usuario} nivel={customerPaymentLevel} salesAccess={salesAccess} regenerateCFDi={regenerateCFDi} sendCFDi={sendCFDi} />} />) :
              (<Route />)
            }
            {status === "signedin" && salesOrderMod === true ?
              (<Route path="/SalesOrderMod" render={(props) => <SalesOrderMod {...props} username={username} sucursal={sucursal} usuario={usuario} nivel={salesOrderModLevel} salesAccess={salesAccess} salesOrderModUpdatePrices={salesOrderModUpdatePrices} approveSalesOrderMod={approveSalesOrderMod} />} />) :
              (<Route />)
            }
            {status === "signedin" && cashRegister === true ?
              (<Route path="/CashRegister" render={(props) => <CashRegister {...props} username={username} sucursal={sucursal} usuario={usuario} nivel={cashRegisterLevel} salesAccess={salesAccess} />} />) :
              (<Route />)
            }
            {status === "signedin" && directDelivery === true ?
              (<Route path="/DirectDelivery" render={(props) => <DirectDelivery {...props} username={username} sucursal={sucursal} usuario={usuario} nivel={directDeliveryLevel} approveDelivery={approveDelivery} voidDeliveryTransactions={voidDeliveryTransactions} salesAccess={salesAccess} />} />) :
              (<Route />)
            }
            {status === "signedin" && directReturn === true ?
              (<Route path="/DirectReturn" render={(props) => <DirectReturn {...props} username={username} sucursal={sucursal} usuario={usuario} nivel={directReturnLevel} voidDeliveryTransactions={voidDeliveryTransactions} salesAccess={salesAccess} />} />) :
              (<Route />)
            }
            {status === "signedin" && deliveryRequest === true ?
              (<Route path="/DeliveryRequest" render={(props) => <DeliveryRequest {...props} username={username} sucursal={sucursal} usuario={usuario} nivel={deliveryRequestLevel} voidDeliveryTransactions={voidDeliveryTransactions} salesAccess={salesAccess} />} />) :
              (<Route />)
            }
            {status === "signedin" && returnRequest === true ?
              (<Route path="/ReturnRequest" render={(props) => <ReturnRequest {...props} username={username} sucursal={sucursal} usuario={usuario} nivel={returnRequestLevel} voidDeliveryTransactions={voidDeliveryTransactions} salesAccess={salesAccess} />} />) :
              (<Route />)
            }
            {status === "signedin" && transferOrder === true ?
              (<Route path="/TransferOrder" render={(props) => <TransferOrder {...props} username={username} sucursal={sucursal} usuario={usuario} nivel={transferOrderLevel} salesAccess={salesAccess} />} />) :
              (<Route />)
            }
            {status === "signedin" && shipmentRequest === true ?
              (<Route path="/ShipmentRequest" render={(props) => <ShipmentRequest {...props} username={username} sucursal={sucursal} usuario={usuario} nivel={shipmentRequestLevel} salesAccess={salesAccess} />} />) :
              (<Route />)
            }
            {status === "signedin" && invTransferOrder === true ?
              (<Route path="/InvTransferOrder" render={(props) => <InvTransferOrder {...props} username={username} sucursal={sucursal} usuario={usuario} nivel={invTransferOrderLevel} salesAccess={salesAccess} approveInvTransferOrder={approveInvTransferOrder} invItemFulfillment={invItemFulfillment} invItemFulfillmentLevel={invItemFulfillmentLevel} invItemReceipt={invItemReceipt} invItemReceiptLevel={invItemReceiptLevel} />} />) :
              (<Route />)
            }
            {status === "signedin" && invItemFulfillment === true ?
              (<Route path="/InvItemFulfillment" render={(props) => <InvItemFulfillment {...props} username={username} sucursal={sucursal} usuario={usuario} nivel={invItemFulfillmentLevel} salesAccess={salesAccess} />} />) :
              (<Route />)
            }
            {status === "signedin" && invItemReceipt === true ?
              (<Route path="/InvItemReceipt" render={(props) => <InvItemReceipt {...props} username={username} sucursal={sucursal} usuario={usuario} nivel={invItemReceiptLevel} salesAccess={salesAccess} />} />) :
              (<Route />)
            }
            {status === "signedin" && inventoryTransfer === true ?
              (<Route path="/InventoryTransfer" render={(props) => <InventoryTransfer {...props} username={username} sucursal={sucursal} usuario={usuario} nivel={inventoryTransferLevel} salesAccess={salesAccess} approveInventoryTransfer={approveInventoryTransfer} />} />) :
              (<Route />)
            }
            {status === "signedin" && kardex === true ?
              (<Route path="/Kardex" render={(props) => <Kardex {...props} username={username} sucursal={sucursal} usuario={usuario} />} />) :
              (<Route />)
            }
            {status === "signedin" && invLevels === true ?
              (<Route path="/InvLevels" render={(props) => <InvLevels {...props} username={username} sucursal={sucursal} usuario={usuario} />} />) :
              (<Route />)
            }
            {status === "signedin" && invLevelsIncCost === true ?
              (<Route path="/InvLevelsIncCost" render={(props) => <InvLevelsIncCost {...props} username={username} sucursal={sucursal} usuario={usuario} />} />) :
              (<Route />)
            }
            {status === "signedin" && repComparativoVentas === true ?
              (<Route path="/SalesCompReport" render={(props) => <SalesCompReport {...props} username={username} />} />) :
              (<Route />)
            }
            {status === "signedin" && poSuggestionsReport === true ?
              (<Route path="/POSuggestionsReport" render={(props) => <POSuggestionsReport {...props} username={username} poSuggestionsReportCfg={poSuggestionsReportCfg} />} />) :
              (<Route />)
            }
            {status === "signedin" && poSuggestionsReport === true && poSuggestionsReportCfg === true ?
              (<Route path="/ItemPOSuggestion" render={(props) => <ItemPOSuggestion {...props} username={username} poSuggestionsReportCfg={poSuggestionsReportCfg} />} />) :
              (<Route />)
            }
            {status === "signedin" && poSuggestionsReport === true && poSuggestionsReportCfg === true ?
              (<Route path="/WarehousePOSuggestion" render={(props) => <WarehousePOSuggestion {...props} username={username} poSuggestionsReportCfg={poSuggestionsReportCfg} />} />) :
              (<Route />)
            }
            {status === "signedin" && exploreBarcodes === true ?
              (<Route path="/ExploreBarcode" render={(props) => <ExploreBarcode {...props} />} />) :
              (<Route />)
            }
            {status === "signedin" && exploreBarcodeCosts === true ?
              (<Route path="/ExploreBarcodeCosts" render={(props) => <ExploreBarcodeCosts {...props} />} />) :
              (<Route />)
            }
            {status === "signedin" && createLabelWMS === true ?
              (<Route path="/LabelWMS" render={(props) => <LabelWMS {...props} username={username} sucursal={sucursal} usuario={usuario} nivel={createLabelWMSLevel} salesAccess={salesAccess} />} />) :
              (<Route />)
            }
            {status === "signedin" && receiveOrderWMS === true ?
              (<Route path="/ReceiveOrderWMS" render={(props) => <ReceiveOrderWMS {...props} username={username} sucursal={sucursal} usuario={usuario} nivel={receiveOrderWMSLevel} salesAccess={salesAccess} />} />) :
              (<Route />)
            }
            {status === "signedin" && fulfillmentOrderWMS === true ?
              (<Route path="/FulfillmentOrderWMS" render={(props) => <FulfillmentOrderWMS {...props} username={username} sucursal={sucursal} usuario={usuario} nivel={fulfillmentOrderWMSLevel} salesAccess={salesAccess} assignFulfillmentOrderWMS={assignFulfillmentOrderWMS} />} />) :
              (<Route />)
            }
            {status === "signedin" && moveItemWMS === true ?
              (<Route path="/MoveItemWMS" render={(props) => <MoveItemWMS {...props} username={username} sucursal={sucursal} usuario={usuario} salesAccess={salesAccess} />} />) :
              (<Route />)
            }
            {status === "signedin" && adjustItemWMS === true ?
              (<Route path="/AdjustItemWMS" render={(props) => <AdjustItemWMS {...props} username={username} sucursal={sucursal} usuario={usuario} salesAccess={salesAccess} nivel={adjustItemWMSLevel} approveAdjustItemWMS={approveAdjustItemWMS} />} />) :
              (<Route />)
            }
            {status === "signedin" && itemCountWMS === true ?
              (<Route path="/ItemCountWMS" render={(props) => <ItemCountWMS {...props} username={username} sucursal={sucursal} usuario={usuario} salesAccess={salesAccess} nivel={itemCountWMSLevel} approveItemCountWMS={approveItemCountWMS} />} />) :
              (<Route />)
            }
            {status === "signedin" && itemCountWMS === true ?
              (<Route path="/CreateItemCountWMS" render={(props) => <CreateItemCountWMS {...props} username={username} sucursal={sucursal} usuario={usuario} salesAccess={salesAccess} nivel={itemCountWMSLevel} approveItemCountWMS={approveItemCountWMS} />} />) :
              (<Route />)
            }
            {status === "signedin" && closeSalesOrder === true ?
              (<Route path="/LockSalesOrder" render={(props) => <LockSalesOrder {...props} username={username} sucursal={sucursal} usuario={usuario} nivel={cashRegisterLevel} salesAccess={salesAccess} />} />) :
              (<Route />)
            }
            {status === "signedin" && closeSalesOrder === true ?
              (<Route path="/UnlockSalesOrder" render={(props) => <UnlockSalesOrder {...props} username={username} sucursal={sucursal} usuario={usuario} nivel={cashRegisterLevel} salesAccess={salesAccess} />} />) :
              (<Route />)
            }
          </div>
        </div>
      </HashRouter>
    </div>
  );
}