import React, { useState, useEffect, useRef, useMemo } from 'react';

// LoadPanel
import { LoadPanel } from 'devextreme-react/load-panel';

// Toast
import { Toast } from 'devextreme-react/toast';

//Form
import Form, { GroupItem, Label, TabbedItem, TabPanelOptions, Tab, EmptyItem, StringLengthRule } from 'devextreme-react/form';

import { Auth, API, Storage, label } from 'aws-amplify';

// Ruteador
import { Route, Link, useHistory } from "react-router-dom";

// Grid
import DataGrid, {
    Column, FilterRow, HeaderFilter, FilterPanel, FilterBuilderPopup,
    GroupPanel, Grouping, SearchPanel, Sorting, Editing, Lookup, Toolbar, Item, RequiredRule,
    Scrolling, Pager, Paging, Summary, TotalItem, StateStoring, ColumnChooser, Format, Selection,
    Export, ColumnChooserSearch, ColumnChooserSelection
} from 'devextreme-react/data-grid';

//CheckBox
import CheckBox from 'devextreme-react/check-box';

// Iconos
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShelves } from "@fortawesome/pro-duotone-svg-icons";
import { faPencilAlt } from "@fortawesome/pro-solid-svg-icons";
import { faArrowRotateLeft } from "@fortawesome/pro-solid-svg-icons";
import { faList } from "@fortawesome/pro-duotone-svg-icons";
import { faArrowsRotate } from "@fortawesome/pro-solid-svg-icons";
import { faSave } from "@fortawesome/pro-solid-svg-icons";
import { faFileAlt } from "@fortawesome/pro-solid-svg-icons";
import { faTrash } from "@fortawesome/pro-solid-svg-icons";
import { faNoteSticky } from "@fortawesome/pro-solid-svg-icons";

import 'devextreme/data/odata/store';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';

import '../ListStyle.css';

import dxDateBox from "devextreme/ui/date_box";

import dxNumberBox from "devextreme/ui/number_box";

import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
import { exportDataGrid } from 'devextreme/excel_exporter'

dxDateBox.defaultOptions({
    options: {
        dateSerializationFormat: "yyyy-MM-dd"
    }
});

dxNumberBox.defaultOptions({
    options: {
        step: 0
    }
});

function isNotEmpty(value) {
    return value !== undefined && value !== null && value !== '';
}

export default function WarehousePositionWMS(props) {
    const history = useHistory();

    const [isDetail, setIsDetail] = useState(false);
    const [almacen, setAlmacen] = useState();
    const [posicion, setPosicion] = useState();
    const [warehousePositionWMSPayload, setWarehousePositionWMSPayload] = useState({
        header: {}
    });

    const [almacenes, setAlmacenes] = useState([]);

    const [autoExpandAll, setAutoExpandAll] = useState(true);

    const [visibleLoadPanel, setVisibleLoadPanel] = useState(false);

    const [visibleToast, setVisibleToast] = useState(false);
    const [messageToast, setMessageToast] = useState('');
    const [typeToast, setTypeToast] = useState('success');

    const [activateUpdateForm, setActivateUpdateForm] = useState(false);
    const [activateSaveButton, setActivateSaveButton] = useState(false);
    const [activateDeleteButton, setActivateDeleteButton] = useState(false);
    const [activatePDFButton, setActivatePDFButton] = useState(false);

    //Ref para el form
    const [formDatosGeneralesRef, setFormDatosGeneralesRef] = useState(React.createRef());

    const filterBuilderPopupPosition = {
        of: window,
        at: 'top',
        my: 'top',
        offset: { y: 10 },
    };

    const allowedPageSizes = [5, 10, 15, 20, 'all'];

    const exportFormats = ['xlsx'];

    function getOrderDay(rowData) {
        return (new Date(rowData.OrderDate)).getDay();
    }

    const filterBuilder = {
        customOperations: [{
            name: 'weekends',
            caption: 'Weekends',
            dataTypes: ['date'],
            icon: 'check',
            hasValue: false,
            calculateFilterExpression: () => [[getOrderDay, '=', 0], 'or', [getOrderDay, '=', 6]],
        }],
        allowHierarchicalFields: true,
    };

    const filterValue = [['Estatus', '=', 'ALTA']];

    //Ref para el grid Tablero de Control
    const [gridTableroRef, setGridTableroRef] = useState(React.createRef());

    function onAutoExpandAllChanged() {
        setAutoExpandAll(!autoExpandAll)
    };

    async function getWarehousePositionWMSRecords(sqlFilter) {
        try {
            let apiName = 'AdminSC';
            let path = '/procesaalmacenposicion';

            let myInit = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
                },
                queryStringParameters: {
                    action: 'list',
                    sqlFilter: sqlFilter,
                    salesAccess: props.salesAccess,
                    sucursal: props.sucursal,
                    usuario: props.usuario,
                    username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username
                }
            }

            var warehousePositionWMSGet = await API.get(apiName, path, myInit);
        }
        catch (e) {
            if (e === 'No current user') {
                history.push('/');

                window.location.reload();
            }
        }

        return warehousePositionWMSGet;
    }

    async function getAlmacenes() {
        let apiName = 'AdminSC';
        let path = '/getcatalogo';

        let myInit = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
            },
            queryStringParameters: {
                type: 'AlmacenNormal',
                username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username
            }
        }

        var pedidosGet = await API.get(apiName, path, myInit);

        setAlmacenes(pedidosGet);
    };

    async function getArticulo(sqlFilter) {
        let apiName = 'AdminSC';
        let path = '/getcatalogo';

        let myInit = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
            },
            queryStringParameters: {
                type: 'Articulo',
                username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username,
                sqlFilter: sqlFilter
            }
        }

        var articulosGet = await API.get(apiName, path, myInit);

        return articulosGet;
    };

    const store = new CustomStore({
        key: 'gridKey',
        load(loadOptions) {
            let sqlFilter = '(';
            [
                'filter'
            ].forEach((i) => {
                if (i in loadOptions && isNotEmpty(loadOptions[i])) {
                    var filters = loadOptions[i];

                    var filtersLength = filters.length;

                    for (var i = 0; i <= filtersLength; i++) {
                        var singleFilter = filters[i];
                        var singleFilterType = typeof singleFilter;
                        var singleFilterIsArray = Array.isArray(singleFilter);
                        var singleFilterIsDate = singleFilter instanceof Date;

                        if (singleFilter === 'NULL') {
                            singleFilter = '0';
                        }

                        if (singleFilterType.toString() === 'object' && singleFilterIsArray) {
                            sqlFilter += '(';

                            var subFiltersLength = singleFilter.length;

                            for (var i2 = 0; i2 <= subFiltersLength; i2++) {
                                var singleSubFilter = singleFilter[i2];
                                var singleSubFilterType = typeof singleSubFilter;
                                var singleSubFilterIsArray = Array.isArray(singleSubFilter);
                                var singleSubFilterIsDate = singleSubFilter instanceof Date

                                if (singleSubFilter === 'NULL') {
                                    singleSubFilter = '0';
                                }

                                if (singleSubFilterType.toString() === 'object' && singleSubFilterIsArray) {
                                    sqlFilter += '(';

                                    var subSubFiltersLength = singleSubFilter.length;

                                    for (var i3 = 0; i3 <= subSubFiltersLength; i3++) {
                                        var singleSubSubFilter = singleSubFilter[i3];
                                        var singleSubSubFilterType = typeof singleSubSubFilter;
                                        var singleSubSubFilterIsArray = Array.isArray(singleSubSubFilter);
                                        var singleSubSubFilterIsDate = singleSubSubFilter instanceof Date

                                        if (singleSubSubFilter === 'NULL') {
                                            singleSubSubFilter = '0';
                                        }

                                        if (singleSubSubFilterType.toString() === 'object' && singleSubSubFilterIsArray) {
                                            sqlFilter += '(';

                                            var subSubSubFiltersLength = singleSubSubFilter.length;

                                            for (var i4 = 0; i4 <= subSubSubFiltersLength; i4++) {
                                                var singleSubSubSubFilter = singleSubSubFilter[i4];
                                                var singleSubSubSubFilterType = typeof singleSubSubSubFilter;
                                                var singleSubSubSubFilterIsArray = Array.isArray(singleSubSubSubFilter);
                                                var singleSubSubSubFilterIsDate = singleSubSubSubFilter instanceof Date

                                                if (singleSubSubSubFilter === 'NULL') {
                                                    singleSubSubSubFilter = '0';
                                                }

                                                if (singleSubSubSubFilterType.toString() === 'object' && singleSubSubSubFilterIsArray) {
                                                    sqlFilter += '(';

                                                    var subSubSubSubFiltersLength = singleSubSubSubFilter.length;

                                                    for (var i5 = 0; i5 <= subSubSubSubFiltersLength; i5++) {
                                                        var singleSubSubSubSubFilter = singleSubSubSubFilter[i5];
                                                        var singleSubSubSubSubFilterType = typeof singleSubSubSubSubFilter;
                                                        var singleSubSubSubSubFilterIsArray = Array.isArray(singleSubSubSubSubFilter);
                                                        var singleSubSubSubSubFilterIsDate = singleSubSubSubSubFilter instanceof Date

                                                        if (singleSubSubSubSubFilter === 'NULL') {
                                                            singleSubSubSubSubFilter = '0';
                                                        }

                                                        if (singleSubSubSubSubFilterType.toString() === 'string') {
                                                            if (singleSubSubSubSubFilter === 'contains') {
                                                                filters[i][i2][i3][i4][i5] = 'LIKE';
                                                                singleSubSubSubSubFilter = 'LIKE';

                                                                filters[i][i2][i3][i4][i5 + 1] = '\'%' + filters[i][i2][i3][i4][i5 + 1] + '%\'';
                                                            }
                                                            else if (singleSubSubSubSubFilter === 'notcontains') {
                                                                filters[i][i2][i3][i4][i5] = 'NOT LIKE';
                                                                singleSubSubSubSubFilter = 'NOT LIKE';

                                                                filters[i][i2][i3][i4][i5 + 1] = '\'%' + filters[i][i2][i3][i4][i5 + 1] + '%\'';
                                                            }
                                                            else if (singleSubSubSubSubFilter === 'startswith') {
                                                                filters[i][i2][i3][i4][i5] = 'LIKE';
                                                                singleSubSubSubSubFilter = 'LIKE';

                                                                filters[i][i2][i3][i4][i5 + 1] = '\'' + filters[i][i2][i3][i4][i5 + 1] + '%\'';
                                                            }
                                                            else if (singleSubSubSubSubFilter === 'endswith') {
                                                                filters[i][i2][i3][i4][i5] = 'LIKE';
                                                                singleSubSubSubSubFilter = 'LIKE';

                                                                filters[i][i2][i3][i4][i5 + 1] = '\'%' + filters[i][i2][i3][i4][i5 + 1] + '\'';
                                                            }
                                                            else if (singleSubSubSubSubFilter === '=' || singleSubSubSubSubFilter === '<>' || singleSubSubSubSubFilter === '>' || singleSubSubSubSubFilter === '>=' || singleSubSubSubSubFilter === '<' || singleSubSubSubSubFilter === '<=') {
                                                                if (!filters[i][i2][i3][i4][i5 + 1])
                                                                    filters[i][i2][i3][i4][i5 + 1] = 'NULL';
                                                                else if (typeof filters[i][i2][i3][i4][i5 + 1] === 'string') {
                                                                    filters[i][i2][i3][i4][i5 + 1] = '\'' + filters[i][i2][i3][i4][i5 + 1] + '\'';
                                                                }
                                                            }
                                                            else if (singleSubSubSubSubFilter === '!') {
                                                                filters[i][i2][i3][i4][i5] = 'NOT';
                                                                singleSubSubSubSubFilter = 'NOT';
                                                            }

                                                            sqlFilter += ' ' + singleSubSubSubSubFilter + ' ';
                                                        }
                                                        else if (singleSubSubSubSubFilterType.toString() === 'object' && singleSubSubSubSubFilterIsDate) {
                                                            sqlFilter += ' \'' + singleSubSubSubSubFilter.toISOString().split('T')[0] + '\' ';
                                                        }
                                                        else if (singleSubSubSubSubFilterType.toString() === 'number') {
                                                            sqlFilter += ' ' + singleSubSubSubSubFilter.toString() + ' ';
                                                        }
                                                        else if (singleSubSubSubSubFilterType.toString() === 'boolean') {
                                                            if (singleSubSubSubSubFilter.toString() === 'true')
                                                                sqlFilter += '1 ';
                                                            else if (singleSubSubSubSubFilter.toString() === 'false' || singleSubSubSubSubFilter === null || singleSubSubSubSubFilter === undefined)
                                                                sqlFilter += '0 ';
                                                        }
                                                    }
                                                }
                                                else if (singleSubSubSubFilterType.toString() === 'string') {
                                                    if (singleSubSubSubFilter === 'contains') {
                                                        filters[i][i2][i3][i4] = 'LIKE';
                                                        singleSubSubSubFilter = 'LIKE';

                                                        filters[i][i2][i3][i4 + 1] = '\'%' + filters[i][i2][i3][i4 + 1] + '%\'';
                                                    }
                                                    else if (singleSubSubSubFilter === 'notcontains') {
                                                        filters[i][i2][i3][i4] = 'NOT LIKE';
                                                        singleSubSubSubFilter = 'NOT LIKE';

                                                        filters[i][i2][i3][i4 + 1] = '\'%' + filters[i][i2][i3][i4 + 1] + '%\'';
                                                    }
                                                    else if (singleSubSubSubFilter === 'startswith') {
                                                        filters[i][i2][i3][i4] = 'LIKE';
                                                        singleSubSubSubFilter = 'LIKE';

                                                        filters[i][i2][i3][i4 + 1] = '\'' + filters[i][i2][i3][i4 + 1] + '%\'';
                                                    }
                                                    else if (singleSubSubSubFilter === 'endswith') {
                                                        filters[i][i2][i3][i4] = 'LIKE';
                                                        singleSubSubSubFilter = 'LIKE';

                                                        filters[i][i2][i3][i4 + 1] = '\'%' + filters[i][i2][i3][i4 + 1] + '\'';
                                                    }
                                                    else if (singleSubSubSubFilter === '=' || singleSubSubSubFilter === '<>' || singleSubSubSubFilter === '>' || singleSubSubSubFilter === '>=' || singleSubSubSubFilter === '<' || singleSubSubSubFilter === '<=') {
                                                        if (!filters[i][i2][i3][i4 + 1])
                                                            filters[i][i2][i3][i4 + 1] = 'NULL';
                                                        else if (typeof filters[i][i2][i3][i4 + 1] === 'string') {
                                                            filters[i][i2][i3][i4 + 1] = '\'' + filters[i][i2][i3][i4 + 1] + '\'';
                                                        }
                                                    }
                                                    else if (singleSubSubSubFilter === '!') {
                                                        filters[i][i2][i3][i4] = 'NOT';
                                                        singleSubSubSubFilter = 'NOT';
                                                    }

                                                    sqlFilter += ' ' + singleSubSubSubFilter + ' ';
                                                }
                                                else if (singleSubSubSubFilterType.toString() === 'object' && singleSubSubSubFilterIsDate) {
                                                    sqlFilter += ' \'' + singleSubSubSubFilter.toISOString().split('T')[0] + '\' ';
                                                }
                                                else if (singleSubSubSubFilterType.toString() === 'number') {
                                                    sqlFilter += ' ' + singleSubSubSubFilter.toString() + ' ';
                                                }
                                                else if (singleSubSubSubFilterType.toString() === 'boolean') {
                                                    if (singleSubSubSubFilter.toString() === 'true')
                                                        sqlFilter += '1 ';
                                                    else if (singleSubSubSubFilter.toString() === 'false' || singleSubSubSubFilter === null || singleSubSubSubFilter === undefined)
                                                        sqlFilter += '0 ';
                                                }
                                            }

                                            sqlFilter += ')';
                                        }
                                        else if (singleSubSubFilterType.toString() === 'string') {
                                            if (singleSubSubFilter === 'contains') {
                                                filters[i][i2][i3] = 'LIKE';
                                                singleSubSubFilter = 'LIKE';

                                                filters[i][i2][i3 + 1] = '\'%' + filters[i][i2][i3 + 1] + '%\'';
                                            }
                                            else if (singleSubSubFilter === 'notcontains') {
                                                filters[i][i2][i3] = 'NOT LIKE';
                                                singleSubSubFilter = 'NOT LIKE';

                                                filters[i][i2][i3 + 1] = '\'%' + filters[i][i2][i3 + 1] + '%\'';
                                            }
                                            else if (singleSubSubFilter === 'startswith') {
                                                filters[i][i2][i3] = 'LIKE';
                                                singleSubSubFilter = 'LIKE';

                                                filters[i][i2][i3 + 1] = '\'' + filters[i][i2][i3 + 1] + '%\'';
                                            }
                                            else if (singleSubSubFilter === 'endswith') {
                                                filters[i][i2][i3] = 'LIKE';
                                                singleSubSubFilter = 'LIKE';

                                                filters[i][i2][i3 + 1] = '\'%' + filters[i][i2][i3 + 1] + '\'';
                                            }
                                            else if (singleSubSubFilter === '=' || singleSubSubFilter === '<>' || singleSubSubFilter === '>' || singleSubSubFilter === '>=' || singleSubSubFilter === '<' || singleSubSubFilter === '<=') {
                                                if (!filters[i][i2][i3 + 1])
                                                    filters[i][i2][i3 + 1] = 'NULL';
                                                else if (typeof filters[i][i2][i3 + 1] === 'string') {
                                                    filters[i][i2][i3 + 1] = '\'' + filters[i][i2][i3 + 1] + '\'';
                                                }
                                            }
                                            else if (singleSubSubFilter === '!') {
                                                filters[i][i2][i3] = 'NOT';
                                                singleSubSubFilter = 'NOT';
                                            }
                                            sqlFilter += ' ' + singleSubSubFilter + ' ';
                                        }
                                        else if (singleSubSubFilterType.toString() === 'object' && singleSubSubFilterIsDate) {
                                            sqlFilter += ' \'' + singleSubSubFilter.toISOString().split('T')[0] + '\' ';
                                        }
                                        else if (singleSubSubFilterType.toString() === 'number') {
                                            sqlFilter += ' ' + singleSubSubFilter.toString() + ' ';
                                        }
                                        else if (singleSubSubFilterType.toString() === 'boolean') {
                                            if (singleSubSubFilter.toString() === 'true')
                                                sqlFilter += '1 ';
                                            else if (singleSubSubFilter.toString() === 'false' || singleSubSubFilter === null || singleSubSubFilter === undefined)
                                                sqlFilter += '0 ';
                                        }
                                    }

                                    sqlFilter += ')';
                                }
                                else if (singleSubFilterType.toString() === 'string') {
                                    if (singleSubFilter === 'contains') {
                                        filters[i][i2] = 'LIKE';
                                        singleSubFilter = 'LIKE';

                                        filters[i][i2 + 1] = '\'%' + filters[i][i2 + 1] + '%\'';
                                    }
                                    else if (singleSubFilter === 'notcontains') {
                                        filters[i][i2] = 'NOT LIKE';
                                        singleSubFilter = 'NOT LIKE';

                                        filters[i][i2 + 1] = '\'%' + filters[i][i2 + 1] + '%\'';
                                    }
                                    else if (singleSubFilter === 'startswith') {
                                        filters[i][i2] = 'LIKE';
                                        singleSubFilter = 'LIKE';

                                        filters[i][i2 + 1] = '\'' + filters[i][i2 + 1] + '%\'';
                                    }
                                    else if (singleSubFilter === 'endswith') {
                                        filters[i][i2] = 'LIKE';
                                        singleSubFilter = 'LIKE';

                                        filters[i][i2 + 1] = '\'%' + filters[i][i2 + 1] + '\'';
                                    }
                                    else if (singleSubFilter === '=' || singleSubFilter === '<>' || singleSubFilter === '>' || singleSubFilter === '>=' || singleSubFilter === '<' || singleSubFilter === '<=') {
                                        if (!filters[i][i2 + 1])
                                            filters[i][i2 + 1] = 'NULL';
                                        else if (typeof filters[i][i2 + 1] === 'string') {
                                            filters[i][i2 + 1] = '\'' + filters[i][i2 + 1] + '\'';
                                        }
                                    }
                                    else if (singleSubFilter === '!') {
                                        filters[i][i2] = 'NOT';
                                        singleSubFilter = 'NOT';
                                    }
                                    sqlFilter += ' ' + singleSubFilter + ' ';
                                }
                                else if (singleSubFilterType.toString() === 'object' && singleSubFilterIsDate) {
                                    sqlFilter += ' \'' + singleSubFilter.toISOString().split('T')[0] + '\' ';
                                }
                                else if (singleSubFilterType.toString() === 'number') {
                                    sqlFilter += ' ' + singleSubFilter.toString() + ' ';
                                }
                                else if (singleSubFilterType.toString() === 'boolean') {
                                    if (singleSubFilter.toString() === 'true')
                                        sqlFilter += '1 ';
                                    else if (singleSubFilter.toString() === 'false' || singleSubFilter === null || singleSubFilter === undefined)
                                        sqlFilter += '0 ';
                                }
                            }

                            sqlFilter += ')';
                        }
                        else if (singleFilterType.toString() === 'string') {
                            if (singleFilter === 'contains') {
                                filters[i] = 'LIKE';
                                singleFilter = 'LIKE';

                                filters[i + 1] = '\'%' + filters[i + 1] + '%\'';
                            }
                            else if (singleFilter === 'notcontains') {
                                filters[i] = 'NOT LIKE';
                                singleFilter = 'NOT LIKE';

                                filters[i + 1] = '\'%' + filters[i + 1] + '%\'';
                            }
                            else if (singleFilter === 'startswith') {
                                filters[i] = 'LIKE';
                                singleFilter = 'LIKE';

                                filters[i + 1] = '\'' + filters[i + 1] + '%\'';
                            }
                            else if (singleFilter === 'endswith') {
                                filters[i] = 'LIKE';
                                singleFilter = 'LIKE';

                                filters[i + 1] = '\'%' + filters[i + 1] + '\'';
                            }
                            else if (singleFilter === '=' || singleFilter === '<>' || singleFilter === '>' || singleFilter === '>=' || singleFilter === '<' || singleFilter === '<=') {
                                if (!filters[i + 1])
                                    filters[i + 1] = 'NULL';
                                else if (typeof filters[i + 1] === 'string') {
                                    filters[i + 1] = '\'' + filters[i + 1] + '\'';
                                }
                            }
                            else if (singleFilter === '!') {
                                filters[i] = 'NOT';
                                singleFilter = 'NOT';
                            }
                            sqlFilter += ' ' + singleFilter + ' ';
                        }
                        else if (singleFilterType.toString() === 'object' && singleFilterIsDate) {
                            sqlFilter += ' \'' + singleFilter.toISOString().split('T')[0] + '\' ';
                        }
                        else if (singleFilterType.toString() === 'number') {
                            sqlFilter += ' ' + singleFilter.toString() + ' ';
                        }
                        else if (singleFilterType.toString() === 'boolean') {
                            if (singleFilter.toString() === 'true')
                                sqlFilter += '1 ';
                            else if (singleFilter.toString() === 'false' || singleFilter === null || singleFilter === undefined)
                                sqlFilter += '0 ';
                        }
                    }

                    sqlFilter += ')';
                }
            });

            async function getRecords() {
                if (sqlFilter === '(')
                    sqlFilter = '';

                var records = await getWarehousePositionWMSRecords(sqlFilter);
                return {
                    data: records,
                    totalCount: records.length
                };
            }

            return getRecords()
                .then(function (result) {
                    return result;
                })
                .catch(() => { throw new Error('Data Loading Error'); });
        },
    });

    const getWarehousePositionWMSPayload = React.useCallback(async (Almacen, Posicion) => {
        try {
            let apiName = 'AdminSC';
            let path = '/procesaalmacenposicion';

            let myInit = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
                },
                queryStringParameters: {
                    Almacen: Almacen,
                    Posicion: Posicion,
                    action: 'payload',
                    username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username
                }
            }

            var payloadGet = await API.get(apiName, path, myInit);

            setWarehousePositionWMSPayload(payloadGet);
        }
        catch (e) {
            if (e === 'No current user') {
                history.push('/');

                window.location.reload();
            }
        }

        return payloadGet;
    }, [history]);

    function editWarehousePositionWMS(data) {
        return (
            <Link to={`/WarehousePositionWMS/${data.data.Almacen}/${data.data.Posicion}`}>
                <button class="bg-blue-500 hover:bg-blue-700 text-white  py-1 px-3 rounded" onClick={() => editButton(data.data.Almacen, data.data.Posicion)}><FontAwesomeIcon icon={faPencilAlt} /> Editar</button>
            </Link>
        )
    }

    const editButton = React.useCallback(async (Almacen, Posicion) => {
        try {
            if (!window.event.metaKey && !window.event.ctrlKey) {
                setVisibleLoadPanel(true);

                setIsDetail(true);
                setAlmacen(Almacen);
                setPosicion(Posicion);

                var payload = await getWarehousePositionWMSPayload(Almacen, Posicion);

                setActivateUpdateForm(true);
                setActivateSaveButton(true);
                setActivateDeleteButton(true);
                setActivatePDFButton(true);

                setVisibleLoadPanel(false);
            }
        }
        catch (e) {
            setVisibleLoadPanel(false);
        }
    }, [getWarehousePositionWMSPayload]);

    const newButton = React.useCallback(async () => {
        try {
            let apiName = 'AdminSC';
            let path = '/procesaalmacenposicion';

            let myInit = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
                },
                queryStringParameters: {
                    action: 'new',
                    username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username,
                    usuario: props.usuario,
                    sucursal: props.sucursal
                }
            };

            var payloadNew = await API.get(apiName, path, myInit);

            setWarehousePositionWMSPayload(payloadNew);

            setIsDetail(true);
            setAlmacen(undefined);
            setPosicion(undefined);

            setActivateUpdateForm(true);
            setActivateSaveButton(true);
            setActivateDeleteButton(false);

            setVisibleLoadPanel(false);
        }
        catch (e) {
            if (e === 'No current user') {
                history.push('/');

                window.location.reload();
            }
        }
    }, [history, props.sucursal, props.usuario]);

    async function cancelEditButton() {
        setIsDetail(false);
    };

    const saveButton = React.useCallback(async () => {
        if (formDatosGeneralesRef.current) {
            var formDatosGenerales = formDatosGeneralesRef.current.instance;
            var isValid = formDatosGenerales.validate();

            if (!isValid.isValid) {
                setVisibleToast(true);
                setMessageToast(isValid.brokenRules[0].message);
                setTypeToast('error');
                return;
            }
        }

        try {
            setVisibleLoadPanel(true);

            var Almacen = warehousePositionWMSPayload.header.Almacen;
            var Posicion = warehousePositionWMSPayload.header.Posicion;

            //Despues enviamos todo al backend
            let apiName = 'AdminSC';
            let path = '/procesaalmacenposicion';

            let myInit = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
                },
                body: {
                    Almacen: Almacen,
                    Posicion: Posicion,
                    payload: warehousePositionWMSPayload,
                    action: 'saveChanges',
                    username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username,
                    usuario: props.usuario,
                    sucursal: props.sucursal,
                    s3Bucket: Storage._config.AWSS3.bucket
                }
            }

            var payloadPost = await API.post(apiName, path, myInit);

            if (payloadPost.ok === 'true') {
                setVisibleToast(true);
                setMessageToast('Se actualizó la Posición con éxito');
                setTypeToast('success')
            }
            else if (payloadPost.ok === 'false') {
                setVisibleToast(true);
                setMessageToast(payloadPost.message);
                setTypeToast('error')
            }

            editButton(Almacen, Posicion);

            setVisibleLoadPanel(false);
        }
        catch (e) {
            setVisibleLoadPanel(false);

            if (e === 'No current user') {
                history.push('/');

                window.location.reload();
            }
        }
    }, [editButton, formDatosGeneralesRef, history, props.sucursal, props.usuario, warehousePositionWMSPayload]);

    function refreshButton() {
        if (gridTableroRef.current) {
            var gridTablero = gridTableroRef.current.instance;

            gridTablero.refresh();
        }
    };

    const deleteButton = React.useCallback(async () => {
        try {
            if (!window.confirm('¿Desea eliminar la Posición?'))
                return;

            setVisibleLoadPanel(true);

            var Almacen = warehousePositionWMSPayload.header.Almacen;
            var Posicion = warehousePositionWMSPayload.header.Posicion;

            if (!Almacen || !Posicion) {
                //Vamos al tablero de control
                history.push('/WarehousePositionWMS');
                return;
            }

            let apiName = 'AdminSC';
            let path = '/procesaalmacenposicion';

            let myInit = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
                },
                body: {
                    Almacen: Almacen,
                    Posicion: Posicion,
                    payload: warehousePositionWMSPayload,
                    action: 'delete',
                    username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username,
                    usuario: props.usuario,
                    sucursal: props.sucursal
                }
            }

            var payloadPost = await API.post(apiName, path, myInit);

            if (payloadPost.ok === 'true') {
                setVisibleLoadPanel(false);

                setVisibleToast(true);
                setMessageToast('Posición eliminada con éxito');
                setTypeToast('success')

                setIsDetail(false);

                //Vamos al tablero de control
                history.push('/WarehousePositionWMS');
            }
            else if (payloadPost.ok === 'false') {
                setVisibleLoadPanel(false);

                setVisibleToast(true);
                setMessageToast(payloadPost.message);
                setTypeToast('error')
            }
        }
        catch (e) {
            setVisibleLoadPanel(false);

            if (e === 'No current user') {
                history.push('/');

                window.location.reload();
            }
        }
    }, [history, props.sucursal, props.usuario, warehousePositionWMSPayload]);

    function printButton() {
        var baseUrl = window.location.href.split('/')[0];

        window.open(baseUrl + "/#/PdfRender?type=warehousepositionwms&id=" + almacen + '__@@__' + posicion, "_blank");
    };

    useEffect(() => {
        window.addEventListener('popstate', (e) => {
            var hash;

            try {
                hash = e.target.location.hash;
            }
            catch (e) {
                hash = '';
            }

            if (hash === '#/WarehousePositionWMS') {
                history.push('/WarehousePositionWMS');

                window.location.reload();
            }
        });

        async function initialize() {
            setVisibleLoadPanel(true);

            await getAlmacenes();

            setVisibleLoadPanel(false);
        }

        initialize();

        //En caso de solicitar un ID especifico, redireccionamos a ese ID
        if (props.location.pathname.replace('/WarehousePositionWMS/', '').replace('/WarehousePositionWMS', '') !== '') {
            var urlID = props.location.pathname.replace('/WarehousePositionWMS/', '').replace('/WarehousePositionWMS', '').split('/');

            if (urlID.length === 2 && urlID[0] !== undefined && urlID[0] !== 'undefined' && urlID[1] !== undefined && urlID[1] !== 'undefined' && urlID[0] !== '' && urlID[1] !== '') {
                setAlmacen(urlID[0]);

                setPosicion(urlID[1]);

                editButton(urlID[0], urlID[1]);
            }
        }

    }, [props.location.pathname, props.match.isExact, editButton, history]);

    function onHidingToast() {
        setVisibleToast(false);
    }

    function onExporting(e) {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Datos');

        exportDataGrid({
            component: e.component,
            worksheet,
            autoFilterEnabled: true,
        }).then(() => {
            workbook.xlsx.writeBuffer().then((buffer) => {
                saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Datos.xlsx');
            });
        });
        e.cancel = true;
    };

    var almacenDataSource = useMemo(() => {
        return new DataSource({
            paginate: true,
            store: {
                type: "array",
                key: "Almacen",
                data: almacenes
            }
        });
    }, [almacenes]);

    var posicionEditorOptions = useMemo(() => {
        return {
            width: '100%',
            readOnly: false,
            inputAttr: { 'style': 'text-transform: uppercase' }
        };
    }, []);

    //Opciones Nivel de Permiso
    const estatusOptions = useMemo(() => {
        return [
            { value: 'ALTA', name: 'ALTA' },
            { value: 'BAJA', name: 'BAJA' }
        ];
    }, []);

    try {
        var estatusEditorOptions = useMemo(() => {
            return {
                items: estatusOptions,
                displayExpr: 'name',
                valueExpr: 'value',
                searchEnabled: false,
                value: (warehousePositionWMSPayload.header.Estatus ? warehousePositionWMSPayload.header.Estatus : '')
            };
        }, [estatusOptions, warehousePositionWMSPayload.header.Estatus]);
    }
    catch (e) {
        var estatusEditorOptions = {
            items: estatusOptions,
            displayExpr: 'name',
            valueExpr: 'value',
            searchEnabled: false,
            value: ''
        };
    }

    const storeArticulo = new CustomStore({
        key: 'value',
        load(loadOptions) {
            let sqlFilter = '';
            [
                'filter'
            ].forEach((i) => {
                sqlFilter = loadOptions.searchValue;
            });

            async function getRecords() {
                if (sqlFilter) {
                    var records = await getArticulo(sqlFilter);

                    return {
                        data: records,
                        totalCount: records.length
                    };
                }
                else {
                    return {
                        data: [],
                        totalCount: 0
                    };
                }
            }

            return getRecords()
                .then(function (result) {
                    return result;
                })
                .catch(() => { throw new Error('Data Loading Error'); });
        },
        byKey: (key) => {
            async function getRecords(key) {
                var records = await getArticulo(key);

                return records;
            }

            return getRecords(key);
        }
    });

    //Opciones del campo Articulo
    try {
        var articuloEditorOptions = {
            dataSource: storeArticulo,
            displayExpr: 'name',
            valueExpr: 'value',
            searchEnabled: true,
            acceptCustomValue: false,
            disabled: false,
            searchTimeout: 1500,
            width: '100%',
            clearButtonText: 'Cerrar',
            noDataText: 'No se encontraron resultados',
            showClearButton: true,
            value: (warehousePositionWMSPayload.header.ArticuloEsp ? warehousePositionWMSPayload.header.ArticuloEsp : '')
        };
    }
    catch (e) {
        var articuloEditorOptions = {
            dataSource: storeArticulo,
            displayExpr: 'name',
            valueExpr: 'value',
            searchEnabled: true,
            acceptCustomValue: false,
            showClearButton: true,
            width: '100%'
        };
    }

    var posicionReciboEditorOptions = useMemo(() => {
        return {
            width: '100%',
            readOnly: false
        };
    }, []);

    var posicionTransitoEditorOptions = useMemo(() => {
        return {
            width: '100%',
            readOnly: false
        };
    }, []);

    var invDisponibleEditorOptions = useMemo(() => {
        return {
            width: '100%',
            readOnly: false
        };
    }, []);

    var pasilloEditorOptions = useMemo(() => {
        return {
            width: '100%',
            readOnly: false
        };
    }, []);

    var nivelEditorOptions = useMemo(() => {
        return {
            width: '100%',
            readOnly: false
        };
    }, []);

    var zonaEditorOptions = useMemo(() => {
        return {
            width: '100%',
            readOnly: false
        };
    }, []);

    //Opciones del campo Almacen
    try {
        var almacenEditorOptions = useMemo(() => {
            return {
                dataSource: almacenDataSource,
                displayExpr: 'name',
                valueExpr: 'value',
                searchEnabled: true,
                value: (warehousePositionWMSPayload.header.Almacen ? warehousePositionWMSPayload.header.Almacen : ''),
                width: '100%'
            };
        }, [almacenDataSource, warehousePositionWMSPayload.header.Almacen]);
    }
    catch (e) {
        var almacenEditorOptions = {
            dataSource: almacenDataSource,
            displayExpr: 'name',
            valueExpr: 'value',
            searchEnabled: true,
            value: '',
            width: '100%'
        };
    }

    return (
        <div>
            {isDetail === false ? (
                <div class="bg-gray-200 m-2">
                    <h1 class="font-sans inline-block text-xl font-bold text-gray-700 leading-8 align-top mb-1"><FontAwesomeIcon icon={faList} size="lg" /> Posiciones Almacén WMS</h1>
                </div>
            ) :
                (
                    <div class="bg-gray-200 m-2">
                        <h1 class="font-sans inline-block text-xl font-bold text-gray-700 leading-8 align-top mb-1"><FontAwesomeIcon icon={faShelves} size="xl" /> {(warehousePositionWMSPayload.header ? warehousePositionWMSPayload.header.Almacen + ' - ' + warehousePositionWMSPayload.header.Posicion : 'Posición')}</h1>
                    </div>
                )}

            <LoadPanel
                shadingColor="rgba(0,0,0,0.4)"
                visible={visibleLoadPanel}
                showIndicator={true}
                message="Por favor espere..."
                shading={true}
                showPane={true}
                hideOnOutsideClick={false}
            />

            <Toast
                visible={visibleToast}
                message={messageToast}
                type={typeToast}
                onHiding={onHidingToast}
                displayTime={5000}
                width={'auto'}
                position={{
                    my: 'top center',
                    at: 'top center',
                    of: window,
                    offset: '0 130'
                }}
            />

            <div class="bg-gray-200 m-2">
                {isDetail === false ? (
                    <div class="bg-white p-3 shadow-xl">
                        <div>
                            <Link to="/WarehousePositionWMS/undefined/undefined">
                                <button class="mb-2 bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded" onClick={() => newButton()}><FontAwesomeIcon icon={faFileAlt} /> Nueva Posición</button>
                            </Link>

                            <button onClick={refreshButton} type="submit" class="ml-4 mb-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                                <FontAwesomeIcon icon={faArrowsRotate} /> Actualizar
                            </button>
                        </div>

                        <div className="options mb-2">
                            <div className="option">
                                <CheckBox text="Expandir todos los grupos"
                                    value={autoExpandAll}
                                    onValueChanged={onAutoExpandAllChanged}></CheckBox>
                            </div>
                        </div>
                        <DataGrid
                            height={800}
                            id="gridContainer"
                            columnsAutoWidth={true}
                            filterBuilder={filterBuilder}
                            dataSource={store}
                            defaultFilterValue={filterValue}
                            allowColumnReordering={true}
                            allowColumnResizing={true}
                            columnResizingMode={"widget"}
                            showBorders={true}
                            rowAlternationEnabled={true}
                            showRowLines={true}
                            showColumnLines={true}
                            remoteOperations={{ filtering: true }}
                            ref={gridTableroRef}
                            onExporting={onExporting}
                        >
                            <Scrolling rowRenderingMode='virtual'></Scrolling>
                            <Paging defaultPageSize={10} />
                            <Pager
                                visible={true}
                                allowedPageSizes={allowedPageSizes}
                                displayMode='compact'
                                showPageSizeSelector={true}
                                showInfo={true}
                                showNavigationButtons={true}
                            />
                            <FilterRow visible={true} />
                            <FilterPanel visible={true} />
                            <FilterBuilderPopup position={filterBuilderPopupPosition} />

                            <HeaderFilter visible={true} />

                            <GroupPanel visible={true} />
                            <SearchPanel visible={true} />
                            <ColumnChooser
                                enabled={true}
                                mode='select'
                            >
                                <ColumnChooserSearch
                                    enabled={true}
                                />

                                <ColumnChooserSelection
                                    allowSelectAll={true}
                                    selectByClick={true}
                                    recursive={true}
                                />
                            </ColumnChooser>
                            <Grouping autoExpandAll={autoExpandAll} />
                            <StateStoring enabled={true} type="localStorage" storageKey="gridContainerWarehousePositionWMS" />
                            <Export enabled={true} />

                            <Column caption="" alignment="center" width={100} cellRender={editWarehousePositionWMS} />

                            <Column dataField="Almacen" dataType="string" width={110} caption="Almacén" />
                            <Column dataField="Posicion" dataType="string" width={110} caption="Posición" />

                            <Column dataField="NombreAlmacen" dataType="string" width={300} caption="Nombre Almacén" visible={false} />
                            <Column dataField="NombreSucursal" dataType="string" width={300} caption="Nombre Sucursal" visible={false} />
                            <Column dataField="Sucursal" dataType="number" width={120} caption="Sucursal" visible={false} />
                            <Column caption="Plaza" dataField="Plaza" dataType="string" width={150} visible={false} />

                            <Column dataField="ArticuloEsp" dataType="string" width={200} caption="Artículo Específico" />
                            <Column dataField="NombreArticulo" dataType="string" width={300} caption="Nombre Artículo" visible={false} />
                            <Column dataField="Descripcion" dataType="string" width={110} caption="Descripción" visible={false} />
                            <Column dataField="Estatus" dataType="string" width={110} caption="Estatus" />
                            <Column dataField="Nivel" dataType="string" width={110} caption="Nivel" />
                            <Column dataField="Pasillo" dataType="string" width={110} caption="Pasillo" />
                            <Column dataField="PosicionRecibo" dataType="boolean" width={110} caption="Posición Recibo" visible={false} />
                            <Column dataField="PosicionTransito" dataType="boolean" width={110} caption="Posición Tránsito" visible={false} />
                            <Column dataField="Zona" dataType="string" width={110} caption="Zona" />
                            <Column dataField="InvDisponible" dataType="boolean" width={110} caption="Inventario Disponible" />
                        </DataGrid>
                    </div>
                ) : (<div />)}
                <Route exact path="/WarehousePositionWMS/:Almacen/:Posicion" render={({ match }) => {
                    return (
                        <div class="bg-gray-200">
                            {isDetail.toString() === 'true' ?
                                (
                                    <div>
                                        <Link to="/WarehousePositionWMS">
                                            <button onClick={cancelEditButton} type="submit" class="mb-4 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
                                                <FontAwesomeIcon icon={faArrowRotateLeft} /> Regresar
                                            </button>
                                        </Link>

                                        {activateSaveButton.toString() === 'true' ?
                                            (
                                                <button onClick={saveButton} type="submit" class="ml-4 mb-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                                                    <FontAwesomeIcon icon={faSave} /> Guardar Cambios
                                                </button>
                                            ) : (
                                                <div class="inline-block"></div>
                                            )}

                                        {activateDeleteButton.toString() === 'true' && almacen && posicion ?
                                            (
                                                <button onClick={deleteButton} type="submit" class="ml-4 mb-4 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
                                                    <FontAwesomeIcon icon={faTrash} /> Eliminar
                                                </button>
                                            ) :
                                            (
                                                <div class="inline-block"></div>
                                            )}

                                        {activatePDFButton.toString() === 'true' && almacen && posicion ?
                                            (
                                                <button onClick={printButton} type="submit" class="ml-4 mb-4 bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded">
                                                    <FontAwesomeIcon icon={faNoteSticky} /> Imprimir Etiqueta
                                                </button>
                                            ) :
                                            (
                                                <div class="inline-block"></div>
                                            )}

                                        <div class="bg-white p-4 shadow-xl">
                                            <Form
                                                id="formTransaccion"
                                                labelMode={'outside'}
                                                formData={warehousePositionWMSPayload}
                                                readOnly={!activateUpdateForm}
                                                showColonAfterLabel={false}
                                                labelLocation={'top'}
                                                ref={formDatosGeneralesRef}
                                            >
                                                <TabbedItem>
                                                    <TabPanelOptions deferRendering={false} showNavButtons={true} />
                                                    <Tab title="Datos Generales" colCount={2}>
                                                        <GroupItem caption="General">
                                                            <Item cssClass="boldText" editorType="dxSelectBox" dataField="header.Almacen" editorOptions={almacenEditorOptions} >
                                                                <Label text={"Almacén"} />
                                                                <RequiredRule message="Almacén es requerido" />
                                                            </Item>
                                                            <Item cssClass="boldText" dataField="header.Posicion" editorOptions={posicionEditorOptions} >
                                                                <Label text={"Posición"} />
                                                                <RequiredRule message="Posición es requerida" />
                                                                <StringLengthRule message="Debe contener máximo 25 caracteres" min={1} max={25} />
                                                            </Item>
                                                            <Item dataField="header.Estatus" editorType="dxSelectBox" editorOptions={estatusEditorOptions} >
                                                                <Label text={"Estatus"} />
                                                                <RequiredRule message="Estatus es requerido" />
                                                            </Item>
                                                            <Item dataField="header.ArticuloEsp" editorType="dxLookup" editorOptions={articuloEditorOptions} >
                                                                <Label text={"Artículo Específico"} />
                                                            </Item>
                                                            <Item dataField="header.PosicionRecibo" editorType="dxCheckBox" editorOptions={posicionReciboEditorOptions} >
                                                                <Label text={"Posición Recibo"} />
                                                            </Item>
                                                            <Item dataField="header.PosicionTransito" editorType="dxCheckBox" editorOptions={posicionTransitoEditorOptions} >
                                                                <Label text={"Posición Tránsito"} />
                                                            </Item>
                                                            <Item dataField="header.InvDisponible" editorType="dxCheckBox" editorOptions={invDisponibleEditorOptions} >
                                                                <Label text={"Inventario Disponible"} />
                                                            </Item>
                                                        </GroupItem>
                                                        <GroupItem caption="Ubicación">
                                                            <Item dataField="header.Pasillo" editorOptions={pasilloEditorOptions} >
                                                                <Label text="Pasillo" />
                                                            </Item>
                                                            <Item dataField="header.Nivel" editorOptions={nivelEditorOptions} >
                                                                <Label text="Nivel" />
                                                            </Item>
                                                            <Item dataField="header.Zona" editorOptions={zonaEditorOptions} >
                                                                <Label text="Zona" />
                                                            </Item>
                                                        </GroupItem>
                                                    </Tab>
                                                    <Tab title="Descripción" >
                                                        <Item dataField="header.Descripcion" editorType="dxTextArea" >
                                                            <Label text="Descripción" visible={false} />
                                                        </Item>
                                                    </Tab>
                                                </TabbedItem>
                                            </Form>
                                        </div>
                                    </div>
                                ) :
                                (
                                    <div></div>
                                )
                            }
                        </div>
                    )
                }}
                />
            </div>
        </div>
    );
}
