// Ruteador
import { Route, Link, useHistory } from "react-router-dom";

import React, { useState, useEffect, useRef, useCallback } from 'react';

// Toast
import { Toast } from 'devextreme-react/toast';

// maplibre
import { createMap } from "maplibre-gl-js-amplify";
import maplibregl from "maplibre-gl";
import "maplibre-gl/dist/maplibre-gl.css";
import "maplibre-gl-js-amplify/dist/public/amplify-map.css";
import * as turf from "@turf/turf";

// iconos
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/pro-solid-svg-icons";
import { faMapMarkerAlt } from "@fortawesome/pro-duotone-svg-icons";
import { faArrowsRotate } from "@fortawesome/pro-solid-svg-icons";

import './Attendance.css';

import { Auth, API } from 'aws-amplify';

// Grid
import DataGrid, {
  Column, FilterRow, HeaderFilter, FilterPanel, FilterBuilderPopup,
  GroupPanel, Grouping, SearchPanel, Sorting, Editing, Lookup, Toolbar, Item, RequiredRule,
  Scrolling, Pager, Paging, StateStoring, ColumnChooser, Format, Selection,
  Export, ColumnChooserSearch, ColumnChooserSelection, Search
} from 'devextreme-react/data-grid';

//CheckBox
import CheckBox from 'devextreme-react/check-box';

//Form
import Form, { GroupItem, Label, TabbedItem, TabPanelOptions, Tab } from 'devextreme-react/form';

import '../ListStyle.css';

import dxDateBox from "devextreme/ui/date_box";

import dxNumberBox from "devextreme/ui/number_box";

import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
import { exportDataGrid } from 'devextreme/excel_exporter'

dxDateBox.defaultOptions({
  options: {
    dateSerializationFormat: "yyyy-MM-dd"
  }
});

dxNumberBox.defaultOptions({
  options: {
    step: 0
  }
});

export default function Attendance(props) {
  const history = useHistory();

  var mapRef = useRef(null); // Reference to the map DOM element

  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');
  const [activateButton, setActivateButton] = useState(false);
  const [assistanceRecords, setAssistanceRecords] = useState([]);
  const [currentLocation, setCurrentLocation] = useState({});

  // setValue para el control tab
  const [value, setValue] = React.useState(0);

  // setValue para el control grid
  const [grouping, setGrouping] = useState([]);

  const [visibleToast, setVisibleToast] = useState(false);
  const [messageToast, setMessageToast] = useState('');
  const [typeToast, setTypeToast] = useState('success');
  const [autoExpandAll, setAutoExpandAll] = useState(true);

  const allowedPageSizes = [5, 10, 15, 20, 'all'];

  const exportFormats = ['xlsx'];

  function getOrderDay(rowData) {
    return (new Date(rowData.OrderDate)).getDay();
  }

  function onAutoExpandAllChanged() {
    setAutoExpandAll(!autoExpandAll)
  };

  const filterBuilderPopupPosition = {
    of: window,
    at: 'top',
    my: 'top',
    offset: { y: 10 },
  };

  const filterBuilder = {
    customOperations: [{
      name: 'weekends',
      caption: 'Weekends',
      dataTypes: ['date'],
      icon: 'check',
      hasValue: false,
      calculateFilterExpression: () => [[getOrderDay, '=', 0], 'or', [getOrderDay, '=', 6]],
    }],
    allowHierarchicalFields: true,
  };

  //Ref para el grid Tablero de Control
  const [gridTableroRef, setGridTableroRef] = useState(React.createRef());

  const handleChange = (event, newValue) => {
    setValue(newValue);

    if (newValue === 0)
      window.location.reload();
  };

  async function getUserLocations() {
    try {
      var personal = props.personal;

      let apiName = 'AdminSC';
      let path = '/ubicaciones';

      let myInit = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
        },
        body: {
          personal: personal,
          username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username
        }
      }

      var userLocationsGet = await API.post(apiName, path, myInit);
    }
    catch (e) {
      if (e === 'No current user') {
        history.push('/');

        window.location.reload();
      }
    }

    return userLocationsGet;
  }

  async function getUserAssistanceRecords() {
    try {
      var personal = props.personal;
      var attendanceAccess = props.attendanceAccess;

      let apiName = 'AdminSC';
      let path = '/procesaasistencia';

      let myInit = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
        },
        queryStringParameters: {
          personal: personal,
          attendanceAccess: attendanceAccess,
          username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username
        }
      }
      var userAssistanceGet = await API.get(apiName, path, myInit);
    }
    catch (e) {
      if (e === 'No current user') {
        history.push('/');

        window.location.reload();
      }
    }
    return userAssistanceGet;
  }

  function refreshButton() {
    if (gridTableroRef.current) {
      var gridTablero = gridTableroRef.current.instance;

      gridTablero.refresh();
    }
  }

  // Wrapping our code in a useEffect allows us to run initializeMap after the div has been rendered into the DOM
  useEffect(() => {
    let map;
    var keys;

    async function initializeMap() {
      //Obtenemos las ubicaciones permitidas del empleado
      var userLocations = await getUserLocations(props.personal);

      //Obtenemos su record de asistencia
      var getAssistanceRecords = await getUserAssistanceRecords(props.personal);

      setAssistanceRecords(getAssistanceRecords);

      //Obtenemos las ubicaciones aceptadas del usuario
      var locations = {};
      var circles = {};

      for (var userLocationIndex in userLocations) {
        var singleUserLocation = userLocations[userLocationIndex];

        // Definimos el circulo
        var center = [singleUserLocation.Longitud, singleUserLocation.Latitud];

        var radius = .1;
        var options = {
          steps: 360,
          units: "kilometers",
        };

        var circle = turf.circle(center, radius, options);

        var validLocation = {
          coordinates: circle.geometry.coordinates
        };

        locations[singleUserLocation.Nombre] = validLocation;
        locations[singleUserLocation.Nombre].Latitud = singleUserLocation.Latitud;
        locations[singleUserLocation.Nombre].Longitud = singleUserLocation.Longitud;

        circles[singleUserLocation.Nombre] = circle;
      }

      // We only want to initialize the underlying maplibre map after the div has been rendered
      if (mapRef.current != null) {
        // Creamos el mapa
        map = await createMap({
          container: mapRef.current,
          center: [-101.95812036696248, 23.732791552256273],
          zoom: 4,
        });

        // Definimos el GeolocateControl para poder obtener la posición del usuario
        var geolocate = new maplibregl.GeolocateControl({
          positionOptions: {
            enableHighAccuracy: true
          },
          trackUserLocation: true
        });

        // Agregamos el control al mapa
        map.addControl(geolocate);

        // Evento al presionar el boton de ubicación del usuario
        geolocate.on('geolocate', function (data) {
          try {
            keys = Object.keys(locations);
          }
          catch (e) {
            keys = [];
          }

          var valid = false;

          for (var keyIndex in keys) {
            if (valid === false) {
              var singleKey = keys[keyIndex];

              var location = locations[singleKey];
              location.name = singleKey;

              var centerLatitud = location.Latitud;
              var centerLongitud = location.Longitud;

              var centerLatLong = new maplibregl.LngLat(centerLongitud, centerLatitud);
              var userLocationLatLong = new maplibregl.LngLat(data.coords.longitude, data.coords.latitude);

              var distance = centerLatLong.distanceTo(userLocationLatLong) / 1000.0;

              if (distance <= 0.1) {
                valid = true;

                setCurrentLocation(location);
                setLatitude(data.coords.latitude);
                setLongitude(data.coords.longitude);

                break;
              }
            }
          }

          setActivateButton(valid);
        });

        // Evento al hacer click sobre el mapa
        // map.on('click', function(e) {
        //   console.log('A click event has occurred at ', e);
        // });

        map.on('load', function () {
          try {
            keys = Object.keys(locations);
          }
          catch (e) {
            keys = [];
          }

          for (var keyIndex in keys) {
            var singleKey = keys[keyIndex];

            var circle = circles[singleKey];

            var layer = map.getLayer(singleKey);

            if (layer)
              map.removeLayer(singleKey);

            var source = map.getSource(singleKey);

            if (source)
              map.removeSource(singleKey);

            map.addSource(singleKey, {
              type: "geojson",
              data: circle
            });

            //Agregamos el Layer al mapa para mostrarlo
            map.addLayer({
              id: singleKey,
              type: "fill",
              source: singleKey,
              paint: {
                "fill-color": "blue",
                "fill-opacity": 0.2
              }
            });
          }

          //Ejecutamos el Geolocate Control para poder centrar el mapa a la posición del usuario
          geolocate.trigger();
        });

        map.addControl(new maplibregl.NavigationControl(), "top-right");

        try {
          keys = Object.keys(locations);
        }
        catch (e) {
          keys = [];
        }

        for (var keyIndex in keys) {
          var singleKey = keys[keyIndex];

          var location = locations[singleKey];

          new maplibregl.Marker({
          })
            .setLngLat([location.Longitud, location.Latitud])
            .addTo(map);
        }
      }
    }
    initializeMap();

    // Cleans up and maplibre DOM elements and other resources - https://maplibre.org/maplibre-gl-js-docs/api/map/#map#remove
    return function cleanup() {
      if (map != null) map.remove();
    };
    // eslint-disable-next-line
  }, []);

  async function postAssitance() {
    var personal = props.personal;

    let apiName = 'AdminSC';
    let path = '/procesaasistencia';

    let myInit = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
      },
      body: {
        personal: personal,
        latitud: latitude,
        longitud: longitude,
        ubicacion: currentLocation.name,
        username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username
      }
    }
    var userAssistancePost = await API.post(apiName, path, myInit);

    if (userAssistancePost.ok === 'true') {
      setVisibleToast(true);
      setMessageToast('Asistencia registrada');
      setTypeToast('success')

      //Obtenemos su record de asistencia
      var getAssistanceRecords = await getUserAssistanceRecords(props.personal);

      setAssistanceRecords(getAssistanceRecords);
    }
    else {
      setVisibleToast(true);
      setMessageToast(userAssistancePost.message);
      setTypeToast('error')
    }
  }

  function onHidingToast() {
    setVisibleToast(false);
  }

  function onExporting(e) {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Datos');

    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Datos.xlsx');
      });
    });
    e.cancel = true;
  };

  return (
    <div>
      <div class="bg-gray-200 m-2">
        <h1 class="font-sans inline-block text-xl font-bold text-gray-700 leading-8 align-top mb-1"><FontAwesomeIcon icon={faMapMarkerAlt} size="xl" /> Asistencia</h1>
      </div>
      <div class="h-72 ml-2" ref={mapRef} id="map" />
      <div class="ml-2 mr-2 p-3 mt-40 bg-white shadow-xl">
        <Toast
          visible={visibleToast}
          message={messageToast}
          type={typeToast}
          onHiding={onHidingToast}
          displayTime={5000}
          width={'auto'}
          position={{
            my: 'top center',
            at: 'top center',
            of: window,
            offset: '0 130'
          }}
        />
        <div>
          {activateButton === true ? (
            <button onClick={postAssitance} type="submit" class="bg-green-500 mb-4 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">
              <FontAwesomeIcon icon={faClock} /> Checar Asistencia
            </button>
          ) :
            <button disabled onClick={postAssitance} type="submit" class="cursor-not-allowed mb-4 disabled:opacity-75 bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded">
              <FontAwesomeIcon icon={faClock} /> Checar Asistencia
            </button>
          }

          <button onClick={refreshButton} type="submit" class="ml-4 mb-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
            <FontAwesomeIcon icon={faArrowsRotate} /> Actualizar
          </button>
        </div>
        <div class="bg-white">
          <div className="options mb-2">
            <div className="option">
              <CheckBox text="Expandir todos los grupos"
                value={autoExpandAll}
                onValueChanged={onAutoExpandAllChanged}></CheckBox>
            </div>
          </div>
          <DataGrid
            height={800}
            id="gridContainer"
            columnsAutoWidth={true}
            filterBuilder={filterBuilder}
            dataSource={assistanceRecords}
            allowColumnReordering={true}
            allowColumnResizing={true}
            columnResizingMode={"widget"}
            showBorders={true}
            rowAlternationEnabled={true}
            showRowLines={true}
            showColumnLines={true}
            ref={gridTableroRef}
            onExporting={onExporting}
          >
            <Scrolling rowRenderingMode='virtual'></Scrolling>
            <Paging defaultPageSize={10} />
            <Pager
              visible={true}
              allowedPageSizes={allowedPageSizes}
              displayMode='compact'
              showPageSizeSelector={true}
              showInfo={true}
              showNavigationButtons={true}
            />
            <FilterRow visible={true} />
            <FilterPanel visible={true} />
            <FilterBuilderPopup position={filterBuilderPopupPosition} />

            <HeaderFilter visible={true} />

            <GroupPanel visible={true} />
            <SearchPanel visible={true} />
            <ColumnChooser
              enabled={true}
              mode='select'
            >
              <ColumnChooserSearch
                enabled={true}
              />

              <ColumnChooserSelection
                allowSelectAll={true}
                selectByClick={true}
                recursive={true}
              />
            </ColumnChooser>
            <Grouping autoExpandAll={autoExpandAll} />
            <StateStoring enabled={true} type="localStorage" storageKey="gridAttendanceRecords" />
            <Export enabled={true} />

            <Column dataField="plaza" caption="Plaza" width={190} />
            <Column dataField="sucursal" caption="Sucursal" width={190} />
            <Column dataField="nombre" caption="Empleado" width={190} />
            <Column dataField="anio" caption="Año" width={190} groupIndex={0} />
            <Column dataField="mes" caption="Mes" width={190} groupIndex={1} />
            <Column dataField="fecha" caption="Fecha" dataType="date" width={190} />
            <Column dataField="registro" caption="Registro" width={190} />
            <Column dataField="horaregistro" caption="Hora de Registro" width={190} />
            <Column dataField="ubicacion" caption="Ubicacion" width={190} />
            <Column dataField="estatus" caption="Estatus" width={190} />
            <Column dataField="minutosRetardo" caption="Minutos Retardo" width={190} />
          </DataGrid>
        </div>
      </div>
    </div>
  );
}