import React, { useState, useEffect, useRef } from 'react';

export default function PdfEditor(props) {
  const [className, setClassName] = useState('');
  const [loginInfo, setLoginInfo] = useState('');

  useEffect(() => {
    var loginInfotmp = '';

    if (props.isadmin === 'true') {
      setClassName('inline-block text-xs ml-3 mt-2 mb-2 mr-5 px-2 py-1 font-semibold leading-tight text-blue-600 bg-blue-300 rounded-lg');

      loginInfotmp = 'Admin; ';
    }
    else
      setClassName('inline-block text-xs ml-3 mt-2 mb-2 mr-5 px-2 py-1 font-semibold leading-tight text-gray-600 bg-gray-300 rounded-lg');

    loginInfotmp += 'Usuario: ' + props.username.trim();

    if (props.usuario) {
      loginInfotmp = loginInfotmp + ' (' + props.usuario + ')';
    }

    if (props.plaza && props.plaza !== '|') {
      loginInfotmp = loginInfotmp + '; Plaza: ' + props.plaza.trim();
    }
    else {
      if (props.nombreSucursal && props.nombreSucursal !== '')
        loginInfotmp = loginInfotmp + '; Suc: ' + props.nombreSucursal.toString().trim() + ' (' + props.sucursal.toString() + ')';
    }

    setLoginInfo(loginInfotmp);
  }, [props, className]);

  return (
    <span className={className} >{loginInfo}</span>
  );
};