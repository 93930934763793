import React, { useState, useEffect, useRef, useMemo } from 'react';

// Ruteador
import { Route, Link, useHistory } from "react-router-dom";

// LoadPanel
import { LoadPanel } from 'devextreme-react/load-panel';

//Form
import Form, { GroupItem, Label, TabbedItem, TabPanelOptions, Tab, EmptyItem, SimpleItem } from 'devextreme-react/form';

import DropDownBox from 'devextreme-react/drop-down-box';

import { Auth, API, Storage } from 'aws-amplify';

// Grid
import DataGrid, {
    Column, FilterRow, HeaderFilter, FilterPanel, FilterBuilderPopup,
    GroupPanel, Grouping, SearchPanel, Sorting, Editing, Lookup, Toolbar, Item, RequiredRule,
    Scrolling, Pager, Paging, Summary, TotalItem, StateStoring, ColumnChooser, Format, Selection,
    Export, ColumnChooserSearch, ColumnChooserSelection
} from 'devextreme-react/data-grid';

// Iconos
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsRotate } from "@fortawesome/pro-solid-svg-icons";
import { faWarehouse } from "@fortawesome/pro-solid-svg-icons";
import { faBoxCheck } from "@fortawesome/pro-solid-svg-icons";
import { faFileExcel } from "@fortawesome/pro-solid-svg-icons";
import { faFileChartColumn } from "@fortawesome/pro-duotone-svg-icons";

import dxDateBox from "devextreme/ui/date_box";

import dxNumberBox from "devextreme/ui/number_box";

import Lambda from 'aws-sdk/clients/lambda';

import '../ListStyle.css';

import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
import { exportDataGrid } from 'devextreme/excel_exporter'

dxDateBox.defaultOptions({
    options: {
        dateSerializationFormat: "yyyy-MM-dd"
    }
});

dxNumberBox.defaultOptions({
    options: {
        step: 0
    }
});

function isNotEmpty(value) {
    return value !== undefined && value !== null && value !== '';
}

export default function POSuggestionsReport(props) {
    const history = useHistory();

    const [visibleLoadPanel, setVisibleLoadPanel] = useState(false);
    const [poSuggestionsReportCfg, setPOSuggestionsReportCfg] = useState(props.poSuggestionsReportCfg);

    const [ejercicios, setEjercicios] = useState([]);
    const [periodos, setPeriodos] = useState([]);
    const [proveedores, setProveedores] = useState([]);

    const [proveedoresSeleccionados, setProveedoresSeleccionados] = useState([]);

    const dateFormatYYYYDDMM = React.useCallback(() => {
        var date = new Date();

        var year = date.getFullYear().toString();
        var month = date.getMonth() + 1;
        var day = date.getDate().toString();

        if (month < 10)
            month = '0' + month.toString();
        else
            month = month.toString();

        return (
            year + "-" + month + "-" + day
        );
    }, []);

    const [reportPayload, setReportPayload] = useState({
        header: {
            FechaD: dateFormatYYYYDDMM(),
            FechaA: dateFormatYYYYDDMM(),
            Proveedor: ''
        },
        titles: {},
        detail: {}
    });

    //Ref para el form
    const [formReporteRef, setFormReporteRef] = useState(React.createRef());

    //Ref para el grid Sucursales
    const [gridSugerenciaRef, setGridSugerenciaRef] = useState(React.createRef());

    const filterBuilderPopupPosition = {
        of: window,
        at: 'top',
        my: 'top',
        offset: { y: 10 },
    };

    const allowedPageSizes = [5, 10, 15, 20, 'all'];

    const [autoExpandAll, setAutoExpandAll] = useState(true);

    async function getProveedor(sqlFilter) {
        let apiName = 'AdminSC';
        let path = '/getcatalogo';

        let myInit = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
            },
            queryStringParameters: {
                type: 'ProveedorCompra',
                username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username,
                sqlFilter: ''
            }
        }

        var proveedoresGet = await API.get(apiName, path, myInit);

        setProveedores(proveedoresGet);
    };

    try {
        var ejercicioEditorOptions = useMemo(() => {
            return {
                items: ejercicios,
                displayExpr: 'name',
                valueExpr: 'value',
                searchEnabled: false,
                value: (reportPayload.header.Ejercicio ? reportPayload.header.Ejercicio : new Date().getFullYear()),
                readOnly: false
            };
        }, [ejercicios, reportPayload]);
    }
    catch (e) {
        var ejercicioEditorOptions = {
            items: ejercicios,
            displayExpr: 'name',
            valueExpr: 'value',
            searchEnabled: false,
            readOnly: false,
            value: '',
        };
    }

    try {
        var periodoEditorOptions = useMemo(() => {
            return {
                items: periodos,
                displayExpr: 'name',
                valueExpr: 'value',
                searchEnabled: false,
                value: (reportPayload.header.Periodo ? reportPayload.header.Periodo : new Date().getMonth() + 1),
                readOnly: false
            };
        }, [periodos, reportPayload]);
    }
    catch (e) {
        var periodoEditorOptions = {
            items: periodos,
            displayExpr: 'name',
            valueExpr: 'value',
            searchEnabled: false,
            readOnly: false,
            value: '',
        };
    }

    const refreshButton = React.useCallback(async () => {
        if (formReporteRef.current) {
            var formReporte = formReporteRef.current.instance;
            var isValid = formReporte.validate();

            if (!isValid.isValid) {
                return;
            }
        }

        try {
            setVisibleLoadPanel(true);

            var lambdaFunction;

            if (window.location.href.indexOf('sandbox.') >= 0 || window.location.href.indexOf('localhost') >= 0)
                lambdaFunction = 'reporteCompras-dev';
            else if (window.location.href.indexOf('sandboxtampico.') >= 0)
                lambdaFunction = 'reporteCompras-devleona';
            else if (window.location.href.indexOf('tampico.') >= 0)
                lambdaFunction = 'reporteCompras-prodleona';
            else
                lambdaFunction = 'reporteCompras-prod';

            Auth.currentCredentials()
                .then(credentials => {
                    const lambda = new Lambda({
                        credentials: Auth.essentialCredentials(credentials),
                        region: 'us-east-1'
                    });

                    var proveedores = [];

                    for (var proveedoresSeleccionadosIndex in proveedoresSeleccionados) {
                        proveedores.push({
                            Proveedor: proveedoresSeleccionados[proveedoresSeleccionadosIndex],
                        })
                    };

                    var params = {
                        FunctionName: lambdaFunction,
                        Payload: JSON.stringify({
                            Ejercicio: reportPayload.header.Ejercicio,
                            Periodo: reportPayload.header.Periodo,
                            Proveedor: proveedores,
                            username: props.username
                        })
                    };

                    lambda.invoke(params, function (err, data) {
                        if (err) {
                            setVisibleLoadPanel(false);
                        }
                        else {
                            var payload = JSON.parse(JSON.parse(data.Payload).body);

                            console.log('payload', payload);
                            setVisibleLoadPanel(false);
                            setReportPayload(payload);
                        }
                    });
                })
        }
        catch (e) {
            setVisibleLoadPanel(false);

            if (e === 'No current user') {
                history.push('/');

                window.location.reload();
            }
        }
    }, [formReporteRef, history, reportPayload, props.username, proveedoresSeleccionados]);

    const cfgItems = React.useCallback(() => {
        var baseUrl = window.location.href.split('/')[0];

        window.open(baseUrl + "/#/ItemPOSuggestion", "_blank");
    }, []);

    const cfgLocations = React.useCallback(() => {
        var baseUrl = window.location.href.split('/')[0];

        window.open(baseUrl + "/#/WarehousePOSuggestion", "_blank");
    }, []);

    const getEjercicios = React.useCallback(() => {
        var year = new Date().getFullYear();
        var yearAux = 2020;
        var ejerciciosTmp = [];

        while (yearAux <= year) {
            ejerciciosTmp.push({
                name: yearAux,
                value: yearAux
            });

            yearAux++;
        }

        setEjercicios(ejerciciosTmp);
    }, []);

    const getPeriodos = React.useCallback(() => {
        var periodosTmp = [];

        periodosTmp.push({
            name: 'Enero',
            value: 1
        });

        periodosTmp.push({
            name: 'Febrero',
            value: 2
        });

        periodosTmp.push({
            name: 'Marzo',
            value: 3
        });

        periodosTmp.push({
            name: 'Abril',
            value: 4
        });

        periodosTmp.push({
            name: 'Mayo',
            value: 5
        });

        periodosTmp.push({
            name: 'Junio',
            value: 6
        });

        periodosTmp.push({
            name: 'Julio',
            value: 7
        });

        periodosTmp.push({
            name: 'Agosto',
            value: 8
        });

        periodosTmp.push({
            name: 'Septiembre',
            value: 9
        });

        periodosTmp.push({
            name: 'Octubre',
            value: 10
        });

        periodosTmp.push({
            name: 'Noviembre',
            value: 11
        });

        periodosTmp.push({
            name: 'Diciembre',
            value: 12
        });


        setPeriodos(periodosTmp);
    }, []);

    const syncDataGridSelection = React.useCallback((e) => {
        setProveedoresSeleccionados(e.value || []);
    }, []);

    const dataGridOnSelectionChanged = React.useCallback((selectedRowKeys) => {
        setProveedoresSeleccionados((selectedRowKeys.selectedRowKeys.length && selectedRowKeys.selectedRowKeys) || []);
    }, []);

    const dataGridRender = React.useCallback(() => (
        <DataGrid
            height={345}
            dataSource={proveedores}
            hoverStateEnabled={true}
            selectedRowKeys={proveedoresSeleccionados}
            onSelectionChanged={dataGridOnSelectionChanged}
            allowUpdating={false}
            keyExpr={"value"}
            key={"value"}
        >
            <Selection mode="multiple" />
            <Scrolling mode="virtual" />
            <Paging
                enabled={true}
                pageSize={10}
            />
            <FilterRow visible={true} />
            <Column dataField="value" caption="Proveedor" width={100} dataType="string" />
            <Column dataField="name" caption="Nombre" width={100} dataType="string" />
        </DataGrid>
    ),
        [proveedores, proveedoresSeleccionados, dataGridOnSelectionChanged],
    );

    useEffect(() => {
        async function initialize() {
            setVisibleLoadPanel(true);

            getEjercicios();
            getPeriodos();
            getProveedor('');

            setVisibleLoadPanel(false);
        }

        initialize();
    }, [getEjercicios, getPeriodos]);

    function onExporting(e) {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Datos');

        exportDataGrid({
            component: e.component,
            worksheet,
            autoFilterEnabled: true,
        }).then(() => {
            workbook.xlsx.writeBuffer().then((buffer) => {
                saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Datos.xlsx');
            });
        });
        e.cancel = true;
    };

    const onRowPrepared = React.useCallback((e) => {
        if (e.rowType === "data" && e.data.SeguimientoPOColor === 'Azul') {
            e.rowElement.style.backgroundColor = 'lightblue';
        }
        else if (e.rowType === "data" && e.data.SeguimientoPOColor === 'Rojo') {
            e.rowElement.style.backgroundColor = 'crimson';
        }
        else if (e.rowType === "data" && e.data.SeguimientoPOColor === 'Verde') {
            e.rowElement.style.backgroundColor = 'lightgreen';
        }
        else if (e.rowType === "data" && e.data.SeguimientoPOColor === 'Amarillo') {
            e.rowElement.style.backgroundColor = 'lightyellow';
        }
        else if (e.rowType === "data" && e.data.SeguimientoPOColor === 'Morado') {
            e.rowElement.style.backgroundColor = 'plum';
        }
        else if (e.rowType === "data" && e.data.SeguimientoPOColor === 'Rosa') {
            e.rowElement.style.backgroundColor = 'pink';
        }
    }, [])

    return (
        <div>
            <div class="bg-gray-200 m-2">
                <h1 class="font-sans inline-block text-xl font-bold text-gray-700 leading-8 align-top mb-1"><FontAwesomeIcon icon={faFileChartColumn} size="xl" /> Sugerencia de Compras</h1>
            </div>
            <div>
                <button onClick={refreshButton} type="submit" class="ml-2 mb-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                    <FontAwesomeIcon icon={faArrowsRotate} /> Actualizar
                </button>

                {poSuggestionsReportCfg.toString() === 'true' ?
                    (
                        <button onClick={cfgItems} type="submit" class="ml-4 mb-4 bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">
                            <FontAwesomeIcon icon={faBoxCheck} /> Cfg. Artículos
                        </button>
                    ) : (
                        <div class="inline-block"></div>
                    )}

                {poSuggestionsReportCfg.toString() === 'true' ?
                    (
                        <button onClick={cfgLocations} type="submit" class="ml-4 mb-4 bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">
                            <FontAwesomeIcon icon={faWarehouse} /> Cfg. Almacenes
                        </button>
                    ) : (
                        <div class="inline-block"></div>
                    )}
            </div>

            <LoadPanel
                shadingColor="rgba(0,0,0,0.4)"
                visible={visibleLoadPanel}
                showIndicator={true}
                message="Por favor espere..."
                shading={true}
                showPane={true}
                hideOnOutsideClick={false}
            />

            <div class="m-2 bg-white p-4 shadow-xl">
                <Form
                    id="formReporte"
                    labelMode={'outside'}
                    formData={reportPayload}
                    readOnly={false}
                    showColonAfterLabel={false}
                    labelLocation={'top'}
                    ref={formReporteRef}
                >
                    <GroupItem caption="Filtros" colCount={2}>
                        <Item dataField="header.Ejercicio" editorType="dxSelectBox" editorOptions={ejercicioEditorOptions}>
                            <Label text={"Ejercicio"} />
                            <RequiredRule message="Ejercicio es requerido" />
                        </Item>
                        <Item dataField="header.Periodo" editorType="dxSelectBox" editorOptions={periodoEditorOptions}>
                            <Label text={"Periodo"} />
                            <RequiredRule message="Periodo es requerido" />
                        </Item>
                        <SimpleItem dataField="header.Proveedor" caption="Proveedor" isRequired={true}>
                            <Label text={"Proveedor"} />
                            <DropDownBox
                                value={proveedoresSeleccionados}
                                valueExpr="value"
                                deferRendering={false}
                                displayExpr="value"
                                showClearButton={true}
                                dataSource={proveedores}
                                onValueChanged={syncDataGridSelection}
                                contentRender={dataGridRender}
                            />
                        </SimpleItem>
                    </GroupItem>
                    <GroupItem caption="Información" colCount={1}>
                        <TabbedItem>
                            <TabPanelOptions deferRendering={false} showNavButtons={true} />
                            <Tab title="Sugerencia" colCount={2}>
                                <DataGrid
                                    height={800}
                                    id="gridSugerencia"
                                    columnsAutoWidth={true}
                                    dataSource={reportPayload.detail}
                                    allowColumnReordering={true}
                                    allowColumnResizing={true}
                                    columnResizingMode={"widget"}
                                    showBorders={true}
                                    showRowLines={true}
                                    showColumnLines={true}
                                    remoteOperations={{ filtering: true }}
                                    ref={gridSugerenciaRef}
                                    onExporting={onExporting}
                                    onRowPrepared={onRowPrepared}
                                >
                                    <Scrolling
                                        mode='virtual'
                                    />
                                    <Paging defaultPageSize={10} />
                                    <Pager
                                        visible={true}
                                        allowedPageSizes={allowedPageSizes}
                                        displayMode='compact'
                                        showPageSizeSelector={true}
                                        showInfo={true}
                                        showNavigationButtons={true}
                                    />
                                    <FilterRow visible={true} />
                                    <FilterPanel visible={true} />
                                    <FilterBuilderPopup position={filterBuilderPopupPosition} />

                                    <HeaderFilter visible={true} />

                                    <GroupPanel visible={true} />
                                    <SearchPanel visible={true} />
                                    <ColumnChooser
                                        enabled={true}
                                        mode='select'
                                    >
                                        <ColumnChooserSearch
                                            enabled={true}
                                        />

                                        <ColumnChooserSelection
                                            allowSelectAll={true}
                                            selectByClick={true}
                                            recursive={true}
                                        />
                                    </ColumnChooser>
                                    <Grouping autoExpandAll={autoExpandAll} />
                                    <StateStoring enabled={true} type="localStorage" storageKey="gridContainerSalesOrder" />
                                    <Export enabled={true} />
                                    <Column dataField="Plaza" dataType="string" width={150} caption="Plaza" />
                                    <Column dataField="Proveedor" dataType="string" width={110} caption="Proveedor" />
                                    <Column dataField="Nombre" dataType="string" width={300} caption="Nombre" />
                                    <Column dataField="Articulo" dataType="string" width={100} caption="Artículo" />
                                    <Column dataField="Descripcion" dataType="string" width={300} caption="Descripción" />
                                    <Column dataField="Actual" dataType="number" width={150} caption={reportPayload.titles.PeriodoNombre} />
                                    <Column dataField="Anterior" dataType="number" width={150} caption={reportPayload.titles.Periodo1Nombre} />
                                    <Column dataField="Anteriores" dataType="number" width={150} caption={reportPayload.titles.Periodo2Nombre} />
                                    <Column dataField="Actual_ant" dataType="number" width={150} caption={reportPayload.titles.Periodo_ant_Nombre} visible={false} />
                                    <Column dataField="Anterior_ant" dataType="number" width={150} caption={reportPayload.titles.Periodo1_ant_Nombre} visible={false}/>
                                    <Column dataField="Anteriores_ant" dataType="number" width={150} caption={reportPayload.titles.Periodo2_ant_Nombre} visible={false}/>
                                    <Column dataField="Existencia" dataType="number" width={110} caption="Existencia" />
                                    <Column dataField="Adeudo" dataType="number" width={110} caption="Adeudo" />
                                    <Column dataField="OC" dataType="number" width={110} caption="OC" />
                                    <Column dataField="Patas" dataType="number" width={110} caption="Patas" />
                                    <Column dataField="SeguimientoPOColor" dataType="string" width={200} caption="Color Seguimiento" visible={false}/>

                                    <Sorting mode="none" />
                                    <FilterRow visible={true} />
                                    <FilterPanel visible={true} />
                                    <FilterBuilderPopup position={filterBuilderPopupPosition} />

                                    <HeaderFilter visible={true} />
                                </DataGrid>
                            </Tab>
                        </TabbedItem>
                    </GroupItem>
                </Form>
            </div>
        </div>
    );
}