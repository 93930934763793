import React, { useState, useEffect, useRef } from 'react';

export default function PdfEditor(props) {
  const [className, setClassName] = useState('');

  useEffect(() => {
    if (props.Estatus === 'Sin Afectar')
      setClassName('inline-block text-l ml-3 px-2 py-1 font-semibold leading-tight text-gray-600 bg-gray-300 rounded-lg');
    else if (props.Estatus === 'Cancelado')
      setClassName('inline-block text-l ml-3 px-2 py-1 font-semibold leading-tight text-red-700 bg-red-200 rounded-lg');
    else if (props.Estatus === 'Pendiente')
      setClassName('inline-block text-l ml-3 px-2 py-1 font-semibold leading-tight text-blue-700 bg-blue-200 rounded-lg');
    else if (props.Estatus === 'Concluido')
      setClassName('inline-block text-l ml-3 px-2 py-1 font-semibold leading-tight text-green-700 bg-green-200 rounded-lg');
    else if (props.Estatus === 'Por Confirmar')
      setClassName('inline-block text-l ml-3 px-2 py-1 font-semibold leading-tight text-yellow-700 bg-yellow-200 rounded-lg');
    else if (props.Estatus === 'Por Autorizar')
      setClassName('inline-block text-l ml-3 px-2 py-1 font-semibold leading-tight text-blue-700 bg-blue-200 rounded-lg');
    else if (props.Estatus === 'Por Procesar')
      setClassName('inline-block text-l ml-3 px-2 py-1 font-semibold leading-tight text-yellow-700 bg-yellow-200 rounded-lg');
    else if (props.Estatus === 'Disponible')
      setClassName('inline-block text-l ml-3 px-2 py-1 font-semibold leading-tight text-blue-700 bg-blue-200 rounded-lg');
    else if (props.Estatus === 'Enrutado')
      setClassName('inline-block text-l ml-3 px-2 py-1 font-semibold leading-tight text-pink-700 bg-pink-200 rounded-lg');
    else if (props.Estatus === 'Autorizado')
      setClassName('inline-block text-l ml-3 px-2 py-1 font-semibold leading-tight text-purple-700 bg-purple-200 rounded-lg');
    else if (props.Estatus === 'En Revision')
      setClassName('inline-block text-l ml-3 px-2 py-1 font-semibold leading-tight text-yellow-700 bg-yellow-200 rounded-lg');
    else if (props.Estatus === 'Finalizado')
      setClassName('inline-block text-l ml-3 px-2 py-1 font-semibold leading-tight text-green-700 bg-green-200 rounded-lg');
    else
      setClassName('inline-block text-l ml-3 px-2 py-1 font-semibold leading-tight text-black-700 bg-gray-200 rounded-lg');
  }, [props, className]);

  return (
    <span className={className} >{props.Estatus}</span>
  );
};