import React, { useState, useEffect, useRef, useMemo } from 'react';

// Ruteador
import { Route, Link, useHistory } from "react-router-dom";

// LoadPanel
import { LoadPanel } from 'devextreme-react/load-panel';

// Toast
import { Toast } from 'devextreme-react/toast';

//Form
import Form, { GroupItem, Label, TabbedItem, TabPanelOptions, Tab, EmptyItem } from 'devextreme-react/form';

//Validator
import { EmailRule, CustomRule } from 'devextreme-react/validator';

import { Auth, API, Storage } from 'aws-amplify';

// Grid
import DataGrid, {
    Column, FilterRow, HeaderFilter, FilterPanel, FilterBuilderPopup,
    GroupPanel, Grouping, SearchPanel, Sorting, Editing, Lookup, Toolbar, Item, RequiredRule,
    Scrolling, Pager, Paging, Summary, TotalItem, StateStoring, ColumnChooser, Format, Selection,
    Export, ColumnChooserSearch, ColumnChooserSelection
} from 'devextreme-react/data-grid';

// Iconos
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsRotate } from "@fortawesome/pro-solid-svg-icons";
import { faToolbox } from "@fortawesome/pro-duotone-svg-icons";
import { faPlay } from "@fortawesome/pro-duotone-svg-icons";

import dxDateBox from "devextreme/ui/date_box";

import Lambda from 'aws-sdk/clients/lambda';

import 'devextreme/data/odata/store';
import CustomStore from 'devextreme/data/custom_store';

import DataSource from 'devextreme/data/data_source';

import '../ListStyle.css';

//confirm
import { confirm } from 'devextreme/ui/dialog';

dxDateBox.defaultOptions({
    options: {
        dateSerializationFormat: "yyyy-MM-dd"
    }
});

export default function CreateItemCountWMS(props) {
    const history = useHistory();

    const [visibleLoadPanel, setVisibleLoadPanel] = useState(false);

    const [visibleToast, setVisibleToast] = useState(false);
    const [messageToast, setMessageToast] = useState('');
    const [typeToast, setTypeToast] = useState('success');

    const [createItemCountWMSPayload, setCreateItemCountWMSPayload] = useState({
        header: {
        },
        detail: []
    });

    // Variables para los filtros
    const [almacenes, setAlmacenes] = useState([]);
    const [posiciones, setPosiciones] = useState([]);

    //Controles para expander los grupos del grid de checklist
    const [autoExpandAllChecklist, setAutoExpandAllChecklist] = useState(true);

    const [autoExpandAll, setAutoExpandAll] = useState(true);

    //Ref para el form
    const [formReporteRef, setFormReporteRef] = useState(React.createRef());

    //Ref para el grid de articulos
    const [gridArticulosRef, setGridArticulosRef] = useState(React.createRef());

    const filterBuilderPopupPosition = {
        of: window,
        at: 'top',
        my: 'top',
        offset: { y: 10 },
    };

    function getOrderDay(rowData) {
        return (new Date(rowData.OrderDate)).getDay();
    }

    const filterBuilder = {
        customOperations: [{
            name: 'weekends',
            caption: 'Weekends',
            dataTypes: ['date'],
            icon: 'check',
            hasValue: false,
            calculateFilterExpression: () => [[getOrderDay, '=', 0], 'or', [getOrderDay, '=', 6]],
        }],
        allowHierarchicalFields: true,
    };

    const allowedPageSizes = [5, 10, 15, 20, 'all'];

    var almacenDataSource = useMemo(() => {
        return new DataSource({
            paginate: true,
            store: {
                type: "array",
                key: "Almacen",
                data: almacenes
            }
        });
    }, [almacenes]);

    var posicionDataSource = useMemo(() => {
        return new DataSource({
            paginate: true,
            store: {
                type: "array",
                key: "Posicion",
                data: posiciones
            }
        });
    }, [posiciones]);

    const refreshButton = React.useCallback(async () => {
        if (formReporteRef.current) {
            var formReporte = formReporteRef.current.instance;
            var isValid = formReporte.validate();

            if (!isValid.isValid) {
                setVisibleToast(true);
                setMessageToast(isValid.brokenRules[0].message);
                setTypeToast('error');
                return;
            }
        }

        try {
            setVisibleLoadPanel(true);

            var lambdaFunction;

            var payload = JSON.parse(JSON.stringify(createItemCountWMSPayload));

            payload.action = 'getItemCountItemList';
            payload.username = props.username;

            if (window.location.href.indexOf('sandbox.') >= 0 || window.location.href.indexOf('localhost') >= 0)
                lambdaFunction = 'procesaConteoCiclicoWMS-dev';
            else if (window.location.href.indexOf('sandboxtampico.') >= 0)
                lambdaFunction = 'procesaConteoCiclicoWMS-devleona';
            else if (window.location.href.indexOf('tampico.') >= 0)
                lambdaFunction = 'procesaConteoCiclicoWMS-prodleona';
            else
                lambdaFunction = 'procesaConteoCiclicoWMS-prod';

            Auth.currentCredentials()
                .then(credentials => {
                    const lambda = new Lambda({
                        credentials: Auth.essentialCredentials(credentials),
                        region: 'us-east-1'
                    });

                    var params = {
                        FunctionName: lambdaFunction,
                        Payload: JSON.stringify(payload)
                    };

                    lambda.invoke(params, function (err, data) {
                        if (err) {
                            setVisibleLoadPanel(false);
                        }
                        else {
                            var payload = JSON.parse(JSON.parse(data.Payload).body);

                            setVisibleLoadPanel(false);
                            setCreateItemCountWMSPayload(payload);
                        }
                    });
                })
        }
        catch (e) {
            setVisibleLoadPanel(false);

            if (e === 'No current user') {
                history.push('/');

                window.location.reload();
            }
        }
    }, [formReporteRef, history, createItemCountWMSPayload, props.username]);

    const createButton = React.useCallback(async () => {
        if (formReporteRef.current) {
            var formReporte = formReporteRef.current.instance;
            var isValid = formReporte.validate();

            if (!isValid.isValid) {
                setVisibleToast(true);
                setMessageToast(isValid.brokenRules[0].message);
                setTypeToast('error');
                return;
            }
        }

        if (gridArticulosRef.current) {
            var gridArticulos = gridArticulosRef.current.instance;

            var keys = gridArticulos.getSelectedRowKeys();

            var arrayItem = [];

            for (var keysIndex in keys) {
                arrayItem.push({
                    Articulo: keys[keysIndex]
                });
            }

            if (arrayItem.length === 0) {
                setVisibleToast(true);
                setMessageToast('Falta seleccionar los artículos a contar');
                setTypeToast('error');
            }
            else {
                var result = confirm('Se creará el conteo cíclico de ' + arrayItem.length + ' artículos. ¿Desea continuar?', 'Cerrar Pedidos');

                result.then(async (dialogResult) => {
                    if (dialogResult) {

                        try {
                            setVisibleLoadPanel(true);

                            var lambdaFunction;

                            var payload = JSON.parse(JSON.stringify(createItemCountWMSPayload));

                            payload.action = 'createItemCount';
                            payload.username = props.username;
                            payload.usuario = props.usuario;
                            payload.selectedItems = arrayItem;

                            if (window.location.href.indexOf('sandbox.') >= 0 || window.location.href.indexOf('localhost') >= 0)
                                lambdaFunction = 'procesaConteoCiclicoWMS-dev';
                            else if (window.location.href.indexOf('sandboxtampico.') >= 0)
                                lambdaFunction = 'procesaConteoCiclicoWMS-devleona';
                            else if (window.location.href.indexOf('tampico.') >= 0)
                                lambdaFunction = 'procesaConteoCiclicoWMS-prodleona';
                            else
                                lambdaFunction = 'procesaConteoCiclicoWMS-prod';

                            Auth.currentCredentials()
                                .then(credentials => {
                                    const lambda = new Lambda({
                                        credentials: Auth.essentialCredentials(credentials),
                                        region: 'us-east-1'
                                    });

                                    var params = {
                                        FunctionName: lambdaFunction,
                                        Payload: JSON.stringify(payload)
                                    };

                                    lambda.invoke(params, function (err, data) {
                                        if (err) {
                                            setVisibleLoadPanel(false);
                                        }
                                        else {
                                            var payload = JSON.parse(JSON.parse(data.Payload).body);

                                            setVisibleLoadPanel(false);

                                            if (payload.header.ok === 'true' && payload.header.newID) {
                                                var baseUrl = window.location.href.split('#/')[0];
                                                var redirect = '/ItemCountWMS/' + payload.header.newID;

                                                redirect = baseUrl + '#' + redirect;

                                                window.open(redirect, "_blank");
                                            }

                                        }
                                    });
                                })
                        }
                        catch (e) {
                            setVisibleLoadPanel(false);

                            if (e === 'No current user') {
                                history.push('/');

                                window.location.reload();
                            }
                        }
                    }
                });
            }
        }
    }, [gridArticulosRef, formReporteRef, history, createItemCountWMSPayload, props.username, props.usuario]);

    async function getArticulo(sqlFilter) {
        let apiName = 'AdminSC';
        let path = '/getcatalogo';

        let myInit = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
            },
            queryStringParameters: {
                type: 'ArticuloWMS',
                username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username,
                sqlFilter: sqlFilter
            }
        }

        var articulosGet = await API.get(apiName, path, myInit);

        return articulosGet;
    };

    async function getProveedor(sqlFilter) {
        let apiName = 'AdminSC';
        let path = '/getcatalogo';

        let myInit = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
            },
            queryStringParameters: {
                type: 'Proveedor',
                username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username,
                sqlFilter: sqlFilter
            }
        }

        var articulosGet = await API.get(apiName, path, myInit);

        return articulosGet;
    };

    const getAlmPos = React.useCallback(async (Almacen) => {
        let apiName = 'AdminSC';
        let path = '/getcatalogo';

        let myInit = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
            },
            queryStringParameters: {
                type: 'AlmPos',
                Almacen: Almacen,
                username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username
            }
        }

        var posicionesGet = await API.get(apiName, path, myInit);

        setPosiciones(posicionesGet);
    }, []);

    async function getAlmacenes() {
        let apiName = 'AdminSC';
        let path = '/getcatalogo';

        let myInit = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
            },
            queryStringParameters: {
                type: 'AlmacenWMS',
                username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username
            }
        }

        var almacenesGet = await API.get(apiName, path, myInit);

        setAlmacenes(almacenesGet);
    };

    const storeArticulo = new CustomStore({
        key: 'value',
        load(loadOptions) {
            let sqlFilter = '';
            [
                'filter'
            ].forEach((i) => {
                sqlFilter = loadOptions.searchValue;
            });

            async function getRecords() {
                if (sqlFilter) {
                    var records = await getArticulo(sqlFilter);

                    return {
                        data: records,
                        totalCount: records.length
                    };
                }
                else {
                    return {
                        data: [],
                        totalCount: 0
                    };
                }
            }

            return getRecords()
                .then(function (result) {
                    return result;
                })
                .catch(() => { throw new Error('Data Loading Error'); });
        },
        byKey: (key) => {
            async function getRecords(key) {
                var records = await getArticulo(key);

                return records;
            }

            return getRecords(key);
        }
    });

    const storeProveedor = new CustomStore({
        key: 'value',
        load(loadOptions) {
            let sqlFilter = '';
            [
                'filter'
            ].forEach((i) => {
                sqlFilter = loadOptions.searchValue;
            });

            async function getRecords() {
                if (sqlFilter) {
                    var records = await getProveedor(sqlFilter);

                    return {
                        data: records,
                        totalCount: records.length
                    };
                }
                else {
                    return {
                        data: [],
                        totalCount: 0
                    };
                }
            }

            return getRecords()
                .then(function (result) {
                    return result;
                })
                .catch(() => { throw new Error('Data Loading Error'); });
        },
        byKey: (key) => {
            async function getRecords(key) {
                var records = await getProveedor(key);

                return records;
            }

            return getRecords(key);
        }
    });

    //Opciones del campo Almacen
    try {
        var almacenEditorOptions = useMemo(() => {
            return {
                dataSource: almacenDataSource,
                displayExpr: 'name',
                valueExpr: 'value',
                searchEnabled: true,
                value: (createItemCountWMSPayload.header.Almacen ? createItemCountWMSPayload.header.Almacen : ''),
                width: '100%'
            };
        }, [almacenDataSource, createItemCountWMSPayload.header.Almacen]);
    }
    catch (e) {
        var almacenEditorOptions = {
            dataSource: almacenDataSource,
            displayExpr: 'name',
            valueExpr: 'value',
            searchEnabled: true,
            value: '',
            width: '100%'
        };
    }

    //Opciones del campo Articulo
    try {
        var articuloEditorOptions = {
            dataSource: storeArticulo,
            displayExpr: 'name',
            valueExpr: 'value',
            searchEnabled: true,
            showClearButton: true,
            acceptCustomValue: false,
            disabled: false,
            searchTimeout: 1500,
            width: '100%',
            clearButtonText: 'Cerrar',
            noDataText: 'No se encontraron resultados'
        };
    }
    catch (e) {
        var articuloEditorOptions = {
            dataSource: storeArticulo,
            displayExpr: 'name',
            valueExpr: 'value',
            searchEnabled: true,
            acceptCustomValue: false,
            width: '100%',
            showClearButton: true
        };
    }

    //Opciones del campo Proveedor
    try {
        var proveedorEditorOptions = {
            dataSource: storeProveedor,
            displayExpr: 'name',
            valueExpr: 'value',
            searchEnabled: true,
            showClearButton: true,
            acceptCustomValue: false,
            disabled: false,
            searchTimeout: 1500,
            width: '100%',
            clearButtonText: 'Cerrar',
            noDataText: 'No se encontraron resultados'
        };
    }
    catch (e) {
        var proveedorEditorOptions = {
            dataSource: storeProveedor,
            displayExpr: 'name',
            valueExpr: 'value',
            searchEnabled: true,
            acceptCustomValue: false,
            width: '100%',
            showClearButton: true
        };
    }

    //Opciones del campo Posicion
    try {
        var posicionEditorOptions = useMemo(() => {
            return {
                dataSource: posicionDataSource,
                displayExpr: 'name',
                valueExpr: 'value',
                searchEnabled: true,
                value: (createItemCountWMSPayload.header.Posicion ? createItemCountWMSPayload.header.Posicion : ''),
                width: '100%',
                showClearButton: true
            };
        }, [posicionDataSource, createItemCountWMSPayload.header.Posicion]);
    }
    catch (e) {
        var posicionEditorOptions = {
            dataSource: posicionDataSource,
            displayExpr: 'name',
            valueExpr: 'value',
            searchEnabled: true,
            value: '',
            width: '100%',
            showClearButton: true
        };
    }

    function onHidingToast() {
        setVisibleToast(false);
    };

    const onFieldDatosGeneralesChanged = React.useCallback(async (e) => {
        var dataField = e.dataField;
        var value = e.value;

        if (formReporteRef.current) {
            var formDatosGenerales = formReporteRef.current.instance;

            if (e.dataField === 'header.Almacen') {
                if (e.value) {
                    await getAlmPos(e.value);
                }
            }
        }
    }, [getAlmPos, formReporteRef]);

    function onOptionChanged(e) {
        if (e.name !== 'focusedRowIndex' && e.name !== 'focusedColumnIndex' && e.name !== 'selectedRowKeys') {
            e.component.on("contentReady", () => {
                e.component.off("contentReady");
                e.component.clearSelection();
            });
        }
    }

    useEffect(() => {
        async function initialize() {
            setVisibleLoadPanel(true);

            await getAlmacenes();

            setVisibleLoadPanel(false);
        }

        initialize();

    }, []);

    return (
        <div>
            <div class="bg-gray-200 m-2">
                <h1 class="font-sans inline-block text-xl font-bold text-gray-700 leading-8 align-top mb-1"><FontAwesomeIcon icon={faToolbox} size="xl" /> Crear Conteo Cíclico</h1>
            </div>
            <div>
                <button onClick={refreshButton} type="submit" class="ml-2 mb-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                    <FontAwesomeIcon icon={faArrowsRotate} /> Actualizar
                </button>
                <button onClick={createButton} type="submit" class="ml-2 mb-4 bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">
                    <FontAwesomeIcon icon={faPlay} /> Crear Conteo
                </button>
            </div>

            <LoadPanel
                shadingColor="rgba(0,0,0,0.4)"
                visible={visibleLoadPanel}
                showIndicator={true}
                message="Por favor espere..."
                shading={true}
                showPane={true}
                hideOnOutsideClick={false}
            />

            <Toast
                visible={visibleToast}
                message={messageToast}
                type={typeToast}
                onHiding={onHidingToast}
                displayTime={5000}
                width={'auto'}
                position={{
                    my: 'top center',
                    at: 'top center',
                    of: window,
                    offset: '0 130'
                }}
            />

            <div class="m-2 bg-white p-4 shadow-xl">
                <Form
                    id="formReporte"
                    labelMode={'outside'}
                    formData={createItemCountWMSPayload}
                    readOnly={false}
                    showColonAfterLabel={false}
                    labelLocation={'top'}
                    ref={formReporteRef}
                    onFieldDataChanged={onFieldDatosGeneralesChanged}
                >
                    <GroupItem caption="Filtros Requeridos" colCount={2}>
                        <Item dataField="header.Almacen" editorType="dxSelectBox" editorOptions={almacenEditorOptions}>
                            <Label text={"Almacén"} />
                            <RequiredRule message="Almacén es requerido" />
                        </Item>
                        <Item dataField="header.Posicion" editorType="dxSelectBox" editorOptions={posicionEditorOptions}>
                            <Label text={"Posición"} />
                        </Item>
                        <Item dataField="header.Proveedor" editorType="dxLookup" editorOptions={proveedorEditorOptions}>
                            <Label text="Proveedor" />
                        </Item>
                        <Item dataField="header.Articulo" editorType="dxLookup" editorOptions={articuloEditorOptions}>
                            <Label text="Artículo" />
                        </Item>
                        <Item dataField="header.IncluirPosicionCero" editorType="dxCheckBox"  >
                            <Label text="Incluir Posiciones sin existencia" />
                        </Item>
                    </GroupItem>
                    <GroupItem caption="Artículos" colCount={1}>
                        <DataGrid
                            id="gridArticulos"
                            columnsAutoWidth={true}
                            filterBuilder={filterBuilder}
                            dataSource={createItemCountWMSPayload.detail}
                            allowColumnReordering={true}
                            allowColumnResizing={true}
                            columnResizingMode={"widget"}
                            showBorders={true}
                            rowAlternationEnabled={true}
                            showRowLines={true}
                            showColumnLines={true}
                            remoteOperations={{ filtering: true }}
                            onOptionChanged={onOptionChanged}
                            ref={gridArticulosRef}
                        >
                            <Scrolling rowRenderingMode='virtual'></Scrolling>
                            <Paging defaultPageSize={0} />
                            <Pager
                                visible={true}
                                allowedPageSizes={allowedPageSizes}
                                displayMode='compact'
                                showPageSizeSelector={true}
                                showInfo={true}
                                showNavigationButtons={true} />
                            <FilterRow visible={true} />
                            <FilterPanel visible={true} />
                            <FilterBuilderPopup position={filterBuilderPopupPosition} />

                            <HeaderFilter visible={true} />

                            <SearchPanel visible={true} />

                            <Selection
                                mode="multiple"
                                selectAllMode={'page'}
                                showCheckBoxesMode={'always'}
                            />

                            <Column dataField="Articulo" dataType="string" width={200} caption="Artículo" visible={true} />
                            <Column dataField="ArtDescripcion" dataType="string" width={400} caption="Descripción" visible={true} />
                        </DataGrid>
                    </GroupItem>
                </Form>
            </div>
        </div>
    );
}