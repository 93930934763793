import React, { useState, useEffect, useRef } from 'react';

import { Auth, API, Storage } from 'aws-amplify';

// Ruteador
import { Route, Link, useHistory } from "react-router-dom";


import { Stimulsoft } from 'stimulsoft-reports-js/Scripts/stimulsoft.viewer';
import 'stimulsoft-reports-js/Css/stimulsoft.viewer.office2013.whiteblue.css';
import './App.css';

export default function PdfRender(props) {
  const [viewer, setViewer] = useState();
  const [report, setReport] = useState();

  useEffect(() => {
    async function initialize() {
      //Obtenemos los parámetros
      try {
        var type = window.location.href.split('?')[1].split('&')[0].split('=')[1];
        var id = window.location.href.split('?')[1].split('&')[1].split('=')[1];

        // Primero obtenemos el template
        let apiNameTemplate = 'AdminSC';
        let pathTemplate = '/procesapdftemplate';

        let myInitTemplate = {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
          },
          queryStringParameters: {
            Tipo: type,
            action: 'payload',
            username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username
          }
        }

        var templateGet = await API.get(apiNameTemplate, pathTemplate, myInitTemplate);

        var plantilla = templateGet.header.Plantilla;

        // Despues obtenemos el payload
        let apiNamePayload = 'AdminSC';
        let pathPayload;
        let myInitPayload;

        if (type === 'vacation') {
          pathPayload = '/procesavacaciones';

          myInitPayload = {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
            },
            queryStringParameters: {
              ID: id,
              action: 'payload',
              username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username
            }
          }
        }
        else if (type === 'checklistaud') {
          pathPayload = '/procesachecklist';

          myInitPayload = {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
            },
            queryStringParameters: {
              personal: '',
              ID: id,
              action: 'payload',
              mov: 'Check List Aud',
              username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username
            }
          }
        }
        else if (type === 'checklist') {
          pathPayload = '/procesachecklist';

          myInitPayload = {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
            },
            queryStringParameters: {
              personal: '',
              ID: id,
              action: 'payload',
              mov: 'Check List',
              username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username
            }
          }
        }
        else if (type === 'checklistveh') {
          pathPayload = '/procesachecklist';

          myInitPayload = {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
            },
            queryStringParameters: {
              personal: '',
              ID: id,
              action: 'payload',
              mov: 'Check List Veh',
              username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username
            }
          }
        }
        else if (type === 'invoice') {
          id = id.split('%2C');

          var invoices = [];

          for (var idIndex in id) {
            if (id[idIndex])
              invoices.push({ ID: id[idIndex] });
          }

          pathPayload = '/procesafacturaslote';

          myInitPayload = {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
            },
            body: {
              action: 'payload',
              invoices: invoices,
              username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username
            }
          }
        }
        else if (type === 'directdelivery') {
          pathPayload = '/procesaentregadirecta';

          myInitPayload = {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
            },
            queryStringParameters: {
              ID: id,
              action: 'payload',
              username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username
            }
          };
        }
        else if (type === 'deliveryrequest') {
          pathPayload = '/procesasolenvio';

          myInitPayload = {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
            },
            queryStringParameters: {
              ID: id,
              action: 'payload',
              username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username
            }
          };
        }
        else if (type === 'directreturn') {
          pathPayload = '/procesadevoluciondirecta';

          myInitPayload = {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
            },
            queryStringParameters: {
              ID: id,
              action: 'payload',
              username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username
            }
          };
        }
        else if (type === 'returnrequest') {
          pathPayload = '/procesasoldevolucion';

          myInitPayload = {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
            },
            queryStringParameters: {
              ID: id,
              action: 'payload',
              username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username
            }
          };
        }
        else if (type === 'salesordermod') {
          pathPayload = '/procesamodpedido';

          myInitPayload = {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
            },
            queryStringParameters: {
              ID: id,
              action: 'payload',
              username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username
            }
          };
        }
        else if (type === 'customerpayment') {
          pathPayload = '/procesaanticiposaldo';

          myInitPayload = {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
            },
            queryStringParameters: {
              ID: id,
              action: 'payload',
              username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username
            }
          };
        }
        else if (type === 'salesorder') {
          pathPayload = '/procesapedidos';

          myInitPayload = {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
            },
            queryStringParameters: {
              ID: id,
              action: 'payload',
              username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username
            }
          };
        }
        else if (type === 'estimate') {
          pathPayload = '/procesacotizaciones';

          myInitPayload = {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
            },
            queryStringParameters: {
              ID: id,
              action: 'payload',
              username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username
            }
          };
        }
        else if (type === 'inventorytransfer') {
          pathPayload = '/procesatransferencias';

          myInitPayload = {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
            },
            queryStringParameters: {
              ID: id,
              action: 'payload',
              username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username
            }
          };
        }
        else if (type === 'shipmentrequest') {
          pathPayload = '/procesasolembarque';

          myInitPayload = {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
            },
            queryStringParameters: {
              ID: id,
              action: 'payload',
              username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username
            }
          };
        }
        else if (type === 'transferorder') {
          pathPayload = '/procesasoltraspaso';

          myInitPayload = {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
            },
            queryStringParameters: {
              ID: id,
              action: 'payload',
              username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username
            }
          };
        }
        else if (type === 'invtransferorder') {
          pathPayload = '/procesainvsoltraspaso';

          myInitPayload = {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
            },
            queryStringParameters: {
              ID: id,
              action: 'payload',
              username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username
            }
          };
        }
        else if (type === 'warehousepositionwms') {
          pathPayload = '/procesaalmacenposicion';

          myInitPayload = {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
            },
            queryStringParameters: {
              Almacen: id.split('__@@__')[0],
              Posicion: id.split('__@@__')[1],
              action: 'payload',
              username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username
            }
          };
        }

        if (type === 'labelwms') {
          id = id.split('%2C');

          var payloadGet = [];

          for (var idIndex in id) {
            if (id[idIndex])
              payloadGet.push(id[idIndex]);
          }
        }
        else {
          if (type !== 'invoice')
            var payloadGet = await API.get(apiNamePayload, pathPayload, myInitPayload);
          else
            var payloadGet = await API.post(apiNamePayload, pathPayload, myInitPayload);
        }

        // Obtenemos la licencia
        let apiNameLicense = 'AdminSC';
        let pathLicense = '/getStimulsoftLicense';

        let myInitLicense = {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
          },
          queryStringParameters: {
          }
        }
        
        var stiLicense = await API.post(apiNameLicense, pathLicense, myInitLicense);

        Stimulsoft.Base.StiLicense.key = stiLicense[0].Value

        // Visualizador del reporte
        var tmpOptions = new Stimulsoft.Viewer.StiViewerOptions();
        tmpOptions.appearance.scrollbarsMode = true;
        tmpOptions.appearance.fullScreenMode = true;
        tmpOptions.toolbar.showOpenButton = false;
        tmpOptions.toolbar.showSaveButton = false;

        var tmpViewer = new Stimulsoft.Viewer.StiViewer(tmpOptions, 'StiViewer', false);
        var tmpReport = new Stimulsoft.Report.StiReport();

        tmpReport.load(plantilla);

        // Create new DataSet object
        var dataSet = new Stimulsoft.System.Data.DataSet("JSON");

        // Load JSON data file from specified URL to the DataSet object
        dataSet.readJson(payloadGet);

        // Remove all connections from the report template
        tmpReport.dictionary.databases.clear();

        // Register DataSet object
        tmpReport.regData("JSON", "JSON", dataSet);

        tmpViewer.report = tmpReport;

        tmpViewer.renderHtml('viewer');
      }
      catch (e) {
      }
    }

    initialize();

    // eslint-disable-next-line
  }, []);

  return (
    <div id="viewer" className="App">
    </div>
  );
}