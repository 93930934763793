import React, { useState, useEffect, useRef, useMemo } from 'react';

// LoadPanel
import { LoadPanel } from 'devextreme-react/load-panel';

// Toast
import { Toast } from 'devextreme-react/toast';

//Form
import Form, { GroupItem, Label, TabbedItem, TabPanelOptions, Tab } from 'devextreme-react/form';

import { Auth, API, Storage } from 'aws-amplify';

// Ruteador
import { Route, Link, useHistory } from "react-router-dom";

// Grid
import DataGrid, {
    Column, FilterRow, HeaderFilter, FilterPanel, FilterBuilderPopup,
    GroupPanel, Grouping, SearchPanel, Sorting, Editing, Lookup, Toolbar, Item, RequiredRule,
    Scrolling, Pager, Paging, Summary, TotalItem, StateStoring, ColumnChooser, Format, Selection,
    Export, ColumnChooserSearch, ColumnChooserSelection
} from 'devextreme-react/data-grid';

//CheckBox
import CheckBox from 'devextreme-react/check-box';

//ContextMenu
import ContextMenu from 'devextreme-react/context-menu';

// Iconos
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGlasses } from "@fortawesome/pro-solid-svg-icons";
import { faPencilAlt } from "@fortawesome/pro-solid-svg-icons";
import { faArrowRotateLeft } from "@fortawesome/pro-solid-svg-icons";
import { faSave } from "@fortawesome/pro-solid-svg-icons";
import { faList } from "@fortawesome/pro-duotone-svg-icons";
import { faFilePdf } from "@fortawesome/pro-solid-svg-icons";
import { faArrowsRotate } from "@fortawesome/pro-solid-svg-icons";
import { faFileAlt } from "@fortawesome/pro-solid-svg-icons";
import { faPenNib } from "@fortawesome/pro-duotone-svg-icons";
import { faPlay } from "@fortawesome/pro-solid-svg-icons";
import { faTrash } from "@fortawesome/pro-solid-svg-icons";
import { faLightbulb } from "@fortawesome/pro-solid-svg-icons";
import { faCircleCheck } from "@fortawesome/pro-solid-svg-icons";
import { faCircleX } from "@fortawesome/pro-solid-svg-icons";
import { faWindowClose } from "@fortawesome/pro-solid-svg-icons";
import { faThumbsUp } from "@fortawesome/pro-solid-svg-icons";
import { faThumbsDown } from "@fortawesome/pro-solid-svg-icons";
import { faPenFancy } from "@fortawesome/pro-solid-svg-icons";
import { faCartPlus } from "@fortawesome/pro-solid-svg-icons";
import { faMoneyCheckDollar } from "@fortawesome/pro-solid-svg-icons";
import { faBadgeDollar } from "@fortawesome/pro-solid-svg-icons";
import { faArrowUpRightFromSquare } from "@fortawesome/pro-solid-svg-icons";


import 'devextreme/data/odata/store';
import CustomStore from 'devextreme/data/custom_store';

import DataSource from 'devextreme/data/data_source';

import '../ListStyle.css';

//FileUploader
import FileUploader from 'devextreme-react/file-uploader';

//FileManager
import FileManager, { Permissions } from 'devextreme-react/file-manager';

import dxDateBox from "devextreme/ui/date_box";

import dxNumberBox from "devextreme/ui/number_box";

import { Popup, Position, ToolbarItem } from 'devextreme-react/popup';

import TransactionStatus from "../TransactionStatus/TransactionStatus.js";

import Lambda from 'aws-sdk/clients/lambda';

import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
import { exportDataGrid } from 'devextreme/excel_exporter'

dxDateBox.defaultOptions({
    options: {
        dateSerializationFormat: "yyyy-MM-dd"
    }
});

dxNumberBox.defaultOptions({
    options: {
        step: 0
    }
});

function isNotEmpty(value) {
    return value !== undefined && value !== null && value !== '';
}

export default function SalesOrderMod(props) {
    const history = useHistory();

    const [isDetail, setIsDetail] = useState(false);
    const [id, setId] = useState();
    const [permissionLevel, setPermissionLevel] = useState(props.nivel);
    const [salesOrderModUpdatePrices, setSalesOrderModUpdatePrices] = useState(props.salesOrderModUpdatePrices);
    const [approveSalesOrderMod, setApproveSalesOrderMod] = useState(props.approveSalesOrderMod);
    const [salesOrderModPayload, setSalesOrderModPayload] = useState({
        header: {},
        detail: [],
        movEstatusLog: []
    });

    const [addItemPayload, setAddItemPayload] = useState({
        Action: undefined,
        Renglon: undefined,
        RenglonID: undefined,
        SCSerieLote: undefined,
        Articulo: undefined,
        Cantidad: undefined,
        Precio: undefined
    });

    const [createCheckPayload, setCreateCheckPayload] = useState({
        Action: 'createCheck',
        SituacionGastosAdmin: 'NO',
        PorcentajeGA: 0
    });

    const [autoExpandAll, setAutoExpandAll] = useState(true);

    // setValue para el control tab
    const [value, setValue] = React.useState(0);

    const [visibleLoadPanel, setVisibleLoadPanel] = useState(false);

    const [visibleToast, setVisibleToast] = useState(false);
    const [messageToast, setMessageToast] = useState('');
    const [typeToast, setTypeToast] = useState('success');

    const [popupVisible, setPopupVisible] = useState(false);
    const [popupCheckVisible, setPopupCheckVisible] = useState(false);
    const [popupTitle, setPopupTitle] = useState('');

    const [fiscalRegimen, setFiscalRegimen] = useState([]);
    const [filtroUsoCFDI, setFiltroUsoCFDI] = useState([]);
    const [usuarios, setUsuarios] = useState([]);

    const [activateUpdateForm, setActivateUpdateForm] = useState(false);
    const [activateCustomSalesOrderField, setActivateCustomSalesOrderField] = useState(false);
    const [acceptCustomValues, setAcceptCustomValue] = useState(false);
    const [activateSaveButton, setActivateSaveButton] = useState(false);
    const [activateDeleteButton, setActivateDeleteButton] = useState(false);
    const [activateCancelButton, setActivateCancelButton] = useState(false);
    const [activateConfirmButton, setActivateConfirmButton] = useState(false);
    const [activateApproveButton, setActivateApproveButton] = useState(false);
    const [activatePDFButton, setActivatePDFButton] = useState(false);
    const [activateAttachments, setActivateAttachments] = useState(false);
    const [activateModifyData, setActivateModifyData] = useState(false);
    const [activateModifyItems, setActivateModifyItems] = useState(false);
    const [activateGetSalesOrderData, setActivateGetSalesOrderData] = useState(false);
    const [activateUpdateGrid, setActivateUpdateGrid] = useState(false);
    const [activateMoneyReturn, setActivateMoneyReturn] = useState(false);

    //Ref para el grid Tablero de Control
    const [gridTableroRef, setGridTableroRef] = useState(React.createRef());

    const [attachments, setAttachments] = useState([]);

    //Ref para el form
    const [formDatosGeneralesRef, setFormDatosGeneralesRef] = useState(React.createRef());

    //Ref para el form Add Item
    const [formAddItemRef, setFormAddItemRef] = useState(React.createRef());

    //Ref para el form Create Check
    const [formCreateCheckRef, setFormCreateCheckRef] = useState(React.createRef());

    //Ref para el grid Articulos Finales
    const [gridArticulosFinalesRef, setGridArticulosFinalesRef] = useState(React.createRef());

    //Ref para el grid Articulos Agregados
    const [gridArticulosAgregadosRef, setGridArticulosAgregadosRef] = useState(React.createRef());

    //Ref para el grid Articulos Eliminados
    const [gridArticulosEliminadosRef, setGridArticulosEliminadosRef] = useState(React.createRef());

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const filterBuilderPopupPosition = {
        of: window,
        at: 'top',
        my: 'top',
        offset: { y: 10 },
    };

    const allowedPageSizes = [5, 10, 15, 20, 'all'];

    const exportFormats = ['xlsx'];

    function getOrderDay(rowData) {
        return (new Date(rowData.OrderDate)).getDay();
    }

    const filterBuilder = {
        customOperations: [{
            name: 'weekends',
            caption: 'Weekends',
            dataTypes: ['date'],
            icon: 'check',
            hasValue: false,
            calculateFilterExpression: () => [[getOrderDay, '=', 0], 'or', [getOrderDay, '=', 6]],
        }],
        allowHierarchicalFields: true,
    };

    const filterValue = [['Estatus', '=', 'Sin Afectar']];

    function onAutoExpandAllChanged() {
        setAutoExpandAll(!autoExpandAll)
    };

    async function getSalesOrderModRecords(sqlFilter) {
        try {
            let apiName = 'AdminSC';
            let path = '/procesamodpedido';

            let myInit = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
                },
                queryStringParameters: {
                    action: 'list',
                    sqlFilter: sqlFilter,
                    salesAccess: props.salesAccess,
                    sucursal: props.sucursal,
                    username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username
                }
            }

            var salesOrderModGet = await API.get(apiName, path, myInit);
        }
        catch (e) {
            if (e === 'No current user') {
                history.push('/');

                window.location.reload();
            }
        }

        return salesOrderModGet;
    }

    const store = new CustomStore({
        key: 'ID',
        load(loadOptions) {
            let sqlFilter = '(';
            [
                'filter'
            ].forEach((i) => {
                if (i in loadOptions && isNotEmpty(loadOptions[i])) {
                    var filters = loadOptions[i];

                    var filtersLength = filters.length;

                    for (var i = 0; i <= filtersLength; i++) {
                        var singleFilter = filters[i];
                        var singleFilterType = typeof singleFilter;
                        var singleFilterIsArray = Array.isArray(singleFilter);
                        var singleFilterIsDate = singleFilter instanceof Date;

                        if (singleFilter === 'NULL') {
                            singleFilter = '0';
                        }

                        if (singleFilterType.toString() === 'object' && singleFilterIsArray) {
                            sqlFilter += '(';

                            var subFiltersLength = singleFilter.length;

                            for (var i2 = 0; i2 <= subFiltersLength; i2++) {
                                var singleSubFilter = singleFilter[i2];
                                var singleSubFilterType = typeof singleSubFilter;
                                var singleSubFilterIsArray = Array.isArray(singleSubFilter);
                                var singleSubFilterIsDate = singleSubFilter instanceof Date

                                if (singleSubFilter === 'NULL') {
                                    singleSubFilter = '0';
                                }

                                if (singleSubFilterType.toString() === 'object' && singleSubFilterIsArray) {
                                    sqlFilter += '(';

                                    var subSubFiltersLength = singleSubFilter.length;

                                    for (var i3 = 0; i3 <= subSubFiltersLength; i3++) {
                                        var singleSubSubFilter = singleSubFilter[i3];
                                        var singleSubSubFilterType = typeof singleSubSubFilter;
                                        var singleSubSubFilterIsArray = Array.isArray(singleSubSubFilter);
                                        var singleSubSubFilterIsDate = singleSubSubFilter instanceof Date

                                        if (singleSubSubFilter === 'NULL') {
                                            singleSubSubFilter = '0';
                                        }

                                        if (singleSubSubFilterType.toString() === 'object' && singleSubSubFilterIsArray) {
                                            sqlFilter += '(';

                                            var subSubSubFiltersLength = singleSubSubFilter.length;

                                            for (var i4 = 0; i4 <= subSubSubFiltersLength; i4++) {
                                                var singleSubSubSubFilter = singleSubSubFilter[i4];
                                                var singleSubSubSubFilterType = typeof singleSubSubSubFilter;
                                                var singleSubSubSubFilterIsArray = Array.isArray(singleSubSubSubFilter);
                                                var singleSubSubSubFilterIsDate = singleSubSubSubFilter instanceof Date

                                                if (singleSubSubSubFilter === 'NULL') {
                                                    singleSubSubSubFilter = '0';
                                                }

                                                if (singleSubSubSubFilterType.toString() === 'object' && singleSubSubSubFilterIsArray) {
                                                    sqlFilter += '(';

                                                    var subSubSubSubFiltersLength = singleSubSubSubFilter.length;

                                                    for (var i5 = 0; i5 <= subSubSubSubFiltersLength; i5++) {
                                                        var singleSubSubSubSubFilter = singleSubSubSubFilter[i5];
                                                        var singleSubSubSubSubFilterType = typeof singleSubSubSubSubFilter;
                                                        var singleSubSubSubSubFilterIsArray = Array.isArray(singleSubSubSubSubFilter);
                                                        var singleSubSubSubSubFilterIsDate = singleSubSubSubSubFilter instanceof Date

                                                        if (singleSubSubSubSubFilter === 'NULL') {
                                                            singleSubSubSubSubFilter = '0';
                                                        }

                                                        if (singleSubSubSubSubFilterType.toString() === 'string') {
                                                            if (singleSubSubSubSubFilter === 'contains') {
                                                                filters[i][i2][i3][i4][i5] = 'LIKE';
                                                                singleSubSubSubSubFilter = 'LIKE';

                                                                filters[i][i2][i3][i4][i5 + 1] = '\'%' + filters[i][i2][i3][i4][i5 + 1] + '%\'';
                                                            }
                                                            else if (singleSubSubSubSubFilter === 'notcontains') {
                                                                filters[i][i2][i3][i4][i5] = 'NOT LIKE';
                                                                singleSubSubSubSubFilter = 'NOT LIKE';

                                                                filters[i][i2][i3][i4][i5 + 1] = '\'%' + filters[i][i2][i3][i4][i5 + 1] + '%\'';
                                                            }
                                                            else if (singleSubSubSubSubFilter === 'startswith') {
                                                                filters[i][i2][i3][i4][i5] = 'LIKE';
                                                                singleSubSubSubSubFilter = 'LIKE';

                                                                filters[i][i2][i3][i4][i5 + 1] = '\'' + filters[i][i2][i3][i4][i5 + 1] + '%\'';
                                                            }
                                                            else if (singleSubSubSubSubFilter === 'endswith') {
                                                                filters[i][i2][i3][i4][i5] = 'LIKE';
                                                                singleSubSubSubSubFilter = 'LIKE';

                                                                filters[i][i2][i3][i4][i5 + 1] = '\'%' + filters[i][i2][i3][i4][i5 + 1] + '\'';
                                                            }
                                                            else if (singleSubSubSubSubFilter === '=' || singleSubSubSubSubFilter === '<>' || singleSubSubSubSubFilter === '>' || singleSubSubSubSubFilter === '>=' || singleSubSubSubSubFilter === '<' || singleSubSubSubSubFilter === '<=') {
                                                                if (!filters[i][i2][i3][i4][i5 + 1])
                                                                    filters[i][i2][i3][i4][i5 + 1] = 'NULL';
                                                                else if (typeof filters[i][i2][i3][i4][i5 + 1] === 'string') {
                                                                    filters[i][i2][i3][i4][i5 + 1] = '\'' + filters[i][i2][i3][i4][i5 + 1] + '\'';
                                                                }
                                                            }
                                                            else if (singleSubSubSubSubFilter === '!') {
                                                                filters[i][i2][i3][i4][i5] = 'NOT';
                                                                singleSubSubSubSubFilter = 'NOT';
                                                            }

                                                            sqlFilter += ' ' + singleSubSubSubSubFilter + ' ';
                                                        }
                                                        else if (singleSubSubSubSubFilterType.toString() === 'object' && singleSubSubSubSubFilterIsDate) {
                                                            sqlFilter += ' \'' + singleSubSubSubSubFilter.toISOString().split('T')[0] + '\' ';
                                                        }
                                                        else if (singleSubSubSubSubFilterType.toString() === 'number') {
                                                            sqlFilter += ' ' + singleSubSubSubSubFilter.toString() + ' ';
                                                        }
                                                        else if (singleSubSubSubSubFilterType.toString() === 'boolean') {
                                                            if (singleSubSubSubSubFilter.toString() === 'true')
                                                                sqlFilter += '1 ';
                                                            else if (singleSubSubSubSubFilter.toString() === 'false' || singleSubSubSubSubFilter === null || singleSubSubSubSubFilter === undefined)
                                                                sqlFilter += '0 ';
                                                        }
                                                    }
                                                }
                                                else if (singleSubSubSubFilterType.toString() === 'string') {
                                                    if (singleSubSubSubFilter === 'contains') {
                                                        filters[i][i2][i3][i4] = 'LIKE';
                                                        singleSubSubSubFilter = 'LIKE';

                                                        filters[i][i2][i3][i4 + 1] = '\'%' + filters[i][i2][i3][i4 + 1] + '%\'';
                                                    }
                                                    else if (singleSubSubSubFilter === 'notcontains') {
                                                        filters[i][i2][i3][i4] = 'NOT LIKE';
                                                        singleSubSubSubFilter = 'NOT LIKE';

                                                        filters[i][i2][i3][i4 + 1] = '\'%' + filters[i][i2][i3][i4 + 1] + '%\'';
                                                    }
                                                    else if (singleSubSubSubFilter === 'startswith') {
                                                        filters[i][i2][i3][i4] = 'LIKE';
                                                        singleSubSubSubFilter = 'LIKE';

                                                        filters[i][i2][i3][i4 + 1] = '\'' + filters[i][i2][i3][i4 + 1] + '%\'';
                                                    }
                                                    else if (singleSubSubSubFilter === 'endswith') {
                                                        filters[i][i2][i3][i4] = 'LIKE';
                                                        singleSubSubSubFilter = 'LIKE';

                                                        filters[i][i2][i3][i4 + 1] = '\'%' + filters[i][i2][i3][i4 + 1] + '\'';
                                                    }
                                                    else if (singleSubSubSubFilter === '=' || singleSubSubSubFilter === '<>' || singleSubSubSubFilter === '>' || singleSubSubSubFilter === '>=' || singleSubSubSubFilter === '<' || singleSubSubSubFilter === '<=') {
                                                        if (!filters[i][i2][i3][i4 + 1])
                                                            filters[i][i2][i3][i4 + 1] = 'NULL';
                                                        else if (typeof filters[i][i2][i3][i4 + 1] === 'string') {
                                                            filters[i][i2][i3][i4 + 1] = '\'' + filters[i][i2][i3][i4 + 1] + '\'';
                                                        }
                                                    }
                                                    else if (singleSubSubSubFilter === '!') {
                                                        filters[i][i2][i3][i4] = 'NOT';
                                                        singleSubSubSubFilter = 'NOT';
                                                    }

                                                    sqlFilter += ' ' + singleSubSubSubFilter + ' ';
                                                }
                                                else if (singleSubSubSubFilterType.toString() === 'object' && singleSubSubSubFilterIsDate) {
                                                    sqlFilter += ' \'' + singleSubSubSubFilter.toISOString().split('T')[0] + '\' ';
                                                }
                                                else if (singleSubSubSubFilterType.toString() === 'number') {
                                                    sqlFilter += ' ' + singleSubSubSubFilter.toString() + ' ';
                                                }
                                                else if (singleSubSubSubFilterType.toString() === 'boolean') {
                                                    if (singleSubSubSubFilter.toString() === 'true')
                                                        sqlFilter += '1 ';
                                                    else if (singleSubSubSubFilter.toString() === 'false' || singleSubSubSubFilter === null || singleSubSubSubFilter === undefined)
                                                        sqlFilter += '0 ';
                                                }
                                            }

                                            sqlFilter += ')';
                                        }
                                        else if (singleSubSubFilterType.toString() === 'string') {
                                            if (singleSubSubFilter === 'contains') {
                                                filters[i][i2][i3] = 'LIKE';
                                                singleSubSubFilter = 'LIKE';

                                                filters[i][i2][i3 + 1] = '\'%' + filters[i][i2][i3 + 1] + '%\'';
                                            }
                                            else if (singleSubSubFilter === 'notcontains') {
                                                filters[i][i2][i3] = 'NOT LIKE';
                                                singleSubSubFilter = 'NOT LIKE';

                                                filters[i][i2][i3 + 1] = '\'%' + filters[i][i2][i3 + 1] + '%\'';
                                            }
                                            else if (singleSubSubFilter === 'startswith') {
                                                filters[i][i2][i3] = 'LIKE';
                                                singleSubSubFilter = 'LIKE';

                                                filters[i][i2][i3 + 1] = '\'' + filters[i][i2][i3 + 1] + '%\'';
                                            }
                                            else if (singleSubSubFilter === 'endswith') {
                                                filters[i][i2][i3] = 'LIKE';
                                                singleSubSubFilter = 'LIKE';

                                                filters[i][i2][i3 + 1] = '\'%' + filters[i][i2][i3 + 1] + '\'';
                                            }
                                            else if (singleSubSubFilter === '=' || singleSubSubFilter === '<>' || singleSubSubFilter === '>' || singleSubSubFilter === '>=' || singleSubSubFilter === '<' || singleSubSubFilter === '<=') {
                                                if (!filters[i][i2][i3 + 1])
                                                    filters[i][i2][i3 + 1] = 'NULL';
                                                else if (typeof filters[i][i2][i3 + 1] === 'string') {
                                                    filters[i][i2][i3 + 1] = '\'' + filters[i][i2][i3 + 1] + '\'';
                                                }
                                            }
                                            else if (singleSubSubFilter === '!') {
                                                filters[i][i2][i3] = 'NOT';
                                                singleSubSubFilter = 'NOT';
                                            }
                                            sqlFilter += ' ' + singleSubSubFilter + ' ';
                                        }
                                        else if (singleSubSubFilterType.toString() === 'object' && singleSubSubFilterIsDate) {
                                            sqlFilter += ' \'' + singleSubSubFilter.toISOString().split('T')[0] + '\' ';
                                        }
                                        else if (singleSubSubFilterType.toString() === 'number') {
                                            sqlFilter += ' ' + singleSubSubFilter.toString() + ' ';
                                        }
                                        else if (singleSubSubFilterType.toString() === 'boolean') {
                                            if (singleSubSubFilter.toString() === 'true')
                                                sqlFilter += '1 ';
                                            else if (singleSubSubFilter.toString() === 'false' || singleSubSubFilter === null || singleSubSubFilter === undefined)
                                                sqlFilter += '0 ';
                                        }
                                    }

                                    sqlFilter += ')';
                                }
                                else if (singleSubFilterType.toString() === 'string') {
                                    if (singleSubFilter === 'contains') {
                                        filters[i][i2] = 'LIKE';
                                        singleSubFilter = 'LIKE';

                                        filters[i][i2 + 1] = '\'%' + filters[i][i2 + 1] + '%\'';
                                    }
                                    else if (singleSubFilter === 'notcontains') {
                                        filters[i][i2] = 'NOT LIKE';
                                        singleSubFilter = 'NOT LIKE';

                                        filters[i][i2 + 1] = '\'%' + filters[i][i2 + 1] + '%\'';
                                    }
                                    else if (singleSubFilter === 'startswith') {
                                        filters[i][i2] = 'LIKE';
                                        singleSubFilter = 'LIKE';

                                        filters[i][i2 + 1] = '\'' + filters[i][i2 + 1] + '%\'';
                                    }
                                    else if (singleSubFilter === 'endswith') {
                                        filters[i][i2] = 'LIKE';
                                        singleSubFilter = 'LIKE';

                                        filters[i][i2 + 1] = '\'%' + filters[i][i2 + 1] + '\'';
                                    }
                                    else if (singleSubFilter === '=' || singleSubFilter === '<>' || singleSubFilter === '>' || singleSubFilter === '>=' || singleSubFilter === '<' || singleSubFilter === '<=') {
                                        if (!filters[i][i2 + 1])
                                            filters[i][i2 + 1] = 'NULL';
                                        else if (typeof filters[i][i2 + 1] === 'string') {
                                            filters[i][i2 + 1] = '\'' + filters[i][i2 + 1] + '\'';
                                        }
                                    }
                                    else if (singleSubFilter === '!') {
                                        filters[i][i2] = 'NOT';
                                        singleSubFilter = 'NOT';
                                    }
                                    sqlFilter += ' ' + singleSubFilter + ' ';
                                }
                                else if (singleSubFilterType.toString() === 'object' && singleSubFilterIsDate) {
                                    sqlFilter += ' \'' + singleSubFilter.toISOString().split('T')[0] + '\' ';
                                }
                                else if (singleSubFilterType.toString() === 'number') {
                                    sqlFilter += ' ' + singleSubFilter.toString() + ' ';
                                }
                                else if (singleSubFilterType.toString() === 'boolean') {
                                    if (singleSubFilter.toString() === 'true')
                                        sqlFilter += '1 ';
                                    else if (singleSubFilter.toString() === 'false' || singleSubFilter === null || singleSubFilter === undefined)
                                        sqlFilter += '0 ';
                                }
                            }

                            sqlFilter += ')';
                        }
                        else if (singleFilterType.toString() === 'string') {
                            if (singleFilter === 'contains') {
                                filters[i] = 'LIKE';
                                singleFilter = 'LIKE';

                                filters[i + 1] = '\'%' + filters[i + 1] + '%\'';
                            }
                            else if (singleFilter === 'notcontains') {
                                filters[i] = 'NOT LIKE';
                                singleFilter = 'NOT LIKE';

                                filters[i + 1] = '\'%' + filters[i + 1] + '%\'';
                            }
                            else if (singleFilter === 'startswith') {
                                filters[i] = 'LIKE';
                                singleFilter = 'LIKE';

                                filters[i + 1] = '\'' + filters[i + 1] + '%\'';
                            }
                            else if (singleFilter === 'endswith') {
                                filters[i] = 'LIKE';
                                singleFilter = 'LIKE';

                                filters[i + 1] = '\'%' + filters[i + 1] + '\'';
                            }
                            else if (singleFilter === '=' || singleFilter === '<>' || singleFilter === '>' || singleFilter === '>=' || singleFilter === '<' || singleFilter === '<=') {
                                if (!filters[i + 1])
                                    filters[i + 1] = 'NULL';
                                else if (typeof filters[i + 1] === 'string') {
                                    filters[i + 1] = '\'' + filters[i + 1] + '\'';
                                }
                            }
                            else if (singleFilter === '!') {
                                filters[i] = 'NOT';
                                singleFilter = 'NOT';
                            }
                            sqlFilter += ' ' + singleFilter + ' ';
                        }
                        else if (singleFilterType.toString() === 'object' && singleFilterIsDate) {
                            sqlFilter += ' \'' + singleFilter.toISOString().split('T')[0] + '\' ';
                        }
                        else if (singleFilterType.toString() === 'number') {
                            sqlFilter += ' ' + singleFilter.toString() + ' ';
                        }
                        else if (singleFilterType.toString() === 'boolean') {
                            if (singleFilter.toString() === 'true')
                                sqlFilter += '1 ';
                            else if (singleFilter.toString() === 'false' || singleFilter === null || singleFilter === undefined)
                                sqlFilter += '0 ';
                        }
                    }

                    sqlFilter += ')';
                }
            });

            async function getRecords() {
                if (sqlFilter === '(')
                    sqlFilter = '';

                var records = await getSalesOrderModRecords(sqlFilter);
                return {
                    data: records,
                    totalCount: records.length
                };
            }

            return getRecords()
                .then(function (result) {
                    return result;
                })
                .catch(() => { throw new Error('Data Loading Error'); });
        },
    });

    var fechaEmisionEditorOptions = { width: '100%', readOnly: true };
    var movimientoEditorOptions = { width: '100%', readOnly: true };
    var datosClienteEditorOptions = { width: '100%', readOnly: true };
    var estatusEditorOptions = { width: '100%', readOnly: true };
    var autorizacionEditorOptions = { width: '100%', readOnly: true };
    var cancelacionTotalEditorOptions = { width: '100%', readOnly: true };
    var saldoEditorOptions = { width: '100%', readOnly: true };
    var precioEditorOptions = { format: { type: 'currency', currency: 'MXN', precision: 2 }, width: '100%', disabled: !(salesOrderModPayload.header.SCUsuarioAutorizaPrecios && salesOrderModPayload.header.SCCodigoAutorizaPrecios) };
    var uppercaseEditorOptions = { inputAttr: { 'style': 'text-transform: uppercase' } };

    var usuarioDataSource = useMemo(() => {
        return new DataSource({
            paginate: true,
            store: {
                type: "array",
                key: "Usuario",
                data: usuarios
            }
        });
    }, [usuarios]);

    const situacionGastosAdminDataSource = [
        { name: 'SI', value: 'SI' },
        { name: 'NO', value: 'NO' }
    ]

    try {
        var fiscalRegimenEditorOptions = {
            dataSource: fiscalRegimen,
            displayExpr: 'name',
            valueExpr: 'value',
            searchEnabled: true,
            value: (salesOrderModPayload.customerData.FiscalRegimen ? salesOrderModPayload.customerData.FiscalRegimen : ''),
            width: '100%'
        };
    }
    catch (e) {
        var fiscalRegimenEditorOptions = {
            dataSource: fiscalRegimen,
            displayExpr: 'name',
            valueExpr: 'value',
            searchEnabled: true,
            value: '',
            width: '100%'
        };
    }

    try {
        var usoCFDIEditorOptions = {
            dataSource: filtroUsoCFDI,
            displayExpr: 'name',
            valueExpr: 'value',
            searchEnabled: true,
            acceptCustomValue: ((salesOrderModPayload.header.Estatus === 'Sin Afectar') ? false : true),
            value: (salesOrderModPayload.customerData.SATCatUsoCFDI ? salesOrderModPayload.customerData.SATCatUsoCFDI : ''),
            width: '100%'
        };
    }
    catch (e) {
        var usoCFDIEditorOptions = {
            dataSource: filtroUsoCFDI,
            displayExpr: 'name',
            valueExpr: 'value',
            acceptCustomValue: ((salesOrderModPayload.header.Estatus === 'Sin Afectar') ? false : true),
            searchEnabled: true,
            value: '',
            width: '100%'
        };
    }

    //Opciones Usuario Asociado del Usuario
    try {
        var usuarioAutorizaEditorOptions = useMemo(() => {
            return {
                dataSource: usuarioDataSource,
                displayExpr: 'name',
                valueExpr: 'value',
                searchEnabled: true,
                value: (salesOrderModPayload.header.SCUsuarioAutorizaPrecios ? salesOrderModPayload.header.SCUsuarioAutorizaPrecios : ''), width: '100%'
            };
        }, [usuarioDataSource, salesOrderModPayload.header.SCUsuarioAutorizaPrecios]);
    }
    catch (e) {
        var usuarioAutorizaEditorOptions = {
            dataSource: usuarioDataSource,
            displayExpr: 'name',
            valueExpr: 'value',
            searchEnabled: true,
            value: '',
            width: '100%'
        };
    }

    const storePedido = new CustomStore({
        key: 'value',
        load(loadOptions) {
            let sqlFilter = '';
            [
                'filter'
            ].forEach((i) => {
                sqlFilter = loadOptions.searchValue;
            });

            async function getRecords() {
                if (sqlFilter) {
                    var records = await getPedidos(sqlFilter);

                    return {
                        data: records,
                        totalCount: records.length
                    };
                }
                else {
                    return {
                        data: [],
                        totalCount: 0
                    };
                }
            }

            return getRecords()
                .then(function (result) {
                    return result;
                })
                .catch(() => { throw new Error('Data Loading Error'); });
        },
        byKey: (key) => {
            return {
                name: key,
                value: key
            };
        }
    });

    //Opciones del campo Pedido
    try {
        var pedidoEditorOptions = {
            dataSource: storePedido,
            displayExpr: 'name',
            valueExpr: 'value',
            searchEnabled: true,
            showClearButton: true,
            acceptCustomValue: !activateCustomSalesOrderField,
            value: (salesOrderModPayload.header.Pedido ? salesOrderModPayload.header.Pedido : ''),
            disabled: !activateCustomSalesOrderField,
            searchTimeout: 1500,
            width: '100%',
            clearButtonText: 'Cerrar',
            noDataText: 'No se encontraron resultados'
        };
    }
    catch (e) {
        var pedidoEditorOptions = {
            dataSource: storePedido,
            displayExpr: 'name',
            valueExpr: 'value',
            searchEnabled: true,
            acceptCustomValue: acceptCustomValues,
            value: '',
            width: '100%'
        };
    }

    const storeArticulo = new CustomStore({
        key: 'value',
        load(loadOptions) {
            let sqlFilter = '';
            [
                'filter'
            ].forEach((i) => {
                sqlFilter = loadOptions.searchValue;
            });

            async function getRecords() {
                if (sqlFilter) {
                    var records = await getArticulo(sqlFilter);

                    return {
                        data: records,
                        totalCount: records.length
                    };
                }
                else {
                    return {
                        data: [],
                        totalCount: 0
                    };
                }
            }

            return getRecords()
                .then(function (result) {
                    return result;
                })
                .catch(() => { throw new Error('Data Loading Error'); });
        },
        byKey: (key) => {
            async function getRecords(key) {
                var records = await getArticulo(key);

                return records;
            }

            return getRecords(key);
        }
    });

    //Opciones del campo Articulo
    try {
        var articuloEditorOptions = {
            dataSource: storeArticulo,
            displayExpr: 'name',
            valueExpr: 'value',
            searchEnabled: true,
            showClearButton: true,
            acceptCustomValue: false,
            disabled: false,
            searchTimeout: 1500,
            width: '100%',
            clearButtonText: 'Cerrar',
            noDataText: 'No se encontraron resultados'
        };
    }
    catch (e) {
        var articuloEditorOptions = {
            dataSource: storeArticulo,
            displayExpr: 'name',
            valueExpr: 'value',
            searchEnabled: true,
            acceptCustomValue: false,
            width: '100%'
        };
    }

    var situacionGastosAdminEditorOptions = {
        dataSource: situacionGastosAdminDataSource,
        displayExpr: 'name',
        valueExpr: 'value',
        searchEnabled: false,
        width: '100%'
    };

    var porcentajeGAEditorOptions = {
        format: 'percent'
    };

    async function uploadChunk(file, uploadInfo) {
        try {
            var responseS3 = await Storage.put("/mod/" + id + "/" + file.name, uploadInfo.chunkBlob, {
                contentType: file.type,
                ACL: 'public-read'
            });

            //Obtenemos el nombre del bucket
            let bucketName = Storage._config.AWSS3.bucket;


            let apiName = 'AdminSC';
            let path = '/uploadattachment';

            let myInit = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
                },
                body: {
                    action: 'register',
                    bucketName: bucketName,
                    type: 'mod',
                    id: id,
                    Key: "/mod/" + id + "/" + file.name,
                    fileName: file.name,
                    username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username
                }
            }

            var attachmentsResult = await API.post(apiName, path, myInit);

            //Obtenemos los attachments
            var attachmentsGet = await Storage.list("/mod/" + id);
            var attachmentsAux = [];

            for (var attachmentsGetIndex in attachmentsGet) {
                var singleAttachmentsGet = attachmentsGet[attachmentsGetIndex];

                var singleAttachment = {
                    name: singleAttachmentsGet.key.split('/').pop(),
                    isDirectory: false,
                    size: singleAttachmentsGet.size,
                };

                attachmentsAux.push(singleAttachment);
            }

            setAttachments(attachmentsAux);
        }
        catch (err) {
            console.log("Error uploading file:", err);
        }
    }

    async function onAttachmentDeleting(e) {
        var fileName = e.item.key;

        //Primero borramos el archivo de S3
        var attachmentsDelete = await Storage.remove("/mod/" + id + "/" + fileName);

        //Despues borramos el attachment en Intelisis
        let bucketName = Storage._config.AWSS3.bucket;

        let apiName = 'AdminSC';
        let path = '/uploadattachment';

        let myInit = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
            },
            body: {
                action: 'delete',
                bucketName: bucketName,
                type: 'mod',
                id: id,
                Key: "/mod/" + id + "/" + fileName,
                fileName: fileName,
                username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username
            }
        }

        var attachmentsResult = await API.post(apiName, path, myInit);

        //Finalmente sincronizamos el state
        var attachmentsGet = await Storage.list("/mod/" + id);
        var attachmentsAux = [];

        for (var attachmentsGetIndex in attachmentsGet) {
            var singleAttachmentsGet = attachmentsGet[attachmentsGetIndex];

            var singleAttachment = {
                name: singleAttachmentsGet.key.split('/').pop(),
                isDirectory: false,
                size: singleAttachmentsGet.size,
            };

            attachmentsAux.push(singleAttachment);
        }

        setAttachments(attachmentsAux);
    };

    function downloadBlob(blob, filename) {
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = filename || 'download';
        const clickHandler = () => {
            setTimeout(() => {
                URL.revokeObjectURL(url);
                a.removeEventListener('click', clickHandler);
            }, 150);
        };

        a.addEventListener('click', clickHandler, false);

        a.click();

        return a;
    };

    async function onItemDownloading(e) {
        e.cancel = true;

        const downloadFileResponse = await Storage.get("/mod/" + id + "/" + e.item.name, { download: true });

        downloadBlob(downloadFileResponse.Body, e.item.name);
    };

    function openBlob(blob, filename) {
        const url = URL.createObjectURL(blob);

        window.open(url, "_blank");
    };

    async function onAttachmentOpen(e) {
        const downloadFileResponse = await Storage.get("/mod/" + id + "/" + e.file.name, { download: true });

        openBlob(downloadFileResponse.Body, e.file.name);
    };

    async function getSalesOrderModPayload(ID) {
        try {
            let apiName = 'AdminSC';
            let path = '/procesamodpedido';

            let myInit = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
                },
                queryStringParameters: {
                    ID: ID,
                    action: 'payload',
                    username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username
                }
            }

            var payloadGet = await API.get(apiName, path, myInit);

            setSalesOrderModPayload(payloadGet);

            //Obtenemos los attachments
            var attachmentsGet = await Storage.list("/mod/" + ID);
            var attachmentsAux = [];

            for (var attachmentsGetIndex in attachmentsGet) {
                var singleAttachmentsGet = attachmentsGet[attachmentsGetIndex];

                var singleAttachment = {
                    name: singleAttachmentsGet.key.split('/').pop(),
                    isDirectory: false,
                    size: singleAttachmentsGet.size,
                };

                attachmentsAux.push(singleAttachment);
            }

            setAttachments(attachmentsAux);
        }
        catch (e) {
            if (e === 'No current user') {
                history.push('/');

                window.location.reload();
            }
        }

        return payloadGet;
    };

    async function getInvoicePayload(IDFactura) {
        try {
            let apiName = 'AdminSC';
            let path = '/procesaanticiposaldo';

            let myInit = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
                },
                body: {
                    action: 'invoicePayload',
                    invoices: [{ ID: IDFactura }],
                    username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username
                }
            };

            var payloadGet = await API.post(apiName, path, myInit);

            return payloadGet;
        }
        catch (e) {
            if (e === 'No current user') {
                history.push('/');

                window.location.reload();
            }
        }

        return payloadGet;
    };

    function editSalesOrderMod(data) {
        return (
            <Link to={`/SalesOrderMod/${data.data.ID}`}>
                <button class="bg-blue-500 hover:bg-blue-700 text-white  py-1 px-3 rounded" onClick={() => editButton(data.data.ID)}><FontAwesomeIcon icon={permissionLevel === 'Editar' ? faPencilAlt : faGlasses} /> {permissionLevel === 'Editar' ? 'Editar' : 'Ver'}</button>
            </Link>
        )
    }

    function openRelatedRecord(redirect) {
        if (redirect) {
            var baseUrl = window.location.href.split('#/')[0];

            redirect = baseUrl + '#' + redirect;

            window.open(redirect, "_blank");
        }
    }

    function openRelatedRecordButton(data) {
        return (
            <button onClick={() => openRelatedRecord(data.data.redirect)}><FontAwesomeIcon icon={faArrowUpRightFromSquare} /></button>
        )
    }

    function estatusRelatedRecords(data) {
        return (
            <TransactionStatus {...props} Estatus={data.data.Estatus} />
        )
    };

    async function generateInvoiceCFDiPDF(payload, payloadFactura) {
        var modulo = 'vtas';
        var type = 'invoice';

        try {
            // Despues enviamos el payload a la API crearPDF
            // *********************************************
            let apiName = 'AdminSC';
            let path = '/crearpdf';

            let myInit = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
                },
                body: {
                    type: type,
                    payload: payloadFactura,
                    bucketName: Storage._config.AWSS3.bucket,
                    fileKey: `public//${modulo}/${payload.header.IDFactura}/${payload.header.MovimientoFactura.trim()}.pdf`,
                    contentType: 'application/pdf',
                    username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username
                }
            }

            var pdfPost = await API.post(apiName, path, myInit);

            // *********************************************

            if (pdfPost.message.indexOf('Error') >= 0) {
                setVisibleToast(true);
                setMessageToast(pdfPost.message);
                setTypeToast('error');
                return;
            }
            else {
                return pdfPost;
            }
        }
        catch (e) {
            setVisibleLoadPanel(false);

            if (e === 'No current user') {
                history.push('/');

                window.location.reload();
            }
        }
    };

    function blobToBase64(blob) {
        return new Promise((resolve, _) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.readAsDataURL(blob);
        });
    };

    async function sendInvoiceCFDiFiles(hideToast, MovimientoFactura, IDFactura) {
        try {
            let apiName = 'AdminSC';
            let path = '/enviarcorreo';

            let pdfFile = await Storage.get("/vtas/" + IDFactura + "/" + MovimientoFactura.trim() + '.pdf', { download: true });
            let xmlFile = await Storage.get("/vtas/" + IDFactura + "/" + MovimientoFactura.trim() + '.xml', { download: true });

            if (!pdfFile || !xmlFile) {
                return;
            }

            if (!hideToast)
                setVisibleLoadPanel(true);

            let xmlFileContents = await blobToBase64(xmlFile.Body);
            let pdfFileContents = await blobToBase64(pdfFile.Body);

            xmlFileContents = xmlFileContents.toString().split('base64,')[1];
            pdfFileContents = pdfFileContents.toString().split('base64,')[1];

            if (!xmlFileContents || !pdfFileContents) {
                if (!hideToast)
                    setVisibleLoadPanel(false);

                setVisibleToast(true);
                setMessageToast('Archivos CFDi incorrectos');
                setTypeToast('error');
                return;
            }

            let myInit = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
                },
                body: {
                    ToAddresses: [salesOrderModPayload.header.cte_CorreoElectronico],
                    // BccAddresses: [],
                    // CcAddresses: [],
                    Body: 'CFDi ' + MovimientoFactura.trim(),
                    Subject: 'CFDi ' + MovimientoFactura.trim(),
                    Source: 'admin@supercolchones.com',
                    Attachments: [{
                        filename: MovimientoFactura.trim() + '.xml',
                        content: xmlFileContents,
                        encoding: 'base64'
                    },
                    {
                        filename: MovimientoFactura.trim() + '.pdf',
                        content: pdfFileContents,
                        encoding: 'base64'
                    }
                    ],
                    username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username
                }
            }

            var sendCFDiPost = await API.post(apiName, path, myInit);

            if (sendCFDiPost.message.indexOf('Error') >= 0) {
                if (!hideToast)
                    setVisibleLoadPanel(false);

                setVisibleToast(true);
                setMessageToast(sendCFDiPost.message);
                setTypeToast('error');
            }
            else {
                if (hideToast === false) {
                    setVisibleLoadPanel(false);

                    setVisibleToast(true);
                    setMessageToast(sendCFDiPost.message);
                    setTypeToast('success');
                }
            }

            return sendCFDiPost;
        }
        catch (e) {
            setVisibleToast(true);
            setMessageToast(e.message);
            setTypeToast('error');
        }
    }

    async function editButton(ID) {
        try {
            if (!window.event.metaKey && !window.event.ctrlKey) {

                setVisibleLoadPanel(true);

                setIsDetail(true);
                setId(ID);

                var payload = await getSalesOrderModPayload(ID);

                if (payload.header.Estatus === 'Sin Afectar' && payload.header.CancelacionTotal === false) {
                    // Obtenemos los Uso de CFDI Válido para el régimen fiscal
                    await getUsoCFDIValido(payload.customerData.FiscalRegimen);
                }

                if (payload.header.Pedido) {
                    setActivateCustomSalesOrderField(false);
                }
                else {
                    setActivateCustomSalesOrderField(true);
                }

                if (permissionLevel === 'Editar') {
                    if (payload.header.Estatus === 'Sin Afectar') {
                        if (payload.header.CancelacionTotal === true) {
                            setActivateUpdateForm(false);
                            setAcceptCustomValue(true);
                            setActivateSaveButton(false);
                            setActivateDeleteButton(false);
                            setActivateConfirmButton(true);
                            setActivateApproveButton(false);
                            setActivatePDFButton(true);
                            setActivateCancelButton(true);
                            setActivateAttachments(true);
                            setActivateModifyData(false);
                            setActivateModifyItems(true);
                            setActivateGetSalesOrderData(false);
                            setActivateUpdateGrid(false);
                            setActivateMoneyReturn(false);
                        }
                        else {
                            setActivateUpdateForm(true);
                            setAcceptCustomValue(false);
                            setActivateSaveButton(true);
                            setActivateDeleteButton(false);
                            setActivateConfirmButton(true);
                            setActivateApproveButton(false);
                            setActivatePDFButton(true);
                            setActivateCancelButton(true);
                            setActivateAttachments(true);
                            setActivateMoneyReturn(false);
                            setActivateModifyData(payload.header.ModificarDatos);
                            setActivateModifyItems(payload.header.ModificarMercancia);
                            setActivateGetSalesOrderData(true);
                            setActivateUpdateGrid(payload.header.ModificarMercancia);
                        }
                    }
                    else if (payload.header.Estatus === 'Pendiente') {
                        setActivateUpdateForm(false);
                        setAcceptCustomValue(true);
                        setActivateSaveButton(false);
                        setActivateDeleteButton(false);

                        if (payload.header.Autorizado === false && approveSalesOrderMod)
                            setActivateApproveButton(true);
                        else
                            setActivateApproveButton(false);

                        if (payload.header.Autorizado === true)
                            setActivateConfirmButton(true);
                        else
                            setActivateConfirmButton(false);

                        setActivatePDFButton(true);
                        setActivateCancelButton(true);
                        setActivateAttachments(false);


                        setActivateModifyData(payload.header.ModificarDatos);
                        setActivateModifyItems(payload.header.ModificarMercancia);
                        setActivateGetSalesOrderData(false);
                        setActivateUpdateGrid(false);
                        setActivateMoneyReturn(false);
                    }
                    else if (payload.header.Estatus === 'Cancelado') {
                        setActivateUpdateForm(false);
                        setAcceptCustomValue(true);
                        setActivateSaveButton(false);
                        setActivateDeleteButton(false);
                        setActivateConfirmButton(false);
                        setActivateApproveButton(false);
                        setActivatePDFButton(true);
                        setActivateCancelButton(false);
                        setActivateAttachments(false);
                        setActivateModifyData(payload.header.ModificarDatos);
                        setActivateModifyItems((payload.header.ModificarMercancia || payload.header.CancelacionTotal));
                        setActivateGetSalesOrderData(false);
                        setActivateUpdateGrid(false);
                        setActivateMoneyReturn(false);
                    }
                    else if (payload.header.Estatus === 'Concluido') {
                        setActivateUpdateForm(false);
                        setAcceptCustomValue(true);
                        setActivateSaveButton(false);
                        setActivateDeleteButton(false);
                        setActivateConfirmButton(false);
                        setActivateApproveButton(false);
                        setActivatePDFButton(true);
                        setActivateCancelButton(true);
                        setActivateAttachments(false);
                        setActivateModifyData(payload.header.ModificarDatos);
                        setActivateModifyItems((payload.header.ModificarMercancia || payload.header.CancelacionTotal));
                        setActivateGetSalesOrderData(false);
                        setActivateUpdateGrid(false);
                        setActivateMoneyReturn(false);
                    }
                    else if (payload.header.Estatus === 'Autorizado') {
                        setActivateUpdateForm(false);
                        setAcceptCustomValue(true);
                        setActivateSaveButton(false);
                        setActivateDeleteButton(false);
                        setActivateConfirmButton(false);
                        setActivateApproveButton(false);
                        setActivatePDFButton(true);
                        setActivateCancelButton(true);
                        setActivateAttachments(false);
                        setActivateModifyData(payload.header.ModificarDatos);
                        setActivateModifyItems((payload.header.ModificarMercancia || payload.header.CancelacionTotal));
                        setActivateGetSalesOrderData(false);
                        setActivateUpdateGrid(false);
                        setActivateMoneyReturn(true);
                    }
                }
                else if (permissionLevel === 'Ver') {
                    setActivateUpdateForm(false);
                    setAcceptCustomValue(true);
                    setActivateSaveButton(false);
                    setActivateDeleteButton(false);
                    setActivateConfirmButton(false);
                    setActivateApproveButton(false);
                    setActivatePDFButton(true);
                    setActivateCancelButton(false);
                    setActivateAttachments(false);
                    setActivateModifyData(payload.header.ModificarDatos);
                    setActivateModifyItems((payload.header.ModificarMercancia || payload.header.CancelacionTotal));
                    setActivateGetSalesOrderData(false);
                    setActivateUpdateGrid(false);
                    setActivateMoneyReturn(false);
                }

                setVisibleLoadPanel(false);
            }
        }
        catch (e) {
            setVisibleLoadPanel(false);

            if (!ID || ID === 'undefined') {
                newButton();
            }
        }
    };

    async function saveButton() {
        try {
            if (salesOrderModPayload.header.Estatus === 'Sin Afectar') {
                if (!salesOrderModPayload.header.Pedido || salesOrderModPayload.header.Pedido === '') {
                    setVisibleToast(true);
                    setMessageToast('Falta especificar el Pedido')
                    setTypeToast('error');
                    return;
                }
                if (!salesOrderModPayload.header.Motivo || salesOrderModPayload.header.Motivo === '') {
                    setVisibleToast(true);
                    setMessageToast('Falta especificar el Motivo')
                    setTypeToast('error');
                    return;
                }
            }

            setVisibleLoadPanel(true);

            var ID = salesOrderModPayload.header.ID;

            //Despues enviamos todo al backend
            let apiName = 'AdminSC';
            let path = '/procesamodpedido';

            let myInit = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
                },
                body: {
                    ID: ID,
                    payload: salesOrderModPayload,
                    action: 'saveChanges',
                    username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username,
                    usuario: props.usuario,
                    sucursal: props.sucursal,
                    s3Bucket: Storage._config.AWSS3.bucket
                }
            }

            var payloadPost = await API.post(apiName, path, myInit);

            if (payloadPost.ok === 'true') {
                if (!ID) {
                    setVisibleToast(true);
                    setMessageToast('Se creó la Solicitud de Modificación con éxito');
                    setTypeToast('success')

                    ID = payloadPost.newID;

                    editButton(ID);

                    history.push('/SalesOrderMod/' + ID.toString());
                }
                else {
                    setVisibleToast(true);
                    setMessageToast('Se actualizó la Solicitud de Modificación con éxito');
                    setTypeToast('success')
                }
            }
            else if (payloadPost.ok === 'false') {
                setVisibleToast(true);
                setMessageToast(payloadPost.message);
                setTypeToast('error')
            }

            editButton(ID);

            setVisibleLoadPanel(false);
        }
        catch (e) {
            setVisibleLoadPanel(false);

            if (e === 'No current user') {
                history.push('/');

                window.location.reload();
            }
        }
    };

    async function approveButton() {
        try {
            setVisibleLoadPanel(true);

            var ID = salesOrderModPayload.header.ID;

            //Despues enviamos todo al backend
            let apiName = 'AdminSC';
            let path = '/procesamodpedido';

            let myInit = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
                },
                body: {
                    ID: ID,
                    payload: salesOrderModPayload,
                    action: 'approve',
                    username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username,
                    usuario: props.usuario,
                    sucursal: props.sucursal
                }
            }

            var payloadPost = await API.post(apiName, path, myInit);

            if (payloadPost.ok === 'true') {
                setVisibleToast(true);
                setMessageToast('Solicitud de Modificación Autorizada');
                setTypeToast('success')
            }
            else if (payloadPost.ok === 'false') {
                setVisibleToast(true);
                setMessageToast(payloadPost.message);
                setTypeToast('error')
            }

            editButton(ID);

            setVisibleLoadPanel(false);
        }
        catch (e) {
            setVisibleLoadPanel(false);

            if (e === 'No current user') {
                history.push('/');

                window.location.reload();
            }
        }
    };

    async function confirmButton() {
        try {
            if (salesOrderModPayload.header.Estatus === 'Sin Afectar') {
                if (!salesOrderModPayload.header.Pedido || salesOrderModPayload.header.Pedido === '') {
                    setVisibleToast(true);
                    setMessageToast('Falta especificar el Pedido')
                    setTypeToast('error');
                    return;
                }
                if (!salesOrderModPayload.header.Motivo || salesOrderModPayload.header.Motivo === '') {
                    setVisibleToast(true);
                    setMessageToast('Falta especificar el Motivo')
                    setTypeToast('error');
                    return;
                }
            }

            setVisibleLoadPanel(true);

            var ID = salesOrderModPayload.header.ID;

            var lambdaFunction;

            if (window.location.href.indexOf('sandbox.') >= 0 || window.location.href.indexOf('localhost') >= 0)
                lambdaFunction = 'procesaModPedido-dev';
            else if (window.location.href.indexOf('sandboxtampico.') >= 0)
                lambdaFunction = 'procesaModPedido-devleona';
            else if (window.location.href.indexOf('tampico.') >= 0)
                lambdaFunction = 'procesaModPedido-prodleona';
            else
                lambdaFunction = 'procesaModPedido-prod';

            Auth.currentCredentials()
                .then(credentials => {
                    const lambda = new Lambda({
                        credentials: Auth.essentialCredentials(credentials),
                        region: 'us-east-1'
                    });

                    var params = {
                        FunctionName: lambdaFunction,
                        Payload: JSON.stringify({
                            ID: ID,
                            payload: salesOrderModPayload,
                            action: 'apply',
                            username: props.username,
                            usuario: props.usuario,
                            sucursal: props.sucursal
                        })
                    };

                    lambda.invoke(params, function (err, data) {
                        if (err) {
                            setVisibleLoadPanel(false);

                            setVisibleToast(true);
                            setMessageToast(err.message);
                            setTypeToast('error');
                        }
                        else {
                            var payloadPost = JSON.parse(JSON.parse(data.Payload).body);

                            if (payloadPost.ok === 'true') {
                                async function checkCFDiStatus() {
                                    //Primero obtenemos el payload actualizado
                                    var payload = await getSalesOrderModPayload(id);

                                    if (payload.header.IDFactura) {
                                        // Primero obtenemos el payload de la factura
                                        var payloadFactura = await getInvoicePayload(payload.header.IDFactura);

                                        // Después generamos el PDF
                                        var pdfPost = await generateInvoiceCFDiPDF(payload, payloadFactura);

                                        // Finalmente enviamos el correo electrónico
                                        var sendCFDiPost = await sendInvoiceCFDiFiles(true, payload.header.MovimientoFactura, payload.header.IDFactura);
                                    }

                                    setVisibleToast(true);
                                    setMessageToast('Sol. Modificación aplicada correctamente');
                                    setTypeToast('success');

                                    editButton(ID);

                                    setVisibleLoadPanel(false);
                                };

                                checkCFDiStatus();
                            }
                            else if (payloadPost.ok === 'false') {

                                setVisibleToast(true);

                                if (!payloadPost.message || payloadPost.message === '' || payloadPost.message === 'Error SQL')
                                    setMessageToast('No se pudo aplicar la Sol. Modificación');
                                else
                                    setMessageToast(payloadPost.message);

                                setTypeToast('error')

                                editButton(ID);

                                setVisibleLoadPanel(false);
                            }
                        }
                    });
                })

            /*
            //Despues enviamos todo al backend
            let apiName = 'AdminSC';
            let path = '/procesamodpedido';

            let myInit = { 
                headers: {
                    'Content-Type' : 'application/json',
                    Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
                },
                body: {
                    ID: ID,
                    payload: salesOrderModPayload,
                    action: 'apply',
                    username: props.username,
                    usuario: props.usuario,
                    sucursal: props.sucursal
                }
            }

            var payloadPost = await API.post(apiName, path, myInit);      

            if(payloadPost.ok === 'true'){
                setVisibleToast(true);
                setMessageToast('Se afectó la Solicitud de Modificación con éxito');
                setTypeToast('success')
            }
            else if(payloadPost.ok === 'false'){
                setVisibleToast(true);
                setMessageToast(payloadPost.message);
                setTypeToast('error')
            }

            editButton(ID);

            setVisibleLoadPanel(false);
            */
        }
        catch (e) {
            setVisibleLoadPanel(false);

            if (e === 'No current user') {
                history.push('/');

                window.location.reload();
            }
        }
    };

    async function cancelSalesOrder() {
        try {
            if (salesOrderModPayload.header.Estatus === 'Sin Afectar') {
                if (!salesOrderModPayload.header.Pedido || salesOrderModPayload.header.Pedido === '') {
                    setVisibleToast(true);
                    setMessageToast('Falta especificar el Pedido')
                    setTypeToast('error');
                    return;
                }
                if (!salesOrderModPayload.header.Motivo || salesOrderModPayload.header.Motivo === '') {
                    setVisibleToast(true);
                    setMessageToast('Falta especificar el Motivo')
                    setTypeToast('error');
                    return;
                }
            }

            if (!window.confirm('¿Desea cancelar totalmente el Pedido?'))
                return;

            setVisibleLoadPanel(true);

            var ID = salesOrderModPayload.header.ID;

            //Despues enviamos todo al backend
            let apiName = 'AdminSC';
            let path = '/procesamodpedido';

            let myInit = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
                },
                body: {
                    ID: ID,
                    payload: salesOrderModPayload,
                    action: 'cancelSalesOrder',
                    username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username,
                    usuario: props.usuario,
                    sucursal: props.sucursal
                }
            }

            var payloadPost = await API.post(apiName, path, myInit);

            if (payloadPost.ok === 'true') {
                setVisibleToast(true);
                setMessageToast('Información actualizada con éxito');
                setTypeToast('success')
            }
            else if (payloadPost.ok === 'false') {
                setVisibleToast(true);
                setMessageToast(payloadPost.message);
                setTypeToast('error')
            }

            editButton(ID);

            setVisibleLoadPanel(false);
        }
        catch (e) {
            setVisibleLoadPanel(false);

            if (e === 'No current user') {
                history.push('/');

                window.location.reload();
            }
        }
    };

    async function updateSalesOrderInfo() {
        try {
            if (salesOrderModPayload.header.Estatus === 'Sin Afectar') {
                if (!salesOrderModPayload.header.Pedido || salesOrderModPayload.header.Pedido === '') {
                    setVisibleToast(true);
                    setMessageToast('Falta especificar el Pedido')
                    setTypeToast('error');
                    return;
                }
                if (!salesOrderModPayload.header.Motivo || salesOrderModPayload.header.Motivo === '') {
                    setVisibleToast(true);
                    setMessageToast('Falta especificar el Motivo')
                    setTypeToast('error');
                    return;
                }
            }

            setVisibleLoadPanel(true);

            var ID = salesOrderModPayload.header.ID;

            //Despues enviamos todo al backend
            let apiName = 'AdminSC';
            let path = '/procesamodpedido';

            let myInit = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
                },
                body: {
                    ID: ID,
                    payload: salesOrderModPayload,
                    action: 'updateSalesOrderInfo',
                    username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username,
                    usuario: props.usuario,
                    sucursal: props.sucursal
                }
            }

            var payloadPost = await API.post(apiName, path, myInit);

            if (payloadPost.ok === 'true') {
                setVisibleToast(true);
                setMessageToast('Información actualizada con éxito');
                setTypeToast('success')
            }
            else if (payloadPost.ok === 'false') {
                setVisibleToast(true);
                setMessageToast(payloadPost.message);
                setTypeToast('error')
            }

            editButton(ID);

            setVisibleLoadPanel(false);
        }
        catch (e) {
            setVisibleLoadPanel(false);

            if (e === 'No current user') {
                history.push('/');

                window.location.reload();
            }
        }
    }

    async function cancelEditButton() {
        setIsDetail(false);
    };

    async function voidButton() {
        try {
            if (salesOrderModPayload.header.Estatus === 'Sin Afectar') {
                if (!salesOrderModPayload.header.Pedido || salesOrderModPayload.header.Pedido === '') {
                    setVisibleToast(true);
                    setMessageToast('Falta especificar el Pedido')
                    setTypeToast('error');
                    return;
                }
                if (!salesOrderModPayload.header.Motivo || salesOrderModPayload.header.Motivo === '') {
                    setVisibleToast(true);
                    setMessageToast('Falta especificar el Motivo')
                    setTypeToast('error');
                    return;
                }
            }

            if (!window.confirm('¿Desea cancelar la Solicitud de Modificación?'))
                return;

            setVisibleLoadPanel(true);

            var ID = salesOrderModPayload.header.ID;

            //Despues enviamos todo al backend
            let apiName = 'AdminSC';
            let path = '/procesamodpedido';

            let myInit = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
                },
                body: {
                    ID: ID,
                    payload: salesOrderModPayload,
                    action: 'void',
                    username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username,
                    usuario: props.usuario,
                    sucursal: props.sucursal
                }
            }

            var payloadPost = await API.post(apiName, path, myInit);

            if (payloadPost.ok === 'true') {
                setVisibleToast(true);
                setMessageToast('Solicitud de Modificación cancelada con éxito');
                setTypeToast('success')
            }
            else if (payloadPost.ok === 'false') {
                setVisibleToast(true);
                setMessageToast(payloadPost.message);
                setTypeToast('error')
            }

            editButton(ID);

            setVisibleLoadPanel(false);
        }
        catch (e) {
            setVisibleLoadPanel(false);

            if (e === 'No current user') {
                history.push('/');

                window.location.reload();
            }
        }
    };

    function refreshButton() {
        if (gridTableroRef.current) {
            var gridTablero = gridTableroRef.current.instance;

            gridTablero.refresh();
        }
    }

    async function newButton(salesorder) {
        try {
            let apiName = 'AdminSC';
            let path = '/procesamodpedido';

            let myInit = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
                },
                queryStringParameters: {
                    action: 'new',
                    salesorder: salesorder,
                    username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username,
                    usuario: props.usuario,
                    sucursal: props.sucursal
                }
            };

            var payloadNew = await API.get(apiName, path, myInit);

            setSalesOrderModPayload(payloadNew);

            setAttachments([]);

            setIsDetail(true);
            setId(undefined);

            setActivateUpdateForm(true);
            setActivateCustomSalesOrderField(true);
            setAcceptCustomValue(false);
            setActivateSaveButton(true);
            setActivateDeleteButton(false);
            setActivateCancelButton(false);
            setActivateConfirmButton(false);
            setActivateApproveButton(false);
            setActivatePDFButton(false);
            setActivateAttachments(false);
            setActivateModifyData(false);
            setActivateModifyItems(false);
            setActivateGetSalesOrderData(false);
            setActivateUpdateGrid(false);
            setActivateMoneyReturn(false);

            setVisibleLoadPanel(false);
        }
        catch (e) {
            if (e === 'No current user') {
                history.push('/');

                window.location.reload();
            }
        }
    };

    async function deleteButton() {
    }

    async function deleteItemButton(Renglon) {
        try {
            if (salesOrderModPayload.header.Estatus === 'Sin Afectar') {
                if (!salesOrderModPayload.header.Pedido || salesOrderModPayload.header.Pedido === '') {
                    setVisibleToast(true);
                    setMessageToast('Falta especificar el Pedido')
                    setTypeToast('error');
                    return;
                }
                if (!salesOrderModPayload.header.Motivo || salesOrderModPayload.header.Motivo === '') {
                    setVisibleToast(true);
                    setMessageToast('Falta especificar el Motivo')
                    setTypeToast('error');
                    return;
                }
            }

            setVisibleLoadPanel(true);

            var ID = salesOrderModPayload.header.ID;

            //Despues enviamos todo al backend
            let apiName = 'AdminSC';
            let path = '/procesamodpedido';

            let myInit = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
                },
                body: {
                    ID: ID,
                    payload: salesOrderModPayload,
                    action: 'deleteItem',
                    username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username,
                    usuario: props.usuario,
                    sucursal: props.sucursal,
                    renglon: Renglon
                }
            }

            var payloadPost = await API.post(apiName, path, myInit);

            if (payloadPost.ok === 'true') {
                setVisibleToast(true);
                setMessageToast('Se eliminó el artículo con éxito');
                setTypeToast('success')
            }
            else if (payloadPost.ok === 'false') {
                setVisibleToast(true);
                setMessageToast(payloadPost.message);
                setTypeToast('error')
            }

            editButton(ID);

            setVisibleLoadPanel(false);
        }
        catch (e) {
            setVisibleLoadPanel(false);

            if (e === 'No current user') {
                history.push('/');

                window.location.reload();
            }
        }
    }

    async function updateItemButton(Renglon, Articulo, Cantidad, Precio, RenglonID, SCSerieLote) {
        setAddItemPayload({
            Action: 'updateItem',
            Renglon: Renglon,
            RenglonID: RenglonID,
            SCSerieLote: SCSerieLote,
            Articulo: Articulo.trim(),
            Cantidad: Cantidad,
            Precio: Precio
        });

        setPopupTitle('Modificar Artículo');
        setPopupVisible(true);
    };

    function printButton() {
        var baseUrl = window.location.href.split('/')[0];

        window.open(baseUrl + "/#/PdfRender?type=salesordermod&id=" + id, "_blank");
    };

    async function addItemButton() {
        setAddItemPayload({
            Action: 'addItem',
            Renglon: undefined,
            RenglonID: undefined,
            SCSerieLote: undefined,
            Articulo: undefined,
            Cantidad: undefined,
            Precio: undefined
        });

        setPopupTitle('Agregar Artículo');
        setPopupVisible(true);
    };

    async function confirmAddButton() {
        try {
            var Articulo = addItemPayload.Articulo;
            var Precio = addItemPayload.Precio;
            var Cantidad = addItemPayload.Cantidad;
            var Action = addItemPayload.Action;
            var Renglon = addItemPayload.Renglon;
            var RenglonID = addItemPayload.RenglonID;
            var SCSerieLote = addItemPayload.SCSerieLote;

            if (salesOrderModPayload.header.Estatus === 'Sin Afectar') {
                if (!salesOrderModPayload.header.Pedido || salesOrderModPayload.header.Pedido === '') {
                    setVisibleToast(true);
                    setMessageToast('Falta especificar el Pedido')
                    setTypeToast('error');
                    return;
                }
                if (!salesOrderModPayload.header.Motivo || salesOrderModPayload.header.Motivo === '') {
                    setVisibleToast(true);
                    setMessageToast('Falta especificar el Motivo')
                    setTypeToast('error');
                    return;
                }
                if (!Articulo) {
                    setVisibleToast(true);
                    setMessageToast('Falta especificar el Artículo')
                    setTypeToast('error');
                    return;
                }
                if (!Precio && Precio !== 0) {
                    setVisibleToast(true);
                    setMessageToast('Falta especificar el Precio')
                    setTypeToast('error');
                    return;
                }
                if (!Cantidad) {
                    setVisibleToast(true);
                    setMessageToast('Falta especificar la Cantidad')
                    setTypeToast('error');
                    return;
                }
                if (Action === 'updateItem' && (!Renglon || !RenglonID || !SCSerieLote)) {
                    setVisibleToast(true);
                    setMessageToast('Datos Incorrectos')
                    setTypeToast('error');
                    return;
                }
            }

            setVisibleLoadPanel(true);

            var ID = salesOrderModPayload.header.ID;

            //Despues enviamos todo al backend
            let apiName = 'AdminSC';
            let path = '/procesamodpedido';

            let myInit = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
                },
                body: {
                    ID: ID,
                    payload: salesOrderModPayload,
                    action: Action,
                    username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username,
                    usuario: props.usuario,
                    sucursal: props.sucursal,
                    articulo: Articulo,
                    precio: Precio,
                    cantidad: Cantidad,
                    renglon: Renglon,
                    renglonid: RenglonID,
                    scserielote: SCSerieLote
                }
            }

            var payloadPost = await API.post(apiName, path, myInit);

            if (payloadPost.ok === 'true') {
                setVisibleToast(true);

                if (Action === 'addItem')
                    setMessageToast('Se agregó el artículo con éxito');
                else if (Action === 'updateItem')
                    setMessageToast('Se actualizó el artículo con éxito');
                setTypeToast('success')
            }
            else if (payloadPost.ok === 'false') {
                setVisibleToast(true);
                setMessageToast(payloadPost.message);
                setTypeToast('error')
            }

            setPopupVisible(false);

            editButton(ID);

            setVisibleLoadPanel(false);
        }
        catch (e) {
            setVisibleLoadPanel(false);

            if (e === 'No current user') {
                history.push('/');

                window.location.reload();
            }
        }
    };

    async function confirmCheckButton() {
        try {
            var SituacionGastosAdmin = createCheckPayload.SituacionGastosAdmin;
            var PorcentajeGA = createCheckPayload.PorcentajeGA;

            if (SituacionGastosAdmin === 'NO')
                PorcentajeGA = 0;
            else if (SituacionGastosAdmin === 'SI') {
                if (PorcentajeGA > 1.0 || PorcentajeGA === 0 || PorcentajeGA < 0) {
                    setVisibleToast(true);
                    setMessageToast('Porcentaje incorrecto')
                    setTypeToast('error');
                    return;
                }
            }

            setVisibleLoadPanel(true);

            var ID = salesOrderModPayload.header.ID;

            //Despues enviamos todo al backend
            let apiName = 'AdminSC';
            let path = '/procesamodpedido';

            let myInit = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
                },
                body: {
                    ID: ID,
                    payload: salesOrderModPayload,
                    action: 'createCheck',
                    username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username,
                    usuario: props.usuario,
                    sucursal: props.sucursal,
                    situaciongastosadmin: SituacionGastosAdmin,
                    porcentajega: PorcentajeGA
                }
            }

            var payloadPost = await API.post(apiName, path, myInit);

            if (payloadPost.ok === 'true') {
                setVisibleToast(true);
                setMessageToast('Se generó el cheque con éxito');
                setTypeToast('success')
            }
            else if (payloadPost.ok === 'false') {
                setVisibleToast(true);
                setMessageToast(payloadPost.message);
                setTypeToast('error')
            }

            setPopupVisible(false);

            editButton(ID);

            setVisibleLoadPanel(false);
        }
        catch (e) {
            setVisibleLoadPanel(false);

            if (e === 'No current user') {
                history.push('/');

                window.location.reload();
            }
        }
    };

    async function getUsuario() {
        let apiName = 'AdminSC';
        let path = '/getcatalogo';

        let myInit = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
            },
            queryStringParameters: {
                type: 'Usuario',
                username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username
            }
        }

        var usuariosGet = await API.get(apiName, path, myInit);

        setUsuarios(usuariosGet);
    };

    async function getArticulo(sqlFilter) {
        let apiName = 'AdminSC';
        let path = '/getcatalogo';

        let myInit = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
            },
            queryStringParameters: {
                type: 'Articulo',
                username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username,
                sqlFilter: sqlFilter
            }
        }

        var articulosGet = await API.get(apiName, path, myInit);

        return articulosGet;
    };

    async function getPedidos(sqlFilter) {
        let apiName = 'AdminSC';
        let path = '/getcatalogo';

        let myInit = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
            },
            queryStringParameters: {
                type: 'PedidoSolModificacion',
                username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username,
                sqlFilter: sqlFilter
            }
        }

        var pedidosGet = await API.get(apiName, path, myInit);
        var tmpPedidos = [];

        for (var pedidosGetIndex in pedidosGet) {

            tmpPedidos.push({
                name: pedidosGet[pedidosGetIndex].SCRama,
                value: pedidosGet[pedidosGetIndex].SCRama
            })
        }

        return tmpPedidos;
    };

    async function getPrice(ID, Articulo) {
        let apiName = 'AdminSC';
        let path = '/getcatalogo';

        let myInit = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
            },
            queryStringParameters: {
                type: 'ObtenerPrecioArticuloMod',
                ID: ID,
                Articulo: Articulo,
                username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username
            }
        }

        var priceGet = await API.get(apiName, path, myInit);

        return priceGet;
    };

    async function getFiscalRegimen(sqlFilter) {
        let apiName = 'AdminSC';
        let path = '/getcatalogo';

        let myInit = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
            },
            queryStringParameters: {
                type: 'FiscalRegimen',
                username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username,
                sqlFilter: sqlFilter
            }
        }

        var fiscalRegimenGet = await API.get(apiName, path, myInit);

        setFiscalRegimen(fiscalRegimenGet);
    };

    async function getUsoCFDIValido(FiscalRegimen) {
        setVisibleLoadPanel(true);

        var tmpUsoCFDI = [];
        let apiName = 'AdminSC';
        let path = '/getcatalogo';

        let myInit = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
            },
            queryStringParameters: {
                type: 'UsoCFDI',
                username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username
            }
        }

        var usoCFDIGet = await API.get(apiName, path, myInit);

        for (var usoCFDIIndex in usoCFDIGet) {
            var singleUsoCFDI = usoCFDIGet[usoCFDIIndex];

            if (singleUsoCFDI.FiscalRegimen === FiscalRegimen) {
                tmpUsoCFDI.push({
                    name: singleUsoCFDI.ClaveUsoCFDI + ' - ' + singleUsoCFDI.Descripcion,
                    value: singleUsoCFDI.ClaveUsoCFDI
                })
            }
        }

        setFiltroUsoCFDI(tmpUsoCFDI);

        setVisibleLoadPanel(false);
    };

    async function createCoupon() {
        try {
            if (salesOrderModPayload.header.Estatus === 'Sin Afectar') {
                if (!salesOrderModPayload.header.Pedido || salesOrderModPayload.header.Pedido === '') {
                    setVisibleToast(true);
                    setMessageToast('Falta especificar el Pedido')
                    setTypeToast('error');
                    return;
                }
                if (!salesOrderModPayload.header.Motivo || salesOrderModPayload.header.Motivo === '') {
                    setVisibleToast(true);
                    setMessageToast('Falta especificar el Motivo')
                    setTypeToast('error');
                    return;
                }
            }

            setVisibleLoadPanel(true);

            var ID = salesOrderModPayload.header.ID;

            //Despues enviamos todo al backend
            let apiName = 'AdminSC';
            let path = '/procesamodpedido';

            let myInit = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
                },
                body: {
                    ID: ID,
                    payload: salesOrderModPayload,
                    action: 'createCoupon',
                    username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username,
                    usuario: props.usuario,
                    sucursal: props.sucursal
                }
            }

            var payloadPost = await API.post(apiName, path, myInit);

            if (payloadPost.ok === 'true') {
                setVisibleToast(true);
                setMessageToast('Se creó el Vale con éxito');
                setTypeToast('success')
            }
            else if (payloadPost.ok === 'false') {
                setVisibleToast(true);
                setMessageToast(payloadPost.message);
                setTypeToast('error')
            }

            editButton(ID);

            setVisibleLoadPanel(false);
        }
        catch (e) {
            setVisibleLoadPanel(false);

            if (e === 'No current user') {
                history.push('/');

                window.location.reload();
            }
        }
    };

    async function createCheck() {
        setCreateCheckPayload({
            Action: 'createCheck',
            SituacionGastosAdmin: 'NO',
            PorcentajeGA: 0
        });

        setPopupCheckVisible(true);
    };


    useEffect(() => {
        window.addEventListener('popstate', (e) => {
            var hash;

            try {
                hash = e.target.location.hash;
            }
            catch (e) {
                hash = '';
            }

            if (hash === '#/SalesOrderMod') {
                history.push('/SalesOrderMod');

                window.location.reload();
            }
        });

        async function initialize() {
            setVisibleLoadPanel(true);

            await getFiscalRegimen();

            await getUsuario();

            setVisibleLoadPanel(false);
        }

        initialize();

        //En caso de solicitar un ID especifico, redireccionamos a ese ID
        if (!props.match.isExact) {
            var urlID = props.location.pathname.replace('/SalesOrderMod/', '');

            var search = props.location.search;
            var salesorder;

            if (search)
                salesorder = search.replace('?salesorder=', '');

            if (urlID === 'undefined')
                urlID = undefined;

            if (urlID)
                editButton(urlID);
            else
                newButton(salesorder);
        }

        // eslint-disable-next-line
    }, []);

    function overrideOnValueChanged(e) {
        //Hacemos la data persistente en cuanto el usuario escriba en el campo
        // if (e.parentType === "dataRow") {
        //     e.editorOptions.valueChangeEvent = "input";
        // }
    }

    function onHidingToast() {
        setVisibleToast(false);
    };

    function onFieldDatosModificacionChanged(e) {
        var dataField = e.dataField;
        var value = e.value;


        if (formDatosGeneralesRef.current) {
            var formDatosGenerales = formDatosGeneralesRef.current.instance;

            if (e.dataField === 'header.Pedido') {
                if (e.value) {
                    async function getSalesOrderInfo() {
                        try {
                            setVisibleLoadPanel(true);

                            let apiName = 'AdminSC';
                            let path = '/procesamodpedido';

                            let myInit = {
                                headers: {
                                    'Content-Type': 'application/json',
                                    Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
                                },
                                queryStringParameters: {
                                    action: 'getSalesOrderInfo',
                                    salesOrder: value,
                                    username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username
                                }
                            };

                            var payloadNew = await API.get(apiName, path, myInit);

                            formDatosGenerales.updateData('header.Cliente', payloadNew.header.Cliente);

                            setVisibleLoadPanel(false);
                        }
                        catch (e) {
                            if (e === 'No current user') {
                                history.push('/');

                                window.location.reload();
                            }
                        }
                    }

                    getSalesOrderInfo();
                }
                else {
                    formDatosGenerales.updateData('header.Cliente', undefined);
                }
            }
            else if (e.dataField === 'customerData.FiscalRegimen') {
                formDatosGenerales.updateData('customerData.SATCatUsoCFDI', undefined);

                async function getUsoCFDI(FiscalRegimen) {
                    await getUsoCFDIValido(FiscalRegimen);
                }

                if (e.value) {
                    getUsoCFDI(e.value);
                }
            }
        }
    };

    function onFieldAddItemChanged(e) {
        var dataField = e.dataField;
        var value = e.value;


        if (formAddItemRef.current) {
            var formAddItem = formAddItemRef.current.instance;

            if (e.dataField === 'Articulo') {
                if (e.value) {
                    async function getItemPrice() {
                        try {
                            setVisibleLoadPanel(true);

                            let apiName = 'AdminSC';
                            let path = '/getcatalogo';

                            let myInit = {
                                headers: {
                                    'Content-Type': 'application/json',
                                    Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
                                },
                                queryStringParameters: {
                                    type: 'ObtenerPrecioArticuloMod',
                                    ID: id,
                                    Articulo: e.value,
                                    username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username
                                }
                            };

                            var price = await API.get(apiName, path, myInit);

                            formAddItem.updateData('Precio', price[0].Price);

                            setVisibleLoadPanel(false);
                        }
                        catch (e) {
                            if (e === 'No current user') {
                                history.push('/');

                                window.location.reload();
                            }
                        }
                    }

                    getItemPrice();
                }
                else {
                    formAddItem.updateData('Precio', undefined);

                    setVisibleLoadPanel(false);
                }
            }
        }
    }

    function deleteItem(data) {
        if (permissionLevel === 'Editar' && data.data.VentaDRenglon && salesOrderModPayload.header.Estatus === 'Sin Afectar') {
            return (
                <button class="bg-red-500 hover:bg-red-700 text-white  py-1 px-3 rounded" onClick={() => deleteItemButton(data.data.Renglon)}><FontAwesomeIcon icon={faTrash} /></button>
            );
        }
    };

    function updateItem(data) {
        if (permissionLevel === 'Editar' && data.data.VentaDRenglon && salesOrderModPayload.header.Estatus === 'Sin Afectar')
            return (
                <button class="bg-blue-500 hover:bg-blue-700 text-white py-1 px-3 rounded" onClick={() => updateItemButton(data.data.Renglon, data.data.Articulo, data.data.Cantidad, data.data.Precio, data.data.RenglonID, data.data.SCSerieLote)}><FontAwesomeIcon icon={faPenFancy} /></button>
            )
    };

    function hidePopupInfo() {
        setPopupVisible(false);
    };

    function hidePopupCheckInfo() {
        setPopupCheckVisible(false);
    };

    function onExporting(e) {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Datos');

        exportDataGrid({
            component: e.component,
            worksheet,
            autoFilterEnabled: true,
        }).then(() => {
            workbook.xlsx.writeBuffer().then((buffer) => {
                saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Datos.xlsx');
            });
        });
        e.cancel = true;
    };

    return (
        <div>
            {isDetail === false ? (
                <div class="bg-gray-200 m-2">
                    <h1 class="font-sans inline-block text-xl font-bold text-gray-700 leading-8 align-top mb-1"><FontAwesomeIcon icon={faList} size="lg" /> Solicitudes de Modificación</h1>
                </div>
            ) :
                (
                    <div class="bg-gray-200 m-2">
                        <h1 class="font-sans inline-block text-xl font-bold text-gray-700 leading-8 align-top mb-1"><FontAwesomeIcon icon={faPenNib} size="xl" /> {(salesOrderModPayload.header ? salesOrderModPayload.header.Movimiento : 'Sol Modificación')}</h1>
                        <TransactionStatus {...props} Estatus={salesOrderModPayload.header.Estatus} />
                    </div>
                )}

            <LoadPanel
                shadingColor="rgba(0,0,0,0.4)"
                visible={visibleLoadPanel}
                showIndicator={true}
                message="Por favor espere..."
                shading={true}
                showPane={true}
                hideOnOutsideClick={false}
            />

            <Toast
                visible={visibleToast}
                message={messageToast}
                type={typeToast}
                onHiding={onHidingToast}
                displayTime={5000}
                width={'auto'}
                position={{
                    my: 'top center',
                    at: 'top center',
                    of: window,
                    offset: '0 130'
                }}
            />

            <div class="bg-gray-200 m-2">
                {isDetail === false ? (
                    <div class="bg-white p-3 shadow-xl">

                        {permissionLevel === 'Editar' ?
                            (
                                <div>
                                    <Link to="/SalesOrderMod/undefined">
                                        <button class="mb-2 bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded" onClick={() => newButton()}><FontAwesomeIcon icon={faFileAlt} /> Nueva Sol. Modificación</button>
                                    </Link>

                                    <button onClick={refreshButton} type="submit" class="ml-4 mb-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                                        <FontAwesomeIcon icon={faArrowsRotate} /> Actualizar
                                    </button>
                                </div>
                            ) :
                            (
                                <div>
                                    <button onClick={refreshButton} type="submit" class="mb-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                                        <FontAwesomeIcon icon={faArrowsRotate} /> Actualizar
                                    </button>
                                </div>
                            )}

                        <div className="options mb-2">
                            <div className="option">
                                <CheckBox text="Expandir todos los grupos"
                                    value={autoExpandAll}
                                    onValueChanged={onAutoExpandAllChanged}></CheckBox>
                            </div>
                        </div>
                        <DataGrid
                            height={800}
                            id="gridContainer"
                            columnsAutoWidth={true}
                            filterBuilder={filterBuilder}
                            dataSource={store}
                            defaultFilterValue={filterValue}
                            allowColumnReordering={true}
                            allowColumnResizing={true}
                            columnResizingMode={"widget"}
                            showBorders={true}
                            rowAlternationEnabled={true}
                            showRowLines={true}
                            showColumnLines={true}
                            remoteOperations={{ filtering: true }}
                            ref={gridTableroRef}
                            onExporting={onExporting}
                        >
                            <Scrolling rowRenderingMode='virtual'></Scrolling>
                            <Paging defaultPageSize={10} />
                            <Pager
                                visible={true}
                                allowedPageSizes={allowedPageSizes}
                                displayMode='compact'
                                showPageSizeSelector={true}
                                showInfo={true}
                                showNavigationButtons={true}
                            />
                            <FilterRow visible={true} />
                            <FilterPanel visible={true} />
                            <FilterBuilderPopup position={filterBuilderPopupPosition} />

                            <HeaderFilter visible={true} />

                            <GroupPanel visible={true} />
                            <SearchPanel visible={true} />
                            <ColumnChooser
                                enabled={true}
                                mode='select'
                            >
                                <ColumnChooserSearch
                                    enabled={true}
                                />

                                <ColumnChooserSelection
                                    allowSelectAll={true}
                                    selectByClick={true}
                                    recursive={true}
                                />
                            </ColumnChooser>
                            <Grouping autoExpandAll={autoExpandAll} />
                            <StateStoring enabled={true} type="localStorage" storageKey="gridContainerSalesOrderMod" />
                            <Export enabled={true} />

                            <Column caption="" alignment="center" width={100} cellRender={editSalesOrderMod} />

                            <Column dataField="MovID" dataType="string" width={110} caption="Folio" />
                            <Column dataField="FechaEmision" caption="Fecha Emisión" dataType="date" width={130} />
                            <Column dataField="Pedido" dataType="string" width={120} />
                            <Column dataField="Estatus" dataType="string" width={120} />
                            <Column dataField="Plaza" caption="Plaza" dataType="string" width={150} visible={false} />

                            <Column dataField="ID" dataType="number" width={150} visible={false} />
                            <Column dataField="Mov" dataType="string" width={190} caption="Tipo Movimiento" visible={false} />
                            <Column dataField="Movimiento" dataType="string" width={190} visible={false} />

                            <Column dataField="Sucursal" dataType="number" width={150} visible={false} />
                            <Column dataField="NombreSucursal" dataType="string" width={190} visible={false} />
                            <Column dataField="Usuario" dataType="string" width={190} visible={false} />
                            <Column dataField="NombreUsuario" dataType="string" width={190} visible={false} />

                            <Column dataField="ModificarDatos" dataType="boolean" width={190} visible={false} />
                            <Column dataField="ModificarMercancia" dataType="boolean" width={190} visible={false} />
                            <Column dataField="CancelacionTotal" dataType="boolean" width={190} visible={false} />
                            <Column dataField="SituacionSaldo" dataType="string" width={190} visible={false} />
                            <Column dataField="SituacionGastosAdmin" dataType="string" width={190} visible={false} />
                            <Column dataField="Vale" dataType="string" width={190} visible={false} />
                            <Column dataField="Cheque" dataType="string" width={190} visible={false} />
                        </DataGrid>
                    </div>
                ) : (<div />)}
                <Route exact path="/SalesOrderMod/:ID" render={({ match }) => {
                    return (
                        <div class="bg-gray-200">
                            {isDetail.toString() === 'true' ?
                                (
                                    <div>
                                        <Link to="/SalesOrderMod">
                                            <button onClick={cancelEditButton} type="submit" class="mb-4 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
                                                <FontAwesomeIcon icon={faArrowRotateLeft} /> Regresar
                                            </button>
                                        </Link>

                                        {activateSaveButton.toString() === 'true' ?
                                            (
                                                <button onClick={saveButton} type="submit" class="ml-4 mb-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                                                    <FontAwesomeIcon icon={faSave} /> Guardar Cambios
                                                </button>
                                            ) : (
                                                <div class="inline-block"></div>
                                            )}

                                        {activateConfirmButton.toString() === 'true' && id ?
                                            (
                                                <button onClick={confirmButton} type="submit" class="ml-4 mb-4 bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">
                                                    <FontAwesomeIcon icon={faPlay} /> Afectar
                                                </button>
                                            ) : (
                                                <div class="inline-block"></div>
                                            )}

                                        {activateApproveButton.toString() === 'true' && id ?
                                            (
                                                <button onClick={approveButton} type="submit" class="ml-4 mb-4 bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">
                                                    <FontAwesomeIcon icon={faThumbsUp} /> Autorizar
                                                </button>
                                            ) : (
                                                <div class="inline-block"></div>
                                            )}

                                        {activateGetSalesOrderData.toString() === 'true' && id ?
                                            (
                                                <button onClick={updateSalesOrderInfo} type="submit" class="ml-4 mb-4 bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-2 px-4 rounded">
                                                    <FontAwesomeIcon icon={faLightbulb} /> Obtener Información
                                                </button>
                                            ) :
                                            (
                                                <div class="inline-block"></div>
                                            )}

                                        {activateGetSalesOrderData.toString() === 'true' && id ?
                                            (
                                                <button onClick={cancelSalesOrder} type="submit" class="ml-4 mb-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                                                    <FontAwesomeIcon icon={faThumbsDown} /> Cancelación Total
                                                </button>
                                            ) :
                                            (
                                                <div class="inline-block"></div>
                                            )}

                                        {activateGetSalesOrderData.toString() === 'true' && id ?
                                            (
                                                <button onClick={addItemButton} type="submit" class="ml-4 mb-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                                                    <FontAwesomeIcon icon={faCartPlus} /> Agregar Artículo
                                                </button>
                                            ) :
                                            (
                                                <div class="inline-block"></div>
                                            )}

                                        {activateMoneyReturn.toString() === 'true' && id ?
                                            (
                                                <button onClick={createCoupon} type="submit" class="ml-4 mb-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                                                    <FontAwesomeIcon icon={faBadgeDollar} /> Crear Vale
                                                </button>
                                            ) :
                                            (
                                                <div class="inline-block"></div>
                                            )}

                                        {activateMoneyReturn.toString() === 'true' && id ?
                                            (
                                                <button onClick={createCheck} type="submit" class="ml-4 mb-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                                                    <FontAwesomeIcon icon={faMoneyCheckDollar} /> Crear Cheque
                                                </button>
                                            ) :
                                            (
                                                <div class="inline-block"></div>
                                            )}


                                        {activateDeleteButton.toString() === 'true' && id ?
                                            (
                                                <button onClick={deleteButton} type="submit" class="ml-4 mb-4 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
                                                    <FontAwesomeIcon icon={faTrash} /> Eliminar
                                                </button>
                                            ) :
                                            (
                                                <div class="inline-block"></div>
                                            )}

                                        {activateCancelButton.toString() === 'true' && id ?
                                            (
                                                <button onClick={voidButton} type="submit" class="ml-4 mb-4 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
                                                    <FontAwesomeIcon icon={faWindowClose} /> Cancelar
                                                </button>
                                            ) :
                                            (
                                                <div class="inline-block"></div>
                                            )}

                                        {activatePDFButton.toString() === 'true' && id ?
                                            (
                                                <button onClick={printButton} type="submit" class="ml-4 mb-4 bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded">
                                                    <FontAwesomeIcon icon={faFilePdf} /> PDF
                                                </button>
                                            ) :
                                            (
                                                <div class="inline-block"></div>
                                            )}

                                        <div class="bg-white p-4 shadow-xl">
                                            <Popup
                                                visible={popupVisible}
                                                onHiding={hidePopupInfo}
                                                dragEnabled={true}
                                                hideOnOutsideClick={false}
                                                showCloseButton={true}
                                                showTitle={true}
                                                title={popupTitle}
                                                width={600}
                                                height={400}
                                                resizeEnabled={true}
                                            >
                                                <button onClick={confirmAddButton} type="submit" class="mb-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                                                    <FontAwesomeIcon icon={faSave} /> Guardar Cambios
                                                </button>

                                                <Form
                                                    id="formAddItem"
                                                    labelMode={'outside'}
                                                    formData={addItemPayload}
                                                    readOnly={!activateUpdateForm}
                                                    showColonAfterLabel={false}
                                                    labelLocation={'top'}
                                                    onFieldDataChanged={onFieldAddItemChanged}
                                                    ref={formAddItemRef}
                                                >
                                                    <Item dataField="Articulo" editorType="dxLookup" editorOptions={articuloEditorOptions}>
                                                        <Label text="Artículo" />
                                                    </Item>
                                                    <Item dataField="Cantidad" editorType="dxNumberBox" >
                                                        <Label text="Cantidad" />
                                                    </Item>
                                                    <Item dataField="Precio" editorType="dxNumberBox" editorOptions={precioEditorOptions} >
                                                        <Label text="Precio" />
                                                    </Item>
                                                </Form>
                                            </Popup>

                                            <Popup
                                                visible={popupCheckVisible}
                                                onHiding={hidePopupCheckInfo}
                                                dragEnabled={true}
                                                hideOnOutsideClick={false}
                                                showCloseButton={true}
                                                showTitle={true}
                                                title="Crear Cheque"
                                                width={600}
                                                height={280}
                                                resizeEnabled={true}
                                            >
                                                <button onClick={confirmCheckButton} type="submit" class="mb-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                                                    <FontAwesomeIcon icon={faSave} /> Guardar Cambios
                                                </button>

                                                <Form
                                                    id="formCreateCheck"
                                                    labelMode={'outside'}
                                                    formData={createCheckPayload}
                                                    readOnly={!activateMoneyReturn}
                                                    showColonAfterLabel={false}
                                                    labelLocation={'top'}
                                                    ref={formCreateCheckRef}
                                                >
                                                    <Item dataField="SituacionGastosAdmin" editorType="dxSelectBox" editorOptions={situacionGastosAdminEditorOptions}>
                                                        <Label text="Gastos Administrativos" />
                                                    </Item>
                                                    <Item dataField="PorcentajeGA" editorType="dxNumberBox" editorOptions={porcentajeGAEditorOptions}>
                                                        <Label text="Porcentaje" />
                                                    </Item>
                                                </Form>
                                            </Popup>

                                            <Form
                                                id="formTransaccion"
                                                labelMode={'outside'}
                                                formData={salesOrderModPayload}
                                                readOnly={!activateUpdateForm}
                                                showColonAfterLabel={false}
                                                labelLocation={'top'}
                                                onFieldDataChanged={onFieldDatosModificacionChanged}
                                                ref={formDatosGeneralesRef}
                                            >
                                                <TabbedItem>
                                                    <TabPanelOptions deferRendering={false} showNavButtons={true} />
                                                    <Tab title="Datos Generales" colCount={3}>
                                                        <GroupItem caption="General">
                                                            <Item cssClass="boldText" dataField="header.Movimiento" editorOptions={movimientoEditorOptions} >
                                                                <Label text={"Movimiento"} />
                                                            </Item>
                                                            <Item dataField="header.FechaEmision" editorType="dxDateBox" editorOptions={fechaEmisionEditorOptions}>
                                                                <Label text={"Fecha Emisión"} />
                                                            </Item>
                                                            <Item dataField="header.Pedido" editorType="dxLookup" editorOptions={pedidoEditorOptions}>
                                                                <Label text="Pedido" />
                                                            </Item>
                                                            <Item dataField="header.Cliente" editorOptions={datosClienteEditorOptions} >
                                                                <Label text="Cliente" />
                                                            </Item>
                                                            <Item dataField="header.Motivo" >
                                                                <Label text="Motivo" />
                                                            </Item>
                                                            <Item dataField="header.ModificarDatos" editorType="dxCheckBox">
                                                                <Label text="Modificar Datos" />
                                                            </Item>
                                                            <Item dataField="header.ModificarMercancia" editorType="dxCheckBox">
                                                                <Label text="Modificar Mercancía" />
                                                            </Item>
                                                            <Item dataField="header.CancelacionTotal" editorType="dxCheckBox" editorOptions={cancelacionTotalEditorOptions} >
                                                                <Label text="Cancelación Total" />
                                                            </Item>
                                                        </GroupItem>
                                                        <GroupItem caption="Datos Modificación" colSpan={2}>
                                                            <TabbedItem>
                                                                <Tab title="Datos Facturación" visible={activateModifyData} >
                                                                    <Item dataField="customerData.RFC" editorOptions={uppercaseEditorOptions}>
                                                                        <Label text="RFC" />
                                                                    </Item>
                                                                    <Item dataField="customerData.RazonSocial" editorOptions={uppercaseEditorOptions}>
                                                                        <Label text="Razón Social" />
                                                                    </Item>
                                                                    <Item dataField="customerData.Direccion" editorOptions={uppercaseEditorOptions}>
                                                                        <Label text="Dirección" />
                                                                    </Item>
                                                                    <Item dataField="customerData.Colonia" editorOptions={uppercaseEditorOptions}>
                                                                        <Label text="Colonia" />
                                                                    </Item>
                                                                    <Item dataField="customerData.Pais" editorOptions={uppercaseEditorOptions}>
                                                                        <Label text="País" />
                                                                    </Item>
                                                                    <Item dataField="customerData.Estado" editorOptions={uppercaseEditorOptions}>
                                                                        <Label text="Estado" />
                                                                    </Item>
                                                                    <Item dataField="customerData.CodigoPostal" editorOptions={uppercaseEditorOptions}>
                                                                        <Label text="Código Postal" />
                                                                    </Item>
                                                                    <Item dataField="customerData.Delegacion" editorOptions={uppercaseEditorOptions}>
                                                                        <Label text="Delegación" />
                                                                    </Item>
                                                                    <Item dataField="customerData.FiscalRegimen" editorType="dxSelectBox" editorOptions={fiscalRegimenEditorOptions} >
                                                                        <Label text="Régimen Fiscal" />
                                                                    </Item>
                                                                    <Item dataField="customerData.SATCatUsoCFDI" editorType="dxSelectBox" editorOptions={usoCFDIEditorOptions} >
                                                                        <Label text="Uso CFDI" />
                                                                    </Item>
                                                                </Tab>
                                                                <Tab title="Mercancía Final" visible={activateModifyItems}>
                                                                    <DataGrid
                                                                        id="gridArticulosFinales"
                                                                        ref={gridArticulosFinalesRef}
                                                                        columnsAutoWidth={true}
                                                                        dataSource={salesOrderModPayload.items}
                                                                        showBorders={true}
                                                                        showColumnLines={true}
                                                                        showRowLines={true}
                                                                        rowAlternationEnabled={true}
                                                                        allowColumnResizing={true}
                                                                        columnResizingMode="widget"
                                                                        allowColumnReordering={false}
                                                                        repaintChangesOnly={false}
                                                                    >
                                                                        <Editing
                                                                            mode="cell"
                                                                            allowUpdating={false}
                                                                            allowAdding={false}
                                                                            allowDeleting={false}
                                                                        />
                                                                        <Sorting mode="none" />

                                                                        <Column dataField="Renglon" width={190} visible={false} allowEditing={false} />
                                                                        <Column dataField="Articulo" caption="Artículo" width={80} allowEditing={false} />
                                                                        <Column dataField="Descripcion" caption="Descripción" width={280} allowEditing={false} />
                                                                        <Column dataField="Cantidad" width={80} allowEditing={false} dataType="number" />
                                                                        <Column dataField="Precio" width={110} allowEditing={false} dataType="number">
                                                                            <Format
                                                                                type="currency"
                                                                                precision={2}
                                                                            />
                                                                        </Column>
                                                                        <Column dataField="Importe" width={110} allowEditing={false} dataType="number">
                                                                            <Format
                                                                                type="currency"
                                                                                precision={2}
                                                                            />
                                                                        </Column>
                                                                        <Column caption="" alignment="center" width={60} cellRender={deleteItem} />
                                                                        <Column caption="" alignment="center" width={60} cellRender={updateItem} />
                                                                    </DataGrid>
                                                                </Tab>
                                                                <Tab title="Mercancía Agregada" visible={activateModifyItems}>
                                                                    <DataGrid
                                                                        id="gridArticulosAgregados"
                                                                        ref={gridArticulosAgregadosRef}
                                                                        columnsAutoWidth={true}
                                                                        dataSource={salesOrderModPayload.addedItems}
                                                                        showBorders={true}
                                                                        showColumnLines={true}
                                                                        showRowLines={true}
                                                                        rowAlternationEnabled={true}
                                                                        allowColumnResizing={true}
                                                                        columnResizingMode="widget"
                                                                        allowColumnReordering={false}
                                                                        repaintChangesOnly={false}
                                                                    >
                                                                        <Editing
                                                                            mode="cell"
                                                                            allowUpdating={activateUpdateGrid}
                                                                            allowAdding={false}
                                                                            allowDeleting={false}
                                                                        />
                                                                        <Sorting mode="none" />

                                                                        <Column dataField="Renglon" width={190} visible={false} allowEditing={false} />
                                                                        <Column dataField="Articulo" caption="Artículo" width={80} allowEditing={false} />
                                                                        <Column dataField="Descripcion" caption="Descripción" width={280} allowEditing={false} />
                                                                        <Column dataField="Cantidad" width={80} allowEditing={false} dataType="number" />
                                                                        <Column dataField="Precio" width={110} allowEditing={false} dataType="number">
                                                                            <Format
                                                                                type="currency"
                                                                                precision={2}
                                                                            />
                                                                        </Column>
                                                                        <Column dataField="Importe" width={110} allowEditing={false} dataType="number">
                                                                            <Format
                                                                                type="currency"
                                                                                precision={2}
                                                                            />
                                                                        </Column>
                                                                    </DataGrid>
                                                                </Tab>
                                                                <Tab title="Mercancía Eliminada" visible={activateModifyItems}>
                                                                    <DataGrid
                                                                        id="gridArticulosEliminados"
                                                                        ref={gridArticulosEliminadosRef}
                                                                        columnsAutoWidth={true}
                                                                        dataSource={salesOrderModPayload.removedItems}
                                                                        showBorders={true}
                                                                        showColumnLines={true}
                                                                        showRowLines={true}
                                                                        rowAlternationEnabled={true}
                                                                        allowColumnResizing={true}
                                                                        columnResizingMode="widget"
                                                                        allowColumnReordering={false}
                                                                        repaintChangesOnly={false}
                                                                    >
                                                                        <Editing
                                                                            mode="cell"
                                                                            allowUpdating={activateUpdateGrid}
                                                                            allowAdding={false}
                                                                            allowDeleting={false}
                                                                        />
                                                                        <Sorting mode="none" />

                                                                        <Column dataField="Renglon" width={190} visible={false} allowEditing={false} />
                                                                        <Column dataField="Articulo" caption="Artículo" width={80} allowEditing={false} />
                                                                        <Column dataField="Descripcion" caption="Descripción" width={280} allowEditing={false} />
                                                                        <Column dataField="Cantidad" width={80} allowEditing={false} dataType="number" />
                                                                        <Column dataField="Precio" width={110} allowEditing={false} dataType="number">
                                                                            <Format
                                                                                type="currency"
                                                                                precision={2}
                                                                            />
                                                                        </Column>
                                                                        <Column dataField="Importe" width={110} allowEditing={false} dataType="number">
                                                                            <Format
                                                                                type="currency"
                                                                                precision={2}
                                                                            />
                                                                        </Column>
                                                                    </DataGrid>
                                                                </Tab>
                                                            </TabbedItem>
                                                        </GroupItem>
                                                    </Tab>
                                                    <Tab title="Autorización de Precios" colCount={2} visible={salesOrderModUpdatePrices}>
                                                        <Item dataField="header.SCUsuarioAutorizaPrecios" editorType="dxSelectBox" editorOptions={usuarioAutorizaEditorOptions}>
                                                            <Label text="Usuario Autoriza" />
                                                        </Item>
                                                        <Item dataField="header.SCCodigoAutorizaPrecios" >
                                                            <Label text="Código" />
                                                        </Item>
                                                        <Item dataField="header.SCFechaAutorizaPrecios" editorType="dxDateBox">
                                                            <Label text="Fecha Autorización" />
                                                        </Item>
                                                        <Item dataField="header.SCMotivoAutorizaPrecios" >
                                                            <Label text="Motivo" />
                                                        </Item>
                                                        <Item dataField="header.SCComentariosAutorizaPrecios" editorType="dxTextArea">
                                                            <Label text="Comentarios" />
                                                        </Item>
                                                    </Tab>
                                                    <Tab title="Autorización" colCount={2}>
                                                        <Item dataField="header.Autorizado" editorType="dxCheckBox" editorOptions={autorizacionEditorOptions} >
                                                            <Label text="Autorizado" />
                                                        </Item>
                                                        <Item dataField="header.Autoriza" editorOptions={autorizacionEditorOptions} >
                                                            <Label text="Autorizado por" />
                                                        </Item>
                                                        <Item dataField="header.FechaAutorizacion" editorOptions={autorizacionEditorOptions} >
                                                            <Label text="Fecha Autorización" />
                                                        </Item>
                                                    </Tab>
                                                    <Tab title="Observaciones" >
                                                        <Item dataField="header.Observaciones" editorType="dxTextArea" >
                                                            <Label text="Observaciones" visible={false} />
                                                        </Item>
                                                    </Tab>
                                                    <Tab title="Saldo a Favor Cliente" colCount={2}>
                                                        <Item dataField="header.SituacionSaldo" editorOptions={saldoEditorOptions}>
                                                            <Label text="Situación Saldo" />
                                                        </Item>
                                                        <Item dataField="header.Vale" editorOptions={saldoEditorOptions}>
                                                            <Label text="Vale" />
                                                        </Item>
                                                        <Item dataField="header.MovIDChequeVale" editorOptions={saldoEditorOptions}>
                                                            <Label text="Cheque" />
                                                        </Item>
                                                        <Item dataField="header.SituacionGastosAdmin" editorOptions={saldoEditorOptions}>
                                                            <Label text="Genera Gastos Administrativos" />
                                                        </Item>
                                                        <Item dataField="header.PorcentajeGA" editorType="dxNumberBox" editorOptions={saldoEditorOptions}>
                                                            <Label text="Porcentaje Gastos Administrativos" />
                                                        </Item>
                                                    </Tab>
                                                    <Tab title="Log Estatus">
                                                        <DataGrid
                                                            id="gridLogEstatus"
                                                            columnsAutoWidth={true}
                                                            dataSource={salesOrderModPayload.movEstatusLog}
                                                            allowColumnReordering={true}
                                                            allowColumnResizing={true}
                                                            columnResizingMode={"widget"}
                                                            showBorders={true}
                                                            rowAlternationEnabled={true}
                                                            showRowLines={true}
                                                            showColumnLines={true}
                                                        >
                                                            <HeaderFilter visible={false} />
                                                            <Column dataField="Usuario" dataType="string" width={350} caption="Usuario" />
                                                            <Column caption="Estatus" alignment="center" width={150} cellRender={estatusRelatedRecords} />
                                                            <Column dataField="Fecha" dataType="datetime" width={300} caption="Fecha" />
                                                        </DataGrid>
                                                    </Tab>
                                                    <Tab title="Registros Relacionados">
                                                        <DataGrid
                                                            id="gridRelatedRecords"
                                                            columnsAutoWidth={true}
                                                            dataSource={salesOrderModPayload.relatedRecords}
                                                            allowColumnReordering={true}
                                                            allowColumnResizing={true}
                                                            columnResizingMode={"widget"}
                                                            showBorders={true}
                                                            rowAlternationEnabled={true}
                                                            showRowLines={true}
                                                            showColumnLines={true}
                                                        >
                                                            <HeaderFilter visible={false} />

                                                            <Column dataField="name" dataType="string" width={300} caption="Transacción" />
                                                            <Column caption="Estatus" alignment="center" width={150} cellRender={estatusRelatedRecords} />
                                                            <Column caption="" alignment="center" width={50} cellRender={openRelatedRecordButton} />
                                                            <Column dataField="FechaEmision" dataType="date" width={150} caption="Fecha Emisión" />
                                                            <Column dataField="ImporteTotal" caption="Importe Total" dataType="number" width={180} >
                                                                <Format
                                                                    type="currency"
                                                                    precision={2}
                                                                />
                                                            </Column>
                                                        </DataGrid>
                                                    </Tab>
                                                    <Tab title="Archivos Anexos" >
                                                        <div className="widget-container">
                                                            <FileUploader
                                                                multiple={true}
                                                                accept="*"
                                                                uploadMode="useButtons"
                                                                chunkSize={20000000}
                                                                maxFileSize={20000000}
                                                                uploadChunk={uploadChunk}
                                                                disabled={!activateAttachments}
                                                            />
                                                        </div>
                                                        <div id="wrapper" className="show-widget" >
                                                            <div id="widget-area">
                                                                <FileManager id="attachmentManager" fileSystemProvider={attachments} onItemDeleting={onAttachmentDeleting} onItemDownloading={onItemDownloading} onSelectedFileOpened={onAttachmentOpen}>
                                                                    <Permissions
                                                                        create={false}
                                                                        copy={false}
                                                                        move={false}
                                                                        delete={activateAttachments}
                                                                        rename={false}
                                                                        upload={false}
                                                                        download={true}
                                                                    >
                                                                    </Permissions>
                                                                </FileManager>
                                                            </div>
                                                        </div>
                                                    </Tab>
                                                </TabbedItem>
                                            </Form>
                                        </div>
                                    </div>
                                ) :
                                (
                                    <div></div>
                                )
                            }
                        </div>
                    )
                }}
                />
            </div>
        </div>
    );
}
