import React, { useState, useEffect, useRef } from 'react';

// LoadPanel
import { LoadPanel } from 'devextreme-react/load-panel';

// Toast
import { Toast } from 'devextreme-react/toast';

//Form
import Form, { GroupItem, Label } from 'devextreme-react/form';

import { Auth, API, Storage } from 'aws-amplify';

// Ruteador
import { Route, Link, useHistory } from "react-router-dom";

// Grid
import DataGrid, {
    Column, FilterRow, HeaderFilter, FilterPanel, FilterBuilderPopup,
    GroupPanel, Grouping, SearchPanel, Sorting, Editing, Lookup, Toolbar, Item, RequiredRule,
    Scrolling, Pager, Paging, Summary, TotalItem, StateStoring, ColumnChooser, Format, Selection,
    Export, ColumnChooserSearch, ColumnChooserSelection
} from 'devextreme-react/data-grid';

//CheckBox
import CheckBox from 'devextreme-react/check-box';

// Iconos
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt } from "@fortawesome/pro-solid-svg-icons";
import { faArrowRotateLeft } from "@fortawesome/pro-solid-svg-icons";
import { faSave } from "@fortawesome/pro-solid-svg-icons";
import { faClipboardCheck } from "@fortawesome/pro-solid-svg-icons";
import { faFileAlt } from "@fortawesome/pro-solid-svg-icons";
import { faTrashAlt } from "@fortawesome/pro-solid-svg-icons";
import { faBan } from "@fortawesome/pro-solid-svg-icons";
import { faList } from "@fortawesome/pro-duotone-svg-icons";
import { faUmbrellaBeach } from "@fortawesome/pro-duotone-svg-icons";
import { faFilePdf } from "@fortawesome/pro-solid-svg-icons";
import { faArrowsRotate } from "@fortawesome/pro-solid-svg-icons";

// tabs
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

//Text area
import 'devextreme-react/text-area';

import 'devextreme/data/odata/store';
import CustomStore from 'devextreme/data/custom_store';

import '../ListStyle.css';

import dxDateBox from "devextreme/ui/date_box";

import dxNumberBox from "devextreme/ui/number_box";

import TransactionStatus from "../TransactionStatus/TransactionStatus.js";

import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
import { exportDataGrid } from 'devextreme/excel_exporter'

dxDateBox.defaultOptions({
    options: {
        dateSerializationFormat: "yyyy-MM-dd"
    }
});

dxNumberBox.defaultOptions({
    options: {
        step: 0
    }
});

function isNotEmpty(value) {
    return value !== undefined && value !== null && value !== '';
}

export default function Vacation(props) {
    const history = useHistory();

    // const [vacationRecords, setVacationRecords] = useState([]);
    const [isDetail, setIsDetail] = useState(false);
    const [id, setId] = useState();
    const [vacationPayload, setVacationPayload] = useState({
        header: {},
        detail: [],
        payrollauth: []
    });

    const [allowUpdating, setAllowUpdating] = useState(false);
    const [allowUpdatingVoid, setAllowUpdatingVoid] = useState(false);
    const [allowUpdatingGrid, setAllowUpdatingGrid] = useState(false);

    const [activateSaveButton, setActivateSaveButton] = useState(false);
    const [activateConfirmButton, setActivateConfirmButton] = useState(false);
    const [activateDeleteButton, setActivateDeleteButton] = useState(false);
    const [activateCancelButton, setActivateCancelButton] = useState(false);

    const [autoExpandAll, setAutoExpandAll] = useState(true);

    const [motivoRechazo, setMotivoRechazo] = useState([]);

    const [personales, setPersonales] = useState([]);

    //Ref para el form
    const [formDatosGeneralesRef, setFormDatosGeneralesRef] = useState(React.createRef());

    // setValue para el control tab
    const [value, setValue] = React.useState(0);

    const [visibleLoadPanel, setVisibleLoadPanel] = useState(false);

    const [visibleToast, setVisibleToast] = useState(false);
    const [messageToast, setMessageToast] = useState('');
    const [typeToast, setTypeToast] = useState('success');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const filterBuilderPopupPosition = {
        of: window,
        at: 'top',
        my: 'top',
        offset: { y: 10 },
    };

    const allowedPageSizes = [5, 10, 15, 20, 'all'];

    const exportFormats = ['xlsx'];

    function getOrderDay(rowData) {
        return (new Date(rowData.OrderDate)).getDay();
    }

    var fechaEmisionEditorOptions = { width: '100%', readOnly: true, text: 'Fecha Emisión' };
    var movimientoEditorOptions = { width: '100%', readOnly: true };
    var nombreSucursalEditorOptions = { width: '100%', readOnly: true };
    var estatusEditorOptions = { width: '100%', readOnly: true };
    var nombreEditorOptions = { width: '100%', readOnly: true };
    var nombreEmpleadoEditorOptions = { width: '100%', readOnly: true };

    var fechaIngresoEditorOptions = { width: '100%', readOnly: true };
    var antiguedadEditorOptions = { width: '100%', readOnly: true };
    var fechaDEditorOptions = { width: '100%', disabled: !allowUpdating };
    var fechaAEditorOptions = { width: '100%', disabled: !allowUpdating };
    var numeroDiasEditorOptions = { width: '100%', readOnly: true };


    var fondoDisponibleEditorOptions = { width: '100%', readOnly: true };
    var restanteDisponibleEditorOptions = { width: '100%', readOnly: true };

    try {
        var motivoRechazoEditorOptions = { items: motivoRechazo, displayExpr: 'name', valueExpr: 'value', searchEnabled: true, value: (vacationPayload.header.MotivoRechazo ? vacationPayload.header.MotivoRechazo : ''), width: '100%', disabled: !allowUpdatingVoid };
    }
    catch (e) {
        var motivoRechazoEditorOptions = { items: motivoRechazo, displayExpr: 'name', valueExpr: 'value', searchEnabled: true, value: '', width: '100%', disabled: !allowUpdatingVoid };
    }

    try {
        var personalCubreEditorOptions = { items: personales, displayExpr: 'name', valueExpr: 'value', searchEnabled: true, value: (vacationPayload.header.PersonalCubre ? vacationPayload.header.PersonalCubre : ''), width: '100%', disabled: !allowUpdating };
    }
    catch (e) {
        var personalCubreEditorOptions = { items: motivoRechazo, displayExpr: 'name', valueExpr: 'value', searchEnabled: true, value: '', width: '100%', disabled: !allowUpdating };
    }

    var observacionesEditorOptions = { height: 130, width: '100%', disabled: !allowUpdatingVoid };

    const filterBuilder = {
        customOperations: [{
            name: 'weekends',
            caption: 'Weekends',
            dataTypes: ['date'],
            icon: 'check',
            hasValue: false,
            calculateFilterExpression: () => [[getOrderDay, '=', 0], 'or', [getOrderDay, '=', 6]],
        }],
        allowHierarchicalFields: true,
    };

    const filterValue = [['Estatus', '<>', 'Rechazado']];

    //Ref para el grid Tablero de Control
    const [gridTableroRef, setGridTableroRef] = useState(React.createRef());

    function onAutoExpandAllChanged() {
        setAutoExpandAll(!autoExpandAll)
    };

    async function getVacationRecords(sqlFilter) {
        try {
            var personal = props.personal;
            var vacationAccess = props.vacationAccess;

            if (!vacationAccess || vacationAccess === 'undefined')
                vacationAccess = '';

            let apiName = 'AdminSC';
            let path = '/procesavacaciones';

            let myInit = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
                },
                queryStringParameters: {
                    personal: personal,
                    vacationAccess: vacationAccess,
                    action: 'list',
                    sqlFilter: sqlFilter,
                    username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username
                }
            }

            var vacationsGet = await API.get(apiName, path, myInit);
        }
        catch (e) {
            if (e === 'No current user') {
                history.push('/');

                window.location.reload();
            }
        }

        return vacationsGet;
    }

    const store = new CustomStore({
        key: 'ID',
        load(loadOptions) {
            let sqlFilter = '(';
            [
                'filter'
            ].forEach((i) => {
                if (i in loadOptions && isNotEmpty(loadOptions[i])) {
                    var filters = loadOptions[i];

                    var filtersLength = filters.length;

                    for (var i = 0; i <= filtersLength; i++) {
                        var singleFilter = filters[i];
                        var singleFilterType = typeof singleFilter;
                        var singleFilterIsArray = Array.isArray(singleFilter);
                        var singleFilterIsDate = singleFilter instanceof Date;

                        if (singleFilter === 'NULL') {
                            singleFilter = '0';
                        }

                        if (singleFilterType.toString() === 'object' && singleFilterIsArray) {
                            sqlFilter += '(';

                            var subFiltersLength = singleFilter.length;

                            for (var i2 = 0; i2 <= subFiltersLength; i2++) {
                                var singleSubFilter = singleFilter[i2];
                                var singleSubFilterType = typeof singleSubFilter;
                                var singleSubFilterIsArray = Array.isArray(singleSubFilter);
                                var singleSubFilterIsDate = singleSubFilter instanceof Date

                                if (singleSubFilter === 'NULL') {
                                    singleSubFilter = '0';
                                }

                                if (singleSubFilterType.toString() === 'object' && singleSubFilterIsArray) {
                                    sqlFilter += '(';

                                    var subSubFiltersLength = singleSubFilter.length;

                                    for (var i3 = 0; i3 <= subSubFiltersLength; i3++) {
                                        var singleSubSubFilter = singleSubFilter[i3];
                                        var singleSubSubFilterType = typeof singleSubSubFilter;
                                        var singleSubSubFilterIsArray = Array.isArray(singleSubSubFilter);
                                        var singleSubSubFilterIsDate = singleSubSubFilter instanceof Date

                                        if (singleSubSubFilter === 'NULL') {
                                            singleSubSubFilter = '0';
                                        }

                                        if (singleSubSubFilterType.toString() === 'object' && singleSubSubFilterIsArray) {
                                            sqlFilter += '(';

                                            var subSubSubFiltersLength = singleSubSubFilter.length;

                                            for (var i4 = 0; i4 <= subSubSubFiltersLength; i4++) {
                                                var singleSubSubSubFilter = singleSubSubFilter[i4];
                                                var singleSubSubSubFilterType = typeof singleSubSubSubFilter;
                                                var singleSubSubSubFilterIsArray = Array.isArray(singleSubSubSubFilter);
                                                var singleSubSubSubFilterIsDate = singleSubSubSubFilter instanceof Date

                                                if (singleSubSubSubFilter === 'NULL') {
                                                    singleSubSubSubFilter = '0';
                                                }

                                                if (singleSubSubSubFilterType.toString() === 'object' && singleSubSubSubFilterIsArray) {
                                                    sqlFilter += '(';

                                                    var subSubSubSubFiltersLength = singleSubSubSubFilter.length;

                                                    for (var i5 = 0; i5 <= subSubSubSubFiltersLength; i5++) {
                                                        var singleSubSubSubSubFilter = singleSubSubSubFilter[i5];
                                                        var singleSubSubSubSubFilterType = typeof singleSubSubSubSubFilter;
                                                        var singleSubSubSubSubFilterIsArray = Array.isArray(singleSubSubSubSubFilter);
                                                        var singleSubSubSubSubFilterIsDate = singleSubSubSubSubFilter instanceof Date

                                                        if (singleSubSubSubSubFilter === 'NULL') {
                                                            singleSubSubSubSubFilter = '0';
                                                        }

                                                        if (singleSubSubSubSubFilterType.toString() === 'string') {
                                                            if (singleSubSubSubSubFilter === 'contains') {
                                                                filters[i][i2][i3][i4][i5] = 'LIKE';
                                                                singleSubSubSubSubFilter = 'LIKE';

                                                                filters[i][i2][i3][i4][i5 + 1] = '\'%' + filters[i][i2][i3][i4][i5 + 1] + '%\'';
                                                            }
                                                            else if (singleSubSubSubSubFilter === 'notcontains') {
                                                                filters[i][i2][i3][i4][i5] = 'NOT LIKE';
                                                                singleSubSubSubSubFilter = 'NOT LIKE';

                                                                filters[i][i2][i3][i4][i5 + 1] = '\'%' + filters[i][i2][i3][i4][i5 + 1] + '%\'';
                                                            }
                                                            else if (singleSubSubSubSubFilter === 'startswith') {
                                                                filters[i][i2][i3][i4][i5] = 'LIKE';
                                                                singleSubSubSubSubFilter = 'LIKE';

                                                                filters[i][i2][i3][i4][i5 + 1] = '\'' + filters[i][i2][i3][i4][i5 + 1] + '%\'';
                                                            }
                                                            else if (singleSubSubSubSubFilter === 'endswith') {
                                                                filters[i][i2][i3][i4][i5] = 'LIKE';
                                                                singleSubSubSubSubFilter = 'LIKE';

                                                                filters[i][i2][i3][i4][i5 + 1] = '\'%' + filters[i][i2][i3][i4][i5 + 1] + '\'';
                                                            }
                                                            else if (singleSubSubSubSubFilter === '=' || singleSubSubSubSubFilter === '<>' || singleSubSubSubSubFilter === '>' || singleSubSubSubSubFilter === '>=' || singleSubSubSubSubFilter === '<' || singleSubSubSubSubFilter === '<=') {
                                                                if (!filters[i][i2][i3][i4][i5 + 1])
                                                                    filters[i][i2][i3][i4][i5 + 1] = 'NULL';
                                                                else if (typeof filters[i][i2][i3][i4][i5 + 1] === 'string') {
                                                                    filters[i][i2][i3][i4][i5 + 1] = '\'' + filters[i][i2][i3][i4][i5 + 1] + '\'';
                                                                }
                                                            }
                                                            else if (singleSubSubSubSubFilter === '!') {
                                                                filters[i][i2][i3][i4][i5] = 'NOT';
                                                                singleSubSubSubSubFilter = 'NOT';
                                                            }

                                                            sqlFilter += ' ' + singleSubSubSubSubFilter + ' ';
                                                        }
                                                        else if (singleSubSubSubSubFilterType.toString() === 'object' && singleSubSubSubSubFilterIsDate) {
                                                            sqlFilter += ' \'' + singleSubSubSubSubFilter.toISOString().split('T')[0] + '\' ';
                                                        }
                                                        else if (singleSubSubSubSubFilterType.toString() === 'number') {
                                                            sqlFilter += ' ' + singleSubSubSubSubFilter.toString() + ' ';
                                                        }
                                                        else if (singleSubSubSubSubFilterType.toString() === 'boolean') {
                                                            if (singleSubSubSubSubFilter.toString() === 'true')
                                                                sqlFilter += '1 ';
                                                            else if (singleSubSubSubSubFilter.toString() === 'false' || singleSubSubSubSubFilter === null || singleSubSubSubSubFilter === undefined)
                                                                sqlFilter += '0 ';
                                                        }
                                                    }
                                                }
                                                else if (singleSubSubSubFilterType.toString() === 'string') {
                                                    if (singleSubSubSubFilter === 'contains') {
                                                        filters[i][i2][i3][i4] = 'LIKE';
                                                        singleSubSubSubFilter = 'LIKE';

                                                        filters[i][i2][i3][i4 + 1] = '\'%' + filters[i][i2][i3][i4 + 1] + '%\'';
                                                    }
                                                    else if (singleSubSubSubFilter === 'notcontains') {
                                                        filters[i][i2][i3][i4] = 'NOT LIKE';
                                                        singleSubSubSubFilter = 'NOT LIKE';

                                                        filters[i][i2][i3][i4 + 1] = '\'%' + filters[i][i2][i3][i4 + 1] + '%\'';
                                                    }
                                                    else if (singleSubSubSubFilter === 'startswith') {
                                                        filters[i][i2][i3][i4] = 'LIKE';
                                                        singleSubSubSubFilter = 'LIKE';

                                                        filters[i][i2][i3][i4 + 1] = '\'' + filters[i][i2][i3][i4 + 1] + '%\'';
                                                    }
                                                    else if (singleSubSubSubFilter === 'endswith') {
                                                        filters[i][i2][i3][i4] = 'LIKE';
                                                        singleSubSubSubFilter = 'LIKE';

                                                        filters[i][i2][i3][i4 + 1] = '\'%' + filters[i][i2][i3][i4 + 1] + '\'';
                                                    }
                                                    else if (singleSubSubSubFilter === '=' || singleSubSubSubFilter === '<>' || singleSubSubSubFilter === '>' || singleSubSubSubFilter === '>=' || singleSubSubSubFilter === '<' || singleSubSubSubFilter === '<=') {
                                                        if (!filters[i][i2][i3][i4 + 1])
                                                            filters[i][i2][i3][i4 + 1] = 'NULL';
                                                        else if (typeof filters[i][i2][i3][i4 + 1] === 'string') {
                                                            filters[i][i2][i3][i4 + 1] = '\'' + filters[i][i2][i3][i4 + 1] + '\'';
                                                        }
                                                    }
                                                    else if (singleSubSubSubFilter === '!') {
                                                        filters[i][i2][i3][i4] = 'NOT';
                                                        singleSubSubSubFilter = 'NOT';
                                                    }

                                                    sqlFilter += ' ' + singleSubSubSubFilter + ' ';
                                                }
                                                else if (singleSubSubSubFilterType.toString() === 'object' && singleSubSubSubFilterIsDate) {
                                                    sqlFilter += ' \'' + singleSubSubSubFilter.toISOString().split('T')[0] + '\' ';
                                                }
                                                else if (singleSubSubSubFilterType.toString() === 'number') {
                                                    sqlFilter += ' ' + singleSubSubSubFilter.toString() + ' ';
                                                }
                                                else if (singleSubSubSubFilterType.toString() === 'boolean') {
                                                    if (singleSubSubSubFilter.toString() === 'true')
                                                        sqlFilter += '1 ';
                                                    else if (singleSubSubSubFilter.toString() === 'false' || singleSubSubSubFilter === null || singleSubSubSubFilter === undefined)
                                                        sqlFilter += '0 ';
                                                }
                                            }

                                            sqlFilter += ')';
                                        }
                                        else if (singleSubSubFilterType.toString() === 'string') {
                                            if (singleSubSubFilter === 'contains') {
                                                filters[i][i2][i3] = 'LIKE';
                                                singleSubSubFilter = 'LIKE';

                                                filters[i][i2][i3 + 1] = '\'%' + filters[i][i2][i3 + 1] + '%\'';
                                            }
                                            else if (singleSubSubFilter === 'notcontains') {
                                                filters[i][i2][i3] = 'NOT LIKE';
                                                singleSubSubFilter = 'NOT LIKE';

                                                filters[i][i2][i3 + 1] = '\'%' + filters[i][i2][i3 + 1] + '%\'';
                                            }
                                            else if (singleSubSubFilter === 'startswith') {
                                                filters[i][i2][i3] = 'LIKE';
                                                singleSubSubFilter = 'LIKE';

                                                filters[i][i2][i3 + 1] = '\'' + filters[i][i2][i3 + 1] + '%\'';
                                            }
                                            else if (singleSubSubFilter === 'endswith') {
                                                filters[i][i2][i3] = 'LIKE';
                                                singleSubSubFilter = 'LIKE';

                                                filters[i][i2][i3 + 1] = '\'%' + filters[i][i2][i3 + 1] + '\'';
                                            }
                                            else if (singleSubSubFilter === '=' || singleSubSubFilter === '<>' || singleSubSubFilter === '>' || singleSubSubFilter === '>=' || singleSubSubFilter === '<' || singleSubSubFilter === '<=') {
                                                if (!filters[i][i2][i3 + 1])
                                                    filters[i][i2][i3 + 1] = 'NULL';
                                                else if (typeof filters[i][i2][i3 + 1] === 'string') {
                                                    filters[i][i2][i3 + 1] = '\'' + filters[i][i2][i3 + 1] + '\'';
                                                }
                                            }
                                            else if (singleSubSubFilter === '!') {
                                                filters[i][i2][i3] = 'NOT';
                                                singleSubSubFilter = 'NOT';
                                            }
                                            sqlFilter += ' ' + singleSubSubFilter + ' ';
                                        }
                                        else if (singleSubSubFilterType.toString() === 'object' && singleSubSubFilterIsDate) {
                                            sqlFilter += ' \'' + singleSubSubFilter.toISOString().split('T')[0] + '\' ';
                                        }
                                        else if (singleSubSubFilterType.toString() === 'number') {
                                            sqlFilter += ' ' + singleSubSubFilter.toString() + ' ';
                                        }
                                        else if (singleSubSubFilterType.toString() === 'boolean') {
                                            if (singleSubSubFilter.toString() === 'true')
                                                sqlFilter += '1 ';
                                            else if (singleSubSubFilter.toString() === 'false' || singleSubSubFilter === null || singleSubSubFilter === undefined)
                                                sqlFilter += '0 ';
                                        }
                                    }

                                    sqlFilter += ')';
                                }
                                else if (singleSubFilterType.toString() === 'string') {
                                    if (singleSubFilter === 'contains') {
                                        filters[i][i2] = 'LIKE';
                                        singleSubFilter = 'LIKE';

                                        filters[i][i2 + 1] = '\'%' + filters[i][i2 + 1] + '%\'';
                                    }
                                    else if (singleSubFilter === 'notcontains') {
                                        filters[i][i2] = 'NOT LIKE';
                                        singleSubFilter = 'NOT LIKE';

                                        filters[i][i2 + 1] = '\'%' + filters[i][i2 + 1] + '%\'';
                                    }
                                    else if (singleSubFilter === 'startswith') {
                                        filters[i][i2] = 'LIKE';
                                        singleSubFilter = 'LIKE';

                                        filters[i][i2 + 1] = '\'' + filters[i][i2 + 1] + '%\'';
                                    }
                                    else if (singleSubFilter === 'endswith') {
                                        filters[i][i2] = 'LIKE';
                                        singleSubFilter = 'LIKE';

                                        filters[i][i2 + 1] = '\'%' + filters[i][i2 + 1] + '\'';
                                    }
                                    else if (singleSubFilter === '=' || singleSubFilter === '<>' || singleSubFilter === '>' || singleSubFilter === '>=' || singleSubFilter === '<' || singleSubFilter === '<=') {
                                        if (!filters[i][i2 + 1])
                                            filters[i][i2 + 1] = 'NULL';
                                        else if (typeof filters[i][i2 + 1] === 'string') {
                                            filters[i][i2 + 1] = '\'' + filters[i][i2 + 1] + '\'';
                                        }
                                    }
                                    else if (singleSubFilter === '!') {
                                        filters[i][i2] = 'NOT';
                                        singleSubFilter = 'NOT';
                                    }
                                    sqlFilter += ' ' + singleSubFilter + ' ';
                                }
                                else if (singleSubFilterType.toString() === 'object' && singleSubFilterIsDate) {
                                    sqlFilter += ' \'' + singleSubFilter.toISOString().split('T')[0] + '\' ';
                                }
                                else if (singleSubFilterType.toString() === 'number') {
                                    sqlFilter += ' ' + singleSubFilter.toString() + ' ';
                                }
                                else if (singleSubFilterType.toString() === 'boolean') {
                                    if (singleSubFilter.toString() === 'true')
                                        sqlFilter += '1 ';
                                    else if (singleSubFilter.toString() === 'false' || singleSubFilter === null || singleSubFilter === undefined)
                                        sqlFilter += '0 ';
                                }
                            }

                            sqlFilter += ')';
                        }
                        else if (singleFilterType.toString() === 'string') {
                            if (singleFilter === 'contains') {
                                filters[i] = 'LIKE';
                                singleFilter = 'LIKE';

                                filters[i + 1] = '\'%' + filters[i + 1] + '%\'';
                            }
                            else if (singleFilter === 'notcontains') {
                                filters[i] = 'NOT LIKE';
                                singleFilter = 'NOT LIKE';

                                filters[i + 1] = '\'%' + filters[i + 1] + '%\'';
                            }
                            else if (singleFilter === 'startswith') {
                                filters[i] = 'LIKE';
                                singleFilter = 'LIKE';

                                filters[i + 1] = '\'' + filters[i + 1] + '%\'';
                            }
                            else if (singleFilter === 'endswith') {
                                filters[i] = 'LIKE';
                                singleFilter = 'LIKE';

                                filters[i + 1] = '\'%' + filters[i + 1] + '\'';
                            }
                            else if (singleFilter === '=' || singleFilter === '<>' || singleFilter === '>' || singleFilter === '>=' || singleFilter === '<' || singleFilter === '<=') {
                                if (!filters[i + 1])
                                    filters[i + 1] = 'NULL';
                                else if (typeof filters[i + 1] === 'string') {
                                    filters[i + 1] = '\'' + filters[i + 1] + '\'';
                                }
                            }
                            else if (singleFilter === '!') {
                                filters[i] = 'NOT';
                                singleFilter = 'NOT';
                            }
                            sqlFilter += ' ' + singleFilter + ' ';
                        }
                        else if (singleFilterType.toString() === 'object' && singleFilterIsDate) {
                            sqlFilter += ' \'' + singleFilter.toISOString().split('T')[0] + '\' ';
                        }
                        else if (singleFilterType.toString() === 'number') {
                            sqlFilter += ' ' + singleFilter.toString() + ' ';
                        }
                        else if (singleFilterType.toString() === 'boolean') {
                            if (singleFilter.toString() === 'true')
                                sqlFilter += '1 ';
                            else if (singleFilter.toString() === 'false' || singleFilter === null || singleFilter === undefined)
                                sqlFilter += '0 ';
                        }
                    }

                    sqlFilter += ')';
                }
            });

            async function getRecords() {
                if (sqlFilter === '(')
                    sqlFilter = '';

                var records = await getVacationRecords(sqlFilter);
                return {
                    data: records,
                    totalCount: records.length
                };
            }

            return getRecords()
                .then(function (result) {
                    return result;
                })
                .catch(() => { throw new Error('Data Loading Error'); });
        },
    });

    async function getVacationPayload(ID) {
        try {
            var personal = props.personal;

            let apiName = 'AdminSC';
            let path = '/procesavacaciones';

            let myInit = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
                },
                queryStringParameters: {
                    personal: personal,
                    ID: ID,
                    action: 'payload',
                    username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username
                }
            }

            var payloadGet = await API.get(apiName, path, myInit);

            setVacationPayload(payloadGet);
        }
        catch (e) {
            if (e === 'No current user') {
                history.push('/');

                window.location.reload();
            }
        }

        return payloadGet;
    }

    async function getMotivoRechazoRecords() {
        let apiName = 'AdminSC';
        let path = '/getcatalogo';

        let myInit = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
            },
            queryStringParameters: {
                type: 'MotivoRechazoVacaciones',
                username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username
            }
        }

        var responsableGetList = await API.get(apiName, path, myInit);

        return responsableGetList;
    }

    async function editVacationButton(ID) {
        try {
            if (!window.event.metaKey && !window.event.ctrlKey) {
                setVisibleLoadPanel(true);

                setIsDetail(true);
                setId(ID);

                var payload = await getVacationPayload(ID);

                var isPayrollAuth = false;

                if (payload.header.Estatus === 'Sin Afectar') {
                    if (props.personal.trim() === payload.header.Personal.trim()) {
                        setActivateSaveButton(true);
                        setActivateConfirmButton(true);
                        setActivateDeleteButton(true);
                        setActivateCancelButton(false);
                        setAllowUpdating(true);
                        setAllowUpdatingVoid(false);
                        setAllowUpdatingGrid(false);
                    }
                    else {
                        setActivateSaveButton(false);
                        setActivateConfirmButton(false);
                        setActivateDeleteButton(false);
                        setActivateCancelButton(false);
                        setAllowUpdating(false);
                        setAllowUpdatingVoid(false);
                        setAllowUpdatingGrid(false);
                    }
                }
                else if (payload.header.Estatus === 'Por Autorizar') {
                    if (props.personal.trim() === payload.header.Personal.trim()) {
                        setActivateSaveButton(false);
                        setAllowUpdatingVoid(true);
                        setAllowUpdatingGrid(false);
                    }
                    else {
                        setActivateSaveButton(false);
                        setAllowUpdatingVoid(false);
                        setAllowUpdatingGrid(false);
                    }

                    if (props.personal.trim().toUpperCase() === payload.header.Supervisor.trim().toUpperCase()) {
                        setActivateConfirmButton(true);
                        setAllowUpdating(true);
                    }
                    else {
                        setActivateConfirmButton(false);
                        setAllowUpdating(false);
                    }

                    if (props.personal.trim() === payload.header.Personal.trim()) {
                        setActivateDeleteButton(false);
                        setActivateCancelButton(true);
                        setAllowUpdating(false);
                    }
                    else {
                        setActivateDeleteButton(false);
                        setActivateCancelButton(false);
                        setAllowUpdating(false);
                    }
                }
                else if (payload.header.Estatus === 'Pendiente') {
                    setActivateSaveButton(true);
                    setActivateDeleteButton(false);
                    setAllowUpdating(false);

                    for (var payrollauthIndex in payload.payrollauth) {
                        var singlePayrollauth = payload.payrollauth[payrollauthIndex];

                        if (props.personal === singlePayrollauth.Personal) {
                            isPayrollAuth = true;
                        }
                    }

                    if (isPayrollAuth === true) {
                        setActivateConfirmButton(true);
                        setActivateSaveButton(true);
                        setAllowUpdatingGrid(true);
                        setAllowUpdatingVoid(true);
                        setActivateCancelButton(true);

                    }
                    else {
                        setActivateConfirmButton(false);
                        setActivateSaveButton(false);
                        setAllowUpdatingGrid(false);
                        setAllowUpdatingVoid(false);
                        setActivateCancelButton(false);
                    }
                }
                else if (payload.header.Estatus === 'Concluido') {
                    setActivateSaveButton(false);
                    setActivateConfirmButton(false);
                    setActivateDeleteButton(false);
                    setActivateCancelButton(false);
                    setAllowUpdating(false);
                    setAllowUpdatingVoid(false);
                    setAllowUpdatingGrid(false);
                }
                else if (payload.header.Estatus === 'Rechazado') {
                    setActivateSaveButton(false);
                    setActivateConfirmButton(false);
                    setActivateDeleteButton(false);
                    setActivateCancelButton(false);
                    setAllowUpdating(false);
                    setAllowUpdatingVoid(false);
                    setAllowUpdatingGrid(false);
                }

                setVisibleLoadPanel(false);
            }
        }
        catch (e) {
            setVisibleLoadPanel(false);

            if (!ID || ID === 'undefined') {
                newButton();
            }
        }
    };

    async function cancelEditButton() {
        setIsDetail(false);

        // var getVacations = await getVacationRecords(props.personal);

        // setVacationRecords(getVacations);
    };

    async function saveButton() {
        try {
            setVisibleLoadPanel(true);

            var ID = vacationPayload.header.ID;

            //Despues enviamos todo al backend
            let apiName = 'AdminSC';
            let path = '/procesavacaciones';

            let myInit = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
                },
                body: {
                    ID: ID,
                    payload: vacationPayload,
                    action: 'saveChanges',
                    s3Bucket: Storage._config.AWSS3.bucket,
                    username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username
                }
            }

            var payloadPost = await API.post(apiName, path, myInit);

            if (payloadPost.ok === 'true') {
                if (!ID) {
                    setVisibleToast(true);
                    setMessageToast('Se creó la solicitud con éxito');
                    setTypeToast('success')

                    ID = payloadPost.newID;

                    // setActivateConfirmButton(true);

                    editVacationButton(ID);

                    history.push('/Vacation/' + ID.toString());
                }
                else {
                    setVisibleToast(true);
                    setMessageToast('Se actualizó la solicitud con éxito');
                    setTypeToast('success')

                    editVacationButton(ID);
                }
            }
            else if (payloadPost.ok === 'false') {
                setVisibleToast(true);
                setMessageToast(payloadPost.message);
                setTypeToast('error')
            }

            setVisibleLoadPanel(false);
        }
        catch (e) {
            setVisibleLoadPanel(false);

            if (e === 'No current user') {
                history.push('/');

                window.location.reload();
            }
        }
    };

    async function newButton() {
        try {
            var personal = props.personal;

            let apiName = 'AdminSC';
            let path = '/procesavacaciones';

            let myInit = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
                },
                queryStringParameters: {
                    personal: personal,
                    action: 'new',
                    username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username
                }
            };

            var payloadNew = await API.get(apiName, path, myInit);

            setVacationPayload(payloadNew);

            setIsDetail(true);
            setId(undefined);

            setActivateSaveButton(true);
            setActivateConfirmButton(false);
            setActivateDeleteButton(false);
            setAllowUpdating(true);
        }
        catch (e) {
            if (e === 'No current user') {
                history.push('/');

                window.location.reload();
            }
        }
    };

    async function confirmButton() {
        try {
            if (!window.confirm('¿Desea confirmar la solicitud?'))
                return;

            setVisibleLoadPanel(true);

            var ID = vacationPayload.header.ID;

            let apiName = 'AdminSC';
            let path = '/procesavacaciones';

            let myInit = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
                },
                body: {
                    ID: ID,
                    payload: vacationPayload,
                    action: 'apply',
                    username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username
                }
            }

            var payloadPost = await API.post(apiName, path, myInit);

            if (payloadPost.ok === 'true') {
                setVisibleLoadPanel(false);

                setVisibleToast(true);
                setMessageToast('Solicitud aplicada con éxito');
                setTypeToast('success')
                // setAllowUpdating(false);
                // setActivateButton(false);

                //Vamos al tablero de control
                setIsDetail(false);

                //Obtenemos los registros actualizados
                // var getVacations = await getVacationRecords(props.personal);
                // setVacationRecords(getVacations);

                //Vamos al tablero de control
                history.push('/Vacation');
            }
            else if (payloadPost.ok === 'false') {
                setVisibleLoadPanel(false);

                setVisibleToast(true);
                setMessageToast(payloadPost.message);
                setTypeToast('error')
            }
        }
        catch (e) {
            setVisibleLoadPanel(false);

            if (e === 'No current user') {
                history.push('/');

                window.location.reload();
            }
        }
    };

    async function deleteButton() {
        try {
            if (!window.confirm('¿Desea eliminar la solicitud?'))
                return;

            setVisibleLoadPanel(true);

            var ID = vacationPayload.header.ID;

            let apiName = 'AdminSC';
            let path = '/procesavacaciones';

            let myInit = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
                },
                body: {
                    ID: ID,
                    payload: vacationPayload,
                    action: 'delete',
                    username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username
                }
            }

            var payloadPost = await API.post(apiName, path, myInit);

            if (payloadPost.ok === 'true') {
                setVisibleLoadPanel(false);

                setVisibleToast(true);
                setMessageToast('Solicitud eliminada con éxito');
                setTypeToast('success')

                setIsDetail(false);

                //Obtenemos los registros actualizados
                // var getVacations = await getVacationRecords(props.personal);
                // setVacationRecords(getVacations);

                //Vamos al tablero de control
                history.push('/Vacation');
            }
            else if (payloadPost.ok === 'false') {
                setVisibleLoadPanel(false);

                setVisibleToast(true);
                setMessageToast(payloadPost.message);
                setTypeToast('error')
            }
        }
        catch (e) {
            setVisibleLoadPanel(false);

            if (e === 'No current user') {
                history.push('/');

                window.location.reload();
            }
        }
    }

    async function cancelButton() {
        try {
            if (!window.confirm('¿Desea rechazar la solicitud?'))
                return;

            if (vacationPayload.header.MotivoRechazo === '' || !vacationPayload.header.MotivoRechazo) {
                setVisibleToast(true);
                setMessageToast('Favor de especificar el Motivo de Rechazo');
                setTypeToast('error')
                return;
            }

            setVisibleLoadPanel(true);

            var ID = vacationPayload.header.ID;

            let apiName = 'AdminSC';
            let path = '/procesavacaciones';

            let myInit = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
                },
                body: {
                    ID: ID,
                    payload: vacationPayload,
                    action: 'void',
                    username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username
                }
            }

            var payloadPost = await API.post(apiName, path, myInit);

            if (payloadPost.ok === 'true') {
                setVisibleLoadPanel(false);

                setVisibleToast(true);
                setMessageToast('Solicitud rechazada con éxito');
                setTypeToast('success')

                setIsDetail(false);

                //Obtenemos los registros actualizados
                // var getVacations = await getVacationRecords(props.personal);
                // setVacationRecords(getVacations);

                //Vamos al tablero de control
                history.push('/Vacation');
            }
            else if (payloadPost.ok === 'false') {
                setVisibleLoadPanel(false);

                setVisibleToast(true);
                setMessageToast(payloadPost.message);
                setTypeToast('error')
            }
        }
        catch (e) {
            setVisibleLoadPanel(false);

            if (e === 'No current user') {
                history.push('/');

                window.location.reload();
            }
        }
    }

    function printButton() {
        var baseUrl = window.location.href.split('/')[0];

        window.open(baseUrl + "/#/PdfRender?type=vacation&id=" + id, "_blank");
    };

    function editVacation(data) {
        return (
            <Link to={`/Vacation/${data.data.ID}`}>
                <button class="bg-blue-500 hover:bg-blue-700 text-white  py-1 px-3 rounded" onClick={() => editVacationButton(data.data.ID)}><FontAwesomeIcon icon={faPencilAlt} /> Editar</button>
            </Link>
        )
    }

    async function onFieldDatosVacacionChanged(e) {
        var payload = JSON.parse(JSON.stringify(vacationPayload));

        payload.personal = props.personal;
        payload.action = 'days';
        payload.username = JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username;

        if (payload.header.FechaD && payload.header.FechaA) {
            let apiName = 'AdminSC';
            let path = '/procesavacaciones';

            let myInit = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
                },
                body: payload
            };

            var daysResult = await API.post(apiName, path, myInit);

            if (formDatosGeneralesRef.current) {
                var formDatosGenerales = formDatosGeneralesRef.current.instance;

                try {
                    formDatosGenerales.updateData('NumeroDias', daysResult.result.NumeroDias);
                }
                catch (e) {
                    formDatosGenerales.updateData('NumeroDias', 0);
                }

            }
        }
    }

    useEffect(() => {
        window.addEventListener('popstate', (e) => {
            var hash;

            try {
                hash = e.target.location.hash;
            }
            catch (e) {
                hash = '';
            }

            if (hash === '#/Vacation') {
                history.push('/Vacation');

                window.location.reload();
            }
        });

        async function initialize() {
            //Obtenemos sus solicitudes de vacaciones
            // var getVacationList = await getVacationRecords(props.personal);

            // setVacationRecords(getVacationList);

            //Obtenemos el listado de Motivo de Rechazo
            var getMotivoRechazoList = await getMotivoRechazoRecords();

            var motivos = [];

            for (var motivoIndex in getMotivoRechazoList) {
                motivos.push({
                    name: getMotivoRechazoList[motivoIndex].value,
                    value: getMotivoRechazoList[motivoIndex].value
                });
            }

            setMotivoRechazo(motivos);

            // Obtenemos el listado de personal
            let apiNamePersonal = 'AdminSC';
            let pathPersonal = '/getcatalogo';

            let myInitPersonal = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
                },
                queryStringParameters: {
                    type: 'Personal',
                    includeInactive: false,
                    username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username
                }
            }

            var personalGet = await API.get(apiNamePersonal, pathPersonal, myInitPersonal);

            var personalestmp = [];

            for (var personalIndex in personalGet) {
                personalestmp.push({
                    value: personalGet[personalIndex].Personal,
                    name: personalGet[personalIndex].ApellidoPaterno + ' ' +
                        personalGet[personalIndex].ApellidoMaterno + ' ' +
                        personalGet[personalIndex].Nombre
                });
            }

            setPersonales(personalestmp);
        }

        initialize();

        //En caso de solicitar un ID especifico, redireccionamos a ese ID
        if (!props.match.isExact) {
            var urlID = props.location.pathname.replace('/Vacation/', '');

            editVacationButton(urlID);
        }

        // eslint-disable-next-line
    }, []);

    function overrideOnValueChanged(e) {
        //Hacemos la data persistente en cuanto el usuario escriba en el campo
        // if (e.parentType === "dataRow") {
        //     e.editorOptions.valueChangeEvent = "input";
        // }
    }

    function onHidingToast() {
        setVisibleToast(false);
    }

    function refreshButton() {
        if (gridTableroRef.current) {
            var gridTablero = gridTableroRef.current.instance;

            gridTablero.refresh();
        }
    }

    function onExporting(e) {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Datos');

        exportDataGrid({
            component: e.component,
            worksheet,
            autoFilterEnabled: true,
        }).then(() => {
            workbook.xlsx.writeBuffer().then((buffer) => {
                saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Datos.xlsx');
            });
        });
        e.cancel = true;
    };

    return (
        <div>
            {isDetail === false ? (
                <div class="bg-gray-200 m-2">
                    <h1 class="font-sans inline-block text-xl font-bold text-gray-700 leading-8 align-top mb-1"><FontAwesomeIcon icon={faList} size="lg" /> Solicitudes de Vacaciones</h1>
                </div>
            ) :
                (
                    <div class="bg-gray-200 m-2">
                        <h1 class="font-sans inline-block text-xl font-bold text-gray-700 leading-8 align-top mb-1"><FontAwesomeIcon icon={faUmbrellaBeach} size="xl" /> {(vacationPayload.header ? vacationPayload.header.Movimiento : 'Solicitud Vac')}</h1>
                        <TransactionStatus {...props} Estatus={vacationPayload.header.Estatus} />
                    </div>
                )}

            <LoadPanel
                shadingColor="rgba(0,0,0,0.4)"
                visible={visibleLoadPanel}
                showIndicator={true}
                message="Por favor espere..."
                shading={true}
                showPane={true}
                hideOnOutsideClick={false}
            />

            <Toast
                visible={visibleToast}
                message={messageToast}
                type={typeToast}
                onHiding={onHidingToast}
                displayTime={5000}
                width={'auto'}
                position={{
                    my: 'top center',
                    at: 'top center',
                    of: window,
                    offset: '0 130'
                }}
            />

            <div class="bg-gray-200 m-2">
                {isDetail === false ? (
                    <div class="bg-white p-3 shadow-xl">
                        <Link to="/Vacation/undefined">
                            <button class="mb-2 bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded" onClick={() => newButton()}><FontAwesomeIcon icon={faFileAlt} /> Nueva Solicitud de Vacaciones</button>
                        </Link>

                        <button onClick={refreshButton} type="submit" class="ml-4 mb-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                            <FontAwesomeIcon icon={faArrowsRotate} /> Actualizar
                        </button>

                        <div className="options mb-2">
                            <div className="option">
                                <CheckBox text="Expandir todos los grupos"
                                    value={autoExpandAll}
                                    onValueChanged={onAutoExpandAllChanged}></CheckBox>
                            </div>
                        </div>
                        <DataGrid
                            height={800}
                            id="gridContainer"
                            columnsAutoWidth={true}
                            filterBuilder={filterBuilder}
                            dataSource={store}
                            defaultFilterValue={filterValue}
                            allowColumnReordering={true}
                            allowColumnResizing={true}
                            columnResizingMode={"widget"}
                            showBorders={true}
                            rowAlternationEnabled={true}
                            showRowLines={true}
                            showColumnLines={true}
                            remoteOperations={{ filtering: true }}
                            ref={gridTableroRef}
                            onExporting={onExporting}
                        >
                            <Scrolling rowRenderingMode='virtual'></Scrolling>
                            <Paging defaultPageSize={10} />
                            <Pager
                                visible={true}
                                allowedPageSizes={allowedPageSizes}
                                displayMode='compact'
                                showPageSizeSelector={true}
                                showInfo={true}
                                showNavigationButtons={true}
                            />
                            <FilterRow visible={true} />
                            <FilterPanel visible={true} />
                            <FilterBuilderPopup position={filterBuilderPopupPosition} />

                            <HeaderFilter visible={true} />

                            <GroupPanel visible={true} />
                            <SearchPanel visible={true} />
                            <ColumnChooser
                                enabled={true}
                                mode='select'
                            >
                                <ColumnChooserSearch
                                    enabled={true}
                                />

                                <ColumnChooserSelection
                                    allowSelectAll={true}
                                    selectByClick={true}
                                    recursive={true}
                                />
                            </ColumnChooser>
                            <Grouping autoExpandAll={autoExpandAll} />
                            <StateStoring enabled={true} type="localStorage" storageKey="gridContainerVacation" />
                            <Export enabled={true} />

                            <Column caption="" alignment="center" width={100} cellRender={editVacation} />

                            <Column dataField="MovID" dataType="string" width={190} caption="Folio" />
                            <Column dataField="FechaEmision" caption="Fecha Emisión" dataType="date" width={150} />
                            <Column dataField="FechaD" caption="De Fecha" dataType="date" width={150} />
                            <Column dataField="FechaA" caption="A Fecha" dataType="date" width={150} />
                            <Column dataField="Estatus" dataType="string" width={150} />
                            <Column dataField="NombreEmpleado" dataType="string" groupIndex={1} />
                            <Column caption="Plaza" dataField="Plaza" dataType="string" width={150} groupIndex={0} />
                        </DataGrid>
                    </div>
                ) : (<div />)}
                <Route exact path="/Vacation/:ID" render={({ match }) => {
                    return (
                        <div class="bg-gray-200">
                            {isDetail.toString() === 'true' ?
                                (
                                    <div>
                                        <Link to="/Vacation">
                                            <button onClick={cancelEditButton} type="submit" class="mb-4 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
                                                <FontAwesomeIcon icon={faArrowRotateLeft} /> Regresar
                                            </button>
                                        </Link>

                                        {activateSaveButton.toString() === 'true' ?
                                            (
                                                <button onClick={saveButton} type="submit" class="ml-4 mb-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                                                    <FontAwesomeIcon icon={faSave} /> Guardar Cambios
                                                </button>
                                            ) : (
                                                <button disabled onClick={saveButton} type="submit" class="ml-4 mb-4 cursor-not-allowed disabled:opacity-75 bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded">
                                                    <FontAwesomeIcon icon={faSave} /> Guardar Cambios
                                                </button>
                                            )}

                                        {activateConfirmButton.toString() === 'true' ?
                                            (
                                                <button onClick={confirmButton} type="submit" class="ml-4 mb-4 bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">
                                                    <FontAwesomeIcon icon={faClipboardCheck} /> Confirmar Solicitud
                                                </button>
                                            ) : (
                                                <button disabled onClick={confirmButton} type="submit" class="ml-4 mb-4 cursor-not-allowed disabled:opacity-75 bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded">
                                                    <FontAwesomeIcon icon={faClipboardCheck} /> Confirmar Solicitud
                                                </button>
                                            )}

                                        {activateDeleteButton.toString() === 'true' && id ?
                                            (
                                                <button onClick={deleteButton} type="submit" class="ml-4 mb-4 bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded">
                                                    <FontAwesomeIcon icon={faTrashAlt} /> Eliminar Solicitud
                                                </button>
                                            ) : (
                                                <button disabled onClick={deleteButton} type="submit" class="ml-4 mb-4 cursor-not-allowed disabled:opacity-75 bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded">
                                                    <FontAwesomeIcon icon={faTrashAlt} /> Eliminar Solicitud
                                                </button>
                                            )}

                                        {activateCancelButton.toString() === 'true' && id ?
                                            (
                                                <button onClick={cancelButton} type="submit" class="ml-4 mb-4 bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded">
                                                    <FontAwesomeIcon icon={faBan} /> Rechazar Solicitud
                                                </button>
                                            ) : (
                                                <button disabled onClick={cancelButton} type="submit" class="ml-4 mb-4 cursor-not-allowed disabled:opacity-75 bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded">
                                                    <FontAwesomeIcon icon={faBan} /> Rechazar Solicitud
                                                </button>
                                            )}

                                        <button onClick={printButton} type="submit" class="ml-4 mb-4 bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded">
                                            <FontAwesomeIcon icon={faFilePdf} /> PDF
                                        </button>
                                        <div class="bg-white p-4 shadow-xl">
                                            <Form
                                                id="datosGenerales"
                                                labelMode={'outside'}
                                                formData={vacationPayload.header}
                                                ref={formDatosGeneralesRef}
                                                showColonAfterLabel={false}
                                                labelLocation={'top'}
                                                colCount={2}
                                                onFieldDataChanged={onFieldDatosVacacionChanged}
                                            >
                                                <GroupItem caption="General">
                                                    <Item cssClass="boldText" dataField="Movimiento" editorOptions={movimientoEditorOptions} />
                                                    <Item dataField="FechaEmision" editorType="dxDateBox" editorOptions={fechaEmisionEditorOptions}>
                                                        <Label text={"Fecha Emisión"} />
                                                    </Item>
                                                </GroupItem>
                                                <GroupItem caption="Datos del Empleado">
                                                    <Item dataField="Personal" editorOptions={nombreEmpleadoEditorOptions} >
                                                        <Label text={"Clave Empleado"} />
                                                    </Item>
                                                    <Item dataField="NombreEmpleado" editorOptions={nombreEmpleadoEditorOptions} >
                                                        <Label text={"Nombre Empleado"} />
                                                    </Item>
                                                    <Item dataField="NombreSucursal" editorOptions={nombreSucursalEditorOptions} >
                                                        <Label text={"Sucursal"} />
                                                    </Item>
                                                    <Item dataField="FechaIngreso" editorType="dxDateBox" editorOptions={fechaIngresoEditorOptions} >
                                                        <Label text={"Fecha de Ingreso"} />
                                                    </Item>
                                                    <Item dataField="Antiguedad" editorOptions={antiguedadEditorOptions} >
                                                        <Label text={"Años Laborados"} />
                                                    </Item>
                                                    <Item cssClass="boldText" dataField="FondoDisponible" editorOptions={fondoDisponibleEditorOptions} >
                                                        <Label text={"Días de Fondo Disponibles"} />
                                                    </Item>
                                                    <Item cssClass="boldText" dataField="RestanteDisponible" editorOptions={restanteDisponibleEditorOptions} >
                                                        <Label text={"Días Restantes Disponibles"} />
                                                    </Item>
                                                </GroupItem>
                                                <GroupItem caption="Datos Jefe Directo">
                                                    <Item dataField="Supervisor" editorOptions={nombreEmpleadoEditorOptions} >
                                                        <Label text={"Clave"} />
                                                    </Item>
                                                    <Item dataField="NombreSupervisor" editorOptions={nombreEmpleadoEditorOptions} >
                                                        <Label text={"Nombre"} />
                                                    </Item>
                                                    <Item dataField="CorreoSupervisor" editorOptions={nombreEmpleadoEditorOptions} >
                                                        <Label text={"eMail"} />
                                                    </Item>
                                                </GroupItem>
                                                <GroupItem caption="Datos Solicitud">
                                                    <Item dataField="FechaD" editorType="dxDateBox" editorOptions={fechaDEditorOptions} >
                                                        <Label text={"De Fecha"} />
                                                    </Item>
                                                    <Item dataField="FechaA" editorType="dxDateBox" editorOptions={fechaAEditorOptions} >
                                                        <Label text={"A Fecha"} />
                                                    </Item>
                                                    <Item dataField="NumeroDias" editorOptions={numeroDiasEditorOptions} >
                                                        <Label text={"Número de Días"} />
                                                    </Item>
                                                    <Item dataField="PersonalCubre" editorType="dxSelectBox" editorOptions={personalCubreEditorOptions} >
                                                        <Label text={"Personal que cubre"} />
                                                    </Item>
                                                </GroupItem>
                                                <GroupItem caption="Motivo de Rechazo">
                                                    <Item dataField="MotivoRechazo" editorType="dxSelectBox" editorOptions={motivoRechazoEditorOptions} >
                                                        <Label text={"Motivo"} />
                                                    </Item>
                                                    <Item dataField="Observaciones" editorOptions={observacionesEditorOptions} editorType="dxTextArea" >
                                                        <Label text={"Observaciones"} />
                                                    </Item>
                                                </GroupItem>
                                                <GroupItem caption="Detalle">
                                                    <DataGrid
                                                        id="gridCelularVehiculoNissan"
                                                        columnsAutoWidth={true}
                                                        dataSource={vacationPayload.detail}
                                                        showBorders={true}
                                                        showColumnLines={true}
                                                        showRowLines={true}
                                                        rowAlternationEnabled={false}
                                                        allowColumnResizing={true}
                                                        columnResizingMode={"widget"}
                                                        allowColumnReordering={false}
                                                        onEditorPreparing={overrideOnValueChanged}
                                                        repaintChangesOnly={true}
                                                    >
                                                        <Paging defaultPageSize={100} />
                                                        <Editing
                                                            mode="cell"
                                                            allowUpdating={allowUpdatingGrid}
                                                        />

                                                        <Sorting mode="none" />
                                                        <Column dataField="Tipo" dataType="string" allowEditing={false} width={150} />
                                                        <Column dataField="Cantidad" width={150} dataType="string" showEditorAlways={true} />
                                                    </DataGrid>
                                                </GroupItem>
                                            </Form>
                                        </div>
                                    </div>
                                ) :
                                (<div></div>)}
                        </div>
                    )
                }}
                />
            </div>
        </div>
    );
}