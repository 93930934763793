import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpRightFromSquare } from "@fortawesome/pro-solid-svg-icons";

export default function gsItem({
  name
}) {
  return (
    <div className="custom-item">
      <div>{`${name}`}<button className={"text-black py-1 px-3 rounded"}><FontAwesomeIcon icon={faArrowUpRightFromSquare} /></button></div>
    </div>
  );
}