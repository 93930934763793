import React, { useState, useEffect, useRef, useMemo } from 'react';

// Ruteador
import { Route, Link, useHistory } from "react-router-dom";

// LoadPanel
import { LoadPanel } from 'devextreme-react/load-panel';

// Toast
import { Toast } from 'devextreme-react/toast';

//Form
import Form, { GroupItem, Label, TabbedItem, TabPanelOptions, Tab, EmptyItem } from 'devextreme-react/form';

//Validator
import { EmailRule, CustomRule } from 'devextreme-react/validator';

import { Auth, API, Storage } from 'aws-amplify';

// Grid
import DataGrid, {
  Column, FilterRow, HeaderFilter, FilterPanel, FilterBuilderPopup,
  GroupPanel, Grouping, SearchPanel, Sorting, Editing, Lookup, Toolbar, Item, RequiredRule,
  Scrolling, Pager, Paging, Summary, TotalItem, StateStoring, ColumnChooser, Format, Selection,
  Export
} from 'devextreme-react/data-grid';

import { exportDataGrid } from 'devextreme/pdf_exporter';
import { exportDataGrid as exportDataGridExcel } from 'devextreme/excel_exporter';
import { jsPDF } from 'jspdf';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';

// Iconos
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsRotate } from "@fortawesome/pro-solid-svg-icons";
import { faArrowUp } from "@fortawesome/pro-solid-svg-icons";
import { faArrowDown } from "@fortawesome/pro-solid-svg-icons";
import { faEquals } from "@fortawesome/pro-solid-svg-icons";
import { faFileExcel } from "@fortawesome/pro-solid-svg-icons";
import { faFilePdf } from "@fortawesome/pro-solid-svg-icons";
import { faFileChartColumn } from "@fortawesome/pro-duotone-svg-icons";

import dxDateBox from "devextreme/ui/date_box";

import Lambda from 'aws-sdk/clients/lambda';

import 'devextreme/data/odata/store';
import CustomStore from 'devextreme/data/custom_store';

import DataSource from 'devextreme/data/data_source';

import '../ListStyle.css';

dxDateBox.defaultOptions({
  options: {
    dateSerializationFormat: "yyyy-MM-dd"
  }
});

export default function Kardex(props) {
  const history = useHistory();

  const [visibleLoadPanel, setVisibleLoadPanel] = useState(false);
  const [visibleToast, setVisibleToast] = useState(false);
  const [messageToast, setMessageToast] = useState('');
  const [typeToast, setTypeToast] = useState('success');

  const dateFormatYYYYDDMM = React.useCallback(() => {
    var date = new Date();

    var year = date.getFullYear().toString();
    var month = date.getMonth() + 1;
    var day = date.getDate().toString();

    if (month < 10)
      month = '0' + month.toString();
    else
      month = month.toString();

    return (
      year + "-" + month + "-" + day
    );
  }, []);

  const getMinArticulo = React.useCallback(async (sqlFilter) => {
    let apiName = 'AdminSC';
    let path = '/getcatalogo';

    let myInit = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
      },
      queryStringParameters: {
        type: 'MinArticulo',
        username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username,
        sqlFilter: sqlFilter
      }
    }

    var articulosGet = await API.get(apiName, path, myInit);

    return articulosGet[0].value;

  }, []);

  const getMaxArticulo = React.useCallback(async (sqlFilter) => {
    let apiName = 'AdminSC';
    let path = '/getcatalogo';

    let myInit = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
      },
      queryStringParameters: {
        type: 'MaxArticulo',
        username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username,
        sqlFilter: sqlFilter
      }
    }

    var articulosGet = await API.get(apiName, path, myInit);

    return articulosGet[0].value;

  }, []);

  const [reportPayload, setReportPayload] = useState({
    header: {
      FechaD: dateFormatYYYYDDMM(),
      FechaA: dateFormatYYYYDDMM(),
      Almacen: undefined,
      ArticuloD: undefined,
      ArticuloA: undefined,
      Grupo: '(Todos)',
      Categoria: '(Todos)'
    },
    salesPlaza: [],
    locations: []
  });

  const [almacenes, setAlmacenes] = useState([]);
  const [categorias, setCategorias] = useState([]);
  const [grupos, setGrupos] = useState([]);

  //Ref para el form
  const [formReporteRef, setFormReporteRef] = useState(React.createRef());

  //Ref para el grid Sucursales
  const [gridKardexRef, setGridKardexRef] = useState(React.createRef());

  //Ref para el grid Plazas
  const [gridPlazasRef, setGridPlazasRef] = useState(React.createRef());

  const filterBuilderPopupPosition = {
    of: window,
    at: 'top',
    my: 'top',
    offset: { y: 10 },
  };

  async function getAlmacenes() {
    let apiName = 'AdminSC';
    let path = '/getcatalogo';

    let myInit = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
      },
      queryStringParameters: {
        type: 'AlmacenNormal',
        username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username
      }
    }

    var almacenesGet = await API.get(apiName, path, myInit);

    setAlmacenes(almacenesGet);
  };

  async function getCategorias() {
    let apiName = 'AdminSC';
    let path = '/getcatalogo';

    var tmpCategorias = [
      {
        name: '(Todos)',
        value: '(Todos)'
      }
    ];

    let myInit = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
      },
      queryStringParameters: {
        type: 'ArtCat',
        username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username
      }
    }

    var categoriasGet = await API.get(apiName, path, myInit);

    for (var categoriasGetIndex in categoriasGet) {
      if (categoriasGet[categoriasGetIndex].value !== '')
        tmpCategorias.push(categoriasGet[categoriasGetIndex]);
    }

    setCategorias(tmpCategorias);
  };

  async function getGrupos() {
    let apiName = 'AdminSC';
    let path = '/getcatalogo';

    var tmpGrupos = [
      {
        name: '(Todos)',
        value: '(Todos)'
      }
    ];

    let myInit = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
      },
      queryStringParameters: {
        type: 'ArtGrupo',
        username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username
      }
    }

    var gruposGet = await API.get(apiName, path, myInit);

    for (var gruposGetIndex in gruposGet) {
      if (gruposGet[gruposGetIndex].value !== '')
        tmpGrupos.push(gruposGet[gruposGetIndex]);
    }

    setGrupos(tmpGrupos);
  };

  const getArticulo = React.useCallback(async (sqlFilter) => {
    let apiName = 'AdminSC';
    let path = '/getcatalogo';

    let myInit = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
      },
      queryStringParameters: {
        type: 'Articulo',
        username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username,
        sqlFilter: sqlFilter
      }
    }

    var articulosGet = await API.get(apiName, path, myInit);

    return articulosGet;

  }, []);

  var fechaEditorOptions = useMemo(() => {
    return {
      width: '100%',
      readOnly: false
    };
  }, []);

  var almacenDataSource = useMemo(() => {
    return new DataSource({
      paginate: true,
      store: {
        type: "array",
        key: "Almacen",
        data: almacenes
      }
    });
  }, [almacenes]);

  var categoriaDataSource = useMemo(() => {
    return new DataSource({
      paginate: true,
      store: {
        type: "array",
        key: "value",
        data: categorias
      }
    });
  }, [categorias]);

  var grupoDataSource = useMemo(() => {
    return new DataSource({
      paginate: true,
      store: {
        type: "array",
        key: "value",
        data: grupos
      }
    });
  }, [grupos]);

  const storeArticulo = useMemo(() => {
    return new CustomStore({
      key: 'value',
      load(loadOptions) {
        let sqlFilter = '';
        [
          'filter'
        ].forEach((i) => {
          sqlFilter = loadOptions.searchValue;
        });

        async function getRecords() {
          if (sqlFilter) {
            var records = await getArticulo(sqlFilter);

            return {
              data: records,
              totalCount: records.length
            };
          }
          else {
            return {
              data: [],
              totalCount: 0
            };
          }
        }

        return getRecords()
          .then(function (result) {
            return result;
          })
          .catch(() => { throw new Error('Data Loading Error'); });
      },
      byKey: (key) => {
        async function getRecords(key) {
          var records = await getArticulo(key);

          return records;
        }

        return getRecords(key);
      }
    })
  }, [getArticulo]);

  //Opciones del campo Almacen
  try {
    var almacenEditorOptions = useMemo(() => {
      return {
        dataSource: almacenDataSource,
        displayExpr: 'name',
        valueExpr: 'value',
        searchEnabled: true,
        value: (reportPayload.header.Almacen ? reportPayload.header.Almacen : ''),
        width: '100%'
      };
    }, [almacenDataSource, reportPayload.header.Almacen]);
  }
  catch (e) {
    var almacenEditorOptions = {
      dataSource: almacenDataSource,
      displayExpr: 'name',
      valueExpr: 'value',
      searchEnabled: true,
      value: '',
      width: '100%'
    };
  }

  //Opciones del campo Articulo
  try {
    var articuloEditorOptions = {
      dataSource: storeArticulo,
      displayExpr: 'name',
      valueExpr: 'value',
      searchEnabled: true,
      showClearButton: true,
      acceptCustomValue: false,
      disabled: false,
      searchTimeout: 1500,
      width: '100%',
      clearButtonText: 'Cerrar',
      noDataText: 'No se encontraron resultados'
    };
  }
  catch (e) {
    var articuloEditorOptions = {
      dataSource: storeArticulo,
      displayExpr: 'name',
      valueExpr: 'value',
      searchEnabled: true,
      acceptCustomValue: false,
      width: '100%'
    };
  }

  //Opciones del campo Categoria
  try {
    var categoriaEditorOptions = useMemo(() => {
      return {
        dataSource: categoriaDataSource,
        displayExpr: 'name',
        valueExpr: 'value',
        searchEnabled: true,
        value: (reportPayload.header.Categoria ? reportPayload.header.Categoria : ''),
        width: '100%'
      };
    }, [categoriaDataSource, reportPayload.header.Categoria]);
  }
  catch (e) {
    var categoriaEditorOptions = {
      dataSource: categoriaDataSource,
      displayExpr: 'name',
      valueExpr: 'value',
      searchEnabled: true,
      value: '',
      width: '100%'
    };
  }

  //Opciones del campo Grupo
  try {
    var grupoEditorOptions = useMemo(() => {
      return {
        dataSource: grupoDataSource,
        displayExpr: 'name',
        valueExpr: 'value',
        searchEnabled: true,
        value: (reportPayload.header.Grupo ? reportPayload.header.Grupo : ''),
        width: '100%'
      };
    }, [grupoDataSource, reportPayload.header.Grupo]);
  }
  catch (e) {
    var grupoEditorOptions = {
      dataSource: grupoDataSource,
      displayExpr: 'name',
      valueExpr: 'value',
      searchEnabled: true,
      value: '',
      width: '100%'
    };
  }

  const refreshButton = React.useCallback(async () => {
    if (formReporteRef.current) {
      var formReporte = formReporteRef.current.instance;
      var isValid = formReporte.validate();

      if (!isValid.isValid) {
        setVisibleToast(true);
        setMessageToast(isValid.brokenRules[0].message);
        setTypeToast('error');
        return;
      }
    }

    try {
      setVisibleLoadPanel(true);

      var lambdaFunction;

      if (window.location.href.indexOf('sandbox.') >= 0 || window.location.href.indexOf('localhost') >= 0)
        lambdaFunction = 'kardexInventarios-dev';
      else if (window.location.href.indexOf('sandboxtampico.') >= 0)
        lambdaFunction = 'kardexInventarios-devleona';
      else if (window.location.href.indexOf('tampico.') >= 0)
        lambdaFunction = 'kardexInventarios-prodleona';
      else
        lambdaFunction = 'kardexInventarios-prod';

      Auth.currentCredentials()
        .then(credentials => {
          const lambda = new Lambda({
            credentials: Auth.essentialCredentials(credentials),
            region: 'us-east-1'
          });

          var params = {
            FunctionName: lambdaFunction,
            Payload: JSON.stringify({
              FechaD: reportPayload.header.FechaD,
              FechaA: reportPayload.header.FechaA,
              Almacen: reportPayload.header.Almacen,
              ArticuloD: reportPayload.header.ArticuloD,
              ArticuloA: reportPayload.header.ArticuloA,
              Grupo: reportPayload.header.Grupo,
              Categoria: reportPayload.header.Categoria,
              username: props.username
            })
          };

          lambda.invoke(params, function (err, data) {
            if (err) {
              setVisibleLoadPanel(false);
            }
            else {
              var payload = JSON.parse(JSON.parse(data.Payload).body);

              setVisibleLoadPanel(false);
              setReportPayload(payload);
            }
          });
        })
    }
    catch (e) {
      setVisibleLoadPanel(false);

      if (e === 'No current user') {
        history.push('/');

        window.location.reload();
      }
    }
  }, [formReporteRef, history, reportPayload, props.username]);

  const excelButton = React.useCallback(() => {
    const workbook = new Workbook();
    const kardexSheet = workbook.addWorksheet('Kardex');

    var gridKardex = gridKardexRef.current.instance;

    exportDataGridExcel({
      worksheet: kardexSheet,
      component: gridKardex,
      topLeftCell: { row: 1, column: 1 }
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Reporte.xlsx');
      });
    });

  }, [gridKardexRef]);

  const pdfButton = React.useCallback(() => {
    const doc = new jsPDF('l');

    var gridKardex = gridKardexRef.current.instance;

    doc.text('Kardex de Artículos', 7, 10);

    exportDataGrid({
      jsPDFDocument: doc,
      component: gridKardex,
      topLeft: { x: 7, y: 5 },
      columnWidths: [20, 70, 20, 30, 15, 15, 20, 40, 10, 10],
      customizeCell: ({ gridCell, pdfCell }) => {
        pdfCell.font.size = 6;
      }
    }).then(() => {
      doc.save('Kardex.pdf');
    });
  }, [gridKardexRef]);

  useEffect(() => {
    async function initialize() {
      setVisibleLoadPanel(true);

      await getAlmacenes();

      await getCategorias();

      await getGrupos();

      setVisibleLoadPanel(false);
    }

    initialize();
    // eslint-disable-next-line
  }, []);

  const onHidingToast = React.useCallback(() => {
    setVisibleToast(false);
  }, []);

  return (
    <div>
      <div class="bg-gray-200 m-2">
        <h1 class="font-sans inline-block text-xl font-bold text-gray-700 leading-8 align-top mb-1"><FontAwesomeIcon icon={faFileChartColumn} size="xl" /> Kardex de inventarios</h1>
      </div>
      <div>
        <button onClick={refreshButton} type="submit" class="ml-2 mb-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
          <FontAwesomeIcon icon={faArrowsRotate} /> Actualizar
        </button>
        <button onClick={excelButton} type="submit" class="mb-4 ml-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
          <FontAwesomeIcon icon={faFileExcel} /> Exportar Excel
        </button>
        <button onClick={pdfButton} type="submit" class="mb-4 ml-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
          <FontAwesomeIcon icon={faFilePdf} /> Exportar PDF
        </button>
      </div>

      <LoadPanel
        shadingColor="rgba(0,0,0,0.4)"
        visible={visibleLoadPanel}
        showIndicator={true}
        message="Por favor espere..."
        shading={true}
        showPane={true}
        hideOnOutsideClick={false}
      />

      <Toast
        visible={visibleToast}
        message={messageToast}
        type={typeToast}
        onHiding={onHidingToast}
        displayTime={5000}
        width={'auto'}
        position={{
          my: 'top center',
          at: 'top center',
          of: window,
          offset: '0 130'
        }}
      />

      <div class="m-2 bg-white p-4 shadow-xl">
        <Form
          id="formReporte"
          labelMode={'outside'}
          formData={reportPayload}
          readOnly={false}
          showColonAfterLabel={false}
          labelLocation={'top'}
          ref={formReporteRef}
        >
          <GroupItem caption="Filtros" colCount={2}>
            <Item dataField="header.FechaD" editorType="dxDateBox" editorOptions={fechaEditorOptions}>
              <Label text={"De Fecha"} />
              <RequiredRule message="De Fecha es requerido" />
            </Item>
            <Item dataField="header.FechaA" editorType="dxDateBox" editorOptions={fechaEditorOptions}>
              <Label text={"A Fecha"} />
              <RequiredRule message="A Fecha es requerido" />
            </Item>
            <Item dataField="header.Almacen" editorType="dxSelectBox" editorOptions={almacenEditorOptions}>
              <Label text={"Almacén"} />
              <RequiredRule message="Almacén es requerido" />
            </Item>
            <Item dataField="header.ArticuloD" editorType="dxLookup" editorOptions={articuloEditorOptions}>
              <Label text={"De Artículo"} />
              <RequiredRule message="De Artículo es requerido" />
            </Item>
            <Item dataField="header.ArticuloA" editorType="dxLookup" editorOptions={articuloEditorOptions}>
              <Label text={"A Artículo"} />
              <RequiredRule message="A Artículo es requerido" />
            </Item>
            <Item dataField="header.Grupo" editorType="dxSelectBox" editorOptions={grupoEditorOptions}>
              <Label text={"Grupo"} />
            </Item>
            <Item dataField="header.Categoria" editorType="dxSelectBox" editorOptions={categoriaEditorOptions}>
              <Label text={"Categoría"} />
            </Item>
          </GroupItem>
          <GroupItem caption="Kardex" colCount={1}>
            <DataGrid
              id="gridKardex"
              ref={gridKardexRef}
              columnsAutoWidth={true}
              dataSource={reportPayload.kardex}
              showBorders={true}
              showColumnLines={true}
              showRowLines={true}
              rowAlternationEnabled={true}
              allowColumnResizing={true}
              columnResizingMode="widget"
              allowColumnReordering={false}
              repaintChangesOnly={true}
            >
              <Scrolling
                mode='virtual'
              />
              <Editing
                mode="cell"
                newRowPosition="last"
                allowUpdating={false}
                allowAdding={false}
                allowDeleting={false}
              />

              <Sorting mode="none" />
              <FilterRow visible={true} />
              <FilterPanel visible={true} />
              <FilterBuilderPopup position={filterBuilderPopupPosition} />

              <HeaderFilter visible={true} />

              <Column dataField="ArtDescripcion" dataType="string" width={400} visible={true} caption="Artículo" groupIndex={0} />
              <Column dataField="FechaRegistro" dataType="date" width={140} visible={true} caption="Fecha Registro" />
              <Column dataField="Mov" dataType="string" width={150} visible={true} caption="Movimiento" />
              <Column dataField="Movid" dataType="string" width={120} visible={true} caption="Folio" />
              <Column dataField="Cantidad" dataType="number" width={100} visible={true} caption="Cantidad" />
              <Column dataField="InventarioActual" dataType="number" width={150} visible={true} caption="Inventario Actual" />
              <Column dataField="Usuario" dataType="string" width={370} visible={true} caption="Usuario" />
              <Column dataField="Revisado" dataType="string" width={100} visible={true} caption="Revisado" />
              <Column dataField="Revisado2" dataType="string" width={110} visible={true} caption="Revisado 2" />

            </DataGrid>
          </GroupItem>
        </Form>
      </div>
    </div>
  );
}